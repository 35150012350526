import {
  TextField,
  IconButton,
  List,
  ListItem,
  Button,
  Box,
  CardHeader,
  Avatar,
  Divider,
  Grid,
  Card,
  CardContent,
  Skeleton,
  Typography,
  MenuItem
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Add,
  Edit,
  SafetyCheck,
} from "@mui/icons-material";
import Masks from "../../../../components/Shared/Masks";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import {api} from "../../../../services/Main/Api";
import Context from "../../../../contexts/Context";
import {useSnackbar} from "notistack";
import SimpleDialog from "../../../../components/Shared/SimpleDialog";
import SaveCancelButton from "../../../../components/Shared/SaveCancelButton";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment";

function DataInput(props) {
  const params = useParams()
  ///// -> When the component is called from a modal without `params` reference from url
  const { filters, dataProvider, setDataProvider } = useContext(Context)
  const { unitId } = filters || {}
  /////
  // const { index, description, value, onChange, onRemove, id, installments, paidInstallments, createdAt, fullyPaid } = props;
  const { index, onChange, id, getInsurance, createInsurance, updateInsurance, deleteInsurance, getContract, ...dataProps } = props;
  const {
    insurance_type,
    insurance_policy_number,
    insurance_company,
    due_date,

  } = dataProps

  const [handleId, setHandleId] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)
  const [edit, setEdit] = useState(false)
  const [saveInsurance, setSaveInsurance] = useState(false)

  const handleInsuranceType = (event) => {
    onChange(index, { ...dataProps, insurance_type: event.target.value, id});
  };

  const handleInsurancePolicyNumber = (event) => {
    onChange(index, { ...dataProps, insurance_policy_number: event.target.value, id });
  };

  const handleInsuranceCompany = (event) => {
    onChange(index, { ...dataProps, insurance_company: event.target.value, id,  });
  };

  const handleDueDate = (date) => {
    onChange(index, { ...dataProps, due_date: date, id });
  };


  function handleEdit(){
    setEdit(!edit)
  }

  function formatInsuranceName(insurance_type){
    switch(insurance_type.value) {
      case 'fire_insurance':
        return 'Seguro Contra Incêndio'
      case 'content_insurance':
        return 'Seguro Conteúdo'
      default:
        return '- - -'
    }
  }

  useEffect(function observeInputs() {
    const requiredKeys = [
      "due_date",
      "installments",
      "installment_start"
    ];

    const allFilled = requiredKeys.every(key => {
      const due_date = dataProps?.due_date;
      return due_date !== undefined && due_date !== null && due_date !== "";
    });

    setSaveInsurance(allFilled);
  }, [dataProps]);

  return (
    <>

      <Box
        sx={{
          display: 'flex',
          borderRadius: '1rem',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        { id && !edit ?
          <Box
            sx={{
              justifyContent: 'space-between',
              borderRadius: '1rem',
              width: '100%',
            }}
          >

            <Box
              sx={{
                mb: '1rem'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: 'primary.main',
                      fontWeight: 'bold',
                      fontSize: '23px'
                    }}
                  >
                    {formatInsuranceName(insurance_type)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ml: '1rem'

                  }}
                >
                  <IconButton onClick={() => {
                    setHandleId(id)
                    handleEdit()
                  }}>
                    <Edit />
                  </IconButton>


                  <IconButton
                    onClick={() => {
                      setHandleId(id)
                      setOpenDelete(true)
                    }}
                    sx={{padding : 0}}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                mb: '2rem'
              }}
            >

              {insurance_policy_number &&
                <SimpleTypography
                  label={'Numero da Apólice'}
                  directValue={insurance_policy_number ?? ''}
                  labelStyles={{
                    fontWeight: 'bold'
                  }}
                  boxStyles={{
                    width: '100%',
                    mb: '1rem'
                  }}
                />
              }

              {insurance_company &&
                <SimpleTypography
                  label={'Compania'}
                  directValue={insurance_company ?? ''}
                  labelStyles={{
                    fontWeight: 'bold'
                  }}
                  boxStyles={{
                    width: '100%',
                    mb: '1rem'
                  }}
                />
              }

              {due_date &&
                <SimpleTypography
                  label={'Data de Vencimento'}
                  directValue={moment(due_date).format('DD/MM/YYYY') ?? ''}
                  labelStyles={{
                    fontWeight: 'bold'
                  }}
                  boxStyles={{
                    width: '100%',
                    mb: '1rem'
                  }}
                />
              }


            </Box>
          </Box>

          :
          <>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >

              { id ?
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: '2rem'
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: 'primary.main',
                        fontWeight: 'bold',
                        fontSize: '23px'
                      }}
                    >
                      {formatInsuranceName(insurance_type)}
                    </Typography>
                  </Box>

                  <SaveCancelButton
                    align={'start'}
                    edit={edit}
                    disabled={!saveInsurance}
                    saveClick={() => {
                      updateInsurance(handleId)
                      setHandleId(null)
                      handleEdit()
                    }}
                    cancelClick={() => {
                      getContract()
                      getInsurance()
                      handleEdit()
                      setHandleId(null)
                    }}
                  />
                </Box>
                :
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography
                    variant={'h6'}
                    sx={{
                      mb: '1rem',
                      color: 'primary.main'
                    }}
                  >
                    { !id ? 'Novo Seguro' : 'Editar Seguro' }
                  </Typography>

                </Box>


              }

              {!edit &&
                <TextField
                  select
                  size={'small'}
                  label={'Tipo de Seguro'}
                  value={insurance_type ?? ''}
                  onChange={handleInsuranceType}
                  sx={{
                    width: '100%',
                    mb: '1rem'
                  }}
                >
                  <MenuItem
                    value={'fire_insurance'}
                    disabled={dataProvider?.disableFire}
                  >
                    Seguro Contra Incêndio
                  </MenuItem>

                  <MenuItem
                    value={'content_insurance'}
                    disabled={dataProvider?.disableContent}
                  >
                    Seguro Conteúdo
                  </MenuItem>
                </TextField>
              }

              <TextField
                label="Número da Apólice"
                size={'small'}
                disabled={id && handleId !== id}
                value={insurance_policy_number}
                onChange={handleInsurancePolicyNumber}
                sx={{
                  width: '100%',
                  mb: '1rem'
                }}
              />

              <TextField
                label="Compania"
                disabled={id && handleId !== id}
                size={'small'}
                value={insurance_company ?? ''}
                onChange={handleInsuranceCompany}
                sx={{
                  width: '100%',
                  mb: '1rem'

                }}
              />
                <DatePicker
                    sx={{ textAlign: 'center' }}
                    views={['year', 'month', 'day']}
                    label="Data de Vencimento"
                    inputProps={{
                      placeholder: 'dd/mm/aaaa'
                    }}
                    value={moment(due_date).format()}
                    onChange={(newValue) => {
                      const formattedDate = moment(newValue).format('YYYY-MM-DD');
                      handleDueDate(formattedDate);
                    }}
                    renderInput={
                      (params) => (
                          <TextField
                              {...params}
                              size={'small'}
                              sx={{ mb: 2 }}
                              helperText={null}
                              fullWidth
                              required
                          />
                      )
                    }
                />

              {!id &&

                  <SaveCancelButton
                      edit={!edit}
                      align={'start'}
                      disabled={!saveInsurance}
                      saveClick={() => {

                        createInsurance(insurance_type)
                      }}
                      cancelClick={() => {
                        getContract(null, true)
                        getInsurance()
                        setHandleId(null)
                      }}
                  />
              }
            </Box>

          </>
        }
        <SimpleDialog
          open={openDelete}
          setOpen={setOpenDelete}
          title={'Excluir seguro'}
          question={'Realmente quer excluir esse seguro?'}
          onClickConfirm={() => {
            setDataProvider(prevState => ({
              ...prevState,
              insurance: (prevState?.insurance || []).filter(type => type !== insurance_type),
            }));
            deleteInsurance(handleId, insurance_type)
          }}
        />
      </Box>
    </>
  )
}

function InsuranceFormSet(props) {
  const { data, onChange, isLoading, getContract, getInsurance, update } = props || {};
  const params = useParams()
  const { dataProvider, setDataProvider } = useContext(Context)

  const {enqueueSnackbar} = useSnackbar()

  const handleDataChange = (index, newData) => {
    const newDataList = [...data];
    newDataList[index] = newData;
    onChange(newDataList);
  };

  const handleAddData = () => {
    const newDataList = [
      ...data,
      {
        insurance_policy_number: '',
        insurance_company: '',
        due_date: '',
        rent_contract: ''
      }
    ];
    onChange(newDataList);
  };

  async function createInsurance(insuranceType) {
    try {

      // await new Promise((resolve) => {
      //   update(); // Chama update(), mas a operação assíncrona espera por isso
      //   resolve();
      // });

      const dataReq = data.map((insurance) => ({
        ...insurance,
      }));

      for (const insurance of dataReq) {
        if (!insurance?.id) {
          await api.post(`rent_contract/insurance/`, {
            insurance_type: insurance.insurance_type,
            insurance_policy_number: insurance.insurance_policy_number,
            insurance_company: insurance.insurance_company,
            due_date: insurance.due_date,
            rent_contract: dataProvider?.rentId ?? params?.id,
          });
          setDataProvider(prevState => ({
            ...prevState,
            insurance: [...(prevState?.insurance || []), insuranceType],
            insuranceCreate: true,
          }))
          getInsurance()
          getContract(null, true);
          enqueueSnackbar('Seguro cadastrado', { variant: 'success' });


        }
      }
    } catch (error) {
      update(false, true)
      console.error(error);
      enqueueSnackbar('Erro ao registrar seguro', { variant: 'error' });
    } finally {
    }
  }




  // function createInsurance () {
  //   const dataReq = data.map(insurance => {
  //     return {
  //       ...insurance,
  //     };
  //   })
  //
  //   dataReq?.map((insurance) => {
  //     if (!insurance?.id) {
  //       api.post(`rent_contract/insurance/`, {
  //         insurance_type: insurance.insurance_type,
  //         insurance_policy_number: insurance.insurance_policy_number,
  //         insurance_company: insurance.insurance_company,
  //         value: +insurance.toString()?.replace(/\D+/g, ""),
  //         installments: insurance.installments,
  //         installment_start: insurance.installment_start,
  //         rent_contract: dataProvider?.rentId ?? params?.id,
  //       })
  //         .then(response => {
  //           enqueueSnackbar('Proprietário cadastrado', { variant: 'success' })
  //           setDataProvider(prevState => ({ ...prevState, UnitSecondStep: true }));
  //
  //         })
  //         .catch(error => {
  //           enqueueSnackbar('Erro ao registrar proprietário', {variant: 'error'})
  //           console.log(error)
  //         })
  //         .finally(() => {
  //           update()
  //         })
  //     }
  //   })
  // }




  async function updateInsurance(insuranceId) {
    const updateInsurance = data.find((insurance) => insurance.id === insuranceId);

    if (updateInsurance) {
      try {
        await new Promise((resolve) => {
          update(); // Chama update(), mas a operação assíncrona espera por isso
          resolve();
        });

        await api.put(`rent_contract/insurance/${updateInsurance.id}/`, {
          insurance_type: updateInsurance.insurance_type.value,
          insurance_policy_number: updateInsurance.insurance_policy_number,
          insurance_company: updateInsurance.insurance_company,
          due_date: updateInsurance.due_date,
          rent_contract: dataProvider?.rentId ?? params?.id,
        });

        enqueueSnackbar('Seguro atualizado', { variant: 'success' });
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Erro ao atualizar seguro', { variant: 'error' });
      } finally {
        // Esta parte será executada independentemente do sucesso ou falha da operação
        getContract();
      }
    }
  }



  async function deleteInsurance(insuranceId, insuranceType) {
    try {
      // await new Promise((resolve) => {
      //   update(); // Chama update(), mas a operação assíncrona espera por isso
      //   resolve();
      // });

      const response = await api.delete(`/rent_contract/insurance/${insuranceId}/`);
      enqueueSnackbar('Seguro deletado', { variant: 'success' });

      setDataProvider((prevState) => {
        const updatedInsurance = (prevState?.insurance || []).filter(
          (type) => type !== insuranceType
        );

        return {
          ...prevState,
          insurance: updatedInsurance,
          insuranceDelete: true,
        };
      });
      getInsurance()
      getContract();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Erro ao excluir seguro', { variant: 'error' });
    } finally {
    }
  }

  useEffect(function insuranceCheck() {
    const fireInsurance = data?.find(e => e.insurance_type?.value === 'fire_insurance');
    const contentInsurance = data?.find(e => e.insurance_type?.value === 'content_insurance');
    const arrayLimit = data?.length === 2

    setDataProvider(prevState => ({
      ...prevState,
      disableFire: !!fireInsurance,
      disableContent: !!contentInsurance,
      disableCreate: !!fireInsurance && !!contentInsurance || arrayLimit,
    }));
  }, [data]);

  useEffect(() => {
    if (dataProvider?.insuranceCreate === true){
      update()
      setDataProvider(prevState => ({
        ...prevState,
        insuranceCreate: false,
        insurance: null,
      }))
    }
  }, [dataProvider?.insuranceCreate])

  useEffect(() => {
    if (dataProvider?.insuranceDelete === true){
      update()
      setDataProvider(prevState => ({
        ...prevState,
        insuranceDelete: false,
        insurance: null,
      }))
    }
  }, [dataProvider?.insuranceDelete])



  return (
    <Grid item xs={12}>
      {!isLoading ?
        <Card sx={{ mt: '1rem'}}>
          <CardHeader
            avatar={<Avatar sx={{bgcolor: 'primary.main'}}><SafetyCheck  /></Avatar>}
            title={'Seguros'}/>
          <Divider sx={{mb: '.3rem'}}/>

          <CardContent>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <List>
                {data?.map((entry, index) => (
                  <ListItem key={index} disableGutters>
                    <DataInput
                      index={index}
                      id={entry.id}
                      insurance_type={entry.insurance_type}
                      insurance_policy_number={entry.insurance_policy_number}
                      insurance_company={entry.insurance_company}
                      due_date={entry.due_date}
                      rent_contract={entry.rent_contract}
                      rent_contract_tax={entry.rent_contract}
                      onChange={handleDataChange}
                      updateInsurance={updateInsurance}
                      createInsurance={createInsurance}
                      deleteInsurance={deleteInsurance}
                      getContract={getContract}
                      getInsurance={getInsurance}
                    />
                  </ListItem>
                ))}
              </List>
              <Button
                size={'small'}
                disabled={dataProvider?.disableCreate}
                variant="outlined"
                startIcon={<Add/>}
                onClick={handleAddData}>
                Adicionar
              </Button>
            </Box>
          </CardContent>
        </Card>
        :
        <Skeleton
          height={300}
          width={'100%'}
          variant='rounded'
        />
      }
    </Grid>
  );
}

export default InsuranceFormSet