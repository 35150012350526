import {Box, Typography} from "@mui/material";
import moment from "moment/moment";
import React from "react";

const InvoiceDetailDue = ({ invoice }) => {
  return (
    <>
      { !invoice?.status?.key === 'open' &&
        <Box>
          <Typography
            variant={'body2'}
          >
            Vencimento: {moment(invoice?.due_date).format('DD \\d\\e MMMM \\d\\e YYYY')}
          </Typography>
        </Box>
      }
    </>
  )
}

export default InvoiceDetailDue