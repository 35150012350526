import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import {
  Close,
  DeleteForever,
  DeleteOutlined,
  Done,
  EditOutlined,
  FilterList,
  MoreVert,
  PriorityHigh,
  SaveAlt,
  Send,
  TextSnippetOutlined,
  Update
} from "@mui/icons-material";
import React, {useContext, useEffect, useState} from "react";
import SimpleText from "../../../components/Shared/SimpleText";
import {api} from "../../../services/Main/Api";
import {useParams} from "react-router-dom";
import moment from "moment";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import {useSnackbar} from "notistack";
import Context from "../../../contexts/Context";
import AnimationGlobal from "../../../components/Shared/animations/AnimationGlobal";
import notFound from "../../../assets/animations/emptyBox.json";

const RentNotes = (props) => {
  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////

  const { isLoading } = props
  const { filters, setFilters } = useContext(Context)
  const params = useParams()
  const { id } = params
  const {enqueueSnackbar} = useSnackbar()

  const [data, setData] = useState([])
  const [reqData, setReqData] = useState(null)
  const [reqDataUpdate, setReqDataUpdate] = useState(null)
  const [openMenu, setOpenMenu] = useState(null)
  const [rentNoteId, setRentNoteId] = useState(null)
  const [anchor, setAnchor ] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)
  const [openInternal, setOpenInternal] = useState(false)


  ///////////////////////////
  //// General functions ////
  ///////////////////////////

  function iconByTag(state = 'unknown') {
    const icons = {
      success: <Done />,
      error: <Close />,
      updated: <Update />,
      info: <PriorityHigh />,
      warning: <PriorityHigh />,
      deleted: <DeleteForever />
    };
    return icons[state];
  }

  function colorByTag(state = 'unknown') {
    const colors = {
      success: '#3ebb27',
      error: '#ca2121',
      updated: '#5a71ce',
      info: '#8a62d8',
      warning: '#c4a50d',
      deleted: '#757575'
    };
    return colors[state] || 'black';
  }

  function Filter() {
    const {filters, setFilters} = useContext(Context)


    const handleFilterChange = (event) => {
      setFilters(prevState => ({...prevState, tag: event.target.value}));
    };
    return (
        <Select
          value={filters?.tag}
          size={'small'}
          onChange={handleFilterChange}
          displayEmpty
          sx={{
            border: 'none',
            outline: 'none',
            // width: '150px',
            paddingLeft: '8px',
            boxShadow: 'none',
            '& fieldset': { border: 'none' }
          }}
          startAdornment={
            <InputAdornment position="start"
                            sx={{
                              mr: '0.5rem'
                            }}
            >
              <FilterList
                sx={{
                  color:'primary.main'

                }}/>
              <Typography color='primary.main'>
                <em>
                  Filtrar por:
                </em>
              </Typography>
            </InputAdornment>
          }

        >
          <MenuItem value="">todos</MenuItem>
          <MenuItem value="success">sucesso</MenuItem>
          <MenuItem value="error" >erro</MenuItem>
          <MenuItem value="updated" >atualizado</MenuItem>
          <MenuItem value="info" >informação</MenuItem>
          <MenuItem value="warning" >aviso</MenuItem>
          <MenuItem value="deleted" >excluído</MenuItem>

        </Select>
    );
  }

  function isButtonDisabled(reqDataUpdate, note) {
    const isMessageEmpty = reqDataUpdate?.message?.length < 1 || reqDataUpdate?.message === undefined;
    const isMessageUnchanged = reqDataUpdate?.message === note.message;

    return isMessageEmpty || isMessageUnchanged;
  }

  const formattedNotes = data?.results?.map(note => {
    const match = note.message.match(/Valor do contrato de locação foi alterado para R\$(\d+(\.\d+)?)/);

    if (match) {
      const value = parseFloat(match[1]);
      const formattedValue = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

      return {
        ...note,
        formattedMessage: note.message.replace(match[0], `Valor do contrato de locação foi alterado para ${formattedValue}`)
      };
    }

    return note;
  });

  const styles = {
    select: {
      border: 'none',
      outline: 'none',
      // width: '150px',
      paddingLeft: '8px',
      boxShadow: 'none',
      '& fieldset': { border: 'none' }
    },
    box: {
      mb: '1rem'
    },

  };

  const sizeMatch = useMediaQuery('@media (min-width:700px)')


  /////////////////////////////
  //////// Requisitions ///////
  /////////////////////////////

  //// => Get
  function getData() {
    const formatFilters = {
      tag: filters?.tag,
      rent_contract: id
    }

    api.get(`rent_contract/internal_note_rent/`, { params: formatFilters})
      .then(response => {
        setData(response.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  //// => Post
  function postRentNote(){

    const data = {
      ...reqData,
      rent_contract: id
    }

    api.post(`rent_contract/internal_note_rent/`, data)
      .then(response => {
        getData()
        enqueueSnackbar('Anotação registrada', { variant: 'success' })
        setReqData(null)
      })
      .catch(error =>{
        enqueueSnackbar('Erro ao registrar anotação ', { variant: 'error' })
        console.log(error)
      })
  }

  //// => Update
  function updateRentNote(noteId){
    const data = {
      ...reqDataUpdate,
      rent_contract: id
    }

    api.put(`rent_contract/internal_note_rent/${noteId}/`, data)
      .then(response => {
        getData()
        setRentNoteId(null)
        setReqDataUpdate(null)
        enqueueSnackbar('Anotação atualizada', { variant: 'success' })

      })
      .catch(error =>{
        enqueueSnackbar('Erro ao atualizar anotação', { variant: 'error' })
        console.log(error)
      })
  }

  //// => Delete
  function deleteRentNote(noteId){
    api.delete(`rent_contract/internal_note_rent/${noteId}/`)
      .then(response => {
        getData()
        setOpenDelete(false)
        enqueueSnackbar('Anotação deletada', { variant: 'success' })

      })
      .catch(error =>{
        console.log(error)
        enqueueSnackbar('Erro ao deletar anotação', { variant: 'error' })

      })
  }


  //////////////////////////////////////
  //// Useffect, triggers and calls ////
  //////////////////////////////////////

  useEffect(function initialFilters(){
    setFilters(prevState => ({
      ...prevState,
      tag: '',
      rent_contract: id
    }))
  },[])

  useEffect(function firstLoad(){
    if (filters) {
      getData()
    }
  },[filters])


  return (
    <Grid item xs={12}>
      {!isLoading ?
        <Card>
          <CardHeader
            sx={{

            }}
            avatar={<Avatar sx={{bgcolor: 'primary.main'}}><TextSnippetOutlined/></Avatar>}
            title={'Notas Internas'}
            action={
            <Box
              sx={{
                display: 'flex',
                flexDirection: sizeMatch ? 'row' : 'column',
                // justifyContent: 'center',
                // mb: '1rem',
                alignItems: 'center',
              }}
            >
                <Filter/>

                <Button
                  onClick={() => {
                    setOpenInternal(!openInternal)
                    setReqData(null)
                  }}
                >

                  {!openInternal ? 'Criar nota' : 'Fechar nota'}
                </Button>
            </Box>}
          />
          <Divider sx={{ mb: '.3rem' }} textAlign={'center'}/>
          <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%'
                }}
              >


                <Fade
                  in={openInternal}
                  mountOnEnter
                  unmountOnExit
                  timeout={300}
                >
                <Box>

                  <SimpleText
                    label={'Anotação'}
                    multiline
                    rows={5}
                    state={reqData}
                    setState={setReqData}
                    keyName={'message'}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      variant="text"
                      endIcon={<Send/>}
                      disabled={!!!reqData?.message}
                      onClick={() => postRentNote()}
                      sx={{
                        mb: '1rem',
                        mt: '0.5rem'
                      }}
                    >
                      Enviar
                    </Button>
                  </Box>
                </Box>
              </Fade>
              {data?.results?.length ?
              <>
                {formattedNotes?.map((note, index) =>
                  <Card
                    key={index}
                    sx={{
                      marginBottom: '1rem',
                      backgroundColor: 'rgba(172,172,177,0.17)',
                    }}
                  >
                    <CardHeader
                      title={
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                          }}
                        >
                        {note?.user}
                        </Typography>
                        {note?.id !== rentNoteId?.editable ?
                            <Typography
                              sx={{
                                textAlign: 'left',
                                flexGrow: 1,
                                ml: '2rem'
                              }}
                            >
                              {note?.formattedMessage ?? note?.message}
                            </Typography>
                          :
                          <Box>
                            <SimpleText
                              label={'Anotação'}
                              multiline
                              rows={5}
                              state={reqDataUpdate}
                              setState={setReqDataUpdate}
                              keyName={'message'}
                              directValue={reqDataUpdate?.message ?? note?.message}
                            />
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mb: '1rem'
                              }}
                            >
                              <Button
                                variant="text"
                                onClick={() => {
                                  setRentNoteId(null)
                                  setReqData((prevState) => ({...prevState, message: note.message}))
                                }}
                              >
                                Cancelar
                              </Button>
                              <Button
                                variant="text"
                                endIcon={<SaveAlt/>}
                                disabled={isButtonDisabled(reqDataUpdate, note)}
                                onClick={() => {
                                  updateRentNote(rentNoteId?.editable)
                                }}
                              >
                                Salvar
                              </Button>
                            </Box>
                          </Box>
                        }


                      </Box>

                      }
                      subheader={moment(note.created_at).locale('pt-br').format('DD/MM/YYYY HH:mm')}
                      avatar={
                        <Avatar
                          sx={{bgcolor: colorByTag(note.tag)}}
                        >
                          {iconByTag(note.tag)}
                        </Avatar>
                      }
                      action={
                        <>

                          <Tooltip
                            title={rentNoteId ? 'Salve ou Cancele a edição' : 'Editar ou Excluir'}
                            arrow
                            placement={'top'}>

                            <IconButton
                              onClick={(e) => {
                                setOpenMenu(true)
                                setRentNoteId({menu: note.id, tag: note.tag})
                                setAnchor(e.currentTarget)
                                e.stopPropagation()
                              }}
                            >
                              {<MoreVert/>}
                            </IconButton>
                          </Tooltip>
                          <Menu
                            open={openMenu ?? !!openMenu}
                            anchorEl={anchor && anchor}
                            onClose={(e) => {
                              e.stopPropagation()
                              setOpenMenu(false)
                            }}
                            PaperProps={{
                              sx: {width: 200, maxWidth: '100%'},
                            }}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left'
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right'
                            }}
                          >
                            {rentNoteId?.tag === 'info' &&
                              <MenuItem
                                onClick={(e) => {
                                  setRentNoteId(({editable: rentNoteId.menu}))

                                  setOpenMenu(false)
                                  e.stopPropagation()
                                }}
                                sx={{
                                  color: 'text.secondary'
                                }}
                              >
                                <ListItemIcon>
                                  <EditOutlined/>
                                </ListItemIcon>
                                <ListItemText
                                  primary="Editar anotação"
                                  primaryTypographyProps={{variant: 'body2'}}/>
                              </MenuItem>
                            }


                            <MenuItem
                              onClick={(e) => {
                                setOpenDelete(true)
                                setOpenMenu(false)
                                e.stopPropagation()
                              }}
                              sx={{
                                color: 'text.secondary'
                              }}
                            >
                              <ListItemIcon>
                                <DeleteOutlined/>
                              </ListItemIcon>
                              <ListItemText
                                primary="Excluir anotação"
                                primaryTypographyProps={{variant: 'body2'}}/>
                            </MenuItem>


                          </Menu>
                        </>
                      }
                    />
                    {/*<CardContent>*/}
                      {/*{note?.id !== rentNoteId?.editable ?*/}
                      {/*    <Typography>*/}
                      {/*      {note?.formattedMessage ?? note?.message}*/}
                      {/*    </Typography>*/}
                      {/*  :*/}
                      {/*  <Box>*/}
                      {/*    <SimpleText*/}
                      {/*      label={'Anotação'}*/}
                      {/*      multiline*/}
                      {/*      rows={5}*/}
                      {/*      state={reqDataUpdate}*/}
                      {/*      setState={setReqDataUpdate}*/}
                      {/*      keyName={'message'}*/}
                      {/*      directValue={reqDataUpdate?.message ?? note?.message}*/}
                      {/*    />*/}
                      {/*    <Box*/}
                      {/*      sx={{*/}
                      {/*        display: 'flex',*/}
                      {/*        justifyContent: 'flex-end',*/}
                      {/*        mb: '1rem'*/}
                      {/*      }}*/}
                      {/*    >*/}
                      {/*      <Button*/}
                      {/*        variant="text"*/}
                      {/*        onClick={() => {*/}
                      {/*          setRentNoteId(null)*/}
                      {/*          setReqData((prevState) => ({...prevState, message: note.message}))*/}
                      {/*        }}*/}
                      {/*      >*/}
                      {/*        Cancelar*/}
                      {/*      </Button>*/}
                      {/*      <Button*/}
                      {/*        variant="text"*/}
                      {/*        endIcon={<SaveAlt/>}*/}
                      {/*        disabled={isButtonDisabled(reqDataUpdate, note)}*/}
                      {/*        onClick={() => {*/}
                      {/*          updateRentNote(rentNoteId?.editable)*/}
                      {/*        }}*/}
                      {/*      >*/}
                      {/*        Salvar*/}
                      {/*      </Button>*/}
                      {/*    </Box>*/}
                      {/*  </Box>*/}
                      {/*}*/}
                    {/*</CardContent>*/}
                  </Card>
                )
                }
                </>
              :
              <AnimationGlobal
                animation={notFound}
                text={'Nenhum resultado'}
                size={'200px'}
                fontSize={'24px'}
              />
            }
              </Box>

          </CardContent>
          <PaginationGlobal
            state={data}
            filters={filters}
            setFilters={setFilters}
          />

        </Card>
        :
        <Skeleton
          height={300}
          width={'100%'}
          variant='rounded'
        />
      }
      <SimpleDialog
        open={openDelete}
        setOpen={setOpenDelete}
        title={'Excluir anotação'}
        question={'Realmente quer excluir essa anotação?'}
        onClickConfirm={() => deleteRentNote(rentNoteId?.menu)}
      />
    </Grid>
  )
}

export default RentNotes