import {Autocomplete, Box, IconButton, InputAdornment, TextField} from "@mui/material";
import {Search, SearchOff} from "@mui/icons-material";
import React from "react";

const BankSelector = ({ reqData, setReqData, bankNames, defaultValue, valueProp }) => {
  const bankOptions = bankNames?.map((option, index) => ({ name: option.name , code: option.code, key: index}))

  return (
    <Box sx={{ mb: 2 }}>
      <Autocomplete
        clearIcon={<SearchOff color="primary"/>}
        size={'small'}
        fullWidth
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        disableClearable
        onChange={(_event, value) => setReqData((prevState) => ({
          ...prevState,
          bank: value.code
        }))}
        value={valueProp}
        defaultValue={defaultValue}
        options={bankOptions ?? ['']}
        getOptionLabel={(option) => `${option.name}` ?? ['']}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => (
          <li {...props} key={option.key}>
            {`${option.name}`}
          </li>
        )}
        renderInput={(params) => <TextField
          {...params}
          label="Selecionar Banco"
          InputProps={{
            ...params.InputProps,
            endAdornment:
              <InputAdornment position="start">
                <IconButton
                  onClick={() => {
                    setReqData((prevState) => ({ ...prevState, code: '' }))
                  }}
                  edge="end"
                  disabled={!!!reqData?.bank}
                >
                  {!reqData?.bank ? <Search/> : <SearchOff color="primary"/>}
                </IconButton>
              </InputAdornment>
          }}
        />}
      />
    </Box>
  )
}

export default BankSelector