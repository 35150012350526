import {Avatar, Box} from "@mui/material";
import {ReceiptOutlined} from "@mui/icons-material";

const AvatarIcon = () => {
  return (
    <Box
      my={3}
      display={'flex'}
      justifyContent={'center'}
    >
      <Avatar
        sx={{
          width: 100,
          height: 100,
          bgcolor: 'secondary.lighter',
          color: 'secondary.main'
        }}
      >
        <ReceiptOutlined
          fontSize={'large'}
        />
      </Avatar>
    </Box>
  )
}

export default AvatarIcon