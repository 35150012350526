import ComplaintTitle from "./ComplaintTitle/ComplaintTitle";
import ComplaintSubHeader from "./ComplaintSubHeader/ComplaintSubHeader";
import {Avatar, CardHeader} from "@mui/material";
import {Chat} from "@mui/icons-material";
import ComplaintActionHeader from "./ComplaintActionHeader/ComplaintActionHeader";
import React from "react";

const ComplaintUpdateHeader =
  ({
    edit,
    setEdit,
    dataUpdate,
    setDataUpdate,
    deleteComplaint,
    error
   }) => {

  return (
    <CardHeader
      title={
        <ComplaintTitle
          edit={edit}
          dataUpdate={dataUpdate}
          setDataUpdate={setDataUpdate}
        />
      }
      subheader={
        <ComplaintSubHeader
          edit={edit}
          dataUpdate={dataUpdate}
          setDataUpdate={setDataUpdate}
          error={error}
        />
      }
      avatar={
        <Avatar
          sx={{bgcolor: "primary.main"}}
        >
          <Chat />
        </Avatar>
      }
      action={
        <ComplaintActionHeader
          edit={edit}
          setEdit={setEdit}
          deleteComplaint={deleteComplaint}
        />
      }
    />
  )
}

export default ComplaintUpdateHeader