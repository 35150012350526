import {Grid, Skeleton, Typography} from "@mui/material";

const BankAccount = ({ loading, bankAccount, bankAccountDigit  }) => {
  return (
    <>
      <Grid
        item
        xs={4}
      >
        <Typography
          variant={'subtitle2'}
        >
          {'Conta'}
        </Typography>
      </Grid>
      <Grid
        item
        xs={8}
      >
        <Typography
          variant={'body2'}
        >
          {!loading
            ? <>{bankAccount}{bankAccountDigit && `-${bankAccountDigit}`}</>
            : <Skeleton
              sx={{
                mx: 'auto'
              }}
              variant={'text'}
              width={'100%'}
            />
          }
        </Typography>
      </Grid>
    </>
  )
}

export default BankAccount