import {Box, Skeleton, Typography} from "@mui/material";

const TransferValue = ({ loading, data }) => {
  const currency = (value) => new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100)

  return (
    <Box textAlign={'center'} sx={{ mb: 3 }}>
      <Typography component={'div'} variant={'h6'} sx={{ bgcolor: 'neutral.main', py: 2, borderRadius: .8 }}>
        {!loading ? currency(data?.value) : <Skeleton sx={{ mx: 'auto' }} variant={'text'} width={'30%'}/>}
      </Typography>
    </Box>

  )
}

export default TransferValue