import {Box, TextField} from "@mui/material";
import Masks from "../../../../../components/Shared/Masks";

const NameAndCpf = ({ setReqData, reqData }) => {
  return (
    <Box>
      <TextField
        label="Nome"
        fullWidth
        size={'small'}
        type="text"
        required
        value={reqData?.name ?? ''}
        onChange={(({ target: { value } }) => setReqData((prevState) => ({
          ...prevState,
          name: value.slice(0, 200)
        })))}
        sx={{ mb: 2 }}
      />
      <TextField
        label="CPF / CNPJ"
        fullWidth
        size={'small'}
        type="text"
        required
        value={reqData?.tax_number?.length <= 14 ? Masks?.cpf(reqData?.tax_number ?? '') : Masks?.cnpj(reqData?.tax_number ?? '')}
        onChange={(({ target: { value } }) => setReqData((prevState) => ({
          ...prevState,
          tax_number: value ?? ''
        })))}
        sx={{ mb: 2 }}
      />
    </Box>
  )
}

export default NameAndCpf