import Box from '@mui/material/Box'
import Header from '../Header'
import { Outlet } from 'react-router-dom'
import SideBar from '../SideBar'
import * as React from 'react'
import { CssBaseline } from '@mui/material'
import AdminHeader from './AdminHeader'

const drawerWidth = 250

const AdminBase = () => {
  const [mobileMenu, setMobileMenu] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileMenu(!mobileMenu)
  }

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>

      <CssBaseline/>
      {/*<SideBar mobileMenu={mobileMenu} handleDrawerToggle={handleDrawerToggle}/>*/}
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          width: { md: `calc(100% - ${drawerWidth}px)` },
          overflow: 'auto',
          minHeight: '100%',
          padding: 1,
          paddingBottom: theme.spacing(10),
          [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(2),
            paddingTop: theme.spacing(3),
          }
        })}
      >
        <AdminHeader handleDrawerToggle={handleDrawerToggle}/>
        <Outlet/>
      </Box>
    </Box>
  )
}
export default AdminBase