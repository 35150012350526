import {Box, Checkbox, FormControlLabel, Typography} from "@mui/material";
import moment from "moment";
import React from "react";

const MaintenanceCheck = ({string, state, setState, disabled}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        m: '0.3rem',
        mb: '1rem'
      }}
    >
      <Typography>
        {string}
      </Typography>
      <FormControlLabel
        disabled={disabled}
        checked={!!state?.end_date}
        control={<Checkbox
          sx={{ml: '1rem'}}
          onChange={(event, checked) =>
            setState((prevState) => ({
              ...prevState,
              end_date: !checked ? null : moment(state?.start_date).format('YYYY-MM-DD')
            }))}
        />}
        label="Sim" />
    </Box>
  )
}

export default MaintenanceCheck