import {TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import React, {useContext} from "react";
import Context from "../../../contexts/Context";

const RentRecipientTableHead = () => {
  const {filters, setFilters} = useContext(Context)

  const handleFilter = (asc: string, desc: string) => {
    setFilters(prevState => ({
        ...prevState,
        ordering: filters?.ordering === asc ? desc : asc
      })
    )
  }


  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{ color: 'primary.main' }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-name' || filters?.ordering === 'name'}
            direction={filters?.ordering === '-name' ? 'desc' : 'asc'}
            onClick={() => handleFilter('name', '-name') }
          >

            Nome
          </TableSortLabel>
        </TableCell>

        <TableCell
          sx={{ color: 'primary.main' }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-cpf_cnpj' || filters?.ordering === 'cpf_cnpj'}
            direction={filters?.ordering === '-cpf_cnpj' ? 'desc' : 'asc'}
            onClick={() => handleFilter('cpf_cnpj', '-cpf_cnpj') }
          >
            CPF / CNPJ
          </TableSortLabel>
        </TableCell>

        <TableCell>
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-phone' || filters?.ordering === 'phone'}
            direction={filters?.ordering === '-phone' ? 'desc' : 'asc'}
            onClick={() => handleFilter('phone', '-phone')}
          >

            Telefone
          </TableSortLabel>
        </TableCell>
        <TableCell
          // align={'left'}
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-email' || filters?.ordering === 'email'}
            direction={filters?.ordering === '-email' ? 'desc' : 'asc'}
            onClick={() => handleFilter('email', '-value')}
          >
            E-mail
          </TableSortLabel>
        </TableCell>
        <TableCell
          // align={'center'}
          sx={{ color: 'primary.main', width: '100px' }}
        >
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default RentRecipientTableHead
