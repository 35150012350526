import React, {
  useEffect,
  useState
} from "react";
import {
  Autocomplete,
  TextField
} from "@mui/material";
import {api} from "../../../../../../services/Main/Api";

const ComplaintCategorySelector = ({
    state,
    setState,
    keyName,
    error,
    styles,
    variant
  }) => {

  const [categories, setCategories] = useState(null)

  useEffect(function getCategories(){
    api.get(`/complaint/category/`)
      .then(response => {
        setCategories(response.data.results)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <Autocomplete
      fullWidth
      size={'small'}
      sx={styles}
      onChange={(e, value) => setState((prevState) => ({...prevState, [keyName]: value ?? ''}))}
      name={'unit'}
      defaultValue={state?.[keyName] ?? ''}
      renderInput={(props) => <TextField
        {...props}
        required
        label={'Categoria'}
        error={!!error?.category}
        helperText={error?.category ? error.category : ''}
        variant={variant}

      />}
      getOptionLabel={(option) => option ?? ['']}
      options={categories?.map((option) => (option.description)) ?? ['']}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      />
  )
}

export default ComplaintCategorySelector