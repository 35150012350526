
import React, {
    useContext,
    useEffect,
    useState
} from "react";
import {
    Box, Button,
    Card, Chip,
    Container,
    Grid, IconButton, Link,
    Skeleton,
    Table, TableBody,
    TableContainer, TableHead, TableRow, TableSortLabel, Modal, InputAdornment, TextField, Tooltip, GlobalStyles,
} from "@mui/material";
import {AddCircle, Close, PictureAsPdf, Search} from "@mui/icons-material";
import {Helmet} from "react-helmet";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {api} from "../../../services/Main/Api";
import {getCondoId} from "../../../services/Main/AuthStorage";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import TableCell from "@mui/material/TableCell";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import moment from "moment";
import {useSnackbar} from "notistack";
import {useParams, useSearchParams} from "react-router-dom";
import Payment from "./Payment";
import Masks from "../../../components/Shared/Masks";
import AuthValidation from "../../../components/Shared/AuthValidation";
import Context from "../../../contexts/Context";
import PaymentChecker from "./components/PaymentChecker";

const PaymentList = () => {
    const [filters, setFilters] = useState(
        {
            condo_id: getCondoId(),
            page: 1,
            page_size: 50,
        }
    )
    const { sessionCheck, dataProvider } = useContext(Context)
    const [open, setOpen] = useState(false);
    const [showAuthValidation, setShowAuthValidation] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClick = () => {
        setShowAuthValidation(true);
        !dataProvider?.validation && sessionCheck();
        if (showAuthValidation) {
            dataProvider?.validation && handleOpen();
        }
    };

    const handleClose = () => {setOpen(false); setShowAuthValidation(false);}
    const [data, setData] = useState(null)
    const [createEvent, setCreateEvent] = useState(null)
    const {enqueueSnackbar} = useSnackbar()
    const [search, setSearch] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(()=>{
        let params = { ...searchParams}
        if (search){
            params.search = search
        }else{
            delete params.search
        }
        setSearchParams(params)
    }, [search])

    async function fetchData() {
        let filter = {...filters}
        if (searchParams.get("search")){
            filter = {...filter, search: searchParams.get("search")}
        }
        try {
            if (filters?.condo_id) {
                const response = await api.get('/payment/', { params: filter });
                setData(response.data);
            }

        } catch (error) {
            console.error(error);
        }
    }


    function cancelPayment(paymentItem){
        const data = {
            document_number: paymentItem?.document_number,
            condo_id: paymentItem?.condo
        }
        api.post(`/payment/cancel/`, data)
            .then(response => {
                enqueueSnackbar('Pagamento deletado', { variant: 'success' })
            })
            .catch(error =>{
                console.log(error)
                enqueueSnackbar('Erro ao deletar Pagamento', { variant: 'error' })
            })
    }

    useEffect(function whenPageLoad (){
        fetchData()
    },[createEvent, filters])

    useEffect(() => {
        fetchData()
    }, [searchParams])

    let typingTimer  = ""

    const handleFilter = (asc: string, desc: string) => {
        setFilters(prevState => ({
                ...prevState,
                ordering: filters?.ordering === asc ? desc : asc
            })
        )
    }

    useEffect(() => {
        if (showAuthValidation) {

                dataProvider?.validation && handleOpen();

        }
    }, [handleClick]);




    return(
        <>
            {/*<AuthValidation />*/}
            <Helmet>
                <title>Pagamentos - Bloco B3</title>
            </Helmet>
            <Container
                maxWidth={false}
            >
                <GlobalStyles styles={{
                    '.MuiInputBase-input': {
                        textAlign: 'center',
                    }
                }}
                />
                <TitleBtnList
                    title={'Pagamentos'}
                    icon={<AddCircle />}
                    onClick={handleClick}
                    buttonText={'Pagar Boleto'}
                />
                <AuthValidation />

                {dataProvider?.validation === true &&
                <Dialog open={open}
                        onClose={handleClose}
                        fullWidth={true}
                        maxWidth={'md'}
                        aria-labelledby="dialog-title">
                    <DialogContent>
                        <Payment />
                    </DialogContent>
                </Dialog>
                }
                <Card>
                    {data?.results ?
                        <>

                            <Grid container sx={{ p: 2, alignItems: 'center' }} spacing={1}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    lg={3}
                                >
                                    <TextField id="outlined-basic" label="Buscar por Nome" variant="outlined" sx={{width: "25rem", marginBottom: '2rem'}}
                                               onKeyUp={(event)=>{
                                                   clearTimeout(typingTimer);
                                                   typingTimer = setTimeout(()=> setSearch(event.target.value), 1000);
                                               }}
                                               size={'small'}
                                               InputProps={{
                                                   endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,
                                               }}
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer>
                                {data?.results?.length > 0 ?
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell  align="center"><TableSortLabel
                                                    style={{ color: '#DD1960' }}
                                                    active={filters?.ordering === '-id' || filters?.ordering === 'id'}
                                                    direction={filters?.ordering === '-id' ? 'desc' : 'asc'}
                                                    onClick={() => handleFilter('id', '-id') }
                                                >
                                                    Nome
                                                </TableSortLabel></TableCell>
                                                <TableCell  align="center"><TableSortLabel
                                                    style={{ color: '#DD1960' }}
                                                    active={filters?.ordering === '-id' || filters?.ordering === 'id'}
                                                    direction={filters?.ordering === '-id' ? 'desc' : 'asc'}
                                                    onClick={() => handleFilter('id', '-id') }
                                                >
                                                    Data de Vencimento
                                                </TableSortLabel></TableCell>

                                                <TableCell  align="center"><TableSortLabel
                                                    style={{ color: '#DD1960' }}
                                                    active={filters?.ordering === '-id' || filters?.ordering === 'id'}
                                                    direction={filters?.ordering === '-id' ? 'desc' : 'asc'}
                                                    onClick={() => handleFilter('id', '-id') }
                                                >
                                                    Data de Pagamento
                                                </TableSortLabel></TableCell>
                                                <TableCell align="center"><TableSortLabel
                                                    style={{ color: '#DD1960' }}
                                                    active={filters?.ordering === '-id' || filters?.ordering === 'id'}
                                                    direction={filters?.ordering === '-id' ? 'desc' : 'asc'}
                                                    onClick={() => handleFilter('id', '-id') }
                                                >
                                                    Usuário
                                                </TableSortLabel></TableCell>
                                                <TableCell  align="center"><TableSortLabel
                                                    style={{ color: '#DD1960' }}
                                                    active={filters?.ordering === '-id' || filters?.ordering === 'id'}
                                                    direction={filters?.ordering === '-id' ? 'desc' : 'asc'}
                                                    onClick={() => handleFilter('id', '-id') }
                                                >
                                                    Status
                                                </TableSortLabel></TableCell>
                                                <TableCell  align="center"><TableSortLabel
                                                    style={{ color: '#DD1960', textAlign: 'center'}}
                                                    active={filters?.ordering === '-id' || filters?.ordering === 'id'}
                                                    direction={filters?.ordering === '-id' ? 'desc' : 'asc'}
                                                    onClick={() => handleFilter('id', '-id') }
                                                >
                                                    Comprovante
                                                </TableSortLabel></TableCell>
                                                <TableCell  align="center"><TableSortLabel
                                                    style={{ color: '#DD1960' }}
                                                    active={filters?.ordering === '-id' || filters?.ordering === 'id'}
                                                    direction={filters?.ordering === '-id' ? 'desc' : 'asc'}
                                                    onClick={() => handleFilter('id', '-id') }
                                                >
                                                    Valor
                                                </TableSortLabel></TableCell>
                                                <TableCell  align="center"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.results.map((item) => (
                                                <TableRow key={item.id}>
                                                    <TableCell>{item.number}</TableCell>
                                                    <TableCell  align="center">{item.bank_concessionare_favored}</TableCell>
                                                    <TableCell  align="center">{item.due_date
                                                        ? moment(item.due_date).format('DD/MM/YYYY') : '-'
                                                    }</TableCell>
                                                    <TableCell  align="center">{item.payment_date
                                                        ? moment(item.payment_date).format('DD/MM/YYYY') : '-'
                                                    }</TableCell>
                                                    <TableCell  align="center">{item.user?.full_name}</TableCell>
                                                    <TableCell>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                        >

                                                                <Chip
                                                                    color={item.status.value === 5 ? 'success' :
                                                                        item.status.value === 7 ? 'error' :
                                                                            item.status.value === 3 ? 'warning' :
                                                                            item.status.value === 2 ? 'warning' :
                                                                            item.status.value === 0 ? 'info' : 'info'

                                                                }
                                                                    size="small"
                                                                    variant="outlined"
                                                                    label={item?.status.label}/>


                                                        </Box>
                                                    </TableCell>

                                                    <TableCell
                                                        align="center"
                                                    >
                                                        {item?.receipt?.length > 0 ?
                                                            <Link
                                                                href={item?.receipt}
                                                                target="_blank"
                                                                onClick={(e) => e.stopPropagation()}
                                                            >
                                                                <IconButton>
                                                                    <PictureAsPdf/>
                                                                </IconButton>
                                                            </Link>
                                                            :
                                                            <Close sx={{ color: 'error.main'}} />
                                                        }
                                                    </TableCell>
                                                    <TableCell  align="center"> {Masks.money(parseFloat(item.principal_value).toFixed(2))}</TableCell>
                                                    <TableCell>
                                                        {item.status.value === 0 &&
                                                            <Box textAlign={'start'} mt={2}>
                                                                <Tooltip title="Cancelar Pagamento">
                                                                    <Link
                                                                        fontSize={'medium'}
                                                                        onClick={() => cancelPayment(item)}
                                                                    >
                                                                        <CancelOutlinedIcon fontSize="medium"/>
                                                                    </Link>
                                                                </Tooltip>
                                                            </Box>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    :
                                    <NoResultsAnimation />
                                }

                            </TableContainer>
                        </>
                        :
                        <Skeleton
                            variant="rounded"
                            height={500}
                            animation="pulse"
                        />

                    }
                </Card>
                <PaginationGlobal
                    state={data}
                    filters={filters}
                    setFilters={setFilters}
                />
            </Container>
        </>

    )
}

export default PaymentList;