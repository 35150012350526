import React, {
  useEffect,
  useState
} from "react";
import {
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import moment from "moment/moment";
import Masks from "../../../../../../../components/Shared/Masks";
import {useParams} from "react-router-dom";

const ExpenseSummaryInstallValue = ({
    state,
    gasWaterTotalValue,
    installments
  }) => {

  const [dueDate, setDueDate] = useState(null)

  const param = useParams()
  const date_installment = `${state.billing_year}-${state.billing_month}`;

  useEffect(() => {
    if (!param.id) {
      const month = state?.billing_month;
      const year = state?.billing_year;

      const date = moment(`${year}-${month}-01`, 'YYYY-MM-DD');

      const formattedDate = date.format('YYYY-MM-DD');

      setDueDate(formattedDate);
    }
  }, [state?.billing_month, state?.billing_year]);


  const totalFormat = state?.total?.toString()?.replace(/\D+/g, "")

  return (
    <>
      {state?.category !== 'DISCOUNT' && (
        <TableRow>
          <TableCell align={'right'}>
            {!state.installment ? <Typography variant={'small'}>
              {'À vista em'}: { !param.id && !state.installment ? `${moment(date_installment).format('MM/YYYY')}` : `${moment(date_installment).format('MM/YYYY')}` }
            </Typography> : <Typography variant={'small'}>
              {1}ª Parc. em: {moment(date_installment).format('MM/YYYY')}
            </Typography>}
          </TableCell>
          <TableCell>
            <Typography variant={'small'} sx={{ fontWeight: 'bold' }}>
              {state?.category === 'GAS' ? Masks.money(String(gasWaterTotalValue > 0 ? Math.floor(gasWaterTotalValue / installments) : 0)) : Masks.money(String(totalFormat > 0 ? Math.floor(totalFormat / installments) : 0))}
            </Typography>
          </TableCell>
        </TableRow>)}
    </>
  )
}

export default ExpenseSummaryInstallValue