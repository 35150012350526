import React, { useContext, useEffect, useState } from 'react'
import {
  Backdrop,
  Box,
  Card,
  Chip,
  Container,
  Grid, InputAdornment,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { api } from '../../../../services/Main/Api'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import CircularProgress from '@mui/material/CircularProgress'
import Context from '../../../../contexts/Context'

import NoResultsAnimation from '../../../../components/Shared/animations/NoResultsAnimation'
import { Alarm, Search } from '@mui/icons-material'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Masks from '../../../../components/Shared/Masks'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import PaginationGlobal from "../../../../components/Shared/PaginationGlobal";
import {getCondoId} from "../../../../services/Main/AuthStorage";

const RentContractAdminList = () => {
  const navigate = useNavigate()
  const [response, setResponse] = useState(null)
  const [monthInput, setMonthInput] = useState(moment().format("MM"))
  const [yearInput, setYearInput] = useState(moment().format("YYYY"))
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState(
      {
        condo_id: getCondoId(),
        page: 1,
        page_size: 50,
      }
  )
  const monthFormat = moment().format("MM")
  const yearFormat = moment().format("YYYY")

  useEffect(()=>{
    let params = { ...searchParams,}
    if (search){
      params.search = search
    }else{
      delete params.search
    }
    setSearchParams(params)
  }, [monthInput, yearInput, search])

  function getData(){
    setLoading(true)
    // let month = monthFormat
    // let year = yearFormat

    // if (searchParams.get("month") && searchParams.get("year") ){
    //   month = searchParams.get("month")
    //   year = searchParams.get("year")
    // }

    let filter = {...filters}

    if (searchParams.get("search")){
      filter = {...filter, search: searchParams.get("search")}
    }

    filter = {...filter, ordering: '-id'}

    api.get(`rent_contract/`, {params: filter})
      .then(response => {
        const data = response.data
        setResponse(data)
        setLoading(false)
      }).catch(error => {
        console.log(error)
        setLoading(false)
    })
  }


  useEffect(() => {

      getData()

  }, [])

  useEffect(() => {
    getData()
  }, [searchParams])
    useEffect(function whenPageLoad (){
        getData()
    },[filters])


    let typingTimer  = ""

  return (
    <>
      <Helmet>
        <title>Contratos - Bloco B3</title>
      </Helmet>

      <Backdrop
        sx={{ color: '#fff', zIndex: 1500, }}
        open={loading}
      >

        <CircularProgress color="inherit"/>
      </Backdrop>
      <Container maxWidth={false}>
        <Box
          sx={{
            display: 'flex',
            '@media (max-width:600px)': {
              display: 'grid',
              gridTemplateColumns: 'repeat(1, 1fr)',
              gap: 3,
            },
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,

          }}
        >
          <Typography
            textAlign={'center'}
            variant={'h5'}
          >
            Contratos ({response?.count || 0})
          </Typography>
          <Box  sx={{textAlign: "right"}}>
            <TextField id="outlined-basic" label="Buscar por beneficiário" variant="outlined" sx={{marginRight: ".5rem"}}
            onKeyUp={(event)=>{
              clearTimeout(typingTimer);
              typingTimer = setTimeout(()=> setSearch(event.target.value), 1000);
            }}
            size={'small'}
           InputProps={{
             endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,
           }}
            />
            {/*<DatePicker*/}
            {/*  openTo="month"*/}
            {/*  views={['year', 'month']}*/}
            {/*  label="Mês de Pagamento"*/}
            {/*  inputProps={{ readOnly: true }}*/}
            {/*  disableMaskedInput*/}

            {/*  value={moment(`${monthInput}-${yearInput}`, 'MM-YYYY').format()}*/}
            {/*  onChange={(newValue) => {*/}
            {/*    setMonthInput(moment(new Date(newValue)).format("MM"))*/}
            {/*    setYearInput(moment(new Date(newValue)).format("YYYY"))*/}
            {/*  }}*/}
            {/*  renderInput={(params) =>*/}
            {/*    <TextField*/}
            {/*      size={'small'}*/}
            {/*      {...params}*/}
            {/*    />}*/}
            {/*/>*/}
          </Box>
        </Box>


        <Card>
          {response?.results ?
            <>

              <Grid container sx={{ p: 2, alignItems: 'center' }} spacing={1}>
                <Grid item xs={12} sm={4} lg={3}>
                  {/*<UnitFilter />*/}
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  {/*<ReferenceMonthFilter*/}
                  {/*  response={response}*/}
                  {/*/>*/}
                </Grid>
              </Grid>

              <TableContainer>
                {response?.results?.length > 0  ?
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome</TableCell>

                        <TableCell>Empresa</TableCell>
                        <TableCell>Criado em</TableCell>
                        <TableCell >Status</TableCell>
                        <TableCell>Total</TableCell>


                    </TableRow>
                    </TableHead>
                    <TableBody>


                    { response.results.map(item =>
                      <TableRow
                        key={item.id}
                        hover
                        sx={{'cursor': 'pointer'}}

                        onClick={(e) => {
                          e.stopPropagation()
                          navigate(`/contratos/${item.id}`)
                        }}
                      >

                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {item.number} - {item.name}
                          </Box>
                        </TableCell>


                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {item.condo}
                          </Box>
                        </TableCell>

                        <TableCell>
                          {moment(item.created_at).format('DD/MM/YYYY')}
                        </TableCell>

                        <TableCell>{item.status}</TableCell>
                        <TableCell>{Masks.money(item.value.toString())}</TableCell>
                      </TableRow>
                    )}

                    </TableBody>
                  </Table>
                  :
                  <NoResultsAnimation />
                }
              </TableContainer>
            </>
            :
            <Skeleton
              variant="rounded"
              height={700}
              animation="pulse"
            />
          }
        </Card>

      </Container>
      {response?.count > 0 &&
        <PaginationGlobal
          state={response}
          filters={filters}
          setFilters={setFilters}
        />
      }
    </>
  )
}

export default RentContractAdminList
