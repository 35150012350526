import {
  Alert,
  Box,
  CardContent,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Table,
  TableContainer,
  Skeleton,
  useMediaQuery, TextField
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {getCondoId, getFilterDate, setFilterDate} from "../../../../services/Main/AuthStorage";
import {api} from "../../../../services/Main/Api";
// import TransferForm from "./TransferForm";
import SearchInput from "../../../../components/Filters/SearchInput";
import NoResultsAnimation from "../../../../components/Shared/animations/NoResultsAnimation";
import {useParams} from "react-router-dom";
import Context from "../../../../contexts/Context";
import PaginationGlobal from "../../../../components/Shared/PaginationGlobal";
import TransferForm from "../../BankOperations/components/BankTransferList/TransferForm";
import HistoryTableHead from "../HistoryTableHead";
import HistoryTableBody from "../HistoryTableBody";
import TransferDetail from "./TransferDetail";
import moment from "moment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";


const RecipientTransferHistory = (props) => {
  const params = useParams()
  const { dataProvider } = useContext(Context)
  const sizeMatch = useMediaQuery('@media (min-width:600px)')
  const storageDate = getFilterDate()

  const [data, setData] = useState(null)
  const [transferOpen, setTransferOpen] = useState(false)
  const [filters, setFilters] = useState({
    page_size: 50,
    transferType: 'ted',
    rent_recipient__account_id: +getCondoId(),
    ordering: '-created_at',
    rent_contract_id: params?.id ?? dataProvider?.rentId,
    month: moment(storageDate).format('MM'),
    year: moment(storageDate).format('YYYY')
  })

  useEffect(function listTransfers () {
    if (filters?.rent_recipient__account_id) {
      api.get(`rent_recipient/transfer/history/`, {params: filters})
        .then(response => {
          setData(response.data)
        })
        .catch(error => {
          console.log(error)
        })
    }

  }, [filters?.search, filters?.status, filters?.ordering, filters?.page, filters?.month, filters?.year])





  return(
    <>
      <CardContent
        sx={{
          paddingX: '2rem',
          mb: '1rem',
          width: '100%',
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent={'space-between'}
        >
          <Grid item xs={12}>
            {/*<ToggleButtonGroup*/}
            {/*  fullWidth*/}
            {/*  exclusive*/}
            {/*  value={filters?.transferType}*/}
            {/*  color={'primary'}*/}
            {/*  onChange={(e, value) => {*/}
            {/*    setData(null)*/}
            {/*    setFilters(prevState => ({...prevState, transferType: value}))*/}
            {/*  }}*/}
            {/*  sx={{*/}
            {/*    mb: '2rem'*/}

            {/*  }}*/}
            {/*>*/}
            {/*  <ToggleButton*/}
            {/*    value={"ted"}*/}
            {/*    selected={filters?.transferType === 'ted'}*/}
            {/*  >*/}
            {/*    TED*/}
            {/*  </ToggleButton>*/}
            {/*  <ToggleButton*/}
            {/*    value={"pix"}*/}
            {/*    selected={filters?.transferType === 'pix'}*/}
            {/*  >*/}
            {/*    Pix*/}
            {/*  </ToggleButton>*/}
            {/*</ToggleButtonGroup>*/}

            {data ?
              <>

                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: sizeMatch ? 'row' : 'column',
                        alignItems: sizeMatch ? 'center' : '',
                        mb: '2rem'
                      }}
                    >
                      <SearchInput
                        label={'Procurar por nome'}
                        styles={{
                          maxWidth: '300px',
                          mb: sizeMatch ? 'auto' : '1rem',
                          mr: sizeMatch ? '0.5rem' : 'auto'
                        }}
                      />

                      <DatePicker
                        label="Data - Mês/Ano"
                        openTo="month"
                        views={['year', 'month',]}
                        value={storageDate}
                        clearLabel
                        onChange={(newValue) => {
                          setFilters(prevState => ({...prevState, month: moment(new Date(newValue)).format('MM')}))
                          setFilters(prevState => ({...prevState, year: moment(new Date(newValue)).format('YYYY')}))
                          setFilterDate(moment(new Date(newValue)).format())
                        }}
                        renderInput={(params) =>
                          <TextField
                            size="small"
                            {...params}
                          />}
                      />

                      {/*<ToggleButtonGroup*/}
                      {/*  aria-label="Selecionar uma opção"*/}
                      {/*  fullWidth*/}
                      {/*  size={'small'}*/}
                      {/*  exclusive*/}
                      {/*  value={filters?.transferType}*/}
                      {/*  color={'primary'}*/}
                      {/*  onChange={(e, value) => {*/}
                      {/*    setData(null)*/}
                      {/*    setFilters(prevState => ({...prevState, status: value}))*/}
                      {/*  }}*/}
                      {/*  sx={{*/}
                      {/*    maxWidth: '300px'*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  <ToggleButton*/}
                      {/*    value={''}*/}
                      {/*    selected={filters?.status === ''}*/}
                      {/*  >*/}
                      {/*    Todas*/}
                      {/*  </ToggleButton>*/}
                      {/*  <ToggleButton*/}
                      {/*    value={*/}
                      {/*      filters?.transferType === 'ted' ? 0 :*/}
                      {/*        filters?.transferType === 'pix' && 'Created'*/}
                      {/*    }*/}
                      {/*    selected={*/}
                      {/*      filters?.transferType === 'ted' ? filters?.status === 0 :*/}
                      {/*        filters?.transferType === 'pix' && filters?.status === 'Created'*/}
                      {/*    }*/}
                      {/*  >*/}
                      {/*    Agendadas*/}
                      {/*  </ToggleButton>*/}
                      {/*</ToggleButtonGroup>*/}
                    </Box>
                  </>


                  <Box>
                    <Alert
                      // variant={'filled'}
                      severity={'info'}
                      sx={{
                        mb: '1rem',
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        padding: 0
                      }}
                    >
                      {'Transferências agendadas podem ser canceladas'}
                    </Alert>
                  </Box>

                <TableContainer>
                  {data?.results?.length > 0 ?
                    <Table>
                      <HistoryTableHead
                        filters={filters}
                        setFilters={setFilters}

                      />
                      <HistoryTableBody
                        state={data}
                        setFilters={setFilters}
                        setTransferOpen={setTransferOpen}
                      />
                    </Table>
                    : filters?.transferType &&
                    <NoResultsAnimation />
                  }
                </TableContainer>
              </>
              :
              <Skeleton
                variant="rounded"
                height={500}
                animation="pulse"
                sx={{
                  mb: '1.5rem'
                }}
              />

            }
          </Grid>
        </Grid>
      </CardContent>

      <TransferDetail
        transferOpen={transferOpen}
        setTransferOpen={setTransferOpen}
        filters={filters}
        setFilters={setFilters}
      />

      <PaginationGlobal
        state={data}
        filters={filters}
        setFilters={setFilters}
      />


    </>
  )
}

export default RecipientTransferHistory

