import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React, {useContext, useEffect, useState} from "react";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import SimpleText from "../../../components/Shared/SimpleText";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {getCondoId, getFilterDate, setFilterDate} from "../../../services/Main/AuthStorage";
import UnitFilter from "../../../components/Filters/UnitFilter";
import {Box} from "@mui/system";
import DateAndTime from "../../../components/BookingAndMaintenance/DateAndTime";
import {api} from "../../../services/Main/Api";
import {useSnackbar} from "notistack";
import {useParams} from "react-router-dom";
import Context from "../../../contexts/Context";
import SimpleTypography from "../../../components/Shared/SimpleTypography";
import Masks from "../../../components/Shared/Masks";
import {Close, Delete, Edit, Person} from "@mui/icons-material";
import ButtonSet from "../../../components/Shared/ButtonSet";

const VisitorForm = (props) => {
  const {
    openVisitor,
    setOpenVisitor,
  }
    = props

  const { dataProvider, setDataProvider, unitOptions } = useContext(Context)



  const {enqueueSnackbar} = useSnackbar()

  const [reqData, setReqData] = useState(null)
  const [edit, setEdit] = useState(false)
  const [error, setError] = useState(null)
  const [timePickerEntry, setTimePickerEntry] = useState(moment().locale('pt-br').format('LT'))
  const [timePickerExit, setTimePickerExit] = useState(moment().locale('pt-br').format('LT'))
  const [openDelete, setOpenDelete] = useState(false)
  const [reqLoading, setReqLoading] = useState(false)
  const [loading, setLoading] = useState(false)



  function getVisitors () {
    if(dataProvider?.isUpdate && openVisitor){
      // setFilters(prevState => ({...prevState, loadVisitor: true }))
      api.get(`management/visitor/${dataProvider?.id}/`)
        .then(response =>{
          setReqData(response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          setDataProvider(prevState => ({...prevState, loadVisitor: false }))
        })
    }
  }

  useEffect(function getInfo(){
    getVisitors()
  },[dataProvider?.isUpdate])

  useEffect(function ifNoData(){
    !reqData?.entry_date && setReqData(prevState => ({...prevState,  entry_date: moment(entryDate()).format()}))
    !reqData?.exit_date && setReqData(prevState => ({...prevState,  exit_date: moment(exitDate()).format()}))
  },[openVisitor])

  const resetFilters = () => {
    setDataProvider(prevState => ({
      ...prevState,
      isCreate: false,
      isUpdate: false,
      loadDelivery: false,
      loadVisitor: false,
      id: false,
    }))
  }



  const entryDate = () => {
    const formatEntryDate = moment(reqData?.entry_date).format('YYYY-MM-DD')
    const finalEntryDate = formatEntryDate + ' ' + timePickerEntry
    return moment(finalEntryDate)
  }

  const exitDate = () => {
    const formatExitDate = moment(reqData?.exit_date).format('YYYY-MM-DD')
    const finalExitDate = formatExitDate + ' ' + timePickerExit
    return moment(finalExitDate)
  }


  async function saveReq(){
    setLoading(true)
    setReqLoading(true)

    const data = {
      ...reqData,
      condo: +getCondoId(),
      entry_date: moment(entryDate()).format(),
      exit_date: moment(exitDate()).format(),
      cpf_visitor: +reqData?.cpf_visitor?.toString()?.replace(/[^\d]+/g, "")
    }



    function alternateReq() {
      if (dataProvider?.isUpdate) {
        return api.put(`/management/visitor/${dataProvider.id}`, data)
      } else {
        return api.post(`/management/visitor/`, data)
      }
    }

    try {
      const response = await alternateReq();
      setEdit(false);
      setOpenVisitor(false)
      setDataProvider(prevState => ({...prevState, requestDone: !dataProvider?.requestDone}))
      // setDataProvider(prevState => ({ ...prevState, openBill: false }));
      if (dataProvider?.isUpdate) {
        enqueueSnackbar('Visitante atualizado', { variant: 'success' });
      } else {
        enqueueSnackbar('Visitante cadastrado', { variant: 'success' });
      }
      setError(null);
    } catch (error) {
      setError(error.response.data);
      if (dataProvider?.isCreate) {
        enqueueSnackbar('Erro ao atualizar visitante', { variant: 'error' });
      } else {
        enqueueSnackbar('Erro ao cadastrar visitante', { variant: 'error' });
      }
      console.log(error);
    } finally {
      setLoading(false)
      setReqLoading(false)
    }
  }


  // const create = () => {
  //
  //   if (filters?.isCreate) {
  //     setReqLoading(true)
  //     api.post(`/management/visitor/`, data)
  //       .then(response =>{
  //         setEdit(false)
  //         setOpenVisitor(false)
  //         setReqData(null)
  //         setFilters(prevState => ({...prevState, requestDone: !filters?.requestDone}))
  //         enqueueSnackbar('Visitante cadastradado', { variant: 'success' })
  //         resetFilters()
  //
  //       })
  //       .catch(error => {
  //         setError(error.response.data)
  //         enqueueSnackbar('Erro ao cadastrar visitante', { variant: 'error' })
  //         console.log(error)
  //       })
  //       .finally(()=> {
  //         setReqLoading(false)
  //       })
  //   }
  // }
  //
  // const update = () => {
  //   if (filters?.isUpdate) {
  //     setReqLoading(true)
  //     api.put(`/management/visitor/${filters.id}`, data)
  //       .then(response =>{
  //         setFilters(prevState => ({...prevState, requestDone: !filters?.requestDone}))
  //         setEdit(false)
  //         // setOpenVisitor(false)
  //         // setReqData(null)
  //         // resetFilters()
  //         enqueueSnackbar('Visitante atualizadado', { variant: 'success' })
  //
  //       })
  //       .catch(error => {
  //         enqueueSnackbar('Erro ao atualizar visitante', { variant: 'error' })
  //         console.log(error)
  //       })
  //       .finally(()=> {
  //         setReqLoading(false)
  //       })
  //   }
  // }

  const deleteReq = () => {
    setReqLoading(true)
    api.delete(`/management/visitor/${dataProvider.id}`)
      .then(response =>{
        setDataProvider(prevState => ({...prevState, requestDone: !dataProvider?.requestDone}))

        setEdit(false)
        setReqData(null)
        setOpenVisitor(false)

        enqueueSnackbar('Visitante deletado', { variant: 'success' })
        resetFilters()

      })
      .catch(error => {
        enqueueSnackbar('Erro ao excluir visitante', { variant: 'error' })
        console.log(error)
      })
      .finally(()=> {
        setReqLoading(false)
      })

  }

  const enableButton = reqData?.name && reqData?.entry_date

  function cpfFormat() {
    if (reqData?.cpf_visitor){
      return Masks?.cpfOrCnpj(reqData?.cpf_visitor?.toString() ?? '')
    } else {
      return <Close sx={{color: 'error.main'}}/>
    }
  }

  useEffect(function whenModalClose(){
    if (openVisitor === false){
      setLoading(true)
      setReqData(null)
      // setDataProvider(prevState => ({ ...prevState, documentId: null}))
      setDataProvider(prevState => ({...prevState, isUpdate: false, isCreate: false}))
    }

  },[openVisitor])

  return (
    <>

      <SimpleDialog
        open={openDelete}
        setOpen={setOpenDelete}
        title={'Excluir visitante'}
        question={'Realmente quer excluir esse visitante?'}
        functionProp={deleteReq}
      />
      <SimpleDialog
        title={dataProvider?.isCreate? 'Cadastro de visitante' : 'Atualização de visitante'}
        titleIcon={<Person/>}
        open={openVisitor}
        setOpen={setOpenVisitor}
        disabled={!enableButton}
        removeConfirm={!!dataProvider?.id}
        onClickConfirm={!dataProvider?.id ? () => {
            saveReq()
          } : false
        }
        onClickCancel={() => {
          setDataProvider(prevState => ({...prevState, loadDelivery: true }))
          setOpenVisitor(false)
          resetFilters()
          setReqData(null)
          setEdit(false)

        }}
        actionMenu={
        <>
          {dataProvider?.id &&
            <ButtonSet
              editState={edit}
              editClick={() => {
                dataProvider?.isUpdate && setEdit(true)
              }}
              deleteClick={() => setOpenDelete(true)}
              saveClick={() => {
                dataProvider?.isUpdate && saveReq()
              }}
              cancelClick={() => {
                getVisitors()
                setEdit(false)
              }}
            />
          }
        </>
        }
        dialogContent={
          <>

          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={reqLoading}
          >
            <CircularProgress color="inherit"/>
          </Backdrop>

            {edit || dataProvider?.isCreate ?
              <>

                <ToggleButtonGroup
                  fullWidth
                  exclusive
                  value={reqData?.authorized}
                  color={'primary'}
                  onChange={(e, value) => setReqData(prevState => ({
                    ...prevState,
                    authorized: value
                  }))}
                  sx={{mb: '1rem'}}
                >
                  <ToggleButton
                    value={false}
                    selected={reqData?.authorized === false || !reqData?.authorized}
                  >
                    Não autorizado
                  </ToggleButton>
                  <ToggleButton
                    value={true}
                    selected={reqData?.authorized === true}
                  >
                    Autorizado
                  </ToggleButton>
                </ToggleButtonGroup>
                <SimpleText
                  label={'Visitante'}
                  required
                  state={reqData}
                  setState={setReqData}
                  keyName={'name'}
                  error={error}
                  styles={{mb: '0.5rem'}}
                />
                <SimpleText
                  label={'CPF'}
                  state={reqData}
                  setState={setReqData}
                  keyName={'cpf_visitor'}
                  error={error}
                  styles={{mb: '0.5rem'}}
                />
                <DateAndTime
                  labelDate={'Data de Entrada'}
                  required
                  labelTime={'Horário'}
                  setState={setReqData}
                  valueFunction={entryDate}
                  timePicker={setTimePickerEntry}
                  keyName={'entry_date'}
                  styles={{mb: '0.5rem'}}
                />
                <DateAndTime
                  labelDate={'Data de Saída'}
                  labelTime={'Horário'}
                  setState={setReqData}
                  valueFunction={exitDate}
                  timePicker={setTimePickerExit}
                  keyName={'exit_date'}
                  styles={{mb: '0.5rem'}}

                />

                {dataProvider?.id ?
                  <UnitFilter
                    state={reqData}
                    setState={setReqData}
                    valueProp={unitOptions?.find(e => e.value === reqData?.unit_visitor) ?? ''}
                    keyName={'unit_delivery'}
                    styles={{mb: '0.5rem'}}
                  />
                  :
                  <UnitFilter
                    state={reqData}
                    setState={setReqData}
                    // valueProp={unitOptions?.find(e => e.value === reqData?.unit_delivery) ?? ''}
                    keyName={'unit_delivery'}
                    styles={{mb: '0.5rem'}}
                  />
                }
              </>
              :
              <Box>
                <SimpleTypography
                  label={'Situação'}
                  labelStyles={{ fontWeight: 'bold '}}
                  directValue={reqData?.authorized ? 'Autorizado' : 'Não autorizado'}
                  isLoading={dataProvider?.loadVisitor}
                  boxStyles={{ mb: '0.5rem' }}
                />
                <SimpleTypography
                  label={'Nome'}
                  labelStyles={{ fontWeight: 'bold '}}
                  directValue={reqData?.name ?? <Close sx={{color: 'error.main'}}/>}
                  isLoading={dataProvider?.loadVisitor}
                  boxStyles={{ mb: '0.5rem' }}
                />
                <SimpleTypography
                  label={'CPF'}
                  labelStyles={{ fontWeight: 'bold '}}
                  directValue={cpfFormat()}
                  isLoading={dataProvider?.loadVisitor}
                  boxStyles={{ mb: '0.5rem' }}
                />
                <SimpleTypography
                  label={'Data de Entrada'}
                  labelStyles={{ fontWeight: 'bold '}}
                  directValue={moment(reqData?.entry_date).format('DD/MM/YYYY HH:mm:ss')}
                  isLoading={dataProvider?.loadVisitor}
                  boxStyles={{ mb: '0.5rem' }}
                />
                <SimpleTypography
                  label={'Data de Saída'}
                  labelStyles={{ fontWeight: 'bold '}}
                  directValue={moment(reqData?.exit_date).format('DD/MM/YYYY HH:mm:ss')}
                  isLoading={dataProvider?.loadVisitor}
                  boxStyles={{ mb: '0.5rem' }}
                />
                <SimpleTypography
                  label={'Imóvel'}
                  labelStyles={{ fontWeight: 'bold '}}
                  directValue={unitOptions?.find(e => e.value === reqData?.unit_visitor)?.label  ?? <Close sx={{color: 'error.main'}}/>}
                  isLoading={dataProvider?.loadVisitor}
                  boxStyles={{ mb: 0 }}
                />
              </Box>
            }


          </>




        }


      />

    </>

  )
}

export default VisitorForm