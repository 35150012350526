import React, {useContext, useEffect, useState} from "react";
import {
  Avatar,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
} from '@mui/material'
import {
  Add,
  PriorityHigh,
  Engineering,
} from "@mui/icons-material";
import {api} from "../../../services/Main/Api";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {useSnackbar} from "notistack";
import Context from '../../../contexts/Context'
import moment from 'moment/moment'
import AutoCompleteGlobal from "../../../components/Shared/AutoCompleteGlobal";
import MaintenanceType from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenanceType";
import MaintenancePeriod from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenancePeriod";
import MaintenanceTime from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenanceTime";
import Title from "../../../components/BookingAndMaintenance/Title";
import MaintenanceCheck from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenanceCheck";
import MaintenanceValue from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenanceValue";
import DateAndTime from "../../../components/BookingAndMaintenance/DateAndTime";
import MaintenanceCondoMailCheck from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenanceCondoMailCheck";
import MaintenanceMailNotes from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenanceMailNotes";
import Description from "../../../components/BookingAndMaintenance/Description";
import MaintenanceNotification from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenanceNotification";
import MaintenanceStatus from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenanceStatus";
import NotAllowed from "../../../components/BookingAndMaintenance/NotAllowed";
import RegisterAndBackBtn from "../../../components/BookingAndMaintenance/RegisterAndBackBtn";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import ServiceFilter from "../../../components/Filters/ServiceFilter";

const MaintenanceCreate = () => {
  const {createEvent, setCreateEvent, clickDate, setRefresh, getServicesOptions, servicesOptions} = useContext(Context)
  const [data, setData] = useState(null)
  const [timePickerStart, setTimePickerStart] = useState(null)
  const [timePickerEnd, setTimePickerEnd] = useState(null)

  const sizeMatch = useMediaQuery('@media (min-width:600px)')

  useEffect(function whenClickDate () {
    setData(
      {
        select_mail: false,
        start_date: moment(new Date(clickDate)).format(),
        status: 'OPEN'
      })
    setTimePickerStart(moment().locale('pt-br').format("LT"))
    setTimePickerEnd(moment().locale('pt-br').format("LT"))
  }, [clickDate])

  const [error, setError] = useState(null)
  const [services, setServices] = useState(null)
  const {enqueueSnackbar} = useSnackbar()

  const handleSubmit = () => {

    Object.assign(data, {
      condo: getCondoId(),
      maintenance_value: data?.maintenance_value?.replace(/\D+/g, ""),
      start_date: moment(new Date(initialDate ())).locale('pt-br').format()
    })


    api.post('/maintenance/', data)
        .then((r) => {
          enqueueSnackbar("Manutenção agendada com sucesso!", {variant: "success"})
          setRefresh(prevState => !prevState)
          setCreateEvent(false)
          resetReqData()
        })
        .catch(error => {
          enqueueSnackbar("Erro ao agendar manutenção!", {variant: "error"})
          console.log(error?.response?.data)
          setError(error?.response?.data)
        })
   }

  useEffect(function providers(){
    if (createEvent) {
      getServicesOptions()

    }
  }, [createEvent])


  const today = moment(new Date()).subtract(1, "days").format()
  const clickedDate = moment(new Date (clickDate)).format()

  const resetReqData = () => {
    setData({
      select_mail: false,
      status: 'OPEN',
      start_date: moment(new Date(clickDate)).format(),
    })
    setError(null)
    setServices(null)
  }

  const initialDate = () => {
    const formStartDate = moment(data?.start_date).format('YYYY-MM-DD')
    const setStartDate = formStartDate + ' ' + timePickerStart
    return moment(setStartDate).format()
  }

  const endDate = () => {
    const formEndDate = moment(data?.end_date).format('YYYY-MM-DD')
    const setEndDate = formEndDate + ' ' + timePickerEnd
    return moment(setEndDate).format()
  }

  return (
    <>
      <SimpleDialog
        title={clickedDate > today ? 'Agendar Manutenção' : 'Erro'}
        titleIcon={clickedDate > today ? <Add/> : <PriorityHigh/>}
        open={createEvent ?? false}
        setOpen={setCreateEvent}
        confirmText={clickedDate > today && 'Criar'}
        onClickConfirm={clickedDate > today && handleSubmit}
        removeConfirm={clickedDate < today}
        onClickCancel={() => {
          resetReqData()
          setCreateEvent(false)
        }}
        dialogContent={
        <>
          {clickedDate > today ?
            <>
              <Title
                state={data}
                setState={setData}
                keyName={'title'}
                error={error}
                styles={{mb: '2rem'}}
              />

              <ServiceFilter
                label={'Prestador'}
                state={data}
                setState={setData}
                keyName={'service_provider'}
                error={error}
              />

              <MaintenanceCheck
                string={'A manutenção possui data de conclusão?'}
                state={data}
                setState={setData}
              />

              <DateAndTime
                labelDate={'Data de Início'}
                labelTime={'Horário de início'}
                setState={setData}
                valueFunction={initialDate}
                timePicker={setTimePickerStart}
                keyName={'start_date'}
                styles={{mb: (data?.end_date) ? '1rem' : '2rem'}}
                disabledDate={true}
              />

              {data?.end_date &&
                <DateAndTime
                  labelDate={'Data de conclusão'}
                  labelTime={'Horário de conclusão'}
                  setState={setData}
                  valueFunction={endDate}
                  timePicker={setTimePickerEnd}
                  keyName={'end_date'}
                />
              }
              <MaintenanceType
                state={data}
                setState={setData}
              />

              <MaintenancePeriod
                state={data}
                setState={setData}
              />

              <MaintenanceTime
                state={data}
                setState={setData}
              />

              <MaintenanceValue
                state={data}
                setState={setData}
              />

              <MaintenanceCondoMailCheck
                state={data}
                setState={setData}
              />

              <MaintenanceMailNotes
                state={data}
                setState={setData}
                error={error}
              />

              <Description
                label={'O que será feito?'}
                keyName={'description'}
                state={data}
                setState={setData}
                error={error}
              />

              <MaintenanceNotification
                state={data}
                setState={setData}
              />

              <MaintenanceStatus
                state={data}
                setState={setData}
              />
            </>
            :
            <NotAllowed />
          }


        </>

        }
      />


    </>
  )
}


export default MaintenanceCreate;
