import {TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import React, {useContext} from "react";
import Context from "../../../../../contexts/Context";

const TableTranferHead = () => {
  const {filters, setFilters} = useContext(Context)

  const handleFilter = (asc: string, desc: string) => {
    setFilters(prevState => ({
        ...prevState,
        ordering: filters?.ordering === asc ? desc : asc
      })
    )
  }



  return (
    <TableHead>
      <TableRow>
          <TableCell>
              <TableSortLabel
                  style={{
                      color: '#DD1960',
                  }}
                  active={filters?.ordering === '-number' || filters?.ordering === 'number'}
                  direction={filters?.ordering === '-number' ? 'desc' : 'asc'}
                  onClick={() => handleFilter('number', '-number') }
              >

                  Número
              </TableSortLabel>
          </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{
              color: '#DD1960',
            }}
            active={filters?.ordering === '-to_name' || filters?.ordering === 'to_name'}
            direction={filters?.ordering === '-to_name' ? 'desc' : 'asc'}
            onClick={() => handleFilter('to_name', '-to_name') }
          >

           Favorecido
          </TableSortLabel>
        </TableCell>


        <TableCell>
          <TableSortLabel
            style={{
              color: '#DD1960',
            }}
            active={filters?.ordering === '-created_at' || filters?.ordering === 'created_at'}
            direction={filters?.ordering === '-created_at' ? 'desc' : 'asc'}
            onClick={() => handleFilter('created_at', '-created_at')}
          >
              {filters.status === 0 || filters.status === 'Created' ? 'Agendado' : 'Criado'}
          </TableSortLabel>
        </TableCell>
        <TableCell
          // align={'left'}
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{
              color: '#DD1960',
            }}
            active={filters?.ordering === '-value' || filters?.ordering === 'value'}
            direction={filters?.ordering === '-value' ? 'desc' : 'asc'}
            onClick={() => handleFilter('value', '-value')}
          >
            Valor
          </TableSortLabel>
        </TableCell>
        <TableCell
          align={'center'}
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{
              color: '#DD1960',
            }}
            active={filters?.ordering === '-status' || filters?.ordering === 'status'}
            direction={filters?.ordering === '-status' ? 'desc' : 'asc'}
            onClick={() => handleFilter('status', '-status')}
          >
           Situação
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default TableTranferHead