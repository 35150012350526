import React, {useCallback, useEffect, useState, useContext} from 'react'
import Context from '../../contexts/Context'
import {useDropzone} from 'react-dropzone'
import {
  Box,
} from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import SendOrSent from "./components/SendOrSent";
import DropZone from "./components/DropZone";
import Rejected from "./components/Rejected";

function DragAndDrop({drop, deletable, singleFile, dropText, styles}) {
  const {
    userFiles,
    setUserfiles,
    deleteApiFiles,
    setFilteredFiles,
    setDeleteApiFiles,
    setUpdateApiFiles,
    documents,
    setDocuments
  } = useContext(Context);
  const [rejectedFiles, setRejectedFiles] = useState([])

  const params =  useParams()
  const location = useLocation()
  const {id} = params
  const {pathname} = location

  const onDrop = useCallback((acceptedFiles, rejectFiles) => {
    if (!singleFile){
    acceptedFiles.forEach(file => {
      const reader = new FileReader()
      reader.onload = () => {
        setFilteredFiles(prevState => [...prevState, reader.result])
        setUserfiles(prevState => [...prevState, file ])
      }
      reader.readAsDataURL(file)
    })
    } else {
      setUserfiles([ acceptedFiles[0] ])
    }
    rejectFiles.forEach(file => {
      setRejectedFiles(prevState => [...prevState, file])
    })


  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    maxSize: 100000000, onDrop,
    ...(singleFile && {maxFiles: 1, multiple: false}),
  });

  useEffect(function whenChangePage(){
    setUserfiles([])
    setUpdateApiFiles([])
  }, [pathname, id])


  return (
      <Box
        sx={{
          ...styles,
          cursor: 'pointer',
        }}
      >
        <SendOrSent
          title={'Documentos Enviados'}
          state={documents}
          setState={setDocuments}
          deletable={deletable}
          setDeleteApiFiles={setDeleteApiFiles}
          mapParam={'file'}
          rejectedFiles={rejectedFiles}
        />

        <DropZone
          enabled={drop}
          dropText={dropText}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          isDragActive={isDragActive}
        />

        <SendOrSent
          title={'Documentos para Envio'}
          state={userFiles}
          setState={setUserfiles}
          mapParam={'path'}
          deletable={true}
          rejectedFiles={rejectedFiles}
        />

        <SendOrSent
          title={'Documentos para excluir'}
          state={deleteApiFiles}
          setState={setDeleteApiFiles}
          setDeleteApiFiles={setDocuments}
          mapParam={'file'}
          deletable={true}
          rejectedFiles={rejectedFiles}
        />

        <Rejected
          rejectedFiles={rejectedFiles}
          setRejectedFiles={setRejectedFiles}
        />

      </Box>
  );
}

export default DragAndDrop;