import React, {useContext, useEffect, useState} from "react";
import Context from "../../../contexts/Context";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Step, StepLabel, Stepper} from "@mui/material";
import SimpleButtons from "../../../components/Shared/SimpleButtons";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {api} from "../../../services/Main/Api";
import {useSnackbar} from "notistack";

const UnitWizard = (props) => {
  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////


  const { dataProvider, setDataProvider, setLoadingModal, getUnitOptions} = useContext(Context)
  const {enqueueSnackbar} = useSnackbar()

  const {  reqData, setError, getUnit, getContract, setUnitData } = props
  const { UnitActiveStep, UnitFirstStep  } = dataProvider || {}

  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  function path (){
    if (location.pathname.includes('alugueis')){
      return navigate('/alugueis/imoveis')
    }
    if (location.pathname.includes('condominio')){
      return navigate('/condominio/imoveis')
    }
    if (location.pathname.includes('contrato')){
      setDataProvider(prevState => ({...prevState, modalUnit: false}))
    }
  }


  async function create() {
    setLoadingModal(true);

    const data = {
      ...reqData,
      condo: +getCondoId(),
      phone: +reqData?.phone?.toString()?.replace(/[^\d]+/g, ""),
      cpf_visitor: +reqData?.cpf?.toString()?.replace(/[^\d]+/g, ""),
      percent_meter: reqData?.percent_meter?.toString()?.replace(/,/g, '.')
    };

    const url = params.id ? `unit/${params.id}/` : 'unit/';
    const rentPath = location?.pathname?.includes('contratos')

    try {
      const response = await api.post(url, data);
      // rentPath && getContract(null, true)
      rentPath && getUnitOptions()
      rentPath && setUnitData(prevState => ({...prevState, unit: response.data.id}))
      // setFilters(prevState => ({...prevState, registerGarage: true, unitId: response.data.id }))
      setDataProvider(prevState => ({ ...prevState, registerGarage: true, unitId: response.data.id, UnitFirstStep: true }));
      enqueueSnackbar("Imóvel registrado", {variant: "success"})
      setError(null)
    } catch (error) {
      enqueueSnackbar(!params.id ? "Erro ao registrar imóvel" : "Erro ao atualizar imóvel", { variant: "error" });
      error.response.data.percent_meter && enqueueSnackbar(error.response.data.percent_meter, { variant: "error" });
      const { data } = error.response;
      setError(data);
      console.log(error);
    } finally {
      // Coloque aqui qualquer ação que você queira executar após o bloco try/catch, se necessário.
      setLoadingModal(false);
    }
  }

  //////////////////////////////////////
  //// Useffect, triggers and calls ////
  //////////////////////////////////////

  useEffect(function initialFilters(){
    setDataProvider(prevState => ({
      ...prevState,
      UnitActiveStep: 0,
      UnitFirstStep: false,
    }))
  },[])

  useEffect(function UnitFirstStepDone(){
    UnitFirstStep && setDataProvider(prevState => ({...prevState, UnitActiveStep: 1}))
  }, [UnitFirstStep])

  useEffect(function whenCreated(){
    if (UnitFirstStep === true) {
      setDataProvider(prevState => ({...prevState, UnitActiveStep: 1}))
    }
  },[UnitFirstStep])

  return (
    <>
      <Stepper
        activeStep={UnitActiveStep}
        sx={{
          mb: '1rem'
        }}
      >
        <Step>
          <StepLabel>Imóvel</StepLabel>
        </Step>
        <Step>
          <StepLabel>Proprietários</StepLabel>
        </Step>
        <Step>
          <StepLabel>Conclusão</StepLabel>
        </Step>
      </Stepper>
      {props.children}

      {UnitActiveStep === 0 &&
        //-> Recipient
        <SimpleButtons
          forwardButton={'Avançar'}
          onForward={() => {
            UnitFirstStep === true && setDataProvider(prevState => ({...prevState, UnitActiveStep: 1}))
            UnitFirstStep === false && create()
          }}
          noBack={(UnitFirstStep === true && UnitActiveStep === 0) || dataProvider?.modalUnit}

        />
      }

      {UnitActiveStep === 1 &&
        // -> Account
        <SimpleButtons
          forwardButton={'Avançar'}
          forwardDisable={dataProvider?.recipientButton}
          onForward={() => setDataProvider(prevState => ({...prevState, UnitActiveStep: 2}))}
          onBack={() =>  setDataProvider(prevState => ({...prevState, UnitActiveStep: 0}))}
        />
      }



      {UnitActiveStep === 2 &&
        // -> Conclusion Page
        <SimpleButtons
          forwardButton={'Concluir'}
          onForward={() => path()}
          onBack={() =>  setDataProvider(prevState => ({...prevState, UnitActiveStep: 1}))}
        />
      }

    </>
  )
}

export default UnitWizard