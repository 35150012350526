import Cookies from 'universal-cookie';

export const TOKEN_KEY_APP = "blocob3";
export const TOKEN_KEY = `${TOKEN_KEY_APP}.token`;
export const REFRESH_KEY = `${TOKEN_KEY_APP}.refresh`;

export const getToken = () => {
    const cookies = new Cookies(document.cookie);
    return cookies.get(TOKEN_KEY);
}

export const getRefreshToken = () => {
    const cookies = new Cookies(document.cookie);
    return cookies.get(REFRESH_KEY);
}

export const setToken = token => {
    const cookies = new Cookies();
    cookies.set(TOKEN_KEY, token, {
        path: '/',
        maxAge: 60 * 60 * 24 * 30 // 30 days
    });
}

export const setRefreshToken = refresh => {
    const cookies = new Cookies();
    cookies.set(REFRESH_KEY, refresh, {
        path: '/',
        maxAge: 60 * 60 * 24 * 30 // 30 days
    });
}

export const removeTokens = () => {
    const cookies = new Cookies(document.cookie);
    cookies.remove(TOKEN_KEY);
    cookies.remove(REFRESH_KEY);
}
