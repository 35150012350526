import {IconButton, ListItemIcon, ListItemText, MenuItem, TableBody, TableCell, TableRow} from "@mui/material";
import React, {useState} from "react";
import moment from "moment";
import {Delete, FileOpen, MoreVert, Share} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import Menu from "@mui/material/Menu";
import {Link as RouterLink} from "react-router-dom";

const TableDocumentBody = ({state, setDataProvider}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [anchor, setAnchor] = useState(null)
  const [open, setOpen] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState(null)


  const handleClick = async (data) => {
    try {
      await navigator.clipboard.writeText(data);
      enqueueSnackbar('Link copiado', { variant: 'success' })
    } catch (error) {
      console.error('Error copying text: ', error);
    }
  };

  return (
    <TableBody>
      { state?.results?.map((archive, index) => (
        <TableRow
          hover
          tabIndex={-1}
          key={index}
          sx={{ 'cursor': 'pointer' }}
          onClick={() => setDataProvider(prevState => ({openDocument: true, documentId: archive?.id}))}
        >

          <TableCell
            component="th"
            scope="row"
            sx={{ alignItems: 'center' }}
          >
            {archive?.title}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{ alignItems: 'center' }}
          >
            {moment(archive?.created_at).format('DD/MM/YYYY')}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{ alignItems: 'center' }}
          >
            {moment(archive?.updated_at).format('DD/MM/YYYY')}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            align={"center"}
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}


          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setSelectedDocument(archive)
                setAnchor(e.currentTarget)
                setOpen(archive.id)
              }}>
              <MoreVert/>
            </IconButton>

            <Menu
              open={open === archive.id}
              anchorEl={anchor && anchor}
              onClose={(e) => {
                e.stopPropagation()
                setOpen(false)
              }}
              PaperProps={{
                sx: { width: 200, maxWidth: '100%' },
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuItem
                component={RouterLink}
                to={archive?.document_file}
                target="_blank"
                onClick={(e)=> {
                  setOpen(false)
                  e.stopPropagation()
                }}
                // disabled={invoice?.status?.key !== "open"}
                sx={{
                  color: 'text.secondary'
                }}
              >
                <ListItemIcon>
                  <FileOpen />
                </ListItemIcon>
                <ListItemText
                  primary="Abrir documento"
                  primaryTypographyProps={{ variant: 'body2' }}/>
              </MenuItem>


              <MenuItem
                component={RouterLink}
                onClick={ (e) => {
                  e.stopPropagation()
                  navigator.clipboard.writeText(archive?.document_file)
                    .then(() => {
                      enqueueSnackbar('Link copiado', { variant: 'success' })
                    })
                  }
                }
                // disabled={invoice?.status?.key !== "open"}
                sx={{
                  color: 'text.secondary'
                }}
              >
                <ListItemIcon>
                  <Share />
                </ListItemIcon>
                <ListItemText
                  primary="Copiar link"
                  primaryTypographyProps={{ variant: 'body2' }}/>
              </MenuItem>


            </Menu>







          </TableCell>
        </TableRow>
      ))}

    </TableBody>
  )
}

export default TableDocumentBody