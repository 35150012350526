import {Box, Button, IconButton} from "@mui/material";
import UnitFilter from "../../../../components/Filters/UnitFilter";
import {Edit} from "@mui/icons-material";
import React, {useContext} from "react";
import {useParams} from "react-router-dom";
import Context from "../../../../contexts/Context";

const UnitSearch = (
  {
    reqData,
    setReqData,
    unitOptions,
    error,
    setFilters,
  }
) => {
  const params = useParams()
  const { dataProvider, setDataProvider } = useContext(Context)

  return (
    <>
      {!params?.id &&
        <Box
          sx={{
            mb: '1rem',
            borderRadius: '1rem',
            padding: '2rem',
            paddingBottom: '0rem'
          }}
        >

          {!reqData?.unit &&
            <UnitFilter
              label={'Imóvel'}
              state={reqData}
              setState={setReqData}
              // defaultValue={unitOptions?.find(e => e.value === reqData?.unit)}
              keyName={'unit'}
              error={error}
            />
          }
          { reqData?.unit && unitOptions?.find(e => e.value === reqData?.unit) &&
            <UnitFilter
              label={'Imóvel'}
              state={reqData}
              setState={setReqData}
              valueProp={unitOptions?.find(e => e.value === reqData?.unit)}
              keyName={'unit'}
              error={error}
            />
          }

          <Button
            onClick={() => {
              // setFilters(prevState => ({ ...prevState, modalUnit: true, unitId: null}))
              setDataProvider(prevState => ({ ...prevState, modalUnit: true, unitId: null, edit: false}))
            }
            }
          >
            Novo
          </Button>
          {reqData?.unit &&
            <IconButton
              onClick={() => {
                // setFilters(prevState => ({ ...prevState, modalUnit: true, unitId: reqData?.unit}))
                setDataProvider(prevState => ({ ...prevState, modalUnit: true, unitId: reqData?.unit, edit: true}))
              }
              }
            >
              <Edit />
            </IconButton>
          }
        </Box>

      }

    </>
  )
}

export default UnitSearch