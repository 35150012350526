import { api } from '../../../services/Main/Api'
import React, { useState } from "react";
import { getCondoId } from "../../../services/Main/AuthStorage";
import { Helmet } from "react-helmet";
import {
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Divider,
  Button,
  Box,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import SimpleText from "../../../components/Shared/SimpleText";
import UnitFilter from "../../../components/Filters/UnitFilter";
import ComplaintCategorySelector from "./components/ComplaintCreateAndUpdate/Shared/ComplaintCategorySelector";
import ComplaintToggleButton from "./components/ComplaintCreateAndUpdate/Shared/ComplaintToggleButton";

const ComplaintCreate = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [error, setError] = useState(false)
  const [data, setData] = useState({condo: +getCondoId(), status: "OPEN"})


  async function handleSubmit(e) {
    try {
      e.preventDefault();

      const dataPost = {
        ...data,
        condo: getCondoId(),
      };

      await api.post(`/complaint/`, dataPost);
      enqueueSnackbar('Reclamação registrada com sucesso!', { variant: 'success' });
      navigate('/reclamacoes');
    } catch (error) {
      enqueueSnackbar('Erro ao registrar reclamação', { variant: 'error' });
      setError(error.data);
      console.error(error);
    } finally {
      setData(null);
    }
  }

  return (
      <>
        <Helmet>
          <title>
            Registrar Reclamação - Bloco B3
          </title>
        </Helmet>
        <Box
          component={'form'}
          onSubmit={handleSubmit}
          sx={{marginBottom: '3rem'}}
          >
            <Card
              sx={{
                width: '100%',
                maxHeight: '620px',
                }}
              >
              <CardHeader
                avatar={<Avatar
                  sx={{bgcolor: "primary.main"}}
                  >
                    <Send />
                  </Avatar>}
                title={"Registrar Reclamação"}
              />
              <Divider
                sx={{margin: '.3rem'}}
                />
              <CardContent>
                    <SimpleText
                      label={'Título'}
                      state={data}
                      setState={setData}
                      keyName={'title'}
                      error={error}
                      required={true}
                      styles={{mb: '1rem'}}
                    />

                    <UnitFilter
                      state={data}
                      setState={setData}
                      keyName={'unit'}
                      styles={{ mb: '1rem' }}
                    />

                    <ComplaintCategorySelector
                      setState={setData}
                      keyName={'category'}
                      error={error}
                      styles={{ mb: '1rem'}}
                    />

                    <ComplaintToggleButton
                      state={data}
                      setState={setData}
                    />

                    <SimpleText
                      label={'Descrição'}
                      state={data}
                      setState={setData}
                      keyName={'message'}
                      error={error}
                      required={true}
                      multiline={true}
                      rows={5}
                      styles={{mb: '1rem'}}
                    />

                <Button
                  type={'submit'}
                  variant={'contained'}
                  fullWidth
                  >
                  {'Registrar'}
                </Button>
              </CardContent>
            </Card>

        </Box>


      </>
  )
}


export default ComplaintCreate;