import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import {
  Box,
  Container,
  Fade,
  Grid,
  useMediaQuery,
  ToggleButton
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { useSnackbar } from "notistack";
import moment from 'moment/moment'
import Lottie from "lottie-react";
import { getCondoId } from '../../../services/Main/AuthStorage'
import {api} from '../../../services/Main/Api'
import Context from "../../../contexts/Context";
import loading from "../../../assets/animations/loading.json";
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import InquiryStatement from "./components/BankStatement/InquiryStatement";
import AccInfoCard from "./components/BankStatement/AccInfoCard";
import BankStatementDownload from "./components/BankStatementDownload/BankStatementDownload";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ReserveFundCard from "./components/BankFundTransfer/ReserveFundCard";


const BankStatement = () => {
  const {enqueueSnackbar} = useSnackbar()
  const { filters, setFilters, setDataProvider } = useContext(Context)
  const sizeMatch = useMediaQuery('@media (min-width:900px)')
  const initialStartDate = moment(new Date()).startOf('month').format('YYYY-MM-DD')
  const initialEndDate = moment(new Date()).format('YYYY-MM-DD')


  const [hasData, setHasData] = useState(true)
  const [animation, setAnimation] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])

  async function getStatements() {
    const condo = getCondoId();
    setDataProvider(prevState => ({...prevState, loadingStatement: true}))

    if (filters?.page_index >= 0 && hasData && getCondoId()) {
      setAnimation(true);
      try {
        const apiModelTransition = filters?.whichAccount === 'main'
          ? await api.get(`/fitbank/entry/${condo}/`, { params: filters })
          : filters?.whichAccount === 'reserve' && await api.get(`/fitbank/reserve-fund/entry/${condo}/`, { params: filters });

        const newData = apiModelTransition.data;

        if (newData.length === 0) {
          setData([]);
          setHasData(false);
        } else {
          const oldData = data || [];
          const mergedData = [...oldData, ...newData];
          setData(mergedData);

          if (newData?.length < filters?.page_size) {
            setHasData(false);
          }
        }

      } catch (err) {
        console.log(err);
        enqueueSnackbar('Erro ao carregar dados', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setIsLoading(false);
        setAnimation(false);
        setDataProvider(prevState => ({...prevState, loadingStatement: false}))

        if (!hasData) {
          enqueueSnackbar('Última requisição efetuada', {
            variant: 'info',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        }
      }
    }
  }



  useEffect(function BankStatementFilters(){
    setFilters({
      page_index: 0,
      page_size: 200,
      start_date: initialStartDate,
      end_date: initialEndDate,
      whichAccount: 'main',
    })
  },[])

  useEffect(function observeFilters(){
    getStatements()
  }, [filters])


  return (
    <> 
      <Helmet>
        <title>Extrato - Bloco B3</title>
      </Helmet>

      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'start',
          flexDirection: 'column',
          margin: 0,
          padding: 0
        }}
      >
      <TitleBtnList
        noButtons
        title={'Extrato Bancário'}
        boxStyles={{
          margin: !sizeMatch && '0 auto',
          mt: '1rem'
        }}
      />

        <Grid container spacing={2} sx={{mt: '1rem'}}>
          <Grid item xs={12} md={7} sx={{ order: { xs: 2, md: 1 } }}>

            <InquiryStatement
              isLoading={isLoading}
              data={data}
              setData={setData}
              hasData={hasData}
              setHasData={setHasData}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
              }}
            >
              {animation && !isLoading &&
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100px'
                  }}
                >
                  <Lottie width="250px" animationData={loading} loop={true} color={'#fff'}/>
                </Box>
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={5} sx={{ display: 'flex', flexDirection: 'column',  order: { xs: 1, md: 2 } }}>
            <AccInfoCard isLoading={isLoading} />

            <Fade
              in={filters?.whichAccount === 'main'}
              mountOnEnter
              unmountOnExit
              timeout={300}
            >
              <Box>
                <BankStatementDownload isLoading={isLoading} />
              </Box>
            </Fade>
          </Grid>
        </Grid>




    </Container>
    
    
    </>
  )
}

export default BankStatement;