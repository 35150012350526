import {Table, TableContainer} from "@mui/material";
import InvoiceDetailThead from "./dependencies/InvoiceDetailThead";
import InvoiceDetailTbody from "./dependencies/InvoiceDetailTbody";
import React from "react";

const InvoiceDetailTable = ({ invoice }) => {
  return (
    <TableContainer
      sx={{
        p: '1rem'
      }}
    >
      <Table
        size="small"
        sx={{
          marginY: '2rem',
        }}
      >
        <InvoiceDetailThead />
        <InvoiceDetailTbody
          invoice={invoice}
        />

      </Table>
    </TableContainer>
  )
}

export default InvoiceDetailTable