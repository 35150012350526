import Box from '@mui/material/Box'
import Header from '../Main/Header'
import { Outlet } from 'react-router-dom'
import * as React from 'react'
import { CssBaseline } from '@mui/material'
import SideBarUnitUser from "./SiderBarUnitUser";
import HeaderUnitUser from "./HeaderUnitUser";

const drawerWidth = 250

const BaseUnitUser = () => {
  const [mobileMenu, setMobileMenu] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileMenu(!mobileMenu)
  }

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <CssBaseline/>
      <SideBarUnitUser mobileMenu={mobileMenu} handleDrawerToggle={handleDrawerToggle}/>
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          width: { md: `calc(100% - ${drawerWidth}px)` },
          overflow: 'auto',
          minHeight: '100%',
          paddingBottom: theme.spacing(10),
          [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(3),
          }
        })}
      >
        <HeaderUnitUser handleDrawerToggle={handleDrawerToggle}/>
        <Outlet/>
      </Box>
    </Box>
  )
}
export default BaseUnitUser