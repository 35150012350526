import {Avatar, Card, CardContent, CardHeader, Divider, Grid, Link, Skeleton, Typography} from "@mui/material";
import {Badge} from "@mui/icons-material";
import Masks from "../../../../../components/Shared/Masks";
import React from "react";
import ProviderReadComponent from "./dependencies/ProviderReadComponent";

const ProviderReadInfo = ({ isLoading, data, jobs }) => {
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar sx={{bgcolor: "primary.main"}}>
            <Badge/>
          </Avatar>
        }
        title={"Prestador de Serviço"}
      />
      <Divider
        sx={{
          marginY: '.3rem'
        }}
      />
      <CardContent
        sx={{
          margin: '1rem'
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
        >
          <ProviderReadComponent
            text={'Nome:'}
            isLoading={isLoading}
            dataText={data?.name}
          />

          <ProviderReadComponent
            text={'Telefone:'}
            isLoading={isLoading}
            dataText={Masks.phone(data?.phone ?? '')}
          />

          <ProviderReadComponent
            text={'Celular:'}
            isLoading={isLoading}
            dataText={Masks.phone(data?.cell ?? '')}
          />

          <ProviderReadComponent
            text={'E-mail:'}
            isLoading={isLoading}
            dataText={data?.email}
          />

          <ProviderReadComponent
            text={'Profissão:'}
            isLoading={isLoading}
            dataText={jobs?.find((e) => (e?.id === data?.profession))?.name}
          />

          <ProviderReadComponent
            text={'Rede / Site:'}
            isLoading={isLoading}
            dataText={data?.social_media_url_1}
          />

          {data?.social_media_url_2 &&
            <ProviderReadComponent
              text={'Rede / Site:'}
              isLoading={isLoading}
              dataText={data?.social_media_url_2}
            />
          }
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ProviderReadInfo