import React, {useState} from "react";
import MaintenanceReadTitle from "./readOnly/MaintenanceReadTitle";
import MaintenanceReadProvider from "./readOnly/MaintenanceReadProvider";
import MaintenanceReadStartDate from "./readOnly/MaintenanceReadStartDate";
import MaintenanceReadEndDate from "./readOnly/MaintenanceReadEndDate";
import MaintenanceReadCheck from "./readOnly/MaintenanceReadCheck";
import MaintenanceReadEndDateSave from "./readOnly/MaintenanceReadEndDateSave";
import MaintenanceReadMaintenanceType from "./readOnly/MaintenanceReadMaintenanceType";
import MaintenanceReadIfPeriodic from "./readOnly/MaintenanceReadIfPeriodic";
import MaintenanceReadMaintenanceValue from "./readOnly/MaintenanceReadMaintenanceValue";
import MaintenanceReadMailCheck from "./readOnly/MaintenanceReadMailCheck";
import MaintenanceReadMailNotes from "./readOnly/MaintenanceReadMailNotes";
import MaintenanceReadDescription from "./readOnly/MaintenanceReadDescription";
import MaintenanceReadNotification from "./readOnly/MaintenanceReadNotification";
import MaintenanceReadStatus from "./readOnly/MaintenanceReadStatus";

const MaintenanceReadOnly = ({dataUpdate, setDataUpdate, isLoading, services, endDate, handleSubmit, setTimePickerEnd}) => {
  const [hasEndDate, setHasEndDate] = useState(false)

  return (
    <>
     <MaintenanceReadTitle
      isLoading={isLoading}
      dataUpdate={dataUpdate}
      />

     <MaintenanceReadProvider
      isLoading={isLoading}
      dataUpdate={dataUpdate}
      services={services}
      />

     <MaintenanceReadStartDate
       isLoading={isLoading}
       dataUpdate={dataUpdate}
       />

      <MaintenanceReadEndDate
        isLoading={isLoading}
        dataUpdate={dataUpdate}
        />

      <MaintenanceReadCheck
        dataUpdate={dataUpdate}
        setHasEndDate={setHasEndDate}
        />

      <MaintenanceReadEndDateSave
        isLoading={isLoading}
        hasEndDate={hasEndDate}
        setDataUpdate={setDataUpdate}
        endDate={endDate}
        setTimePickerEnd={setTimePickerEnd}
        handleSubmit={handleSubmit}
        />

      <MaintenanceReadMaintenanceType
        isLoading={isLoading}
        dataUpdate={dataUpdate}
        />

      <MaintenanceReadIfPeriodic
        isLoading={isLoading}
        dataUpdate={dataUpdate}
        />

      <MaintenanceReadMaintenanceValue
         isLoading={isLoading}
         dataUpdate={dataUpdate}
        />

      <MaintenanceReadMailCheck
         isLoading={isLoading}
         dataUpdate={dataUpdate}
        />

      <MaintenanceReadMailNotes
        isLoading={isLoading}
        dataUpdate={dataUpdate}
        />

      <MaintenanceReadDescription
        isLoading={isLoading}
        dataUpdate={dataUpdate}
        />

      <MaintenanceReadNotification
        isLoading={isLoading}
        dataUpdate={dataUpdate}
        />

      <MaintenanceReadStatus
        isLoading={isLoading}
        dataUpdate={dataUpdate}
        />
    </>

  )
}

export default MaintenanceReadOnly;