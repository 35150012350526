import FullCalendar from '@fullcalendar/react' // must go before plugins
import interactionPlugin from '@fullcalendar/interaction'
import ptBr from '@fullcalendar/core/locales/pt-br'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import {
  Card,
  CardContent,
  Container,
  Divider,
  Skeleton,
  useMediaQuery
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Context from '../../contexts/Context'
import { api } from '../../services/Main/Api'
import moment from 'moment'
import { getCondoId } from '../../services/Main/AuthStorage'
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import TitleBtnList from "../Shared/TitleBtnList";

const CalendarMaintenance = () => {
  const {setCreateEvent, setClickDate, setEditEvent, refresh } = useContext(Context)
  const [events,  setEvents] = useState(null)
  const [services, setServices] = useState(null)

  const sizeMatch = useMediaQuery('@media (min-width:700px)')


  useEffect(function bookingArea(){
      api.get(`/service/provider/?condo_id=${getCondoId()}`)
        .then(response => {
          setServices(response.data.results)
        })
        .catch(error => {
          console.log(error)
        })

  },[refresh])

  useEffect(function calendarState(){
    if (services) {
    api.get('/maintenance/', { params: {condo_id: getCondoId(), page_size: 1000} })
      .then(response => {
        setEvents(response.data.results.map(a =>

          ({
            title: a.title,
            start: a.start_date ?? a?.maintenance_date,
            end: a.end_date ?? '',
            id: a.id
          })
        ))
      })
    }

  },[refresh, services])


  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      >
      <TitleBtnList
        title={'Manutenções'}
        buttonText={'Acessar Prestadores'}
        buttonHref={'/manutencao/servicos'}
      />

      <Card sx={{maxWidth: '1200px'}}>
        {events ?
          <>
            <Divider light sx={{marginY: '.3rem'}}/>
            <CardContent>
                <FullCalendar
                  plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
                  initialView="dayGridMonth"
                  selectable={true}
                  locale={ptBr}
                  weekends={true}
                  aspectRatio={1.3}
                  editable={true}
                  headerToolbar={{
                    left: sizeMatch && 'prev,today,next',
                    center: 'title',
                    right: sizeMatch ? 'dayGridMonth,timeGridWeek,timeGridDay,dayGridYear' : 'prev,today,next'
                  }}
                  footerToolbar={{
                    right: !sizeMatch && 'dayGridMonth,timeGridWeek,timeGridDay,dayGridYear'
                  }}
                  eventClick={
                    function (arg) {
                      setClickDate(arg.event.startStr)
                      setEditEvent({event: true, id: arg.event.id})
                    }
                  }
                  dateClick={(arg) => {
                    setCreateEvent(true)
                    setClickDate(moment(arg.dateStr).format())
                  }
                  }
                  events={events}
                />
            </CardContent>
          </>

            :
              <Skeleton
                variant="rounded"
                height={700}
                animation="pulse"
              />
          }
      </Card>

    </Container>
    )
  }

export default CalendarMaintenance;
