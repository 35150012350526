import React, {useContext, useEffect, useState} from "react";
import {
  Box,
  Card,
  Table,
  TableContainer,
  Avatar,
  CardHeader,
  Skeleton,
} from "@mui/material";
import { Announcement } from "@mui/icons-material";
import { api } from '../../../services/Main/Api'
import {getCondoId} from "../../../services/Main/AuthStorage";
import Context from "../../../contexts/Context";
import TableComplaintHead from "./components/ComplaintList/TableComplaintHead";
import TableComplaintBody from "./components/ComplaintList/TableComplaintBody";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";

const ComplaintList = () => {
  const [response, setResponse] = useState(null)
  const {filters, setFilters} = useContext(Context)
  useEffect(function initialFilter(){
    setFilters({
      condo_id: getCondoId(),
      page_size: 50
    })
  },[])

  async function fetchData() {
    try {
      if (filters) {
        const response = await api.get(`/complaint/`, { params: filters });
        setResponse(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(function listData() {
    fetchData()
  }, [filters])

  return(
    <>
      <Box
        sx={{
          maxHeight: '620px'
        }}
        >
        {response ?
        <Card
          sx={{
            width: '100%',
            overflow: 'auto',
            maxHeight: '620px'
            }}
          >
          <CardHeader
            avatar={<Avatar
              sx={{bgcolor: "primary.main"}}
            >
              <Announcement />
            </Avatar>}
            title={"Reclamações Feitas"}
          />
          <TableContainer>
            {response?.results?.length > 0 ?
              <Table>

                <TableComplaintHead />
                <TableComplaintBody
                  response={response}
                />
              </Table>
              :
             <NoResultsAnimation />
            }

          </TableContainer>
        </Card>
          :
            <Skeleton variant="rounded" height={500} animation="pulse" sx={{margin: 1}}/>
          }
        <PaginationGlobal
          state={response}
          filters={filters}
          setFilters={setFilters}
        />
      </Box>
    </>
  )
}

export default ComplaintList;