import {useSnackbar} from "notistack";
import React, {useContext, useState, useEffect} from "react";
import Context from "../../../contexts/Context";
import {api} from "../../../services/Main/Api";
import {useParams} from "react-router-dom";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {Backdrop, Button, CircularProgress, IconButton, Skeleton} from "@mui/material";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import {Delete, Edit, FileCopy} from "@mui/icons-material";
import SimpleText from "../../../components/Shared/SimpleText";
import DocumentsReadyOnly from "./components/DocumentsReadyOnly";
import DragAndDrop from "../../../components/DragAndDrop/DragAndDrop";
import {Box} from "@mui/system";



const DocumentForm = () => {
  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////

  const {enqueueSnackbar} = useSnackbar()
  const {userFiles, documents, setDocuments, dataProvider, setDataProvider} = useContext(Context)

  const params = useParams()

  const [reqData, setReqData] = useState(null)
  const [loading, setLoading] = useState(!!dataProvider?.documentId)
  const [error, setError] = useState(null)
  const [edit, setEdit] = useState(null)
  const [openDelete, setOpenDelete] = useState(null)
  const [reqLoading, setReqLoading] = useState(false)

  ///////////////////////
  //// Requisitions /////
  ///////////////////////

  // function getDocuments(){
  //   if (dataProvider?.documentId){
  //     api.get(`/document/${dataProvider?.documentId}`)
  //       .then((response)=> {
  //         setReqData(response.data)
  //         setDocuments([{file: response.data.document_file}])
  //       })
  //       .catch(error => {
  //         console.log(error)
  //         setError(error.data)
  //       }).finally(()=>{
  //         setLoading(false)
  //     })
  //   }
  // }

  async function getDocuments() {
    if (dataProvider?.documentId) {
      try {
        const response = await api.get(`/document/${dataProvider?.documentId}`);
        setReqData(response.data);
        setDocuments([{ file: response.data.document_file }]);
      } catch (error) {
        console.log(error);
        setError(error.data);
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(function firstLoad(){
    getDocuments()
  },[dataProvider?.documentId])

  // function saveReq () {
  //
  //
  //   const createData = {
  //     ...reqData,
  //     document_file: userFiles[0],
  //     condo: getCondoId()
  //   }
  //
  //   function alternateReq () {
  //     if(dataProvider?.documentId){
  //       return api.put(`/document/update/${dataProvider?.documentId}/`, reqData)
  //     } else {
  //       return api.post(`/document/`, createData, { headers: {'Content-Type': 'multipart/form-data'} })
  //     }
  //
  //   }
  //
  //   alternateReq()
  //     .then(response => {
  //       setEdit(false)
  //       dataProvider?.documentId && enqueueSnackbar('Arquivo atualizado com sucesso', {variant: 'success'})
  //       !dataProvider?.documentId && enqueueSnackbar('Documento arquivado com sucesso', {variant: 'success'})
  //       !dataProvider?.documentId && setDataProvider(prevState => ({...prevState, openDocument: false }))
  //       setError(null)
  //     })
  //     .catch(error => {
  //       setError(error.response.data)
  //       dataProvider?.documentId && enqueueSnackbar('Erro ao atualizar arquivo', {variant: 'error'})
  //       !dataProvider?.documentId && enqueueSnackbar('Erro ao arquivar documento', {variant: 'error'})
  //       console.log(error)
  //     })
  // }

  async function saveReq() {
    setLoading(true)
    setReqLoading(true)

    const createData = {
      ...reqData,
      document_file: userFiles[0],
      condo: getCondoId()
    };

    function alternateReq() {
      if (dataProvider?.documentId) {
        return api.put(`/document/update/${dataProvider.documentId}/`, reqData);
      } else {
        return api.post(`/document/`, createData, { headers: { 'Content-Type': 'multipart/form-data' } });
      }
    }

    try {
      const response = await alternateReq();
      setEdit(false);
      setDataProvider(prevState => ({ ...prevState, openDocument: false }));
      if (dataProvider?.documentId) {
        enqueueSnackbar('Arquivo atualizado com sucesso', { variant: 'success' });
       } else {
        enqueueSnackbar('Documento arquivado com sucesso', { variant: 'success' });
      }
      setError(null);
    } catch (error) {
      setError(error.response.data);
      if (dataProvider?.documentId) {
        enqueueSnackbar('Erro ao atualizar arquivo', { variant: 'error' });
       } else {
        enqueueSnackbar('Erro ao arquivar documento', { variant: 'error' });
      }
      console.log(error);
    } finally {
      setLoading(false)
      setReqLoading(false)
    }
  }

  async function deleteArchive (){
    setReqLoading(true)
    try {
      await api.delete(`/document/${dataProvider?.documentId}`);
      enqueueSnackbar('Arquivo deletado com sucesso', { variant: 'success' });
      setOpenDelete(false);
      setDataProvider(prevState => ({ ...prevState, openDocument: false }));

    } catch (error) {
      enqueueSnackbar('Erro ao excluir arquivo', { variant: 'error' });
      console.log(error);
    } finally {
      setReqLoading(false)
    }
  }

  useEffect(function whenModalClose(){
    if (dataProvider?.openDocument === false){
      setLoading(true)
      setReqData(null)
      setDocuments([])
      setDataProvider(prevState => ({ ...prevState, documentId: null}))
    }

  },[dataProvider?.openDocument])


  return (
    <>
      {/*<Backdrop*/}
      {/*  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}*/}
      {/*  open={loading}*/}
      {/*>*/}
      {/*  <CircularProgress color="inherit"/>*/}
      {/*</Backdrop>*/}
      <SimpleDialog
        title={'Documento'}
        open={dataProvider?.openDocument}
        titleIcon={<FileCopy />}
        removeConfirm={!!dataProvider?.documentId}
        onClickConfirm={!dataProvider?.documentId ? () => {
           saveReq()
        } : false}
        onClickCancel={() => {
          setDataProvider(prevState => ({ ...prevState, openDocument: false}))
          setLoading(true)
        }}
        actionMenu={
        <>
          {dataProvider?.documentId &&
          <>
            {!edit ?
              <Box>
                <IconButton
                  onClick={() => {
                    setEdit(true)
                  }
                  }
                >
                  <Edit/>
                </IconButton>
                <IconButton
                  onClick={() => setOpenDelete(true)}
                >
                  <Delete/>
                </IconButton>
              </Box>

              :
              <Box>
                <Button
                  variant={'contained'}
                  onClick={() => {
                   saveReq()
                  }
                  }
                >
                  Salvar
                </Button>
                <Button
                  onClick={() => {
                    getDocuments()
                    setEdit(false)
                  }}
                >
                  Cancelar
                </Button>
              </Box>
              }
            </>
            }
          </>
        }
        dialogContent={
          <>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={reqLoading}
            >
              <CircularProgress color="inherit"/>
            </Backdrop>
            {
              edit || !dataProvider?.documentId && dataProvider?.openDocument ?
                <>
                  <SimpleText
                    label={'Título'}
                    state={reqData}
                    setState={setReqData}
                    keyName={'title'}
                    error={error}
                    styles={{
                      mt: '1rem',
                      mb: '1rem'
                    }}
                  />
                  <SimpleText
                    label={'Descrição'}
                    state={reqData}
                    setState={setReqData}
                    keyName={'description'}
                    multiline={true}
                    rows={5}
                    error={error}
                  />
                </>
                :
                <DocumentsReadyOnly
                  reqData={reqData}
                  documents={documents}
                />
            }

            {!dataProvider?.documentId ?
              <DragAndDrop
                dropText={'Arraste ou clique para adicionar o arquivo'}
                drop={true}
                deletable={true}
                singleFile={true}
                styles={{
                  mt: '1rem',
                }}
              />
              :
              <DragAndDrop
                drop={false}
                deletable={false}
                styles={{
                  mt: '1rem',
                }}
              />
            }
            <SimpleDialog
              title={'Excluir Documento'}
              question={'Realmente deseja excluir esse documento?'}
              open={openDelete}
              setOpen={setOpenDelete}
              functionProp={deleteArchive}
            />
          </>
        }
    />
  </>


  )
}

export default DocumentForm