import React from "react";
import {
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import moment from "moment";

const TableBillBody = ({state, setDataProvider}) => {
  return (
    <TableBody>
      { state?.results?.map((bill, index) => (
        <TableRow
          hover
          tabIndex={-1}
          key={index}
          sx={{ 'cursor': 'pointer' }}
          onClick={() => setDataProvider(prevState => ({
            ...prevState,
            openBill: true, billId: bill?.id

          }))}
        >

          <TableCell
            component="th"
            scope="row"
            sx={{ alignItems: 'center' }}
          >
            {bill?.subject}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{ alignItems: 'center' }}
          >
            {moment(bill?.created_at).format('DD/MM/YYYY')}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{ alignItems: 'center' }}
          >
            {moment(bill?.updated_at).format('DD/MM/YYYY')}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{ alignItems: 'center' }}
          >
            {bill?.user?.first_name}
          </TableCell>
        </TableRow>
      ))}

    </TableBody>
  )
}

export default TableBillBody