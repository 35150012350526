import React, {
  useState
} from "react";
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import {DeleteOutlined, EditOutlined, MoreVert} from "@mui/icons-material";
import ModalDelete from "../../../../../../../components/Modal/ModalDelete";
import {useParams} from "react-router-dom";

const ComplaintActionHeader =
  ({
     edit,
     setEdit,
     deleteComplaint
  }) => {
    const param = useParams()
    const {id} = param

    const [anchorEl, setAnchorEl] = useState(null)
    const [openMenu, setOpenMenu] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleOpenConfirmation = () => {
      setOpenMenu(false)
      setOpenConfirmation(true)
    }

    const handleCloseConfirmation = () => {
      setOpenConfirmation(false)
    }


  return (
    <>
      {!edit &&
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            setOpenMenu(true)
            handleClick(e)

          }}>
          <MoreVert/>
        </IconButton>
      }
      <Menu
        open={openMenu ?? false}
        anchorEl={anchorEl}
        onClose={() => setOpenMenu(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            setEdit(prevState => !prevState)
            setOpenMenu(false)
          }
          }
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <EditOutlined/>
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }}/>
        </MenuItem>

        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={() => handleOpenConfirmation()}
        >
          <ListItemIcon>
            <DeleteOutlined color={'error'}/>
          </ListItemIcon>
          <ListItemText primary="Excluir" primaryTypographyProps={{ variant: 'body2' }}/>
        </MenuItem>
      </Menu>
      <ModalDelete
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        onDelete={() => deleteComplaint(id)}
      />
    </>
  )
}

export default ComplaintActionHeader