import {Box, Divider, Skeleton, Typography} from "@mui/material";
import Masks from "../../../../../components/Shared/Masks";

const InvoiceDetailed = ({ invoice }) => {
  return (
    <Box
      sx={{
        mt: '1rem',
        paddingX: '2rem',
        // backgroundColor: '#a7a7a7'
      }}
    >
      {invoice ?
      <>
          {/*<Typography*/}
          {/*  variant={'h5'}*/}
          {/*  sx={{*/}
          {/*    mb: '0.5rem'*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Detalhes*/}

          {/*</Typography>*/}
        {invoice?.fees?.map((e, index) => (
        <Box
          key={index}
          sx={{
            mb: '1rem'
          }}
        >
          { e.description &&
            <Typography
              sx={{
                fontWeight: 'bold',
              }}
            >
              {e.description}
            </Typography>
          }

          {e.category.key === 'GAS' && e.category.data &&
          <>

            <Typography>
              Consumo:
            </Typography>
            <Typography>
              Leitura anterior: {e?.category?.data?.previous_reading_date}
            </Typography>
            <Typography>
              Valor da leitura: {e?.category?.data?.previous_reading_amount}
            </Typography>
            <Typography>
              Leitura atual: {e?.category?.data?.current_reading_date}
            </Typography>
            <Typography>
              Valor da leitura: {e?.category?.data?.current_reading_amount}
            </Typography>
            <Typography>
              Consumido: {e?.category?.data?.consumed}
            </Typography>
          </>
          }

          {e.condo_total &&
            <Typography>
              Total condomínio: {Masks.money(e.condo_total.toString())}
            </Typography>
          }

          {e.total &&
            <Typography>
              Total da categoria: {Masks.money(e.total.toString())}
            </Typography>
          }
          <Divider />
        </Box>

        ))}

        {invoice?.total &&
          <Typography
            variant={'h6'}
          >
            Valor total fatura: {Masks.money(invoice?.total?.toString())}
          </Typography>
        }




      </>
    :
    <Skeleton
      height={'500px'}
      width={'500px'}
      sx={{
        mt: -10
      }}
    />
    }
    </Box>
  )
}

export default InvoiceDetailed