import * as React from 'react'
import { useRef, useState, useContext } from 'react'
import {
  IconButton,
} from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { api } from '../../../services/Main/Api'
import { useSnackbar } from 'notistack'
import Context from '../../../contexts/Context'
import MakeMenu from "./components/MakeItPaidMenu/MakeMenu";
import MakeDialogOptions from "./components/MakeItPaidMenu/MakeDialogOptions";

const MakeItPaidMenu = ({invoice, onSuccess}) => {

  const {setLoadingModal} = useContext(Context)
  const [isOpen, setIsOpen] = useState(false)
  const [openByStatus, setOpenByStatus] = useState({paid: false, remake: false, create: false, open: false, deleted: false})
  const [reqNewInvoice, setReqNewInvoice] = useState({ send_email: false, send_whatsapp: false, due_date: ''})
  const [reqCreateInvoice, setReqCreateInvoice] = useState({ send_email: false, send_whatsapp: false, due_date: ''})
  const [errors, setErrors] = useState(null)
  const [anchor, setAnchor] = useState(null)
  const {setInvoiceListRefresh} = useContext(Context)
  const {enqueueSnackbar} = useSnackbar()

  const handleCloseConfirmation = (e) => {
    setOpenByStatus({paid: false, remake: false, create: false, open: false, deleted: false})
    setIsOpen(false)
  }

  const handleMakeItPaid = (e) => {
    api.put(`invoice/${invoice?.id}/`, {status: "paid", paid_by: "MANUAL"}).then(response => {
      if (response.status === 200) {
        onSuccess()
        enqueueSnackbar("Fatura marcada como paga com sucesso!", {variant: 'success'})
      }
    }).catch(response => {
      enqueueSnackbar("Não foi possível processar esta fatura", {variant: "error"})
    }).finally(() => {
      handleCloseConfirmation(e)
    })
  }
  const handleMakeItOpen = (e) => {
    setIsOpen(false)
    setLoadingModal(true)
    api.put(`invoice/${invoice?.id}/`, {status: "open", paid_by: null}).then(response => {

      enqueueSnackbar("Fatura aberta com sucesso!", {variant: 'success'})
      onSuccess()
    }).catch(response => {
      enqueueSnackbar("Não foi possível abrir esta fatura", {variant: "error"})
    }).finally(() => {
      handleCloseConfirmation(e)
      setLoadingModal(false)
    })
  }

  const handleMakeItDelete = (e) => {
    setIsOpen(false)
    setLoadingModal(true)
    api.put(`invoice/${invoice?.id}/`, {status: "deleted", paid_by: null}).then(response => {

      enqueueSnackbar("Fatura deletada com sucesso!", {variant: 'success'})
      onSuccess()
    }).catch(response => {
      enqueueSnackbar("Não foi possível deletar esta fatura", {variant: "error"})
    }).finally(() => {
      handleCloseConfirmation(e)
      setLoadingModal(false)
    })
  }

  const generateNew = (e) => {
    setLoadingModal(true)
    api.put(`bank-slip/invoice/${invoice?.id}/`, reqNewInvoice)
      .then(response =>{
        setLoadingModal(false)
          enqueueSnackbar("Pedido para gerar segunda via do boleto efetuado!", {variant: 'success'})
          enqueueSnackbar("Processando boleto, disponivel em breve!", {variant: 'success'})
      }).catch(response => {
        enqueueSnackbar("Informe uma data válida", {variant: "error"})
        setErrors(response)
        console.log(response)
        setLoadingModal(false)
    })
  }

  const generate = () => {
    setLoadingModal(true)
    api.post(`bank-slip/invoice/${invoice?.id}/`, reqCreateInvoice)
      .then(response =>{
        setLoadingModal(false)
        enqueueSnackbar("Pedido para gerar boleto efetuado!", {variant: 'success'})
          enqueueSnackbar("Processando boleto, disponivel em breve!", {variant: 'success'})
      }).catch(response => {
        enqueueSnackbar("Informe uma data válida", {variant: "error"})
        setErrors(response)
        console.log(response.response.data)
        setLoadingModal(false)
    }).finally(() => {
      setInvoiceListRefresh(true)
    })
  }

  return (
    <>

      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          setAnchor(e.currentTarget)
          setIsOpen(true)
      }}>
        <MoreVert/>
      </IconButton>

      <MakeMenu
        invoice={invoice}
        isOpen={isOpen}
        anchor={anchor}
        setOpenByStatus={setOpenByStatus}
        openByStatus={openByStatus}
        setIsOpen={setIsOpen}
      />

      <MakeDialogOptions
        openByStatus={openByStatus}
        setOpenByStatus={setOpenByStatus}
        reqNewInvoice={reqNewInvoice}
        setReqNewInvoice={setReqNewInvoice}
        reqCreateInvoice={reqCreateInvoice}
        setReqCreateInvoice={setReqCreateInvoice}
        generate={generate}
        generateNew={generateNew}
        handleMakeItPaid={handleMakeItPaid}
        handleMakeItOpen={handleMakeItOpen}
        handleMakeItDelete={handleMakeItDelete}
        handleCloseConfirmation={handleCloseConfirmation}
        errors={errors}
        invoice={invoice}
      />

    </>
  )
}

MakeItPaidMenu.propTypes = {
  invoice: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
}

MakeItPaidMenu.defaultProps = {}

export default MakeItPaidMenu
