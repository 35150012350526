import {Box, Typography} from "@mui/material";
import Lottie from "lottie-react";
import loading from "../../../assets/animations/loading.json";
import noDescription from "../../../assets/animations/noDescription.json";
import {grey} from "@mui/material/colors";
import React from "react";

const NoDescription = ({size, fontSize}) => {
  return (

    <Box
      sx={{alignItems: 'center', display: 'flex', justifyContent: 'center', mt: '1rem', flexDirection: 'column'}}
    >
      <Box
        sx={{
          width: size
        }}
      >
        <Lottie animationData={noDescription} loop={false} />
      </Box>
      <Typography
        align={'center'}
        sx={{
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontSize: fontSize,
          fontWeight: 'bold',
          color: grey[400]
        }}

      >
        Nenhuma descrição adicionada
      </Typography>

    </Box>
  )
}

export default NoDescription