import {InputAdornment, TextField} from "@mui/material";
import {Notifications} from "@mui/icons-material";
import React from "react";


const MaintenanceNotification = ({state, setState, disabled}) => {
  return (
    <TextField
      label="Notificar quantos dias antes?"
      disabled={disabled}
      size={"small"}
      fullWidth
      sx={{marginBottom: '2rem'}}
      onChange={({ target: { value } })  => setState((prevState) => ({...prevState, notify_days_before: +value.replace(/[^0-9]/g, '')}))}
      value={state?.notify_days_before ?? ''}
      InputProps={{
        startAdornment:
          <InputAdornment position="start">
            <Notifications />
          </InputAdornment>
      }}
    />
  )
}

export default MaintenanceNotification