import React, {useContext, useEffect, useState} from 'react';
import {api} from "../../../services/Main/Api";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
    Backdrop,
    Box,
    Card,
    CardContent,
    CircularProgress,
    FormControlLabel,
    Grid,
    Modal,
    Switch,
    Typography
} from "@mui/material";
import Divider from "@mui/material/Divider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {getFilterDate, setFilterDate} from "../../../services/Main/AuthStorage";
import Context from "../../../contexts/Context";
import SimpleText from "../../../components/Shared/SimpleText";
import Masks from "../../../components/Shared/Masks";
import {ArrowBack} from "@mui/icons-material";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AnimationGlobal from "../../../components/Shared/animations/AnimationGlobal";
import successAnimation from '../../../assets/animations/success.json'
import {useNavigate} from "react-router-dom";


function CreateDuplicateExpense(props) {
    const storageDate = getFilterDate()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [newData, setNewData] = useState(null);
    const [total, setTotal] = useState();
    const [state, setState] = useState(null);
    const [open, setOpen] = useState(false);
    const [month, setMonth] = useState(moment(new Date(storageDate)).format("MM"))

    const [year, setYear] = useState(moment(new Date(storageDate)).format("YYYY"))
    const [activeBackdrop, setActiveBackdrop] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [filterDate, setFilterDate] = useState(false);
    const navigate = useNavigate()
    const [isAnimationCompleted, setIsAnimationCompleted] = useState(false);



    const handleClickOpen = () => {
        setOpen(true);
    };

    const RedirectNewDuplicate = () => {

        if (newData) {
            navigate(`/despesas/${newData.id}/editar`);
        }
    };
    const handleCreateDuplicate = () => {
        setActiveBackdrop(true)
        setLoading(true);
        const data = {
            billing_month: month,
            billing_year: year,
            total: total,
        }


        api.post(`expense/duplicate/${props.params.id}/`, data)

            .then(response => {
                setNewData(response.data)
                // setLoading(false);
                setOpen(false)
                setTimeout(() => {
                    // setOpen(false)
                    setIsSuccess(true)

                }, 2000);
            }).catch(error => {
            console.log(error)
        })
            .finally(data => {
                setTimeout(() => {
                    setActiveBackdrop(false)
                    setIsSuccess(false)
                    setIsAnimationCompleted(true)
                }, 4000);
            })
    }

    function handleSaveContract () {
        setOpen(false)
        setActiveBackdrop(true)
    }
    const RealValueChange = (event) => {
        const numString = event.target.value.replace(/R\$|,|\s/g, '');
        setTotal(numString);
    };

    useEffect(() => {
        if (newData && isAnimationCompleted) {
            navigate(`/despesas/${newData.id}/editar`);
        }
    }, [newData, isAnimationCompleted]);

    console.log(props, '1')
    console.log(newData, '2')
    return (
        <Box>
            <Button
                variant={"outlined"}
                startIcon={<ContentCopyOutlinedIcon/>}
                size={"small"}
                color={"inherit"}
                onClick={handleClickOpen}
            >
                Duplicar Cobrança
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>

                <Card sx={{width:'35rem', marginTop:'15rem', marginLeft:'40%'}}>
                    <Typography
                        variant={'h6'}
                        sx={{
                            p: '1rem',
                            ml: '.5rem'
                        }}
                    >
                        Duplicar Cobrança
                    </Typography>
                    <Divider sx={{ mb: '1rem' }}/>
                    <CardContent>
                        <form>
                                <DatePicker
                                    openTo={"month"}
                                    views={['year', 'month']}
                                    label="Mês de Pagamento"
                                    inputProps={{ readOnly: true }}
                                    disableMaskedInput
                                    value={(moment(`${month}-${year}`, 'MM-YYYY').format())}
                                    onChange={(newValue) => {
                                        setMonth(moment(new Date(newValue)).format("MM"))
                                        setYear(moment(new Date(newValue)).format("YYYY"))
                                        console.log(newValue)
                                        setFilterDate(moment(newValue, 'MM-YYYY').toISOString())
                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            size={'large'}
                                            sx={{
                                                mb: '1rem',
                                                width: '100%'
                                            }}
                                        />}
                                />

                            <TextField
                                label={'Total'}
                                key={'total'}
                                required
                                fullWidth
                                // type="text"
                                sx={{ mb: '2rem'}}
                                value={setTotal ? Masks?.money(total) : ''}
                                onChange={RealValueChange}
                            />
                        </form>
                        <Grid container>
                            <Grid item xs={6} align="left">
                                <Button
                                    type={"button"}
                                    variant={"outlined"}
                                    startIcon={<ArrowBack />}
                                    onClick={() => setOpen(false)}
                                >
                                    Voltar
                                </Button>
                            </Grid>

                            <Grid item xs={6} align="right">
                                <Button
                                    // type="submit"
                                    variant={'contained'}
                                    startIcon={<SaveOutlinedIcon />}
                                    onClick={() => {
                                        handleCreateDuplicate();
                                        handleSaveContract();
                                    }}
                                >
                                    Salvar
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={activeBackdrop}
            >
                { !isSuccess ? <CircularProgress color="inherit" />
                    : <AnimationGlobal
                        text={'Duplicação criada com sucesso!'}
                        size={'200px'}
                        animation={successAnimation}
                        fontStyles={{mb: '1rem'}}
                        fontSize={'20px'}
                        color="#FFFFFF"
                    />
                }
            </Backdrop>
        </Box>
    );
}

export default CreateDuplicateExpense;