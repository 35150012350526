import React, {useEffect, useState} from "react";
import { Helmet } from 'react-helmet'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Skeleton,
  Typography,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import {AddBusiness, ArrowBack } from "@mui/icons-material";
import {api} from "../../../services/Main/Api";
import {useNavigate, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import ProviderReadInfo from "./components/ServiceDetail/ProviderReadInfo";
import ProviderReadAdress from "./components/ServiceDetail/ProviderReadAdress";
import ProviderInfo from "./components/ServiceCreateAndUpdate/ProviderInfo";
import ProviderAdress from "./components/ServiceCreateAndUpdate/ProviderAdress";
import SimpleButtons from "../../../components/Shared/SimpleButtons";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import {getCondoId} from "../../../services/Main/AuthStorage";

const ServiceDetailAndUpdate = () => {
  const [data, setData] = useState({})
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [edit, setEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [error, setError] = useState(null)


  const {enqueueSnackbar} = useSnackbar()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const {id} = useParams()

  const navigate = useNavigate()

  const [jobs, setJobs] = useState([])


  useEffect(function jobLoad(){
    api.get('/service/profession/')
      .then(response => {
        setJobs(response.data.results)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(()=> {
        setIsLoading(false)
      })

  }, [])



  useEffect(function readDataLoad(){
    api.get(`/service/provider/${id}`)
      .then(response => {
        setData(response.data)
      })
      .catch(error => {
        console.log(error)
      })

  }, [])

  const deleteProvider = () => {
    api.delete(`/service/provider/${id}/`)
      .then(response => {
        navigate('/servicos/')
        enqueueSnackbar("Prestador de Serviço Deletado com Sucesso!", {variant: "success"})
      })

  }

  const update = (e) => {
    e.preventDefault()

    Object.assign(data, { condo: getCondoId()})
    Object.assign(data, { profession: jobs?.find((e) => (e?.name?.toString().toLowerCase() === data?.profession?.toString().toLowerCase()))?.id})

    api.put(`/service/provider/${id}`, data)
      .then(response => {
        console.log(response)
        enqueueSnackbar("Prestador de Serviço Atualizado com Sucesso!", {variant: "success"})
        navigate('/manutencao/servicos/')
      })
      .catch(error => {
        console.log(error?.response?.data)
        setError((error?.response?.data))
      })

  }

  return (
    <>
      <Helmet>
        <title>Detalhes do Prestador - Bloco B3</title>
      </Helmet>
      <Container
        fixed
        noValidate
        sx={{marginBottom: '3em'}}
      >
        {!edit ?
        <>
          <ProviderReadInfo
            data={data}
            jobs={jobs}
            isLoading={isLoading}
          />

          <ProviderReadAdress
            data={data}
            isLoading={isLoading}
          />
        </>
          :
          <>
            <ProviderInfo
              data={data}
              setData={setData}
              jobs={jobs}
              isLoading={isLoading}
            />

            <ProviderAdress
              data={data}
              setData={setData}
              isLoading={isLoading}
            />
          </>

        }

        <SimpleDialog
          open={openDelete ?? false}
          setOpen={setOpenDelete}
          title={"Excluir Prestador de Serviço"}
          question={'Você tem certeza que deseja excluir esse prestador de serviços?'}
          functionProp={deleteProvider}
        />


        {/*<Box sx={{display: "flex", justifyContent: "space-between", marginY: "1rem"}}>*/}

        {/*  <Button type={"button"} variant={"outlined"}*/}
        {/*          startIcon={<ArrowBack/>}*/}
        {/*          onClick={(e) => {*/}
        {/*            window.history.back()*/}
        {/*          }}>{"Voltar"}</Button>*/}
        {/*  <Box>*/}
        {/*    <Button*/}
        {/*      variant="contained"*/}
        {/*      size="large"*/}
        {/*      onClick={handleClickOpen}*/}
        {/*    >*/}
        {/*      {"Excluir"}*/}
        {/*    </Button>*/}
        {/*    <Dialog*/}
        {/*      open={open}*/}
        {/*      onClose={handleClose}*/}
        {/*    >*/}
        {/*      <DialogTitle id="alert-dialog-title">*/}
        {/*        {"Excluir Prestador de Serviço"}*/}
        {/*      </DialogTitle>*/}
        {/*      <DialogContent>*/}
        {/*        <DialogContentText id="alert-dialog-description">*/}
        {/*          Você tem certeza que deseja excluir esse prestador de serviços?*/}
        {/*        </DialogContentText>*/}
        {/*      </DialogContent>*/}
        {/*      <DialogActions*/}
        {/*        sx={{*/}
        {/*          display: 'flex',*/}
        {/*          justifyContent: 'space-between',*/}
        {/*          margin: '1rem'*/}
        {/*        }}*/}
        {/*        >*/}
        {/*        <Button*/}
        {/*          onClick={handleClose}*/}
        {/*          >*/}
        {/*          Cancelar*/}
        {/*        </Button>*/}
        {/*        <Button*/}
        {/*          onClick={deleteProvider}*/}
        {/*          autoFocus>*/}
        {/*          Sim, excluir*/}
        {/*        </Button>*/}
        {/*      </DialogActions>*/}
        {/*    </Dialog>*/}
        {/*    <Button*/}
        {/*      sx={{*/}
        {/*        ml: '1rem'*/}
        {/*      }}*/}
        {/*      variant="contained"*/}
        {/*      size="large"*/}
        {/*    >*/}
        {/*      {"Editar"}*/}
        {/*    </Button>*/}
        {/*  </Box>*/}
        {/*</Box>*/}
        {!edit ?
        <SimpleButtons
          type={'button'}
          forwardButton={'editar'}
          onForward={() => setEdit(true)}
          deleteButton={true}
          onDelete={() => setOpenDelete(true)}
        />
          :
        <SimpleButtons
          type={'button'}
          forwardButton={'Salvar'}
          onForward={(e) => update(e)}
          cancelButton={true}
          onCancel={() => setEdit(false)}
        />
        }


      </Container>
    </>
  )
}


export default ServiceDetailAndUpdate;
