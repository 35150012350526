import moment from "moment";

export const setLocalUser = user => {
    let localUser = JSON.stringify(user);
    return window.localStorage.setItem("user", localUser);
}

export const getLocalUser = () => {
    let user = window.localStorage.getItem("user");
    return JSON.parse(user);
}

export const removeLocalUser = () => {
    return window.localStorage.removeItem("user");
}

export const getCondoId = () => {
    return  window.localStorage.getItem("condoId");
}

export const getCondoName = () => {
    return  window.localStorage.getItem("condoName")
}

export const getCondoType = () => {
    return  window.localStorage.getItem("condoType")
}

export const setCondoName = name => {
    return window.localStorage.setItem("condoName", name);
}

export const setCondoType = name => {
    return window.localStorage.setItem("condoType", name);
}

export const removeCondoType = () => {
    return window.localStorage.removeItem("condoType");
}
export const removeCondoName = () => {
    return window.localStorage.removeItem("condoName");
}

export const setCondoId = id => {
    return window.localStorage.setItem("condoId", id);
}

export const removeCondoId = () => {
    return window.localStorage.removeItem("condoId")
}

export const setFilterDate = (date) => {
    return window.localStorage.setItem("dateFilter", date);
}

export const getFilterDate = () => {
    return window.localStorage.getItem("dateFilter");
}

export const removeFilterDate = () => {
    return window.localStorage.removeItem("dateFilter");
}

export const setLocalUnitUser = user => {
    let localUser = JSON.stringify(user);
    return window.localStorage.setItem("unitUser", localUser);
}

export const localUnitUser = () => {
    let user = window.localStorage.getItem("unitUser");
    return JSON.parse(user);
}

export const removeLocalUnitUser = () => {
    return window.localStorage.removeItem("unitUser");
}

