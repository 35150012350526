import {Button, Container, Grid, Stack, Typography} from "@mui/material";
import React from "react";
import {Outlet} from "react-router-dom";
import ComplaintSideBar from "./ComplaintSideBar";


const ComplaintMain = () => {
  return (
    <Container maxWidth={false}>
      <Stack mb={4}>
        <Typography variant={'h5'}>{"Reclamações"}</Typography>
      </Stack>
      <Grid container spacing={{xs: 2}} direction={'row-reverse'}>
        <Grid item xs={12} md={4} xl={3}>
          <ComplaintSideBar/>
        </Grid>
        <Grid item xs={12} md={8} xl={9}>
          <Outlet/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ComplaintMain;