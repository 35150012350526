import {Button, Link} from "@mui/material";
import { Helmet } from "react-helmet";
import {Container} from "@mui/system";

export const NoMatch = () => {
    return (
        <>
            <Helmet>
                <title>Página Não Encontrada - Bloco B3</title>
            </Helmet>
            <Container>
                <h2>Página não encontrada</h2>
                <p>
                    <Button href="/">Voltar para página principal</Button>
                </p>
            </Container>
        </>
    );
}
