import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import {
  Backdrop,
  Box,
  Card,
  Container,
  Grid,
  Pagination,
  Skeleton,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme
} from '@mui/material'
import {
  getCondoId,
  getFilterDate
} from '../../../services/Main/AuthStorage'
import {
  AddCircleOutlined,
  AttachMoneyOutlined,
  CircleOutlined,
  CleaningServicesOutlined,
  HomeRepairServiceOutlined,
  ManageAccountsOutlined,
  OpacityOutlined,
  PowerOutlined,
  PropaneTankOutlined,
  SavingsOutlined,
  ViewList, Water
} from '@mui/icons-material'
import { api } from '../../../services/Main/Api'
import { visuallyHidden } from '@mui/utils'
import moment from 'moment'
import 'moment/locale/pt-br'
import { ExpenseCategory } from '../../../components/Filters/ExpenseCategory'
import { useSnackbar } from 'notistack'
import ReferenceDate from '../../../components/Filters/ReferenceDate'
import { Helmet } from 'react-helmet'
import Context from '../../../contexts/Context'
import category from "../Category/Category";
import TableShape from "./components/ExpenseList/TableShape";
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import CircularProgress from "@mui/material/CircularProgress";


function descendingComparator (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort (array) {
  const stabilizedThis = array.map((el, index) => [el, index])
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  {
    id: 'category',
    align: 'left',
    disablePadding: true,
    label: 'Descrição',
  },
  {
    id: 'unit',
    align: 'left',
    disablePadding: true,
    label: 'Imóvel',
  },
  {
    id: 'created_at',
    align: 'left',
    disablePadding: true,
    label: 'CRIADO EM',
  },
  {
    id: 'billing_month',
    align: 'left',
    disablePadding: true,
    label: 'VENCIMENTO',
  },
  {
    id: 'total',
    align: 'right',
    disablePadding: true,
    label: 'TOTAL',
  },
]

function EnhancedTableHead (props) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }


  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{ color: 'primary.main' }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell/>
      </TableRow>
    </TableHead>
  )
}

const ExpenseFilters = ({ response }) => {
  const [categorySelected, setCategorySelected] = useState('')
  const [categoryList, setCategoryList] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const { setFilters, condoIdLogin } = useContext(Context)
  const condo = getCondoId()



  useEffect(function getCategories(){
      api.get(`category/?condo_id=${condoIdLogin ?? condo}`).then(response => {
        setCategoryList(response?.data?.results?.map(element => ({
                  'CATEGORY':
                      <Box
                          sx={{
                            display: 'flex',
                          }}
                      >
                        <ViewList
                            sx={{
                              color: 'primary.main'
                            }}
                        />
                        <Typography
                            ml={1}
                            color="primary.main"
                            fontWeight="bold"
                        >
                          Todas Categorias
                        </Typography>
                      </Box>,

                  [element?.slug]:
                      <Box
                          sx={{
                            display: 'flex',
                          }}
                      >
                        {
                          element?.slug === 'WATER_READING' ? <Water sx={{ color: 'secondary.light'}}/>
                              :
                          element?.slug === 'WATER' ? <OpacityOutlined sx={{ color: 'secondary.light'}}/>
                              :
                          element?.slug === 'ENERGY' ?  <PowerOutlined sx={{ color: 'secondary.light'}}/>
                              :
                          element?.slug === 'RESERVE_FUND' ? <SavingsOutlined sx={{ color: 'secondary.light'}}/>
                              :
                          element?.slug === 'GAS' ? <PropaneTankOutlined sx={{ color: 'secondary.light'}}/>
                              :
                          element?.slug === 'ADMINISTRATION_FEE' ? <ManageAccountsOutlined sx={{ color: 'secondary.light'}}/>
                              :
                          element?.slug === 'CLEANING_FEE' ? <CleaningServicesOutlined sx={{ color: 'secondary.light'}}/>
                              :
                          element?.slug === 'DISCOUNT' ? <AttachMoneyOutlined sx={{ color: 'secondary.light'}}/>
                              :
                          element?.slug === 'SERVICE_FEE' ? <HomeRepairServiceOutlined sx={{ color: 'secondary.light'}}/>
                              :
                          <CircleOutlined sx={{ color: 'secondary.light'}}/>

                        }
                        <Typography
                            ml={1}

                            fontWeight="bold"
                        >
                          {element?.description}
                        </Typography>
                      </Box>,

                })
            )
        )
      }).catch(reason => {
        console.log(reason)
      })

  }, [])


  useEffect(function dinamicCategory(){
    setCategoryOptions(
        categoryList.reduce(function(result, current) {
          return Object.assign(result, current);
        }, {})
    )
  }, [categoryList])

  const handleCategoryChange = (e) => {
    setFilters(filters => ({
      ...filters,
      categories: e.target.value,
    }))
    setCategorySelected(e.target.value)
    if (e.target.value === 'CATEGORY') {
      setFilters(filters => ({
        ...filters,
        categories: '',
      }))
      setCategorySelected('CATEGORY')
    }
  }

  return (
    <Grid container spacing={1}  sx={{ p: 2, py: 3, alignItems: 'center'}}>
      <Grid item xs={12} sm={4} lg={3}>
        <ExpenseCategory selected={categorySelected} values={categoryOptions} onChange={handleCategoryChange}/>
      </Grid>
      <Grid item xs={12} sm={4} lg={3}>
        <ReferenceDate response={response} setFilters={setFilters}/>
      </Grid>
    </Grid>
  )
}

const ExpenseList = () => {
  const [response, setResponse] = useState(null)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('id')
  const { enqueueSnackbar } = useSnackbar()
  const [date, setDate] = useState(new Date(moment().format('MM/yyyy')))
  const [loading, setLoading] = useState(false)

  const storageDate = getFilterDate()

  // useEffect(function preventError() {
  //   // const date = storageDate ? moment(new Date(storageDate)).format() : moment().format()
  //   setFilterDate(date)
  // }, [])

  const theme = useTheme()

  const { filters, setFilters } = useContext(Context)


  useEffect(function initialFilters(){
    const monthFormat = +moment(storageDate).format("MM")
    const yearFormat = +moment(storageDate).format("YYYY")

    getCondoId() && storageDate && setFilters(prevState => ({
      ...prevState,
      page_size: 50,
      condo_id: getCondoId(),
      billing_month: monthFormat,
      billing_year: yearFormat
    }))
  },[])

  const handlePage = (e, value) => {
    setFilters({...filters, page: value})
  }

  const currency = (value) => new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleDelete = (e, id = null) => {
    if (id) {
      setLoading(true)
      api.delete(`expense/${id}/`).then(() => {
        getExpenses(filters)
        enqueueSnackbar('Despesa excluída com sucesso!', { variant: 'success' })
      }).catch(response => {
        console.log(response)
      }).finally(() =>{
        setLoading(false)
      })
    }
  }

  const getExpenses = (filters) => {
      api.get(`expense/`, { params: filters })
        .then(response => {
          setResponse(response?.data)
      }).catch(error => {
        console.log(error)
      })

    }

  useEffect(() => {
    if (filters?.billing_month) {
        getExpenses(filters)
    }
  }, [filters])

  return (
    <>
      <Helmet>
        <title>Despesas - Bloco B3</title>
      </Helmet>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1500, }}
        open={loading}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
      <Container
        maxWidth={false}
      >

          <TitleBtnList
            title={'Despesas'}
            buttonText={'Criar Despesa'}
            buttonHref={'/despesas/criar'}
            icon={<AddCircleOutlined />}
          />
          <>
          <Card>
          {response?.results ?
            <>
            <ExpenseFilters
              setFilters={setFilters}
              date={date}
              setDate={setDate}
              response={response}
            />
                <TableContainer>
                  {response?.results?.length > 0  ?
                      <Table sx={{minWidth: 800}}>
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableShape
                          stableSort={stableSort}
                          response={response}
                          getComparator={getComparator}
                          order={order}
                          orderBy={orderBy}
                          currency={currency}
                          handleDelete={handleDelete}
                        />
                      </Table>
                      :
                    <NoResultsAnimation />
                  }
                </TableContainer>
            </>
                :
                  <Skeleton variant="rounded" height={500} animation="pulse" />
            }
            </Card>
          </>
            {response?.results &&
              <Box spacing={2} display={'flex'} justifyContent={'center'} sx={{ m: 3 }}>
                <Pagination
                  count={Math.ceil(+response?.count / filters.page_size)}
                  variant={'outlined'}
                  color={'primary'}
                  onChange={handlePage}
                />
              </Box>
            }
      </Container>


    </>
  )
}

export default ExpenseList
