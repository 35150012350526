import React, {
  useContext,
  useEffect
} from "react";
import {
  Box, Skeleton,
  Typography
} from "@mui/material";
import UnitFilter from "../../../../../../../components/Filters/UnitFilter";
import Context from "../../../../../../../contexts/Context";

const ComplaintTitle = ({
    edit,
    dataUpdate,
    setDataUpdate,
  }) => {
  const { unitOptions, unitFormat } = useContext(Context)

  useEffect(function units (){
    unitFormat()
  },[])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >


      {!edit &&
        <Typography variant="h6">
          {dataUpdate?.unit?.number ?? '- - -'} | {dataUpdate?.unit?.name ?? '- - -'}
        </Typography>
      }

      {edit && unitOptions &&
        <UnitFilter
          defaultValue={unitOptions?.find(e => e.value === dataUpdate?.unit?.id)}
          state={dataUpdate}
          setState={setDataUpdate}
          keyName={'unit'}
          variant={'standard'}
          styles={{
            width: '70%',
          }}
        />
      }

      {!edit &&
        <Typography
          sx={{
            ml: '1rem',
            color: dataUpdate?.status === 'OPEN' ? '#0f9d0a' : 'primary.main'
          }}
        >
          {dataUpdate?.status === 'OPEN' ? 'Em Aberto' : 'Fechada'}
        </Typography>
      }

    </Box>
  )
}

export default ComplaintTitle