import * as React from 'react'
import { alpha, styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import {
  Apartment,
  Home,
  Receipt,
  ReceiptLong,
  Settings,
  PeopleAlt,
  SafetyDivider,
  Announcement,
  CalendarMonth,
} from '@mui/icons-material'
import logo from '../../assets/images/blocob3-logo-dark.png'
import logoText from '../../assets/images/blocob3-text-dark.png'
import CssBaseline from '@mui/material/CssBaseline'
import { useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material'

const drawerWidth = 250

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

const SideBarUnitUser = (props) => {
  const theme = useTheme()
  const { window } = props
  const location = useLocation()

  const container = window !== undefined ? () => window().document.body : undefined

  const activeRootStyle = {
    // color: 'primary.main',
    fontWeight: 'bold',
    bgcolor: alpha(theme.palette.secondary.light, theme.palette.action.selectedOpacity),
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.secondary.light, theme.palette.action.selectedOpacity)
    },
    '&.Mui-selected:hover': {
      backgroundColor: alpha(theme.palette.secondary.light, theme.palette.action.activatedOpacity)
    }
  }

  const sideBarContent = (
    <div>
      <DrawerHeader>
        <Box component={'div'} sx={{ display: 'flex', alignItem: 'center', pb: 5, pt: 1 }}>
          <img src={logo} alt="" style={{ height: 50 }}/>
          <img src={logoText} alt="" style={{ height: 24, marginLeft: 20, marginTop: 18 }}/>
        </Box>
      </DrawerHeader>
      <List>
        <ListItem key={'invoice'} disablePadding sx={{ my: 2 }}>
          <ListItemButton href={'/usuario/faturas/'} selected={location.pathname.indexOf('/usuario/faturas/') > -1}
                          sx={{ ...(location.pathname.indexOf('/faturas') > -1 && activeRootStyle) }}>
            <ListItemIcon>
              <Receipt sx={{ color: 'neutral.main' }}/>
            </ListItemIcon>
            <ListItemText primary={'Faturas'}/>
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline/>
      <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }} aria-label="Bloco B3">
        <Drawer
          container={container}
          variant="temporary"
          open={props.mobileMenu}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          PaperProps={{
            sx: {
              backgroundColor: 'secondary.main',
              color: 'neutral.main'
            }
          }}>
          {sideBarContent}
        </Drawer>
        <Drawer
          variant="permanent"
          open
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          PaperProps={{
            sx: {
              backgroundColor: 'secondary.main',
              color: 'neutral.main'
            }
          }}>
          {sideBarContent}
        </Drawer>
      </Box>
    </Box>
  )
}

export default SideBarUnitUser
