import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import React from "react";

const MaintenanceStatus = ({state, setState, disabled}) => {
  return (
  <>
    <label htmlFor={'status'}>Situação da Manutenção?</label>
    <ToggleButtonGroup
      fullWidth
      disabled={disabled}
      name={'status'}
      exclusive
      value={state?.status ?? ''}
      color={'primary'}
      onChange={(e, value) => setState(prevState => ({
        ...prevState,
        status: value ?? 'OPEN'
      }))}
      sx={{ mb: '1rem', mt: '0.5rem'}}
    >
      <ToggleButton value={"OPEN"} selected={state?.status === 'OPEN'}>Em aberto</ToggleButton>
      <ToggleButton value={"FINISHED"} selected={state?.status === 'FINISHED'}>Finalizado</ToggleButton>
      <ToggleButton value={"IN_PROGRESS"} selected={state?.status === 'IN_PROGRESS'}>Em andamento</ToggleButton>
    </ToggleButtonGroup>
  </>
  )
}

export default MaintenanceStatus