import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Button, Dialog, TextField, useMediaQuery} from "@mui/material";
import * as React from "react";

const SignedRecipentTransferMakePaid = ({ openByStatus, handleCloseConfirmation, handleMakeItFinalized, reason, setReason }) => {
    const sizeMatch = useMediaQuery('@media (min-width:600px)')

    return (
        <Dialog
            fullScreen={!sizeMatch}
            sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '600px', maxHeight: 435 }, padding: 2 }}

            maxWidth="xs"
            open={openByStatus.finalized ?? !!openByStatus.finalized }
            onClick={event => event.stopPropagation()}
        >
            <DialogTitle>{"Baixa Manual"}</DialogTitle>
            <DialogContent>
                {"Deseja realmente marcar esta repasse como finalizado?"}
                <br/>
                <TextField
                    id="reason-for-finishing"
                    label="Motivo"
                    fullWidth
                    margin="normal"
                    value={reason}
                    onChange={e => setReason(e.target.value)}
                />
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button autoFocus onClick={handleCloseConfirmation}>{"Cancelar"}</Button>
                <Button variant={"contained"} onClick={handleMakeItFinalized}>{"Confirmar"}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SignedRecipentTransferMakePaid