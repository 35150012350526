import {Avatar, Card, CardContent, CardHeader, Divider, Grid} from "@mui/material";
import {Badge} from "@mui/icons-material";
import SimpleText from "../../../../../components/Shared/SimpleText";
import AutoCompleteJobs from "./AutoCompleteJobs";

const ProviderInfo = ({ data, setData, jobs, error, isLoading }) => {
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar sx={{bgcolor: "primary.main"}}>
            <Badge/>
          </Avatar>
        }
        title={"Prestador de Serviço"}
      />
      <Divider light sx={{marginY: '.3rem'}}/>
      <CardContent>
        <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
        >
          <Grid
            item
            xs={12}
            lg={6}
          >
            <SimpleText
              label={'Nome'}
              required
              state={data}
              setState={setData}
              keyName={'name'}
              error={error}
              isLoading={isLoading}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
          >
            <SimpleText
              label={'Telefone'}
              phone={true}
              state={data}
              setState={setData}
              keyName={'phone'}
              error={error}
              isLoading={isLoading}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
          >
            <SimpleText
              label={'Celular'}
              phone={true}
              state={data}
              setState={setData}
              keyName={'cell'}
              error={error}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <SimpleText
              label={'E-mail'}
              state={data}
              setState={setData}
              keyName={'email'}
              error={error}
              isLoading={isLoading}
            />
          </Grid>
          <AutoCompleteJobs
            data={data}
            setData={setData}
            jobs={jobs}
            isLoading={isLoading}
          />
          <Grid
            item
            xs={12}
            lg={6}
          >
            <SimpleText
              label={'Rede / Site'}
              state={data}
              setState={setData}
              keyName={'social_media_url_1'}
              error={error}
              isLoading={isLoading}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
          >
            <SimpleText
              label={'Rede / Site'}
              state={data}
              setState={setData}
              keyName={'social_media_url_2'}
              error={error}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ProviderInfo