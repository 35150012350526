import React, { useContext, useEffect, useState } from 'react'
import {
    Accordion,
    AccordionActions, AccordionDetails, AccordionSummary, Alert,
    Backdrop, Badge,
    Box, Button,
    Card, CardActions, CardHeader, Checkbox,
    Chip,
    Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl,
    Grid, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import { api } from '../../../services/Main/Api'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import CircularProgress from '@mui/material/CircularProgress'
import Context from '../../../contexts/Context'

import NoResultsAnimation from '../../../components/Shared/animations/NoResultsAnimation'
import { Alarm, CalendarMonthOutlined, Search } from '@mui/icons-material'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Masks from '../../../components/Shared/Masks'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import {getCondoId} from "../../../services/Main/AuthStorage";


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {useSnackbar} from "notistack";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Reajust = () => {
    const navigate = useNavigate()
    const [response, setResponse] = useState(null)
    const [responseDetail, setResponseDetail] = useState(null)
    const [igpmIndex, setIgpmIndex] = useState(null)
    const [igpmValue, setIgpmValue] = useState(null)
    const [open, setOpen] = useState(false);
    const [newValue, setNewValue] = useState(null);
    const [value, setValue] = useState(null);
    const [chargeId, setChargeId] = useState(null);
    const [contrctId, setContrctId] = useState(moment().startOf('date').format('YYYY'));
    const [month, setMonth] = useState(moment().startOf('date').format('MM'));
    const {enqueueSnackbar} = useSnackbar()
    const [loading, setLoading] = useState(false)



  function getData(){
    setLoading(true)


    let filter = { condo_id: getCondoId(),}


    api.get(`readjust/`, {params: filter})
      .then(response => {
        const data = response.data
        setResponse(data)
        setLoading(false)
      }).catch(error => {
        console.log(error)
        setLoading(false)
    })
  }

  useEffect(() => {
    getData()
  }, [])



    String.prototype.toProperCase = function () {
        return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    };


    const getChargeDetails = (data) => {
        setOpen(true);
        if (data) {
            api.get(`readjust/${data.id}/`)
                .then(response => {
                    const responseData = response.data.data;
                    console.log(responseData.new_value, '1')
                    setResponseDetail(responseData);
                    setIgpmValue(responseData.index_average);
                    setNewValue(responseData.new_value);
                    setChargeId(responseData.id);
                    setValue(responseData.value)
                    setIgpmIndex(responseData.readjustment_index);
                    setContrctId(responseData.rent_contract_id);
                    setLoading(false)
                }).catch(error => {
                console.log(error)
                setLoading(false)
            })
        } else {
            console.log("Data is not defined");
        }
    }

    function handleSave() {
        setLoading(true);
        if (chargeId) {
            const data = {
                value: newValue,
                id: chargeId
            }
            api.post(`/readjust/`, data)
                .then(response => {
                    setLoading(false);
                    enqueueSnackbar('Contrato Reajustado com Sucesso', { variant: 'success' })
                    setTimeout(() => {
                        window.location.href = `/contratos/${contrctId}`;
                    }, 200);
                })
                .catch(error => {
                    setLoading(false);
                    enqueueSnackbar('Erro ao Reajustar o Contrato', {variant: 'error'})
                    console.log(error)
                })
        }
        setOpen(false);
    }


  return (
    <>
      <Helmet>
        <title>Reajustes - Bloco B3</title>
      </Helmet>
        <Backdrop
            sx={{ color: '#fff', zIndex: 1500, }}
            open={loading}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
      <Container maxWidth={false}>
      <Box
        sx={{
          display: 'flex',
          '@media (max-width:600px)': {
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            gap: 3,
          },
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,

        }}
      >
        <Typography
          textAlign={'center'}
          variant={'h5'}
        >
          Reajustes e Renovações
        </Typography>

      </Box>
      </Container>
      <Container maxWidth={false}>

          {response?.results ?
            response?.results.map(item =>

              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"

                >
                      <Box sx={{  display: 'flex' }}>
                        <CalendarMonthOutlined color={'primary'} sx={{mr: 1}} />
                        <Typography  variant={'button'} color={'primary'}>
                          {moment(item.month, 'YYYY-MM-DD').format('MMMM [de] YYYY')}
                        </Typography>

                      </Box>
                  {item.count > 0 ?
                    <Chip color={'info'} variant="outlined"  sx={{ml: 2}} size={'small'} label={`${item.count} itens`}></Chip>
                    :
                    <Chip variant="outlined"  sx={{ml: 2}} size={'small'} label={`${item.count} itens`}></Chip>
                  }
                </AccordionSummary>
                <AccordionDetails>

                  <TableContainer>
                    {item.items?.length > 0  ?
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Categoria</TableCell>
                            <TableCell>Contrato</TableCell>
                            <TableCell align={'center'}>Vencimento</TableCell>

                            <TableCell>Valor</TableCell>
                            <TableCell>Indice</TableCell>
                            <TableCell></TableCell>


                          </TableRow>
                        </TableHead>
                        <TableBody>


                          { item.items.map(data =>
                            <TableRow
                              key={data.id}
                              hover
                              sx={{'cursor': 'pointer'}}

                              // onClick={(e) => {
                              //   e.stopPropagation()
                              //   navigate(`/repasses/${item.id}`)
                              // }}
                            >
                              <TableCell>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  {data.number}
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                 {data.category}
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  {data.rent_contract}
                                </Box>
                              </TableCell>
                              <TableCell align={'center'}>
                                {moment(data.reference_end_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                              </TableCell>



                              <TableCell>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  {Masks.money(data.value.toString())}
                                </Box>
                              </TableCell>
                                <TableCell>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {data.readjustment_index}
                                    </Box>
                                </TableCell>

                              <TableCell align="center">
                                  {data?.readjustment_format === 'AUTOMATIC' ?
                                  ( data.category === 'Aluguel' &&
                                      <Tooltip
                                          title="Este reajuste será realizado automaticamente, conforme definido no contrato, não sendo possível reajustá-lo manualmente.">
                                      <Chip
                                          label="Automático"
                                          color="success"
                                          variant="outlined"
                                      />
                                          </Tooltip>
                                  )
                                  :
                                      ( data.category === 'Aluguel' &&
                                  <Button size="small" variant="outlined" color="primary" onClick={() => {
                                      getChargeDetails(data);
                                  }}>
                                      Reajustar
                                  </Button>
                                      )
                                  }
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                      :
                      <NoResultsAnimation />
                    }
                  </TableContainer>
                </AccordionDetails>
              </Accordion>)

            :
            <Skeleton
              variant="rounded"
              height={700}
              animation="pulse"
            />
          }
      </Container>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Reajuste</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Alert
                        variant={'filled'}
                        severity={'warning'}
                        sx={{mb:1}}
                    >
                        Atenção: o melhor dia para o reajuste será no dia 1º do mês seguinte, pois o índice do mês atual só sairá no dia 30 deste mês.
                    </Alert>
                </DialogContentText>
                <DialogContentText sx={{mb: 1}}>
                    Valor - {Masks.money(String(value ?? ''))}
                </DialogContentText>
                <DialogContentText sx={{mb: 1}}>
                    Indice - { (igpmIndex && igpmValue) ? `${igpmIndex} - ${igpmValue}` : "índice não definido" }
                </DialogContentText>
                <DialogContentText sx={{mb: 1}}>
                    Por favor, confirme ou insira o novo valor para o reajuste do aluguel para os próximos 12 meses.                </DialogContentText>
                <TextField
                    id="name"
                    label="Novo valor"
                    fullWidth
                    value={Masks.money(String(newValue))}
                    onChange={(event) => {
                        const numericValue = event.target.value.replace(/[^0-9.-]+/g,"");
                        setNewValue(numericValue);
                    }}
                />
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', mx: 1 }}>
                <Button onClick={() => setOpen(false)}>Fechar</Button>
                <Button variant="contained" size={'small'} color={"primary"} onClick={handleSave}>Salvar</Button>
            </DialogActions>
        </Dialog>
    </>
)
}

export default Reajust
