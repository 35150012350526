import { Box, Chip, Popover, Skeleton, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { grey } from '@mui/material/colors'

const SlipStatus = (props) => {
  const [status, setStatus] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (props.status) {
      let color = 'default'
      if (props.status.key === 0) color = 'info'
      else if (props.status.key === 1) color = 'secondary'
      else if (props.status.key === 2) color = 'info'
      else if (props.status.key === 3) color = 'warning'
      else if (props.status.key === 4) color = 'error'
      else if (props.status.key === 5) color = 'success'
      else if (props.status.key === 7) color = 'default'
      else if (props.status.key === 8) color = 'error'
      else if (props.status.key === 9) color = 'primary'
      setStatus({
        color: color,
        label: props.status.value
      })
    }

  }, [props.status])

  return (
    <>
      {status ?
        <>
          <Chip
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            color={status?.color}
            size="small"
            variant="outlined"
            label={status?.label}
          />
          {props.status &&
            <Popover
              id="paid-at-popover"
              sx={{
                pointerEvents: 'none',
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Box sx={{ p: 1 }}>
                <Typography fontSize={'small'}>{status?.label}</Typography>
              </Box>
            </Popover>
          }
        </>
        : <Skeleton variant="text" animation="pulse" width={100} height={20}/>}
    </>
  )
}

export default SlipStatus
