import {Grid, Skeleton, Typography} from "@mui/material";
import React from "react";
import {Close} from "@mui/icons-material";

const ProviderReadComponent = ({ isLoading, dataText, text  }) => {
  return (
    <Grid
      item
      xs={6}
    >
      {!isLoading ?
        <Typography>
          <Typography
            component={'span'}
            sx={{
              mr: '1rem',
              fontWeight: 'bold'
            }}
          >
            {text}
          </Typography>
          {dataText ?? <Close sx={{color: 'error.main'}}/>}
        </Typography>
        :
        <Skeleton
          variant="rounded"
          animation="pulse"
          height={24}
        />
      }
    </Grid>
  )
}

export default ProviderReadComponent