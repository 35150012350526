import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import Masks from "../../../../components/Shared/Masks";
import {Box} from "@mui/material";

const EscrowRead =
  ({
     edit,
     guarantee,
     guaranteeData,
   }) => {
    return (
      <>
        {guarantee === 'deposit' && !edit &&
          <Box>
            {guaranteeData?.description &&
              <SimpleTypography
                label={'Descrição'}
                directValue={guaranteeData?.description}
                labelStyles={{
                  fontWeight: 'bold'
                }}
                boxStyles={{
                  width: '100%',
                  mb: '1rem'
                }}
              />
            }


            {guaranteeData?.value &&
              <SimpleTypography
                label={'Valor'}
                directValue={Masks?.money(guaranteeData?.value?.toString() ?? '') ?? ''}
                labelStyles={{
                  fontWeight: 'bold'
                }}
                boxStyles={{
                  width: '100%',
                  mb: '1rem'
                }}
              />
            }

          </Box>
        }
      </>
    )
  }

export default EscrowRead