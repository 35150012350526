import {useEffect, useState} from "react";
import { Helmet } from 'react-helmet'
import {
  InputAdornment,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
  MenuItem, IconButton,
} from '@mui/material'
import {AddBusiness, ArrowBack, Badge, Search, SearchOff} from "@mui/icons-material";
import {api} from "../../../services/Main/Api";
import {getCondoId} from "../../../services/Main/AuthStorage";
import Masks from '../../../components/Shared/Masks'
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import SimpleText from "../../../components/Shared/SimpleText";
import AutoCompleteJobs from "./components/ServiceCreateAndUpdate/AutoCompleteJobs";
import ProviderInfo from "./components/ServiceCreateAndUpdate/ProviderInfo";
import AutoCompleteGlobal from "../../../components/Shared/AutoCompleteGlobal";
import SimpleSelector from "../../../components/Shared/SimpleSelector";
import SimpleButtons from "../../../components/Shared/SimpleButtons";
import ProviderAdress from "./components/ServiceCreateAndUpdate/ProviderAdress";

const ServiceCreate = () => {
  const [data, setData] = useState({})
  const [error, setError] = useState(null)
  const [jobs, setJobs] = useState([])

  const {enqueueSnackbar} = useSnackbar()


  const navigate = useNavigate()

  useEffect(function firstLoad(){
    api.get('/service/profession/')
      .then(response => {
        setJobs(response.data.results)
      })
      .catch(error => {
        console.log(error)
      })

  }, [])


  const handleSubmit = (e) => {
    e.preventDefault()

    Object.assign(data, { condo: getCondoId()})
    Object.assign(data, { profession: jobs?.find((e) => (e?.name?.toLowerCase() === data?.profession?.toLowerCase()))?.id})

    api.post('/service/provider/', data)
      .then(response => {
        enqueueSnackbar("Prestador de Serviço Cadastrado com Sucesso!", {variant: "success"})
        navigate('/manutencao/servicos/')
      })
      .catch(error => {
        console.log(error?.response?.data)
        setError((error?.response?.data))
      })

  }

  return (
    <>
      <Helmet>
        <title>Cadastrar Prestador - Bloco B3</title>
      </Helmet>
      <Container
        component="form"
        fixed
        onSubmit={handleSubmit}
        noValidate
        sx={{marginBottom: '3em'}}
        >
        {/*<Backdrop*/}
        {/*  sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}*/}
        {/*  open={isLoading}*/}
        {/*  >*/}
        {/*  <CircularProgress color="inherit"/>*/}
        {/*</Backdrop>*/}

        <ProviderInfo
          data={data}
          setData={setData}
          jobs={jobs}
          error={error}
          isLoading={false}
        />

        <ProviderAdress
          data={data}
          setData={setData}
          isLoading={false}
        />

        <SimpleButtons
          type={'submit'}
          backButton={'Voltar'}
          forwardButton={'Cadastrar'}
        />
      </Container>
    </>
  )
}


export default ServiceCreate;
