import {useState} from "react";
import {Avatar, Box, Button, Container, CssBaseline, Grid, Link, TextField, Typography, Alert} from "@mui/material";
import {LockClockOutlined, DoneOutlineOutlined } from "@mui/icons-material";
import {api} from "../../../services/Main/Api";
import { Helmet } from "react-helmet";



const RecoverPassword = () => {
    const [email, setEmail] = useState('')
    const [recoverDone, setRecoverDone] = useState('')


    const instruction = "Informe seu e-mail e enviaremos instruções para você criar sua senha."
    const textWhenEmailSend = "Foi enviado um e-mail com instruções para a criação de uma nova senha."

    const recoverPassword = async ({email}) => {
        api.post('user/reset-password/',{
            email
        }).then(response => {

        }).catch(error => console.log(error))
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const data = {
            email,
        }
        await recoverPassword(data);
        setEmail('')
        setRecoverDone(true)
    }

    

    return (
        <>  
        <Helmet>
            <title>Recuperar Senha - Bloco B3</title>
        </Helmet>        
        <CssBaseline/>
            { !recoverDone ? 
                (
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main', height: 54, width: 54}}>
                        <LockClockOutlined fontSize={"large"}/>
                    </Avatar>
                    <Typography component="h1" variant="h5">{"Recuperar senha"}</Typography>
                    <Box component="form"
                         onSubmit={handleSubmit}
                         noValidate sx={{mt: 1}}>
                        <Typography sx={{mt: 1}}>{instruction}</Typography>
                        <TextField
                            onChange={({target : {value}}) => setEmail(value)}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            value={email}
                            label="Email Cadastrado"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            {"Recuperar"}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/MainPages/User/Login" variant="body2">{"Realizar Login"}</Link>
                            </Grid>
                            {/*<Grid item>*/}
                            {/*    <Link href="/User/Register" variant="body2">{"Criar Conta"}</Link>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Box>
                </Box>
            </Container>
                    )
                     : 
                <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    }}
                    >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main', height: 54, width: 54}}>
                        <DoneOutlineOutlined fontSize={"large"}/>
                    </Avatar>
                        <Typography component="h1" variant="h5">{"E-mail enviado com sucesso!"}</Typography>

                        <Alert                           
                            sx={{                                                          
                                mt: 2,
                                mb: 4,                                
                                backgroundColor: 'transparent', 
                        
                            }}
                            variant="outlined"
           
                            >
                            {textWhenEmailSend}
                        </Alert>                         
                             <Link href="/login" variant="body2">{"Voltar"}</Link> 
                </Box>
                    }
           
        </>
    );
}

export default RecoverPassword;
