import {Box, Button, Typography} from "@mui/material";
import React from "react";
import {Delete} from "@mui/icons-material";

const FormSetHead = (
  {
    returnRecipientName,
    bondCreate,
    data,
    disableButton,
    onRemove,
    resetFilters,
    index,
    params,
    id,
  }
) => {


  return(
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Typography
        variant={'h6'}
        sx={{
          mb: '1rem',
          color: 'primary.main'
        }}
      >
        { id ? returnRecipientName() : 'Novo vínculo' }
      </Typography>
      {!id &&
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '1rem'
          }}
        >
          {/*<Button*/}
          {/*  onClick={() => {*/}
          {/*    bondCreate(params.id ?? data?.rentId)*/}
          {/*  }}*/}
          {/*  disabled={!disableButton()}*/}
          {/*>*/}
          {/*  Salvar*/}
          {/*</Button>*/}


          <Button onClick={() => {
            // getBonds()
            onRemove(index)
            resetFilters()
          }}>
            <Delete />
          </Button>

          {/*<IconButton onClick={() => {*/}
          {/*  resetFilters()*/}
          {/*  onRemove(index)*/}
          {/*}}>*/}
          {/*  <DeleteIcon/>*/}
          {/*</IconButton>*/}
        </Box>
      }
    </Box>
  )
}

export default FormSetHead