import React, {useContext} from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material";
import Context from "../../../../contexts/Context";

const TableBillHead = () => {
  const {filters, setFilters} = useContext(Context)

  const handleFilter = (asc: string, desc: string) => {
    setFilters(prevState => ({
        ...prevState,
        ordering: filters?.ordering === asc ? desc : asc
      })
    )
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-subject' || filters?.ordering === 'subject'}
            direction={filters?.ordering === '-subject' ? 'desc' : 'asc'}
            onClick={() => handleFilter('subject', '-subject') }
          >
            Título
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-created_at' || filters?.ordering === 'created_at'}
            direction={filters?.ordering === '-created_at' ? 'desc' : 'asc'}
            onClick={() => handleFilter('created_at', '-created_at') }
          >
            Criado
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-updated_at' || filters?.ordering === 'updated_at'}
            direction={filters?.ordering === '-updated_at' ? 'desc' : 'asc'}
            onClick={() => handleFilter('updated_at', '-updated_at') }
          >
            Última atualização
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-first_name' || filters?.ordering === 'first_name'}
            direction={filters?.ordering === '-first_name' ? 'desc' : 'asc'}
            onClick={() => handleFilter('first_name', '-first_name') }
          >
           Usuário
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default TableBillHead