import {TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import React, {useContext} from "react";
import Context from "../../../contexts/Context";

const RentTableHead = () => {
  const {filters, setFilters} = useContext(Context)

  const handleFilter = (asc: string, desc: string) => {
    setFilters(prevState => ({
        ...prevState,
        ordering: filters?.ordering === asc ? desc : asc
      })
    )
  }


  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{ color: 'primary.main' }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-id' || filters?.ordering === 'id'}
            direction={filters?.ordering === '-id' ? 'desc' : 'asc'}
            onClick={() => handleFilter('id', '-id') }
          >

            Código
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{ color: 'primary.main' }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-name' || filters?.ordering === 'name'}
            direction={filters?.ordering === '-name' ? 'desc' : 'asc'}
            onClick={() => handleFilter('name', '-name') }
          >
            Locatário
          </TableSortLabel>
        </TableCell>

        <TableCell
          sx={{ color: 'primary.main' }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-unit' || filters?.ordering === 'unit'}
            direction={filters?.ordering === '-unit' ? 'desc' : 'asc'}
            onClick={() => handleFilter('unit', '-unit') }
          >

            Proprietários
          </TableSortLabel>
        </TableCell>
        <TableCell
          // align={'left'}
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-value' || filters?.ordering === 'value'}
            direction={filters?.ordering === '-value' ? 'desc' : 'asc'}
            onClick={() => handleFilter('value', '-value')}
          >
            Valor
          </TableSortLabel>
        </TableCell>
        <TableCell
            sx={{ color: 'primary.main' }}
        >
          <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-contract_type' || filters?.ordering === 'contract_type'}
              direction={filters?.ordering === '-contract_type' ? 'desc' : 'asc'}
              onClick={() => handleFilter('contract_type', '-contract_type') }
          >

            Administração
          </TableSortLabel>
        </TableCell>


        <TableCell>
          <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-start_date' || filters?.ordering === 'start_date'}
              direction={filters?.ordering === '-start_date' ? 'desc' : 'asc'}
              onClick={() => handleFilter('start_date', '-start_date')}
          >

            Multa
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-start_date' || filters?.ordering === 'start_date'}
              direction={filters?.ordering === '-start_date' ? 'desc' : 'asc'}
              onClick={() => handleFilter('start_date', '-start_date')}
          >

            Juros
          </TableSortLabel>
        </TableCell>
        <TableCell
          align={'center'}
          sx={{ color: 'primary.main', width: '100px' }}
        >
          {/*<TableSortLabel*/}
          {/*  style={{ color: '#DD1960' }}*/}
          {/*  active={filters?.ordering === '-active' || filters?.ordering === 'active'}*/}
          {/*  direction={filters?.ordering === '-active' ? 'desc' : 'asc'}*/}
          {/*  onClick={() => handleFilter('active', '-active')}*/}
          {/*>*/}
            Situação
          {/*</TableSortLabel>*/}
        </TableCell>
        <TableCell/>
      </TableRow>
    </TableHead>
  )
}

export default RentTableHead
