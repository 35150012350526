import React, {
  useContext,
  useEffect,
  useState
} from "react";
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress, Fade, FormControl, FormControlLabel, FormLabel, InputAdornment, Radio, RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Receipt } from "@mui/icons-material";
import UnitFilter from "../../../components/Filters/UnitFilter";
import moment from "moment/moment";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import Masks from "../../../components/Shared/Masks";
import {useSnackbar} from "notistack";
import {api} from "../../../services/Main/Api";
import Context from "../../../contexts/Context";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {useNavigate} from "react-router-dom";
import GlobalSwitch from "../../../components/Shared/GlobalSwitch";
import OwnerSearch from "../../../components/Filters/OnwerSearch";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import SimpleText from "../../../components/Shared/SimpleText";


const ChargeCreate = ({
    createEvent,
    setCreateEvent
  }) => {

  const { loadingModal, setLoadingModal, ownerOptions, getOwnerOptions } = useContext(Context)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [bankFine, setBankFine] = useState();
  const [bankInterest, setBankInterest] = useState();
  const [reqData, setReqData] = useState({due_date: moment().add(1, 'day').format()})

  function handleBankFineChange (event) {
    const value = event.target.value;
    setBankFine(value);
    setReqData(prevState => {
      const updatedData = { ...prevState };
      if (value === 'value') {
        updatedData.fine_percent = null;
      } else {
        updatedData.fine_value = null;
      }
      return updatedData;
    });
  };

  function handleBankInterestChange (event) {
    const value = event.target.value;
    setBankInterest(value);
    setReqData(prevState => {
      const updatedData = { ...prevState };
      if (value === 'value') {
        updatedData.interest_percent = null;
      } else {
        updatedData.interest_value = null;
      }
      return updatedData;
    });
  }

  useEffect(function createEventOpens(){
    setReqData({due_date: moment().add(1, 'day').format()})
  }, [createEvent])

  async function saveReq() {
    setLoadingModal(true);

    const data = {
      ...reqData,
      condo: getCondoId(),
      total: +reqData?.total?.replace(/\D+/g, ""),
      due_date: moment(reqData?.due_date).format('YYYY-MM-DD')
    };

    try {
      await api.post('/charge/', data);
      enqueueSnackbar('Cobrança criada com sucesso!', { variant: 'success' });
      navigate('/cobranca/');
      setCreateEvent(false);
      setReqData(null);
    } catch (error) {
      enqueueSnackbar('Erro ao tentar criar cobrança!', { variant: 'error' });
      console.log(error);
    } finally {
      setLoadingModal(false);
    }
  }


  useEffect(() => {
    if (reqData?.unit) {
      getOwnerOptions(reqData?.unit)
    }
  }, [reqData?.unit])

  return (
    <>
      <SimpleDialog
        title={'Criar Cobrança'}
        open={createEvent}
        titleIcon={<Receipt />}
        onClickConfirm={() => {
          saveReq()
        }}
        onClickCancel={() => {
          setCreateEvent(false)
          setReqData(null)
        }}
        dialogContent={
          <>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loadingModal}
            >
              <CircularProgress color="inherit"/>
            </Backdrop>
            <UnitFilter
              setState={setReqData}
              keyName={'unit'}
            />
            <DesktopDatePicker
              label="Data de Vencimento"
              minDate={moment().add(1, 'days').toDate()}
              value={ moment(
                  reqData?.due_date ? reqData.due_date : new Date()
              ).add(1, 'day').toDate() }
              onChange={(e) => setReqData((prevState) =>({...prevState, due_date: moment(e).toDate() }))}
              slotProps={{
                field: { clearable: false, onClear: () => {} },
                textField: {
                  size: "small"
                }
              }}
              sx={{
                mt: '1rem',
                mb: '1rem',
                width: '100%'
              }}
            />
            <TextField
              fullWidth
              size={'small'}
              id="total"
              label="Total"
              placeholder={'R$ 0,00' }
              onChange={({ target: { value } })  => setReqData((prevState) => ({...prevState, total: value}))}
              value={Masks.money(String(reqData?.total ?? ''))}
              sx={{
                mb: '1rem'
              }}
            />

            <TextField
              label="Descrição"
              multiline
              rows={5}
              size={"small"}
              name="description"
              fullWidth
              type="text"
              onChange={({target:{value}}) => setReqData((prevState) => ({...prevState, description: value}))}
              value={reqData?.description ?? ''}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <GlobalSwitch
                state={reqData}
                setState={setReqData}
                keyName={'send_email'}
                label={'Enviar para o e-mail?'}
                styles={{
                  mt: '1rem'
                }}
              />

              <GlobalSwitch
                  state={reqData}
                  setState={setReqData}
                  keyName={'send_whatsapp'}
                  label={'Enviar por Whatsapp?'}
                  styles={{
                    mt: '1rem'
                  }}
              />

              <GlobalSwitch
                helperText={'Necessário escolher a unidade'}
                state={reqData}
                disabled={!reqData?.unit || ownerOptions?.length < 1}
                setState={setReqData}
                keyName={'charge_unit_owner'}
                label={'Cobrar do Proprietário?'}
                styles={{
                  mt: '1rem'
                }}
              />
            </Box>

            {(reqData?.charge_unit_owner && reqData?.unit) && ownerOptions?.length > 0 &&
              <OwnerSearch
                label={'Proprietário'}
                state={reqData}
                setState={setReqData}
                keyName={'unit_owner'}
                unitParam={reqData?.unit}
                styles={{ mt: '1rem'}}
              />
            }

            { ownerOptions?.length < 1 &&
              <Alert variant={'filled'} severity={'error'} sx={{ width: '100%', mt: '0.5rem' }}>
                {`Imóvel sem proprietário registrado; opção `}
                <Typography component={'span'} style={{ fontWeight: 'bold' }}>{"Cobrança do proprietário"}</Typography>
                {` desativada.`}
              </Alert>
            }

          </>
        }
      />
    </>
  )
}

export default ChargeCreate;