import {Helmet} from "react-helmet";
import {Backdrop, Box, Button, Card, CardContent, CircularProgress, Container, Fade, Grid} from "@mui/material";
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import InquiryStatement from "../BankOperations/components/BankStatement/InquiryStatement";
import AccInfoCard from "../BankOperations/components/BankStatement/AccInfoCard";
import BankStatementDownload from "../BankOperations/components/BankStatementDownload/BankStatementDownload";
import Lottie from "lottie-react";
import loading from "../../../assets/animations/loading.json";
import React, {useContext, useEffect, useState} from "react";
import SimpleText from "../../../components/Shared/SimpleText";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {api} from "../../../services/Main/Api";
import PaymentCard from "./components/PaymentCard";
import {useSnackbar} from "notistack";
import PaymentPreview from "./components/PaymentPreview";
import moment from "moment/moment";
import Context from "../../../contexts/Context";
import {useNavigate, useParams} from "react-router-dom";
import {Close} from "@mui/icons-material";

const Payment = () => {

  const { filters, setFilters, setLoadingModal, dataProvider, setDataProvider } = useContext(Context)
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const params = useParams()
  const navigate = useNavigate()

  const { operationType } = filters || {}

  const [date, setDate] = useState(moment().format())
  const [data, setData] = useState([])
  const [paymentReq, setPaymentReq] = useState([])
  const [error,setError] = useState(null)
  const [requirement, setRequirement] = useState(false)
  const [url, setUrl] = useState(null)
  const [errorDue, setErrorDue] = useState(null)

  // function checkDue() {
  //   api.post(`payment/read_cip_barcode/`, formatData)
  //     .then(response => {
  //       setPaymentReq(response.data)
  //       setData([])
  //       filters?.operationType === 'payment' && setFilters(prevState => ({...prevState, payConfirmation: true}))
  //     })
  //     .catch(error => {
  //       error.response.data.barcode && setError(error.response.data.barcode)
  //       error.response.data.error && setError(error.response.data.error)
  //       setPaymentReq(null)
  //       console.log(error.response.data)
  //     })
  //
  // }

  const handleSubmitPreview = () => {
    // e.preventDefault()
    setRequirement(true)

    const formatData = {
      ...data,
      condo: +getCondoId()
    }

    api.post(`payment/read_barcode/`, formatData)
      .then(response => {
        setPaymentReq(response.data)
        setData([])
        filters?.operationType === 'payment' && setFilters(prevState => ({...prevState, payConfirmation: true}))
        setError(null)
      })
      .catch(error => {
        error.response.data.barcode && setError(error.response.data.barcode)
        error.response.data.error && setError(error.response.data.error)
        setPaymentReq(null)
        console.log(error.response.data)
        setRequirement(false)
      })
  }

  function errorHandling(error) {
    switch (error) {
      case 'Não existe resultado para informação solicitada.':
        return setDataProvider(prevState => ({...prevState, paymentStep: true}))
      case 'APB0002 - Código de Barras não localizado.':
        return setDataProvider(prevState => ({...prevState, paymentStep: true}))
      default:
        return setDataProvider(prevState => ({...prevState, paymentStep: false}))
    }
  }

  function checkDue() {

    const formatData = {
      condo: +getCondoId(),
      barcode: paymentReq?.Barcode
    }

    api.post(`payment/read_cip_barcode/`, formatData)
      .then(response => {
        setPaymentReq(prevState => ({ ...prevState, ...response.data }));
        setDataProvider(prevState => ({...prevState, paymentStep: true}))
        setError(null)
      })
      .catch(error => {
        errorHandling(error.response.data.error)
        console.log(error.response.data.error)
        error.response.data.error && setError(error.response.data.error)
        setErrorDue(error.response.data.error)
        // setDataProvider(prevState => ({...prevState, paymentStep: false}))
      }).finally(()=>{
      setRequirement(false)
    })
  }

  useEffect(function waitBarcode (){
    if (paymentReq?.Barcode){
      checkDue()
    }

  },[paymentReq?.Barcode])


  useEffect(function expenseReq(){
    // Requistion if made from ExpenseCreate
    filters?.paymentReq === true && handleSubmitPayment()
  }, [filters?.paymentReq])

  const snackNavigate = key => {
    enqueueSnackbar('Recibo', {
      variant: 'info',
      autoHideDuration: null,
      action: (
        <>
          <Button color="inherit" size="small" onClick={() => closeSnackbar(key)}>
            <Close />
          </Button>
          <Button color="inherit" size="small" onClick={() => window.open(url, '_blank')}>
            Abrir
          </Button>
        </>
      ),
    })
  }

  function duplicatedPaymentChecker () {

   const data = {
     condo: +getCondoId(),
     barcode: paymentReq?.Barcode,
     value: +paymentReq?.Value?.toString()?.replace(/\D+/g, "")
  }

  api.post(`/payment/last/`, data)
    .then(response => {
      setFilters(prevState => ({...prevState, duplicityChecker: response.data }))
    })
    .catch(error => {
      console.log(error)
    })
  }

  useEffect(function handlePaymentEffect() {
    if (filters && filters.duplicityChecker) {
      if (filters.duplicityChecker.success) {
        handleSubmitPayment();
      } else {
        setFilters(prevState => ({...prevState, openPayDialog: true}));
      }
    }
  }, [filters?.duplicityChecker])


  useEffect(function snackReceipt() {
    url && snackNavigate()
  }, [url])


  const handleSubmitPayment = () => {
    if (dataProvider?.validation === true) {

      // e.preventDefault()
      setRequirement(true)
      filters?.paymentReq && setLoadingModal(true)

      const formattedData = {
        ...paymentReq,
        condo: +getCondoId(),
        barcode: paymentReq?.Barcode,
        principal_value: paymentReq?.Value,
        // payment_date: moment(date).format('YYYY-MM-DD'),
        expense: filters?.expenseId ?? null
      }


      api.post(`/payment/`, formattedData)
        .then(response => {
          enqueueSnackbar('Pagamento efetuado', {variant: 'success'})
          setPaymentReq([])
          setData([])
          setDataProvider(prevState => ({...prevState, paymentStep: false}))
          setUrl(response.data.receipt)
          setErrorDue(null)
          setError(null)
          filters?.paymentReq && setFilters(prevState => ({
            ...prevState,
            receiptUrl: response.data.receipt,
            paymentDone: true,
            secondStep: true,
            operationType: ''
          }))
          // Trigger for ExpenseCreate
        })
        .catch(error => {
          enqueueSnackbar('Erro ao realizar pagamento', {variant: 'error'})
          console.log(error)
          filters?.paymentReq && setFilters(prevState => ({...prevState, paymentReq: false}))
          error.response.data.barcode && setError(error.response.data.barcode)
          error.response.data.error && setError(error.response.data.error)

          // Trigger for ExpenseCreate
          // filters?.paymentReq === true && setFilters(prevState => ({...prevState, paymentFail: true}))
        })
        .finally(() => {
          setRequirement(false)
          setLoadingModal(false)
        })
    } else {
      enqueueSnackbar('Sem permissão, efetuar etapa de segurança', {variant: 'error'})
      setDataProvider(prevState => ({openAuth: true, validation: false}))
    }

  }

  return (
    <>
      {!operationType  ?
        <>
          <Helmet>
            <title>Pagamento de Boletos - Bloco B3</title>
          </Helmet>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={requirement}
          >
            <CircularProgress color="inherit"/>
          </Backdrop>

          <Container
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'start',
              flexDirection: 'column',
              margin: 0,
              padding: 0
            }}
          >
            <TitleBtnList
              noButtons
              title={'Pagamento de Boletos'}
              boxStyles={{

              }}
            />
                <Card
                  sx={{
                    width: '100%',
                    mb: '1rem',
                  }}

                >
                  <CardContent
                    sx={{
                      p: '2rem',
                    }}
                  >
                    <PaymentCard
                      data={data}
                      setData={setData}
                      setPaymentReq={setPaymentReq}
                      paymentReq={paymentReq}
                      error={error}
                      setError={setError}
                      handleSubmitPreview={handleSubmitPreview}
                      duplicatedPaymentChecker={duplicatedPaymentChecker}
                    />
                  </CardContent>
                </Card>

          </Container>
        </>
        :
        <Fade
          in={operationType === 'payment'}
          mountOnEnter
          unmountOnExit
          timeout={300}
        >
          <Grid item xs={12}>
            <Grid item xs={12} >


              <PaymentCard
                data={data}
                setData={setData}
                setPaymentReq={setPaymentReq}
                paymentReq={paymentReq}
                error={error}
                setError={setError}
                handleSubmitPreview={handleSubmitPreview}
                handleSubmitPayment={handleSubmitPayment}
              />
            </Grid>
          </Grid>
        </Fade>
      }


    </>
  )
}

export default Payment