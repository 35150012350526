import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import {
  Card,
  Container,
  Skeleton,
  Table,
  TableContainer,
} from '@mui/material'
import Context from "../../../contexts/Context";
import { api } from '../../../services/Main/Api'
import { AddCircleOutlined } from '@mui/icons-material'
import { Helmet } from 'react-helmet'
import { getCondoId } from '../../../services/Main/AuthStorage'
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import TableAreaHead from "./components/BookingAreaList/TableAreaHead";
import TableAreaBody from "./components/BookingAreaList/TableAreaBody";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import BookingAreaCreate from "./BookingAreaCreate";
import BookingAreaUpdate from "./BookingAreaUpdate";


const BookingAreaList = () => {

  const [filters, setFilters] = useState({
    condo_id: getCondoId(),
    page_size: 50
  })
  const [areas, setAreas] = useState(null)
  const [createEvent, setCreateEvent] = useState(null)
  const [editEvent, setEditEvent] = useState(null)


  async function fetchData (){
    try {
      if (filters) {
        const response = await api.get('/booking/areas/', { params: filters });
        setAreas(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(function whenPageLoad(){
    fetchData()
  }, [filters, editEvent, createEvent])

  return (
    <>
      <Helmet>
        <title>Áreas Comuns - Bloco B3</title>
      </Helmet>
        <Container maxWidth={false}>
          <TitleBtnList
            title={'Áreas Comuns'}
            buttonText={'Registrar Área Comum'}
            onClick={() => setCreateEvent(true)}
            icon={<AddCircleOutlined />}
          />
      {areas ?
          <>
            <Card>
              <TableContainer sx={{p: 2}}>
                { areas?.results?.length > 0 ?
                  <Table sx={{ minWidth: 800 }}>
                    <TableAreaHead />
                    <TableAreaBody
                      areas={areas}
                      setEditEvent={setEditEvent}
                    />
                  </Table>
                  :
                  <NoResultsAnimation />
                }
              </TableContainer>
            </Card>
          </>
        :
          <Skeleton
            variant="rounded"
            height={700}
            animation="pulse"
            sx={{ with: '100%'}}
          />
      }
        <PaginationGlobal
          state={areas}
          filters={filters}
          setFilters={setFilters}
        />

        </Container>
      <BookingAreaCreate
        createEvent={createEvent}
        setCreateEvent={setCreateEvent}
      />
      <BookingAreaUpdate
        editEvent={editEvent}
        setEditEvent={setEditEvent}
      />


    </>
  )
}

export default BookingAreaList;
