import React, {useContext, useEffect, useState} from "react";
import Context from "../../../contexts/Context";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {api} from "../../../services/Main/Api";
import {Helmet} from "react-helmet";
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  Container,
  Skeleton,
  Table,
  TableContainer,
  useMediaQuery
} from "@mui/material";
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import {AddCircle, Search} from "@mui/icons-material";
import SearchInput from "../../../components/Filters/SearchInput";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import RentTableHead from "../Rent/RentTableHead";
import RentTableBody from "../Rent/RentTableBody";
import RentForm from "../Rent/RentForm";
import UnitFilter from "../../../components/Filters/UnitFilter";
import CircularProgress from "@mui/material/CircularProgress";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import RentRecipientTableHead from "./RentRecipientTableHead";
import RentRecipientTableBody from "./RentRecipientTableBody";

const RentRecipientList = () => {

  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////

  const { filters, setFilters  } = useContext(Context)

  const [data, setData] = useState(null)
  const [reqLoading, setReqLoading] = useState(false)
  const sizeMatch = useMediaQuery('@media (min-width:700px)')

  ///////////////////////////
  //// General functions ////
  ///////////////////////////

  function initialFilters () {
    return setFilters(prevState => ({
      ...prevState,
      page_size: 50,
      search: '',
      active: true,
      account_id: getCondoId(),
    }))
  }

  /////////////////////////////
  //////// Requisitions ///////
  /////////////////////////////

  //// => Get
  function listRentRecipients() {
    if(filters.account_id) {
      api.get(`/rent_recipient/`, {params: filters})
        .then(response => {
          setData(response.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  //////////////////////////////////////
  //// Useffect, triggers and calls ////
  //////////////////////////////////////

  useEffect(function firstLoad(){
    if (filters){
      listRentRecipients()
    }
  }, [filters])

  useEffect(function filterSet () {
    initialFilters()
  }, [])





  return (
    <>
      <Helmet>
        <title>Beneficiários - Bloco B3</title>
      </Helmet>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1500, }}
        open={reqLoading}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
      <Container
        maxWidth={false}
      >
        <TitleBtnList
          title={'Beneficiários'}
          icon={<AddCircle />}
          buttonText={'Cadastrar'}
          buttonHref={'/beneficiarios/criar'}
        />

        <Card>
          {data ?

            <CardContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: sizeMatch ? 'space-between' : 'center',
                flexDirection: sizeMatch ? 'row' : 'column',
                mb: '1rem'
              }}
            >

              <SearchInput
                label={'Procurar por beneficiário'}
                styles={{
                  mr: sizeMatch ? '1rem' : 'auto',
                  maxWidth: sizeMatch ? '400px' : 'auto',
                  mb: sizeMatch ? 'auto' : '1rem'

                }}
              />

            </Box>
            <TableContainer>
                <>
                  {data?.results?.length ?
                    <Table>
                      <RentRecipientTableHead />
                      <RentRecipientTableBody
                        state={data}
                        reqLoading={reqLoading}
                        setReqLoading={setReqLoading}
                      />
                    </Table>
                    :
                    <NoResultsAnimation />
                  }
                </>
            </TableContainer>
          </CardContent>
            :
            <Skeleton
              variant="rounded"
              height={700}
              animation="pulse"
            />
          }
        </Card>

        <PaginationGlobal
          state={data}
          filters={filters}
          setFilters={setFilters}
        />

      </Container>
    </>
  )
}

export default RentRecipientList