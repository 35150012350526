import moment from 'moment/moment'
import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import React, {useEffect, useState} from 'react'
import {getFilterDate, setFilterDate} from "../../services/Main/AuthStorage"
import {DesktopDatePicker} from "@mui/x-date-pickers";

const ReferenceDate = ({ setFilters }) => {
  const storageDate = getFilterDate()

  const [dateValue, setDateValue] = useState(moment(new Date(storageDate)).toDate() ?? moment().toDate())

  const monthFormat = +moment(storageDate).format("MM")
  const yearFormat = +moment(storageDate).format("YYYY")

  useEffect(() => {
    if (dateValue) {
      setFilters((prevState) => ({
        ...prevState,
        billing_month: monthFormat,
        billing_year: yearFormat
      }))
    }
  }, [dateValue] )

  return (
    <>
      <DesktopDatePicker
        label="Data de Pagamento"
        openTo="month"
        views={['year', 'month',]}
        value={dateValue}
        clearLabel
        onChange={(newValue) => {
          setDateValue(moment(new Date(newValue)).format())
          setFilterDate(moment(new Date(newValue)).format())
        }}
        slotProps={{
            field: { clearable: false, onClear: () => {} },
            textField: {
                size: "small"
            }
        }}
      />
    </>
  )
}
export default ReferenceDate