import {Box, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import Lottie from "lottie-react";
import emptyBox from "../../../assets/animations/emptyBox.json";
import React from "react";

const NoResultsAnimation = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >

      <Typography
        align={'center'}
        sx={{
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontSize: '24px',
          fontWeight: 'bold',
          color: grey[400],
        }}
      >
        Nenhum Resultado!
      </Typography>
      <Box
        sx={{
          width:'250px',
        }}
      >
        <Lottie
          animationData={emptyBox}
          loop={true}
        />
      </Box>
    </Box>
  )
}

export default NoResultsAnimation