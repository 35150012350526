import {IconButton, TableBody, TableCell, TableRow} from "@mui/material";
import React, {useContext, useState} from "react";
import moment from "moment";
import {Close, FileOpen, Share} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import Context from "../../../contexts/Context";

const DeliveryTableBody = ({state, setOpenDelivery}) => {
  const { setDataProvider, unitOptions } = useContext(Context)

  return (
    <TableBody>
      { state?.results?.map((deliver, index) => (
        <TableRow
          hover
          tabIndex={-1}
          key={index}
          sx={{ 'cursor': 'pointer' }}
          onClick={() => {
            setDataProvider(prevState => ({...prevState, isUpdate: true , id: deliver?.id, loadDelivery: true}))
            setOpenDelivery(true)
          }}
        >

          <TableCell
            component="th"
            scope="row"
          >
            {deliver?.name_delivery ?? <Close sx={{color: 'error.main'}}/>}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
          >
            {moment(deliver?.exit_date).format('DD/MM/YYYY')}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
          >
            {unitOptions?.find(e => e.value === deliver?.unit_delivery)?.label  ?? <Close sx={{color: 'error.main'}}/>}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            align={'center'}
            sx={{
              fontWeight: 'bold',
              color: deliver?.delivered ? '#47b514' : '#ad0000'
            }}
          >
            {deliver?.delivered ? 'Entregue' : 'Não entregue'}
          </TableCell>
        </TableRow>
      ))}

    </TableBody>
  )
}

export default DeliveryTableBody