import {Backdrop, CircularProgress, Dialog, DialogContent, TextField, Typography} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React, {useContext, useEffect, useState} from "react";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import SimpleText from "../../../components/Shared/SimpleText";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {getCondoId, getFilterDate, setFilterDate} from "../../../services/Main/AuthStorage";
import UnitFilter from "../../../components/Filters/UnitFilter";
import {Box} from "@mui/system";
import DateAndTime from "../../../components/BookingAndMaintenance/DateAndTime";
import {api} from "../../../services/Main/Api";
import {useSnackbar} from "notistack";
import {useParams} from "react-router-dom";
import Context from "../../../contexts/Context";
import SimpleTypography from "../../../components/Shared/SimpleTypography";
import {Close, Outbox} from "@mui/icons-material";
import ButtonSet from "../../../components/Shared/ButtonSet";

const DeliveryForm = (props) => {
  const {
    openDelivery,
    setOpenDelivery,
  }
  = props

  const { dataProvider, setDataProvider, unitOptions } = useContext(Context)

  const {enqueueSnackbar} = useSnackbar()

  const [reqData, setReqData] = useState(null)
  const [edit, setEdit] = useState(false)
  const [error, setError] = useState(null)
  const [timePickerEntry, setTimePickerEntry] = useState(moment().locale('pt-br').format('LT'))
  const [timePickerExit, setTimePickerExit] = useState(moment().locale('pt-br').format('LT'))
  const [openDelete, setOpenDelete] = useState(false)
  const [reqLoading, setReqLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  function getDelivery() {
    if (dataProvider?.isUpdate && openDelivery) {
      // setFilters(prevState => ({...prevState, loadDelivery: true }))
      api.get(`management/delivery/${dataProvider?.id}/`)
        .then(response => {
          setReqData(response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          setDataProvider(prevState => ({...prevState, loadDelivery: false}))
        })
    }
  }

  useEffect(function getInfo(){
    getDelivery()
  },[dataProvider?.isUpdate])

  useEffect(function ifNoData(){
    !reqData?.exit_date && setReqData(prevState => ({...prevState, exit_date: moment(exitDate()).format()}))
  },[openDelivery])

  // const entryDate = () => {
  //   const formatEntryDate = moment(reqData?.entry_date).format('YYYY-MM-DD')
  //   const finalEntryDate = formatEntryDate + ' ' + timePickerEntry
  //   return moment(finalEntryDate)
  // }

  const exitDate = () => {
    const formatExitDate = moment(reqData?.exit_date).format('YYYY-MM-DD')
    const finalExitDate = formatExitDate + ' ' + timePickerExit
    return moment(finalExitDate)
  }



  const resetFilters = () => {
    setDataProvider(prevState => ({
      ...prevState,
      isCreate: false,
      isUpdate: false,
      loadDelivery: false,
      loadVisitor: false,
      id: false,
    }))
  }

  async function saveReq(){
    setLoading(true)
    setReqLoading(true)

    const data = {
      ...reqData,
      condo: +getCondoId(),
      exit_date: moment(exitDate()).format()
    }


    function alternateReq() {
      if (dataProvider?.isUpdate) {
        return api.put(`/management/delivery/${dataProvider.id}`, data)
      } else {
        return api.post(`/management/delivery/`, data)
      }
    }

    try {
      const response = await alternateReq();
      setEdit(false);
      setOpenDelivery(false)
      setDataProvider(prevState => ({...prevState, requestDone: !dataProvider?.requestDone}))
      // setDataProvider(prevState => ({ ...prevState, openBill: false }));
      if (dataProvider?.isUpdate) {
        enqueueSnackbar('Entrega atualizada', { variant: 'success' });
      } else {
        enqueueSnackbar('Entrega criada', { variant: 'success' });
      }
      setError(null);
    } catch (error) {
      setError(error.response.data);
      if (dataProvider?.isCreate) {
        enqueueSnackbar('Erro ao atualizar entrega', { variant: 'error' });
      } else {
        enqueueSnackbar('Erro ao criar entrega', { variant: 'error' });
      }
      console.log(error);
    } finally {
      setLoading(false)
      setReqLoading(false)
    }
  }


  // const create = () => {
  //
  //   if (filters?.isCreate) {
  //     setReqLoading(true)
  //     api.post(`/management/delivery/`, data)
  //     .then(response => {
  //       setOpenDelivery(false)
  //       setReqData(null)
  //       setFilters(prevState => ({...prevState, requestDone: !filters?.requestDone}))
  //       resetFilters()
  //       enqueueSnackbar('Entrega cadastrada', { variant: 'success' })
  //
  //     })
  //     .catch(error => {
  //       setError(error.response.data)
  //       enqueueSnackbar('Erro ao cadastrar entrega', { variant: 'success' })
  //       console.log(error)
  //     })
  //     .finally(()=> {
  //       setReqLoading(false)
  //     })
  //   }
  // }

  // const update = () => {
  //   if (filters?.isUpdate) {
  //     setReqLoading(true)
  //     api.put(`/management/delivery/${filters.id}`, data)
  //       .then(response =>{
  //         setFilters(prevState => ({...prevState, requestDone: !filters?.requestDone}))
  //         setEdit(false)
  //         setOpenDelivery(false)
  //         setReqData(null)
  //         resetFilters()
  //         enqueueSnackbar('Entrega atualizada', { variant: 'success' })
  //
  //       })
  //       .catch(error => {
  //         enqueueSnackbar('Erro ao atualizar entrega', { variant: 'success' })
  //         console.log(error)
  //       })
  //       .finally(()=> {
  //         setReqLoading(false)
  //       })
  //   }
  // }

  const deleteReq = () => {
    setReqLoading(true)
    api.delete(`/management/delivery/${dataProvider.id}`)
      .then(response =>{
        setDataProvider(prevState => ({...prevState, requestDone: !dataProvider?.requestDone}))

        setEdit(false)
        setReqData(null)
        setOpenDelivery(false)

        enqueueSnackbar('Entrega deletada', { variant: 'success' })
        resetFilters()

      })
      .catch(error => {
        enqueueSnackbar('Erro ao excluir entrega', { variant: 'error' })
        console.log(error)
      })
      .finally(()=> {
        setReqLoading(false)
      })

  }



  const confirmButton = dataProvider?.isCreate ? 'Cadastrar entrega' : dataProvider?.isUpdate && 'Editar entregar'

  // const requisitionConditional = filters?.isCreate ? create() : filters?.isUpdate && setEdit(true)


  const enableButton = reqData?.name_delivery && reqData?.exit_date

  useEffect(function whenModalClose(){
    if (openDelivery === false){
      setLoading(true)
      setReqData(null)
      // setDataProvider(prevState => ({ ...prevState, documentId: null}))
      setDataProvider(prevState => ({...prevState, isUpdate: false, isCreate: false}))
    }

  },[openDelivery])

  return (
    <>

      <SimpleDialog
        open={openDelete}
        setOpen={setOpenDelete}
        title={'Excluir entrega'}
        question={'Realmente quer excluir essa entrega?'}
        functionProp={deleteReq}
      />
      <SimpleDialog
        title={dataProvider?.isCreate? 'Cadastro de entrega' : 'Atualização de entrega'}
        titleIcon={<Outbox/>}
        disabled={!enableButton}
        open={openDelivery}
        setOpen={setOpenDelivery}
        removeConfirm={!!dataProvider?.id}
        onClickConfirm={!dataProvider?.id ? () => {
            saveReq()
         } : false
        }
        onClickCancel={() => {
          setDataProvider(prevState => ({...prevState, loadDelivery: true }))
          setOpenDelivery(false)
          resetFilters()
          setReqData(null)
          setEdit(false)
        }}
        actionMenu={
          <>
            <Backdrop
              sx={{ color: '#fff', zIndex: 1500, }}
              open={reqLoading}
            >
              <CircularProgress color="inherit"/>
            </Backdrop>
            {dataProvider?.id &&
              <ButtonSet
                editState={edit}
                editClick={() => {
                  dataProvider?.isUpdate && setEdit(true)
                }}
                deleteClick={() => setOpenDelete(true)}
                saveClick={() => {
                  dataProvider?.isUpdate && saveReq()
                }}
                cancelClick={() => {
                  getDelivery()
                  setEdit(false)
                }}
              />
            }
          </>
        }

        dialogContent={
        <>
          <Backdrop
            sx={{ color: '#fff', zIndex: 1500, }}
            open={reqLoading}
          >
            <CircularProgress color="inherit"/>
          </Backdrop>
          {edit || dataProvider?.isCreate ?
            <>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={reqLoading}
              >
                <CircularProgress color="inherit"/>
              </Backdrop>
              <ToggleButtonGroup
                fullWidth
                exclusive
                value={reqData?.delivered}
                color={'primary'}
                onChange={(e, value) => setReqData(prevState => ({
                  ...prevState,
                  delivered: value
                }))}
                sx={{mb: '0.5rem'}}
              >
                <ToggleButton
                  value={false}
                  selected={reqData?.delivered === false || !reqData?.delivered}
                >
                  Não entregue
                </ToggleButton>
                <ToggleButton
                  value={true}
                  selected={reqData?.delivered === true}
                >
                  Entregue
                </ToggleButton>
              </ToggleButtonGroup>
              <SimpleText
                label={'Destinatário'}
                state={reqData}
                setState={setReqData}
                keyName={'name_delivery'}
                error={error}
                styles={{mb: '0.5rem'}}
              />
              <SimpleText
                label={'Quem recebeu?'}
                state={reqData}
                setState={setReqData}
                keyName={'name_delivered'}
                error={error}
                styles={{mb: '0.5rem'}}
              />
              {/*<DateAndTime*/}
              {/*  labelDate={'Data de recebimento'}*/}
              {/*  labelTime={'Horário'}*/}
              {/*  setState={setReqData}*/}
              {/*  valueFunction={entryDate}*/}
              {/*  timePicker={setTimePickerEntry}*/}
              {/*  keyName={'entry_date'}*/}
              {/*  styles={{mb: '0.5rem'}}*/}
              {/*/>*/}
              <DateAndTime
                labelDate={'Data de entrega'}
                labelTime={'Horário'}
                setState={setReqData}
                valueFunction={exitDate}
                timePicker={setTimePickerExit}
                keyName={'exit_date'}
                styles={{mb: '0.5rem'}}
                required
              />


              {dataProvider?.id ?
                <UnitFilter
                  state={reqData}
                  setState={setReqData}
                  valueProp={unitOptions?.find(e => e.value === reqData?.unit_delivery) ?? ''}
                  keyName={'unit_delivery'}
                  styles={{mb: '0.5rem'}}
                />
                :
                <UnitFilter
                  state={reqData}
                  setState={setReqData}
                  // valueProp={unitOptions?.find(e => e.value === reqData?.unit_delivery) ?? ''}
                  keyName={'unit_delivery'}
                  styles={{mb: '0.5rem'}}
                />
              }

              <SimpleText
                label={'Descrição?'}
                multiline
                rows={5}
                state={reqData}
                setState={setReqData}
                keyName={'description'}
                error={error}
                styles={{mb: '0'}}
              />
              </>
              :
              <Box>
                <SimpleTypography
                  label={'Situação da entrega'}
                  labelStyles={{ fontWeight: 'bold '}}
                  directValue={reqData?.delivered ? 'Entregue' : 'Não entregue'}
                  isLoading={dataProvider?.loadDelivery}
                  boxStyles={{ mb: '0.5rem' }}
                />
                <SimpleTypography
                  label={'Destinatário'}
                  labelStyles={{ fontWeight: 'bold '}}
                  directValue={reqData?.name_delivery ?? <Close sx={{color: 'error.main'}}/>}
                  isLoading={dataProvider?.loadDelivery}
                  boxStyles={{ mb: '0.5rem' }}
                />
                <SimpleTypography
                  label={'Quem recebeu?'}
                  labelStyles={{ fontWeight: 'bold '}}
                  directValue={reqData?.name_delivered ?? <Close sx={{color: 'error.main'}}/>}
                  isLoading={dataProvider?.loadDelivery}
                  boxStyles={{ mb: '0.5rem' }}
                />
                {/*<SimpleTypography*/}
                {/*  label={'Data de recebimento'}*/}
                {/*  labelStyles={{ fontWeight: 'bold '}}*/}
                {/*  directValue={moment(reqData?.entry_date).format('DD/MM/YYYY HH:mm:ss')}*/}
                {/*  isLoading={filters?.loadDelivery}*/}
                {/*/>*/}
                <SimpleTypography
                  label={'Data de entrega'}
                  labelStyles={{ fontWeight: 'bold '}}
                  directValue={moment(reqData?.exit_date).format('DD/MM/YYYY HH:mm:ss')}
                  isLoading={dataProvider?.loadDelivery}
                  boxStyles={{ mb: '0.5rem' }}
                />
                <SimpleTypography
                  label={'Imóvel'}
                  labelStyles={{ fontWeight: 'bold '}}
                  directValue={unitOptions?.find(e => e.value === reqData?.unit_delivery)?.label  ?? <Close sx={{color: 'error.main'}}/>}
                  isLoading={dataProvider?.loadDelivery}
                  boxStyles={{ mb: '0' }}
                />
              </Box>
          }


        </>

        }


      />

    </>

  )
}

export default DeliveryForm