import {Box, Button, Divider, IconButton, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {useContext} from "react";
import Context from "../../../../contexts/Context";

const FormHeadText =
  ({
     id,
     returnRecipientName,
     setHandleId,
     setOpenDelete,
    setOpenTransfer
   }) => {

  const { dataProvider } = useContext(Context)


  return (
    <>
      <Box
        sx={{
          // paddingX: '2rem',
          // paddingTop: '0.5rem',
          mb: '0.5rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'

          }}
        >
          <Box>
            <Typography
              sx={{
                color: 'primary.main',
                fontWeight: 'bold',
                fontSize: '18px'
              }}
            >
              {
                returnRecipientName()
              }
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              ml: '1rem'

            }}
          >

            <IconButton
              sx={{
                mr: '-1rem'
              }}
              onClick={() => {
                setHandleId(id)
                setOpenDelete(true)
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default FormHeadText