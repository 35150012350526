import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import {Box} from "@mui/material";

const BailInsuranceRead =
  ({
     edit,
     guarantee,
     guaranteeData,
   }) => {
    return (
      <>
        {guarantee === 'titulo_de_captalização' && !edit &&

          <Box
            sx={{
              // paddingX: '1rem'

            }}
          >
            {guaranteeData?.insurance_policy_number &&
              <SimpleTypography
                label={'Número de Apólice/Contrato'}
                directValue={guaranteeData?.insurance_policy_number}
                labelStyles={{
                  fontWeight: 'bold'
                }}
                boxStyles={{
                  width: '100%',
                  mb: '1rem'
                }}
              />
            }

            {guaranteeData?.insurer &&
              <SimpleTypography
                label={'Seguradora'}
                directValue={guaranteeData?.insurer}
                labelStyles={{
                  fontWeight: 'bold'
                }}
                boxStyles={{
                  width: '100%',
                  mb: '1rem'
                }}
              />
            }

            {guaranteeData?.how_many_times_the_rent &&
              <SimpleTypography
                label={'Valor da locação'}
                directValue={`${guaranteeData?.how_many_times_the_rent} x`}
                labelStyles={{
                  fontWeight: 'bold'
                }}
                boxStyles={{
                  width: '100%',
                  mb: '1rem'
                }}
              />
            }
          </Box>
        }
      </>
    )
  }

export default BailInsuranceRead