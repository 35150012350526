import { Box, Chip, Grid, Link } from '@mui/material'
import InvoiceDetailTitle from "./dependencies/InvoiceDetailTitle";
import InvoiceDetailStatus from "./dependencies/InvoiceDetailStatus";
import InvoiceDetailDue from "./dependencies/InvoiceDetailDue";
import InvoiceDetailDownload from "./dependencies/InvoiceDetailDownload";
import InvoiceDetailSentMail from "./dependencies/InvoiceDetailSentMail";
import Divider from "@mui/material/Divider";
import React, {useContext} from "react";
import InvoiceDetailGenerateSlip from "./dependencies/InvoiceDetailGenerateSlip";
import InvoiceDetailDeleteSlip from "./dependencies/InvoiceDetailDeleteSlip";
import InvoiceDetailDownloadRentReceipt from "./dependencies/InvoiceDetailDowloadRentReceipt";
import { OpenInNew } from '@mui/icons-material'
import Context from "../../../../../../contexts/Context";

const InvoiceDetailHeader = ({ invoice, sending, sendToMail, condo }) => {
    const { user } = useContext(Context)
    const { perm_bank } = user || {}

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent={'space-between'}
      >
        <InvoiceDetailTitle
          invoice={invoice}
        />
        <InvoiceDetailStatus
          invoice={invoice}
        />
        <Grid
          item
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
          display={'flex'}
          textAlign={'right'}
        >
          <InvoiceDetailDue
            invoice={invoice}
          />
            { perm_bank === true &&
          <Box>
            {/*<InvoiceDetailDownload*/}
            {/*  invoice={invoice}*/}
            {/*/>*/}
            { invoice?.status.key !== 'open' && invoice?.status.key !== 'processing' &&
            <Link
              href={invoice?.payment_page}
              target="_blank"
              onClick={(e) => e.stopPropagation()}>
              <Chip
                label={'Página de pagamento'}
                size={'small'}
                icon={<OpenInNew />}
              />
            </Link> }
            <InvoiceDetailSentMail
              invoice={invoice}
              sending={sending}
              sendToMail={sendToMail}
            />
            {!invoice?.bank_slip_status?.key || invoice.bank_slip_status.key === 7 ?
                <InvoiceDetailGenerateSlip
                  invoice={invoice}
                />
                :
                <InvoiceDetailDeleteSlip
                  invoice={invoice}
                />
            }
              {condo?.type === "RENT" && invoice?.status?.key === 'paid' &&
                  <InvoiceDetailDownloadRentReceipt
                      invoice={invoice}
                  />
              }

          </Box>
            }
        </Grid>
      </Grid>
      <Divider
        sx={{
          mb: '2rem',
          mt: '.5rem'
        }}
      />
    </>
  )
}

export default InvoiceDetailHeader