
import React from "react";
import {useParams} from "react-router-dom";
import BankTransferList from "../BankTransferList/BankTransferList";

const TransferDetailPix = () => {
    let { id } = useParams();
    let transferType = 'pix';

    return (
        <>

            <BankTransferList
                setTransferOpen={true}
                transferType={transferType}
                id={id}

            />
        </>

    )
}

export default TransferDetailPix