import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox, Chip,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Skeleton,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {Add, AllInclusive, Check, Close, Edit, ReceiptLong} from "@mui/icons-material";
import Masks from "../../../components/Shared/Masks";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SimpleTypography from "../../../components/Shared/SimpleTypography";
import moment from "moment";
import {DatePicker} from "@mui/x-date-pickers";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import Context from "../../../contexts/Context";
import SaveCancelButton from "../../../components/Shared/SaveCancelButton";

function DataInput(props) {
  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////
  const params = useParams()
  const { dataProvider } = useContext(Context)
  const { index, onChange, onRemove, id, taxUpdate, taxCreate, taxDeleteById, getTax, ...dataProps } = props;
  const {
    description,
    value,
    installments,
    paid_installment,
    transfer_recipient,
    fully_paid,
    installment_value,
    installment_start,
    year,
    month,
    apply_proportional_calculation,
    include_income_tax,
    charge_recipient
  } = dataProps

  const [handleId, setHandleId] = useState(null)
  const [deleteId, setDeleteId] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)
  const [showInstall, setShowInstall] = useState(!!installments)
  const [disableSelect, setDisableSelect] = useState(false)
  const [edit, setEdit] = useState(false)
  const [disable, setDisable] = useState(false)


  ///////////////////////////
  //// General functions ////
  ///////////////////////////
  function handleDescriptionChange (event){
    onChange(index, { ...dataProps, description: event.target.value, id });
  }

  function handleValueChange (event){
    const newValue = event.target.value?.replace(/[^0-9-]/g, '')
    onChange(index, { ...dataProps, value: event.target.value, id,  });
  }

  function handleInstallmentChange (event){
    onChange(index, { ...dataProps, installments: event.target.value, id });
  }

  function handleInstallmentChangeStart (event){
    onChange(index, { ...dataProps, installment_start: event.target.value, id });
  }

  function handleProportioalChange (event) {
    onChange(index, { ...dataProps, apply_proportional_calculation: event.target.checked, id });
  }

  function handleRecipientCharge (event) {
    onChange(index, { ...dataProps, charge_recipient: event.target.checked, id });
  }

  function handleDateChange (newValue) {
    onChange(index, {
      ...dataProps,
      month: moment(newValue).format('MM'),
      year: moment(newValue).format('YYYY'),
      id
    });
  }

  function handleRecipientTransfer(event) {
    onChange(index, { ...dataProps, transfer_recipient: event.target.checked, id });
  }

  function handleIncomeTax(event) {
    onChange(index, { ...dataProps, include_income_tax: event.target.checked, id });
  }

  function installmentPreview() {
    const installmentCalc = Math.round(value?.toString()?.replace(/\D+/g, '') / installments)
    return Masks?.money(installmentCalc?.toString()) || ''
  }

  function handleEdit(){
    setEdit(!edit)
  }

  function disableSaveButton(){
    if (+value?.toString()?.replace(/\D+/g, "") < 1){
      return true
    } else {
      return false
    }
  }

  function disableRecipientTransfer (){
    if (charge_recipient){
      onChange(index, { ...dataProps, transfer_recipient: true, include_income_tax: false, id });
      setDisable(true)
    } else {
      setDisable(false)
    }
  }

  //////////////////////////////////////
  //// Useffect, triggers and calls ////
  //////////////////////////////////////

  useEffect(function showInstallObserver(){
    if (showInstall === false){
      onChange(index, { ...dataProps, installment_start: null, installments: null, id });
    }

  }, [showInstall])

  useEffect(function descriptionSelect(){
    if (!id && description === 'Adicione descrição...'){
      setDisableSelect(true)
      onChange(index, { ...dataProps, description: '', id });
    }
  },[description])

  // useEffect(function ifValueBelowZero(){
  //   const valueFormat = value.toString()?.replace(/[^0-9-]/g, '') <= 0
  //
  //   if(valueFormat){
  //     onChange(index, { ...dataProps, transfer_recipient: false, id });
  //   }
  // }, [value]

  useEffect(function chargeRecipientObserver(){
    disableRecipientTransfer()
  },[charge_recipient])



  return (
    <>

    <Box
      sx={{
        display: 'flex',
        borderRadius: '1rem',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >

      { id && !edit ?
        <Box
          sx={{
            justifyContent: 'space-between',
            width: '100%',
          }}
        >

          <Box
            sx={{
              // paddingX: '2rem',
              // mt: '0.5rem',
              mb: '0.3rem'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minHeight: '50px',
                mb: '0.5rem'

              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: 'primary.main',
                    fontWeight: 'bold',
                    fontSize: '18px'

                  }}
                >
                  {description?.length < 1 ? ' - - - ' : description}
                </Typography>
                {fully_paid &&
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      color: 'rgba(63,255,91,0.5)'
                    }}
                  >
                    {'Pago'}
                  </Typography>
                }
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  ml: '1rem',


                }}
              >
                <IconButton onClick={() => {
                  setHandleId(id)
                  handleEdit()
                }}>
                  <Edit />
                </IconButton>


                <IconButton
                  sx={{
                    paddingX: 0
                  }}
                  onClick={() => {
                    setHandleId(id)
                    // handleEdit()

                    setOpenDelete(true)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
          {/*<Divider sx={{mb: '1rem'}}/>*/}

          <Box
            sx={{
              // paddingX: '2rem',
              mb: '1rem'
            }}
          >

            <SimpleTypography
              label={'Início'}
              directValue={moment(`${year}-${month}`, 'YYYY-MM').format('MMMM YYYY')?.replace(/^\w/, (char) => char.toUpperCase())}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '0.5rem'
              }}
            />

            <SimpleTypography
              label={'Total'}
              directValue={Masks?.money(value.toString(), true) || ''}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '0.5rem'

              }}
            />
            <SimpleTypography
              label={installments ? 'Parcelas Pagas' : 'Mensalidade'}
              directValue={installments ? `${paid_installment} / ${installments}x  ${Masks?.money(installment_value?.toString()) || ''}` : <AllInclusive sx={{ color: 'primary.main'}}/>}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '0.5rem'
              }}
            />

            <SimpleTypography
              label={'Cobrança para os Beneficiários'}
              directValueFree={
                <Box
                  sx={{
                    color: charge_recipient ? 'success.main' : 'error.main'
                  }}
                  color={charge_recipient ? 'success' : 'error'}
                >
                  {charge_recipient ? <Check /> : <Close />}
                </Box>
              }
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: 'auto'
              }}
            />

            <SimpleTypography
              label={'Transferência para os Beneficiários'}
              directValueFree={
                <Box
                  sx={{
                    color: transfer_recipient ? 'success.main' : 'error.main'
                  }}
                  color={transfer_recipient ? 'success' : 'error'}
                >
                  {transfer_recipient ? <Check /> : <Close />}
                </Box>
              }
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: 'auto'
              }}
            />

            {/*<SimpleTypography*/}
            {/*  label={'Cobrança Proporcional'}*/}
            {/*  directValueFree={*/}
            {/*    <Box*/}
            {/*      sx={{*/}
            {/*        color: apply_proportional_calculation ? 'success.main' : 'error.main'*/}
            {/*      }}*/}
            {/*      color={apply_proportional_calculation ? 'success' : 'error'}*/}
            {/*    >*/}
            {/*      {apply_proportional_calculation ? <Check /> : <Close />}*/}
            {/*    </Box>*/}
            {/*  }*/}
            {/*  labelStyles={{*/}
            {/*    fontWeight: 'bold'*/}
            {/*  }}*/}
            {/*  boxStyles={{*/}
            {/*    width: '100%',*/}
            {/*    mb: 'auto'*/}
            {/*  }}*/}
            {/*/>*/}

            <SimpleTypography
              label={'Imposto de renda incluso'}
              directValueFree={
                <Box
                  sx={{
                    color: include_income_tax ? 'success.main' : 'error.main'
                  }}
                  color={include_income_tax ? 'success' : 'error'}
                >
                  {include_income_tax ? <Check /> : <Close />}
                </Box>
              }
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: 'auto'
              }}
            />


          </Box>
        </Box>

      :
        <>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            // maxWidth: '700px',
            // padding: '2rem'
          }}
        >

          { id ?
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minHeight: '50px',
                mb: '0.5rem'

              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: 'primary.main',
                    fontWeight: 'bold',
                    fontSize: '18px'
                  }}
                >
                  {description?.length < 1 ? ' - - - ' : description}
                </Typography>
                {fully_paid &&
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      color: 'rgba(63,255,91,0.5)'
                    }}
                  >
                    {'Pago'}
                  </Typography>
                }
              </Box>
              <SaveCancelButton
                edit={edit}
                disabled={value?.length < 1}
                saveClick={() => {
                  taxUpdate(params.id, handleId)
                  setHandleId(null)
                  handleEdit()
                  }
                }
                cancelClick={() => {
                  getTax()
                  handleEdit()
                  setHandleId(null)
                }}
              />



              {/*<Box*/}
              {/*  sx={{*/}
              {/*    display: 'flex',*/}
              {/*    flexDirection: 'row',*/}
              {/*    alignItems: 'center',*/}
              {/*    ml: '1rem',*/}
              {/*    // mb: '1rem',*/}
              {/*  }}*/}
              {/*>*/}

              {/*  <Button*/}
              {/*    size={'small'}*/}
              {/*    variant={'contained'}*/}
              {/*    onClick={() => {*/}
              {/*      taxUpdate(params.id, handleId)*/}
              {/*      setHandleId(null)*/}
              {/*      handleEdit()*/}
              {/*    }*/}
              {/*    }*/}
              {/*    disabled={disableSaveButton()}*/}
              {/*  >*/}
              {/*    Salvar*/}
              {/*  </Button>*/}

              {/*  <Button*/}
              {/*    size={'small'}*/}
              {/*    onClick={() => {*/}
              {/*      getTax()*/}
              {/*      handleEdit()*/}
              {/*      setHandleId(null)*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    Cancelar*/}
              {/*  </Button>*/}


              {/*</Box>*/}
            </Box>
            :
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography
                variant={'h6'}
                sx={{
                  // mb: '1rem',
                  color: 'primary.main'
                }}
              >
                { !id ? 'Nova cobrança' : 'Editar cobrança' }
              </Typography>
              {!id &&

                  <SaveCancelButton
                    edit={!edit}
                    disabled={value?.length < 1}
                    saveClick={() => {
                      taxCreate(params.id ?? dataProvider?.rentId)
                    }}
                    cancelClick={() => {
                      getTax()
                      setHandleId(null)
                    }}
                  />
              }
            </Box>
          }
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <DatePicker
              openTo={'month'}
              views={['year', "month"]}
              // minDate={moment().add(1, 'month').startOf('month')}
              label="Mês de Cobrança"
              value={moment(`${year}-${month}`, 'YYYY-MM').format()}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "MMMM AAAA",
                    readOnly: true
                  }}
                  size="small"
                  fullWidth
                  required
                  sx={{
                    maxWidth: '200px',
                    mr: '0.5rem',
                  }}
                />
              )}
            />

            {!id && !disableSelect ?
              <TextField
                select
                size={'small'}
                label={'Descrição'}
                value={description ?? ''}
                onChange={handleDescriptionChange}
                sx={{
                  maxWidth: '250px',
                  width: '100%',
                  mr: '0.5rem'
                }}
              >
                <MenuItem
                  value={'default'}
                >
                  Escolha uma opção
                </MenuItem>
                <MenuItem
                  value={'Seguro obrigatório contra incêndio'}
                >
                  Seguro obrigatório contra incêndio
                </MenuItem>

                <MenuItem
                  value={'IPTU'}
                >
                  IPTU
                </MenuItem>

                <MenuItem
                  value={'Seguro conteúdo'}
                >
                  Seguro conteúdo
                </MenuItem>

                <MenuItem
                  value={'Manutenção'}
                >
                  Manutenção
                </MenuItem>

                <MenuItem
                  value={'Fundo de reserva'}
                >
                  Fundo reserva
                </MenuItem>

                <MenuItem
                  value={'Taxa bancária'}
                >
                  Taxa bancária
                </MenuItem>

                <MenuItem
                  value={'Taxa repasse'}
                >
                  Taxa repasse
                </MenuItem>



                <MenuItem
                  value={'Adicione descrição...'}
                >
                  Adicione descrição...
                </MenuItem>

              </TextField>
              :
              <TextField
                label="Descrição"
                size={'small'}
                disabled={id && handleId !== id}
                value={description ?? ''}
                onChange={handleDescriptionChange}
                sx={{
                  maxWidth: '300px',
                  width: '100%'
                }}
              />
            }

          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: '1rem',
              mb: '1rem'
            }}
          >
            <TextField
              label="Valor"
              disabled={id && handleId !== id}
              size={'small'}
              value={Masks?.money(value?.toString() ?? '')}
              onChange={handleValueChange}
              sx={{
                maxWidth: '200px',
                width: '100%',
                mr: '1rem'
              }}
            />
            <FormControlLabel
              sx={{ml: '1rem'}}
              control={
                  <>
              <Typography>Recorrente</Typography>
                  <Switch
                    checked={!!showInstall}
                    onChange={(e) => {
                      setShowInstall(e.target.checked)
                    }}
                  />
              <Typography>Parcelado</Typography>
              </>
              }
            />


          </Box>

          <Fade
            in={showInstall}
            mountOnEnter
            unmountOnExit
            timeout={300}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: '0.5rem'
              }}
            >

              <TextField
                label="Inicio da parcela"
                size={'small'}
                disabled={id && handleId !== id}
                value={installment_start?.toString()?.replace(/\D+/g, "") ?? ''}
                onChange={handleInstallmentChangeStart}
                sx={{
                  maxWidth: '150px',
                  width: '100%',
                }}
              />
              <Typography
                sx={{
                  fontSize: '23px',
                  paddingX: '0.5rem'
                }}
              >
                /
              </Typography>

              <TextField
                label="Total"
                size={'small'}
                disabled={id && handleId !== id}
                value={installments?.toString()?.replace(/\D+/g, "") ?? ''}
                onChange={handleInstallmentChange}
                sx={{
                  maxWidth: '150px',
                  width: '100%',
                }}
              />

              {installments &&
                <>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      // paddingX: '1rem'
                      marginX: '0.5rem'
                    }}
                  >
                    x
                  </Typography>
                  <Typography>
                    {installmentPreview()}
                  </Typography>
                </>
              }

            </Box>

          </Fade>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: '0.5rem'
            }}
          >
          <Box>
            {/*<FormControlLabel*/}
            {/*  control={<Checkbox*/}
            {/*    sx={{*/}
            {/*      // padding: '0.6rem',*/}

            {/*    }}*/}
            {/*    onChange={handleProportioalChange}*/}
            {/*    checked={apply_proportional_calculation}*/}
            {/*  />}*/}
            {/*  label="Cobrança Proporcional"*/}
            {/*/>*/}

            <FormControlLabel
              control={<Checkbox
                sx={{
                  // padding: '0.6rem',

                }}
                onChange={handleRecipientCharge}
                checked={charge_recipient}
              />}
              label="Cobrar Beneficiário"
            />
          </Box>
          <Box>
            <FormControlLabel
              sx={{
                // paddingX: '0.6rem'
              }}
              control={
                <Checkbox
                  disabled={disable}
                  checked={transfer_recipient}
                  onChange={handleRecipientTransfer}
                />
              }
              label="Transferir para o Beneficiário"/>

            <FormControlLabel
              sx={{
                // paddingX: '0.6rem'
              }}
              control={
                <Checkbox
                  disabled={disable}
                  checked={include_income_tax}
                  onChange={handleIncomeTax}
                />
              }
              label="Incluir no Imposto de Renda"/>
          </Box>
          </Box>
        </Box>

      </>
    }

      <SimpleDialog
        open={openDelete}
        setOpen={setOpenDelete}
        title={'Excluir cobrança'}
        question={'Realmente quer excluir essa cobranca?'}
        onClickConfirm={() => taxDeleteById(handleId)}
      />
    </Box>
    </>
  );
}

function ExtraCharge(props) {
  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////
  const { data, onChange, setTaxDelete, isLoading, taxUpdate, taxCreate, taxDeleteById, getTax } = props;
  const [filters, setFilters] = useState({ fully_paid: false })

  ///////////////////////////
  //// General functions ////
  ///////////////////////////

  function handleDataChange (index, newData) {
    const newDataList = [...data];
    newDataList[index] = newData;
    onChange(newDataList);
  };

  function handleDataRemove (index) {
    const newDataList = data.filter((_, i) => i !== index);
    const newDataDelete = data.filter((_, i) => i === index);
    const objToDelete = newDataDelete.find((obj) => obj.hasOwnProperty("id"));
    if (objToDelete) {
      setTaxDelete(prevState => [...prevState, objToDelete]);
    }
    onChange(newDataList);
  }

  function handleAddData () {
    // moment().add(1, 'month').startOf('month').format('MM')
    const today = moment().add(1, 'month').startOf('date');
    const month = moment(today).startOf('date').format('MM');
    const year = moment(today).startOf('date').format('YYYY');
    const newDataList = [
      ...data,
      {
        transfer_recipient: false,
        include_income_tax: true,
        charge_recipient: false,
        description: '',
        value: 0,
        month: month,
        year: year
      }
    ];
    onChange(newDataList);
  }

  useEffect(function whenFiltersChange(){
    if(filters?.fully_paid){
      getTax(filters)
    }
  },[filters])

  return (
    <Grid item xs={12}>
      {!isLoading ?
        <Card>
          <CardHeader
            avatar={<Avatar sx={{bgcolor: 'primary.main'}}><ReceiptLong/></Avatar>}
            title={'Cobranças'}
            action={
              <TextField
                select
                size={'small'}
                label={'Classificar'}
                value={filters?.fully_paid ?? ''}
                onChange={(e) => setFilters({ fully_paid: e.target.value}) }
                sx={{
                  maxWidth: '250px',
                  width: '100%',
                  mr: '0.5rem'
                }}
              >
                <MenuItem
                  value={'true'}
                >
                  Pagos
                </MenuItem>
                <MenuItem
                  value={'false'}
                >
                  Pendentes
                </MenuItem>
              </TextField>
            }


          />
          <Divider sx={{mb: '.3rem'}}/>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <List sx={{p: 0}}>
                {data?.map((entry, index) => (
                  <ListItem sx={{p: 0}} key={index} disableGutters>
                    <DataInput
                      index={index}
                      installments={entry.installments}
                      paid_installment={entry.paid_installment}
                      id={entry.id}
                      description={entry.description}
                      value={entry.value}
                      installment_value={entry?.installment_value}
                      fully_paid={entry.fully_paid}
                      installment_start={entry.installment_start}
                      apply_proportional_calculation={entry.apply_proportional_calculation ?? false}
                      month={entry.month}
                      year={entry.year}
                      include_income_tax={entry.include_income_tax}
                      createdAt={entry.created_at}
                      onChange={handleDataChange}
                      onRemove={handleDataRemove}
                      taxDeleteById={taxDeleteById}
                      taxUpdate={taxUpdate}
                      taxCreate={taxCreate}
                      getTax={getTax}
                      transfer_recipient={entry.transfer_recipient}
                      charge_recipient={entry.charge_recipient}
                    />
                  </ListItem>
                ))}
              </List>
              <Button
                variant="contained"
                startIcon={<Add/>}
                size={'small'}
                onClick={handleAddData}
                sx={{mt: '1rem'}}
              >
                Adicionar
              </Button>
            </Box>
          </CardContent>
        </Card>
        :
        <Skeleton
          height={300}
          width={'100%'}
          variant='rounded'
        />
      }

    </Grid>
  );
}

export default ExtraCharge