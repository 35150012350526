import {IconButton, Tooltip} from "@mui/material";
import {DescriptionOutlined, Download} from "@mui/icons-material";
import React, {useState} from "react";
import {api} from "../../../../../../../services/Main/Api";
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import {useSnackbar} from "notistack";
import axios from 'axios';
import FileSaver from 'file-saver';
const InvoiceDetailDownloadRentReceipt = (props) => {

    const { invoice } = props;
    const { enqueueSnackbar } = useSnackbar();

    async function handleDownload(invoiceId) {
        try {
            const response = await api.get(`rent_receipt_preview/${invoiceId.id}`, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            FileSaver.saveAs(blob, 'Recibo de Aluguel.pdf');
            enqueueSnackbar("Download realizado com sucesso!", {variant: "success"});
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Problema no download do recibo.", { variant: "error"});
        }
    }

return (
        <Tooltip
            title={'Baixar Recibo'}
            arrow
            placement={'top'}
        >
      <span>
        <IconButton
            onClick={() => handleDownload(invoice)}
            disabled={invoice?.status?.key !== 'paid'}>
            <ReceiptOutlinedIcon/>
        </IconButton>
      </span>
        </Tooltip>
    )
}

export default InvoiceDetailDownloadRentReceipt