import { Avatar, Badge, Box, Grid, IconButton, Link, Skeleton, Typography } from '@mui/material'
import { MessageOutlined, NotificationsOutlined } from '@mui/icons-material'
import Menu from '@mui/material/Menu'
import React, {useContext, useEffect, useState} from 'react'
import { getCondoId } from '../../services/Main/AuthStorage'
import { api } from '../../services/Main/Api'
import Divider from '@mui/material/Divider'
import moment from 'moment'
import Context from "../../contexts/Context";

const NotificationMenu = () => {
  const { filters, setFilters } = useContext(Context)
  useEffect(function NotificationFilters(){
    setFilters({
      condo_id: getCondoId(),
    })
  },[])

  const [anchor, setAnchor] = useState(null)
  const [notificationCount, setNotificationCount] = useState()
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(true)
  const open = Boolean(anchor)

  useEffect(() => {
    if (filters && getCondoId()) {
      api.get('notification/unread-count/', { params: filters })
       .then(r => {
        setNotificationCount(r?.data?.total)
      })
        .catch(r => {console.log(r)
        })
    }
  }, [notifications, filters])

  const handleAnchor = (e) => {
    const params = { condo_id: getCondoId() }

    api.get('notification/', { params: params})
      .then(r => {
        setNotifications(r?.data?.results)
        setLoading(false)
    }).catch(r => {console.log(r)})

    setAnchor(e.currentTarget)
  }

  const handleClose = () => {
    setAnchor(null)
  }

  return (
    <>
      <IconButton
        id="notification-button"
        onClick={handleAnchor}
        aria-controls={open ? 'notification-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Badge color={'primary'} badgeContent={notificationCount} >
          <NotificationsOutlined color={'primary'}/>
        </Badge>
      </IconButton>
      <Menu
        id="notification-menu"
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
        MenuListProps={{
          'aria-labelledby': 'notification-button',
        }}
        onClose={handleClose}
        anchorEl={anchor}
        open={open}
        sx={{
          filter: 'drop-shadow(rgb(32 32 32 / 6%) 0 1px 8px 16px)',
        }}>
        <Box sx={{ p: 2, maxWidth: 300, minWidth: 300, width: '100%' }}>
          <Typography component={'span'} fontWeight={'bold'}>{'Notificações'}</Typography>
          <Divider sx={{ my: '.5rem' }}/>
          <Grid container my={2}>
            {loading ?
              <>
                <Skeleton variant={'rectangular'} width={280} height={50} sx={{ mb: 2 }}/>
                <Skeleton variant={'rectangular'} width={280} height={50} sx={{ mb: 2 }}/>
                <Skeleton variant={'rectangular'} width={280} height={50} sx={{ mb: 2 }}/>
                <Skeleton variant={'rectangular'} width={280} height={50} sx={{ mb: 2 }}/>
                <Skeleton variant={'rectangular'} width={280} height={50} sx={{ mb: 2 }}/>
              </> :
              notifications.map((item, i) => {
                if (i < 5) {
                  return (
                    <Grid item xs={12} key={i} sx={{ mb: 2 }} display={'flex'} alignItems={'center'}>
                      <Avatar sizes={'small'} sx={{ width: 36, height: 36, bgcolor: 'neutral.main', mr: 2 }}>
                        <MessageOutlined fontSize={'small'} sx={{ color: 'secondary.light' }}/>
                      </Avatar>
                      <Box>
                        <Typography component={'div'} fontWeight={!item.read ? 'bold' : 'normal'}
                                    fontSize={'small'}>{item.message}</Typography>
                        <Typography fontSize={12} color={'#aaa'}>{moment(item.created_at).fromNow()}</Typography>
                      </Box>
                    </Grid>
                  )
                } else {
                  return false
                }
              })
            }
          </Grid>
          <Box textAlign={'center'} mt={2}>
            <Link
              fontSize={'small'}
              fontWeight={'bold'}
              href={'/notificacoes'}>
              {'Ver Todas'}
            </Link>
          </Box>
        </Box>
      </Menu>
    </>
  )
}

export default NotificationMenu
