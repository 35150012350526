import {Box, Skeleton} from "@mui/material";
import React from "react";


const DateSkeleton = ({mb}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mb: mb ?? '2rem'
      }}
    >
      <Skeleton
        variant="rounded"
        animation="pulse"
        height={39.974}
        width={'49%'}
      />
      <Skeleton
        variant="rounded"
        animation="pulse"
        height={39.974}
        width={'49%'}
      />
    </Box>
  )
}

export default DateSkeleton