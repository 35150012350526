import {Avatar, Card, CardContent, CardHeader, Divider, Grid, Skeleton, Typography} from "@mui/material";
import {AddBusiness, Close} from "@mui/icons-material";
import React from "react";
import Masks from "../../../../../components/Shared/Masks";
import ProviderReadComponent from "./dependencies/ProviderReadComponent";

const ProviderReadAdress = ({ isLoading, data }) => {
  return (
    <Card
      sx={{
        marginY: "1rem"
      }}
    >
      <CardHeader
        avatar={
          <Avatar sx={{bgcolor: "primary.main"}}>
            <AddBusiness/>
          </Avatar>
        }
        title={"Endereço"}
      />
      <Divider
        sx={{
          marginY: '.3rem'
        }}
      />
      <CardContent
        sx={{
          margin: '1rem'
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
        >

          <ProviderReadComponent
            text={'CEP:'}
            isLoading={isLoading}
            dataText={Masks.cep(data?.cep ?? '' ) ?? <Close sx={{color: 'error.main'}}/>}
          />

          <ProviderReadComponent
            text={'Rua:'}
            isLoading={isLoading}
            dataText={data?.street ?? <Close sx={{color: 'error.main'}}/>}
          />

          <ProviderReadComponent
            text={'Número:'}
            isLoading={isLoading}
            dataText={data?.number ?? <Close sx={{color: 'error.main'}}/>}
          />

          <ProviderReadComponent
            text={'Bairro:'}
            isLoading={isLoading}
            dataText={data?.district ?? <Close sx={{color: 'error.main'}}/>}
          />

          <ProviderReadComponent
            text={'Cidade:'}
            isLoading={isLoading}
            dataText={data?.city ?? <Close sx={{color: 'error.main'}}/>}
          />

          <ProviderReadComponent
            text={'UF:'}
            isLoading={isLoading}
            dataText={data?.state ?? <Close sx={{color: 'error.main'}}/>}
          />

        </Grid>
      </CardContent>
    </Card>
  )
}

export default ProviderReadAdress