import React, {useContext, useEffect} from "react";
import Context from "../../contexts/Context";
import {api} from "../../services/Main/Api";
import {getCondoId} from "../../services/Main/AuthStorage";
import {Autocomplete, Skeleton, TextField} from "@mui/material";

const BlockFilter = ({ state, setState, keyName, defaultValue, styles, variant, value, error, numberValue, isLoading}) => {
  const { setFilters, blockOptions, setBlockOptions } = useContext(Context)

  // useEffect(() => {
  //   api.get(`block/?condo_id=${getCondoId()}`)
  //     .then(r => {
  //       setBlockOptions(r?.data?.results?.map((option) => ({ label: option.identifier, value: option.id })))
  //     }).catch(r => {
  //     console.log(r)
  //   })
  // }, [])

  const alternateState = setState ?? setFilters



  return(
    <>
      {!isLoading ?
      <Autocomplete
        fullWidth
        onChange={(_event,value) => alternateState((prevState) => (
          {...prevState, [keyName ?? 'block']: numberValue ? +value?.value : value?.value }
        ))}
        defaultValue={defaultValue}
        renderInput={(props) => <TextField
          {...props}
          variant={variant}
          label={'Bloco'}
          size={"small"}
          error={!!error?.[keyName]}
          helperText={error?.[keyName]}
        />}
        getOptionLabel={(option) => `${option.label}` ?? ['']}
        options={blockOptions ?? ['']}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        sx={styles}
      />
      :
      <Skeleton
        variant="rounded"
        animation="pulse"
        height={39.964}
        sx={styles}
      />
      }
    </>
  )
}

export default BlockFilter