import {Avatar, Card, CardContent, CardHeader, Grid, Skeleton, useMediaQuery} from "@mui/material";
import {AccountBalance} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import AccountReadInfo from "../BankTransfer/AccountReadInfo";
import React from "react";

const AccInfoCard = ({ isLoading }) => {

  const sizeMatch = useMediaQuery('@media (min-width:900px)')

  return (
    <>
      {!isLoading ?
        <Card
          sx={{
            width: '100%',
            mb: '1rem',
            paddingBottom: sizeMatch ? '2rem' : '1rem',
          }}
        >
          <CardHeader
            title={'Informações da conta'}
            avatar={<Avatar sx={{bgcolor: "primary.main"}}><AccountBalance/></Avatar>}
          />
          <Divider />

          <CardContent
            sx={{
              p: '2rem',

            }}
          >
            <AccountReadInfo
              balanceStyles={{ mb: 0 }}
            />
          </CardContent>
        </Card>
        :
        <Skeleton
          variant="rounded"
          animation="pulse"
          height={272.833}
          sx={{
            width: '100%'
          }}
        />
      }
    </>
  )
}

export default AccInfoCard