import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import moment from 'moment/moment'
import {
  Avatar,
  Backdrop,
  Box, Button,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  Tab,
  Tabs,
  useMediaQuery,
} from '@mui/material'
import {
  Close,
  CurrencyExchangeOutlined,
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { Helmet } from 'react-helmet'
import {
  getAcc,
  getBanks,
  searchAcc,
} from "./components/BankTransfer/Requisitions";
import {getCondoId} from "../../../services/Main/AuthStorage";
import Context from "../../../contexts/Context";
import {api} from "../../../services/Main/Api";
import BankTransferBody from "./components/BankTransfer/BankTransferBody";
import BankTransferList from "./components/BankTransferList/BankTransferList";
import BankTransferButtons from "./components/BankTransfer/BankTransferButtons";
import AuthValidation from "../../../components/Shared/AuthValidation";

const BankTransfer = () => {
  const  { filters, setFilters, setLoadingModal, dataProvider, setDataProvider } = useContext(Context)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { operationType, transferReq } = filters || {}
  const sizeMatch = useMediaQuery('@media (min-width:600px)')



  const [checkingAccounts, setCheckingAccounts] = useState(null)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [reqData, setReqData] = useState(null)
  const [bankNames, setBankNames] = useState(null)
  const [check, setCheck] = useState(false)
  const [transferLoad, setTransferLoad] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  const [isNotDuplicate, setIsNotDuplicate] = useState(true)
  const [ url, setUrl] = useState(null)
  const [tabValue, setTabValue] = useState(0)
  const [account, setAccount] = useState({search: ''})


  const buttonBehavior = {
    isEnabled:
      reqData?.account_type &&
      reqData?.bank &&
      reqData?.bank_account &&
      reqData?.bank_account_digit &&
      reqData?.bank_branch &&
      reqData?.name &&
      reqData?.payment_date &&
      reqData?.tax_number &&
      reqData?.value &&
      reqData?.value?.toString()?.replace(/[^\d]+/g, "") !== '00',

    isEnabledJustTransfer:
      reqData?.payment_date &&
      reqData?.value &&
      reqData?.value?.toString()?.replace(/[^\d]+/g, "") !== '00'  &&
      selectedAccount?.id
  }

  const buttonBehaviorPix = {
    isEnabled:
      (reqData?.pix_key_type || reqData?.pix_key_type === 0)  &&
      reqData?.pix_key &&
      reqData?.payment_date &&
      reqData?.value &&
      reqData?.value?.toString()?.replace(/[^\d]+/g, "") !== '00',

    isEnabledJustTransfer:
      reqData?.value &&
      reqData?.value?.toString()?.replace(/[^\d]+/g, "") !== '00'  &&
      reqData?.payment_date &&
      selectedAccount?.id
  }

  function initialStates (){
    setReqData({ payment_date: moment().format('YYYY-MM-DD')  })
    setFilters(prevState => ({...prevState, transferType: ''}))
    setSelectedAccount(null)
    setCheck(false)
    setCodeSent(false)
  }

  function snackNavigate(key) {
    enqueueSnackbar('Recibo', {
      variant: 'info',
      autoHideDuration: null,
      action: (
        <>
          <Button color="inherit" size="small" onClick={() => closeSnackbar(key)}>
            <Close />
          </Button>
          <Button color="inherit" size="small" onClick={() => window.open(url, '_blank')}>
            Abrir
          </Button>
        </>
      ),
    })
  }


  ///////////////////////////////////
  //////////Requisitions////////////
  //////////////////////////////////

  async function duplicateSubmit() {
    try {
      const data = {
        condo: +getCondoId(),
        value: +reqData?.value?.replace(/\D+/g, ""),
        external_bank: +selectedAccount?.id,
      };

      const pixData = {
        condo: +getCondoId(),
        value: +reqData?.value?.replace(/\D+/g, ""),
        pix_key: selectedAccount?.pix_key_value?.toString(),
      };

      let alternateUrl;

      if (filters?.transferType === 'ted') {
        alternateUrl = await api.post('fitbank/money-transfer/last/', data);
      } else if (filters?.transferType === 'pix') {
        alternateUrl = await api.post('fitbank/pix-transfer/last/', pixData);
      }

      setIsNotDuplicate(alternateUrl.data);
      return alternateUrl.data;
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Ocorreu um erro nessa etapa', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } finally {
      setTransferLoad(false);
    }
  }

  async function submitReq() {
    setTransferLoad(true);

    try {
      if (check) {
        const externalBankRes = await api.post('fitbank/external-bank/', {
          name: reqData?.name,
          tax_number: reqData?.tax_number?.replace(/[/.-]/g,''),
          bank: reqData?.bank,
          bank_branch: reqData?.bank_branch,
          bank_account: reqData?.bank_account,
          bank_branch_digit: reqData?.bank_branch_digit,
          bank_account_digit: reqData?.bank_account_digit,
          account_type: reqData?.account_type,
          condo: getCondoId(),
        });

        enqueueSnackbar('Conta criada com sucesso!', { variant: 'success' });

        const selectedAccountRes = await api.get(`fitbank/external-bank/${externalBankRes.data.id}`);
        setSelectedAccount(selectedAccountRes.data);

        const moneyTransferRes = await api.post('fitbank/money-transfer/last/', {
          condo: +getCondoId(),
          value: +reqData?.value.replace(/\D+/g, ""),
          external_bank: +selectedAccountRes.data.id,
        });

        setIsNotDuplicate(moneyTransferRes.data);

      } else {
        await duplicateSubmit();
      }
    } catch (error) {
      console.log(error);

      if (error.response) {
        enqueueSnackbar(error.response.data, { variant: 'error' });
      } else {
        enqueueSnackbar('Ocorreu um erro na requisição', { variant: 'error' });
      }

    } finally {
      setTransferLoad(false);
    }
  }

  async function submitReqPix() {
    setTransferLoad(true);

    try {
      if (check) {
        const pixKeyRes = await api.post('fitbank/pix-key/', {
          pix_key_type: +reqData?.pix_key_type,
          pix_key:
            (reqData?.pix_key_type !== 2 && reqData?.pix_key_type !== 4 )
              ? reqData?.pix_key?.toString()?.replace(/[^\d]+/g, "")
              : reqData?.pix_key_type === 4 ? reqData?.pix_key?.toString()
                : reqData?.pix_key_type === 2  && reqData?.pix_key?.toString(),
          condo: +getCondoId(),
        });

        enqueueSnackbar('Conta criada com sucesso!', { variant: 'success' });

        const selectedAccountRes = await api.get(`fitbank/pix-key/${pixKeyRes.data.id}`);
        setSelectedAccount(selectedAccountRes.data);

        const pixTransferRes = await api.post('fitbank/pix-transfer/last/', {
          condo: +getCondoId(),
          value: +reqData?.value.replace(/\D+/g, ""),
          pix_key: selectedAccountRes.data.pix_key_value.toString(),
        });

        setIsNotDuplicate(pixTransferRes.data);

      } else {
        await duplicateSubmit();
      }
    } catch (error) {
      console.log(error);

      if (error.response) {
        enqueueSnackbar(error.response.data, { variant: 'error' });
      } else {
        enqueueSnackbar('Ocorreu um erro na requisição', { variant: 'error' });
      }

    } finally {
      setTransferLoad(false);
    }
  }

  async function submitTransfer() {
    if (dataProvider?.validation === true) {
      setTransferLoad(true);
      transferReq && setLoadingModal(true);

      const data = {
        condo: +selectedAccount?.condo,
        value: +reqData?.value?.toString()?.replace(/\D+/g, ""),
        external_bank: +selectedAccount?.id,
        description: reqData?.description,
        payment_date: reqData?.payment_date,
        validation_code: null,
        expense: filters?.expenseId ?? null,
      };

      try {
        if (data) {
          const res = await api.post('fitbank/money-transfer/', data);
          setSelectedAccount('');
          setUrl(`/transferencia/${res?.data?.id}/recibo/ted`);
          setDataProvider((prevState) => ({ ...prevState, validation: false, transferReq: false }));
          enqueueSnackbar('Transferência agendada com sucesso', { variant: 'success' });

          if (transferReq) {
            setFilters((prevState) => ({
              ...prevState,
              secondStep: true,
              transferDone: true,
              receiptUrl: `/transferencia/${res?.data?.id}/recibo/ted`,
              operationType: '',
            }));
          }

          initialStates();
        }
      } catch (error) {
        console.log(error);
        transferReq && setFilters((prevState) => ({ ...prevState, transferReq: false }));

        if (error.response) {
          error.response.data[0] &&
          enqueueSnackbar(error.response.data[0], {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });

          setDataProvider((prevState) => ({ ...prevState, reqError: error.response.data[0] }));

          error.response.data.Erro &&
          enqueueSnackbar(
            filters?.transferReq
              ? 'Erro ao fazer transferência, código errado'
              : 'Código errado, confira novamente seu e-mail ou reenvie',
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            }
          );
        } else {
          enqueueSnackbar('Ocorreu um erro na requisição', { variant: 'error' });
        }
      } finally {
        setTransferLoad(false);
        setLoadingModal(false);
      }
    } else {
      enqueueSnackbar('Sem permissão, efetuar etapa de segurança', { variant: 'error' });
      setDataProvider((prevState) => ({ openAuth: true, validation: false }));
    }
  }

  async function submitTransferPix() {
    if (dataProvider?.validation === true) {
      setTransferLoad(true);
      transferReq && setLoadingModal(true);

      const data = {
        condo: +selectedAccount?.condo,
        value: +reqData?.value?.toString()?.replace(/\D+/g, ""),
        pix_key: +selectedAccount?.id,
        description: reqData?.description,
        payment_date: reqData?.payment_date,
        validation_code: null,
        expense: filters?.expenseId ?? null,
      };

      try {
        if (data) {
          const res = await api.post('fitbank/pix-transfer/', data);
          setSelectedAccount('');
          setDataProvider((prevState) => ({ ...prevState, validation: false, transferReq: false}));
          setUrl(`/transferencia/${res?.data?.id}/recibo/pix`);
          enqueueSnackbar('Transferência agendada com sucesso', { variant: 'success' });

          if (transferReq) {
            setFilters((prevState) => ({
              ...prevState,
              secondStep: true,
              transferDone: true,
              receiptUrl: `/transferencia/${res?.data?.id}/recibo/pix`,
              operationType: '',
            }));
          }

          initialStates();
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Erro ao realizar transferência', { variant: 'error' });

        transferReq && setFilters((prevState) => ({ ...prevState, transferReq: false }));
        setDataProvider((prevState) => ({ ...prevState, reqError: error.response?.data[0] }));

        if (error.response) {
          error.response.data[0] &&
          enqueueSnackbar(error.response.data[0], {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });

          error.response.data.Erro &&
          enqueueSnackbar(
            filters?.transferReq
              ? 'Erro ao fazer transferência, código errado'
              : 'Código errado, confira novamente seu e-mail ou reenvie',
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            }
          );
        }
      } finally {
        setTransferLoad(false);
        setLoadingModal(false);
      }
    } else {
      enqueueSnackbar('Sem permissão, efetuar etapa de segurança', { variant: 'error' });
      setDataProvider((prevState) => ({ openAuth: true, validation: false }));
    }
  }


  useEffect(function expenseReq(){
    // Requistion if made from ExpenseCreate
    filters?.transferReq === true && filters?.transferType === 'ted' && submitTransfer()
    filters?.transferReq === true && filters?.transferType === 'pix' && submitTransferPix()
  }, [filters?.transferReq])

  useEffect(function snackReceipt() {
    url && snackNavigate()
  }, [url])

  useEffect(function bankAndAcc() {
    getAcc(setCheckingAccounts, filters)
    getBanks(setBankNames)
  }, [filters?.transferType, dataProvider?.refresh])


  useEffect(function accSearch () {
    searchAcc(account, setSelectedAccount,filters)
    account?.search === "" && setSelectedAccount(null)
  }, [account?.id])


  useEffect(function whenCheck () {
    check && setSelectedAccount('')
    check && setAccount((prevState) =>({...prevState, search: '' }))
    !check && setReqData({ payment_date: moment().format('YYYY-MM-DD') })
    !check && searchAcc(account, setSelectedAccount, filters)

  }, [check, account?.search])



  return (
    <>
      {!operationType ?
        <>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={transferLoad}>
            <CircularProgress color="inherit"/>
          </Backdrop>
          <Helmet>
            <title>Transferência - Bloco B3</title>
          </Helmet>
          <Box
            sx={{
              p: sizeMatch ? '24px' : 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Box
              sx={{
                maxWidth: '900px',
                width: '100%',
              }}
            >
              <Grid
                item
                xs={12} >
                <Card
                  sx={{
                    width: '100%',
                  }}
                >
                  <CardHeader
                    avatar={<Avatar sx={{ bgcolor: 'primary.main' }}><CurrencyExchangeOutlined/></Avatar>}
                    title={'Transferências'}/>
                  <Tabs
                    value={tabValue}
                    onChange={(_event, newValue) => {
                      setFilters(prevState => ({...prevState, transferType: ''}))
                      setTabValue(newValue)
                    } }
                    sx={{
                      borderTopRightRadius: '10px',
                      borderTopLeftRadius: '10px'

                    }}
                  >
                    <Tab label="Transferir"/>
                    <Tab label="Histórico"/>
                  </Tabs>
                  <Divider sx={{ mb: '.3rem' }}/>

                  {tabValue === 0 ?
                    <Fade
                      in={true}
                      mountOnEnter
                      unmountOnExit
                      timeout={300}
                    >
                      <Box>
                        <BankTransferBody
                          selectedAccount={selectedAccount}
                          setSelectedAccount={setSelectedAccount}
                          codeSent={codeSent}
                          check={check}
                          setCheck={setCheck}
                          checkingAccounts={checkingAccounts}
                          reqData={reqData}
                          setReqData={setReqData}
                          bankNames={bankNames}
                          setCodeSent={setCodeSent}
                          setIsNotDuplicate={setIsNotDuplicate}
                          isNotDuplicate={isNotDuplicate}
                          submitReq={submitReq}
                          submitTransfer={submitTransfer}
                          account={account}
                          setAccount={setAccount}
                          submitReqPix={submitReqPix}
                          submitTransferPix={submitTransferPix}
                          buttonBehavior={buttonBehavior}
                          buttonBehaviorPix={buttonBehaviorPix}

                        />
                      </Box>
                    </Fade>
                    : tabValue === 1 &&
                    <Fade
                      in={true}
                      mountOnEnter
                      unmountOnExit
                      timeout={300}
                    >
                      <Box>
                        <BankTransferList/>
                      </Box>
                    </Fade>
                  }
                </Card>

                {tabValue === 0 &&
                  <BankTransferButtons
                    check={check}
                    setCheck={setCheck}
                    buttonBehavior={buttonBehavior}
                    buttonBehaviorPix={buttonBehaviorPix}
                    codeSent={codeSent}
                    reqData={reqData}
                    setSelectedAccount={setSelectedAccount}
                    setCodeSent={setCodeSent}
                    submitTransfer={submitTransfer}
                    submitTransferPix={submitTransferPix}
                    submitReq={submitReq}
                    submitReqPix={submitReqPix}
                    duplicateSubmit={duplicateSubmit}
                    isNotDuplicate={isNotDuplicate}
                    setIsNotDuplicate={setIsNotDuplicate}

                  />
                }


              </Grid>
            </Box>
          </Box>
        </>
        :
        <>
          <BankTransferBody
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            codeSent={codeSent}
            check={check}
            setCheck={setCheck}
            checkingAccounts={checkingAccounts}
            reqData={reqData}
            setReqData={setReqData}
            bankNames={bankNames}
            setCodeSent={setCodeSent}
            setIsNotDuplicate={setIsNotDuplicate}
            isNotDuplicate={isNotDuplicate}
            submitReq={submitReq}
            submitTransfer={submitTransfer}
            account={account}
            setAccount={setAccount}
            submitReqPix={submitReqPix}
            submitTransferPix={submitTransferPix}
            buttonBehavior={buttonBehavior}
            buttonBehaviorPix={buttonBehaviorPix}
          />
        </>
      }
      <AuthValidation />

    </>
  )
}

export default BankTransfer
