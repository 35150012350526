import {
  Avatar,
  Backdrop,
  Box, Button,
  Card,
  CardContent, CardHeader, Chip,
  CircularProgress,
  Container, Divider, Fade,
  Grid, IconButton,
  Skeleton,
  Typography
} from "@mui/material";
import GarageBox from "./components/GarageBox/GarageBox";
import SimpleButtons from "../../../components/Shared/SimpleButtons";
import React, {useContext, useEffect, useState} from "react";
import Context from "../../../contexts/Context";
import {useLocation, useParams} from "react-router-dom";
import { Edit, House } from "@mui/icons-material";
import {api} from "../../../services/Main/Api";
import {useSnackbar} from "notistack";
import Owner from "./components/Owner/Owner";
import {getCondoId} from "../../../services/Main/AuthStorage";
import UnitInputs from "./components/UnitCreateAndUpdate/UnitInputs";
import UnitRead from "./components/UnitCreateAndUpdate/UnitRead";
import {Helmet} from "react-helmet";
import UnitWizard from "./UnitWizard";
import ButtonSet from "../../../components/Shared/ButtonSet";
import ConcluisionUnit from "./ConcluisionUnit";
import AnimationGlobal from "../../../components/Shared/animations/AnimationGlobal";
import successAnimation from "../../../assets/animations/success.json";
import GarageFormSet from "./GarageFormSet";
import SaveCancelButton from "../../../components/Shared/SaveCancelButton";

  /*
   This component serves both UnitCreateUpdate and UnitUpdates
  */
const UnitForm = ({ getContract, setUnitData }) => {


  const { loadingModal, blockOptions, dataProvider, setDataProvider, getUnitOptions } = useContext(Context)
  const {enqueueSnackbar} = useSnackbar()

  const params = useParams()
  const location = useLocation()
  const rentUrl = location.pathname.includes('alugueis')

  const [reqData, setReqData] = useState(null)
  const [defaultValue, setDefaultValue] = useState(null)
  const [owner, setOwner] = useState(false)
  const [edit, setEdit] = useState(false)
  const [loadBlock, setLoadBlock] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [dataOwner, setDataOwner] = useState([])
  const [ownerDelete, setOwnerDelete] = useState([])
  const [filters, setFilters] = useState({
    renter: false,
    unit_id: params.id,
    unit__condo_id: +getCondoId()
  })


  function statusFilter(param) {
    switch (param) {
      case 'available':
        return 'Disponível';
      case 'occupied':
        return 'Ocupado';
      default:
        return '- - -';
    }
  }

  function statusColor(param) {
    switch (param) {
      case 'available':
        return 'success.main';
      case 'occupied':
        return 'error.main';
      default:
        return 'info.main';
    }
  }

  async function getUnit(load, param) {
    if (getCondoId()) {
      if (load) {
        setIsLoading(true);
      }

      const unitIdToFetch = dataProvider?.unitId || param || params?.id

      try {
        const response = await api.get(`unit/${unitIdToFetch}`);
        setReqData(response.data);
        setDataProvider(prevState => ({...prevState, unitInfo: response.data}))
        setReqData((prevState) => ({ ...prevState, condo: +getCondoId() }));
      } catch (error) {
        console.log(error);
      } finally {
        if (load) {
          setIsLoading(false);
        }
      }
    }
  }


  async function getOwner() {
    if (dataProvider?.unitId || params?.id) {
      try {
        const response = await api.get(`/unit_owner/`, { params: { unit_id: dataProvider?.unitId ?? params?.id } });
        setDataOwner(response.data.results);
      } catch (error) {
        console.log(error);
      }
    }
  }


  async function update() {
    // setIsLoading(true);

    const data = {
      ...reqData,
      owner: !filters?.renter,
      condo: +getCondoId(),
      phone: +reqData?.phone?.toString()?.replace(/[^\d]+/g, ""),
      cpf_visitor: +reqData?.cpf?.toString()?.replace(/[^\d]+/g, ""),
      percent_meter: reqData?.percent_meter?.toString()?.replace(/,/g, '.')
    };

    const url = `unit/${dataProvider?.unitId ?? params?.id}/`;
    const rentPath = location?.pathname?.includes('contratos')



    try {
      const response = await api.put(url, data);
      getUnit(response.data.id);
      rentPath && getContract(null, true)
      setEdit(false)
      rentPath && getUnitOptions()
      setError(null)
      enqueueSnackbar("Imóvel atualizado", { variant: "success" });
    } catch (error) {
      const { data } = error.response;
      enqueueSnackbar("Erro ao editar o imóvel", { variant: "error" });
      setError(data);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }


  useEffect(function initialLoad(){
    const isOnPropertyPage = location.pathname.includes('imoveis');
    if (isOnPropertyPage && params?.id) {
      getUnit(true)
      getOwner()
    }
  },[filters?.unit__condo_id])


  // useEffect(function afterSubmit(){
  //   if (dataProvider?.registerGarage === false){
  //     setIsLoading(false)
  //     enqueueSnackbar(params.id ? "Imóvel atualizado" : "Imóvel registrado", {variant: "success"})
  //     // navigate('/configuracoes/imoveis')
  //   }
  // }, [dataProvider?.registerGarage])

  useEffect(() => {
    if (blockOptions && reqData) {
      const defaultValue = blockOptions.find(e => +e.value === +reqData.block);
      setDefaultValue(defaultValue);
    }
  }, [blockOptions, reqData]);

  useEffect(function blockDefaultValue(){
    if (blockOptions && reqData) {
      setLoadBlock(true)
    }
  }, [blockOptions, reqData])

  // useEffect(function ownerRequired(){
  //   filters?.renter && reqData?.owner_name?.length > 0 ? setOwner(false) : setOwner(true)
  //   !filters?.renter &&  setOwner(false)
  // },[filters?.renter, reqData?.owner_name])

  useEffect(function updateWhenModalMode() {
    if (dataProvider?.modalUnit === true && dataProvider?.unitId){
      getUnit()
      getOwner()
    } else {
      setReqData(null)
    }
  },[dataProvider?.modalUnit])

  function stepComplete() {
    return (
      <Grid item xs={12}>

        <Card>
          <Fade
            in={true}
            mountOnEnter
            unmountOnExit
            timeout={300}
          >
            <CardContent>
              <AnimationGlobal
                text={'Etapa concluída'}
                size={'200px'}
                animation={successAnimation}
                fontStyles={{mb: '1rem'}}
                fontSize={'20px'}
              />
            </CardContent>
          </Fade>
        </Card>
      </Grid>
    )
  }

  function displayUnitWizard() {
    const isOnPropertyPage = location.pathname.includes('imoveis');
    const isOnContractPage = location.pathname.includes('contrato');

    if (isOnPropertyPage && !params?.id) {
      return true;
    } else if (isOnContractPage && dataProvider?.edit === false){
      return true
    }

    return false;
  }



  return (
    <>
      <Helmet>
        <title>{!params.id ? 'Criar imóvel - Bloco B3' : 'Editar imóvel - Bloco B3'}</title>
      </Helmet>
        <Backdrop
          sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
          open={loadingModal}
        >
          <CircularProgress color="inherit"/>
        </Backdrop>

      {displayUnitWizard() ?
        <Container
          // maxWidth={false}
          disableGutters={!!dataProvider?.modalUnit}
        >

          <Grid container spacing={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Grid item xs={12} lg={8} xl={dataProvider?.modalUnit ? 12 : 7}>
            <UnitWizard
                reqData={reqData}
                setError={setError}
                getUnit={getUnit}
                getContract={getContract}
                setUnitData={setUnitData}
              >
                {dataProvider?.UnitActiveStep === 0 && !dataProvider?.UnitFirstStep &&

                  <Card>
                <CardHeader
                  avatar={<Avatar sx={{bgcolor: 'primary.main'}}><House/></Avatar>}
                  title={'Imóvel'}/>
                <Divider sx={{mb: '.3rem'}}/>
                <CardContent>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '18px',
                        color: 'primary.main',
                      }}
                    >
                      Informações
                    </Typography>


                    <UnitInputs
                      reqData={reqData}
                      setReqData={setReqData}
                      error={error}
                      isLoading={isLoading}
                      rentUrl={rentUrl}
                      loadBlock={loadBlock}
                      defaultValue={defaultValue}
                      blockOptions={blockOptions}
                      filters={filters}
                    />

                </CardContent>
                </Card>
                }

              {dataProvider?.UnitActiveStep === 0 && dataProvider?.UnitFirstStep &&
                stepComplete()
              }

                  {dataProvider?.UnitActiveStep === 1 &&
                    <>
                      <Grid
                        item
                        xs={12}
                        sx={{mb: '1rem'}}
                      >

                        <Owner
                          data={dataOwner}
                          onChange={setDataOwner}
                          setOwnerDelete={setOwnerDelete}
                          isLoading={isLoading}
                          getOwner={getOwner}
                        />
                      </Grid>


                        <Grid item xs={12}>
                          <GarageFormSet />
                        </Grid>
                        </>
                      }
                  {dataProvider?.UnitActiveStep === 2 && dataProvider?.UnitFirstStep === true &&
                    <ConcluisionUnit/>
                  }
            </UnitWizard>
            </Grid>
          </Grid>
        </Container>
      :
      <Container
        maxWidth={false}
        disableGutters={!!dataProvider?.modalUnit}
      >
        <Grid container spacing={2} sx={{ display: 'flex', p: 0}}>
            <Grid item xs={12} lg={8} xl={dataProvider?.modalUnit ? 12 : 7}>
              {reqData || !params?.id ?
                <Card>
                  <CardHeader
                    avatar={<Avatar sx={{bgcolor: 'primary.main'}}><House/></Avatar>}
                    title={'Imóvel'}
                    action={
                      <Chip
                        label={statusFilter(reqData?.status)}
                        sx={{
                          borderColor: statusColor(reqData?.status),
                          color: statusColor(reqData?.status),
                          fontWeight: 'bold',
                        }}
                        variant="outlined"
                      />


                    }
                  />
                  <Divider sx={{mb: '.3rem'}}/>
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '18px',
                          color: 'primary.main',
                          mb: '2rem'

                        }}
                      >
                        Informações
                      </Typography>
                      {params?.id &&
                        <>
                          <IconButton
                              onClick={() => setEdit(true)}
                          >
                            <Edit/>
                          </IconButton>
                        </>
                      }
                    </Box>


                    {edit || !params?.id ?
                        <>
                      <UnitInputs
                        reqData={reqData}
                        setReqData={setReqData}
                        error={error}
                        isLoading={isLoading}
                        rentUrl={rentUrl}
                        loadBlock={loadBlock}
                        defaultValue={defaultValue}
                        blockOptions={blockOptions}
                        filters={filters}
                      />

                      {/*<ButtonSet*/}
                      {/*    editState={edit}*/}
                      {/*    removeDelete*/}
                      {/*    editClick={() => setEdit(true)}*/}
                      {/*    saveClick={() => {update()}}*/}
                      {/*    cancelClick={() => {*/}
                      {/*      getUnit()*/}
                      {/*      setEdit(false)*/}
                      {/*    }}*/}
                      {/*/>*/}
                          <SaveCancelButton
                              edit={edit}
                              removeDelete
                              saveClick={() => {update()}}
                              cancelClick={() => {
                                getUnit()
                                setEdit(false)
                              }}
                          />
                        </>

                      :
                      <UnitRead
                        reqData={reqData}
                        loadBlock={loadBlock}
                        rentUrl={rentUrl}
                      />
                    }


                  </CardContent>
                </Card>
                :
                <Skeleton
                  variant="rounded"
                  height={743.594}
                  animation="pulse"
                />
              }

            </Grid>
          <Grid item xs={12} lg={dataProvider?.modalUnit ? 12 : 5}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <Owner
                  data={dataOwner}
                  onChange={setDataOwner}
                  setOwnerDelete={setOwnerDelete}
                  isLoading={isLoading}
                  getOwner={getOwner}
                />
              </Grid>


              <Grid item xs={12}>
                <GarageFormSet />
                {/*<GarageBox/>*/}
              </Grid>
            </Grid>
          </Grid>


        </Grid>
        {!dataProvider?.modalUnit &&
          <SimpleButtons
            backButton={'Voltar'}
          />
        }
      </Container>
      }

    </>
  )
}

export default UnitForm