import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer
} from "@mui/material";
import {ReceiptLongOutlined} from "@mui/icons-material";
import {tableCellClasses} from "@mui/material/TableCell";
import ExpenseSummaryTotal from "./dependencies/ExpenseSummaryTotal";
import ExpenseSummaryInstallments from "./dependencies/ExpenseSummaryInstallments";
import ExpenseSummaryInstallValue from "./dependencies/ExpenseSummaryInstallValue";

const ExpenseResume = ({
    reqData,
    gasWaterTotalValue,
    installments
  }) => {

  return(
    <Grid item xs={12}>
      <Card >
        <CardHeader avatar={<Avatar sx={{ bgcolor: 'primary.main' }}><ReceiptLongOutlined/></Avatar>}
                    title={'Resumo da Despesa'}/>
        <Divider
          sx={{
            mb: '.3rem'
          }}
        />
        <CardContent>
          <TableContainer>
            <Table size={'small'} sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none'
              }
            }}>
              <TableBody>
                <ExpenseSummaryTotal
                  state={reqData}
                  gasWaterTotalValue={gasWaterTotalValue}
                />

                <ExpenseSummaryInstallments
                  state={reqData}
                  installments={installments}
                />

                <ExpenseSummaryInstallValue
                  state={reqData}
                  gasWaterTotalValue={gasWaterTotalValue}
                  installments={installments}
                />

              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default ExpenseResume