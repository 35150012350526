import {Box, Grid, Skeleton, Typography} from "@mui/material";
import React from "react";

const InvoiceDetailTitle = ({ invoice }) => {
    const reference = invoice ? invoice.reference_mode_company.value : null;

  return (
    <Grid
      item
      xs={6}
    >
      <Box
        border
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {invoice
          ?
          <Box>
                <Typography variant="h6">{invoice?.number}</Typography>
                 { reference === 'payment' ?
                <Typography >Competência: {invoice?.payment_month_reference}</Typography>
                  :
                <Typography >Referência: {invoice?.reference_month}</Typography>
                 }
          </Box>

          : <Skeleton
            variant="rectangular"
            animation="pulse"
            width={100}
            height={20}
          />
        }
      </Box>
    </Grid>
  )
}

export default InvoiceDetailTitle