import React, {
  useContext,
  useEffect,
  useState
} from "react";
import {
  Backdrop,
  CircularProgress,
  Skeleton,
  Typography
} from "@mui/material";
import {useSnackbar} from "notistack";
import {api} from "../../../services/Main/Api";
import {getCondoId} from "../../../services/Main/AuthStorage";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import { PriorityHigh } from "@mui/icons-material";
import SimpleText from "../../../components/Shared/SimpleText";
import Context from "../../../contexts/Context";
import ButtonSet from "../../../components/Shared/ButtonSet";

const BillboardForm = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { dataProvider, setDataProvider } = useContext(Context)

  const [reqData, setReqData] = useState(null)
  const [error, setError] = useState()
  const [loading, setLoading] = useState(!!dataProvider?.billId)
  const [edit, setEdit] =  useState(false)
  const [openDelete, setOpenDelete] = useState(null)
  const [reqLoading, setReqLoading] = useState(false)

  async function getBillBoard(){
    if(dataProvider?.billId) {
      try {
        const response = await api.get(`/billboard/${dataProvider?.billId}`);
        setReqData(response.data);
      } catch (error) {
        console.log(error);
        setError(error.data);
      } finally {
        setLoading(false);
      }
    }
  }

  async function saveReq(){
    setLoading(true)
    setReqLoading(true)

    const data = {
      ...reqData,
      condo: getCondoId()
    }


    function alternateReq() {
      if (dataProvider?.billId) {
        return api.put(`/billboard/${dataProvider?.billId}/`, data)
      } else {
        return api.post(`/billboard/`, data);
      }
    }

    try {
      const response = await alternateReq();
      setEdit(false);
      if (dataProvider?.billId) {
        enqueueSnackbar('Aviso atualizado', { variant: 'success' });
      } else {
        enqueueSnackbar('Aviso criado', { variant: 'success' });
        setDataProvider(prevState => ({ ...prevState, openBill: false }));
      }
      setError(null);
    } catch (error) {
      setError(error.response.data);
      if (dataProvider?.billId) {
        enqueueSnackbar('Erro ao atualizar aviso', { variant: 'error' });
      } else {
        enqueueSnackbar('Erro ao criar aviso', { variant: 'error' });
      }
      console.log(error);
    } finally {
      setLoading(false)
      setReqLoading(false)
    }
  }

  async function deleteBill (){
    try {
      await api.delete(`/billboard/${dataProvider?.billId}`);
      enqueueSnackbar('Aviso deletado com sucesso', { variant: 'success' });
      setOpenDelete(false);
      setDataProvider(prevState => ({ ...prevState, openBill: false }));

    } catch (error) {
      enqueueSnackbar('Erro ao excluir aviso', { variant: 'error' });
      console.log(error);
    }
  }

  useEffect(function getInfo(){
    getBillBoard()
  }, [dataProvider?.billId])

  useEffect(function whenModalClose(){
    if (dataProvider?.openBill === false){
      setLoading(true)
      setReqData(null)
      setDataProvider(prevState => ({ ...prevState, billId: null}))
    }

  },[dataProvider?.openBill])


  return (
    <>
      <SimpleDialog
        title={'Aviso'}
        open={dataProvider?.openBill}
        titleIcon={<PriorityHigh />}
        removeConfirm={!!dataProvider?.billId}
        onClickConfirm={!dataProvider?.billId ? () => {
          saveReq()
        } : false}
        onClickCancel={() => {
          setDataProvider(prevState => ({ ...prevState, openBill: false}))
          setLoading(true)
        }}
        actionMenu={
          <>
            {dataProvider?.billId &&
            <ButtonSet
              editState={edit}
              editClick={() => {setEdit(true)}}
              deleteClick={() => setOpenDelete(true)}
              saveClick={() => {saveReq()}}
              cancelClick={() => {
                getBillBoard()
                setEdit(false)
              }}
            />
            }
          </>
        }
        dialogContent={
          <>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={reqLoading}
            >
              <CircularProgress color="inherit"/>
            </Backdrop>
            {
              edit || !dataProvider?.billId && dataProvider?.openBill ?
                <>
                  <SimpleText
                    label={'Título'}
                    state={reqData}
                    setState={setReqData}
                    keyName={'subject'}
                    error={error}
                    styles={{
                      mt: '1rem',
                      mb: '1rem'
                    }}
                  />
                  <SimpleText
                    label={'Descrição'}
                    state={reqData}
                    setState={setReqData}
                    keyName={'text'}
                    multiline={true}
                    rows={5}
                    error={error}
                  />
                </>
                : reqData ?
                <>
                  <Typography
                    variant={'h5'}
                    sx={{
                      mt:'1rem',
                      mb: '2rem'
                    }}
                  >
                    {reqData?.subject}
                  </Typography>
                  <Typography
                    sx={{
                      wordBreak: 'break-word',
                      mb: '2rem',
                    }}
                  >
                    {reqData?.text}
                  </Typography>
                </>
                :
                <>
                <Skeleton
                  height={50}
                  sx={{
                    mt:'1rem',
                    mb: '2rem',
                  }}
                />
                <Skeleton
                  height={300}
                  sx={{
                    mt: '-6rem',
                    mb: '-3rem'
                  }}
                />
                </>
            }



            <SimpleDialog
              title={'Excluir Aviso'}
              question={'Realmente deseja excluir esse aviso?'}
              open={openDelete}
              setOpen={setOpenDelete}
              functionProp={deleteBill}
            />
          </>
        }
      />
    </>
  )
}

export default BillboardForm