import {Box, Grid, Skeleton, Typography} from "@mui/material";
import {PhoneOutlined} from "@mui/icons-material";
import React from "react";

const InvoiceDetailAddressee = ({ invoice }) => {

  return (
    <Box>
      <Typography mb={1}>Para:</Typography>
      {invoice ? <>
        <Typography fontWeight={'bold'} mb={1} variant="body1">{invoice?.unit}</Typography>
        <Typography variant="body2">{invoice?.unit_detail?.street} {invoice?.unit_detail?.number}, {invoice?.unit_detail.district}</Typography>
        <Typography variant="body2">{invoice?.unit_detail?.cep}, {invoice?.unit_detail?.city} - {invoice?.unit_detail?.state}</Typography>
        <Typography variant="body2" mt={2} alignItems={'center'}>
          <PhoneOutlined fontSize={'.2rem'}/> {invoice?.unit_detail?.phone}
        </Typography>
      </> : <>
        <Skeleton variant="text" animation="pulse" width={200} height={20}/>
        <Skeleton variant="text" animation="pulse" width={200} height={20}/>
        <Skeleton variant="text" animation="pulse" width={200} height={20}/>
        <Skeleton variant="text" animation="pulse" width={200} height={20}/>
      </>
      }
    </Box>
  )
}

export default InvoiceDetailAddressee