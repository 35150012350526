import React, {
  useEffect,
  useState
} from "react";
import SimpleTypography from "../../../../../components/Shared/SimpleTypography";
import {getCondoId} from "../../../../../services/Main/AuthStorage";
import {getAccountData} from "./Requisitions";
import Masks from "../../../../../components/Shared/Masks";
import {Close} from "@mui/icons-material";

const AccountReadInfo = ({ balanceStyles }) => {

  const [accountData, setAccountData] = useState(null)

  useEffect(function accountBalance () {
    if (getCondoId()){
      getAccountData(setAccountData)
    }
  }, [])


  return (
    <>
      <SimpleTypography
        label={'Banco'}
        directValue={accountData?.bank ?? <Close sx={{color: 'error.main'}}/>}
        labelStyles={{ fontWeight: 'bold'}}
        isLoading={!accountData?.balance}
        block={false}
        boxStyles={{ mb: '1rem'}}
      />

      <SimpleTypography
        label={'Agência'}
        directValue={accountData?.bank_branch ?? <Close sx={{color: 'error.main'}}/>}
        isLoading={!accountData?.balance}
        boxStyles={{mb: '1rem'}}
        labelStyles={{ fontWeight: 'bold'}}
      />

      <SimpleTypography
        label={'Conta'}
        directValue={accountData?.bank_account ?? <Close sx={{color: 'error.main'}}/>}
        isLoading={!accountData?.balance}
        boxStyles={{mb: '1rem'}}
        labelStyles={{ fontWeight: 'bold'}}

      />

      <SimpleTypography
        label={'Saldo'}
        directValue={Masks?.money(accountData?.balance?.toString() ?? '') ?? <Close sx={{color: 'error.main'}}/>}
        isLoading={!accountData?.balance}
        boxStyles={balanceStyles}
        labelStyles={{ fontWeight: 'bold'}}

      />
    </>
  )
}

export default AccountReadInfo