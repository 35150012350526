import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment
} from "@mui/material";
import {PaymentsOutlined} from "@mui/icons-material";
import PaymentDate from "./dependencies/PaymentDate";
import SimpleText from "../../../../../../components/Shared/SimpleText";
import PaymentInstallment from "./dependencies/PaymentInstallment";

const ExpensePayment = ({
    reqData,
    setReqData,
    errors,
    gasWaterRequired,
    installments,
    setInstallments
  }) => {

  return (
    <Grid item xs={12}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <Avatar
                  sx={{
                    bgcolor: 'primary.main'
                  }}
                >
                  <PaymentsOutlined/>
                </Avatar>
              }
              title={'Pagamento'}
            />

            <Divider
              sx={{
                mb: '.3rem'
              }}
            />

            <CardContent>
              <PaymentDate
                state={reqData}
                setState={setReqData}
                errors={errors}
              />

              { !gasWaterRequired  &&
                <SimpleText
                  label={'Total'}
                  money
                  required={!gasWaterRequired}
                  state={reqData}
                  setState={setReqData}
                  keyName={'total'}
                  error={errors}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{reqData?.category === 'DISCOUNT' && '-'}</InputAdornment>
                  }}
                />
              }

              <PaymentInstallment
                state={reqData}
                installments={installments}
                setInstallments={setInstallments}
              />

            </CardContent>
          </Card>
        </Grid>
    </Grid>
  )
}

export default ExpensePayment