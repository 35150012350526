import {
  Collapse,
  Divider,
  TextField
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

const PaymentInstallment = ({
    state,
    setInstallments,
    installments
  }) => {

  const installmentOptions = {
    '1': '1x',
    '2': '2x',
    '3': '3x',
    '4': '4x',
    '5': '5x',
    '6': '6x',
    '7': '7x',
    '8': '8x',
    '9': '9x',
    '10': '10x',
    '11': '11x',
    '12': '12x',
  }

  return (
    <Collapse in={state?.category !== 'DISCOUNT'}>
      <Divider sx={{ my: '1rem' }}>{'Parcelamento'}</Divider>
        <TextField
          label={'Parcelas'}
          select
          fullWidth
          size={'small'}
          onChange={(e) => setInstallments(e.target.value)}
          value={state?.installment ?? installments}
        >
          {Object.keys(installmentOptions).map((item, i) => (
            <MenuItem key={item} value={item} sx={{ fontWeight: 'bold' }}>
              {installmentOptions[item]}
            </MenuItem>))}
        </TextField>
    </Collapse>
  )
}

export default PaymentInstallment;