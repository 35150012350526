import React from "react";
import {
  Box,
  Card,
  Skeleton,
  Typography
} from "@mui/material";
import Lottie from "lottie-react";
import noComments from "../../../../../assets/animations/noComments.json";
import {grey} from "@mui/material/colors";
import {Description} from "@mui/icons-material";

const BookingReadDescription = ({
    isLoading,
    dataUpdate
  }) => {

  return (
    <>
      <Box>
        {!isLoading ?
          <>
            <Typography
              sx={{marginBottom: '0.5rem', alignItems: 'center', display: 'flex'}}
            >
              <Description sx={{mr: '0.5rem'}}/>
              <b>Observações:</b>
            </Typography>
            <Card
              sx={{
                backgroundColor: 'rgba(208,208,208,0.22)',
                mb: '0.5rem'
              }}
            >
              {dataUpdate?.notes ?
                <Typography>
                  {dataUpdate?.notes}
                </Typography>
                :
                <Box
                  sx={{alignItems: 'center', display: 'flex', justifyContent: 'center', margin: '1rem', flexDirection: 'column'}}
                >
                  <Box width="100px">
                    <Lottie animationData={noComments} loop={true}/>
                  </Box>
                  <Typography
                    align={'center'}
                    sx={{
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      fontSize: '24px',
                      fontWeight: 'bold',
                      color: grey[400]
                    }}

                  >
                    Nenhuma Obervação!
                  </Typography>
                </Box>}
            </Card>
          </>
          :
          <Skeleton
            variant="rounded"
            animation="pulse"
            height={175.3}
            width={348.02}
            sx={{marginBottom: '0.5rem'}}

          />
        }
      </Box>
    </>
  )
}

export default BookingReadDescription