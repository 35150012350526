import {Skeleton, Typography} from "@mui/material";
import {Email} from "@mui/icons-material";
import React from "react";

const MaintenanceReadMailCheck = ({isLoading, dataUpdate}) => {
  return (
    <>
      {!isLoading ?
        <Typography
          sx={{marginBottom: '0.5rem', alignItems: 'center', display: 'flex'}}
        >
          <Email sx={{mr: '0.5rem'}}/>
          <b>Enviado via e-mail para os condôminos? </b>&nbsp;
          {dataUpdate?.select_email ? 'Sim' : ' Não'}
        </Typography>
        :
        <Skeleton
          variant="rounded"
          animation="pulse"
          height={24}
          sx={{marginBottom: '0.5rem'}}
        />
      }
    </>
  )
}

export default MaintenanceReadMailCheck