import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery
} from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import React from "react";
import SimpleDialogTitle from "./SimpleDialogTitle";
import {Delete} from "@mui/icons-material";

const SimpleDialog =
  ({
     open,
     setOpen,
     title,
     question,
     functionProp,
     justOne,
     onClickConfirm,
     onClickCancel,
     logicInverter,
     dialogContent,
     confirmText,
     cancelText,
     buttonConfirmMorph,
     onClickConfirmMorph,
     buttonConfirmMorphText,
     titleIcon,
     extraButton,
     extraButtonText,
     onClickExtraButton,
     disabled,
     removeConfirm,
     subtitle,
     styles,
     actionMenu,
    disabledCancel
   }) => {
  const sizeMatch = useMediaQuery('@media (min-width:600px)')

  return (
    <Dialog
      fullScreen={!sizeMatch}
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '600px', ...styles } }}
      open={open ?? false}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        logicInverter ? setOpen(true) : setOpen(false)

      }}
    >
      {title &&
        <SimpleDialogTitle
          title={title}
          subtitle={subtitle}
          avatarIcon={titleIcon}
          actionMenu={actionMenu}
        />
      }
      {/*<DialogTitle>*/}
      {/*  {title}*/}
      {/*</DialogTitle>*/}
      <DialogContent>
        {dialogContent}

        <DialogContentText
        >
          {question}
        </DialogContentText>
      </DialogContent>
      {!justOne ?
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '1rem'
        }}
      >
        {!disabledCancel ?
          <>
          {!onClickCancel  ?
            <Button
              sx={{ p : 0 }}
              onClick={() => logicInverter ? setOpen(true) : setOpen(false)}
            >
              {cancelText ?? 'Cancelar'}
            </Button>
            :
            <Button
              size={'small'}
              sx={{ p : 0 }}
              onClick={onClickCancel}
            >
              {cancelText ?? 'Cancelar'}
            </Button>
          }</> :
          <Box></Box>}
        <Box>
          {extraButton &&
            <Button
              size={'small'}
              variant={'contained'}
              onClick={onClickExtraButton}
              autoFocus
            >
              {extraButtonText ?? <Delete />}
            </Button>
          }

          {!onClickConfirm && !removeConfirm &&
            <Button
              size={'small'}
              variant={'contained'}
              disabled={disabled}
              onClick={() => {
                functionProp()
                setOpen(false)
              }}
              autoFocus
            >
              {confirmText ?? 'Confirmar'}
            </Button>
          }
          {!buttonConfirmMorph && onClickConfirm &&
            <Button
              size={'small'}
              variant={'contained'}
              disabled={disabled}
              onClick={onClickConfirm}
              autoFocus
            >
              {confirmText ?? 'Confirmar'}
            </Button>
           }
          { buttonConfirmMorph &&
            <Button
              size={'small'}
              variant={'contained'}
              disabled={disabled}
              onClick={onClickConfirmMorph}
              autoFocus
            >
              {buttonConfirmMorphText ?? 'Confirmar'}
            </Button>
          }
        </Box>
      </DialogActions>
        :
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            margin: '1rem'
          }}
        >
          <Button
            size={'small'}
            variant={'contained'}
            onClick={() => setOpen(false)}
          >
            Ok
          </Button>
        </DialogActions>
      }
    </Dialog>
  )
}


export default SimpleDialog