import {IconButton, TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";

const TableTranferHead = ({ filters, setFilters }) => {

  const handleFilter = (asc: string, desc: string) => {
    setFilters(prevState => ({
        ...prevState,
        ordering: filters?.ordering === asc ? desc : asc
      })
    )
  }



  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{
              color: '#DD1960',
            }}
            active={filters?.ordering === '-rent_recipient_name' || filters?.ordering === 'rent_recipient_name'}
            direction={filters?.ordering === '-rent_recipient_name' ? 'desc' : 'asc'}
            onClick={() => handleFilter('rent_recipient_name', '-rent_recipient_name') }
          >
            Favorecido
          </TableSortLabel>
        </TableCell>


        <TableCell>
          <TableSortLabel
            style={{
              color: '#DD1960',
            }}
            active={filters?.ordering === '-created_at' || filters?.ordering === 'created_at'}
            direction={filters?.ordering === '-created_at' ? 'desc' : 'asc'}
            onClick={() => handleFilter('created_at', '-created_at')}
          >

            Criado
          </TableSortLabel>
        </TableCell>
        <TableCell
          // align={'left'}
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{
              color: '#DD1960',
            }}
            active={filters?.ordering === '-value' || filters?.ordering === 'value'}
            direction={filters?.ordering === '-value' ? 'desc' : 'asc'}
            onClick={() => handleFilter('value', '-value')}
          >
            Valor
          </TableSortLabel>
        </TableCell>
        <TableCell
          // align={'left'}
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{
              color: '#DD1960',
            }}
            active={filters?.ordering === '-user_detail.first_name' || filters?.ordering === 'user_detail.first_name'}
            direction={filters?.ordering === '-user_detail.first_name' ? 'desc' : 'asc'}
            onClick={() => handleFilter('user_detail.first_name', '-user_detail.first_name')}
          >
            Realizou
          </TableSortLabel>
        </TableCell>
        <TableCell
          align={'left'}
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{
              color: '#DD1960',
            }}
            active={filters?.ordering === '-status' || filters?.ordering === 'status'}
            direction={filters?.ordering === '-status' ? 'desc' : 'asc'}
            onClick={() => handleFilter('status', '-status')}
          >
            Situação
          </TableSortLabel>
        </TableCell>
        <TableCell
          align={'center'}
          sx={{
            color: 'primary.main'
          }}
        >
          Arquivos
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default TableTranferHead