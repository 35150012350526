import {
  Box,
  Card,
  Pagination,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React, {useContext, useEffect, useState} from 'react'
import { api } from '../../../services/Main/Api'
import { useSnackbar } from 'notistack'
import {AddCircleOutlined} from '@mui/icons-material'
import { getCondoId } from '../../../services/Main/AuthStorage'
import { filter } from 'lodash'
import Lottie from "lottie-react";
import Context from "../../../contexts/Context";
import loading from "../../../assets/animations/loading.json";
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import SearchField from "../../../components/Shared/SearchField";
import {Helmet} from "react-helmet";
import TableCategoryBody from "./components/Category/TableCategoryBody";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import CategoryModal from "./components/Category/CategoryModal";

const Category = () => {
  const { filters, setFilters } = useContext(Context)
  useEffect(function categoryFilters(){
    setFilters({
      condo_id: getCondoId(),
      page_size: 50
    })
  },[])

  const [response, setResponse] = useState([])
  const [filterName, setFilterName] = useState('')
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('name')
  const [name, setName] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errors, setErrors] = useState()
  const [open, setOpen] = useState(false)


  const { enqueueSnackbar } = useSnackbar()


  const handlePage = (e, value) => {
    setFilters({ ...filters, page: value })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    const data = {
      condo: getCondoId(),
      description: name,
    }

    api.post('category/', data)
        .then(() => {
          getCategories()
          setIsSubmitting(false)
          setOpen(false)
          enqueueSnackbar('Categoria criada com sucesso!', { variant: 'success' })
        })
          .catch(reason => {
          setErrors(reason?.response.data)
          setIsSubmitting(false)
          console.log(reason)
        })
    }

  const getCategories = () => {

    api.get(`category/`, {params: filters})
      .then(response => {
        console.log(response.data.results)
        setResponse(response?.data)
      }).catch(reason => {
        console.log(reason)
    })
  }

  const handleDelete = (id) => {
    api.delete(`category/${id}/`)
      .then(response => {
      getCategories()
      enqueueSnackbar('Categoria apagada com sucesso!', { variant: 'success' })
    }).catch(reason => console.log(reason))
  }

  useEffect(() => {
    if (filters) {
    getCategories()
    }
  }, [filters])

  function descendingComparator (a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function getComparator (order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function applySortFilter (array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index])
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    if (query) {
      return filter(array, (__object) => __object.description.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    }
    return stabilizedThis?.map((el) => el[0])
  }
  const filteredCategories = applySortFilter(response?.results, getComparator(order, orderBy), filterName)

  return (
    <>
      <Helmet>
        <title>Categorias de Despesas - Bloco B3</title>
      </Helmet>
      <Box>
        <Card>
          <TitleBtnList
            title={'Categorias de Despesas'}
            buttonText={'Criar Categoria'}
            icon={<AddCircleOutlined/>}
            onClick={() => setOpen(true)}
            boxStyles={{
              p: '1rem'
            }}
          />
          <SearchField
            label="Procurar por categoria"
            state={filters}
            setState={setFilters}
            keyName={'search'}
            boxStyles={{
              maxWidth: '400px',
              m: '1rem'
            }}
          />
          <TableContainer>
            {response?.results?.length ?
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{'Nome'}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableCategoryBody
                filteredCategories={filteredCategories}
                handleDelete={handleDelete}
              />
            </Table>
              : response?.results?.length < 1  ?
                <NoResultsAnimation />
              :
              <Box
                sx={{alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', mt: '3rem'}}
              >
                <Box
                  sx={{width:"100px"}}
                >
                  <Lottie  animationData={loading} loop={true} color={'#fff'}/>
                </Box>
              </Box>
              }
          </TableContainer>
        </Card>
      </Box>
      <CategoryModal
        open={open}
        handleSubmit={handleSubmit}
        errors={errors}
        setOpen={setOpen}
        isSubmitting={isSubmitting}
        setName={setName}
      />
      {response?.count > 0 &&
        <Box spacing={2} display={'flex'} justifyContent={'center'} sx={{ m: 3 }}>
          <Pagination
            count={Math.ceil(response?.count / filters.page_size)}
            variant={'outlined'}
            color={'primary'}
            onChange={handlePage}
          />
        </Box>}
    </>
  )
}
export default Category
