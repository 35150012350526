import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, FormControl, FormControlLabel, FormLabel,
  Grid, InputLabel, Menu, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Tooltip,
  Typography
} from '@mui/material'
import { PlaylistAddCheckCircleOutlined, PlaylistRemoveOutlined, ReceiptLong } from '@mui/icons-material'
import Masks from '../../../components/Shared/Masks'
import React, {useContext, useEffect, useState} from 'react'
import moment from 'moment'
import { api } from '../../../services/Main/Api'
import CircularProgress from '@mui/material/CircularProgress'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import AllInclusiveOutlinedIcon from '@mui/icons-material/AllInclusiveOutlined'
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  TabContext,
  TabList,
  TabPanel, Timeline, TimelineConnector, TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent, timelineOppositeContentClasses,
  TimelineSeparator
} from '@mui/lab'
import { alpha, styled } from '@mui/material/styles'
import Context from "../../../contexts/Context";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {useParams} from "react-router-dom";

function RentContractFeeDetail(props) {
  const { id } = useParams()
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [message, setMessage] = useState(null);
  const [alternativeState, setAlternativeState] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusChild, setStatusChild] = useState([]);
  const open = Boolean(anchorEl);
  const {screenRefresh, refreshScreen, setScreenRefresh, setRefreshScreen} = useContext(Context)
  const [invoiceRule, setInvoiceRule] = useState(null);
  const [transferRule, setTransferRule] = useState(null);
  const [includeIncomeTax, setIncludeIncomeTax] = useState(null);
  const [invoiceRuleChoices, setInvoiceRuleChoices] = useState([]);
  const [transferRuleChoices, setTransferRuleChoices] = useState([]);
  const [applyProportionalCalculation, setApplyProportionalCalculation] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenDialog(false);
    props.loadRentContractFee()
    props.handleDialogClose()
    setRefreshScreen(screenRefresh)

  };


  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/rent-contract/fee/${props.idFee}/`);
      setData(response.data);
      if (response.data.recurring_fees){
        setStatusChild([response.data.recurring_fees.map(item => item)])
      }else if (response.data.installment_fees){
        setStatusChild([response.data.installment_fees.map(item => item)])
      }

      setAlternativeState(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.idFee]);


  function handleSaveFee () {
    const data = {
      invoice_rule:invoiceRule,
      transfer_rule: transferRule,
      include_income_tax: includeIncomeTax,
      apply_proportional_calculation: applyProportionalCalculation,
    }

    api.put(`/rent-contract/fee/${props.idFee}/`, data).then(response => {
      setLoading(false)
      setRefreshScreen(screenRefresh)
      handleClose();
      fetchData()
    }).catch(error => {
      setLoading(false)
      console.log(error)
      handleClose();
    })
  }

  function handleDelete(itemId){
    setLoading(true)
    api.delete(`rent-contract/fee/${itemId.id}/`).then(response => {
      props.loadRentContractFee()
      props.handleDialogClose()
      setLoading(false)
      setRefreshScreen(screenRefresh)

    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }
  function handleActiveOrDeactive(item){
    let status = 'ACTIVE'
    if (item?.status === 'ACTIVE'){
      status = 'DISABLED'
    }

    const dataOption = {
      status: status
    }
    setLoading(true)

    api.put(`rent-contract/fee/${item.id}/`, dataOption).then(response => {

        console.log(response)
        setRefreshScreen(screenRefresh)
        fetchData()
        setLoading(false)
        props.handleDialogClose()

    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  function handlePayment(item){

    const dataOption = {
      payment: !item.payment
    }
    setLoading(true)

    api.put(`rent-contract/fee/${item.id}/`, dataOption).then(response => {
      fetchData()
      setLoading(false)

    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  function clearMessage() {
    setMessage(null);
    props.handleDialogClose()
  }
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

  useEffect(() => {
    let params = {
      rent_contract:props.rent_contract,

    }
    api.get("/rent-contract/choices/", {params}).then(response => {

      setInvoiceRuleChoices(response.data.invoice_choices)
      setTransferRuleChoices(response.data.transfer_choices)

    }).catch(error => {
      console.log(error)
    })
  }, [])

  useEffect(() => {
    if (data) {
      setInvoiceRule(data?.invoice_rule?.key);
      setTransferRule(data?.transfer_rule?.key);
      setIncludeIncomeTax(data?.include_income_tax);
      setApplyProportionalCalculation(data?.apply_proportional_calculation);
    }
  }, [data]);


    return (
      <>
      {!loading && data ? <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={props.openDialog}
        onClose={props.handleDialogClose}
      >
        <DialogTitle>
          <Box sx={{display:"flex", alignItems: "center", justifyContent:"space-between"}}>
            <Box sx={{display:"flex", alignItems: "center"}}>
              <Avatar sx={{bgcolor: 'primary.main'}}><ReceiptLong/></Avatar>
              <Typography variant={"h5"} sx={{ml:1}}>COB-{props.idFee} - {data.category}</Typography>
            </Box>
            { data.status === 'DISABLED' ?
                <Box sx={{display:"flex", alignItems: "center", justifyContent:"space-between"}}>
                  <Box sx={{marginRight: '1rem'}}>
                  { data.recurring === true ? <Chip icon={<AllInclusiveOutlinedIcon />} variant={"outlined"} color={"info"} label={"Cobrança Recorrente"} />
                      : data.installment === true ? <Chip icon={<CreditScoreOutlinedIcon />} variant={"outlined"} color={"info"} label={"Cobrança Parcelada"} />
                          : <Chip icon={<CalendarMonthOutlinedIcon />} variant={"outlined"} color={"info"} label={"Cobrança Mensal"} /> }
                    </Box>
                  <Box>
                  { data.recurring === true ? <Chip variant={"outlined"} color={"error"} label={"Desativada"} />
                      : data.installment === true ? <Chip variant={"outlined"} color={"error"} label={"Desativada"} />
                          : '' }
                </Box>
                </Box>
                :
            <Box>
              { data.recurring === true ? <Chip icon={<AllInclusiveOutlinedIcon />} variant={"outlined"} color={"info"} label={"Cobrança Recorrente"} />
               : data.installment === true ? <Chip icon={<CreditScoreOutlinedIcon />} variant={"outlined"} color={"info"} label={"Cobrança Parcelada"} />
              : <Chip icon={<CalendarMonthOutlinedIcon />} variant={"outlined"} color={"info"} label={"Cobrança Mensal"} /> }
            </Box>
            }
          </Box>
          </DialogTitle>
        <Divider/>


          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>

              <Box sx={{ borderBottom: 1, borderColor: 'divider', display:  'flex', justifyContent: "space-between", alignItems: 'center' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Detalhes" value="1" />

                  <Tab label="Histórico" value="2" />

                </TabList>
                <Box>
                  { !data.parent_id &&
                    <>
                    <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    variant={'contained'}
                    size={'small'}
                    color={'action'}
                    sx={{marginRight: '1rem'}}
                  >
                    Mais ações
                  </Button>
                  <StyledMenu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={()=> {
                      handlePayment(data)
                      handleClose()
                    }}>
                      { data.payment ?
                          <> <PlaylistRemoveOutlined /> Remover dos encargos locatícios</>
                          :
                          <><PlaylistAddCheckCircleOutlined /> Adicionar aos encargos locatícios</>

                      }
                    </MenuItem>

                    <Divider sx={{my:0.5}}/>

                    <MenuItem onClick={handleClickOpen}>
                      <EditNoteOutlinedIcon /> Editar Cobrança
                    </MenuItem>
                    <Divider sx={{my:0.5}}/>

                    { data && data.status !== 'DISABLED' ?
                      <>
                      {  data?.can_be_delete ?
                        <MenuItem  onClick={() => {
                          setScreenRefresh(true)
                          handleDelete(data)
                          handleClose()
                        }}>
                          <DeleteIcon/> Excluir Cobrança
                        </MenuItem>
                        :
                        <MenuItem onClick={() => {
                          setScreenRefresh(true)
                          handleActiveOrDeactive(data)
                          handleClose()
                        }}>
                          <ReportOutlinedIcon /> Desativar Cobrança
                        </MenuItem> }</>
                    :
                      <MenuItem onClick={() => {
                        setScreenRefresh(true)
                        handleActiveOrDeactive(data)
                        handleClose()
                      }}>
                        <ReportOutlinedIcon /> Ativar Cobrança
                      </MenuItem>
                    }
                  </StyledMenu> </>}
                </Box>

              </Box>
              <TabPanel value="1">
                <DialogContent>
                <Box>
                  {message && (
                    <Typography sx={{ textAlign: 'center', color: 'primary.main' }}>
                      {message?.Message}
                    </Typography>
                  )}
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} md={4}>
                      <Typography sx={{fontWeight:"bold"}} variant="subtitle1">
                        { data.recurring === true ? "Inicio": "Data"} da Cobrança</Typography>

                      {moment(`${data?.year}-${data?.month}`, "YYYY-MM").format("MMMM [de] YYYY")}

                    </Grid>
                    {data?.recurring_type && <Grid item xs={12} md={4}>
                      <Typography sx={{fontWeight:"bold"}} variant="subtitle1">
                        Final da Cobrança</Typography>
                      { data.recurring_type !== "EVERYDAY" ?<Box>
                          {moment(`${data?.recurring_end_year}-${data?.recurring_end_month}`, "YYYY-MM").format("MMMM [de] YYYY")}
                        </Box> :
                        "Sem data de término" }
                    </Grid> }
                    <Grid item xs={12} md={4}>
                      <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Valor</Typography>
                      {data.value_type === "PERCENTAGE_VALUE" ?
                        `${data.value?.toString()}% do ${data.percentage_rule}`
                        :
                        `${Masks.money(data.value?.toString())}`

                      }
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Categoria</Typography>
                      {data.category}
                    </Grid>

                    {data.description && <Grid item xs={12} md={4}>
                      <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Descrição</Typography>
                      {data.description}
                    </Grid> }

                    <Grid item xs={12} md={4}>
                      <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Fatura</Typography>
                      {data.invoice_rule.value}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Repasse</Typography>
                      {data.transfer_rule.value}
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Calculo proporcional</Typography>
                      {data.apply_proportional_calculation === true ? "Sim" : "Não"}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Incluir no IRRF</Typography>
                      {data.include_income_tax === true ? "Sim" : "Não"}
                    </Grid>
                    { data.installment === true && <>
                      <Grid item xs={12} md={4}>
                        <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Número de parcelas </Typography>
                        {data.installment_total_number} vezes
                      </Grid>
                      {data.installment_start > 0 && <Grid item xs={12} md={4}>
                        <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Parcelamento Iniciou </Typography>
                        {data.installment_start}ª Parcela
                      </Grid> }
                    </>}
                    <Grid item xs={12} md={4}>
                      <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Encargos Locatícios</Typography>
                      {data.payment === true ? "Sim" : "Não"}
                    </Grid>
                  </Grid>

                </Box>
                { data.installment === true && <>
                  <Divider sx={{ margin: "2rem 0", color:"primary.main", fontWeight:"normal"}}>Cobrança Parcelada em {data.installment_total_number} vezes</Divider>

                  <TableContainer>

                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align={"left"}>Código</TableCell>
                          <TableCell>Parcela</TableCell>
                          <TableCell >Referencia</TableCell>
                          <TableCell>Valor</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        { data.installment_fees?.filter(item => item.status.key !== 'deleted').map(item => (
                          <TableRow>
                            <TableCell align={"left"}>{item.name}</TableCell>
                            <TableCell>{item.index}ª Parcela </TableCell>
                            <TableCell>{moment(item.reference_date, "MM-YYYY").format("MMM[/]YYYY")}</TableCell>
                            <TableCell>{Masks.money(item.value?.toString())}</TableCell>
                            <TableCell>
                              <Chip
                                color={item?.status.key === 'paid' ? 'success' :
                                  item?.status.key === 'pending' ? 'warning' :
                                    item?.status.key === 'FINALIZED' ? 'success' :
                                      item?.status.key === 'overdue' ? 'error' :
                                        item?.status.key === 'FAILED' ? 'error' :
                                          item?.status.key === 'open' && 'info'}
                                size="small"
                                variant="outlined"
                                label={item?.status.value}/>

                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </> }

                { data.recurring === true && <>
                  <Divider sx={{ margin: "2rem 0", color:"primary.main", fontWeight:"normal"}}>
                    Cobrança recorrente {data.recurring_type !== "EVERYDAY" && `até ${moment(`${data?.recurring_end_year}-${data?.recurring_end_month}`, "YYYY-MM").format("MMMM [de] YYYY")}`}
                  </Divider>

                  <TableContainer>

                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align={"left"}>Código</TableCell>
                          <TableCell>Mês/Ano</TableCell>
                          <TableCell>Valor</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        { data.recurring_fees?.filter(item => item.status.key !== 'deleted').map(item => (
                          <TableRow>
                            <TableCell align={"left"}>{item.name}</TableCell>

                            <TableCell>{moment(item.reference_date, "MM-YYYY").format("MMM[/]YYYY")}</TableCell>
                            <TableCell>{Masks.money(item.value?.toString())}</TableCell>
                            <TableCell>
                              <Tooltip title={item.invoice_number ? `${item.invoice_number}` : 'A fatura ainda não foi criada'}>
                                <Chip
                                  color={item?.status.key === 'paid' ? 'success' :
                                    item?.status.key === 'pending' ? 'warning' :
                                    item?.status.key === 'exemption' ? 'secondary' :
                                      item?.status.key === 'FINALIZED' ? 'success' :
                                        item?.status.key === 'overdue' ? 'error' :
                                          item?.status.key === 'FAILED' ? 'error' :
                                          item?.status.key === 'processing' ? 'secondary' :
                                            item?.status.key === 'open' && 'info'}
                                  size="small"
                                  variant="outlined"
                                  label={item?.status.value}
                                />
                              </Tooltip>
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </> }
                </DialogContent>
              </TabPanel>
              <TabPanel value="2">
                <Timeline
                  sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                      flex: 0.2,
                    },
                  }}
                >
                  {data?.history.map(history =>
                  <TimelineItem sx={{width: '100%'}}>
                    <TimelineOppositeContent color="textSecondary">
                      <span>
                      {moment(history.history_date).format("DD/MM/YYYY [às] HH:mm")}
                        </span>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      {data?.history.length > 1 &&
                      <TimelineConnector /> }
                    </TimelineSeparator>
                    {history.history_type === '+' ?
                      <TimelineContent >
                     {history.history_user ? `Cobrança criado por ${history.history_user}` : 'Cobrança criada automaticamente'}
                    </TimelineContent>
                      : history.history_type === '~' &&
                      <TimelineContent >
                        {history.history_user ? `Cobrança alterada por ${history.history_user}` : 'Cobrança criada automaticamente'}
                      </TimelineContent>
                    }
                  </TimelineItem>  )}

                </Timeline>


              </TabPanel>

            </TabContext>
          </Box>


        <Divider />
        <DialogActions sx={{display:"flex", justifyContent:"space-between", padding: "2rem 1rem"}}>



          <Button variant="text" color={"secondary"} onClick={()=>clearMessage() }>Fechar</Button>

        </DialogActions>
      </Dialog> :
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={openDialog}
            scroll={'body'}
            onClose={handleClose}
        >
          <DialogTitle >Editar Cobrança</DialogTitle>
          <DialogContent >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={2} mt={.5}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth >
                  <InputLabel id="invoice-select-label">Fatura</InputLabel>
                  <Select
                      size={'small'}
                      labelId="invoice-select"
                      id="invoice-select"
                      value={invoiceRule}
                      label="fatura"
                      onChange={(event)=> {
                        setAnchorEl(null)
                        setInvoiceRule(event.target.value)
                      }}
                  >
                    {invoiceRuleChoices.map((item, index) =>
                        <MenuItem
                            key={index}
                            value={item.id}
                        >
                          {item.value}
                        </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth >
                  <InputLabel id="transfer-select-label">Repasse</InputLabel>
                  <Select
                      size={'small'}
                      labelId="transfer-select"
                      id="transfer-select"
                      value={transferRule}
                      label="Repasses"

                      onChange={(event)=> {
                        setAnchorEl(null)
                        setTransferRule(event.target.value)
                      }}
                  >
                    {transferRuleChoices.map((item, index) =>
                        <MenuItem
                            key={index}
                            value={item.id}
                        >
                          {item.value}
                        </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth >
                  <InputLabel id="transfer-select-label">Cobrança Proporcional</InputLabel>
                  <Select
                      size={'small'}
                      labelId="apply-proportional-calculation"
                      id="apply-proportional-calculation"
                      value={applyProportionalCalculation}
                      label="Cobrança Proporcional"

                      onChange={(event)=> {
                        setAnchorEl(null)
                        setApplyProportionalCalculation(event.target.value)
                      }}
                  >
                    <MenuItem value={true}>Sim</MenuItem>
                    <MenuItem value={false}>Não</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth >
                  <InputLabel id="transfer-select-label">Incluir no IRRF</InputLabel>
                  <Select
                      size={'small'}
                      labelId="include-income-tax"
                      id="include-income-tax"
                      value={includeIncomeTax}
                      label="Incluir no IRRF"

                      onChange={(event)=> {
                        setAnchorEl(null)
                        setIncludeIncomeTax(event.target.value)
                      }}
                  >
                    <MenuItem value={true}>Sim</MenuItem>
                    <MenuItem value={false}>Não</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions sx={{display:"flex", justifyContent:"space-between", padding: "2rem 1rem"}}>
            <Button variant="text" color={"secondary"} onClick={()=> handleClose() }>Cancelar</Button>
            <Button variant="contained" color={"primary"} onClick={() => {
              handleSaveFee();
              setLoading(true);
              setScreenRefresh(true);
            }}>{"Salvar" }</Button>
          </DialogActions>
        </Dialog>
    </>
    )
}



export default RentContractFeeDetail