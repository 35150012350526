import {Engineering, SearchOff} from "@mui/icons-material";
import {Autocomplete, InputAdornment, TextField} from "@mui/material";
import React from "react";

const AutoCompleteGlobal = ({
    setState,
    options,
    error,
    disabled,
    label,
    icon,
    styles,
    keyName,
    optioMapName
  }) => {

  return (
    <Autocomplete
      clearIcon={<SearchOff color="primary"/>}
      fullWidth
      size={"small"}
      disabled={disabled}
      selectOnFocus
      clearOnBlur
      disableClearable
      onChange={(_event, value) => value && setState((prevState) => ({
        ...prevState,
        [keyName]: value?.value
      }))}
      sx={{
        mb: '1rem',
        ...styles
        }}
      getOptionLabel={(option) => option.name ?? ''}
      options={options?.map((option) => ({ name: option?.[optioMapName], value: option?.id })) ?? ['']}
      isOptionEqualToValue={(option, value) => option.id === value?.value}
      renderInput={(params) => <TextField
        {...params}
        label={label}
        required
        error={!!error?.[keyName]}
        helperText={error?.[keyName]}
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <>
              <InputAdornment
                sx={{
                  mr: '0.2rem'
                  }}
                position="start">
                {icon}
              </InputAdornment>
              {params.InputProps.startAdornment}
            </>
          )
        }}
      />}
    />
  )

}

export default AutoCompleteGlobal