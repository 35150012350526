import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  Grid,
  List,
  ListItem,
  Typography
} from "@mui/material";
import {Check, Remove} from "@mui/icons-material";
import AnimationGlobal from "../../../components/Shared/animations/AnimationGlobal";
import successAnimation from "../../../assets/animations/success.json";
import React, {useContext} from "react";
import Context from "../../../contexts/Context";

const ConclusionUnit = () => {
  const { dataProvider } = useContext(Context)
  const { UnitSecondStep } = dataProvider || {}

  function avatarType(param) {
    const color = param  ? 'success' : 'warning';
    const icon = param ? <Check sx={{color: 'white', fontSize: '2rem'}}/> :
      <Remove sx={{color: 'white', fontSize: '2rem'}}/>;

    return (
      <Avatar sx={{ backgroundColor: `${color}.main`, mr: '0.5rem' }}>
        {icon}
      </Avatar>
    )
  }

  const ownerRegister = UnitSecondStep ? 'Foi registrado ao menos um proprietário' :
    'Não foi registrado nenhum proprietário'


  return (
    <Grid item xs={12}>
      <Card>
        <Fade
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={300}
        >
          <Box>
            <CardHeader
              avatar={<Avatar sx={{ bgcolor: 'primary.main' }}><Check/></Avatar>}
              title={'Conclusão'}/>
            <Divider sx={{ mb: '.3rem' }}/>
            <CardContent
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: '1rem', flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <AnimationGlobal
                size={'200px'}
                animation={successAnimation}
                fontStyles={{mb: '1rem'}}
                fontSize={'20px'}
              />
              <List
                sx={{
                  paddingBottom: '5rem'
                }}
              >
                <ListItem sx={{display: 'flex'}}>
                  <Avatar sx={{backgroundColor: 'success.main', mr: '0.5rem'}}>
                    <Check sx={{color: 'white', fontSize: '2rem'}} />
                  </Avatar>
                  <Typography variant="body1" sx={{fontSize: '1.5rem'}}>
                    Imóvel cadastrado
                  </Typography>
                </ListItem>
                <ListItem sx={{display: 'flex'}}>
                  {avatarType(UnitSecondStep)}
                  <Typography variant="body1" sx={{fontSize: '1.5rem'}}>
                    {ownerRegister}
                  </Typography>
                </ListItem>
              </List>
            </CardContent>
          </Box>
        </Fade>
      </Card>
    </Grid>
  )
}

export default ConclusionUnit