import Box from '@mui/material/Box'
import { Outlet } from 'react-router-dom'
import * as React from 'react'
import { Container, Grid, Stack, Typography } from '@mui/material'
import SettingsSidebar from './SettingsSidebar'

const Settings = () => {
  return (
    <Container maxWidth={false}>
      <Stack mb={4}>
        <Typography variant={'h5'}>{"Configurações"}</Typography>
      </Stack>
      <Grid container spacing={{xs: 2}} direction={'row-reverse'}>
        <Grid item xs={12} md={4} xl={3}>
          <SettingsSidebar/>
        </Grid>
        <Grid item xs={12} md={8} xl={9}>
          <Outlet/>
        </Grid>
      </Grid>
    </Container>
  )
}
export default Settings
