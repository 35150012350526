import {IconButton} from "@mui/material";
import {MoreVert} from "@mui/icons-material";
import React from "react";

const CommentActionHeader = ({ setOpenMenu, setCommentId, comment, handleClick, edit  }) => {
  return (
    <>
      {!edit &&
        <IconButton
          // ref={ref}
          onClick={(e) => {
            e.stopPropagation()
            setOpenMenu(true)
            setCommentId(comment.id)
            handleClick(e)
          }}
        >
          <MoreVert/>
        </IconButton>
      }
    </>
  )
}

export default CommentActionHeader