import SimpleButtons from "../../../../../components/Shared/SimpleButtons";
import {Grid} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import Context from "../../../../../contexts/Context";
import {api} from "../../../../../services/Main/Api";
import AuthValidation from "../../../../../components/Shared/AuthValidation";
import SimpleDialog from "../../../../../components/Shared/SimpleDialog";
import {useSnackbar} from "notistack";

const BankTransferButtons = (props) => {
  const { filters, setFilters, sessionCheck, dataProvider, setDataProvider } = useContext(Context)
  const { enqueueSnackbar } = useSnackbar()

  const{
    check,
    setCheck,
    buttonBehavior,
    buttonBehaviorPix,
    submitReq,
    submitReqPix,
    codeSent,
    reqData,
    submitTransfer,
    submitTransferPix,
    setSelectedAccount,
    setCodeSent,
    duplicateSubmit,
    isNotDuplicate,
    setIsNotDuplicate

  } = props

  function cancelOperation() {
    setIsNotDuplicate(null)
    setFilters(prevState => ({...prevState, transferType: ''}))
    setSelectedAccount(null)
    setCheck(false)
    setDataProvider(prevState => ({...prevState, transferReq: false}))
    enqueueSnackbar('Operação cancelada', {
      variant: 'info',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    });
  }

  useEffect(function ifValidationPass(){
    if (dataProvider?.transferReq === true && !check) {
      duplicateSubmit()
    }
    // if (dataProvider?.transferReq === true && check && filters?.transferType === 'pix') {
    //   submitReqPix()
    // }
    // if (dataProvider?.transferReq === true && check && filters?.transferType === 'ted') {
    //   submitReq()
    // }
  },[dataProvider?.transferReq])


  return(
    <>
      {!filters?.bankTransfer && dataProvider?.validation &&
        <>
          {dataProvider?.validation && !dataProvider?.transferReq ?
            <>
              {filters?.transferType === 'ted' ?
                <SimpleButtons
                  forwardButton={'Transferir'}
                  type={'button'}
                  onForward={ () => {
                    filters?.transferType === 'ted' && check && submitReq()
                    filters?.transferType === 'pix' && check && submitReqPix()
                    setDataProvider(prevState => ({...prevState, transferReq: true}))
                  } }
                  forwardDisable={check ? !buttonBehavior?.isEnabled : !buttonBehavior?.isEnabledJustTransfer}
                />
                : filters?.transferType === 'pix' &&
                <SimpleButtons
                  forwardButton={'Transferir'}
                  type={'button'}
                  forwardDisable={check ? !buttonBehaviorPix?.isEnabled : !buttonBehaviorPix?.isEnabledJustTransfer}
                  onForward={ () => {
                    filters?.transferType === 'ted' && check && submitReq()
                    filters?.transferType === 'pix' && check && submitReqPix()
                    setDataProvider(prevState => ({...prevState, transferReq: true}))
                  } }
                />
              }
            </>
            : dataProvider?.validation && dataProvider?.transferReq === true &&
            // <Grid
            //   item
            //   xs={12}
            // >
              <SimpleButtons
                forwardButton={'Confirmar'}
                // forwardDisable={!reqData?.validation_code}
                onForward={() => {
                  // setCodeSent(false)
                  filters?.transferType === 'ted' && submitTransfer()
                  filters?.transferType === 'pix' && submitTransferPix()

                }}
                // onBack={() => {
                //   cancelOperation()
                //   // setCodeSent(false)
                // }}
              />
            // </Grid>
          }
        </>
      }
      {/*<AuthValidation />*/}
      <SimpleDialog
        title={'Transferência duplicada'}
        logicInverter
        disableBackdropClick
        open={isNotDuplicate?.error === false && true}
        setOpen={setIsNotDuplicate}
        question={'Indentificada transferência com esse mesmo valor e conta, gostaria de prosseguir mesmo assim?'}
        onClickConfirm={() => {
          setIsNotDuplicate(null)
        }}
        onClickCancel={() =>{
          cancelOperation()
        }}
      />

    </>
  )
}

export default BankTransferButtons