import React, {useContext, useEffect, useState} from 'react'
import {
  Box,
  Card,
  Container,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { api } from '../../../services/Main/Api'
import { getCondoId } from '../../../services/Main/AuthStorage'
import moment from 'moment'
import Context from "../../../contexts/Context";

export const NotificationList = () => {
  const { filters, setFilters } = useContext(Context)
  useEffect(function NotificationFilters(){
    setFilters({
      page: 1,
      page_size: rowsPerPage,
      condo_id: getCondoId(),
    })
  },[])

  const [isLoading, setIsLoading] = useState(true)
  const [response, setResponse] = useState({
    result: [],
    count: 0
  })
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const handlePage = (e, value) => {
    setFilters({ ...filters, page: value })
  }


  useEffect(() => {
    if(filters)  {
      api.get(`notification/`, { params: filters })
        .then(response => {
          setResponse(response.data)
          setIsLoading(false)
      }).catch(error => {
          console.log(error)
      })
    }
  }, [filters])

  return (
    <Container maxWidth={false}>
      <Box sx={{
        marginY: '1em',
        display: 'flex',
        justifyContent: 'space-between',
        mb: 3
      }}>
        <Typography variant={'h5'}>{'Notificações'}</Typography>
      </Box>
      {isLoading ?
        <>
          <Skeleton variant="rounded" animation="pulse" height={500}/>
        </>
        :

        <Card>
          <TableContainer>
            {!response?.count ?
              <Typography variant="subtitle1" component="h2" sx={{
                paddingY: '1em',
                textAlign: 'center',
                color: 'gray'
              }}>
                Não foi encontrado nenhum resultado!
              </Typography>
              :
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: 'primary.main' }}>
                      Mensagem
                    </TableCell>
                    <TableCell sx={{ color: 'primary.main' }}>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {response?.results?.map((object, index) => (
                    <TableRow
                      hover
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                    >
                      <TableCell>{object.message}</TableCell>
                      <TableCell align={'right'}>{moment(object.created_at).fromNow()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            }
          </TableContainer>
          <Box spacing={2} display={'flex'} justifyContent={'center'} sx={{ m: 3 }}>
            <Pagination
              count={Math.ceil(parseInt(response?.count) / parseInt(rowsPerPage))}
              variant={'outlined'}
              color={'primary'}
              onChange={handlePage}
            />
          </Box>
        </Card>
      }
    </Container>
  )
}
