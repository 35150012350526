import {IconButton, ListItemIcon, ListItemText, MenuItem, TableBody, TableCell, TableRow} from "@mui/material";
import React, {useState} from "react";
import moment from "moment";
import {Delete, FileOpen, Link, MoreVert, Share} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import Menu from "@mui/material/Menu";
import {Link as RouterLink} from "react-router-dom";

const RentReportBody = ({ state, filters }) => {
  const {enqueueSnackbar} = useSnackbar()

  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [anchor, setAnchor] = useState(null)
  const [selectedReport, setSelectedReport] = useState(null)


  return (
    <TableBody>
      { state?.results?.map((report, index) => (
        <TableRow
          hover
          tabIndex={-1}
          key={index}
        >

          <TableCell
            component="th"
            scope="row"
            sx={{ alignItems: 'center' }}
          >
            {moment(report?.month, 'M').format('MMMM').replace(/^\w/, (c) => c.toUpperCase())}
          </TableCell>

            <TableCell
              component="th"
              scope="row"
              // align={'center'}
            >
              {report?.year}
            </TableCell>



          <TableCell
            component="th"
            scope="row"
            sx={{
              width: '24px'
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setSelectedReport(report)
                setAnchor(e.currentTarget)
                setOpen(report.id)
              }}>
              <MoreVert/>
            </IconButton>

            <Menu
              open={open === report.id}
              anchorEl={anchor && anchor}
              onClose={(e) => {
                e.stopPropagation()
                setOpen(false)
              }}
              PaperProps={{
                sx: { width: 200, maxWidth: '100%' },
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuItem
                component={RouterLink}
                onClick={(e)=> {
                  setOpen(false)
                  e.stopPropagation()
                }}
                to={report?.general_rent_report}
                target="_blank"
                sx={{
                  color: 'text.secondary'
                }}
              >
                <ListItemIcon>
                  <FileOpen />
                </ListItemIcon>
                <ListItemText
                  primary="Relatório"
                  primaryTypographyProps={{ variant: 'body2' }}/>
              </MenuItem>

              <MenuItem
                component={RouterLink}
                onClick={ (e) => {
                  e.stopPropagation()
                  navigator.clipboard.writeText(report?.general_rent_report)
                    .then(() => {
                      enqueueSnackbar('Link copiado', { variant: 'success' })
                    })
                }
                }
                sx={{
                  color: 'text.secondary'
                }}
              >
                <ListItemIcon>
                  <Share />
                </ListItemIcon>
                <ListItemText
                  primary="Compartilhar"
                  primaryTypographyProps={{ variant: 'body2' }}/>
              </MenuItem>


            </Menu>
          </TableCell>



        </TableRow>
      ))}

    </TableBody>
  )
}

export default RentReportBody