import
{
  Avatar,
  Card, TextField, Button, Box, Container, Typography
} from "@mui/material";
import React, {useState} from "react";
import {ArrowBack, LockClockOutlined} from "@mui/icons-material";
import {api} from "../../../services/Main/Api";
import {useSnackbar} from "notistack";
import logo from "../../../assets/images/blocob3-logo.png";
import {Helmet} from "react-helmet";
import Masks from "../../../components/Shared/Masks";
import {useNavigate} from "react-router-dom";

const UnitUserRegister = () => {
  const {enqueueSnackbar} = useSnackbar()
  const navigate = useNavigate()

  const [dataReq, setDataReq] = useState(null)
  const [error, setError] = useState(null)

  const handleSubmit = (e) => {
    e.preventDefault()

    Object.assign(dataReq, {cnpj_cpf: dataReq?.cnpj_cpf?.replace(/\D+/g, '') })

    api.post(`user/unit-signup/`, dataReq)
      .then(response => {
        console.log(response)
        enqueueSnackbar("Cadastro realizado com sucesso!", {variant: "success"})
        navigate('/login/usuario')
      })
      .catch(error =>{
        console.log(error)
        setError(error.response.data)
        enqueueSnackbar("Problema ao realizar cadastro.", {variant: "error"})
      })
  }



  return (
    <>
      <Helmet>
        <title>Cadastro de Usuário - Bloco B3 </title>
      </Helmet>
        <Box
          component={'form'}
          onSubmit={handleSubmit}
          sx={{
            marginTop: 8,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
          <Avatar variant={'square'} sx={{ m: 1, height: 64, width: 64 }} src={logo}>
            <LockClockOutlined fontSize={'large'}/>
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            mb={'1rem'}
            >{'Cadastro de Usuário'}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '400px',
              justifyContent: 'space-between'

            }}
            >
            <TextField
              label={'Nome'}
              required
              size={'small'}
              fullWidth
              error={!!error?.first_name}
              helperText={error?.first_name}
              value={dataReq?.first_name ?? ''}
              onChange={(e) => setDataReq((prevState) => ({...prevState, first_name: e.target.value}))}
              sx={{
                mb: "1rem"
              }}
              />

            <TextField
              label={'Sobrenome'}
              required
              size={'small'}
              error={!!error?.last_name}
              helperText={error?.last_name}
              value={dataReq?.last_name ?? ''}
              onChange={(e) => setDataReq((prevState) => ({...prevState, last_name: e.target.value}))}
              sx={{
                mb: "1rem"
              }}
            />
            <TextField
              label={'CPF/CNPJ'}
              required
              size={'small'}
              error={!!error?.cnpj_cpf}
              helperText={error?.cnpj_cpf}
              value={dataReq?.cnpj_cpf?.length <= 14 ? Masks.cpf(dataReq?.cnpj_cpf ?? '') : Masks.cnpj(dataReq?.cnpj_cpf ?? '')}
              onChange={(e) => setDataReq((prevState) => ({...prevState, cnpj_cpf: e.target.value}))}
              sx={{
                mb: "1rem"
              }}
            />
            <TextField
              label={'Senha'}
              required
              size={'small'}
              error={!!error?.password}
              helperText={error?.password}
              value={dataReq?.password ?? ''}
              onChange={(e) => setDataReq((prevState) => ({...prevState, password: e.target.value}))}
              sx={{
                mb: "1rem"
              }}
            />
            <TextField
              label={'Confirmar Senha'}
              required
              size={'small'}
              error={!!error?.confirm_password}
              helperText={error?.confirm_password}
              value={dataReq?.confirm_password ?? ''}
              onChange={(e) => setDataReq((prevState) => ({...prevState, confirm_password: e.target.value}))}
            />
        </Box>
          <Box  sx={{
            display: "flex",
            justifyContent: "space-between",
            marginY:"1rem",
            width: '400px'
          }}>

          <Button type={"button"} variant={"outlined"}
                  startIcon={<ArrowBack />}
                  onClick={(e) => {
                    window.history.back()
                  }}>{"Voltar"}</Button>
          <Button

            type="submit"
            variant="contained"
            size="large"
            // onClick={handleSubmit}
          >
            {"Registrar"}
          </Button>

          </Box>
        </Box>
    </>
  )
}

export default UnitUserRegister