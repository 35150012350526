     import axios from 'axios';

import { getRefreshToken, getToken, removeTokens, setRefreshToken, setToken } from './AuthToken'
     import {removeTokensUnitUser} from "../UnitUser/UnitUserToken";
     import {removeCondoId, removeCondoName, removeFilterDate, removeLocalUser} from "./AuthStorage";
     import {useSnackbar} from "notistack";
     import {Navigate} from "react-router-dom";
     import {useEffect, useState} from "react";

function signOut(){
    removeTokens()
    removeLocalUser()
    removeCondoId()
    removeCondoName()
    removeFilterDate()
}

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://api.blocob3.com.br/',
})

let isRefreshing = false;
let failedRequestsQueue = []

api.interceptors.request.use(async config => {
    const token = getToken();

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

function errorReader(param, string) {
    if (param.includes(string)){
        signOut()
    }
}

api.interceptors.response.use(response => {
    // console.log(response)
    return response;
}, error => {
    console.log(error)
    // error && errorReader(error.response.data.condo, 'Você não é administrador desse condomínio')
    // error.response.data !== undefined && signOut()
    // console.log("on rejects", error.response)
    // console.log("URL", error.config.url)
    if (error.response.status === 401 && error.config.url !== 'user/token-refresh/') {
        if (error.response.data?.code === "token_not_valid") {
            const refresh = getRefreshToken()
            const orginalConfig = error.config

            if (!isRefreshing) {
                isRefreshing = true;
                api.post("user/token-refresh/", {
                    refresh
                }).then(response => {
                    const {access} = response.data
                    // console.log(response)
                    setToken(access)
                    setRefreshToken(refresh)
                    api.defaults.headers["Authorization"] = `Bearer ${access}`
                    failedRequestsQueue.forEach(request => request.onSuccess(access))
                    failedRequestsQueue = []
                }).catch(err => {
                    failedRequestsQueue.forEach(request => request.onFailure(err))
                    failedRequestsQueue = []
                    removeTokensUnitUser()
                    removeTokens()
                }).finally(() => {
                    isRefreshing = false;
                })
            }

            return new Promise((resolve, reject) => {
                failedRequestsQueue.push({
                    onSuccess: (token) => {
                        console.log("onSuccess")
                        orginalConfig.headers["Authorization"] = `Bearer ${token}`
                        resolve(api(orginalConfig))
                    },
                    onFailure: (err) => {
                        console.log("onFailure")
                        window.location.reload(true)
                        reject(err)
                    }
                })
            })
        }
    }

    return Promise.reject(error);
})
