import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Button, Dialog, useMediaQuery} from "@mui/material";
import * as React from "react";

const SignedRecipentTransferMakeOpen = ({ openByStatus, handleCloseConfirmation, handleMakeItOpen  }) => {
    const sizeMatch = useMediaQuery('@media (min-width:600px)')

    return (
        <Dialog
            fullScreen={!sizeMatch}
            sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '600px', maxHeight: 435 }, padding: 2 }}

            maxWidth="xs"
            open={openByStatus.open ?? !!openByStatus.open }
            onClick={event => event.stopPropagation()}
        >
            <DialogTitle>{"Abrir Repasse"}</DialogTitle>
            <DialogContent>
                {"Deseja realmente abrir este repasse?"}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button autoFocus onClick={handleCloseConfirmation}>{"Cancelar"}</Button>
                <Button variant={"contained"} onClick={handleMakeItOpen}>{"Confirmar"}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SignedRecipentTransferMakeOpen