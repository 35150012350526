import {InputAdornment, Skeleton, TextField} from "@mui/material";
import {Category} from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import {Event} from "@mui/icons-material";

import moment from "moment/moment";

const SimpleSelector = (
  { simpleOptions,
    simpleIndexOptions,
    state,
    setState,
    disabled,
    label,
    keyName,
    InputProps,
    isLoading,
    styles,
    required,
    options,
    error,
    alternateOption,
    optionKey,
    optionKeyValue,
    SelectProps,
    menuItems
  }) => {


  return (
    <>
    {!isLoading ?
      <TextField
        label={label}
        SelectProps={SelectProps}
        required={required}
        select
        fullWidth
        disabled={disabled}
        size={"small"}
        value={state?.[keyName] ?? ''}
        onChange={(e)=> setState((prevState) => ({...prevState, [keyName]: e.target.value}))}
        error={!!error?.[keyName]}
        helperText={error?.[keyName]}
        sx={styles}
        InputProps={InputProps}
      >
        {options?.sort()?.map((option, index) => (
          <MenuItem
            key={index}
            value={optionKeyValue ? option?.[optionKeyValue] : option ?? ''}>
            { alternateOption ? alternateOption[option] : option?.[optionKey] ?? option }
          </MenuItem>
        ))}

        {simpleOptions?.map((option, index) => (
          <MenuItem key={index} value={option?.value}>
            {option?.label}
          </MenuItem>
         ))
        }
          {simpleIndexOptions?.map((option, index) => (
              <MenuItem key={index} value={option?.value}>
                  {option?.label}
              </MenuItem>
          ))
          }

        {menuItems}


      </TextField>
        :
      <Skeleton
        variant="rounded"
        animation="pulse"
        height={39.964}
      />
    }
    </>
  )
}

export default SimpleSelector