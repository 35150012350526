import React, {useContext, useEffect, useState} from "react";
import Context from "../../../contexts/Context";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {api} from "../../../services/Main/Api";
import {Helmet} from "react-helmet";
import {
  Backdrop,
  Box, Button,
  Card,
  CardContent,
  Container, Divider, Grid, Link, MenuItem,
  Skeleton,
  Table,
  TableContainer, TextField, Tooltip, Typography,
  useMediaQuery
} from '@mui/material'
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import { Add, AddCircle, Assignment, Receipt, Report, Search, Update } from '@mui/icons-material'
import SearchInput from "../../../components/Filters/SearchInput";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import RentTableHead from "./RentTableHead";
import RentTableBody from "./RentTableBody";
import UnitFilter from "../../../components/Filters/UnitFilter";
import CircularProgress from "@mui/material/CircularProgress";
import RentPreview from "./RentRecipientSet/RentPreview";
import RentContractForm from './RentContractForm'
import AIRentContractForm from './AIRentContractForm'

const RentList = () => {
  const { filters, setFilters, getUnitOptionsNumbers } = useContext(Context)
  const { user } = useContext(Context)
  const {
    is_superuser,
  } = user || {}
  const [data, setData] = useState(null)
  const [total, setTotal] = useState(0)
  const [openDelete, setOpenDelete] = useState(false)
  const [openFormRent, setOpenFormRent] = useState(false)
  const [openAIFormRent, setOpenAIFormRent] = useState(false)
  const [reqLoading, setReqLoading] = useState(false)
  const [openPreview, setOpenPreview] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(['active', 'pending', 'inactivating']);



  function initialFilters () {
    return setFilters(prevState => ({
      ...prevState,
      page: 1,
      page_size: 50,
      unit__condo_id: +getCondoId(),
      search: '',
      // status: ''
    }))
  }


  useEffect(function filterSet () {
    initialFilters()
    getUnitOptionsNumbers()
  }, [])


  function listRents() {
    if (filters?.unit__condo_id) {
      let params = new URLSearchParams();


      // Adiciona os demais parâmetros da requisição
      params.append('unit__condo_id', filters?.condo_id);
      params.append('page', filters?.page);
      params.append('page_size', filters?.page_size);
      params.append('search', filters?.search);

      // Verifica se a propriedade 'status' está presente nos filtros e não está vazia
      if (selectedStatus?.length > 0) {
        // Cria uma nova instância de URLSearchParams
        // let params = new URLSearchParams();

        // Adiciona cada status aos parâmetros
        selectedStatus.forEach(status => {
          params.append('status', status);
        });

        api.get(`/rent_contract/`, { params: params })
          .then(response => {
            setData(response.data);
            setTotal(response.data.count);
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        api.get(`/rent_contract/`, { params: params })
          .then(response => {
            setData(response.data);
            setTotal(response.data.count);
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  }


  function contractHandleChange (e){
    const value =  e.target.value
    setFilters(prevState => ({...prevState, contract_type: value}))
  }

  function openDialog (create: boolean, update: boolean) {
    setFilters(prevState => ({...prevState, isCreate: create, isUpdate: update}))
  }

  const sizeMatch = useMediaQuery('@media (min-width:700px)')

  useEffect(function firstLoad(){
    if (filters){
      listRents()
    }
  }, [filters, selectedStatus])

  const statusLabels = {
    active: 'Ativo',
    inactivating: 'Encerrando',
    inactive: 'Encerrado',
    pending: 'Pendente',
  };

  const statusHandleChange = (event) => {
    setSelectedStatus(event.target.value);
  };


  const handleOpenFormRent = () => {
    setOpenFormRent(true);
  }


  return (
   <>
     <Helmet>
       <title>Locações - Bloco B3</title>
     </Helmet>
     { openFormRent &&
       <RentContractForm
        closeForm={()=>{
          setOpenFormRent(false)
        }}
       /> }

     { openAIFormRent &&
       <AIRentContractForm
         closeForm={()=>{
           setOpenAIFormRent(false)
         }}
       /> }

     <Backdrop
       sx={{ color: '#fff', zIndex: 1500, }}
       open={reqLoading}
     >
       <CircularProgress color="inherit"/>
     </Backdrop>
     <Container
       maxWidth={false}
     >
       <Box
         sx={{
           display: 'flex',
           '@media (max-width:600px)': {
             display: 'grid',
             gridTemplateColumns: 'repeat(1, 1fr)',
             gap: 3,
           },
           justifyContent: 'space-between',
           alignItems: 'center',
           mb: 3,

         }}
       >
         <Typography
           textAlign={'center'}
           variant={'h5'}
         >
           Contratos ({total})
         </Typography>

           <Box
             sx={{
               '@media (max-width:600px)': {
                 display: 'grid',
                 gridTemplateColumns: 'repeat(1, 1fr)',
                 gap: 2,

               }
             }}
           >
             {/*{ is_superuser && <Button*/}
             {/*  variant={'contained'}*/}
             {/*  // href={buttonHref}*/}
             {/*  // startIcon={icon}*/}
             {/*  sx={{mr: 2}}*/}
             {/*  size={'small'}*/}
             {/*  onClick={() => {*/}
             {/*    setOpenAIFormRent(true);*/}
             {/*  }}*/}
             {/*>*/}
             {/*  <Add /> { `Criar Contrato com AI` }*/}
             {/*</Button> }*/}
             <Button
               variant={'contained'}
               // href={buttonHref}
               // startIcon={icon}
               size={'small'}
               onClick={() => {
                 setOpenFormRent(true);
               }}
             >
               <Add /> { `Criar Contrato` }
             </Button>

           </Box>

       </Box>

       <Card>
         {data ?
           <CardContent>
             <Grid container spacing={2} >
               <Grid item xs={9}>
                 <SearchInput
                   label={'Procurar pelo nome ou imóvel'}
                   styles={{
                     mr: sizeMatch ? '1rem' : 'auto',
                     maxWidth: sizeMatch ? '400px' : 'auto',
                     mb: sizeMatch ? 'auto' : '1rem'
                   }}
                 />


                 <TextField
                   select
                   size={'small'}
                   label={'Situação'}
                   value={selectedStatus}
                   onChange={statusHandleChange}
                   SelectProps={{
                     multiple: true,
                     renderValue: (selected) => selected.map(value => statusLabels[value]).join(', '), // Mapeia valores para legendas
                   }}
                   sx={{
                     maxWidth: sizeMatch ? '250px' : 'auto',
                     width: '100%',
                     mr: '0.5rem',
                     mb: sizeMatch ? 'auto' : '1rem',
                   }}
                 >
                   {Object.keys(statusLabels).map(value => (
                     <MenuItem key={value} value={value}>
                       {statusLabels[value]}
                     </MenuItem>
                   ))}
                 </TextField>

                 <TextField
                   select
                   size={'small'}
                   label={'Tipo de Contrato'}
                   value={filters?.contract_type ?? ''}
                   onChange={contractHandleChange}
                   sx={{
                     maxWidth: sizeMatch? '250px' : 'auto',
                     width: '100%',
                     mr: '0.5rem',
                   }}
                 >
                   <MenuItem
                     value={'commercial'}
                   >
                     Comercial

                   </MenuItem>
                   <MenuItem
                     value={'residencial'}
                   >
                     Residencial
                   </MenuItem>
                 </TextField>
               </Grid>
               <Grid item xs={3} sx={{justifyContent: 'end', display:'flex', alignItems: 'center'}} >



               </Grid>
             </Grid>

          <Divider sx={{my:3}} />


           <TableContainer>
              <>
             {data?.results?.length > 0 ?
                 <Table>
                   <RentTableHead />
                   <RentTableBody
                     state={data}
                     reqLoading={reqLoading}
                     setReqLoading={setReqLoading}
                   />


                 </Table>
                 :
               <NoResultsAnimation />
              }
            </>
           </TableContainer>
         </CardContent>
             :
             <Skeleton
               variant="rounded"
               height={700}
               animation="pulse"
             />

           }
       </Card>

       <PaginationGlobal
         state={data}
         filters={filters}
         setFilters={setFilters}
       />



     </Container>
     <RentPreview
       open={openPreview}
       setOpen={setOpenPreview}
     />
   </>
 )
}

export default RentList