import {Search, SearchOff} from "@mui/icons-material";
import {Autocomplete, IconButton, InputAdornment, TextField} from "@mui/material";

const AccountSelector = ({ account, setAccount, checkingAccounts }) => {

  return (
    <Autocomplete
      fullWidth
      size={'small'}
      sx={{ mb: 2 }}
      clearIcon={<SearchOff color="primary"/>}
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      onChange={(_event, value) => setAccount((prevState) => ({ ...prevState ,search: value?.name ?? '', id: value?.id }))}
      value={checkingAccounts?.find(option => option.name === account?.search) ?? ''}
      options={checkingAccounts?.map((option) => ({ name: option?.name, id: option?.id })) ?? ['']}
      getOptionLabel={(option) => option.name ?? ''}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option?.name}
          </li>
        );
      }}
      renderInput={(params) => <TextField
        {...params}
        label="Selecionar Conta"
      />}
    />
  )
}

export default AccountSelector