import {TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";

const TableServiceHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          Nome
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          Serviço
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          E-mail
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          Telefone
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          Criado em
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default TableServiceHead