import React, {useState, useEffect} from "react";
import {Avatar, Card, CardContent, CardHeader, IconButton, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import {Box} from "@mui/system";
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import {api} from "../../../../services/Main/Api";
import moment from "moment";
import Masks from "../../../../components/Shared/Masks";
import {Edit, SafetyCheck} from "@mui/icons-material";

const TerminateContractDetail = (params) => {

    return (
        <Card sx={{width:'100%' }}>
            <CardHeader
                avatar={<Avatar sx={{bgcolor: 'primary.main'}}><UpdateDisabledIcon  /></Avatar>}
                title={'Encerrar Contrato'}/>
            <Divider sx={{mb: '.3rem'}}/>

            <CardContent >
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={'1.5rem'}>
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            color: "primary.main",
                        }}
                    >
                        Dados de Encerramento
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1rem'}}>
                    <Typography style={{fontWeight: 'bold'}}>
                        Data de Aviso de Encerramento
                    </Typography>
                    <Typography>
                        {moment(params.data?.warning_date).format('DD/MM/YYYY')}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1rem'}}>
                    <Typography style={{fontWeight: 'bold'}}>
                        Data de Encerramento
                    </Typography>
                    <Typography>
                        {moment(params.data?.closing_date).format('DD/MM/YYYY')}
                    </Typography>
                </Box>
                {params.data.final_contract_fine_value && params.data.final_contract_fine_value !== 0 ?
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1rem'}}>
                            <Typography style={{fontWeight: 'bold'}}>
                                Valor da Multa
                            </Typography>
                            <Typography>
                                {Masks.money(String(params.data.final_contract_fine_value))}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1rem'}}>
                            <Typography style={{fontWeight: 'bold'}}>
                                Multa Referente ao período de
                            </Typography>
                            <Typography>
                                {params.data.reference_period}
                            </Typography>
                        </Box>
                    </>
                    :
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1rem'}}>
                        <Typography style={{fontWeight: 'bold'}}>
                            Valor da Multa
                        </Typography>
                        <Typography>
                            Não calculada !
                        </Typography>
                    </Box>
                }
            </CardContent>
        </Card>
    )
}

export default TerminateContractDetail