import {
    Alert,
    Box,
    Button,
    Fade,
    FormHelperText,
    GlobalStyles,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import SimpleText from "../../../../components/Shared/SimpleText";
import {Forward} from "@mui/icons-material";
import React, {useContext, useEffect} from "react";
import Context from "../../../../contexts/Context";
import AuthValidation from "../../../../components/Shared/AuthValidation";

const PaymentChecker = (props) => {
  const { filters, sessionCheck, dataProvider } = useContext(Context)

  const { data, setData, handleSubmitPreview, handleSubmitPayment, error, paymentReq } = props

  const sizeMatch = useMediaQuery('@media (min-width:900px)')



    useEffect(() => {
        if (data?.barcode && data?.barcode.length >= 47) {
            setTimeout(() => {
                dataProvider?.validation && handleSubmitPreview();
            }, 2000);
        }
    }, [data?.barcode]);


    return (
    <>

      <Fade
        in={!dataProvider?.paymentStep}
        mountOnEnter
        unmountOnExit
        timeout={300}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingX: '1rem',

          }}
        >
            <GlobalStyles styles={{
                '.MuiInputBase-input': {
                    textAlign: 'center',
                }
            }}
            />

            <TextField
                label={'Código de barras'}
                variant='outlined'
                inputProps={{
                    pattern: "\\d*"
                }}
                value={data?.barcode}
                error={!!error}
                helperText={error}
                onChange={(e) => setData({...data, barcode: e.target.value})}
                fullWidth
                size={'small'}
                sx={{
                    textAlign: "center",
                    maxWidth: '800px',
                    mt: '1rem',
                    mb: '1rem',
                }}
            />
          {/*<Button*/}
          {/*  disabled={!!!data?.barcode}*/}
          {/*  onClick={() => {*/}
          {/*     !dataProvider?.validation && sessionCheck()*/}
          {/*      dataProvider?.validation && handleSubmitPreview()*/}
          {/*    }*/}
          {/*  }*/}
          {/*>*/}
          {/*  Consultar*/}
          {/*</Button>*/}
          {filters?.payment &&
            <Alert
              variant={'outlined'}
              severity="info"
              sx={{
                border: 'none',
                marginY: '1rem',
                boxShadow: 'none'
                // display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center'
              }}
            >
              Etapa necessária para criar a despesa
            </Alert>
          }
        </Box>
      </Fade>

      <Fade
        in={!!paymentReq?.Barcode}
        mountOnEnter
        unmountOnExit
        timeout={300}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            mt: '1rem'
          }}

        >


        </Box>
      </Fade>
    </>
  )
}

export default PaymentChecker