import React, {
  useContext
} from "react";
import moment from "moment";
import {Box,
  TextField
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {Forward} from "@mui/icons-material";
import Context from "../../../../../contexts/Context";


const DateSelect = ({
    setHasData,
    setData
  }) => {

  const { filters, setFilters } = useContext(Context)

  return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <DatePicker
          views={['year', 'month', 'day']}
          label="De"
          value={moment(filters?.start_date).toDate() }
          inputProps={{ readOnly: true }}
          onChange={(newValue) => {
            setFilters((prevState) => ({...prevState, start_date: moment(newValue).format('YYYY-MM-DD'), page_index: 0}))
            setData([])
            setHasData(true)

          }
          }
          renderInput={(params) =>
            <TextField
              {...params}
              variant={'standard'}
              helperText={null}
              size="small"
              sx={{
                maxWidth: '125px',
                width: '100%'
              }}
            />}
        />
       <Forward
        sx={{
          mr: '2rem',
          ml: '2rem'
        }}
       />
        <DatePicker
          views={['year', 'month', 'day']}
          label="Até"
          minDate={moment(filters?.start_date)}
          value={moment(filters?.end_date).toDate()}
          inputProps={{ readOnly: true }}
          onChange={(newValue) => {
            setFilters((prevState) => ({ ...prevState, end_date: moment(newValue).format('YYYY-MM-DD'), page_index: 0}))
            setData([])
            setHasData(true)
          }}
          renderInput={(params) =>
            <TextField
              {...params}
              variant={'standard'}
              sx={{
                maxWidth: '125px',
                width: '100%'
              }}
              helperText={null}
              size="small"
            />}
        />
      </Box>
  )
}
export default DateSelect