import React, {useContext, useEffect, useState} from "react";
import { Step, StepLabel, Stepper} from "@mui/material";
import SimpleButtons from "../../../components/Shared/SimpleButtons";
import Context from "../../../contexts/Context";
import {useNavigate} from "react-router-dom";

const RecipientWizard = (props) => {

  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////

  const { dataProvider, setDataProvider } = useContext(Context)
  const {  create } = props
  const { activeStep, firstStep  } = dataProvider || {}

  const navigate = useNavigate()


  //////////////////////////////////////
  //// Useffect, triggers and calls ////
  //////////////////////////////////////

  useEffect(function firstStepDone(){
    firstStep && setDataProvider(prevState => ({...prevState, activeStep: 1}))
  }, [firstStep])


  useEffect(function whenCreated(){
    if (firstStep === true) {
      setDataProvider(prevState => ({...prevState, activeStep: 1}))
    }
  },[firstStep])


  return (
    <>
      <Stepper
        activeStep={activeStep}
        sx={{
          mb: '1rem'
        }}
      >
        <Step>
          <StepLabel>Beneficiário</StepLabel>
        </Step>
        <Step>
          <StepLabel>Contas</StepLabel>
        </Step>
        <Step>
          <StepLabel>Conclusão</StepLabel>
        </Step>
      </Stepper>
      {props.children}

      {activeStep === 0 &&
        //-> Recipient
        <SimpleButtons
          forwardButton={'Avançar'}
          onForward={() => {
            firstStep === true && setDataProvider(prevState => ({...prevState, activeStep: 1}))
            firstStep === false && create()
          }}
          noBack={firstStep === true && activeStep === 0}

        />
      }

      {activeStep === 1 &&
        // -> Account
        <SimpleButtons
          forwardButton={'Avançar'}
          forwardDisable={dataProvider?.recipientButton}
          onForward={() => setDataProvider(prevState => ({...prevState, activeStep: 2}))}
          onBack={() =>  setDataProvider(prevState => ({...prevState, activeStep: 0}))}
        />
      }



      {activeStep === 2 &&
        // -> Conclusion Page
        <SimpleButtons
          forwardButton={'Concluir'}
          onForward={() => navigate('/beneficiarios')}
          onBack={() =>  setDataProvider(prevState => ({...prevState, activeStep: 1}))}
        />
      }




    </>
  )
}

export default RecipientWizard