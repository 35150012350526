import {Box, Grid} from "@mui/material";
import StatusInvoice from "../../../../../../../components/Invoice/StatusInvoice";
import React from "react";

const InvoiceDetailStatus = ({ invoice }) => {
  return (
    <Grid
      item
      xs={6}
    >
      <Box
        border
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center'
        }}
      >
        <StatusInvoice
          invoice={invoice}
        />
      </Box>
    </Grid>
  )
}

export default InvoiceDetailStatus