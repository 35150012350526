import SimpleDialog from "../../../../components/Shared/SimpleDialog";
import {CompareArrows, Pix} from "@mui/icons-material";
import {Alert, Backdrop, Box, CardContent, Fade, TextField, Typography} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import Masks from "../../../../components/Shared/Masks";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import Context from "../../../../contexts/Context";
import DateAndTime from "../../../../components/BookingAndMaintenance/DateAndTime";

const FormDialogs = (
  {
    openDelete,
    setOpenDelete,
    deleteBondById,
    handleId,
    openTransfer,
    setOpenTransfer,
    recipient_account,
    disableTransfer,
    transfer,
    loadingModal,
    data,
    setData,
    returnRecipientName,
    processCredentials,
    returnBankLabel,
    returnBankName,
    returnBankBranch,
    returnBankAccount,
    returnPixLabel,
    returnPixBank,
    percentage,
    returnPixAcc,
    formatTax,
    recipient_transfer_amount,
    recipient_pix_key,
  }
) => {

  const { dataProvider } = useContext(Context)
  const { dataBondLength } = dataProvider || {}

  const [timepicker, setTimePicker] = useState(moment().locale('pt-br').format("LT"))

  function dateFormat () {
    const formEndDate = moment(data?.transfer_date).format('YYYY-MM-DD')
    const setEndDate = formEndDate + ' ' + timepicker
    return moment(setEndDate).format()
  }

  useEffect(function whenClockChange(){
    setData(prevState => ({...prevState, transfer_date: dateFormat() }))
  }, [data?.transfer_date, timepicker])

  return (
    <>
      <SimpleDialog
        open={openDelete}
        setOpen={setOpenDelete}
        title={'Deletar chave Pix'}
        // question={'Realmente deseja remover esse beneficiário do contrato?'}
        onClickConfirm={() => deleteBondById(handleId, true)}
        dialogContent={
        <CardContent>
          <Typography>
            Realmente deseja remover esse beneficiário do contrato?
          </Typography>
          { dataBondLength > 1 &&
            <Typography
            sx={{
              fontWeight: 'bold'
            }}
          >
            Ao remover esse beneficiário, será necessário redistribuir a porcentagem, o que será feito assim que a remoção for confirmada.
          </Typography>
          }
        </CardContent>
        }
      />
      <SimpleDialog
        open={openTransfer}
        setOpen={setOpenTransfer}
        title={`Transferência ${data?.transferType === 'TED' ? 'TED' : 'Pix'} `}
        disabled={!disableTransfer}
        titleIcon={data?.transferType === 'TED' ? <CompareArrows /> : <Pix />}
        onClickConfirm={() => transfer()}
        dialogContent={
          <>

            <Backdrop
              sx={{ color: '#fff', zIndex: 1500, }}
              open={loadingModal}
            >
              <CircularProgress color="inherit"/>
            </Backdrop>

            <CardContent>
              { data?.autoTransfer === true &&
                <Box>
                  <Alert
                    variant={'outlined'}
                    severity="info"
                    sx={{
                      mb: '1rem'
                    }}
                  >
                    {`Repasse automático ativo, agendado para todo dia ${data?.transferDay}`}
                  </Alert>
                </Box>
              }


              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  bgcolor: 'neutral.main',
                  p: '1rem',
                  borderRadius: '1rem',
                  mb: '1rem',
                }}
              >
                <SimpleTypography
                  label={returnRecipientName()}
                  isLoading={!returnRecipientName()}
                  labelStyles={{
                    fontWeight: 'bold',
                    color: 'primary.main'
                  }}
                  boxStyles={{
                    width: '100%',
                    mb: '0.5rem'
                  }}
                />

                <SimpleTypography
                  label={`CPF / CNPJ`}
                  isLoading={!processCredentials()}
                  directValue={processCredentials()}
                  labelStyles={{
                    fontWeight: 'bold'
                  }}
                  boxStyles={{
                    width: '100%',
                    mb: '0.5rem'
                  }}
                />

                {data?.transferType === 'TED' &&
                  <>
                    <SimpleTypography
                      label={`Banco`}
                      isLoading={!returnBankLabel()}
                      directValue={returnBankName()}
                      labelStyles={{
                        fontWeight: 'bold'
                      }}
                      boxStyles={{
                        width: '100%',
                        mb: '0.5rem'
                      }}
                    />
                    <SimpleTypography
                      label={`Agência`}
                      isLoading={!returnBankLabel()}
                      directValue={returnBankBranch()}
                      labelStyles={{
                        fontWeight: 'bold'
                      }}
                      boxStyles={{
                        width: '100%',
                        mb: '0.5rem'
                      }}
                    />
                    <SimpleTypography
                      label={`Conta`}
                      isLoading={!returnBankLabel()}
                      directValue={returnBankAccount()}
                      labelStyles={{
                        fontWeight: 'bold'
                      }}
                      boxStyles={{
                        width: '100%',
                        mb: '0.5rem'
                      }}
                    />
                  </>
                }

                {data?.transferType === 'pix' &&
                  <>
                    <SimpleTypography
                      label={`Chave Pix`}
                      isLoading={!returnPixLabel()}
                      directValue={returnPixLabel()}
                      labelStyles={{
                        fontWeight: 'bold'
                      }}
                      boxStyles={{
                        width: '100%',
                        mb: '0.5rem'
                      }}
                    />
                    <SimpleTypography
                      label={`Banco`}
                      isLoading={!returnPixBank()}
                      directValue={returnPixBank()}
                      labelStyles={{
                        fontWeight: 'bold'
                      }}
                      boxStyles={{
                        width: '100%',
                        mb: '0.5rem'
                      }}
                    />
                    <SimpleTypography
                      label={`Conta`}
                      isLoading={!returnPixAcc()}
                      directValue={returnPixAcc()}
                      labelStyles={{
                        fontWeight: 'bold'
                      }}
                      boxStyles={{
                        width: '100%',
                        mb: '0.5rem'
                      }}
                    />
                  </>
                }

                <SimpleTypography
                  label={`Porcentagem`}
                  isLoading={!percentage}
                  directValue={`${percentage}%`}
                  labelStyles={{
                    fontWeight: 'bold'
                  }}
                  boxStyles={{
                    width: '100%',
                    mb: '0.5rem'
                  }}
                />

                <SimpleTypography
                  label={'Imposto de renda'}
                  isLoading={!formatTax()}
                  directValue={formatTax()}
                  labelStyles={{
                    fontWeight: 'bold'
                  }}
                  boxStyles={{
                    width: '100%',
                    mb: '0.5rem'
                  }}
                />



                <SimpleTypography
                  label={`Valor`}
                  isLoading={!recipient_transfer_amount}
                  directValue={Masks?.money(recipient_transfer_amount?.toString()) || ''}
                  labelStyles={{
                    fontWeight: 'bold',
                    color: 'primary.main'
                  }}
                  boxStyles={{
                    width: '100%',
                    mb: '0.5rem'
                  }}
                />
              </Box>
              <Fade
                in={true}
                mountOnEnter
                unmountOnExit
                timeout={500}
                sx={{
                  marginY: '1rem',
                  // width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Box>
                  <DateAndTime
                    labelDate={'Data de Transferência'}
                    labelTime={'Horário'}
                    minDate={moment().format}
                    setState={setData}
                    valueFunction={dateFormat}
                    timePicker={setTimePicker}
                    keyName={'transfer_date'}
                  />





                  {/*<DatePicker*/}
                  {/*  sx={{ textAlign: 'center' }}*/}
                  {/*  views={['year', 'month', 'day']}*/}
                  {/*  label="Dia para transferência"*/}
                  {/*  minDate={moment()}*/}
                  {/*  inputProps={{*/}
                  {/*    placeholder: 'DD/MM/AAAA'*/}
                  {/*  }}*/}
                  {/*  value={moment(data?.transfer_date)}*/}
                  {/*  onChange={(newValue) => setData((prevState) => ({*/}
                  {/*    ...prevState,*/}
                  {/*    transfer_date: moment(newValue).format('YYYY-MM-DD')*/}
                  {/*  }))}*/}
                  {/*  renderInput={*/}
                  {/*    (params) => (*/}
                  {/*      <TextField*/}
                  {/*        {...params}*/}
                  {/*        size={'small'}*/}
                  {/*        sx={{ mb: 2 }}*/}
                  {/*        helperText={null}*/}
                  {/*        fullWidth*/}
                  {/*        required*/}

                  {/*      />*/}
                  {/*    )*/}
                  {/*  }*/}
                  {/*/>*/}

                </Box>
              </Fade>
            </CardContent>
          </>
        }
      />

    </>

  )
}

export default FormDialogs