import {Box, Fade} from "@mui/material";
import SimpleText from "../../../../components/Shared/SimpleText";
import StateFilter from "../../../../components/Filters/StateFilter";
import React from "react";

const GuarantorInputs =
  ({
    edit,
    guarantee,
    guaranteeData,
    setGuaranteeData
   }) => {

  return (
    <Box
      sx={{
        // paddingX:'1rem'
      }}
    >
      {guarantee === 'guarantor' && edit &&
        <Fade
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={300}
        >
          <Box>
            <SimpleText
              label={'Nome'}
              state={guaranteeData}
              setState={setGuaranteeData}
              keyName={'name'}
              styles={{ mb: '1rem' }}
            />

            <SimpleText
              label={'CPF / CNPJ'}
              cpfAndCnpj
              state={guaranteeData}
              setState={setGuaranteeData}
              keyName={'cpf_cnpj'}
              styles={{ mb: '1rem' }}
            />

            <SimpleText
              label={'Telefone'}
              phone
              state={guaranteeData}
              setState={setGuaranteeData}
              keyName={'phone'}
              styles={{ mb: '1rem' }}
            />

            <SimpleText
              label={'Rua'}
              state={guaranteeData}
              setState={setGuaranteeData}
              keyName={'street'}
              styles={{ mb: '1rem' }}
            />

            <SimpleText
              label={'Número'}
              onlyNumbers
              state={guaranteeData}
              setState={setGuaranteeData}
              keyName={'number'}
              styles={{ mb: '1rem' }}
            />

            <SimpleText
              label={'Bairro'}
              state={guaranteeData}
              setState={setGuaranteeData}
              keyName={'district'}
              styles={{ mb: '1rem' }}
            />

            <SimpleText
              label={'Complemento'}
              state={guaranteeData}
              setState={setGuaranteeData}
              keyName={'complement'}
              styles={{ mb: '1rem' }}
            />

            <SimpleText
              label={'Cidade'}
              state={guaranteeData}
              setState={setGuaranteeData}
              keyName={'city'}
              styles={{ mb: '1rem' }}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                align: 'center',
                mb: '1rem'
              }}
            >

              <StateFilter
                label={'Estado'}
                state={guaranteeData}
                setState={setGuaranteeData}
                keyName={'state'}
              />

              <SimpleText
                label={'CEP'}
                cep
                state={guaranteeData}
                setState={setGuaranteeData}
                keyName={'cep'}
                styles={{ml: '0.5rem'}}
              />

            </Box>

          </Box>
        </Fade>
      }
    </Box>
  )
}

export default GuarantorInputs