import {
  Alert,
  Avatar, Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Fade,
  FormHelperText,
  Skeleton, TextField, Typography,
  useMediaQuery
} from "@mui/material";
import {AccountBalance, Close, Forward, Receipt} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import AccountReadInfo from "../../BankOperations/components/BankTransfer/AccountReadInfo";
import React, {useContext} from "react";
import SimpleText from "../../../../components/Shared/SimpleText";
import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import Context from "../../../../contexts/Context";

const PaymentPreview = ({ paymentReq, handleSubmitPayment, date, setDate }) => {
  const sizeMatch = useMediaQuery('@media (min-width:900px)')

  const { filters, dataProvider } = useContext(Context)

  const { operationType } = filters || {}


  return (
    <>
      <Fade
        in={!!dataProvider?.paymentStep}
        mountOnEnter
        unmountOnExit
        timeout={300}
      >

        <Box
          sx={{
            p: '2rem',
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
            // flexDirection: 'column'

          }}
        >
          <SimpleTypography
            label={'Beneficiado'}
            state={paymentReq}
            keyName={'BeneficiaryName'}
            labelStyles={{ fontWeight: 'bold'}}
          />
          {/*<SimpleTypography*/}
          {/*  label={'Quem vai pagar'}*/}
          {/*  state={paymentReq}*/}
          {/*  keyName={'DraweeName'}*/}
          {/*  labelStyles={{ fontWeight: 'bold'}}*/}
          {/*/>*/}
          {/*<SimpleTypography*/}
          {/*  label={'Código de barras'}*/}
          {/*  state={paymentReq}*/}
          {/*  keyName={'Barcode'}*/}
          {/*  labelStyles={{ fontWeight: 'bold'}}*/}
          {/*/>*/}

          <SimpleTypography
            label={'Vencimento'}
            directValue={paymentReq?.DueDate? moment(paymentReq?.DueDate?.replace('Z', ''))?.format('DD/MM/YYYY') : <Close sx={{color: 'error.main'}}/>}
            labelStyles={{ fontWeight: 'bold'}}
          />

          <SimpleTypography
            label={'Valor da Multa'}
            type={'moneyMultiplier'}
            state={paymentReq}
            keyName={'TotalAddedValue'}
            labelStyles={{ fontWeight: 'bold'}}
          />

          <SimpleTypography
            label={'Valor Final'}
            type={'moneyMultiplier'}
            state={paymentReq}
            keyName={'Value'}
            labelStyles={{
              fontWeight: 'bold',
              color: 'primary.main'
          }}
          />

          {moment(paymentReq?.DueDate?.replace('Z', ''))?.format('DD/MM/YYYY') < moment()?.format('DD/MM/YYYY') &&
            <Box
              sx={{
                marginY: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
            <Alert
              severity="warning"

            >
              Este boleto está atrasado. Por favor, atente-se ao cálculo da multa.
            </Alert>
            </Box>
          }




          {operationType === 'payment' &&
            <Box
              sx={{
                marginY: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Alert
                severity="info"
              >
                Ao clicar em "PAGAR" o agendamento será confirmado
              </Alert>
            </Box>
          }





        </Box>
      </Fade>
    </>
  )

}

export default PaymentPreview