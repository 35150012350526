import {Box, Button, Dialog, DialogContent, DialogTitle, Stack, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useMediaQuery} from "@mui/material";
import React from "react";

const CategoryModal = ({open, handleSubmit, errors, setOpen, isSubmitting, setName}) => {
  const sizeMatch = useMediaQuery('@media (min-width:600px)')

  return (
    <Dialog
      fullScreen={!sizeMatch}
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '600px' } }}
      open={open}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle>Nova Categoria</DialogTitle>
      <DialogContent>
        <Stack component={'form'} noValidate sx={{ pt: 3 }} onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label={'Nome'}
            onChange={(e) => setName(e.target.value)}
            sx={{ mb: 2 }}
            error={!!errors?.name}
            helperText={errors?.name ? errors.name : ''}
          />
          <Box textAlign={'right'}>
            <Button onClick={() => setOpen(false)} sx={{ mr: 1 }}>
              {'Cancelar'}
            </Button>
            <LoadingButton
              type={'submit'}
              loading={isSubmitting}
              variant={'contained'}>
              {'Salvar Categoria'}
            </LoadingButton>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default CategoryModal