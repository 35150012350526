import {
  Avatar,
  Box,
  IconButton,
  Skeleton,
} from "@mui/material";
import {grey} from "@mui/material/colors";
import { PhotoCamera } from "@mui/icons-material";
import React from "react";

const Branding = ({
      imageFile,
      setImageFile,
      setImg,
      image,
    }) => {

  function handlePictureChange(e) {
    const newImage = e.target?.files?.[0]
    setImageFile(prevState => ({
      ...prevState,
      logo: newImage
    }))
    if (newImage) {
      setImg(URL.createObjectURL(newImage))
    }
  }


  return(
    <>
      {imageFile ?
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mb: '1rem'
        }}
      >
          {image ?
            <Avatar
              sx={(theme) => ({
                width: theme.spacing(18),
                height: theme.spacing(18),
                borderWidth: "1px",
                borderStyle: 'dashed',
                borderColor: grey[300],
                // p: 1
              })}
              src={image}
            >
              <PhotoCamera/>
            </Avatar>
            :
            <Avatar
              sx={(theme) => ({
                backgroundColor: theme.palette.secondary.main,
                width: theme.spacing(18),
                height: theme.spacing(18),
                borderWidth: "1px",
                fontSize: '4.5rem',
                borderStyle: 'dashed',
                borderColor: grey[300],
              })}
            >
              {`N/A`}
            </Avatar>
          }
        <Box display={'flex'} justifyContent={'center'}>
          <IconButton aria-label="upload picture" component="label">
            <input hidden accept="image/*" type="file" id={'profile_picture'}
                   onChange={handlePictureChange}/>
            <PhotoCamera/>
          </IconButton>
        </Box>

      </Box>
      :
        <Box
          sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              mb: '1rem'
          }}
        >

        <Avatar
          sx={(theme) => ({
            width: theme.spacing(18),
            height: theme.spacing(18),
            borderWidth: "1px",
            borderStyle: 'dashed',
            borderColor: grey[300],
          })}
          src={image}
        >
            <Skeleton
              width={'100%'}
              variant='rounded'
              height={373.33}
            />
        </Avatar>
      </Box>
      }
    </>
  )
}

export default Branding
