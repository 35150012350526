import {ArrowBack} from "@mui/icons-material";
import {Button} from "@mui/material";

const ReceiptBackButton = () => {
  return (
    <Button
      sx={{
        mt: 3
      }}
      type={'button'}
      variant={'outlined'}
      href={"/"}
      startIcon={<ArrowBack/>}>
      {'Voltar'}
    </Button>
  )
}

export default ReceiptBackButton