import {Image, InsertDriveFile, PictureAsPdf, TextSnippet} from "@mui/icons-material";
import {Avatar} from "@mui/material";
import React from "react";

export const Pdf = () => {
  return (
    <Avatar sx={{
      width: 50,
      height: 50,
      mr: 2,
      bgcolor: "transparent",
      border: `1px solid`,
      borderColor: "secondary.light"
    }}>
      <PictureAsPdf sx={{color: "red"}}/>
    </Avatar>

  )
}

export const Txt = () => {
  return (
    <Avatar sx={{
      width: 50,
      height: 50,
      mr: 2,
      bgcolor: "transparent",
      border: `1px solid`,
      borderColor: "secondary.light"
    }}>
      <TextSnippet sx={{color: "#95a8ba"}}/>
    </Avatar>
  )
}

export const ImgIcon = () => {
  return (
    <Avatar sx={{
      width: 50,
      height: 50,
      mr: 2,
      bgcolor: "transparent",
      border: `1px solid`,
      borderColor: "secondary.light"
    }}>
      <Image sx={{color: "blue"}}/>
    </Avatar>
  )
}

export const WhateverIcon = () => {
  return (
    <Avatar sx={{
      width: 36,
      height: 36,
      mr: 2,
      bgcolor: "transparent",
      border: `1px solid`,
      borderColor: "secondary.light"
    }}>
      <InsertDriveFile sx={{color: "black"}}/>
    </Avatar>
  )
}