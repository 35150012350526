import {Grid, Skeleton, Typography} from "@mui/material";

const TransferId = ({ loading, specificData }) => {
  return (
    <>
        <Grid
          item
          xs={4}
        >
          <Typography
            variant={'subtitle2'}
          >
            {'Nome'}
          </Typography>
        </Grid>
        <Grid
          item
          xs={8}
        >
          <Typography
            variant={'body2'}
          >
            {!loading
              ? specificData
              : <Skeleton
                sx={{
                  mx: 'auto'
                }}
                variant={'text'}
                width={'100%'}
              />
            }
          </Typography>
        </Grid>
    </>
  )
}

export default TransferId