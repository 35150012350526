import React from "react";
import {
  Box,
  Fade
} from "@mui/material";
import SimpleText from "../../../../components/Shared/SimpleText";

const EscrowInputs =
  ({
     edit,
     guarantee,
     guaranteeData,
     setGuaranteeData,
   }) => {


    return (
      <Box>
        {guarantee === 'deposit' && edit &&
          <Fade
            in={true}
            mountOnEnter
            unmountOnExit
            timeout={300}
          >
            <Box>
              <SimpleText
                label={'Descrição'}
                state={guaranteeData}
                setState={setGuaranteeData}
                keyName={'description'}
                styles={{ mb: '1rem' }}
              />

              <SimpleText
                label={'Valor'}
                money
                state={guaranteeData}
                setState={setGuaranteeData}
                keyName={'value'}
                styles={{ mb: '1rem' }}
              />
            </Box>
          </Fade>
        }
      </Box>
    )
  }

export default EscrowInputs