import { createContext } from 'react';

const AuthContextData = {
    signIn: credentials => { },
    isAuthentication: false,
    user: null
}

const AuthContext = createContext(AuthContextData)

export default AuthContext;