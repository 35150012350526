import React, {
  useContext,
  useEffect,
  useState
} from "react";
import {
  Avatar,
  Box,
  Fade,
  Link,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell, TableBody
} from "@mui/material";
import {
  Done,
  ExpandMore,
  FileDownloadOutlined,
  NorthOutlined,
  ReceiptOutlined
} from "@mui/icons-material";
import SimpleTypography from "../../../../../components/Shared/SimpleTypography";
import Context from "../../../../../contexts/Context";
import CustomerInvoiceDetail from "../../../Admin/CustomerInvoice/CustomerInvoiceDetail";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import Masks from "../../../../../components/Shared/Masks";
import {api} from "../../../../../services/Main/Api";

const CardStatement = ({
    e,
    hasData,
  }) => {

  const { filters } = useContext(Context)
  const [open, setOpen] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState(false);

  function getLabel(entry) {
    const details = entry.Details?.toString();
    const entryValue = entry.EntryValue?.toString();
    const tags = entry.Tags[1]?.toString();

    switch (true) {
      case details?.includes('Boleto Pago'):
        return 'Pagamento recebido';
      case details?.includes('Pagamentos de conta'):
        return 'Pagamento efetuado';
      case details?.includes('Saldo Inicial'):
        return 'Saldo Inicial';
      case details?.includes('Saldo Final'):
        return 'Saldo Total';
      case tags?.includes('Taxas Bancarias'):
        return tags;
      case entryValue?.includes('-'):
        return 'Transferência enviada';
      default:
        return 'Transferência recebida';
    }
  }

  useEffect(function(){
    if (!hasData){
      filters?.expand ? setOpen(false) : setOpen(true)
    }
  },[filters?.expand])

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  function getCustomerInvoiceRate (id) {
      api.get(`/customer-invoice/rate/${id}`)
          .then( response => {
            setData(response.data)
          })
          .catch(error => {
            console.log(error)
          })
  }

  return (
    <>
      <IconButton
          onClick={()=>setOpen(prevState => !prevState)}
          sx={{
            mb: '0.5rem'
          }}
      >
        <ExpandMore
            sx={{
              transform: open && 'rotate(180deg)',
              color: open ? '#A2A2A2FF' : 'primary.main'
            }}
        />
      </IconButton>
        <>
          {
            e.entries.map((e, index) =>
            <Fade
              key={index}
              in={open}
              mountOnEnter
              unmountOnExit
              timeout={300}
            >
              <Box
                key={index}
                sx={{
                  width: '100%',
                  mb: '2rem',

                }}
              >
                <Box

                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'center',
                    mb: '1rem',
                    width: '100%'
                  }}
                >
                  <Avatar

                    sx={{
                      bgcolor: 'transparent',
                      border: `.05px solid`,
                      borderColor: e.EntryValue?.toString().includes('-') ? '#A2A2A2FF' : '#4bc454',
                      width: '40px',
                      height: '40px',
                      mr: '1rem',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    {
                      e.Details?.toString()?.includes('Boleto Pago')
                        ? <ReceiptOutlined
                          sx={{
                            color: '#4bc454',
                          }}
                        />
                        : e.Details?.toString()?.includes('Pagamentos de conta')
                          ? <Done
                            sx={{
                              color: '#A2A2A2FF'
                            }}
                          />
                          : <NorthOutlined
                            sx={{
                              color: e.EntryValue?.toString().includes('-') ? '#A2A2A2FF' : '#4bc454',
                              transform: !e.EntryValue?.toString().includes('-') && 'rotate(180deg)',
                            }}
                          />
                    }

                  </Avatar>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'start',
                      alignItems: 'start',
                      width: '100%'

                    }}
                  >

                    <SimpleTypography
                      label={
                        getLabel(e)
                      }
                      labelStyles={{
                        fontWeight: 'bold'
                      }}
                      type={'moneyMultiplier'}
                      state={e}
                      keyName={'EntryValue'}
                      boxStyles={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: 16,
                        mb: '0.5rem',
                      }}
                    />
                    <Typography>
                      {
                        e?.Tags[1] === "Taxas Bancarias do Dia"
                            ? "Plataforma BlocoB3"
                            : (e?.Tags === "contract_fee"
                                    ? e?.Tags[1]
                                    : e?.Details?.replace('Transferência', '')
                            )
                      }
                    </Typography>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',

                      }}
                      >
                      <SimpleTypography
                        state={e}
                        keyName={'EntryDate'}
                        type={'time'}
                        boxStyles={{mb: '1rem'}}
                      />
                      <Box
                          display="flex"
                          alignItems="center"
                          gap={2}
                      >
                        {e?.Tags[1] === "Taxas Bancarias do Dia" &&
                            <Link
                                component="button"
                                variant="body2"
                                underline="none"
                                onClick={() => {
                                  handleClickOpen()
                                  getCustomerInvoiceRate(e?.Tags[0])
                                }}
                            >
                              Ver taxas
                            </Link>
                        }
                        {e?.ReceiptUrl &&
                            <Link
                                target={"_blank"}
                                underline="none"
                                href={e?.ReceiptUrl}
                            >
                              <FileDownloadOutlined
                                  sx={{ fontSize: '30px' }}
                              />
                            </Link>
                        }
                      </Box>
                    </Box>

                  </Box>
                </Box>
              </Box>
            </Fade >
            )
          }
        </>
      <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">{"Detalhes"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell >Código</TableCell>
                  <TableCell>Empresa</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell width={150}>Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { data?.rates?.map(rate =>
                    <TableRow>

                      <TableCell>
                        TRA-{rate.id}
                      </TableCell>
                      <TableCell>
                        {rate?.description}
                      </TableCell>
                      <TableCell >
                        {moment(`${rate.reference_date}`).format('DD/MM/YYYY')}

                      </TableCell>
                      <TableCell>
                        {Masks.money(rate?.rate?.toString())}
                      </TableCell>
                    </TableRow> )}



                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align={"right"}><Typography sx={{fontWeight:"bold",}} level={"h4"} color={data?.total < 0 && "error.main" }> Total:</Typography></TableCell>
                  <TableCell><Typography sx={{fontWeight:"bold", fontSize:"1.3rem"}} level={"h4"} color={data?.total < 0 && "error.main" }> {Masks.money(data?.total?.toString())}</Typography> </TableCell>
                </TableRow>
              </TableBody>

            </Table>

          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-start', marginLeft: '1rem' }}>
          <Button onClick={handleClose} color="primary" autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CardStatement