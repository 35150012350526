import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Button, Dialog, useMediaQuery} from "@mui/material";
import * as React from "react";

const MakePaid = ({ openByStatus, handleCloseConfirmation, handleMakeItPaid  }) => {
  const sizeMatch = useMediaQuery('@media (min-width:600px)')

  return (
    <Dialog
      fullScreen={!sizeMatch}
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '600px', maxHeight: 435 }, padding: 2 }}

      maxWidth="xs"
      open={openByStatus.paid ?? !!openByStatus.paid }
      onClick={event => event.stopPropagation()}
    >
      <DialogTitle>{"Baixa Manual"}</DialogTitle>
      <DialogContent>
        {"Deseja realmente marcar esta fatura como paga?"}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button autoFocus onClick={handleCloseConfirmation}>{"Cancelar"}</Button>
        <Button variant={"contained"} onClick={handleMakeItPaid}>{"Confirmar"}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default MakePaid