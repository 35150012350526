import React, {useContext, useEffect, useState} from 'react'
import Chart from 'react-apexcharts'
import Context from '../../contexts/Context'
import { getCondoId } from '../../services/Main/AuthStorage'
import { api } from '../../services/Main/Api'
import {Box, Card, CardContent, Skeleton, Typography} from '@mui/material'
import moment from 'moment'
import {grey} from "@mui/material/colors";
import Lottie from "lottie-react";
import emptyBox from "../../assets/animations/emptyBox.json";

export default function Pizza (props) {
  const { condoIdLogin } = useContext(Context)
  const [categories, setCategories] = useState(null)
  const [loading, setLoading] = useState(true)
  const [verifier, setVerifier] = useState(false)


  useEffect(function firstRender () {
    const condo = getCondoId()

    const data = {
      condo: condoIdLogin ?? condo,
      reference_month: +moment(props?.date).format('MM'),
      reference_year: +moment(props?.date).format('YYYY')
    }

    api.post('analytics/apexcharts/category-pie/', data)
      .then(response => {
        const seriesResult = response?.data?.series?.map((e) => (e))
        const labelsResult = response?.data?.labels?.map((e) => (e))

        setCategories(
          {
            series: seriesResult ?? '',
            options: {
            labels: labelsResult,

              legend: {
                // show: true,
                position: 'top',
                // horizontalAlign: 'right',
                // floating: true,
                offsetY: 0,
                // offsetX: -5
              },
              responsive: [{
                breakpoint: 700,
                options: {

                  legend: {
                    position: 'top',
                    offsetY: -3,

                  },
                  title: {
                    offsetY: -15,
                  },
                  plotOptions: {
                    pie: {
                      offsetX: 0,

                    }
                  },
                }
              }],

              chart: {
                toolbar: {
                  show: false,
                },
                type: 'pie',
              },
              plotOptions: {
                pie: {
                  distributed: true,
                  enableShades: false,
                  offsetX: 0,
                  offsetY: 20,
                }
              },
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: "20px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: "bold"
                }
              },
              tooltip: {
                enabled: true,
                enabledOnSeries: undefined,
                shared: true,
                followCursor: false,
                intersect: false,
                inverseOrder: false,
                custom: undefined,
                fillSeriesColor: false,
                theme: 'light',
                style: {
                  fontSize: '13px',
                  fontFamily: undefined,
                },
                marker: {
                  show: true,
                },
              },

              colors: [
                '#5c9ae6',
                '#31e613',
                '#d808d1',
                '#5700a7',
                '#f17e24',
                '#ffc882',
                '#8291a3',
                '#e9affe',
                '#2e8475',
                '#1e67c0',
                '#720266',
                '#71336b',
                '#222f3c',

              ]
            },


          }
        )
        setLoading(false)
      }).catch(error => {
        console.log(error)
    }).finally(r => {
      setVerifier(true)
    })

  }, [props?.date, condoIdLogin])

  return (
    <>
      {categories &&
        <Card
          sx={{
            width: '100%',
            mr: 2,
            maxHeight: '600px'
          }}>
          {verifier
            ?
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: '2rem'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    textAnchor: 'start',
                    dominantBaseline: 'auto',
                    fontSize: '14px',
                    fontWeight: 900,

                  }}
                >
                  Despesas por Categoria
                </Typography>
              </Box>

              <>
                {categories?.series?.length > 0 ?
                  <>

                    <Chart
                      options={categories?.options}
                      series={categories?.series}
                      labels={categories?.labels}
                      type="pie"
                      height={500}
                    />
                  </>
                  :
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >

                    <Typography
                      align={'center'}
                      sx={{
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        color: grey[400],
                      }}

                    >
                      Nenhum Resultado!
                    </Typography>
                    <Box
                      sx={{
                        width: '250px',
                      }}
                    >
                      <Lottie animationData={emptyBox} loop={true}/>
                    </Box>
                  </Box>
                }
              </>
            </CardContent>
            :
            <Skeleton
              height={'500px'}
              variant={"rounded"}
              animation={'pulse'}/>
          }
        </Card>
      }
    </>
  )
}
