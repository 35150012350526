import React, {
  useContext,
  useEffect,
  useState
} from "react";
import {
  Box,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import Context from "../../../contexts/Context";
import { LocalLibrary } from "@mui/icons-material";
import { api } from "../../../services/Main/Api";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {useSnackbar} from "notistack";
import SimpleText from "../../../components/Shared/SimpleText";
import SimpleTypography from "../../../components/Shared/SimpleTypography";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import ButtonSet from "../../../components/Shared/ButtonSet";

const BookingAreaUpdate = ({ editEvent, setEditEvent }) => {
  const { loadingModal, setLoadingModal } = useContext(Context)

  const [reqData, setReqData] = useState(null)
  const [edit, setEdit] = useState(false)
  const [error, setError] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)

  const {enqueueSnackbar} = useSnackbar()



  async function fetchData (){
    try {
      if (editEvent?.id) {
        const response = await api.get(`/booking/areas/${editEvent?.id}`);
        setReqData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteBookingArea (){
    try {
      const response = await api.delete(`/booking/areas/${editEvent?.id}/`);
      setEditEvent({ open: false });
      setEdit(false);
      enqueueSnackbar("Área comum Deletada com Sucesso!", { variant: "success" });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Erro ao excluir área comum!", { variant: "error" });
    }
  }

  async function handleSubmit (){
    setLoadingModal(true);

    const data = {
      ...reqData,
      condo: getCondoId(),
      capacity: +reqData?.capacity,
    };

    try {
      const response = await api.put(`/booking/areas/${editEvent?.id}/`, data);
      enqueueSnackbar("Área Comum Editada com Sucesso!", { variant: "success" });
      setEdit(false);
    } catch (error) {
      enqueueSnackbar("Erro ao Editar Área Comum!", { variant: "error" });
      console.error(error?.response?.data);
      setError(error?.response?.data);
    } finally {
      setLoadingModal(false);
    }
  }


  useEffect(function dataOnLoad(){
    fetchData()
  }, [editEvent])


  return (
    <>
      <SimpleDialog
        title={'Editar Área Comum'}
        titleIcon={<LocalLibrary/>}
        open={editEvent?.open ?? false}
        removeConfirm
        onClickCancel={() => {
          setEditEvent(false)
        }}
        actionMenu={
          <>
            {editEvent?.id &&
              <ButtonSet
                editState={edit}
                editClick={() => {
                  setEdit(true)
                }}
                deleteClick={() => setOpenDelete(true)}
                saveClick={() => {
                  handleSubmit()
                }}
                cancelClick={() => {
                  fetchData()
                  setEdit(false)
                }}
              />
            }
          </>
        }
        dialogContent={
          <>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loadingModal}
            >
              <CircularProgress color="inherit"/>
            </Backdrop>
            {edit ?
              <>
                <SimpleText
                  label={'Nome'}
                  state={reqData}
                  setState={setReqData}
                  keyName={'name'}
                  required={true}
                  error={error}
                  styles={{
                    mb: '1rem'
                  }}
                />

                <SimpleText
                  label={'Descrição'}
                  state={reqData}
                  setState={setReqData}
                  keyName={'description'}
                  error={error}
                  styles={{
                    mb: '1rem'
                  }}
                />

                <SimpleText
                  label={'Capacidade'}
                  state={reqData}
                  setState={setReqData}
                  keyName={'capacity'}
                  error={error}
                  onlyNumbers={true}
                  styles={{
                    mb: '1rem'
                  }}
                />

                <SimpleText
                  label={'Informações Adicionais'}
                  multiline
                  rows={5}
                  state={reqData}
                  setState={setReqData}
                  keyName={'information'}
                  error={error}
                  styles={{
                    mb: '1rem'
                  }}
                />
              </>
              :
              <Box

              >
                <SimpleTypography
                  label={'Nome'}
                  state={reqData}
                  isLoading={!reqData}
                  keyName={'name'}
                  labelStyles={{
                    fontWeight: 'bold',
                    fontSize: '21px'
                  }}
                  styles={{ fontSize: '21px' }}
                />
                <SimpleTypography
                  label={'Descrição'}
                  state={reqData}
                  isLoading={!reqData}
                  keyName={'description'}
                  labelStyles={{
                    fontWeight: 'bold',
                    fontSize: '21px'
                  }}
                  styles={{ fontSize: '21px' }}
                />
                <SimpleTypography
                  label={'Capacidade'}
                  state={reqData}
                  isLoading={!reqData}
                  keyName={'capacity'}
                  labelStyles={{
                    fontWeight: 'bold',
                    fontSize: '21px'
                  }}
                  styles={{ fontSize: '21px' }}
                />

                <SimpleTypography
                  block
                  state={reqData}
                  isLoading={!reqData}
                  keyName={'information'}
                  styles={{ fontSize: '21px', wordBreak: 'break-all' }}
                />
              </Box>
            }


          </>
        }
      />

      <SimpleDialog
        title={'Excluir Área Comum'}
        question={'Realmente deseja excluir essa área?'}
        open={openDelete}
        setOpen={setOpenDelete}
        functionProp={deleteBookingArea}
      />
    </>
  )
}


export default BookingAreaUpdate;
