import {InputAdornment, TextField} from "@mui/material";
import {Subtitles} from "@mui/icons-material";
import React from "react";

const Title = ({error, state, setState, disabled, styles, keyName}) => {
  return (
    <TextField
      fullWidth
      disabled={disabled}
      error={!!error?.[keyName]}
      helperText={error?.[keyName]}
      size={"small"}
      label={'Título'}
      sx={styles}
      onChange={(e)=> setState((prevState) => ({...prevState, [keyName]: e.target.value}))}
      value={state?.[keyName] ?? ''}
      InputProps={{
        startAdornment:
          <InputAdornment position="start">
            <Subtitles />
          </InputAdornment>
      }}
    />
  )
}

export default Title