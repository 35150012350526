import React, {
  useContext,
  useEffect,
  useState
} from "react";
import {
  CardContent,
  Card,
  Box,
  Skeleton,
} from "@mui/material";
import {
  useNavigate,
  useParams
} from "react-router-dom";
import {api} from "../../../services/Main/Api";
import {useSnackbar} from "notistack";
import {getCondoId} from "../../../services/Main/AuthStorage";
import Comments from "./Comments/Comments";
import ComplaintUpdateHeader from "./components/ComplaintCreateAndUpdate/ComplaintUpdateHeader/ComplaintUpdateHeader";
import ComplaintEditableContent from "./components/ComplaintCreateAndUpdate/ComplaintEditableContent/ComplaintEditableContent";
import ComplaintReadContent from "./components/ComplaintCreateAndUpdate/ComplaintReadContent/ComplaintReadContent";
import {Helmet} from "react-helmet";
import Context from "../../../contexts/Context";


const ComplaintDetailandUpdate = () => {
  const params = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { setDataProvider } = useContext(Context)

  const navigate = useNavigate()

  const [dataUpdate, setDataUpdate] = useState(null)
  const [edit, setEdit] = useState(false)
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [observer, setObserver] = useState(null)

  async function fetchDataById() {
    setIsLoading(true)
    try {
      const response = await api.get(`/complaint/${params?.id}/`);
      setDataUpdate(response.data);
      setDataUpdate((prevState) => ({ ...prevState, category: response.data?.category.key }));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false)
    }
  }

  async function update() {
    setIsLoading(true);
    setDataProvider(prevState => ({...prevState, loadingComplaint: true}))
    try {
      const dataReq = {
        ...dataUpdate,
        condo: +getCondoId(),
        ...(dataUpdate?.unit?.id && { unit: dataUpdate?.unit?.id })
      };

      const response = await api.put(`/complaint/${params?.id}/`, dataReq);
      enqueueSnackbar('Reclamação atualizada com sucesso!', { variant: 'success' });
      setObserver(prevState => !prevState);
    } catch (error) {
      console.error(error.response.data);
      enqueueSnackbar('Ocorreu um erro ao atualizar', { variant: 'error' });
      setError(error.response.data);
    } finally {
      setEdit(prevState => !prevState);
      setIsLoading(false)
    }
  }

  async function deleteComplaint(idToDelete) {
    try {
      await api.delete(`/complaint/${idToDelete}`);
      enqueueSnackbar('Reclamação deletada com sucesso!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Erro ao excluir reclamação!', { variant: 'error' });
    } finally {
      navigate('/reclamacoes');
    }
  }

  useEffect(function complaintDetails() {
    fetchDataById()
  }, [observer])

  return(
    <>
      <Helmet>
        <title>{`Reclamação ${dataUpdate?.category?.value}- Bloco B3`}</title>
      </Helmet>
      <Box>
        {dataUpdate && !isLoading ?
        <Card
          sx={{
            width: '100%',
            marginRight: '1rem'
          }}
          >
          <ComplaintUpdateHeader
            edit={edit}
            setEdit={setEdit}
            dataUpdate={dataUpdate}
            setDataUpdate={setDataUpdate}
            error={error}
            deleteComplaint={deleteComplaint}
          />

          <CardContent>
            {!edit ?
              <ComplaintReadContent
                dataUpdate={dataUpdate}
              />
              :
              <ComplaintEditableContent
                dataUpdate={dataUpdate}
                setDataUpdate={setDataUpdate}
                setEdit={setEdit}
                error={error}
                update={update}
              />
              }
            <Comments />
          </CardContent>
        </Card>
          :
          <Skeleton variant="rounded" height={500} animation="pulse" sx={{margin: 1}}/>

        }
      </Box>
    </>

  )
}

export default ComplaintDetailandUpdate;