import React, {useContext, useEffect, useState} from 'react'
import {
  Backdrop,
  Box,
  Card,
  Container,
  Grid,
  IconButton,
  Link,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {getCondoId, getFilterDate, localUnitUser, setFilterDate} from '../../../services/Main/AuthStorage'
import { PictureAsPdf } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import StatusInvoice from '../../../components/Invoice/StatusInvoice'
import { useSnackbar } from 'notistack'
import InvoiceIcon from '../../../components/Invoice/InvoiceIcon'
import moment from 'moment'
import SlipStatus from '../../../components/Invoice/SlipStatus'
import { Helmet } from 'react-helmet'
import CircularProgress from '@mui/material/CircularProgress'
import Context from '../../../contexts/Context'
import {grey} from '@mui/material/colors'
import Masks from '../../../components/Shared/Masks'
import Lottie from "lottie-react";
import emptyBox from "../../../assets/animations/emptyBox.json";
import {apiUser} from "../../../services/UnitUser/ApiUnitUser";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";


const UnitUserInvoiceList = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [response, setResponse] = useState(null)

  const storageDate = getFilterDate()
  const [filterResponse, setFilterResponse] = useState(null)
  const { loadingModal, unitUser } = useContext(Context)

  useEffect(function preventError() {
    const date = storageDate ? moment(new Date(storageDate)).format() : moment().format()
    setFilterDate(date)
  }, [])

  const [filters, setFilters] = useState({
    unit__condo_id: getCondoId(),
    page_size: 50,
    unit_id: localUnitUser().id ?? unitUser?.id,
    status: ""
  })

  const handlePage = (e, value) => {
    setFilters({ ...filters, page: value })
  }

  useEffect(function whenPageLoad (){
    if(filters) {
      apiUser.get(`invoice/`, {params: filters})
        .then(response => {
          const data = response.data
          setResponse(data)
          setFilterResponse(data)
          setIsLoading(false)
        }).catch(error => {
        console.log(error)
      })
    }

  },[filters])


  return (
    <>
      <Helmet>
        <title>Faturas - Bloco B3</title>
      </Helmet>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1500, }}
        open={loadingModal}
      >

        <CircularProgress color="inherit"/>
      </Backdrop>

      <Container maxWidth={false}>
        <Box sx={{
          marginY: '1em',
          pb: 1,
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <Typography variant={'h5'}>{'Faturas'}</Typography>
        </Box>

        <Card>
          <Grid container sx={{ p: 2, alignItems: 'center' }} spacing={1}>
            <Grid item xs={12} sm={4} lg={3}>
              <ToggleButtonGroup
                fullWidth
                exclusive
                value={filters?.status}
                color={'primary'}
                onChange={(e, value) => value !== null && setFilters(prevState => ({
                  ...prevState,
                  status: value
                }))}
                sx={{ mb: 2 }}
              >
                <ToggleButton value={""}>Todas</ToggleButton>
                <ToggleButton value={"pending"}>Pendentes</ToggleButton>
                <ToggleButton value={"overdue"}>Atrasadas</ToggleButton>
                <ToggleButton value={"paid"}>Pagas</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          { response?.results ?
            <TableContainer>
              {response?.results?.length > 0  ?
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: 'primary.main' }}>
                        Nº da Fatura
                      </TableCell>
                      <TableCell sx={{ color: 'primary.main' }}>
                        Imóvel
                      </TableCell>
                      <TableCell align={'center'} sx={{ color: 'primary.main' }}>
                        Referência
                      </TableCell>
                      <TableCell align={'center'} sx={{ color: 'primary.main' }}>
                        Vencimento
                      </TableCell>
                      <TableCell align={'center'} sx={{ color: 'primary.main' }}>
                        Status
                      </TableCell>
                      <TableCell sx={{ color: 'primary.main' }}>
                        Total
                      </TableCell>
                      <TableCell align={'center'} sx={{ color: 'primary.main' }}>
                        Boleto
                      </TableCell>
                      <TableCell/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {response?.results?.map(object =>
                      <TableRow
                        key={object.number}
                      >
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <InvoiceIcon variant={object?.status.key} key={object.id}/>
                            {object.number}
                          </Box>
                        </TableCell>
                        <TableCell>{object.unit}</TableCell>
                        <TableCell align={'center'}>
                          {object.reference_month}
                        </TableCell>
                        <TableCell align={'center'}>
                          {object.due_date
                            ? moment(object.due_date).format('DD/MM/YYYY')
                            : '-'}
                        </TableCell>
                        <TableCell align={'center'}>
                          <StatusInvoice status={object?.status} paidAt={object?.paid_at}/>
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          {object?.total.toString().includes('-') && '- '}
                          {Masks.money(object?.total.toString())}
                        </TableCell>
                        <TableCell align={'center'}>
                          {object?.bank_slip.length > 0
                            ? <Link
                              href={object?.bank_slip[0].file}
                              target="_blank"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <IconButton>
                                <PictureAsPdf/>
                              </IconButton>
                            </Link>
                            : <SlipStatus status={object.bank_slip_status}/>
                          }
                        </TableCell>
                      </TableRow>
                    )
                    }
                  </TableBody>
                </Table>
                :
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >

                  <Typography
                    align={'center'}
                    sx={{
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      fontSize: '24px',
                      fontWeight: 'bold',
                      color: grey[400],
                    }}

                  >
                    Nenhum Resultado!
                  </Typography>
                  <Box
                    sx={{
                      width:'250px',
                    }}
                  >
                    <Lottie animationData={emptyBox} loop={true} />
                  </Box>
                </Box>
              }
            </TableContainer>
            :
            <Skeleton variant="rounded" height={700} animation="pulse" sx={{margin: 1}}/>

          }

        </Card>


      </Container>

      {response?.count > 0 &&
        <Box spacing={2} display={'flex'} justifyContent={'center'} sx={{ m: 3 }}>
          <Pagination
            count={Math.ceil(response?.count / filters.page_size)}
            variant={'outlined'}
            color={'primary'}
            onChange={handlePage}
          />
        </Box>}
    </>
  )
}

export default UnitUserInvoiceList
