import {Box, Grid, Skeleton, Typography} from "@mui/material";
import {PhoneOutlined} from "@mui/icons-material";
import React from "react";

const InvoiceDetailSenter = ({ condo }) => {
  return (
    <Box

    >
      <Typography
        mb={1}>
        De:
      </Typography>
      {condo
        ? <>
          <Typography
            fontWeight={'bold'}
            mb={1}
            variant="body1"
          >
            {condo.name}
          </Typography>
          <Typography
            variant="body2"
          >
            {condo.street} {condo.number}, {condo.district}
          </Typography>
          <Typography
            variant="body2"
          >
            {condo.cep}, {condo.city} - {condo.state}
          </Typography>
          <Typography
            variant="body2"
            mt={2}
            alignItems={'center'}
          >
            <PhoneOutlined
              fontSize={'.2rem'}
            />
            {condo.phone}
          </Typography>
        </>
        : <>
          <Skeleton variant="text" animation="pulse" width={200} height={20}/>
          <Skeleton variant="text" animation="pulse" width={200} height={20}/>
          <Skeleton variant="text" animation="pulse" width={200} height={20}/>
          <Skeleton variant="text" animation="pulse" width={200} height={20}/>
        </>
      }
    </Box>
  )
}

export default InvoiceDetailSenter