import { useContext, useEffect, useState } from 'react'
import Context from '../../../contexts/Context'
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Fade,
  FormControlLabel,
  Grid, IconButton, InputAdornment,
  Link,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import {LockClockOutlined, Visibility, VisibilityOff} from '@mui/icons-material'
import logo from '../../../assets/images/blocob3-logo.png'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Masks from "../../../components/Shared/Masks";
import {getUserToken, removeTokensUnitUser} from "../../../services/UnitUser/UnitUserToken";
import Cookies from "universal-cookie";
import {REFRESH_KEY, TOKEN_KEY} from "../../../services/Main/AuthToken";

const UnitUserLogin = () => {
  const { signInUnitUser, unitUser, setErrors, errors } = useContext(Context)
  const navigate = useNavigate()
  const [dataReq, setDataReq] = useState(null)
  const [showPassword, setShowPassword] = useState (false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(()=> {
    const cookies = new Cookies(document.cookie);
    cookies.remove('userBlocob3.refresh');
    cookies.remove('userBlocob3.token');
  },[])

  useEffect(() => {
    if (unitUser && getUserToken()) {
      navigate('/usuario/faturas/', { replace: true })
    }
  }, [unitUser])

  const handleSubmit = (e) => {
    e.preventDefault()
    setErrors(null)


    dataReq?.cpf_cnpj && Object.assign(dataReq, {cpf_cnpj: dataReq?.cpf_cnpj?.replace(/\D+/g, '') ?? ''})
    signInUnitUser(dataReq)
  }

  return (
    <>
      <Helmet>
        <title>Login de Usuário- Bloco B3</title>
      </Helmet>
      {/*<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY} language={"pt-BR"}>*/}
        <Container component="main" maxWidth="xs">
          <CssBaseline/>
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar variant={'square'} sx={{ m: 1, height: 64, width: 64 }} src={logo}>
              <LockClockOutlined fontSize={'large'}/>
            </Avatar>
            <Typography component="h1" variant="h5">{'Bem Vindo Usuário!'}</Typography>

            {errors?.detail &&
              <Fade in={!!errors?.detail}>
                <Alert variant={'filled'} severity={'error'} sx={{ width: '100%', mt: 3 }}>
                  {'Usuário e/ou senha inválidos. Favor, verifique!'}
                </Alert>
              </Fade>
            }

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="CPF/CNPJ"
                name="email"
                autoFocus
                onChange={(e) => setDataReq((prevState) => ({...prevState, cpf_cnpj: e.target.value}))}
                value={dataReq?.cpf_cnpj?.length <= 14 ? Masks.cpf(dataReq?.cpf_cnpj ?? '') : Masks.cnpj(dataReq?.cpf_cnpj ?? '')}
                error={!!errors?.cpf_cnpj}
                helperText={errors?.cpf_cnpj ?? ''}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Senha"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                onChange={(e) => setDataReq((prevState) => ({...prevState, password: e.target.value }))}
                error={!!errors?.password}
                helperText={errors?.password ? errors.password[0] : ''}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(prevState => !prevState)}
                        edge="end"
                      >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>,
                }}
              />
              <FormControlLabel control={<Switch/>} label={'Lembrar'}/>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!isLoading}
              >
                {!isLoading ? <CircularProgress color={'primary'} size={28}/> : 'Entrar'}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/recuperar-senha" variant="body2">{'Esqueceu a Senha?'}</Link>
                </Grid>
                <Grid item>
                  <Link href="/registrar/usuario" variant="body2">{'Não possui cadastro?'}</Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      {/*</GoogleReCaptchaProvider>*/}
    </>
  )
}

export default UnitUserLogin
