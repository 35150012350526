import SimpleDialog from "./SimpleDialog";
import React, {useContext, useEffect, useState} from "react";
import Context from "../../contexts/Context";
import {useSnackbar} from "notistack";
import {api} from "../../services/Main/Api";
import CircularProgress from "@mui/material/CircularProgress";
import {Backdrop, Box, Button, Typography} from "@mui/material";
import {Lock} from "@mui/icons-material";
import SimpleText from "./SimpleText";

const AuthValidation = () => {
  const { dataProvider, setDataProvider } = useContext(Context)
  const { enqueueSnackbar } = useSnackbar()

  const [codeData, setCodeData] = useState('')
  const [error, setError] = useState(null)
  const [reqLoading, setReqLoading] = useState(false)

  async function codeValidation () {
    setReqLoading(true)
    try {
      const response = await api.post(`user/validation/code/${codeData?.validation_code}/`)
      setDataProvider(prevState => ({...prevState, openAuth: false, validation: true}))
      enqueueSnackbar('Validação efetuada', { variant: 'success' });
      setError(null);
    } catch (error) {
      setError(error.response.data);
      setDataProvider(prevState => ({...prevState, openAuth: true, validation: false}))
      enqueueSnackbar('Erro ao validar sessão', { variant: 'error' });
      console.log(error);
    } finally {
      setReqLoading(false)
    }
  }

  async function codeSubmit (){
    try {
      const response = await api.post('user/validation/code/');

      enqueueSnackbar('Código enviado para o e-mail cadastrado', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } catch (error) {
      enqueueSnackbar('Erro ao enviar código para e-mail', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
      console.error(error);
    } finally {
    }
  }

  function checkCodeLength(){
    if (codeData?.validation_code?.length >= 6){
      return false
    } else {
      return true
    }
  }

  useEffect(function whenComponentMount(){
    if(dataProvider?.openAuth === true) {
      codeSubmit()
    }
  },[dataProvider?.openAuth])

  return (
    <>
      <SimpleDialog
        title={'Validação de Segurança'}
        titleIcon={<Lock />}
        open={dataProvider?.openAuth}
        onClickCancel={()=> setDataProvider(prevState => ({openAuth: false}))}
        disabledCancel={true}
        onClickConfirm={() => codeValidation()}
        disabled={checkCodeLength()}
        dialogContent={
          <>
            <Backdrop
              sx={{color: '#fff', zIndex: 1500,}}
              open={reqLoading}
            >
              <CircularProgress color="inherit"/>
            </Backdrop>
            <Typography>
              Código de segurança enviado ao e-mail cadastrado, finalize essa etapa inserindo o código e pressionando em "CONFIRMAR"
            </Typography>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <SimpleText
                label={'Código de verificação'}
                variant={'standard'}
                required
                onlyNumbers
                state={codeData}
                onChange={(event) => setCodeData(prevState => (({...prevState, validation_code: event.target.value?.slice(0, 6)})))}
                // error={error}
                keyName={'validation_code'}
                styles={{
                  mb: '1rem',
                  width: '170px'
                }}
              />
              <Button
                variant={'text'}
                onClick={() => {
                  codeSubmit()
                }}
              >
                Reenviar código
              </Button>
            </Box>
          </>



        }



          />


    </>
  )
}

export default AuthValidation