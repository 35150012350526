import {
  TextField,
  IconButton,
  List,
  ListItem,
  Button,
  Box,
  CardHeader,
  Avatar,
  Divider,
  Grid,
  Card, CardContent, Skeleton, Typography, Select, InputAdornment, MenuItem
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Add,
  AllInclusive,
  Article,
  Edit,
  FilterList, Person,
  ReceiptLong,
  Save,
  VerifiedUserOutlined
} from "@mui/icons-material";
import SimpleText from "../../../../../components/Shared/SimpleText";
import Masks from "../../../../../components/Shared/Masks";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SimpleTypography from "../../../../../components/Shared/SimpleTypography";
import moment from "moment";
import {api} from "../../../../../services/Main/Api";
import Context from "../../../../../contexts/Context";
import {useSnackbar} from "notistack";
import {getCondoId} from "../../../../../services/Main/AuthStorage";
import SimpleDialog from "../../../../../components/Shared/SimpleDialog";
import SaveCancelButton from "../../../../../components/Shared/SaveCancelButton";

function DataInput(props) {
  const params = useParams()
  ///// -> When the component is called from a modal without `params` reference from url
  const { filters, dataProvider } = useContext(Context)
  const { unitId } = filters || {}
  /////
  const { index, handleEdit, onChange, onRemove, id, getOwner, createOwner, updateOwner,
    deleteOwner, edit, setEdit, error, setError, handleId, setHandleId, ...dataProps } = props;
  const { name, cpf_cnpj, phone, email } = dataProps
  const [openDelete, setOpenDelete] = useState(false)
  const [isEditing, setIsEditing] = useState(false);


  const handleNameChange = (event) => {
    onChange(index, { ...dataProps, name: event.target.value, id });
  };

  const handleCpfCnpjChange = (event) => {
    onChange(index, { ...dataProps, cpf_cnpj: event.target.value, id,  });
  };

  const handlePhoneChange = (event) => {
    onChange(index, { ...dataProps, phone: event.target.value, id });
  };

  const handleEmailChange = (event) => {
    onChange(index, { ...dataProps, email: event.target.value, id });
  };

  function disableSaveButton(){
    if ((name < 1) || (cpf_cnpj?.length < 1)){
      return true
    } else {
      return false
    }
  }

  useEffect(function deleteObserver(){
    setOpenDelete(false)
  }, [dataProvider?.deleteOwner])

  return (
    <>

      <Box
        sx={{
          display: 'flex',
          borderRadius: '1rem',
          maxWidth: '800px',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        { id && !isEditing ?
          <Box
            sx={{
              justifyContent: 'space-between',
              borderRadius: '1rem',
              width: '100%',
            }}
          >

            <Box
              sx={{
                // paddingX: '2rem',
                // mt: '1rem',
                mb: '1rem'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: 'primary.main',
                      fontWeight: 'bold',
                      fontSize: '23px'
                    }}
                  >
                    {name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ml: '1rem'

                  }}
                >
                  <IconButton onClick={() => {
                    setHandleId(id)
                    handleEdit()
                    setIsEditing(true)
                  }}>
                    <Edit />
                  </IconButton>


                  <IconButton
                    onClick={() => {
                      setHandleId(id)
                      setOpenDelete(true)
                     }}
                    sx={{padding : 0}}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            {/*<Divider sx={{mb: '1.5rem'}}/>*/}

            <Box
              sx={{
                // paddingX: '2rem',
                mb: '2rem'
              }}
            >

              <SimpleTypography
                label={'CPF / CNPJ'}
                directValue={Masks?.cpfOrCnpjText(cpf_cnpj)}
                labelStyles={{
                  fontWeight: 'bold'
                }}
                boxStyles={{
                  width: '100%',
                  mb: '1rem'
                }}
              />

              {phone &&
                <SimpleTypography
                  label={'Telefone'}
                  directValue={Masks?.phone(phone.toString(), true) || ''}
                  labelStyles={{
                    fontWeight: 'bold'
                  }}
                  boxStyles={{
                    width: '100%',
                    mb: '1rem'
                  }}
                />
              }


              {email &&
                <SimpleTypography
                  label={'E-mail'}
                  directValue={email}
                  labelStyles={{
                    fontWeight: 'bold'
                  }}
                  boxStyles={{
                    width: '100%',
                    mb: 'auto'
                  }}
                />
              }
            </Box>
          </Box>

          :
          <>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                // maxWidth: '700px',
                // padding: '2rem'
              }}
            >

              { id && isEditing?
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: '2rem'
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: 'primary.main',
                        fontWeight: 'bold',
                        fontSize: '23px'
                      }}
                    >
                      {name}
                    </Typography>
                  </Box>

                </Box>
                :
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography
                    variant={'h6'}
                    sx={{
                      mb: '1rem',
                      color: 'primary.main'
                    }}
                  >
                    { !id ? 'Novo Proprietário' : 'Editar proprietário' }
                  </Typography>

                </Box>


              }

              <TextField
                label="Nome"
                size={'small'}
                disabled={id && handleId !== id}
                value={name}
                onChange={handleNameChange}
                sx={{
                  width: '100%',
                  mb: '1rem'

                }}
              />
                <TextField
                  label="CPF / CNPJ"
                  disabled={id && handleId !== id}
                  size={'small'}
                  value={Masks?.cpfOrCnpj(cpf_cnpj) ?? ''}
                  onChange={handleCpfCnpjChange}
                  sx={{
                    width: '100%',
                    mb: '1rem'

                  }}
                />

                <TextField
                  label="Telefone"
                  size={'small'}
                  disabled={id && handleId !== id}
                  error={Boolean(error)}
                  helperText={error}
                  value={Masks.phone(phone?.toString() ?? '')  ?? ''}
                  onChange={handlePhoneChange}
                  sx={{
                    width: '100%',
                    mb: '1rem'
                  }}
                />

              <TextField
                label="E-mail"
                size={'small'}
                disabled={id && handleId !== id}
                value={email}
                onChange={handleEmailChange}
                sx={{
                  width: '100%',
                  mb: '1rem'
                }}
              />
              <SaveCancelButton
                  align={'start'}
                  edit={edit}
                  saveClick={() => {
                    updateOwner(handleId, setIsEditing)
                    createOwner(setIsEditing)

                  }}
                  cancelClick={() => {
                    getOwner()
                    handleEdit()
                    setHandleId(null)
                    setIsEditing(false)
                  }}
              />

            </Box>

          </>
        }
        <SimpleDialog
          open={openDelete}
          setOpen={setOpenDelete}
          title={'Excluir proprietário'}
          question={'Realmente quer excluir esse proprietário?'}
          onClickConfirm={() => deleteOwner(handleId)}
        />
      </Box>
    </>
  );
}

function Owner(props) {
  const { data, onChange, setOwnerDelete, isLoading, getOwner } = props || {};
  const params = useParams()
  const { id } = params
  const [filters, setFilters] = useState({fully_paid: ''})
  const { dataProvider, setDataProvider } = useContext(Context)
  const [handleId, setHandleId] = useState(null)
  const [error, setError] = useState(false)
  const [edit, setEdit] = useState(false)
  const {enqueueSnackbar} = useSnackbar()


  useEffect(function filter(){

  },[filters?.fully_paid])

  const handleDataChange = (index, newData) => {
    const newDataList = [...data];
    newDataList[index] = newData;
    onChange(newDataList);
  };

  const handleDataRemove = (index) => {
    const newDataList = data.filter((_, i) => i !== index);
    const newDataDelete = data.filter((_, i) => i === index);
    const objToDelete = newDataDelete.find((obj) => obj.hasOwnProperty("id"));
    if (objToDelete) {
      setOwnerDelete(prevState => [...prevState, objToDelete]);
    }
    onChange(newDataList);
  };

  const handleAddData = () => {
    const newDataList = [...data, { name: '', cpf_cnpj: '', phone: '', email: '' }];
    onChange(newDataList);
    setEdit(true);
  };

  function handleEdit(){
    setEdit(!edit)
  }

  function createOwner () {
    const dataReq = data.map(owner => {
      return {
        ...owner,
        unit: dataProvider?.unitId ?? params?.id
      };
    })

    dataReq?.map((owner) => {
      if (!owner?.id) {
        api.post(`unit_owner/`, {
          name: owner.name,
          cpf_cnpj: owner.cpf_cnpj.replace(/\D+/g, ""),
          phone: owner.phone.replace(/\D+/g, ""),
          email: owner.email,
          unit: dataProvider?.unitId ?? params?.id
        })
          .then(response => {
            enqueueSnackbar('Proprietário cadastrado', { variant: 'success' })
            setDataProvider(prevState => ({ ...prevState, UnitSecondStep: true }));
            getOwner()
            setHandleId(null)
            setEdit(false)
            setError(null)

          })
          .catch(error => {
            setError(error.response.data.phone)
            enqueueSnackbar('Erro ao registrar proprietário', {variant: 'error'})
            console.log(error)
          })
      }
    })
  }

  function updateOwner (ownerId, setIsEditing) {
    const updatedOwner = data.find(owner => owner.id === ownerId);
    if (updatedOwner) {
      api.put(`unit_owner/${updatedOwner.id}/`, {
        name: updatedOwner.name,
        cpf_cnpj: updatedOwner.cpf_cnpj,
        phone: updatedOwner.phone,
        email: updatedOwner.email,
        unit: dataProvider?.unitId ?? params?.id
      })
        .then(response => {
          enqueueSnackbar('Proprietário atualizado', { variant: 'success' })
          getOwner()
          setHandleId(null)
          handleEdit()
          setIsEditing(false)
          setError(null)
        })
        .catch(error => {
          setError(error.response.data.phone)
          enqueueSnackbar('Erro ao atualizar proprietário', { variant: 'error' })
          console.log(error)
        })
    }
  }

  function deleteOwner (ownerId) {
    api.delete(`/unit_owner/${ownerId}/`)
      .then(response => {
        enqueueSnackbar('Proprietário deletado', { variant: 'success' })
        setDataProvider(prevState => ({
          ...prevState,
          deleteOwner: !dataProvider?.deleteOwner
        }))
        getOwner()
      })
      .catch(error => {
        enqueueSnackbar('Erro ao excluir cobrança', { variant: 'error' })
        console.log(error)
      })
  }

  return (
    <Grid item xs={12}>
      {!isLoading ?
        <Card>
          <CardHeader
            avatar={<Avatar sx={{bgcolor: 'primary.main'}}><Person  /></Avatar>}

            title={'Proprietários'}/>
          <Divider sx={{mb: '.3rem'}}/>

          <CardContent>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <List>
                {data?.map((entry, index) => (
                  <ListItem key={index} disableGutters>
                    <DataInput
                      index={index}
                      name={entry.name}
                      cpf_cnpj={entry.cpf_cnpj}
                      id={entry.id}
                      phone={entry.phone}
                      email={entry.email}
                      onChange={handleDataChange}
                      onRemove={handleDataRemove}
                      updateOwner={updateOwner}
                      createOwner={createOwner}
                      error={error}
                      setError={setError}
                      handleId={handleId}
                      setHandleId={setHandleId}
                      edit={edit}
                      setEdit={setEdit}
                      handleEdit={handleEdit}
                      getOwner={getOwner}
                      deleteOwner={deleteOwner}
                    />
                  </ListItem>
                ))}
              </List>
              <Button
                size={'small'}
                variant="outlined"
                startIcon={<Add/>}
                onClick={handleAddData}>
                Adicionar
              </Button>
            </Box>
          </CardContent>
        </Card>
        :
        <Skeleton
          height={300}
          width={'100%'}
          variant='rounded'
        />
      }
    </Grid>
  );
}

export default Owner