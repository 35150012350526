import {
    Box,
    Fade,
    FormControl,
    FormControlLabel, FormHelperText,
    FormLabel,
    InputAdornment,
    Radio,
    RadioGroup, TextField,
} from "@mui/material";
import GlobalSwitch from "../../../../components/Shared/GlobalSwitch";
import SimpleText from "../../../../components/Shared/SimpleText";
import React, {useEffect} from "react";
import Divider from "@mui/material/Divider";
import SimpleSelector from "../../../../components/Shared/SimpleSelector";
import MenuItem from "@mui/material/MenuItem";

const SlipOptionsInputs =
  (
    {
      reqData,
      setReqData,
      errorHandling,
      handleBankFineChange,
      bankFine,
      bankInterest,
      handleBankInterestChange,
      error


    }
  ) => {

      useEffect(() => {
          if (!reqData?.generate_discount) {
              setReqData(prevReqData => ({
                  ...prevReqData,
                  bankslip_discount_day: null,
                  bankslip_discount_value: null
              }));
          }
      }, [reqData?.generate_discount]);

      useEffect(() => {
          if (!reqData?.fine_and_interest) {
              setReqData(prevReqData => ({
                  ...prevReqData,
                  bankslip_fine_percent: null,
                  bankslip_fine_value: null,
                  bankslip_interest_percent: null,
                  bankslip_interest_value: null
              }));
          }
      }, [reqData?.fine_and_interest]);

      const contractReferenceMode = [
          {
              value: 'none', label: 'Sem Referência'
          },
          {
              value: 'month', label: 'Mês de Referência'
          },
          {
              value: 'range', label: 'Referência Entre Datas'
          },
          {
              value: 'payment', label: 'Mês de Competência'
          }
      ]

      const contractSendEmailDaily = [
          {
              value: 'NOT_SEND', label: 'Não Enviar'
          },
          {
              value: 'SEND_ONE_DAYS_EARLY', label: 'Enviar Um Dia Antes do Vencimento'
          },
          {
              value: 'SEND_THREE_DAYS_EARLY', label: 'Enviar Três Dias Antes do Vencimento'
          },
          {
              value: 'SEND_FIVE_DAYS_EARLY', label: 'Enviar Cinco Dias Antes do Vencimento'
          },
          {
              value: 'SEND_EVERY_DAYS_EARLY', label: 'Enviar todos os Dia após a Geração'
          }
      ]

      return (
    <>
      <Box
        sx={{
          mt: '1rem',
          display: 'flex',
          flexDirection: 'column',
          // paddingX: '2rem'
        }}
      >
          <Box
              sx={{mb: '0.6rem'}}
          >

              <FormLabel>
                  Vencimento
              </FormLabel>

          </Box>

          <Box
              sx={{
                  // paddingX: '2rem',
                  mb: '1rem',
              }}
          >
              <SimpleText
                  label={'Dia'}
                  state={reqData}
                  setState={setReqData}
                  keyName={'bankslip_due_day'}
                  error={error}
                  onlyNumbers
              />
          </Box>

          <Divider sx={{mb: '.3rem'}}/>

        <GlobalSwitch
          label={'Geração Automática'}
          state={reqData}
          setState={setReqData}
          keyName={'generate_bankslip'}
          styles={{mb: '0.3rem'}}
        />
          <Fade
              in={reqData?.generate_bankslip}
              mountOnEnter
              unmountOnExit
              timeout={300}
          >
              <Box
                  sx={{
                      // paddingX: '2rem',
                      mb: '1rem'
                  }}
              >
                  <SimpleText
                      required
                      label={'Gerar dia'}
                      state={reqData}
                      setState={setReqData}
                      keyName={'generate_bankslip_day'}
                      onlyNumbers
                      directError={errorHandling()}

                  />
              </Box>
          </Fade>

          <GlobalSwitch
              label={'Desconto por Antecipação'}
              state={reqData}
              setState={setReqData}
              keyName={'generate_discount'}
              styles={{mb: '0.3rem'}}
          />
          <Fade
              in={reqData?.generate_discount}
              mountOnEnter
              unmountOnExit
              timeout={300}
          >
              <Box
                  sx={{
                      // paddingX: '2rem',
                      mb: '1rem'
                  }}
              >
                  <SimpleText
                      required
                      label={'Desconto até o dia'}
                      state={reqData}
                      setState={setReqData}
                      keyName={'bankslip_discount_day'}
                      onlyNumbers
                      directError={errorHandling()}

                  />
              </Box>
          </Fade>
          <Fade
              in={reqData?.generate_discount}
              mountOnEnter
              unmountOnExit
              timeout={300}
          >
              <Box
                  sx={{
                      // paddingX: '2rem',
                      mb: '1rem'
                  }}
              >
                  <SimpleText
                      required
                      label={'Valor do Desconto'}
                      state={reqData}
                      setState={setReqData}
                      keyName={'bankslip_discount_value'}
                      money={reqData.bankslip_discount_value}
                      directError={errorHandling()}

                  />
              </Box>
          </Fade>

          <GlobalSwitch
              label={'Gerar Multa e Juros'}
              state={reqData}
              setState={setReqData}
              keyName={'fine_and_interest'}
              styles={{mb: '0.3rem'}}
          />
          <Fade
              in={reqData?.fine_and_interest}
              mountOnEnter
              unmountOnExit
              timeout={300}
          >
              <FormControl>
                  <FormLabel>Multa</FormLabel>

                  <RadioGroup
                      row
                      onChange={handleBankFineChange}
                      value={bankFine}
                  >
                      <FormControlLabel value="value" control={<Radio size={'small'} />} label="Fixo" />
                      <FormControlLabel value="percent" control={<Radio size={'small'} />} label="Percentual" />
                  </RadioGroup>
              </FormControl>
          </Fade>
              <Fade
                  in={reqData?.fine_and_interest}
                  mountOnEnter
                  unmountOnExit
                  timeout={300}
              >
              <Box
                  sx={{
                      // paddingX: '2rem',
                      mb: '1rem',
                  }}
              >
                  <SimpleText
                      label={'Valor'}
                      state={reqData}
                      setState={setReqData}
                      keyName={bankFine === 'value' ? 'bankslip_fine_value' : 'bankslip_fine_percent' }
                      onlyNumbers={bankFine === 'percent' && true}
                      money={bankFine === 'value' && true}
                      InputProps={{
                          ...(bankFine === 'percent' && {
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }),
                      }}
                  />
              </Box>
          </Fade>
          <Fade
              in={reqData?.fine_and_interest}
              mountOnEnter
              unmountOnExit
              timeout={300}
          >
              <FormControl>
                  <FormLabel >Juros</FormLabel>
                  <RadioGroup
                      row
                      value={bankInterest}
                      onChange={handleBankInterestChange }
                  >
                      <FormControlLabel value="value" control={<Radio size={'small'}/>} label="Fixo" />
                      <FormControlLabel value="percent" control={<Radio size={'small'}/>} label="Percentual" />
                  </RadioGroup>
              </FormControl>
          </Fade>
          <Fade
              in={reqData?.fine_and_interest}
              mountOnEnter
              unmountOnExit
              timeout={300}
          >
              <Box
                  sx={{
                      // paddingX: '2rem',
                      mb: '1rem',
                  }}
              >
                  <SimpleText
                      label={'Valor'}
                      state={reqData}
                      setState={setReqData}
                      keyName={bankInterest === 'value' ? 'bankslip_interest_value' : 'bankslip_interest_percent' }
                      onlyNumbers={bankInterest === 'percent' && true}
                      money={bankInterest === 'value' && true}
                      InputProps={{
                          ...(bankInterest === 'percent' && {
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }),
                      }}
                  />
              </Box>
          </Fade>

          <FormHelperText sx={{mb: '.5rem'}}>
              O campo abaixo serve exclusivamente para definir a forma de mostrar a referência no boleto </FormHelperText>
          <TextField
              label={'Modo De Referência'}
              select
              fullWidth
              keyName={'reference_mode'}
              size={"small"}
              value={reqData?.reference_mode?.value}
              onChange={(e) => {
                  const selectedMode = contractReferenceMode.find(option => option.value === e.target.value)
                  setReqData(prevState => ({...prevState, reference_mode: selectedMode}))
              }}
          >
              {contractReferenceMode.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                      {option.label}
                  </MenuItem>
              ))}
          </TextField>
          <GlobalSwitch
          label={'Enviar para o e-mail?'}
          state={reqData}
          setState={setReqData}
          keyName={'send_email'}
          styles={{mb: '0.3rem'}}
        />
          {reqData?.send_email &&
          <FormHelperText sx={{mb: '.5rem'}}>
              O campo abaixo serve exclusivamente para definir quantos dias antes será enviado notificação diária do boleto antes do vencimento </FormHelperText>
          }
          {reqData?.send_email &&
          <TextField
              label={'Enviar Email'}
              select
              fullWidth
              keyName={'send_email_daily'}
              size={"small"}
              value={reqData?.send_email_daily?.value}
              onChange={(e) => {
                  const selectedMode = contractSendEmailDaily.find(option => option.value === e.target.value)
                  setReqData(prevState => ({...prevState, send_email_daily: selectedMode}))
              }}
          >
              {contractSendEmailDaily.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                      {option.label}
                  </MenuItem>
              ))}
          </TextField>
      }
          <GlobalSwitch
              label={'Enviar para o whatsapp?'}
              disabled={!reqData.whatsapp_send_message}
              state={reqData}
              setState={setReqData}
              keyName={'send_whatsapp'}
              styles={{mb: '0.3rem'}}
          />
      </Box>


    </>
  )
}

export default SlipOptionsInputs
