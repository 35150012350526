import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import {Box} from "@mui/material";
import moment from "moment";

const BailInsuranceRead =
  ({
     edit,
     guarantee,
     guaranteeData,
  }) => {

  return (
    <>
      {guarantee === 'bail_insurance' && !edit &&

        <Box
          sx={{
            // paddingX: '1rem'

          }}
        >
          {guaranteeData?.insurance_policy_number &&
            <SimpleTypography
              label={'Número de Apólice/Contrato'}
              directValue={guaranteeData?.insurance_policy_number}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

          {guaranteeData?.insurance_company &&
            <SimpleTypography
              label={'Companhia'}
              directValue={guaranteeData?.insurance_company}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }


          {guaranteeData?.due_date &&
            <SimpleTypography
              label={'Data de vencimento'}
              directValue={moment(guaranteeData?.due_date).format('DD/MM/YYYY')}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }
        </Box>
      }
    </>
  )
}

export default BailInsuranceRead