import {
  Avatar, Box, Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Tooltip
} from "@mui/material";
import {Close, Receipt, Update} from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import InvoiceIcon from "../../../components/Invoice/InvoiceIcon";
import moment from "moment";
import NumberFormatB3 from "../../../components/NumberFormat/NumberFormat";
import AnimationGlobal from "../../../components/Shared/animations/AnimationGlobal";
import notFound from "../../../assets/animations/emptyBox.json";
import success from "../../../assets/animations/congratulation.json";
import { api } from "../../../services/Main/Api";
import {useNavigate, useParams} from "react-router-dom";
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import Context from "../../../contexts/Context";
import SimpleSelector from "../../../components/Shared/SimpleSelector";
import InvoiceDetail from "../Invoice/InvoiceDetail";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import SimpleTypography from "../../../components/Shared/SimpleTypography";
import Masks from "../../../components/Shared/Masks";

const ContractInvoices = (props) => {

  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////

  const { filters, setFilters, dataProvider, setDataProvider, screenRefresh, setScreenRefresh, refreshScreen, setRefreshScreen } = useContext(Context)
  const { refreshSlip } = dataProvider || {}
  const { unitId, status } = props || {}
  const params = useParams()
  const { id } = params

  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [openPreview, setOpenPreview] = useState(false)
  const [dataPreview, setDataPreview] = useState(null)

  const reference = data?.results && data?.results.length > 0 ?
      data?.results[0].reference_mode_company.value : null;

  useEffect(function initialFilters(){
    setFilters(prevState => ({
      ...prevState,
      page_size: 10,
      rent_contract : id,
      status: 'Todos'
    }))

  },[])


  useEffect(function whenLoad() {
    if(filters?.rent_contract ) {
      const { status } = filters || {}

      const formatedFilters = {
        ...filters,
        status: status === 'Todos' ? '' :  status
      }

      api.get(`/invoice/rent-contract/`, {params: formatedFilters})
        .then(response => {
          setData(response?.data)
          filters?.status === 'Todos' && setDataProvider(prevState => ({
            ...prevState,
            hasInvoice: response?.data?.results?.length
          }))
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

  }, [filters?.rent_contract , filters?.status, refreshSlip])

  const statusInvoice = {
    "Todos" : 'Todos',
    "Pago": 'paid',
    "Pendente": 'pending',
    "Atrasado": 'overdue',
    "Aberto": 'open',
    "Isentos": 'exemption',
  };

  const statusArray = Object.keys(statusInvoice).map((invoice) => {
    return { type: invoice, value: statusInvoice[invoice] };
  });

  function getPreviewInvoice () {
    if(unitId && openPreview === true){
      api.get(`/invoice/preview/?unit_id=${unitId?.id}`)
        .then( response => {
          setDataPreview(response.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  useEffect(function getPreview(){
    getPreviewInvoice()
  }, [unitId?.id, openPreview])

  useEffect(() => {
    if (screenRefresh) {
      const { status } = filters || {}

      const formatedFilters = {
        ...filters,
        status: status === 'Todos' ? '' :  status
      }

      api.get(`/invoice/rent-contract/`, {params: formatedFilters})
          .then(response => {
            setData(response?.data)
            filters?.status === 'Todos' && setDataProvider(prevState => ({
              ...prevState,
              hasInvoice: response?.data?.results?.length
            }))
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            setIsLoading(false)
            setScreenRefresh(false);
            setRefreshScreen(null);

          })
  }
  }, [refreshScreen]);

  return (
    <>
    {!isLoading ?
      <Card>
        <CardHeader
          avatar={
            <Avatar
              sx={{
                bgcolor: 'primary.main'
              }}>
              <Receipt/>
            </Avatar>}
          title={'Faturas - Contrato'}
        />
        <Divider sx={{ mb: '.3rem' }}/>
        <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start'
          }}
        >
         </Box>
          {data?.results?.length ?
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    { reference === 'payment' ?
                    <TableCell sx={{ color: 'primary.main' }} align={'center'}>
                      Competência
                    </TableCell>
                      :
                    <TableCell sx={{ color: 'primary.main' }} align={'center'}>
                      Referência
                    </TableCell>
                    }
                    <TableCell align={'center'} sx={{ color: 'primary.main' }}>
                      Vencimento
                    </TableCell>
                    <TableCell sx={{ color: 'primary.main' }} align={'center'}>
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.results?.map(invoice =>
                    <TableRow
                      key={invoice.number}
                      hover onClick={() => { setFilters(prevState => ({...prevState, modalInvoice: true, invoiceId: invoice.id}))}}
                      sx={{ 'cursor': 'pointer', }}
                    >
                      { reference === 'payment' ?
                      <TableCell>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                          <InvoiceIcon variant={invoice?.status.key} key={invoice.id} styles={{mr: '0', mb: '0.3rem'}}/>
                          {invoice.payment_month_reference}
                        </Box>
                      </TableCell>
                          :
                      <TableCell>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                          <InvoiceIcon variant={invoice?.status.key} key={invoice.id} styles={{mr: '0', mb: '0.3rem'}}/>
                          {invoice.reference_month}
                        </Box>
                      </TableCell>
                      }
                      <TableCell align={'center'}>
                        {invoice.due_date
                          ? moment(invoice.due_date).format('DD/MM/YYYY')
                          : '-'}
                      </TableCell>
                      <TableCell align={'center'} sx={{ fontWeight: 'bold' }}>
                        <NumberFormatB3 value={invoice.total}/>
                      </TableCell>
                    </TableRow>
                  )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </>
          :
          <AnimationGlobal
            animation={filters?.status === 'overdue' ? success : notFound   }
            text={filters?.status === 'overdue' ? 'Sem inadimplências' : 'Nenhum resultado'}
            size={'300px'}
            fontSize={'24px'}
          />
          }

        </CardContent>
      </Card>
      :
        <Skeleton
          height={400}
          width={'100%'}
          variant='rounded'
        />
      }
      {filters?.modalInvoice &&
        <InvoiceDetail/>
      }
      <SimpleDialog
        title={'Previsão - 12 meses'}
        titleIcon={<Update />}
        open={openPreview}
        removeConfirm
        onClickCancel={() => {
          setOpenPreview(false)
        }}
        cancelText={'Fechar'}
        dialogContent={
        <>
          { dataPreview?.map(preview =>
            <Box
              sx={{
                bgcolor: 'neutral.main',
                p: '1rem',
                borderRadius: '1rem',
                mb: '1rem',
              }}
            >
              <SimpleTypography
                label={moment(`${preview.year}-${preview.month}`, 'YYYY-M')
                  .format('MMMM YYYY')
                  .replace(/^\w/, (char) => char.toUpperCase())}
                labelStyles={{
                  fontWeight: 'bold'
                }}
                boxStyles={{
                  mb: '0.5rem'
                }}
              />
              {preview.fees.map(fee =>
                <SimpleTypography
                  label={fee.description ?? <Close sx={{color: 'error.main'}}/>}
                  labelStyles={{
                    fontSize: '0.9rem'
                  }}
                  styles={{
                    fontSize: '0.9rem'
                  }}
                  directValue={Masks.money(fee.total.toString())}
                  boxStyles={{
                    // paddingX: '2rem',
                    mb: '1rem'
                  }}
                 />
                )
              }


              <SimpleTypography
                label={'Total'}
                labelStyles={{
                  fontWeight: 'bold',
                  color: 'primary.main'
                }}
                directValue={Masks?.money(preview.total.toString())}
                boxStyles={{
                  mb: '0.5rem'
                }}
              />

            </Box>



            )
          }
        </>


        }
        />

    </>
  )
}

export default ContractInvoices