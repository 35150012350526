import React, {
  useEffect,
  useState
} from 'react'
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Input,
  Skeleton,
  TextField,
} from '@mui/material'
import {
  DeleteOutlined,
  DomainOutlined,
  SaveOutlined,
} from '@mui/icons-material'
import { api } from '../../../services/Main/Api'
import { getCondoId } from '../../../services/Main/AuthStorage'
import { useSnackbar } from 'notistack'
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import {LoadingButton} from "@mui/lab";

const BlockFormSet = () => {
  const { enqueueSnackbar } = useSnackbar()

  const condo = getCondoId()
  const [blocks, setBlocks] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  function addBlock (e, i) {
    let data = [...blocks]
    data[i][e.target.name] = e.target.value
    setBlocks(data)
  }

  function removeBlock (e, i){
    let data = [...blocks]
    const block = data[i]
    data.splice(i, 1)
    setBlocks(data)
    if (block.id !== undefined) {
      api.delete(`block/${block.id}/`).then(r => {})
      enqueueSnackbar('Blocos atualizados com sucesso!', { variant: 'success' })
    }
  }

  async function getblocks () {
    let data = [...blocks]
    await api.get('block/', {params: {condo_id: getCondoId()}})
      .then(r => {
        setIsLoading(false)
        if (r.status === 200) {
          r?.data.results.map((item, i) => {
            data[i] = {
              id: item.id,
              name: item.identifier
            }
          })
        }
      }).catch(r => {console.log(r)})
    setBlocks(data)
  }

  async function handleSubmit (e) {
    e.preventDefault();

    if (blocks.length > 0) {
      setSubmitting(true);

      for (const item of blocks) {
        try {
          if (item.id === '' || item.id === undefined) {
            await api.post('block/', {
              identifier: item.name,
              condo: condo
            });
          } else {
            await api.put(`block/${item.id}`, {
              identifier: item.name,
              condo: condo
            });
          }
          await getblocks();
          setSubmitting(false);
        } catch (reason) {
          setSubmitting(false);
          console.error(reason);
        }
      }

      enqueueSnackbar('Blocos atualizados com sucesso!', { variant: 'success' });
    }
  }

  useEffect(() => {
    getblocks()
  }, [])


  return (
    <Box component={'form'} onSubmit={handleSubmit}>
      <Card >

        {!isLoading ?
          <>
            <TitleBtnList
              title={'Blocos'}
              buttonText={'Criar Bloco'}
              onClick={() => setBlocks([...blocks, {name: ''}])}
              icon={<DomainOutlined/>}
              boxStyles={{
                p: '1rem'
              }}
            />

            <CardContent style={{paddingBottom: 0}}>
              {blocks.map((input, i) => (
                <Box display={'flex'} sx={{mb: 2}} key={`block-input-${i}`}>
                  <Input type={'hidden'} id={'id'} name={'id'} value={input.id || ''}/>
                  <TextField
                    required
                    fullWidth
                    size={'small'}
                    id={'name'}
                    name={'name'}
                    label={'Bloco'}
                    value={input.name}
                    onChange={(e) => addBlock(e, i)}
                  />
                  <IconButton
                    sx={{ml: 2}}
                    onClick={(e) => removeBlock(e, i)}>
                    <DeleteOutlined color={'primary'}/>
                  </IconButton>
                </Box>
              ))}
              <Box sx={{mt: 2, mb: 1}} display={'flex'} justifyContent={'center'}>
                <LoadingButton
                  loading={!!submitting}
                  startIcon={<SaveOutlined/>}
                  sx={{m: '1rem'}}
                  variant={'contained'}
                  type={'submit'}
                  color={'primary'}>
                  {'Salvar'}
                </LoadingButton>
              </Box>
            </CardContent>
          </>
          :
          <Skeleton
            variant="rounded"
            height={700}
            animation="pulse"
          />
        }
      </Card>
    </Box>
  )
}

export default BlockFormSet
