import {
    Alert,
    Avatar, Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Fade,
    FormHelperText, Grid,
    Skeleton, TextField, Typography,
    useMediaQuery
} from "@mui/material";
import {AccountBalance, Close, Forward, Receipt} from "@mui/icons-material";
import Divider from "@mui/material/Divider";

import React, {useState} from "react";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import SimpleTypography from "../../../components/Shared/SimpleTypography";
import Masks from "../../../components/Shared/Masks";

const RentalChargeDetail = ({charge}) => {

    const renderChargeType = () => {
        let chargeType, chargeDetail;

        if (charge.recurring) {
            chargeType = 'Parcela:';
            chargeDetail = 'Recorrente';
        } else if (charge?.installment) {
            chargeType = 'Parcela:';
            chargeDetail = `${charge.installment_position}/${charge.installment_total_number}`;
        } else {
            chargeType = 'Parcela:';
            chargeDetail = 'Parcela Unica';
        }
        return (
            <Box>
                <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
                    {chargeType}
                </Typography>
                <Typography variant="body1">
                    {chargeDetail}
                </Typography>
            </Box>
        );
    };

    console.log(charge, '5')

    return (
        <>
            <Fade
                in={!!charge}
                mountOnEnter
                unmountOnExit
                timeout={300}
            >

                <CardContent
                    style={{
                        maxWidth: '100%',
                    }}
                >
                    <Typography variant="h6" component="div" sx={{mb: 2}}>
                        Detalhes do Cobrança
                    </Typography>
                    <Grid sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        maxWidth: '900px',
                        mb: 3
                    }}>
                        <Grid>
                            <Box>
                                <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
                                    {`Categoria:`}
                                </Typography>
                                <Typography variant="body1">
                                    {charge?.category}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
                                    {`Valor:`}
                                </Typography>
                                <Typography variant="body1">
                                    {Masks.money(charge.value.toString())}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid>
                            {renderChargeType()}
                            <Box>
                                <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
                                    {`Referencia:`}
                                </Typography>
                                <Typography variant="body1">
                                    {charge ? moment(`${charge.month}-${charge.year}`, 'MM-YYYY').format('MMMM/YYYY') : "-"}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Fade>
        </>
    )

}

export default RentalChargeDetail