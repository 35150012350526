import React from "react";
import {api} from "../../../services/Main/Api";
import {Box, Typography} from "@mui/material";
import {
  AttachMoneyOutlined,
  CircleOutlined,
  CleaningServicesOutlined,
  HomeRepairServiceOutlined,
  ManageAccountsOutlined,
  OpacityOutlined,
  PowerOutlined,
  PropaneTankOutlined,
  SavingsOutlined,
  Water
} from "@mui/icons-material";
import {getCondoId} from "../../../services/Main/AuthStorage";

const condo = getCondoId()


export const getCategories = (setCategoryList, condoIdLogin) => {
  api.get(`category/?condo_id=${condo ?? condoIdLogin}`).then(response => {
    setCategoryList(response?.data?.results?.map(element => ({
          [element?.slug]:
            <Box
              sx={{
                display: 'flex',
              }}
            >
              {
                element?.slug === 'WATER_READING' ? <Water sx={{ color: 'secondary.light'}}/>
                  :
                element?.slug === 'WATER' ? <OpacityOutlined sx={{ color: 'secondary.light'}}/>
                  :
                element?.slug === 'ENERGY' ?  <PowerOutlined sx={{ color: 'secondary.light'}}/>
                  :
                element?.slug === 'RESERVE_FUND' ? <SavingsOutlined sx={{ color: 'secondary.light'}}/>
                  :
                element?.slug === 'GAS' ? <PropaneTankOutlined sx={{ color: 'secondary.light'}}/>
                  :
                element?.slug === 'ADMINISTRATION_FEE' ? <ManageAccountsOutlined sx={{ color: 'secondary.light'}}/>
                  :
                element?.slug === 'CLEANING_FEE' ? <CleaningServicesOutlined sx={{ color: 'secondary.light'}}/>
                  :
                element?.slug === 'DISCOUNT' ? <AttachMoneyOutlined sx={{ color: 'secondary.light'}}/>
                  :
                element?.slug === 'SERVICE_FEE' ? <HomeRepairServiceOutlined sx={{ color: 'secondary.light'}}/>
                  :
                  <CircleOutlined sx={{ color: 'secondary.light'}}/>
              }
              <Typography
                ml={1}

                fontWeight="bold"
              >
                {element?.description}
              </Typography>
            </Box>,
          })
        )
      )
    }).catch(reason => {
      console.log(reason)
    })
  }

export const getUnits = (setUnits) => {
  api.get('unit/')
    .then(r => {
      if (r?.status === 200) {
        setUnits(r?.data.results)
      }
  })
    .catch(r => {
    console.log(r?.data)
  })
}

export const gasWaterValue = (reqData, setGasWaterTotalValue) => {
  const data = {
    condo: condo,
    previous_reading_amount: reqData?.previous_reading_amount?.replace(/,/g,'.'),
    current_reading_amount: reqData?.current_reading_amount?.replace(/,/g,'.'),
  }

  if (reqData?.current_reading_amount && reqData?.category === 'GAS') {
    api.post('expense/gas/preview/', data)
      .then(r => {
        setGasWaterTotalValue(r.data.total)
      }).catch(error => {
      console.log(error.response.data);
    })
  }

  if (reqData?.current_reading_amount && reqData?.category === 'WATER') {
    api.post('expense/water/preview/', data)
      .then(r => {
        console.log(r)

        setGasWaterTotalValue(r.data.total)
      }).catch(error => {
      console.log(error.response.data);
    })
  }
}

export const gasWaterPreviousReading = (reqData, setPreviousData) => {
  if (reqData?.category === 'GAS' && reqData?.unit){
    api.get(`/expense/last_gas_read/${reqData?.unit}/`)
      .then(response => {
        setPreviousData((prevState) => ({...prevState, previousReading: response.data.last_reading_amount, previousDate: response.data.last_reading_date}))
      })
      .catch(error => {
        console.log(error)
      })
  }
  if (reqData?.category === 'WATER' && reqData?.unit){
    api.get(`/expense/last_water_read/${reqData?.unit}/`)
      .then(response => {
        setPreviousData((prevState) => ({...prevState, previousReading: response.data.last_reading_amount, previousDate: response.data.last_reading_date}))
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const expense = (setGasWaterRequired, setReqData, setShowUnit, setShowGas, params, filters) => {

  let url = `expense/${params.id}`
  console.log(filters?.expenseCategory)
  if (filters?.expenseCategory === 'GAS') {
    url = `expense/gas/${params.id}`
    setGasWaterRequired(true)
  }
  if (filters?.expenseCategory === 'WATER_READING') {
    url = `expense/water/${params.id}`
    setGasWaterRequired(true)
  }
    api.get(url).then(response => {
      setReqData(response.data)
      // setReqData({...response?.data, division_pattern: response.data.division_pattern.key, category: response.data.category.key})
      // setReqData((prevState) => ({...prevState, }))
      // setReqData((prevState) => ({...prevState, }))
      // setReqData((prevState) => ({...prevState, billing_month: moment(new Date()).format(`YYYY-${response?.data.billing_month.toString()}-DD`)  }))

      if (response?.data.division_pattern.key === 'UNIT') {
        setShowUnit(true)
      }
      if (response?.data.category.key === 'GAS') {
        setShowGas(true)
      }
    }).catch(reason => {
      console.log(reason)
    })
}

export const getDocuments = (setDocuments, params, setDropLoad, filters) => {
    let url = `expense/${params?.id}`
    if (filters?.expenseCategory === 'GAS') {
      url = `expense/gas/${params.id}`
    }
     if (filters?.expenseCategory === 'WATER_READING') {
      url = `expense/water/${params.id}`
    }
    if (params?.id) {
      api.get(url)
        .then(response => {
          setDocuments(response?.data?.documents)
        }).catch(error =>{
        console.log(error)
      }).finally(() => {
        setDropLoad(false)
      })
    }

}