import TitleBtnList from "../../../components/Shared/TitleBtnList";
import React, {useContext, useEffect, useState} from "react";
import Context from "../../../contexts/Context";
import DeliveryForm from "./DeliveryForm";
import DeliveryTableHead from "./DeliveryTableHead";
import DeliveryTableBody from "./DeliveryTableBody";
import {Backdrop, Card, CircularProgress, Container, Skeleton, Table, TableContainer} from "@mui/material";
import {api} from "../../../services/Main/Api";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import VisitorForm from "./VisitorForm";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import VisitorTableBody from "./VisitorTableBody";
import VisitorTableHead from "./VisitorTableHead";
import {Add, AddCircleOutlined, Search} from "@mui/icons-material";
import SearchField from "../../../components/Shared/SearchField";
import SearchInput from "../../../components/Filters/SearchInput";
import {getCondoId} from "../../../services/Main/AuthStorage";
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import {Helmet} from "react-helmet";

const ManagementList = () => {
  const { getUnitOptions, dataProvider, setDataProvider } = useContext(Context)
  useEffect(function initialFilters(){
    getUnitOptions()
  },[])

  const [openDelivery, setOpenDelivery] = useState(false)
  const [openVisitor, setOpenVisitor] = useState(false)
  const [data, setData] = useState(null)
  const [loadingTable, setLoadingTable] = useState(true)
  const [whichTable, setWhichTable] = useState({
    table: 'delivery',
    loadingDeliveryTable: false,
    loadingVisitorTable: false
  })
  const [filters, setFilters] = useState(
    {
      page_size: 50,
      condo_id: +getCondoId(),
      search: '',
      isCreate: false,
      isUpdate: false,
      loadDelivery: false,
      loadVisitor: false,
      requestDone: false,
    }
  )

  useEffect(function whenPageLoad(){
    whichTable?.table === 'delivery' ?
      api.get(`management/delivery/`, {params: filters} )
        .then(response => {
          setData(response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() =>{
          setLoadingTable(false)
        })
    :
    whichTable?.table === 'visitor' &&
      api.get(`management/visitor/`, {params: filters})
        .then(response => {
          setData(response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() =>{
          setLoadingTable(false)
        })



  },[whichTable?.table, dataProvider?.requestDone, filters?.search, filters?.ordering])

  // console.log("search" in filters)


  return (
    <>
      <Helmet>
        <title>Portaria - Bloco B3</title>
      </Helmet>
      <Container
        maxWidth={false}
      >

      {/*<TitleBtnList*/}
      {/*  title={'Portaria'}*/}
      {/*  extraButton*/}
      {/*  buttonText={'Cadastrar entrega'}*/}
      {/*  onClick={() => {*/}
      {/*    setOpenDelivery(true)*/}
      {/*    setFilters(prevState => ({...prevState, isCreate: true, isUpdate: false}))*/}
      {/*  }}*/}
      {/*  buttonTextExtra={'Cadastrar visitante'}*/}
      {/*  onClickExtra={() => {*/}
      {/*    setOpenVisitor(true)*/}
      {/*    setFilters(prevState => ({...prevState, isCreate: true, isUpdate: false}))*/}
      {/*  }}*/}
      {/*/>*/}
        { whichTable?.table === 'delivery' &&
          <TitleBtnList
            title={'Portaria'}
            buttonText={'Cadastrar entrega'}
            icon={<AddCircleOutlined/>}
            onClick={() => {
              setOpenDelivery(true)
              setFilters(prevState => ({...prevState, isCreate: true, isUpdate: false}))
              setDataProvider(prevState => ({...prevState, isCreate: true, isUpdate: false}))
            }}
          />
        }

        { whichTable?.table === 'visitor' &&
          <TitleBtnList
            title={'Portaria'}
            buttonText={'Cadastrar visistante'}
            icon={<AddCircleOutlined/>}
            onClick={() => {
              setOpenVisitor(true)
              setFilters(prevState => ({...prevState, isCreate: true, isUpdate: false}))
              setDataProvider(prevState => ({...prevState, isCreate: true, isUpdate: false}))
            }}
          />
        }


      <DeliveryForm
        openDelivery={openDelivery}
        setOpenDelivery={setOpenDelivery}
      />
      <VisitorForm
        openVisitor={openVisitor}
        setOpenVisitor={setOpenVisitor}
      />
        <Card>
          {data?.results ?
            <>
            <ToggleButtonGroup
              fullWidth
              exclusive
              value={whichTable?.table}
              color={'primary'}
              onChange={(e, value) => {
              setData(null)
              setWhichTable(prevState => ({
                ...prevState,
                table: value
              }))
            }}
            sx={{mb: '1rem'}}
          >
            <ToggleButton
              value={'delivery'}
              selected={whichTable?.table === 'delivery'}
              disabled={whichTable?.table === 'delivery' && whichTable?.table !== undefined}
            >
              Entregas
            </ToggleButton>
            <ToggleButton
              value={'visitor'}
              selected={whichTable?.table === 'visitor'}
              disabled={whichTable?.table === 'visitor' && whichTable?.table !== undefined}
            >
              Visitantes
            </ToggleButton>
          </ToggleButtonGroup>

          <SearchInput
            styles={{p: '1rem'}}
          />
          {/*<SearchField*/}
          {/*  label={'Procure pelo nome ou unidade'}*/}
          {/*  state={filters}*/}
          {/*  setState={setFilters}*/}
          {/*  keyName={'search'}*/}
          {/*/>*/}
        <>
        {whichTable?.table === 'delivery' &&
        <>
        {data ?
          <TableContainer>
            <Table>
              <DeliveryTableHead
                filters={filters}
                setFilters={setFilters}
              />
              <DeliveryTableBody
                state={data}
                setOpenDelivery={setOpenDelivery}
              />
            </Table>
          </TableContainer>
          :
          <NoResultsAnimation />
        }
        </>
        }

          {whichTable?.table === 'visitor' &&
            <>
              {data ?
                <TableContainer>
                  <Table>
                    <VisitorTableHead
                      filters={filters}
                      setFilters={setFilters}
                    />
                    <VisitorTableBody
                      state={data}
                      setOpenVisitor={setOpenVisitor}
                    />
                  </Table>
                </TableContainer>
                :
                <NoResultsAnimation />
              }
            </>
          }
        </>
            </>
          :
          <Skeleton
            variant="rounded"
            height={500}
            animation="pulse"
            sx={{
              with: '100%',
            }}
          />
        }
        </Card>
        <PaginationGlobal
          state={data}
          filters={filters}
          setFilters={setFilters}
        />
       </Container>




    </>
  )
}

export default ManagementList