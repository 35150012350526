import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '../../../../services/Main/Api'
import {
  Alert, AlertTitle,
  Avatar,
  Backdrop,
  Box,
  Button,
  Card, CardActionArea, CardActions,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Helmet } from 'react-helmet'
import { useSnackbar } from 'notistack'
import Context from '../../../../contexts/Context'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment/moment'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import {
  AccountBalance,
  Alarm,
  Article,
  CancelOutlined,
  ContactMailOutlined, Download, PictureAsPdf,
  PixOutlined,
  Receipt
} from '@mui/icons-material'
import Masks from '../../../../components/Shared/Masks'
import { MobileDatePicker } from '@mui/x-date-pickers'
import useClipboard from '../../../../hooks/useClipboard'

const CustomerInvoiceDetail = () => {
  const { filters, setFilters, loadingModal, setLoadingModal } = useContext(Context)

  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState()
  const [openDialog, setOpenDialog] = useState(false)
  const [dateInput, setDateInput] = useState(moment().format("DD/MM/YYYY"))
  const [valueFormated, setValueFormated] = useState(0)
  const [value, setValue] = useState(0)
  const [isSaving, setIsSaving] = useState(false)


  const sizeMatch = useMediaQuery('@media (min-width:600px)')

  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()

  useEffect(() => {

    getSignedRecipientTransferId()
    // getCondo()
    window.scrollTo(0, 0)


  }, [])

  function handleDialogClose() {
    setOpenDialog(false)
  }





  const getSignedRecipientTransferId = async () => {
    setIsLoading(true)
    setIsSaving(true)
    const url = `customer-invoice/${params.id}/`
    let result = await api.get(url).then(response => {
      console.log(response.data)
      setData(response.data)
      setIsLoading(false)
      setIsSaving(false)
    })
  }

  const copyToClipboard = useClipboard();

  return (
    <>

      <Helmet>
        <title>Invoice - Bloco B3</title>
      </Helmet>
        <Backdrop
          sx={{ color: '#fff', zIndex: 1500, }}
          open={loadingModal}
        >
          <CircularProgress color="inherit"/>
        </Backdrop>

        {/*{!isLoading &&*/}
      { data &&
      <Grid
        container
        spacing={2}
        justifyContent={'space-between'}
        sx={{ flexGrow: 1, padding: "1rem"  }}
      >

        <Grid md={8}>
          <Card
            sx={{padding:"1rem", margin: "0 1rem " }}>
            { !isLoading &&
              <>
                <CardContent >
                  <Grid
                    container
                    spacing={2}
                    justifyContent={'space-between'}
                    sx={{ flexGrow: 1}}
                  >
                    <Grid xs={6}>
                      <Box>
                        <Typography variant="h6"  sx={{
                          color: 'primary.main'
                        }}>CLI-{data?.id}</Typography>
                      </Box>
                    </Grid>
                    <Grid xs={6}>
                      <Box
                        border
                        sx={{
                          display: 'flex',
                          justifyContent: 'end',
                          alignItems: 'center'

                        }}
                      >


                        {/*<Chip*/}
                        {/*  color={data?.status.key === 'PAID' ? 'success' :*/}
                        {/*    data?.status.key === 'PENDING' ? 'warning' :*/}
                        {/*      data?.status.key === 'SCHEDULED' ? 'warning' :*/}
                        {/*        data?.status.key === 'FINALIZED' ? 'success' :*/}
                        {/*          data?.status.key === 'PROCESSING' ? 'primary' :*/}
                        {/*            data?.status.key === 'ERROR' ? 'error' :*/}
                        {/*              data?.status.key === 'DUE' ? 'error' :*/}
                        {/*              data?.status.key === 'DEBIT' ? 'error' :*/}
                        {/*                data?.status.key === 'FAILED' ? 'error' :*/}
                        {/*                  data?.status.key === 'OPEN' && 'info'}*/}
                        {/*  size="small"*/}
                        {/*  variant="outlined"*/}
                        {/*  sx={{marginLeft:"1rem"}}*/}
                        {/*  label={data?.status.value}/>*/}

                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider/>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell >Código</TableCell>
                      <TableCell>Contrato</TableCell>
                      <TableCell>Referente</TableCell>
                      <TableCell width={150}>Valor</TableCell>
                      <TableCell >Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { data?.invoices.map(invoice =>
                    <TableRow>

                      <TableCell>
                        {invoice.number}
                      </TableCell>
                      <TableCell>
                        BB3-{invoice.rent_contract_id} - {invoice.name}
                      </TableCell>
                      <TableCell >
                        {moment(`${invoice.month}/${invoice.year}`,  'MM/YYYY').format('MMM/YYYY')}

                      </TableCell>
                      <TableCell>
                        {Masks.money(invoice.unit__condo__price_per_invoice.toString())}
                      </TableCell>
                      <TableCell>
                        {invoice.status_billing}
                      </TableCell>
                    </TableRow> )}



                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align={"right"}><Typography sx={{fontWeight:"bold",}} level={"h4"} color={data?.total < 0 && "error.main" }> Total:</Typography></TableCell>
                      <TableCell><Typography sx={{fontWeight:"bold", fontSize:"1.3rem"}} level={"h4"} color={data?.total < 0 && "error.main" }> {Masks.money(data?.total_invoice.toString())}</Typography> </TableCell>
                    </TableRow>
                  </TableBody>

                </Table>

              </>
            }

          </Card>

          { data?.charges.length > 0 && <Card
            sx={{padding:"1rem", margin: "2rem 1rem 0 " }}>
            { !isLoading &&
              <>
                <CardContent >
                  <Grid
                    container
                    spacing={2}
                    justifyContent={'space-between'}
                    sx={{ flexGrow: 1}}
                  >
                    <Grid xs={6}>
                      <Box>
                        <Typography variant="h6"  sx={{
                          color: 'primary.main'
                        }}>Cobranças</Typography>
                      </Box>
                    </Grid>
                    <Grid xs={6}>
                      <Box
                        border
                        sx={{
                          display: 'flex',
                          justifyContent: 'end',
                          alignItems: 'center'

                        }}
                      >

                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider/>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell >Código</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Referente</TableCell>
                      <TableCell width={150}>Valor</TableCell>
                      <TableCell >Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { data?.charges.map(charge =>
                      <TableRow>

                        <TableCell>
                          {charge.number}
                        </TableCell>
                        <TableCell>
                          {charge.unit__name}
                        </TableCell>
                        <TableCell >
                          {moment(charge.created_at,  'YYYY-MM-DD').format('MMM/YYYY')}

                        </TableCell>
                        <TableCell>
                          {Masks.money(charge.unit__condo__price_per_charge.toString())}
                        </TableCell>
                        <TableCell>
                          {charge.status_billing}
                        </TableCell>
                      </TableRow> )}



                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align={"right"}><Typography sx={{fontWeight:"bold",}} level={"h4"} color={data?.total < 0 && "error.main" }> Total:</Typography></TableCell>
                      <TableCell><Typography sx={{fontWeight:"bold", fontSize:"1.3rem"}} level={"h4"} color={data?.total < 0 && "error.main" }> {Masks.money(data?.total_charge.toString())}</Typography> </TableCell>
                    </TableRow>
                  </TableBody>

                </Table>

              </>
            }

          </Card> }

        </Grid>

        <Grid md={4}>
          <Card >
            <CardContent >
              <Box sx={{display: "flex", alignItems: "center", padding: ".5rem 0 "}}>
              <ContactMailOutlined  sx={{
                color: 'primary.main'
              }} />
              <Typography color={"primary.main"} sx={{marginLeft: ".5rem"}} variant="h6">
                 Dados do Cliente</Typography>
              </Box>
              <Divider/>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>{data?.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>CPF/CNPJ</TableCell>
                    <TableCell>{data?.cpf_cnpj}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Referente</TableCell>
                    <TableCell>{data?.month}/{data?.year}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{fontWeight: 'bold'}} >Total</TableCell>
                    <TableCell sx={{fontWeight: 'bold', fontSize: '1.3rem'}}>{Masks.money(data?.total.toString())}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>

          </Card>

        </Grid>


      </Grid>}

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSaving}
        // onClick={() => {
        //   if (isSaving === false){
        //     setActiveBackdrop(false)
        //   }
        //
        // }}
      >
        <CircularProgress color="inherit" />

      </Backdrop>
   </>

  )
}

export default CustomerInvoiceDetail
