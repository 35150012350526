import {Box, Typography} from "@mui/material";
import {FileUpload} from "@mui/icons-material";
import React from "react";

const DropZone = ({ enabled, getRootProps, getInputProps, isDragActive, dropText}) => {
  return (
    <>
      {enabled &&
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: 2,
            borderStyle: 'dashed',
            strokeDasharray: '15%2c 50%2c 3',
            strokeDashoffset: '0',
            borderRadius: 2,
            borderColor: 'secondary.light',
            backgroundColor: 'rgb(240,240,240)',
            mb: '1rem',
          }}
          {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />

          <Box
            sx={{
              padding: '1rem',
              textAlign: 'center'
            }}
          >
            {isDragActive ? 'Adicione aqui' : dropText}

          </Box>
          <FileUpload
            sx={{
              marginBottom: '1rem',
              fontSize: '50px',
              color: 'green',
              opacity: 0.6
            }}
          />
        </Box>
      }
    </>
  )
}

export default DropZone