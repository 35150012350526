import {Box, Divider, Fade, IconButton, Typography, useMediaQuery} from "@mui/material";
import {Edit} from "@mui/icons-material";
import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import Masks from "../../../../components/Shared/Masks";
import React, {useContext} from "react";
import {useParams} from "react-router-dom";
import Context from "../../../../contexts/Context";

const RenterInfo = (
  {
    reqData,
    setFilters,
    isLoading,
    unitInfo,


  }
) => {
  const params = useParams()
  const { setDataProvider } = useContext(Context)

  const sizeMatch = useMediaQuery('@media (min-width:730px)')

  return (
    <Fade
      in={!!reqData?.unit}
      mountOnEnter
      unmountOnExit
      timeout={500}
    >

      <Box
        sx={{
          // p: '1rem',
          borderRadius: '1rem',
          width: sizeMatch ? '49%' : '100%',
        }}
      >

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '0.5rem',
            // mt: '0.5rem',
            minHeight: '49.95px'
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '18px',
              color: 'primary.main',
              // mb: '0.4rem'
            }}>
            Informações do Inquilino
          </Typography>

          <IconButton
            sx={{paddingX: 0}}
            onClick={() => {
              setFilters(prevState => ({ ...prevState, modalUnit: true, unitId: reqData?.unit}))
              setDataProvider(prevState => ({ ...prevState, modalUnit: true, unitId: reqData?.unit?.id}))
            }
            }
          >
            <Edit />
          </IconButton>
        </Box>
        {/*<Divider*/}
        {/*  sx={{*/}
        {/*    mb: '1rem',*/}
        {/*  }}*/}
        {/*/>*/}
        <SimpleTypography
          label={'Nome'}
          isLoading={isLoading}
          directValue={unitInfo?.name ?? reqData?.name}
          labelStyles={{
            fontWeight: 'bold'
          }}
          boxStyles={{
            width: '100%',
            mb: '1rem'
          }}
        />

        <SimpleTypography
          label={'CPF / CNPJ'}
          isLoading={isLoading}
          directValue={Masks?.cpfOrCnpjText(params?.id ? reqData?.cpf_cnpj?.toString() : unitInfo?.cpf?.toString() ?? '') ?? ''}
          labelStyles={{
            fontWeight: 'bold'
          }}
          boxStyles={{
            width: '100%',
            mb: '1rem'
          }}
        />

        {unitInfo?.street && unitInfo?.number &&
          <SimpleTypography
            label={'Endereço'}
            isLoading={isLoading}
            directValue={`${unitInfo.street}, ${unitInfo.number}`}
            labelStyles={{
              fontWeight: 'bold'
            }}
            boxStyles={{
              width: '100%',
              mb: '1rem'
            }}
          />
        }

        {unitInfo?.district &&
          <SimpleTypography
            label={'Bairro'}
            isLoading={isLoading}
            directValue={`${unitInfo.district}`}
            labelStyles={{
              fontWeight: 'bold'
            }}
            boxStyles={{
              width: '100%',
              mb: '1rem'
            }}
          />
        }

      </Box>
    </Fade>

  )
}

export default RenterInfo