import {Avatar, Card, CardContent, CardHeader, Collapse, Divider, Grid, TextField} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import moment from "moment/moment";
import React, {useEffect} from "react";
import {Opacity} from "@mui/icons-material";

const WaterConsume = ({
    state,
    setState,
    previousData,
    gasWaterRequired,
    errors
  }) => {


  return (
    <Grid
      item
      xs={12}
    >
      {state?.category === 'WATER_READING' &&
        <Card>
          <CardHeader
            avatar={<Avatar sx={{bgcolor: 'primary.main'}}><Opacity /></Avatar>}
            title={'Consumo de Água'}/>
          <Divider
            sx={{
              mb: '.3rem'
            }}
          />
          <CardContent>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={6}
              >
                <DesktopDatePicker
                  label="Data da Leitura Anterior"
                  value={moment(state?.previous_reading_date).toDate()}
                  onChange={(e) => setState((prevState) => ({
                    ...prevState,
                    previous_reading_date: moment(new Date(e)).format('YYYY-MM-DD')
                  }))}
                  renderInput={(params) => <TextField
                    {...params}
                    fullWidth
                    size={'small'}
                    error={!!errors?.previous_reading_date}
                    helperText={errors?.previous_reading_date ? errors.previous_reading_date : previousData?.previousDate ? `Última data de leitura: ${moment(previousData?.previousDate).format('DD/MM/YYYY')}` : ''}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "dd/mm/aaaa"
                    }}
                  />}
                />
              </Grid>
              <Grid item xs={6}>
                <DesktopDatePicker
                  label="Data da Leitura Atual"
                  value={moment(state?.current_reading_date).toDate()}
                  onChange={(e) => setState((prevState) => ({
                    ...prevState,
                    current_reading_date: moment(new Date(e)).format('YYYY-MM-DD')
                  }))}
                  renderInput={(params) => <TextField
                    {...params}
                    fullWidth
                    size={'small'}
                    required={!!gasWaterRequired}
                    error={!!errors?.current_reading_date}
                    helperText={errors?.current_reading_date ? errors.current_reading_date : ''}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "dd/mm/aaaa"
                    }}
                  />}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Leitura Anterior"
                  size={'small'}
                  fullWidth
                  type={'text'}
                  value={state?.previous_reading_amount?.toString().replace(/[^0-9.,]/g, '') ?? ''}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      previous_reading_amount: e.target.value?.replace(/[^0-9.,]/g, '')
                    }))}
                  error={!!errors?.previous_reading_amount}
                  helperText={errors?.previous_reading_amount ? errors.previous_reading_amount : previousData?.previousReading ? `Última leitura: ${previousData?.previousReading}` : ''}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Leitura Atual"
                  size={'small'}
                  fullWidth
                  type={'text'}
                  required={!!gasWaterRequired}
                  value={state?.current_reading_amount?.replace(/[^0-9.,]/g, '') ?? ''}
                  onChange={(e) => setState((prevState) => ({
                    ...prevState,
                    current_reading_amount: e.target.value?.replace(/[^0-9.,]/g, '')
                  }))}
                  error={!!errors?.current_reading_amount}
                  helperText={errors?.current_reading_amount}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }
    </Grid>
  )
}

export default WaterConsume