import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const ComplaintToggleButton = ({ state, setState }) => {
  return (
    <ToggleButtonGroup
      fullWidth
      exclusive
      value={state?.status}
      color={'primary'}
      onChange={(e, value) => setState(prevState => ({
        ...prevState,
        status: value
      }))}
      sx={{ mb: '1rem'}}
    >
      <ToggleButton value={"OPEN"} selected={state?.status === 'OPEN' || !state?.status}>Aberta</ToggleButton>
      <ToggleButton value={"FINISHED"} selected={state?.status === 'FINISHED'}>Fechada</ToggleButton>
    </ToggleButtonGroup>
  )
}

export default ComplaintToggleButton