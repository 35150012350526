import { Backdrop, CircularProgress } from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import SimpleDialog from "../../../../components/Shared/SimpleDialog";
import { getCondoId } from "../../../../services/Main/AuthStorage";
import {Box} from "@mui/system";
import {api} from "../../../../services/Main/Api";
import {useSnackbar} from "notistack";
import Context from "../../../../contexts/Context";
import {CompareArrows, Pix} from "@mui/icons-material";
import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import Masks from "../../../../components/Shared/Masks";

const TransferDetail = (props) => {
  const {
    transferOpen,
    setTransferOpen,
    filters,
    setFilters
  }
    = props

  const { transferType } = filters || {}
  const {enqueueSnackbar} = useSnackbar()

  const [reqData, setReqData] = useState(null)
  const [cancelOpen, setCancelOpen] = useState(false)
  const [reqLoading, setReqLoading] = useState(false)


  useEffect(function getInfo(){
    if(transferOpen && filters?.id){
      const alternateUrl = filters?.transferType === 'ted' ?
        api.get(`rent_recipient/transfer/${filters?.id}/`)
        : filters?.transferType === 'pix' &&
        api.get(`rent_recipient/pix-transfer/${filters?.id}/`)



      alternateUrl
        .then(response =>{
          setReqData(response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          setFilters(prevState => ({...prevState, load: false }))
        })
    }
  },[filters?.id, transferOpen])

  const resetFilters = () => {
    setFilters(prevState => ({
      ...prevState,
      id: null
    }))
  }

  const cancelTransfer = () => {
    setReqLoading(true)
    setFilters(prevState => ({...prevState, load: true }))


    const data ={
      account_id: +getCondoId(),
      document_number: +reqData?.document_number
    }


    api.post(`rent_recipient/transfer/cancel/`, data)
      .then(response =>{
        setFilters(prevState => ({...prevState, requestDone: !filters?.requestDone, load: true}))

        setTransferOpen(false)
        response.data.response.false.includes('processo de registro/efetivada') && enqueueSnackbar('Não é possível cancelar transferência que esta em processo de registro/efetivada' , { variant: 'error' })
        response.data.response.false.includes('Transação já se encontra como cancelada.') && enqueueSnackbar('Essa transferência já foi cancelada' , { variant: 'info' })
        !response.data.response.false && enqueueSnackbar('Transferência cancelada', { variant: 'success' })
        // resetFilters()

      })
      .catch(error => {
        enqueueSnackbar('Erro ao cancelar a transferência', { variant: 'error' })
        console.log(error)
      })
      .finally(()=> {
        setReqLoading(false)
        setFilters(prevState => ({...prevState, load: false}))
      })

  }

  function disableCancel() {
    return reqData?.status?.value === 'Created' || reqData?.status?.value === 'pending';
  }


  return (
    <>
      <SimpleDialog
        open={cancelOpen}
        setOpen={setCancelOpen}
        title={'Cancelar transferência'}
        question={'Realmente quer cancelar essa transferência?'}
        functionProp={cancelTransfer}
      />
      <SimpleDialog
        title={
          filters?.transferType === 'ted' ? 'Transferência TED' :
            filters?.transferType === 'pix' && 'Transferência Pix'
        }
        titleIcon={
          filters?.transferType === 'ted' ? <CompareArrows /> :
            filters?.transferType === 'pix' && <Pix />

        }
        // disabled={reqData?.status?.value !== 0 && reqData?.status?.value !== 'Created'}
        open={transferOpen}
        setOpen={setTransferOpen}
        confirmText={transferType === 'ted' ? 'Cancelar' : false}
        cancelText={'Voltar'}
        onClickConfirm={transferType === 'ted' && disableCancel() ?
          () => {
          setCancelOpen(true)
          }
          : false
        }
        onClickCancel={() => {
          setFilters(prevState => ({...prevState, load: true }))
          resetFilters()
          setTransferOpen(false)
          setReqData(null)
        }}
        removeConfirm={transferType === 'pix' && true}
        dialogContent={
          <>
            <Backdrop
              sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
              open={reqLoading}
            >
              <CircularProgress color="inherit"/>
            </Backdrop>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                bgcolor: 'neutral.main',
                p: '1rem',
                borderRadius: '1rem',
                mb: '1rem',
              }}
            >
              <SimpleTypography
                label={reqData?.rent_recipient_name ?? ''}
                isLoading={filters?.load}
                labelStyles={{
                  fontWeight: 'bold',
                  color: 'primary.main'
                }}
                boxStyles={{
                  width: '100%',
                  mb: '0.5rem'
                }}
              />
              <SimpleTypography
                label={'Banco'}
                directValue={reqData?.rent_recipient_bank ?? ''}
                isLoading={filters?.load}
                labelStyles={{
                  fontWeight: 'bold'
                }}
                boxStyles={{
                  width: '100%',
                  mb: '0.5rem'
                }}
              />
              <SimpleTypography
                label={'Agência'}
                directValue={reqData?.rent_recipient_bank_branch ?? ''}
                isLoading={filters?.load}
                labelStyles={{
                  fontWeight: 'bold'
                }}
                boxStyles={{
                  width: '100%',
                  mb: '0.5rem'
                }}
              />

              <SimpleTypography
                label={'Conta'}
                directValue={`${reqData?.rent_recipient_bank_account} - ${reqData?.rent_recipient_bank_account_digit}` ?? ''}
                isLoading={filters?.load}
                labelStyles={{
                  fontWeight: 'bold'
                }}
                boxStyles={{
                  width: '100%',
                  mb: '0.5rem'
                }}
              />
              <SimpleTypography
                label={'Valor'}
                directValue={Masks?.money(String(reqData?.value) ?? '')}
                isLoading={filters?.load}
                styles={{
                  fontWeight: 'bold',
                  color: 'primary.main'

                }}
                labelStyles={{
                  fontWeight: 'bold',
                  color: 'primary.main'
                }}
                boxStyles={{
                  width: '100%',
                  mb: '0.5rem'
                }}
              />
              {/*<SimpleTypography*/}
              {/*  label={'Situação'}*/}
              {/*  directValue={reqData?.status?.label}*/}
              {/*  isLoading={filters?.load}*/}
              {/*  labelStyles={{*/}
              {/*    fontWeight: 'bold'*/}
              {/*  }}*/}
              {/*  boxStyles={{*/}
              {/*    width: '100%',*/}
              {/*    mb: '0.5rem'*/}
              {/*  }}*/}
              {/*/>*/}
            </Box>

            {reqData?.user_detail &&
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  bgcolor: 'neutral.main',
                  p: '1rem',
                  borderRadius: '1rem',
                  mb: '1rem',
                }}
              >
                <SimpleTypography
                  label={'Usuário que realizou a operação'}
                  isLoading={filters?.load}
                  labelStyles={{
                    fontWeight: 'bold',
                    // color: 'primary.main'
                  }}
                  boxStyles={{
                    width: '100%',
                    mb: '0.5rem'
                  }}
                />
                {reqData?.user_detail?.first_name &&
                  <SimpleTypography
                    label={'Nome'}
                    directValue={reqData?.user_detail?.first_name + ' ' + reqData?.user_detail?.last_name ?? ''}
                    isLoading={filters?.load}
                    labelStyles={{
                      fontWeight: 'bold',
                    }}
                    boxStyles={{
                      width: '100%',
                      mb: '0.5rem'
                    }}
                  />
                }

                {reqData?.user_detail?.email &&
                  <SimpleTypography
                    label={'E-mail'}
                    directValue={reqData?.user_detail?.email ?? ''}
                    isLoading={filters?.load}
                    labelStyles={{
                      fontWeight: 'bold',
                      // color: 'primary.main'
                    }}
                    boxStyles={{
                      width: '100%',
                      mb: '0.5rem'
                    }}
                  />
                }
                {reqData?.user_detail?.cnpj_cpf &&
                  <SimpleTypography
                    label={'CPF/CNPJ'}
                    directValue={Masks?.cpfOrCnpj(reqData?.user_detail?.cnpj_cpf?.toString() ?? '')}
                    isLoading={filters?.load}
                    labelStyles={{
                      fontWeight: 'bold',
                      // color: 'primary.main'
                    }}
                    boxStyles={{
                      width: '100%',
                      mb: '0.5rem'
                    }}
                  />
                }


              </Box>
            }
          </>
        }




      />

    </>

  )
}

export default TransferDetail