import {Skeleton, Typography} from "@mui/material";
import {Subtitles} from "@mui/icons-material";
import React from "react";

const MaintenanceReadTitle = ({isLoading, dataUpdate}) => {
  return (
    <>
        {!isLoading ?
        <Typography
          sx={{
            marginBottom: '0.5rem',
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Subtitles sx={{mr: '0.5rem'}}/>
          <b>Título: </b> &nbsp; {dataUpdate?.title}
        </Typography>
        :
        <Skeleton
          variant="rounded"
          animation="pulse"
          height={24}
          sx={{marginBottom: '0.5rem'}}
          />
        }
  </>
  )
}

export default MaintenanceReadTitle