import {TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import React, {useContext} from "react";
import Context from "../../../../../contexts/Context";

const TableComplaintHead = () => {
  const {filters, setFilters} = useContext(Context)

  const handleFilter = (asc: string, desc: string) => {
    setFilters(prevState => ({
        ...prevState,
        ordering: filters?.ordering === asc ? desc : asc
      })
    )
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-unit' || filters?.ordering === 'name'}
            direction={filters?.ordering === '-unit' ? 'desc' : 'asc'}
            onClick={() => handleFilter('unit', '-unit') }
          >
            Imóvel
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-created_at' || filters?.ordering === 'created_at'}
            direction={filters?.ordering === '-created_at' ? 'desc' : 'asc'}
            onClick={() => handleFilter('created_at', '-created_at') }
          >
            Criada
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-updated_at' || filters?.ordering === 'updated_at'}
            direction={filters?.ordering === '-updated_at' ? 'desc' : 'asc'}
            onClick={() => handleFilter('updated_at', '-updated_at') }
          >
           Ultima atualização
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-category' || filters?.ordering === 'category.value'}
            direction={filters?.ordering === '-category' ? 'desc' : 'asc'}
            onClick={() => handleFilter('category', '-category') }
          >
            Categoria
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-status' || filters?.ordering === 'status'}
            direction={filters?.ordering === '-status' ? 'desc' : 'asc'}
            onClick={() => handleFilter('status', '-status') }
          >
            Status
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default TableComplaintHead
