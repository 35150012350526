import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  CardHeader,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { api } from '../../../services/Main/Api'
import { useParams } from 'react-router-dom'
import AvatarIcon from "./components/BankTransferReceipt/AvatarIcon";
import TimeInfo from "./components/BankTransferReceipt/TimeInfo";
import TransferStatus from "./components/BankTransferReceipt/TransferStatus";
import TransferValue from "./components/BankTransferReceipt/TransferValue";
import TransferType from "./components/BankTransferReceipt/TransferType";
import TransferTitle from "./components/BankTransferReceipt/TransferTitle";
import TransferId from "./components/BankTransferReceipt/TransferId";
import TransferBank from "./components/BankTransferReceipt/TransferBank";
import TransferBranch from "./components/BankTransferReceipt/TransferBranch";
import BankAccount from "./components/BankTransferReceipt/BankAccount";
import TransferDescription from "./components/BankTransferReceipt/TransferDescription";
import ReceiptDownload from "./components/BankTransferReceipt/ReceiptDownload";
import ReceiptBackButton from "./components/BankTransferReceipt/ReceiptBackButton";

const BankTransferReceiptTed = () => {
  const params = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const id = params.id
    api.get(`fitbank/money-transfer/${id}`).then(response => {
      setData(response?.data)
      setLoading(false)
    }).catch(reason => console.log(reason))
  }, [])

  return (
    <Container maxWidth={'sm'}>
      <Card>
        <CardHeader title={'Comprovante de Transferência - TED'}/>
        <CardContent>
          <AvatarIcon />
          <TimeInfo
            loading={loading}
            data={data}
          />
          <TransferStatus
            loading={loading}
            data={data}
          />
          <TransferValue
            loading={loading}
            data={data}
          />
          <TransferType
            type={'TED'}
          />

          <Box sx={{ my: 3 }}>
            <TransferTitle
              title={'Destino'}
            />
            <Grid
              container
              spacing={1}
              fontSize={'small'}
            >
              <TransferId
                loading={loading}
                specificData={data?.to_name}
              />
              <TransferBank
                loading={loading}
                specificData={data?.to_bank_name}
              />
              <TransferBranch
                loading={loading}
                specificData={data?.to_bank_branch}
              />
              <BankAccount
                loading={loading}
                bankAccount={data?.to_bank_account}
                bankAccountDigit={data?.to_bank_account_digit}
              />
            </Grid>
          </Box>

          <Box sx={{ my: 3 }}>
            <TransferTitle
              title={'Origem'}
            />
            <Grid container spacing={1} fontSize={'small'}>
              <TransferId
                loading={loading}
                specificData={data?.bank_account?.name}
              />
              <TransferBank
                specificData={'Bloco B3 (Fitbank)'}
              />
              <TransferBranch
                loading={loading}
                specificData={data?.bank_account?.bank_branch}
              />
              <BankAccount
                loading={loading}
                bankAccount={data?.bank_account?.bank_account}
                bankAccountDigit={data?.bank_account?.bank_account_digit}
              />
            </Grid>

            <TransferDescription
              loading={loading}
              data={data}
            />
          </Box>
        </CardContent>
        <ReceiptDownload
          data={data}
        />
      </Card>
      <ReceiptBackButton />
    </Container>
  )
}

export default BankTransferReceiptTed
