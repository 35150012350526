import {
  Backdrop,
  Box, Card, CardContent,
  CircularProgress,
  Fade,
  MenuItem,
  TextField,
} from "@mui/material";
import SimpleText from "../../../components/Shared/SimpleText";
import BlockFilter from "../../../components/Filters/BlockFilter";
import React, {useContext, useEffect, useState} from "react";
import GlobalSwitch from "../../../components/Shared/GlobalSwitch";
import Context from "../../../contexts/Context";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import {api} from "../../../services/Main/Api";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {useNavigate, useParams} from "react-router-dom";
import Owner from "./components/Owner/Owner";
import {useSnackbar} from "notistack";
import StateFilter from "../../../components/Filters/StateFilter";

/*
 This component serves both UnitCreateUpdate and UnitUpdates
*/
const UnitFormModelModal = (props) => {
  const { setUnitInfo, getContract } = props || {}
  const { filters, setFilters, blockOptions, getUnitOptions, setDataProvider, dataProvider } = useContext(Context)
  const { unitId } = filters || {}


  const [defaultValue, setDefaultValue] = useState(null)
  const [reqData, setReqData] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(!!unitId)
  const [requesting, setRequesting] = useState(false)
  const [loadBlock, setLoadBlock] = useState(null)
  const [owner, setOwner] = useState(false)
  const [dataOwner, setDataOwner] = useState([])
  const [ownerDelete, setOwnerDelete] = useState([])

  const navigate = useNavigate()
  const params = useParams()
  const {enqueueSnackbar} = useSnackbar()

  function getOwner (){
    if (unitId?.id) {
      api.get(`/unit_owner/`, { params: {
          unit__condo_id: getCondoId(),
          unit_id: unitId?.id
        }
        })
        .then(response => {
          setDataOwner(response.data.results)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }


  const ownerUpdate = (unitId, ownerId) => {
    const updatedOwner = dataOwner.find(owner => owner.id === ownerId);
    if (updatedOwner) {
      api.put(`unit_owner/${updatedOwner.id}/`, {
        name: updatedOwner.name,
        cpf_cnpj: updatedOwner.cpf_cnpj,
        phone: updatedOwner.phone,
        email: updatedOwner.email,
        unit: unitId?.id
      })
        .then(response => {
          enqueueSnackbar('Proprietário atualizado', { variant: 'success' })
        })
        .catch(error => {
          enqueueSnackbar('Erro ao atualizar proprietário', { variant: 'error' })
          console.log(error)
        })
        .finally(() => {
          getOwner()
        })
    }
  }


  useEffect(function callBlockOptionsReq (){
    //-> request Blocks from provider
    setFilters(prevState => ({...prevState, blockReq: true}))
  }, [])

  useEffect(function setInitialFilters(){
    setFilters(prevState => ({...prevState, renter: false }))
  },[])

  useEffect(() => {
    if (blockOptions && reqData) {
      const defaultValue = blockOptions.find(e => +e.value === +reqData.block);
      setDefaultValue(defaultValue);
    }
  }, [blockOptions, reqData]);

  useEffect(function ownerRequired(){
    filters?.renter && reqData?.owner_name?.length > 0 ? setOwner(false) : setOwner(true)
    !filters?.renter &&  setOwner(false)
  },[filters?.renter, reqData?.owner_name])

  function resetFilter () {
    setIsLoading(true)
    // setReqData(null)
    setIsLoading(false)

  }


  function getUnit(param) {
      api.get(`/unit/${dataProvider?.unitId ?? param}`)
        .then(response => {
          setReqData(response.data)
          setUnitInfo(response.data)
        })
        .finally(() => {
          setIsLoading(false)
        })
  }

  useEffect(function updateWhenModalMode() {
    if (dataProvider?.modalUnit === true && dataProvider?.unitId){
      getUnit()
      getOwner()
    } else {
      setReqData(null)
    }
  },[dataProvider?.modalUnit])

  function requisition() {
    setRequesting(true)

    const data = {
      ...reqData,
      owner: !filters?.renter,
      condo: +getCondoId(),
      phone: reqData?.phone?.toString()?.replace(/[^\d]+/g, ""),
      cpf_visitor: reqData?.cpf?.toString()?.replace(/[^\d]+/g, "")
    }

    const alternateUrl = dataProvider?.unitId ? api.put(`/unit/${dataProvider?.unitId}`, data) : api.post(`/unit/`, data)

    alternateUrl
      .then(response => {
        getUnit(response.data.id)
        params?.id && getContract(null, true)
        ownerCreateUpdate(response.data.id)
        ownerDeleteReq(response.data.id)
        setDataProvider(prevState => ({ ...prevState, reqDone: true, unitId: response.data.id, modalUnit: false}))
        setFilters(prevState => ({ ...prevState, modalUnit: false,  }))
        resetFilter()
        getUnitOptions()
        // navigate(0)
      })
      .catch(error => {
        enqueueSnackbar(!params.id ? "Erro ao registrar imóvel" : "Erro ao atualizar imóvel" , {variant: "error"})
        error.response.data.percent_meter && enqueueSnackbar(error.response.data.percent_meter , {variant: "error"})

        setError(error.response.data)
        console.log(error)
      })
      .finally(() => {
        setRequesting(false)
      })
  }


  const ownerCreateUpdate = (unit) => {

    const dataReq = dataOwner.map(owner => {
      return {
        ...owner,
        unit: unit
      };
    })

    dataReq?.map((owner) => {
      if(!owner?.id ) {
        api.post(`unit_owner/`, {
          name: owner.name,
          cpf_cnpj: owner.cpf_cnpj.replace(/\D+/g, ""),
          phone: owner.phone.replace(/\D+/g, ""),
          email: owner.email,
          unit: owner?.unit
        })
          .then(response => {
            console.log(response)
            getOwner()
            // enqueueSnackbar('Cobrança criada', { variant: 'success' })

          })
          .catch(error => {
            enqueueSnackbar('Erro ao registrar proprietário', { variant: 'error' })
            console.log(error)
          })
          .finally(() => {

          })
      } else {
        api.put(`unit_owner/${owner.id}/`, {
          name: owner.name,
          cpf_cnpj: owner.cpf_cnpj.replace(/\D+/g, ""),
          phone: owner.phone.replace(/\D+/g, ""),
          email: owner.email,
          unit: owner?.unit
        })
          .then(response => {
            // enqueueSnackbar('Cobrança atualizada', { variant: 'success' })
            getOwner()


          })
          .catch(error => {
            // enqueueSnackbar('Erro ao atualizar cobrança', { variant: 'success' })
            console.log(error)
          })
          .finally(() => {

          })
      }
    })
  }

  const ownerDeleteReq = () => {
    if (ownerDelete?.length > 0) {
      const deletePromises = ownerDelete.map(owner => {
        return api.delete(`/unit_owner/${owner?.id}/`)
          .then(response => {
            return Promise.resolve();
          })
          .catch(error => {
            console.log(error);
            return Promise.reject(error);
          });
      });

      Promise.all(deletePromises)
        .then(() => {
          enqueueSnackbar('Proprietários excluídos', { variant: 'success' });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  useEffect(function errorHandle(){
    if (error?.length > 0) {
      error.forEach((erro) => {
        const mensagemErro = erro
          .replace("ErrorDetail(string='", "")
          .replace("', code='invalid')", "")
          .replace("PixKey", "Chave Pix")
          .replace("O atributo", "")
          .replace("inválido", "inválida")
        enqueueSnackbar(mensagemErro, { variant: 'error' });
      });
      setError(null)
    }

  }, [error])



  return (
    <>

      <SimpleDialog
        title={reqData?.id ? 'Edita imóvel' : 'Registrar imóvel'}
        open={dataProvider?.modalUnit}
        confirmText={reqData?.id ? 'Salvar' : 'Registrar'}
        disabled={!reqData?.name || !reqData?.cpf || !reqData?.number || owner || !reqData?.unit_type}
        onClickConfirm={() => {
          requisition()
        }}
        onClickCancel={() => {
          setDataProvider(prevState => ({...prevState, modalUnit: false}))
          resetFilter()
        }}
        cancelText={'Cancelar'}
        dialogContent={
          <>
            <Card>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={requesting}
            >
              <CircularProgress color="inherit"/>
            </Backdrop>

            {/*<Box>*/}

            <CardContent>
            <TextField
              select
              required
              label={'Tipo de Imóvel'}
              value={reqData?.unit_type ?? ''}
              size={'small'}
              onChange={(e) => setReqData(prevState => ({...prevState, unit_type: e.target.value}))}
              sx={{mb: '1rem', width: '100%'}}

            >
              <MenuItem value="apartment">Apartamento</MenuItem>
              <MenuItem value="house">Casa</MenuItem>
              <MenuItem value="commercial_house">Casa comercial</MenuItem>
              <MenuItem value="pavilion">Pavilhão</MenuItem>
              <MenuItem value="commercial_room">Sala comercial</MenuItem>
            </TextField>

              <TextField
                select
                required
                label={'Situação do Imóvel'}
                value={reqData?.status ?? ''}
                size={'small'}
                onChange={(e) => setReqData(prevState => ({...prevState, status: e.target.value}))}
                sx={{mb: '1rem', width: '100%'}}

              >
                <MenuItem value="available">Disponível</MenuItem>
                <MenuItem value="occupied">Ocupado</MenuItem>
              </TextField>

            {/*</Box>*/}


            <SimpleText
              label={'Nome do Locatário'}
              required
              state={reqData}
              setState={setReqData}
              keyName={'name'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />

            <SimpleText
              label={'CPF / CNPJ do Locatário'}
              required
              cpfAndCnpj
              state={reqData}
              setState={setReqData}
              keyName={'cpf'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />

            <SimpleText
              label={'E-mail do locatário'}
              state={reqData}
              setState={setReqData}
              keyName={'email'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />

            <SimpleText
              label={'Telefone do locatário'}
              phone
              state={reqData}
              setState={setReqData}
              keyName={'phone'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />

            <SimpleText
              label={'Rua'}
              state={reqData}
              setState={setReqData}
              keyName={'street'}
              isLoading={isLoading}
              error={error}
              styles={{mb: '1rem'}}
            />

            <SimpleText
              label={'Número'}
              required
              onlyNumbers
              state={reqData}
              setState={setReqData}
              keyName={'number'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />

              <SimpleText
                label={'Bairro'}
                state={reqData}
                setState={setReqData}
                keyName={'district'}
                isLoading={isLoading}
                error={error}
                styles={{ mb: '1rem'}}
              />

              <SimpleText
                label={'Complemento'}
                state={reqData}
                setState={setReqData}
                keyName={'complement'}
                isLoading={isLoading}
                error={error}
                styles={{ mb: '1rem'}}
              />

              <Box
                sx={{
                  display: 'flex'
                }}
              >

                <SimpleText
                  label={'Cidade'}
                  state={reqData}
                  setState={setReqData}
                  keyName={'city'}
                  isLoading={isLoading}
                  error={error}
                  styles={{
                    mr: '1rem',
                    mb: '1rem'
                  }}
                />

                <StateFilter
                  label={'Estado'}
                  state={reqData}
                  setState={setReqData}
                  keyName={'state'}
                  styles={{ mb: '1rem'}}
                />

              </Box>

              <SimpleText
                label={'CEP'}
                cep
                state={reqData}
                setState={setReqData}
                keyName={'cep'}
                isLoading={isLoading}
                error={error}
                styles={{ mb: '1rem'}}
              />


            <SimpleText
              label={'m²'}
              onlyNumbers
              state={reqData}
              setState={setReqData}
              keyName={'square_meter'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />

            {loadBlock === true && unitId?.id &&
              <BlockFilter
                label={'Blocos'}
                state={reqData}
                setState={setReqData}
                errors={error}
                isLoading={isLoading}
                keyName={'block'}
                defaultValue={defaultValue}
                styles={{
                  mb: '1rem'
                }}
              />
            }

            {loadBlock === true && blockOptions?.length > 0 && !unitId?.id &&
              <BlockFilter
                label={'Blocos'}
                state={reqData}
                setState={setReqData}
                errors={error}
                isLoading={isLoading}
                keyName={'block'}
                defaultValue={defaultValue}
                styles={{
                  mb: '1rem'
                }}
              />
            }

            </CardContent>
            </Card>



            <Box
              sx={{
                mt: '1rem',
              }}
            >
              <Owner
                data={dataOwner}
                onChange={setDataOwner}
                setOwnerDelete={setOwnerDelete}
                ownerUpdate={ownerUpdate}
                isLoading={isLoading}
              />
            </Box>

           </>
            }
        />
    </>
  )
}

export default UnitFormModelModal