import {Navigate, useOutlet} from "react-router-dom";
import {getToken, removeTokens} from "../Main/AuthToken";
import {useEffect} from "react";
import {useSnackbar} from "notistack";
import {removeCondoId, removeCondoName, removeFilterDate, removeLocalUnitUser, removeLocalUser} from "../Main/AuthStorage";
import {getUserToken, removeTokensUnitUser} from "./UnitUserToken";


const RequiredUnitAuth = () => {
  const outlet = useOutlet()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(function withoutCookies(){
    if (!getUserToken()){
      enqueueSnackbar('Por favor realize login', {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      })
      removeTokensUnitUser()
      removeLocalUnitUser()
      removeCondoId()
      removeCondoName()
      removeFilterDate()
    }

  },[])


  return (getUserToken())
    ? outlet
    : <Navigate to={'/login/usuario'} replace={true} />
}

export default RequiredUnitAuth;
