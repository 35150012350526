import React, {useContext, useEffect, useState} from "react";
import {
  Avatar,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Skeleton,
  CircularProgress,
  Backdrop, useMediaQuery,
} from '@mui/material'
import {
  Edit,
  Engineering,
} from "@mui/icons-material";
import {api} from "../../../services/Main/Api";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {useSnackbar} from "notistack";
import Context from '../../../contexts/Context'
import moment from 'moment/moment'
import MaintenanceDeleteDialog from "./Components/MaintenanceCreateAndUpdate/dependencies/MaintenanceDeleteDialog";
import MaintenanceReadOnly from "./Components/MaintenanceCreateAndUpdate/dependencies/MaintenanceReadOnly";
import ButtonsUpdate from "../../../components/BookingAndMaintenance/ButtonsUpdate";
import AutoCompleteGlobal from "../../../components/Shared/AutoCompleteGlobal";
import MaintenanceType from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenanceType";
import MaintenancePeriod from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenancePeriod";
import MaintenanceTime from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenanceTime";
import Title from "../../../components/BookingAndMaintenance/Title";
import MaintenanceCheck from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenanceCheck";
import MaintenanceValue from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenanceValue";
import DateAndTime from "../../../components/BookingAndMaintenance/DateAndTime";
import DateSkeleton from "../../../components/BookingAndMaintenance/DateSkeleton";
import MaintenanceCondoMailCheck from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenanceCondoMailCheck";
import MaintenanceMailNotes from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenanceMailNotes";
import Description from "../../../components/BookingAndMaintenance/Description";
import MaintenanceNotification from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenanceNotification";
import MaintenanceStatus from "./Components/MaintenanceCreateAndUpdate/dependencies/inputs/MaintenanceStatus";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import ServiceFilter from "../../../components/Filters/ServiceFilter";
import ButtonSet from "../../../components/Shared/ButtonSet";



const MaintenanceUpdate = () => {
  const { editEvent, setEditEvent, clickDate, setRefresh, servicesOptions, getServicesOptions} = useContext(Context)
  const [dataUpdate, setDataUpdate] = useState(null)
  const [dataDelete, setDataDelete] = useState(null)
  const [error, setError] = useState(null)
  const [services, setServices] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isFinished, setIsFinished] = useState(false)
  const [edit, setEdit] = useState(false)
  const [timePickerStart, setTimePickerStart] = useState(null)
  const [timePickerEnd, setTimePickerEnd] = useState(null)

  const sizeMatch = useMediaQuery('@media (min-width:600px)')

  const {enqueueSnackbar} = useSnackbar()

  useEffect(function whenClickDate () {
    if(dataUpdate?.start_date) {
      setTimePickerStart(moment(dataUpdate?.start_date).locale('pt-br').format("LT"))
    }
    setTimePickerEnd(moment().locale('pt-br').format("LT"))
  }, [dataUpdate?.start_date])

  const handleSubmit = () => {

    if (dataUpdate?.start_date && dataUpdate?.end_date) {
    Object.assign(dataUpdate, { start_date: moment(new Date(dataUpdate?.start_date)).format()})
    Object.assign(dataUpdate, { end_date: moment(new Date(dataUpdate?.end_date)).format()})
    }

    Object.assign(dataUpdate, { condo: getCondoId()})
    Object.assign(dataUpdate, { maintenance_value: +dataUpdate?.maintenance_value?.toString().replace(/\D+/g, "")})


    setIsLoading(true)
    api.put(`/maintenance/${editEvent.id}/`, dataUpdate)
        .then(() => {
          enqueueSnackbar("Manutenção editada com sucesso!", {variant: "success"})
          setRefresh(prevState => !prevState)
          setEditEvent(false)
          initialStates()
          setEdit(false)
        })
        .catch(error => {
          enqueueSnackbar("Erro ao editar manutenção!", {variant: "error"})
          console.log(error.response.data)
          setError((error?.response?.data))
        })
      .finally(() => {
        setIsLoading(false)
      })
    }

  function getMaintenance(){
    api.get(`/maintenance/${editEvent.id}/`)
      .then(response => {
        setDataUpdate(response.data)
      }).catch(error => {
      console.log(error)
    }).finally(() =>{
      setIsLoading(false)
    })
  }

  useEffect(function maintenance(){
    getServicesOptions()
    if (editEvent.id && servicesOptions){
      getMaintenance()
    }
    }, [editEvent])

  useEffect(function providers(){
    if (editEvent?.event) {
      api.get(`/service/provider/?condo_id=${getCondoId()}`)
        .then(response => {
          setServices(response.data.results)
        })
        .catch(error => {
          console.log(error)
        })
      }
    }, [editEvent?.event])


  const deleteMaintenance = () => {
    Object.assign(dataDelete, {...(dataDelete === 'unique_id' ? {unique_id: true, all_futures: false} : dataDelete === 'all_futures' && {unique_id: false, all_futures:true})})

    api.post(`/maintenance/delete/${editEvent.id}/`, dataDelete)
        .then(response => {
          setRefresh(prevState => !prevState)
          enqueueSnackbar("Manutenção deletada com sucesso!", {variant: "success"})
          setDataDelete(null)
        }).catch(response => {
           console.log(response)
           enqueueSnackbar(response.response.data[0] ?? "Erro ao excluir manutenção", {variant: "error"})
        })
    }

  useEffect(function whenChange(){
    setIsLoading(true)
  },[editEvent?.event])

  const today = moment(new Date()).subtract(1, "days").format()
  const clickedDate = moment(new Date (clickDate)).format()

  const initialStates = () => {
    setDataUpdate(null)
    setDataDelete(null)
    setError(null)
    setServices(null)
    setOpenDelete(false)
    setEdit(false)
  }

  const initialDate = () => {
    const formStartDate = moment(dataUpdate?.start_date).format('YYYY-MM-DD')
    const setStartDate = formStartDate + ' ' + timePickerStart
    return moment(setStartDate).format()
  }

  const endDate = () => {
    const formEndDate = moment(dataUpdate?.end_date ?? dataUpdate?.start_date).format('YYYY-MM-DD')
    const setEndDate = formEndDate + ' ' + timePickerEnd
    return moment(setEndDate).format()
  }

  return (
      <>
        <SimpleDialog
          title={clickedDate > today ? "Editar Manutenção" : 'Manutenção Passada - somente leitura'}
          titleIcon={<Edit/>}
          // subtitle={clickedDate < today && 'Manutenção passada - somente leitura'}
          open={!!editEvent ?? false}
          setOpen={setEditEvent}
          removeConfirm
          onClickCancel={() => {
            setEditEvent(false)
            initialStates()
            // setEdit(true)
          }}
          actionMenu={
            <>
              {clickedDate > today &&
                <ButtonSet
                  editState={edit}
                  editClick={() => {
                    setEdit(true)
                  }}
                  deleteClick={() => setOpenDelete(true)}
                  saveClick={() => {
                    handleSubmit()
                  }}
                  cancelClick={() => {
                    getMaintenance()
                    setEdit(false)
                  }}
                />
              }
            </>

          }
          dialogContent={
            <>
              {edit ?
                <>
              {!isLoading ?
                <Title
                  state={dataUpdate}
                  setState={setDataUpdate}
                  keyName={'title'}
                  error={error}
                  // disabled={edit}
                  styles={{mb: '2rem', mt: '2rem'}}
                />
                :
                <Skeleton
                  variant="rounded"
                  animation="pulse"
                  height={39.974}
                  sx={{marginBottom: '1rem', marginTop: '2rem'}}
                />
              }
                {!isLoading && dataUpdate?.id ?
                  <ServiceFilter
                    label={'Prestador'}
                    state={dataUpdate}
                    setState={setDataUpdate}
                    keyName={'service_provider'}
                    // disabled={edit}
                    defaultValue={servicesOptions?.find(e => e.value === dataUpdate?.service_provider)}
                    error={error}
                  />
                  :
                  <Skeleton
                    variant="rounded"
                    animation="pulse"
                    height={39.974}
                    sx={{marginBottom: '1rem', marginTop: '2rem'}}
                  />
                }

                  {!isLoading ?
                    <MaintenanceCheck
                      string={'A manutenção foi concluída?'}
                      state={dataUpdate}
                      setState={setDataUpdate}
                      // disabled={edit}
                    />
                    :
                    <Skeleton
                      variant="rounded"
                      animation="pulse"
                      height={41.99}
                      sx={{mb: '1rem'}}

                    />
                  }

                  {!isLoading ?
                    <DateAndTime
                      labelDate={'Data de Início'}
                      labelTime={'Horário de início'}
                      setState={setDataUpdate}
                      valueFunction={initialDate}
                      timePicker={setTimePickerStart}
                      keyName={'start_date'}
                      mb={(dataUpdate?.end_date || isFinished) ? '1rem' : '2rem'}
                      // disabled={edit}
                    />
                    :
                    <DateSkeleton
                      mb={(dataUpdate?.end_date || isFinished) ? '1rem' : '2rem'}
                    />
                  }

                  {(dataUpdate?.end_date ) &&
                    <>
                      {!isLoading ?
                        <DateAndTime
                          labelDate={'Data de conclusão'}
                          labelTime={'Horário de conclusão'}
                          setState={setDataUpdate}
                          valueFunction={endDate}
                          timePicker={setTimePickerEnd}
                          keyName={'end_date'}
                          // disabled={edit}
                        />
                        :
                        <DateSkeleton/>
                      }
                    </>
                  }

                  {!isLoading ?
                    <MaintenanceType
                      state={dataUpdate}
                      setState={setDataUpdate}
                      disabled={true}
                    />
                    :
                    <Skeleton
                      variant="rounded"
                      animation="pulse"
                      height={39.974}
                      sx={{marginBottom: '2rem'}}
                    />
                  }

                  {!isLoading ?
                    <MaintenancePeriod
                      state={dataUpdate}
                      setState={setDataUpdate}
                      disabled={true}
                    />
                    :
                    <Skeleton
                      variant="rounded"
                      animation="pulse"
                      height={39.974}
                      sx={{marginBottom: '2rem'}}
                    />
                  }

                  {!isLoading ?
                    <MaintenanceTime
                      state={dataUpdate}
                      setState={setDataUpdate}
                      disabled={true}
                    />
                    :
                    <Skeleton
                      variant="rounded"
                      animation="pulse"
                      height={39.974}
                      sx={{marginBottom: '2rem'}}
                    />
                  }

                  {!isLoading ?
                    <MaintenanceValue
                      state={dataUpdate}
                      setState={setDataUpdate}
                      // disabled={edit}
                    />
                    :
                    <Skeleton
                      variant="rounded"
                      animation="pulse"
                      height={39.974}
                      sx={{marginBottom: '2rem'}}
                    />
                  }

                  {!isLoading ?
                    <MaintenanceCondoMailCheck
                      state={dataUpdate}
                      setState={setDataUpdate}
                      // disabled={edit}
                    />
                    :
                    <Skeleton
                      variant="rounded"
                      animation="pulse"
                      height={39.974}
                      sx={{marginBottom: '2rem'}}
                    />
                  }

                  {!isLoading ?
                    <MaintenanceMailNotes
                      state={dataUpdate}
                      setState={setDataUpdate}
                      error={error}
                      // disabled={edit}
                    />
                    :
                    <Skeleton
                      variant="rounded"
                      animation="pulse"
                      height={108.971}
                      sx={{marginBottom: '2rem'}}
                    />
                  }

                  {!isLoading ?
                    <Description
                      label={'O que será feito?'}
                      keyName={'description'}
                      state={dataUpdate}
                      setState={setDataUpdate}
                      // disabled={edit}
                      error={error}
                    />
                    :
                    <Skeleton
                      variant="rounded"
                      animation="pulse"
                      height={175.3}
                      width={535.339}
                      sx={{marginBottom: '0.5rem'}}
                    />
                  }

                  {!isLoading ?
                    <MaintenanceNotification
                      state={dataUpdate}
                      setstate={setDataUpdate}
                      // disabled={edit}
                    />
                    :
                    <Skeleton
                      variant="rounded"
                      animation="pulse"
                      height={39.974}
                      sx={{marginBottom: '2rem'}}
                    />
                  }
                  {!isLoading ?
                    <MaintenanceStatus
                      state={dataUpdate}
                      setState={setDataUpdate}
                      // disabled={edit}
                    />
                    :
                    <Skeleton
                      variant="rounded"
                      animation="pulse"
                      height={39.974}
                      sx={{marginBottom: '2rem'}}
                    />
                  }
              </>
                :
                <MaintenanceReadOnly
                  dataUpdate={dataUpdate}
                  setDataUpdate={setDataUpdate}
                  isLoading={isLoading}
                  services={services}
                  endDate={endDate}
                  setTimerPickerEnd={setTimePickerEnd}
                  handleSubmit={handleSubmit}
                />
              }

              <MaintenanceDeleteDialog
                dataDelete={dataDelete}
                openDelete={openDelete}
                setOpenDelete={setOpenDelete}
                deleteMaintenance={deleteMaintenance}
                setEditEvent={setEditEvent}
                setDataDelete={setDataDelete}
              />

            </>
          }

        />

      </>
  )
}


export default MaintenanceUpdate;
