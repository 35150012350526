import {Helmet} from "react-helmet";
import {
    Alert,
    Backdrop,
    Box,
    Button,
    Card, CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    Fade, GlobalStyles,
    Grid, TextField, Typography
} from "@mui/material";
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import InquiryStatement from "../BankOperations/components/BankStatement/InquiryStatement";
import AccInfoCard from "../BankOperations/components/BankStatement/AccInfoCard";
import BankStatementDownload from "../BankOperations/components/BankStatementDownload/BankStatementDownload";
import Lottie from "lottie-react";
import loading from "../../../assets/animations/loading.json";
import React, {useContext, useEffect, useState} from "react";
import SimpleText from "../../../components/Shared/SimpleText";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {api} from "../../../services/Main/Api";

import {useSnackbar} from "notistack";

import moment from "moment/moment";
import Context from "../../../contexts/Context";
import {useNavigate, useParams} from "react-router-dom";
import {Close} from "@mui/icons-material";
import PaymentCard from "../Payment/components/PaymentCard";
import RentalChargeDetail from "./RentalChargeDetail";
import Divider from "@mui/material/Divider";
import SimpleTypography from "../../../components/Shared/SimpleTypography";
import PaymentChecker from "../Payment/components/PaymentChecker";
import PayButton from "../Payment/components/PayButton";
import AuthValidation from "../../../components/Shared/AuthValidation";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import Masks from "../../../components/Shared/Masks";
import SimpleDialog from "../../../components/Shared/SimpleDialog";

const RentalChargePayment = ({ charge, handleClose, handleOpen, setPaymentSuccess }) => {

    const { filters, setFilters, setLoadingModal, dataProvider, sessionCheck, setDataProvider } = useContext(Context)
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()

    const { operationType } = filters || {}

    const [data, setData] = useState([])
    const [paymentReq, setPaymentReq] = useState([])
    const [error,setError] = useState(null)
    const [requirement, setRequirement] = useState(false)
    const [url, setUrl] = useState(null)
    const [errorDue, setErrorDue] = useState(null)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [open, setOpen] = useState(false);
    const { openPayDialog} = filters || {}
    const [selectedCharge, setSelectedCharge] = useState(charge);




    useEffect(() => {
        if (data?.barcode && data?.barcode?.length >= 47) {
            setTimeout(() => {
                !dataProvider?.validation && sessionCheck();
                dataProvider?.validation && handleSubmitPreview();
            }, 2000);
        }
    }, [data?.barcode]);


    const handleSubmitPreview = () => {

        // Verifica se data não está vazio
        if (data && Object.keys(data).length !== 0) {
            setRequirement(true)

            const formatData = {
                ...data,
                condo: +getCondoId()
            }

            api.post(`payment/read_barcode/`, formatData)
                .then(response => {
                    setPaymentReq(response.data)
                    setData([])
                    filters?.operationType === 'payment' && setFilters(prevState => ({...prevState, payConfirmation: true}))
                    setError(null)
                })
                .catch(error => {
                    error.response.data && setError(error.response.data)
                    error.response.data.error && setError(error.response.data.error)
                    setPaymentReq(null)
                    console.log(error.response.data)
                    setRequirement(false)
                })
        } else {
            handleOpen()
        }
    }

    function errorHandling(error) {
        switch (error) {
            case 'Não existe resultado para informação solicitada.':
                return setDataProvider(prevState => ({...prevState, paymentStep: true}))
            case 'APB0002 - Código de Barras não localizado.':
                return setDataProvider(prevState => ({...prevState, paymentStep: true}))
            default:
                return setDataProvider(prevState => ({...prevState, paymentStep: false}))
        }
    }

    function checkDue() {

        const formatData = {
            condo: +getCondoId(),
            barcode: paymentReq?.Barcode
        }

        api.post(`payment/read_cip_barcode/`, formatData)
            .then(response => {
                setPaymentReq(prevState => ({ ...prevState, ...response.data }));
                setDataProvider(prevState => ({...prevState, paymentStep: true}))
                setError(null)
            })
            .catch(error => {
                errorHandling(error.response.data.error)
                console.log(error.response.data.error)
                error.response.data.error && setError(error.response.data.error)
                setErrorDue(error.response.data.error)
                // setDataProvider(prevState => ({...prevState, paymentStep: false}))
            }).finally(()=>{
            setRequirement(false)
        })
    }

    useEffect(function waitBarcode (){
        if (paymentReq?.Barcode){
            checkDue()
        }

    },[paymentReq?.Barcode])


    useEffect(function expenseReq(){
        filters?.paymentReq === true && handleSubmitPayment()
    }, [filters?.paymentReq])


    function duplicatedPaymentChecker () {

        const data = {
            condo: +getCondoId(),
            barcode: paymentReq?.Barcode,
            value: +paymentReq?.Value?.toString()?.replace(/\D+/g, "")
        }

        api.post(`/payment/last/`, data)
            .then(response => {
                setFilters(prevState => ({...prevState, duplicityChecker: response.data }))
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(function handlePaymentEffect() {
        if (filters && filters.duplicityChecker) {
            if (filters.duplicityChecker.success) {
                handleSubmitPayment();
            } else {
                setFilters(prevState => ({...prevState, openPayDialog: true}));
            }
        }
    }, [filters?.duplicityChecker])


    const handleSubmitPayment = () => {
        if (dataProvider?.validation === true) {
            if (paymentReq && Object.keys(paymentReq).length !== 0) {

                // e.preventDefault()
                setRequirement(true)
                filters?.paymentReq && setLoadingModal(true)

                const formattedData = {
                    ...paymentReq,
                    condo: +getCondoId(),
                    barcode: paymentReq?.Barcode,
                    principal_value: paymentReq?.Value,
                    payment_date: selectedDate,
                    expense: filters?.expenseId ?? null,
                    rental_charge_payment: selectedCharge?.id ?? null
                }


                api.post(`/payment/`, formattedData)
                    .then(response => {
                        enqueueSnackbar('Pagamento efetuado', {variant: 'success'})
                        setPaymentReq([])
                        setData([])
                        setDataProvider(prevState => ({...prevState, paymentStep: false}))
                        setUrl(response.data.receipt)
                        setErrorDue(null)
                        setError(null)
                        filters?.paymentReq && setFilters(prevState => ({
                            ...prevState,
                            receiptUrl: response.data.receipt,
                            paymentDone: true,
                            secondStep: true,
                            operationType: ''
                        }))
                        setPaymentSuccess(true)
                        setTimeout(() => {
                            handleClose();
                        }, 1000);
                        // Trigger for ExpenseCreate
                    })
                    .catch(error => {
                        console.log(error, '7')
                        filters?.paymentReq && setFilters(prevState => ({...prevState, paymentReq: false}))
                        error.response.data.barcode && setError(error.response.data.barcode)
                        error.response.data && setError(error.response.data)
                        const errorMsg = error.response && error.response.data && error.response.data.error ?
                            error.response.data.error :
                            'Erro ao realizar pagamento';

                        enqueueSnackbar(errorMsg, {variant: 'error'})

                        // Trigger for ExpenseCreate
                        // filters?.paymentReq === true && setFilters(prevState => ({...prevState, paymentFail: true}))
                    })
                    .finally(() => {
                        setRequirement(false)
                        setLoadingModal(false)
                    })
            } else {
                handleOpen()
            }
        } else {
            enqueueSnackbar('Sem permissão, efetuar etapa de segurança', {variant: 'error'})
            setDataProvider(prevState => ({openAuth: true, validation: false}))
        }

    }

    const handleDateChange = (date) => {
        date.setHours(new Date().getHours(), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds());
        const newDate = new Date(date);

        setSelectedDate(newDate);
    };


    return (
        <>
            {!operationType && !dataProvider?.paymentStep ?
                <>
                    <Helmet>
                        <title>Pagamento de Boletos - Bloco B3</title>
                    </Helmet>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={requirement}
                    >
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                        <Card
                            sx={{
                                width: '100%',

                            }}
                        >
                            <GlobalStyles styles={{
                                '.MuiInputBase-input': {
                                    textAlign: 'center',
                                }
                            }}
                            />
                            <CardContent>
                            <RentalChargeDetail charge={selectedCharge}
                            />
                        <Divider variant="middle" />
                                <Grid container alignItems="center" justifyContent="center">
                                    <Grid item xs={10}>
                                        <TextField
                                            label={'Código de barras'}
                                            variant='outlined'
                                            inputProps={{
                                                pattern: "\\d*"
                                            }}
                                            value={data.barcode}
                                            error={!!error}
                                            helperText={error}
                                            onChange={(e) => setData({...data, barcode: e.target.value})}
                                            fullWidth
                                            data={data}
                                            setData={setData}
                                            setPaymentReq={setPaymentReq}
                                            paymentReq={paymentReq}
                                            setError={setError}
                                            handleSubmitPreview={handleSubmitPreview}
                                            duplicatedPaymentChecker={duplicatedPaymentChecker}
                                            size={'small'}
                                            sx={{
                                                textAlign: "center",
                                                mt: '2rem',
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                            </CardContent>
                    <Divider variant="middle"  sx={{p:2}}/>
                    <CardActions sx={{backgroundColor: '#fff'}}>
                        <Grid sx={{mt: 1, p:2}}>
                            <Grid container spacing={2} justifyContent={'space-between'}>
                                <Grid item>
                                    <Button
                                        onClick={() => {
                                            handleClose()
                                            setOpen(false)

                                        }}
                                        sx={{ mb: '1rem' }}
                                    >
                                        Fechar
                                    </Button>
                                </Grid>

                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
                            </>
                                :
                <Card>
                <CardContent>
                    <RentalChargeDetail charge={charge}
                    />
                    <Divider variant="middle" />
                    <Grid container sx={{p:2}}>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box>
                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                    {'Beneficiario'}
                                </Typography>
                                <Typography variant="body1">
                                    {paymentReq?.BeneficiaryName ? paymentReq?.BeneficiaryName : '-'}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} container justifyContent="flex-end">
                            <Box>
                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                    {'Vencimento'}
                                </Typography>
                                <Typography variant="body1">
                                    {paymentReq?.DueDate ? moment(paymentReq?.DueDate?.replace('Z', ''))?.format('DD/MM/YYYY') : '-'}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box>
                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                    {'Valor da Multa'}
                                </Typography>
                                <Typography variant="body1">
                                    {paymentReq?.TotalAddedValue !== undefined && paymentReq?.TotalAddedValue !== null
                                        ? Masks.money(paymentReq.TotalAddedValue.toString())
                                        : ''}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} container justifyContent="flex-end">
                            <Box>
                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                    {'Valor Final'}
                                </Typography>
                                <Typography variant="body1">
                                    {paymentReq?.Value ? Masks.money(Number(paymentReq.Value).toFixed(2).toString()) : ''}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                                {moment(paymentReq?.DueDate?.replace('Z', ''))?.format('DD/MM/YYYY') < moment()?.format('DD/MM/YYYY') &&
                                    <Box
                                        sx={{
                                            marginY: '1rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                        <Alert
                                            severity="warning"

                                        >
                                            Este boleto está atrasado. Por favor, atente-se ao cálculo da multa.
                                        </Alert>
                                    </Box>
                                }




                                {operationType === 'payment' &&
                                    <Box
                                        sx={{
                                            marginY: '1rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Alert
                                            severity="info"
                                        >
                                            Ao clicar em "PAGAR" o agendamento será confirmado
                                        </Alert>
                                    </Box>
                                }

                                    <Grid container alignItems='center' justifyContent='space-between' sx={{mt:2}}>
                                        <Grid item>
                                            <DatePicker
                                                label="Selecione a Data de Pagamento"
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                renderInput={(params) => <TextField  size={'small'} {...params} />}
                                            />
                                        </Grid>
                                    </Grid>
                        {error &&
                            <Grid item xs={12}>
                                <Typography variant="body2" color="error">{error}</Typography>
                            </Grid>
                        }
                    </Grid>
                </CardContent>
                    <Divider variant="middle" />
                    <CardActions>
                                    <Grid container spacing={2} justifyContent={'space-between'} sx={{p:2}}>
                                        <Grid item>
                                            <Button
                                                onClick={() => {
                                                    setDataProvider(prevState => ({...prevState, paymentStep: false}))
                                                    handleClose()

                                                }}
                                                sx={{ mb: '1rem' }}
                                            >
                                                Fechar
                                            </Button>
                                        </Grid>

                                        {operationType !== 'payment' &&
                                            <Grid item>
                                                <Button
                                                    variant={'contained'}
                                                    onClick={() => duplicatedPaymentChecker() }
                                                >
                                                    Efetuar pagamento
                                                </Button>
                                            </Grid>
                                        }
                                    </Grid>
                    </CardActions>
                    </Card>
            }

            <SimpleDialog
                title={'Pagamento duplicado'}
                disableBackdropClick
                open={openPayDialog}
                onClickCancel={() => setFilters(prevState => ({...prevState, openPayDialog: false }))}
                question={'Esse pagamento já foi agendado. Deseja prosseguir mesmo assim?'}
                onClickConfirm={() => {
                    setFilters(prevState => ({...prevState, openPayDialog: false, duplicityChecker: {success: true} }))
                }}
            />
        </>
    )
}

export default RentalChargePayment