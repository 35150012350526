import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useContext } from 'react'
import { api } from '../../services/Main/Api'
import {getCondoId} from "../../services/Main/AuthStorage";
import Context from "../../contexts/Context";

const OwnerSearch = ({ state, setState, keyName, defaultValue, styles, variant, valueProp, error, label, unitParam }) => {
  const { setFilters, ownerOptions, getOwnerOptions } = useContext(Context)

  useEffect(() => {
    if (unitParam && !ownerOptions) {
      getOwnerOptions(unitParam)
    }
  }, [unitParam])

  const alternateState = setState ?? setFilters

  return (
    <>
      <Autocomplete
        fullWidth
        disabled={ownerOptions?.length < 1}
        onChange={(_event,value) => alternateState((prevState) => ({...prevState, [keyName ?? 'unit']: value?.value }))}
        defaultValue={defaultValue}
        value={valueProp}
        renderInput={(props) => <TextField
          {...props}
          variant={variant}
          label={label ?? 'Proprietário'}
          size={"small"}
          error={!!error?.[keyName]}
          helperText={error?.[keyName]}
        />}
        getOptionLabel={(option) => `${option.label}` ?? ['']}
        options={ownerOptions ?? ['']}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        sx={styles}
        renderOption={(props, option) => (
          <li {...props} key={option.key}>
            {`${option.label}`}
          </li>
        )}
      />
    </>
  )
}

export default OwnerSearch
