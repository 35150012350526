import React from "react";
import {
  Autocomplete,
  Grid,
  Skeleton,
  TextField
} from "@mui/material";

const CondoUf = ({
    uf,
    condoData,
    setCondoData,
    error
  }) => {

  return (
    <Grid item xs={12} sm={4}>
      {condoData ?
        <Autocomplete
          fullWidth
          size={"small"}
          isOptionEqualToValue={(option, value) => typeof option === typeof value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          disableClearable
          onChange={
            (_event, value) => {
              setCondoData((prevState) => ({ ...prevState, state: value ?? '' }))
            }}
          value={condoData?.state ?? ''}
          options={uf?.map((option) => option ?? '')}
          renderInput={(params) => <TextField
            {...params}
            label="UF"
            error={!!error?.uf ?? ''}
            helperText={error?.uf}
          />}
        />
        :
        <Skeleton
          variant="rounded"
          animation="pulse"
          sx={{
            height: '39.974px',
          }}
        />
      }
    </Grid>
  )
}

export default CondoUf
