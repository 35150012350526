import React from "react";
import {Typography} from "@mui/material";

const CommentCounter = ({ state }) => {
  return(
    <Typography
      sx={{
        mb: '1rem',
        mt: '2rem'
      }}
    >
      {`${state?.count} comentários`}
    </Typography>
  )
}

export default CommentCounter