import React from "react";
import moment from "moment/moment";
import {
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import { Close } from "@mui/icons-material";

const TableAreaBody = ({
    areas,
    setEditEvent
  }) => {

  return (
    <TableBody>
      { areas?.results?.map((area, index) => (
        <TableRow
          hover
          tabIndex={-1}
          key={index}
          sx={{ 'cursor': 'pointer' }}
          onClick={(e) => {
            setEditEvent({ open: true, id: area?.id})
          }}
        >

          <TableCell
            component="th"
            scope="row"
            sx={{ display: 'flex', alignItems: 'center' }}

          >
            {area.name}
          </TableCell>
          <TableCell
            align="left"
          >
            {area.description ?? <Close />}

          </TableCell>
          <TableCell
            align="left"
          >
            {area.capacity ?? <Close />}
          </TableCell>
          <TableCell
            align="left"
          >
            {moment(area.created_at).format('DD/MM/yyyy')}
          </TableCell>
        </TableRow>
      ))}

    </TableBody>
  )
}

export default TableAreaBody