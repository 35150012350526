import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import {
  Avatar,
  Backdrop,
  Box,
  Card,
  CardContent, CardHeader, CircularProgress, Skeleton,
} from '@mui/material'
import { api } from '../../../services/Main/Api'
import {getCondoId, getCondoName, setCondoName} from '../../../services/Main/AuthStorage'
import { useSnackbar } from 'notistack'
import Context from "../../../contexts/Context";
import CondoInfo from "./components/CondoUpdate/CondoInfo/CondoInfo";
import CondoSpecs from "./components/CondoUpdate/CondoSpecs/CondoSpecs";
import CondoChargeConfig from "./components/CondoUpdate/CondoChargeConfig/CondoChargeConfig";
import SimpleButtons from "../../../components/Shared/SimpleButtons";
import {Lock} from "@mui/icons-material";
import Branding from "../User/Branding";

const CondoUpdate = () => {
  const {setCondoNameLogin} = useContext(Context)

  const { enqueueSnackbar } = useSnackbar()

  const [condoData, setCondoData] = useState(null)
  const [error, setError] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [image, setImage] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  function cleanup () {
    URL.revokeObjectURL(image)
  }

  function setImg(newImage) {
    if (image) {
      cleanup()
    }
    setImage(newImage)
  }

  async function getCondoData() {
    if (getCondoId()) {
      const condo = getCondoId();
      try {
        const res = await api.get(`/condo/${condo}/`);
        setCondoData(res.data);
        setImg(res.data.logo);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
  }

  async function handleSubmit() {
    try {
      setSubmitting(true);
      const condo = getCondoId();

      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      const payload = {
        ...condoData,
        logo: typeof condoData?.logo !== 'string' ? condoData?.logo : null,
        gas_value: condoData?.gas_value?.toString()?.replace(/[^0-9\s]/g, ''),
        fine_value: condoData?.fine_value?.toString()?.replace(/[^0-9\s]/g, ''),
        interest_value: condoData?.interest_value?.toString()?.replace(/[^0-9\s]/g, '')
      };

      setError(null);

      const res = await api.put(`/condo/${condo}/`, payload, headers);
      enqueueSnackbar('Conta atualizada', { variant: 'success' });
      if (res.data.name !== getCondoName()) {
        setCondoName(res.data.name);
        setCondoNameLogin(res.data.name);
        await getCondoData();
      }
    } catch (err) {
      enqueueSnackbar('Erro ao atualizar conta', { variant: 'error' });
      console.error(err);
      setError(err.response.data);
    } finally {
      setSubmitting(false);
    }
  }


  useEffect(function deletePercentInputs(){
    if(condoData?.interest_percent?.length < 1 || condoData?.fine_percent?.length < 1){
      delete condoData?.interest_percent
      delete condoData?.fine_percent
    }
  },[condoData])

  useEffect(() => {
    getCondoData()
  }, [])

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitting}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
      <Box>
        <Card>
          {!isLoading ?
            <>
              <CardHeader
                avatar={
                  <Avatar
                    sx={{
                      bgcolor: 'primary.main'
                    }}>
                    <Lock/>
                  </Avatar>}
                title={'Dados da Conta'}
              />
              <CardContent>
                <Branding
                  imageFile={condoData}
                  setImageFile={setCondoData}
                  setImg={setImg}
                  image={image}
                  setImage={setImage}
                />
                <CondoInfo
                  condoData={condoData}
                  setCondoData={setCondoData}
                  error={error}
                />

                <CondoSpecs
                  condoData={condoData}
                  setCondoData={setCondoData}
                  error={error}
                />

                <CondoChargeConfig
                  condoData={condoData}
                  setCondoData={setCondoData}
                  error={error}
                />

              </CardContent>
            </>
            :
            <Skeleton
              variant="rounded"
              height={700}
              animation="pulse"
            />
          }

        </Card>

        <SimpleButtons
          type={'submit'}
          forwardButton={'Salvar'}
          onForward={() => handleSubmit()}
          backButton={'Voltar'}
        />

      </Box>
    </>
  )
}

export default CondoUpdate
