import {
    Alert,
    Avatar, Backdrop, Badge,
    Box, Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
    Divider, Grid,
    IconButton, Skeleton, TextField, Tooltip, Typography, useMediaQuery,
} from "@mui/material";
import {
    DescriptionOutlined,
    FileCopyOutlined,
    ForwardToInboxOutlined, Lock, NotificationsOutlined,
    Receipt, UploadFile
} from "@mui/icons-material";
import React, {useContext, useEffect, useState} from "react";
import { api } from "../../../services/Main/Api";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { getCondoId } from '../../../services/Main/AuthStorage'
import Context from "../../../contexts/Context";
import Branding from "../User/Branding";
import CondoInfo from "../Condo/components/CondoUpdate/CondoInfo/CondoInfo";
import CondoSpecs from "../Condo/components/CondoUpdate/CondoSpecs/CondoSpecs";
import CondoChargeConfig from "../Condo/components/CondoUpdate/CondoChargeConfig/CondoChargeConfig";
import Masks from "../../../components/Shared/Masks";
import { useDropzone } from 'react-dropzone';


const LimitBankDetail = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { ownerOptions, getOwnerOptions } = useContext(Context)
    const params = useParams()
    const {id} = params
    const navigate = useNavigate()

    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [duplicateData, setDuplicateData] = useState(null)
    const [edit, setEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const companyId = getCondoId()
    const sizeMatch = useMediaQuery('@media (min-width:600px)')
    const [open, setOpen] = useState(false);
    const [newValue, setNewValue] = useState( null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const handleFilesChange = (files) => {
        setSelectedFiles(oldFiles => [...oldFiles, ...files]);
        const names = files.map((file) => file.name);
        setFileNames(oldNames => [...oldNames, ...names]);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'application/pdf/*',
        onDrop: (acceptedFiles) => {
            handleFilesChange(acceptedFiles);
        },
        multiple: true
    });

    async function fetchData() {
        setIsLoading(true)
        try {
            const response = await api.get(`/fitbank/limit-bank/${companyId}/`);
            setData(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    const postIncreaseLimitRequest = async (value, files) => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('limit_value', newValue.replace(/[^0-9]/g, ''));
        files.forEach((file) => {
            formData.append('document_file', file);
        });
        formData.append('company', companyId);

        console.log(formData)
        try {
            await api.put(`fitbank/limit-bank/${companyId}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setIsLoading(false)
            enqueueSnackbar('Sucesso ao enviar sua solicitação!', { variant: 'success' });
            fetchData();
        } catch (error) {
            setIsLoading(false)
            const errorMessage = error.response?.data[0] || 'Erro ao enviar sua solicitação!';
            console.log(error.response.data[0], error);
            enqueueSnackbar(errorMessage, { variant: 'error' });
        }
    }

    const onSubmit = () => {
        if (selectedFiles.length && newValue) {
            postIncreaseLimitRequest(newValue, selectedFiles);  // pass selectedFiles here
            handleClose()
        } else {
            console.error('Value ou selectedFiles não foram definidos');
        }
    }

    let message = "";
    if (data?.new_limit) {
        message = `Já existe uma solicitação de aumento de limite em análise.
         Para o valor ${Masks.money(String(data.limit_increase_suggestion))},
          é necessario aguardar a conclusão para uma nova solicitação`;
    }
   else {
        message = "Por favor, digite o valor desejado para o aumento do limite mensal." +
            " Os demais limites serão calculados pelo sistema.";
    }

    useEffect(() => {
        if (data) {
            setNewValue(Masks.money(String(data.limit_increase_suggestion)))
        }
    }, [data]);

    useEffect(function pageLoad(){
        fetchData()
    },[])

    let value = newValue;
    value = value?.replace("R$", "").replace(/\./g, "").replace(",", "").trim();
    let integerValue = parseInt(value, 10);

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: 1500, }}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Box>
                <Card>
                    {!isLoading ?
                        <>
                            <CardHeader
                                avatar={
                                    <Avatar
                                        sx={{
                                            bgcolor: 'primary.main'
                                        }}>
                                        <AccountBalanceOutlinedIcon/>
                                    </Avatar>}
                                title={'Limite Bancário'}
                                action={
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size={"small"}
                                        sx={{mt:1, mr: 1}}
                                        onClick={handleClickOpen}>
                                        Solicitar Limite
                                    </Button>
                                }
                            />
                            <Divider sx={{ my: '.5rem' }}/>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={2} mb={'1.5rem'}>
                                        <Grid item xs={4}>
                                            <Typography fontWeight="bold" align="left">LIMITE</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography fontWeight="bold" align="center">PIX</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography fontWeight="bold" align="center">TED</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} mb={'.5rem'}>
                                        <Grid item xs={4}>
                                            <Typography>Diário</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">{data?.daily_limit_pix && data.daily_limit_pix > 0
                                                ? Masks?.money(data.daily_limit_pix.toString())
                                                : '-'}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">{data?.daily_limit_ted && data.daily_limit_ted > 0
                                                ? Masks?.money(data.daily_limit_ted.toString())
                                                : '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mb={'.5rem'}>
                                        <Grid item xs={4}>
                                            <Typography>Mensal</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">{data?.monthly_limit_pix && data.monthly_limit_pix > 0
                                                ? Masks?.money(data.monthly_limit_pix.toString())
                                                : '-'}
                                           </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">
                                                {data?.monthly_limit_ted && data.monthly_limit_ted > 0
                                                    ? Masks?.money(data.monthly_limit_ted.toString())
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mb={'.5rem'}>
                                        <Grid item xs={4}>
                                            <Typography>Noturno</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">
                                                {data?.overnigth_limit_pix && data.overnigth_limit_pix > 0
                                                    ? Masks?.money(data.overnigth_limit_pix.toString())
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">
                                                {data?.overnigth_limit_ted && data.overnigth_limit_ted > 0
                                                    ? Masks?.money(data.overnigth_limit_ted.toString())
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mb={'.5rem'}>
                                        <Grid item xs={4}>
                                            <Typography>Por Transação</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">
                                                {data?.transaction_limit_pix && data.transaction_limit_pix > 0
                                                    ? Masks?.money(data.transaction_limit_pix.toString())
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">
                                                {data?.transaction_limit_ted && data.transaction_limit_ted > 0
                                                    ? Masks?.money(data.transaction_limit_ted.toString())
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mb={'.5rem'}>
                                        <Grid item xs={4}>
                                            <Typography>Mesma Titularidade</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">
                                                {data?.same_ownership_limit_pix && data.same_ownership_limit_pix > 0
                                                    ? Masks?.money(data.same_ownership_limit_pix.toString())
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">
                                                {data?.same_ownership_limit_ted && data.same_ownership_limit_ted > 0
                                                    ? Masks?.money(data.same_ownership_limit_ted.toString())
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <Dialog open={open} onClose={handleClose} data={data}>
                                <DialogTitle>Solicitar Aumento de Limite</DialogTitle>
                                <Divider sx={{ mt: '.5rem' }}/>
                                <DialogContent sx={{maxWidth: '500px', minWidth: '500px'}}>
                                    {message &&
                                        <Alert
                                            severity={'info'}
                                            sx={{
                                                mb: '1rem',
                                            }}
                                        >
                                            {message}
                                        </Alert>
                                    }
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        disabled={data?.new_limit}
                                        id="value"
                                        label="Valor Solicitado para Limite Mensal"
                                        placeholder={newValue}
                                        onChange={({target: {value}}) => setNewValue(value)}
                                        value={newValue ? Masks.money(String(newValue)) : ""} sx={{
                                        mb: '1rem'
                                    }}
                                    />
                                    <Box
                                        {...(data?.new_limit ? {} : getRootProps())}
                                        sx={{
                                        border: '2px dotted gray',
                                        borderRadius: 1,
                                        padding: '10px',
                                        textAlign: 'center',
                                        marginTop: '10px',
                                        cursor: 'pointer',
                                        backgroundColor: '#f3f3f3'
                                    }}>
                                        <input {...getInputProps()} />
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            {!fileNames.length ? (
                                                <>
                                                    <UploadFile sx={{color: 'gray'}}/>
                                                    <p style={{color: 'gray'}}>Comprovante de Faturamento</p>
                                                </>
                                            ) : (
                                                <p style={{color: 'gray'}}>{fileNames.join(', ')}</p>
                                            )}
                                        </Box>
                                    </Box>
                                </DialogContent>
                                <DialogActions sx={{justifyContent: 'space-between', p: 2}}>
                                    <Button
                                        onClick={handleClose}
                                        size={"small"}
                                        color="primary">
                                        Cancelar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        disabled={
                                            data?.new_limit ||
                                            (parseInt(integerValue, 10) <= parseInt(data?.monthly_limit_ted, 10))
                                        }
                                        onClick={onSubmit}
                                        size={"small"}
                                        color={"primary"}
                                    >
                                        Solicitar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                        :
                        <Skeleton
                            variant="rounded"
                            height={700}
                            animation="pulse"
                        />
                    }

                </Card>

            </Box>
        </>
    )
}

export default LimitBankDetail;