import {Box, Checkbox, FormControlLabel, Typography} from "@mui/material";
import React from "react";

const MaintenanceReadCheck = ({dataUpdate, setHasEndDate}) => {
  return (
    <>
      {!dataUpdate?.end_date &&
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            m: '0.3rem',
            mb: '0.5rem'
          }}
        >
          <Typography>
            A manutenção foi concluída?
          </Typography>
          <FormControlLabel
            control={<Checkbox
              sx={{ml: '1rem'}}
              onChange={(event, checked) => setHasEndDate(checked)}
            />}
            label="Sim" />
        </Box>
      }
    </>

  )
}

export default MaintenanceReadCheck