import {Box, Button, Grid, Skeleton, Typography} from "@mui/material";
import CardFiles from "../Expense/components/ExpenseCreateUpdate/ExpenseDocuments/dependencies/CardFiles";
import DragAndDrop from "../../../components/DragAndDrop/DragAndDrop";
import React, {useContext} from "react";
import Context from "../../../contexts/Context";
import {useParams} from "react-router-dom";

const RentDocuments = (props) => {
  const { loadingDocuments, getDocuments, documentPromise} = props
  const { userFiles, deleteApiFiles, setUserfiles, setDeleteApiFiles} = useContext(Context)
  const params = useParams()

  const checkDocuments = (userFiles?.length > 0 || deleteApiFiles?.length > 0 ) && params.id

  function whenCancel(){
    setUserfiles([])
    setDeleteApiFiles([])
    getDocuments(!!params.id)
  }


  return (
    <Grid item xs={12}>
      {!loadingDocuments ?
          <CardFiles>
            <DragAndDrop
              dropText={
              <Box>
                <Typography>
                  Arraste ou clique para adicionar
                </Typography>
                <Typography>
                  Máximo de 100 mb por arquivo
                </Typography>
              </Box>
              }
              drop={true}
              deletable={true}
              styles={{
                mt: '1rem',
              }}
            />
            {checkDocuments &&
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Button
                  onClick={() => whenCancel()}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={() => {
                    documentPromise(params.id)
                    }
                  }
                  variant={'contained'}
                >
                  Salvar
                </Button>
              </Box>
            }
          </CardFiles>

        :
        <Skeleton
          height={200}
          width={'100%'}
          variant='rounded'
        />
      }
    </Grid>
  )
}

export default RentDocuments