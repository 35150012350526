import { Box, Card, CardContent } from '@mui/material'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Category, Announcement, Send} from '@mui/icons-material'
import { useLocation } from 'react-router-dom'

const ComplaintSideBar = () => {
  const location = useLocation()

  return (
    <>
      <Box>
        <Card>
          <CardContent>
            <List component={'nav'}>
              <ListItemButton
                href={"/reclamacoes"}
                selected={location.pathname === '/reclamacoes'}
              >
                <ListItemIcon><Announcement/></ListItemIcon>
                <ListItemText primary={'Reclamações'}/>
              </ListItemButton>


              <ListItemButton
                href={"/reclamacoes/registrar"}
                selected={location.pathname === '/reclamacoes/registrar'}
              >
                <ListItemIcon><Send/></ListItemIcon>
                <ListItemText primary={'Registrar Reclamação'}/>
              </ListItemButton>

              <ListItemButton
                href={"/reclamacoes/categorias"}
                selected={location.pathname === '/reclamacoes/categorias'}
              >
                <ListItemIcon><Category/></ListItemIcon>
                <ListItemText primary={'Categorias'}/>
              </ListItemButton>


            </List>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

export default ComplaintSideBar
