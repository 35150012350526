import React, {
  useEffect,
  useState,
  useContext
} from 'react'
import {
  Backdrop,
  Box,
  Card, CardContent, Container,
  Pagination,
  Skeleton,
  Table,
  TableContainer,
} from '@mui/material'
import { api } from '../../../services/Main/Api'
import { getCondoId } from '../../../services/Main/AuthStorage'
import {AddCircle } from '@mui/icons-material'
import { Helmet } from 'react-helmet'
import Context from "../../../contexts/Context";
import TableUnitHead from "./components/UnitList/TableUnitHead";
import TableUnitBody from "./components/UnitList/TableUnitBody";
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import SearchField from "../../../components/Shared/SearchField";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import {useLocation, useParams} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

export const UnitList = () => {
  const location = useLocation()

  const { filters, setFilters, contextLoading } = useContext(Context)
  useEffect(function setInitialFilter (){
    setFilters({
      page: 1,
      page_size: 50,
      condo_id: getCondoId(),
    })
  },[])

  const [response, setResponse] = useState(null)
  const [verifier, setVerifier] = useState(null)
  const handlePage = (e, value) => {
    setFilters({ ...filters, page: value })
  }

  useEffect(() => {
    if (filters) {
      api.get(`unit/`, { params: filters })
      .then(response => {
        setResponse(response.data)
      }).catch(error => {
        console.log(error)
      }).finally(r =>{
        setVerifier(true)
      })
    }
  }, [filters])

  const isAluguel = location.pathname.includes('alugueis');
  const basePath = isAluguel ? 'alugueis' : 'condominio';

  return (
    <>
      <Helmet>
        <title>Imóveis - Bloco B3</title>
      </Helmet>
        <Backdrop
          sx={{ color: '#fff', zIndex: 1500, }}
          open={contextLoading}
        >
          <CircularProgress color="inherit"/>
        </Backdrop>
        <Container
          maxWidth={false}
        >
            <TitleBtnList
              title={'Imóveis'}
              buttonText={'Criar Imóvel'}
              buttonHref={`/${basePath}/imoveis/criar`}
              icon={<AddCircle />}
            />
          <Card>
            {verifier ?
            <CardContent>
            <SearchField
              label={"Nome | Nº da imóvel | E-mail | Telefone"}
              state={filters}
              setState={setFilters}
              keyName={'search'}
              boxStyles={{
                maxWidth:'400px',
                mb: '1rem'
              }}
            />
          <TableContainer>
              <>
                {response?.results?.length > 0 ?
                  <Table sx={{minWidth: 800}} >
                    <TableUnitHead />
                    <TableUnitBody
                      response={response}
                    />
                  </Table>
                  :
                  <NoResultsAnimation />
                }
              </>
            </TableContainer>
          </CardContent>
            :
          <Skeleton
            variant="rounded"
            height={700}
            animation="pulse"
          />
          }
        </Card>
          {response?.results &&
            <Box spacing={2} display={'flex'} justifyContent={'center'} sx={{ m: 3 }}>
              <Pagination
                count={Math.ceil(+response?.count / filters.page_size)}
                variant={'outlined'}
                color={'primary'}
                onChange={handlePage}
              />
            </Box>
          }
        </Container>


    </>
  )
}
