import { Breadcrumbs, Link, Typography } from '@mui/material'
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom'
import { Home, NavigateNextOutlined } from '@mui/icons-material'
import { api } from '../../services/Main/Api'
import { useEffect, useState } from 'react'

const getRoutePath = (location, params) => {
  const pathname = location

  if (!Object.keys(params).length) {
    return pathname
  }

  let path = pathname

  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`)
    }
  })

  return path
}

const BreadCrumbs = () => {
  const location = useLocation()
  const params = useParams()
  const pathNames = location.pathname.split('/').filter((x) => x)
  const LinkRouter = (props) => <Link {...props} component={RouterLink}/>
  const [unitNumber, setUnitNumber] = useState('')

  useEffect(function unitNumber () {
    if (location.pathname.includes('/imoveis/') && params.id !== undefined) {
      api.get(`unit/${params.id}`).then(res => {
        setUnitNumber(res.data.number)
      })
    }

  }, [location.pathname, params.id])

  const breadcrumbNameMap = {
    '/': 'Dashboard',
    '/contas': 'Contas',
    '/condominio/criar': 'Registrar Condomínio',
    '/faturas': 'Faturas',
    '/faturas/:id': 'Detalhes da Fatura',
    '/cobranca': 'Cobrança Individual',
    '/cobranca/:id': 'Detalhes da Cobrança',
    '/despesas': 'Despesas',
    '/despesas/criar': 'Criar Despesa',
    '/despesas/:id/editar/:category': 'Editar Despesa',
    '/extrato': 'Extrato Bancário',
    '/perfil': 'Perfil',
    '/perfil/alterar-senha': 'Alterar Senha',

    '/configuracoes/contas': 'Contas',
    '/configuracoes/dadoscondo' : 'Dados do condomínio',
    '/alugueis/imoveis': 'Imóveis',
    '/alugueis/imoveis/:id/detalhes': `${unitNumber}`,
    '/alugueis/imoveis/:id/editar': ` Editar  Imóvel ${unitNumber}`,
    '/alugueis/imoveis/criar': 'Registrar Imóvel',

    '/condominio/imoveis': 'Imóveis',
    '/condominio/imoveis/:id/detalhes': `${unitNumber}`,
    '/condominio/imoveis/:id/editar': ` Editar  Imóvel ${unitNumber}`,
    '/condominio/imoveis/criar': 'Registrar Imóvel',


    '/configuracoes/bloco': 'Blocos',
    '/configuracoes/blocos/criar': 'Registrar bloco',
    '/configuracoes/blocos/editar': 'Editar bloco',
    '/configuracoes/blocos/detalhes': 'Detalhes do bloco',
    '/configuracoes/categorias' : 'Categorias',
    '/configuracoes/relatorios' : 'Relatórios',
    '/configuracoes/documentos' : 'Documentos',


    '/notificacoes': 'Notificações',
    '/transferencia': 'Transferência',
    '/reclamacoes' : 'Reclamações',
    '/reclamacoes/registrar' : 'Registrar Reclamação',
    '/reclamacoes/categorias' : 'Categorias de Reclamação',
    '/manutencao' : 'Manutenções',
    '/manutencao/servicos' : 'Prestadores de Serviços',
    '/manutencao/servicos/cadastrar' : 'Cadastrar Prestador',
    '/manutencao/servicos/prestador/:id' : 'Detalhes do Prestador',
    '/manutencao/servicos/prestador/:id/editar' : 'Editar Prestador',
    '/reserva' : 'Reservar Área Comum',
    '/reserva/areas' : 'Áreas Comuns',
    '/reserva/areas/criar' : 'Criar Área Comum',
    '/reserva/areas/detalhes/:id' : 'Detalhes da Área',
    '/reserva/areas/detalhes/:id/editar' : 'Editar Área',
    '/avisos' : 'Avisos',
    '/portaria' : 'Portaria',
    '/contratos' : 'Contratos',
    '/contratos/:id' : 'Detalhes do Contrato',
    '/beneficiarios' : 'Beneficiários',
    '/beneficiarios/:id' : 'Detalhes do Beneficiário',
    '/pagamento' : 'Pagamento'




  }

  return (
    <Breadcrumbs sx={{ display: { xs: 'none', md: 'block' } }} aria-label="breadcrumb"
                 separator={<NavigateNextOutlined fontSize={'small'}/>}>
      <LinkRouter underline="hover" color="inherit" to="/" sx={{ display: 'flex', alignItems: 'center' }}>
        <Home color={'primary'}/>
      </LinkRouter>
      {pathNames.map((value, index) => {
        const last = index === pathNames.length - 1
        const to = `/${pathNames.slice(0, index + 1).join('/')}`
        let route = getRoutePath(to, params)
        if (breadcrumbNameMap[route] !== undefined) {
          return last ? (
            <Typography color="text.primary" key={to}>
              {breadcrumbNameMap[route]}
            </Typography>
          ) : (
            <LinkRouter underline="hover" color="inherit" to={to} key={to}>
              {breadcrumbNameMap[route]}
            </LinkRouter>
          )
        }
      })}
    </Breadcrumbs>
  )
}

export default BreadCrumbs
