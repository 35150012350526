import React, { useContext, useEffect, useState } from 'react'
import {
  Backdrop,
  Box, Button,
  Card,
  Chip,
  Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider,
  Grid, InputAdornment,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { api } from '../../../services/Main/Api'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import CircularProgress from '@mui/material/CircularProgress'
import Context from '../../../contexts/Context'

import NoResultsAnimation from '../../../components/Shared/animations/NoResultsAnimation'
import { Alarm, Search } from '@mui/icons-material'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Masks from '../../../components/Shared/Masks'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import {getCondoId} from "../../../services/Main/AuthStorage";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import {DesktopDatePicker} from "@mui/x-date-pickers";

const WebhookList = () => {
  const navigate = useNavigate()
  const [response, setResponse] = useState(null)
  const [monthInput, setMonthInput] = useState(moment().format("MM"))
  const [dateInput, setDateInput] = useState(moment().format(moment().format("YYYY-MM-DD")))
  const [yearInput, setYearInput] = useState(moment().format("YYYY"))
  const [type, setType] = useState("processed")
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [fileSelected, setFileSelected] = useState(null)
  const [jsonResult, setJsonResult] = useState(null)
  const [search, setSearch] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState(
      {
        condo_id: getCondoId(),
        page: 1,
        page_size: 50,
        ordering: "-last_login",
      }
  )
  const monthFormat = moment().format("MM")
  const yearFormat = moment().format("YYYY")

  useEffect(()=>{
    let params = { ...searchParams, "dateInput": dateInput, "type": type}
    if (search){
      params.search = search
    }else{
      delete params.search
    }
    setSearchParams(params)
  }, [search, dateInput, type])

  function getData(){
    setLoading(true)
    let month = monthFormat
    let year = yearFormat

    if (searchParams.get("month") && searchParams.get("year") ){
      month = searchParams.get("month")
      year = searchParams.get("year")
    }

    let filter = {...filters, month:month, year:year}
    if (searchParams.get("rent_contract_id")){
      filter = {...filter, rent_contract_id: searchParams.get("rent_contract_id")}
    }

    if (searchParams.get("invoice_id")){
      filter = {...filter, invoice_id: searchParams.get("invoice_id")}
    }

    if (searchParams.get("search")){
      filter = {...filter, search: searchParams.get("search")}
    }

    if (searchParams.get("dateInput")){
      filter = {...filter, dateInput: searchParams.get("dateInput")}
    }

    if (searchParams.get("type")){
      filter = {...filter, type: searchParams.get("type")}
    }

    api.get(`fitbank/webhook-list/`, {params: filter})
      .then(response => {
        const data = response.data
        setResponse(data)
        setLoading(false)
      }).catch(error => {
        console.log(error)
        setLoading(false)
    })
  }


  useEffect(() => {
    if (searchParams.get("month") && searchParams.get("year") ){
      setMonthInput(searchParams.get("month"))
      setYearInput(searchParams.get("year"))
    }else {
      getData()
    }
  }, [])

  useEffect(() => {
    getData()
  }, [searchParams])
    useEffect(function whenPageLoad (){
        getData()
    },[filters])


    let typingTimer  = ""

  const handleClickOpen = () => {
    const  data = {
      file: fileSelected
    }
    api.post(`fitbank/webhook-list/`, data)
      .then(response => {
        const data = response.data
        setJsonResult(data)
        setLoading(false)
        setOpen(true);
      }).catch(error => {
      console.log(error)
      setLoading(false)
    })

  };

  const processWebhook = () => {
    const  data = {
      file: fileSelected
    }
    api.put(`fitbank/webhook-list/`, data)
      .then(response => {
        const data = response.data
        setJsonResult(data)
        setLoading(false)
        setOpen(true);
      }).catch(error => {
      console.log(error)
      setLoading(false)
    })

  };

  const handleClose = () => {
    setOpen(false);
    setFileSelected(null);
  };

  useEffect(()=>{
    handleClickOpen()
  }, [fileSelected])
  return (
    <>
      <Helmet>
        <title>Webhooks - Bloco B3</title>
      </Helmet>

      <Backdrop
        sx={{ color: '#fff', zIndex: 1500, }}
        open={loading}
      >

        <CircularProgress color="inherit"/>
      </Backdrop>
      <Container maxWidth={false}>
        <Box
          sx={{
            display: 'flex',
            '@media (max-width:600px)': {
              display: 'grid',
              gridTemplateColumns: 'repeat(1, 1fr)',
              gap: 3,
            },
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,

          }}
        >
          <Typography
            textAlign={'center'}
            variant={'h5'}
          >
            Webhooks ({response?.count || 0})
          </Typography>
          <Box  sx={{textAlign: "right"}}>
            <ToggleButtonGroup
              color="primary"
              value={type}
              size={"small"}
              exclusive
              onChange={(e, value)=>{
                setType(value)
              }}
              aria-label="Platform"
              sx={{mr: 3}}
            >
              <ToggleButton value={"processed"}>Processados</ToggleButton>
              <ToggleButton value={"do_not_processed"}>Não processados</ToggleButton>

            </ToggleButtonGroup>

            <DesktopDatePicker
              label="Calendário"
              value={moment(dateInput, "YYYY-MM-DD").toDate()}
              onChange={(newValue) => {

                setDateInput(moment(new Date(newValue)).format("YYYY-MM-DD"))
              }}
              slotProps={{
                field: { clearable: false, onClear: () => {} },
                textField: {
                  size: "small"
                }
              }}
            />
          </Box>
        </Box>


        <Card>
          {response?.results ?
            <>

              <Grid container sx={{ p: 2, alignItems: 'center' }} spacing={1}>
                <Grid item xs={12} sm={4} lg={3}>
                  {/*<UnitFilter />*/}
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  {/*<ReferenceMonthFilter*/}
                  {/*  response={response}*/}
                  {/*/>*/}
                </Grid>
              </Grid>

              <TableContainer>
                {response?.results?.length > 0  ?
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Código</TableCell>
                        <TableCell>Metodo</TableCell>
                        <TableCell>Nº do Documento</TableCell>
                        <TableCell>Arquivo</TableCell>
                        <TableCell>Criado em</TableCell>

                    </TableRow>
                    </TableHead>
                    <TableBody>


                    { response.results.map((item, index) =>
                      <TableRow
                        key={index}
                        hover
                        sx={{'cursor': 'pointer'}}

                        onClick={(e) => {
                          e.stopPropagation()
                          setFileSelected(item.file)
                        }}
                      >
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            WEB-{index}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {item.method}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {item.document_number}
                          </Box>
                        </TableCell>


                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {item.file}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {moment(item.created_at).format("DD/MM/YYYY [às] HH:mm:ss")}
                          </Box>
                        </TableCell>


                      </TableRow>
                    )}

                    </TableBody>
                  </Table>
                  :
                  <NoResultsAnimation />
                }
              </TableContainer>
            </>
            :
            <Skeleton
              variant="rounded"
              height={700}
              animation="pulse"
            />
          }
        </Card>

      </Container>

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle> {jsonResult?.Method} - {jsonResult?.Status}</DialogTitle>
        <Divider/>
        <DialogContent>
          <DialogContentText>
            <pre>
            {JSON.stringify(jsonResult, null, 2)}
              </pre>
          </DialogContentText>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button variant={"outlined"} onClick={processWebhook}>Processar Webhook </Button>
        </DialogActions>
      </Dialog>

    </>

  )
}

export default WebhookList
