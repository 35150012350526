import { Box, IconButton, Link, ListItemIcon, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React, {useContext} from "react";
import {ImgIcon, Pdf, Txt, WhateverIcon} from "./IconTypes";
import Context from "../../../contexts/Context";

const  SendOrSent = ({state, setState, deletable, title, setDeleteApiFiles, mapParam, rejectedFiles}) => {
  const imgTypes = ['.jpg', '.png', '.gif']
  const {userFiles} = useContext(Context)


  return (
    <>
      {state?.length > 0 && !rejectedFiles?.length > 0 &&
        <Box
          sx={{
            borderWidth: 1,
            padding: '1rem',
            borderRadius: 2,
            backgroundColor: 'rgb(240,240,240)',
            marginBottom: '1rem',
          }}
        >
          <Typography
            variant={"h6"}
            sx={{
              margin: '0 0 1rem 0.3rem',
            }}
          >
            {title}
          </Typography>
          {state?.map((obj, index) => (
            <ListItemIcon
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                wordBreak: 'break-all',
                marginBottom: '1rem',
                marginRight: '1rem'
              }}
              key={index}>
              <Link
                href={obj?.[mapParam]}
                underline="hover"
                target="_blank"
                rel="noreferrer"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  color: 'inherit'
                }}
              >
                {obj?.[mapParam]?.includes('.pdf') ?
                  <Pdf />
                  : obj?.[mapParam]?.includes('.txt') ?
                  <Txt />
                  : imgTypes.some(e => obj?.[mapParam]?.includes(e)) ?
                  <ImgIcon />
                  :
                  <WhateverIcon />
                }
                <Typography>
                  {/*{ (title === 'Documentos Para Envio') ?*/}
                  {/*  */}
                  {/*  :*/}
                  {

                    obj?.[mapParam]?.substring(obj?.file?.lastIndexOf('/') + 1)

                  }


                  {/*}*/}
                </Typography>
              </Link>
              {deletable &&
                <IconButton
                  onClick={
                    () => {
                      setState(state?.filter(e => e?.file !== obj?.file))
                      setDeleteApiFiles && setDeleteApiFiles((prevState) => state?.filter(e => e?.file === obj?.file).concat(...prevState))
                    }
                  }
                >
                  <Delete/>
                </IconButton>
              }
            </ListItemIcon>
          ))}
        </Box>
      }
    </>
  )
}

export default SendOrSent