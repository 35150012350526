import {Skeleton, Typography} from "@mui/material";
import {Today} from "@mui/icons-material";
import moment from "moment/moment";
import React from "react";

const MaintenanceReadStartDate = ({isLoading, dataUpdate}) => {
  return (
    <>
      {!isLoading ?
        <Typography
          sx={{marginBottom: '0.5rem', alignItems: 'center', display: 'flex'}}
        >
          <Today sx={{mr: '0.5rem'}}/>
          <b>Data de ínicio:</b>&nbsp; {moment(new Date(dataUpdate?.start_date)).format('LLL')}
        </Typography>
        :
        <Skeleton
          variant="rounded"
          animation="pulse"
          height={24}
          sx={{marginBottom: '0.5rem'}}
        />
      }
    </>
  )
}

export default MaintenanceReadStartDate