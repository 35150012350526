import {Autocomplete, MenuItem, TextField} from '@mui/material'
import React, { useEffect, useContext } from 'react'
import Context from "../../../contexts/Context";

const RecipientFilter =
  ({
     state,
     setState,
     keyName,
     defaultValue,
     styles, variant,
     value,
     error,
     label,
     options,
     secondKey,
     valueOption,
     thirdKey,
     secondValue,
     rent_recipient
  }) => {

  const { dataProvider } = useContext(Context)
  const { recipients } = dataProvider || {}

  return (
    <>
      <Autocomplete
        fullWidth
        onChange={(event,newValue) =>
          setState((prevState) => ({
            ...prevState,
            [keyName]: newValue?.value,
            ...(secondKey && {[secondKey]: newValue?.[valueOption]}),
            ...(thirdKey && {[thirdKey]: newValue?.[secondValue]}),

          }))}
        value={defaultValue}
        renderInput={(props) => <TextField
          {...props}
          variant={variant}
          label={label}
          size={"small"}
          error={!!error?.[keyName]}
          helperText={error?.[keyName]}
        />}
        getOptionLabel={(option) => `${option.label}` ?? ['']}
        options={options ?? ['']}
        // isOptionEqualToValue={(option, value) => option.id === value.id}
        sx={styles}
        renderOption={(props, option) => {
          // const isDisabled = !!recipients?.find(e => option.value === e)
          return (
            <MenuItem
              {...props}
              key={option.value}
              // disabled={isDisabled}
            >
              {`${option.label}`}
            </MenuItem>
        )}}

      />
    </>
  )
}

export default RecipientFilter
