import React from "react";
import {
  Box,
  Skeleton,
  Typography
} from "@mui/material";
import moment from "moment/moment";
import {Today} from "@mui/icons-material";

const BookingReadStartDate = ({
    isLoading,
    dataUpdate
  }) => {

  return (
    <>
      {!isLoading ?
        <Box
          sx={{
            display: 'flex'
          }}
        >
          <Today sx={{mr: '0.5rem'}}/>
          <Typography
            sx={{marginBottom: '0.5rem'}}
          >
            <b>Data de Inicio:</b> {moment(new Date(dataUpdate?.date_start)).format('LLL')}
          </Typography>
        </Box>
        :
        <Skeleton
          variant="rounded"
          animation="pulse"
          height={24}
          sx={{marginBottom: '0.5rem'}}
        />
      }
    </>
  )
}

export default BookingReadStartDate