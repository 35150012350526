import {IconButton, ListItemIcon, ListItemText, MenuItem, TableBody, TableCell, TableRow} from "@mui/material";
import moment from "moment/moment";
import Masks from "../../../components/Shared/Masks";
import React, {useContext, useState} from "react";
import Context from "../../../contexts/Context";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {Close, Delete, Download, MoreVert, Share} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import {api} from "../../../services/Main/Api";
import {useSnackbar} from "notistack";

const RentRecipientTableBody = (props) => {
  const { state, setReqLoading, reqLoading } = props
  const { filters, setFilters, unitOptionsNumber } = useContext(Context)
  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()

  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [anchor, setAnchor] = useState(null)
  const [selectedRecipient, setSelectedRecipient] = useState(null)

  const deleteReq = () => {
    setReqLoading(true)
    api.delete(`/rent_recipient/${selectedRecipient?.id}`)
      .then(response =>{
        setFilters(prevState => ({...prevState, deleteDone: !filters?.deleteDone}))
        enqueueSnackbar('Beneficiário deletado', { variant: 'success' })
      })
      .catch(error => {
        // error?.response?.data[0] && enqueueSnackbar(error.response?.data[0], { variant: 'error' })
        !!error?.response?.data[0] && enqueueSnackbar(error.response?.data[0], { variant: 'error' })
        enqueueSnackbar('Erro ao excluir beneficiário', { variant: 'error' })
        console.log(error)
      })
      .finally(() => {
        setReqLoading(false)
      })
  }

  return (
    <>
      <TableBody>
        { state?.results?.map((recipient, index) => (
          <TableRow
            hover
            tabIndex={-1}
            key={recipient?.id}
            sx={{ 'cursor': 'pointer' }}
            onClick={(e) => {
              e.stopPropagation()
              navigate(`/beneficiarios/${recipient?.id}/`)
              setFilters(prevState => ({...prevState, isUpdate: true , id: recipient?.id, load: true}))
            }}
          >
            <TableCell
              component="th"
              scope="row"
            >
              {recipient?.name ?? <Close sx={{color: 'error.main'}}/>}
            </TableCell>

            <TableCell
              component="th"
              scope="row"
            >
              {Masks.cpfOrCnpjText(recipient.cpf_cnpj) ?? <Close sx={{color: 'error.main'}}/>}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
            >
              {Masks?.phone(recipient?.phone ?? '' ) ?? <Close sx={{color: 'error.main'}}/>}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
            >
              {recipient.email ?? <Close sx={{color: 'error.main'}}/>}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              sx={{
                width: '24px'
              }}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  setSelectedRecipient(recipient)
                  setAnchor(e.currentTarget)
                  setOpen(recipient.id)
                }}>
                <MoreVert/>
              </IconButton>

              <Menu
                open={open === recipient.id}
                anchorEl={anchor && anchor}
                onClose={(e) => {
                  e.stopPropagation()
                  setOpen(false)
                }}
                PaperProps={{
                  sx: { width: 200, maxWidth: '100%' },
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <MenuItem
                  component={RouterLink}
                  onClick={(e)=> {
                    setOpenDelete(true)
                    setOpen(false)
                    e.stopPropagation()
                  }}
                  // disabled={invoice?.status?.key !== "open"}
                  sx={{
                    color: 'text.secondary'
                  }}
                >
                  <ListItemIcon>
                    <Delete />
                  </ListItemIcon>
                  <ListItemText
                    primary="Excluir beneficiário"
                    primaryTypographyProps={{ variant: 'body2' }}/>
                </MenuItem>


              </Menu>
            </TableCell>
          </TableRow>
        ))}

      </TableBody>
      <SimpleDialog
        open={openDelete}
        setOpen={setOpenDelete}
        title={'Excluir beneficiário'}
        question={'Realmente quer excluir esse beneficiário?'}
        functionProp={deleteReq}
      />
    </>
  )
}

export default RentRecipientTableBody