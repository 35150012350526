import MaintenanceCreate from './MaintenanceCreate'
import MaintenanceUpdate from './MaintenanceUpdate';
import CalendarMaintenance from "../../../components/Calendars/CalendarMaintenance";
import {Helmet} from "react-helmet";
import {Box} from "@mui/material";


const MaintenanceCalendar = () => {
  return(
      <>
        <Helmet>
          <title>Reserva de Área Comum - Bloco B3</title>
        </Helmet>
        <MaintenanceCreate />
        <MaintenanceUpdate />
        <CalendarMaintenance />
      </>

  )
}

export default MaintenanceCalendar;