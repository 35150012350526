import {Box, Collapse, InputAdornment, TextField} from "@mui/material";
import {AccessTime, ArrowDownward} from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";


const MaintenancePeriod = ({setState, state, disabled }) => {
  return (
    <Collapse in={state?.category === "PERIODIC"} unmountOnExit>
      <Box
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}
      >
        <ArrowDownward
          sx={{
            fontSize: '40px',
            color: 'primary.main',
          }}/>
        <TextField
          fullWidth
          select
          disabled={disabled}
          size={"small"}
          label={'Período'}
          sx={{marginBottom: !state?.choice && '2rem'}}
          value={state?.choice ?? ''}
          onChange={(e)=> setState((prevState) => ({...prevState, choice: +e.target.value}))}
          InputProps={{
            startAdornment:
              <InputAdornment position="start">
                <AccessTime />
              </InputAdornment>
          }}
        >
          <MenuItem value={1}>{"Díária"}</MenuItem>
          <MenuItem value={7}>{"Semanal"}</MenuItem>
          <MenuItem value={30}>{"Mensal"}</MenuItem>
          <MenuItem value={365}>{"Anual"}</MenuItem>
        </TextField>
      </Box>
    </Collapse>
  )
}

export default MaintenancePeriod