import {TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import React, {useContext} from "react";
import Context from "../../../../contexts/Context";

const TableDocumentHead = () => {
  const {filters, setFilters} = useContext(Context)

  const handleFilter = (asc: string, desc: string) => {
    setFilters(prevState => ({
        ...prevState,
        ordering: filters?.ordering === asc ? desc : asc
      })
    )
  }


  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-title' || filters?.ordering === 'title'}
            direction={filters?.ordering === '-title' ? 'desc' : 'asc'}
            onClick={() => handleFilter('title', '-title') }
          >
            Título
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-created_at' || filters?.ordering === 'created_at'}
            direction={filters?.ordering === '-created_at' ? 'desc' : 'asc'}
            onClick={() => handleFilter('created_at', '-created_at') }
          >
           Criado
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-updated_at' || filters?.ordering === 'updated_at'}
            direction={filters?.ordering === '-updated_at' ? 'desc' : 'asc'}
            onClick={() => handleFilter('updated_at', '-updated_at') }
          >
            Última atualização
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default TableDocumentHead