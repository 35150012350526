import {Box, Fade, TextField} from "@mui/material";
import SimpleText from "../../../../components/Shared/SimpleText";
import React from "react";
import moment from "moment";
import {DatePicker} from "@mui/x-date-pickers";

const BailInsuranceInpunts =
  ({
    edit,
    guarantee,
    guaranteeData,
    setGuaranteeData
   }) => {
  return (
    <Box
      sx={{
        // paddingX:'1rem'
      }}
    >
      {guarantee === 'bail_insurance' && edit &&
        <Fade
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={300}
        >
          <Box>
            <SimpleText
              label={'Número de Apólice/Contrato'}
              onlyNumbers
              state={guaranteeData}
              setState={setGuaranteeData}
              keyName={'insurance_policy_number'}
              styles={{ mb: '1rem' }}
            />

            <SimpleText
              label={'Companhia'}
              state={guaranteeData}
              setState={setGuaranteeData}
              keyName={'insurance_company'}
              styles={{ mb: '1rem' }}
            />

              <DatePicker
                  sx={{ textAlign: 'center' }}
                  views={['year', 'month', 'day']}
                  label="Data de Vencimento"
                  // minDate={moment()}
                  value={moment(guaranteeData?.due_date)}
                  onChange={(newValue) => setGuaranteeData((prevState) => ({
                      ...prevState,
                      due_date: moment(newValue).format('YYYY-MM-DD')
                  }))}
                  renderInput={
                      (params) => (
                          <TextField
                              {...params}
                              size={'small'}
                              sx={{ mb: 2 }}
                              helperText={null}
                              fullWidth
                          />
                      )
                  }
              />

          </Box>
        </Fade>
      }

    </Box>
  )
}

export default BailInsuranceInpunts