import Cookies from 'universal-cookie';

export const TOKEN_KEY_APP = "userBlocob3";
export const TOKEN_KEY = `${TOKEN_KEY_APP}.token`;
export const REFRESH_KEY = `${TOKEN_KEY_APP}.refresh`;

export const getUserToken = () => {
    const cookies = new Cookies(document.cookie);
    return cookies.get(TOKEN_KEY);
}

export const getUserRefreshToken = () => {
    const cookies = new Cookies(document.cookie);
    return cookies.get(REFRESH_KEY);
}

export const setUserToken = token => {
    const cookies = new Cookies();
    cookies.set(TOKEN_KEY, token, {
      path: '/',
      maxAge: 60 * 60 * 24 * 30 // 30 days
    });
}

export const setUserRefreshToken = refresh => {
    const cookies = new Cookies();
    cookies.set(REFRESH_KEY, refresh, {
      path: '/',
      maxAge: 60 * 60 * 24 * 30 // 30 days
    });
}

export const removeTokensUnitUser = () => {
  const cookies = new Cookies(document.cookie);
  cookies.remove(TOKEN_KEY, { path: '/' });
  cookies.remove(REFRESH_KEY, { path: '/' });
}