import {Box, Typography} from "@mui/material";
import {WatchLater} from "@mui/icons-material";
import moment from "moment/moment";
import React from "react";

const CommentSubHeader = ({ comment }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          component={'span'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '0.800rem',
            color: '#637381',
          }}
        >
          <WatchLater
            sx={{
              fontSize: '1rem',
              mr: '0.2rem'
            }}
          />
          {moment(comment?.created_at).fromNow()}
        </Typography>
      </Box>
      <Typography
        component={'span'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 400,
          fontSize: '0.800rem',
          color: '#637381',
        }}
      >
        {`Última atualização: ${moment(comment?.update_at).fromNow()}`}
      </Typography>
    </>
  )
}

export default CommentSubHeader