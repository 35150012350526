import * as React from "react";
import MakePaid from "./dependencies/MakePaid";
import MakeDuplicate from "./dependencies/MakeDuplicate";
import MakeBankSlip from "./dependencies/MakeBankSlip";
import MakeOpen from "./dependencies/MakeOpen";
import MakeDelete from "./dependencies/MakeDelete";

const MakeDialogOptions = (
  {
    openByStatus,
    setOpenByStatus,
    handleCloseConfirmation,
    handleMakeItPaid,
    handleMakeItOpen,
    handleMakeItDelete,
    reqNewInvoice,
    setReqNewInvoice,
    generate,
    generateNew,
    reqCreateInvoice,
    setReqCreateInvoice,
    errors,
    invoice
  }
  ) => {

  return (
    <>
        <MakeDelete
            openByStatus={openByStatus}
            handleCloseConfirmation={handleCloseConfirmation}
            handleMakeItDelete={handleMakeItDelete}
        />

        <MakePaid
        openByStatus={openByStatus}
        handleCloseConfirmation={handleCloseConfirmation}
        handleMakeItPaid={handleMakeItPaid}
      />

        <MakeOpen
            openByStatus={openByStatus}
            handleCloseConfirmation={handleCloseConfirmation}
            handleMakeItOpen={handleMakeItOpen}
        />

      <MakeDuplicate
        openByStatus={openByStatus}
        setOpenByStatus={setOpenByStatus}
        reqNewInvoice={reqNewInvoice}
        setReqNewInvoice={setReqNewInvoice}
        handleCloseConfirmation={handleCloseConfirmation}
        generateNew={generateNew}
        errors={errors}
        invoice={invoice}
      />

      <MakeBankSlip
        openByStatus={openByStatus}
        setOpenByStatus={setOpenByStatus}
        reqCreateInvoice={reqCreateInvoice}
        setReqCreateInvoice={setReqCreateInvoice}
        handleCloseConfirmation={handleCloseConfirmation}
        generate={generate}
        invoice={invoice}
      />


    </>
  )
}

export default MakeDialogOptions