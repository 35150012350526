import React, {
  useContext
} from "react";
import {
  Alert,
  Box,
  Button
} from "@mui/material";
import AccountSelector from "./AccountSelector";
import ReceiverAccInfo from "./ReceiverAccInfo";
import NameAndCpf from "./NameAndCpf";
import BankSelector from "./BankSelector";
import AccountInfo from "./AccountInfo";
import TransferInfo from "./TransferInfo";
import AccountType from "./AccountType";
import SimpleText from "../../../../../components/Shared/SimpleText";
import Context from "../../../../../contexts/Context";
import TransferNewAcc from "./TransferNewAcc";

const Ted = ({
    setAccount,
    account,
    checkingAccounts,
    selectedAccount,
    setSelectedAccount,
    bankNames,
    codeSent,
    check,
    setCheck,
    reqData,
    setReqData,
    submitReq,
    buttonBehavior
  }) => {

  const { filters, sessionCheck } = useContext(Context)

  const { operationType } = filters || {}

  return(
    <>
      <TransferNewAcc
        check={check}
        setCheck={setCheck}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        {!check &&
          <AccountSelector
            account={account}
            setAccount={setAccount}
            checkingAccounts={checkingAccounts}
          />
        }
      </Box>
      <ReceiverAccInfo
        selectedAccount={selectedAccount}
        bankNames={bankNames}
        codeSent={codeSent}
        account={account}
        setSelectedAccount={setSelectedAccount}
        setAccount={setAccount}
      />

      {check &&
        <>
          <NameAndCpf
            reqData={reqData}
            setReqData={setReqData}
          />

          <BankSelector
            reqData={reqData}
            setReqData={setReqData}
            bankNames={bankNames}
          />

          <AccountInfo
            reqData={reqData}
            setReqData={setReqData}
          />
        </>
      }

      <TransferInfo
        reqData={reqData}
        setReqData={setReqData}
      />


      {check &&
        <AccountType
          reqData={reqData}
          setReqData={setReqData}
        />
      }
      <SimpleText
        label={'Descrição'}
        fullwidth
        state={reqData}
        setState={setReqData}
        keyName={'description'}
        multiline={true}
        rows={5}
      />

      {operationType === 'transfer' &&
        <Alert
          variant={'outlined'}
          severity="info"
          sx={{
            border: 'none',
            marginY: '1rem',
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          É necessário validar para salvar a despesa
        </Alert>
      }

      {operationType === 'transfer' &&
        <>
        <Button
            onClick={() => {
              sessionCheck()
              if (check) {
                submitReq()
              }
            }}
            variant={'contained'}
            disabled={check ? !buttonBehavior?.isEnabled : !buttonBehavior?.isEnabledJustTransfer}
            sx={{
              mt: '1rem',
              width: '100%'
            }}
          >
            {'Validar'}
          </Button>
        </>
      }
    </>
  )
}

export default Ted