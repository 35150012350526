import {Box, Chip, Skeleton} from "@mui/material";

const TransferStatus = ({ loading, data }) => {
  return (
    <Box textAlign={'center'} sx={{ mb: 3 }}>
      {!loading ?
        <Chip
          label={data?.status?.label}
        />
        : <Skeleton
          sx={{
            mx: 'auto'
          }}
          variant={'text'}
          width={'80%'}/>
      }
    </Box>
  )
}

export default TransferStatus