import React from "react";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from "@mui/material";
import {DeleteOutlined, EditOutlined} from "@mui/icons-material";
import ModalDelete from "../../../../../components/Modal/ModalDelete";

const CommentActionMenu =
  ({
     openMenu,
     anchorEl,
     setOpenMenu,
     setEdit,
     setFinalId,
     commentId,
     handleOpenConfirmation,
     openConfirmation,
     handleCloseConfirmation,
     deleteComment,
   }) => {

  return (
    <>
      <Menu
        open={openMenu}
        anchorEl={anchorEl}
        onClose={() => setOpenMenu(false)}
        PaperProps={{
          sx: {width: 200, maxWidth: '100%'},
        }}
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        <MenuItem
          onClick={() => {
            setEdit(prevState => !prevState)
            setFinalId(commentId)
            setOpenMenu(false)
          }
          }
          sx={{color: 'text.secondary'}}
        >
          <ListItemIcon>
            <EditOutlined/>
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>

        <MenuItem
          sx={{color: 'error.main'}}
          onClick={() => handleOpenConfirmation()}
        >
          <ListItemIcon>
            <DeleteOutlined color={'error'}/>
          </ListItemIcon>
          <ListItemText primary="Excluir" primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
      </Menu>
      <ModalDelete
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        onDelete={() => deleteComment(commentId)}
      />

    </>

  )
}

export default CommentActionMenu