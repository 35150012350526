import React from "react";
import { Grid } from "@mui/material";
import SimpleSelector from "../../../../../../../components/Shared/SimpleSelector";
import { Event } from "@mui/icons-material";

const PaymentDate = ({
    state,
    setState,
    errors
  }) => {

  const months = {
    "Janeiro": 1,
    "Fevereiro": 2,
    "Março": 3,
    "Abril": 4,
    "Maio": 5,
    "Junho": 6,
    "Julho": 7,
    "Agosto": 8,
    "Setembro": 9,
    "Outubro": 10,
    "Novembro": 11,
    "Dezembro": 12,
  };

  const monthsArray = Object.keys(months).map((mes) => {
    return { month: mes, value: months[mes] };
  });

  function generateYears(numYears) {
    const startYear = 2023;
    const years = [];
    for (let i = 0; i < numYears; i++) {
      years.push(startYear + i);
    }
    return years;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>

        <SimpleSelector
          label={'Mês de Pagamento'}
          options={monthsArray}
          optionKeyValue={'value'}
          optionKey={'month'}
          state={state}
          setState={setState}
          keyName={'billing_month'}
          error={errors}
          styles={{mb: '1rem'}}
          SelectProps={{
            IconComponent: () => (
              <Event
                sx={{ m: "0.5rem" }}
              />
            )
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <SimpleSelector
          label={'Ano de Pagamento'}
          options={generateYears(10)}
          state={state}
          setState={setState}
          keyName={'billing_year'}
          error={errors}
          styles={{mb: '1rem'}}
          SelectProps={{
            IconComponent: () => (
              <Event
                sx={{ m: "0.5rem" }}
              />
            )
          }}
        />

      </Grid>
    </Grid>
  )
}

export default PaymentDate