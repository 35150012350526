import {Update} from "@mui/icons-material";
import {Box, Divider, Skeleton, TextField} from "@mui/material";
import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import moment from "moment/moment";
import Masks from "../../../../components/Shared/Masks";
import SimpleDialog from "../../../../components/Shared/SimpleDialog";
import React, {useEffect, useState} from "react";
import {getCondoId} from "../../../../services/Main/AuthStorage";
import {api} from "../../../../services/Main/Api";
import {DesktopDatePicker} from "@mui/x-date-pickers";

const RentPreview = ({open, setOpen}) => {
  const [filters, setFilters ] = useState({
    page_size: 50,
    condo_id: getCondoId(),
    month: moment(),
    year: moment(),

  })
  const [data, setData] = useState([])


  async function previewReq () {
    const data = {
      ...filters,
      month: moment(filters?.month).month() + 1,
      year: moment(filters?.year).year()
    }


    try {
      const response = await api.post('rent_transfer/preview/', data);

      setData(response.data.rent_transfer);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(function whenModalOpen() {
    setData([])
    if (open) {
      previewReq()
    }
  }, [open, filters]);

  useEffect(function reset(){
    if (open === false){
      setData([])
      setFilters({
        page_size: 50,
        condo_id: getCondoId(),
        month: moment(),
        year: moment(),
        }
      )
    }
  }, [open])

  return (
    <>
      <SimpleDialog
        title={'Previsão de Repasse'}
        titleIcon={<Update />}
        open={open}
        setOpen={setOpen}
        removeConfirm
        onClickCancel={() => {
          setOpen(false);
          setData([]);
        }}
        cancelText={'Fechar'}
        dialogContent={
          <>
            {data?.length > 0 ? (
              <>
                <Box
                  sx={{
                    mb: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    width : '40%',
                  }}
                >
                  <DesktopDatePicker
                    label="Mês"
                    disableMaskedInput
                    inputFormat="MMMM"
                    value={filters?.month}
                    openTo={'month'}
                    views={['month']}
                    disableHighlightToday
                    onChange={(e) => {
                      setFilters((prevState) => ({...prevState, month: moment(e).format('MM')}))
                    }}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        size={'small'}
                        fullWidth
                        sx={{
                          pr: '1rem'
                        }}
                        variant={'standard'}


                      />}
                  />

                  <DesktopDatePicker
                    label="Ano"
                    disableMaskedInput
                    inputFormat="yyyy"
                    value={filters?.year}
                    openTo={'year'}
                    views={['year']}
                    disableHighlightToday
                    onChange={(e) => {
                      setFilters((prevState) => ({...prevState, year: moment(e).format()}))
                    }}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        fullWidth
                        size={'small'}
                        variant={'standard'}
                      />}
                  />
                </Box>

                <SimpleTypography
                  label={moment(`${data[0]?.year}-${data[0]?.month}`, 'YYYY-M')
                    .format('MMMM YYYY')
                    .replace(/^\w/, (char) => char.toUpperCase())}
                  labelStyles={{
                    fontWeight: 'bold',
                  }}
                  boxStyles={{
                    mb: '0.5rem',
                  }}
                />

                {data?.map((preview, index) => (
                  <>

                        <Box
                          key={index}
                          sx={{
                            bgcolor: 'neutral.main',
                            p: '1rem',
                            borderRadius: '1rem',
                            mb: '1rem',
                          }}
                        >

                          <SimpleTypography
                            label={`Contrato`}
                            directValue={preview.contract_name}
                            labelStyles={{
                              fontSize: '0.9rem'
                            }}
                            styles={{
                              fontSize: '0.9rem'
                            }}
                            boxStyles={{
                              mb: '0.5rem'
                            }}
                          />

                          <SimpleTypography
                            label={`Beneficiário`}
                            directValue={preview.rent_recipient}
                            labelStyles={{
                              fontSize: '0.9rem'
                            }}
                            styles={{
                              fontSize: '0.9rem'
                            }}
                            boxStyles={{
                              mb: '0.5rem'
                            }}
                          />

                          <SimpleTypography
                            label={`Porcentagem`}
                            directValue={`${preview.percentage.toString()}%`}
                            labelStyles={{
                              fontSize: '0.9rem'
                            }}
                            styles={{
                              fontSize: '0.9rem'
                            }}
                            boxStyles={{
                              mb: '0.5rem'
                            }}
                          />

                          <SimpleTypography
                            label={`Valor`}
                            directValue={Masks.money(preview.value.toString())}
                            labelStyles={{
                              fontSize: '0.9rem'
                            }}
                            styles={{
                              fontSize: '0.9rem'
                            }}
                            boxStyles={{
                              mb: '0.5rem'
                            }}
                          />

                          {preview.administrative_tax_amount !== 0 &&
                            <SimpleTypography
                              label={'Taxa Administrativa'}
                              directValue={Masks.money(preview.administrative_tax_amount.toString())}
                              labelStyles={{
                                fontSize: '0.9rem'
                              }}
                              styles={{
                                fontSize: '0.9rem'
                              }}
                              boxStyles={{
                                mb: '0.5rem',
                              }}
                            />
                          }

                          {preview.rent_contract_taxes?.length > 0 &&
                            preview.rent_contract_taxes?.map(tax => (
                              <Box
                                sx={{mb: '0.5rem'}}
                                key={tax.id}
                              >
                                <SimpleTypography
                                  label={tax.description}
                                  directValue={Masks.money(tax.value.toString())}
                                  labelStyles={{
                                    fontSize: '0.9rem'
                                  }}
                                  styles={{
                                    fontSize: '0.9rem'
                                  }}
                                  boxStyles={{
                                    mb: '0.5rem',
                                  }}
                                />
                            </Box>
                            ))
                           }

                          <Divider
                            sx={{
                              mb: '0.5rem'
                            }}
                          />
                          <SimpleTypography
                            label={`Valor do Repasse`}
                            directValue={Masks.money(preview.value_transfer.toString())}
                            labelStyles={{
                              fontSize: '1rem',
                              fontWeight: 'bold',
                              color: 'primary.main'
                            }}
                            styles={{
                              fontSize: '1rem',
                              fontWeight: 'bold',

                            }}
                            boxStyles={{
                              mb: '0rem'
                            }}
                          />
                        </Box>
                  </>
                ))}
              </>
            ) : (
              <Skeleton
                variant="rounded"
                height={700}
                animation="pulse"
              />
            )}
          </>
        }
      />
    </>
  );

}

export default RentPreview