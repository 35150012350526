import {Chip, IconButton, ListItemIcon, ListItemText, MenuItem, TableBody, TableCell, TableRow} from "@mui/material";
import React, {useContext, useState} from "react";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import {Close, Delete, MoreVert} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import {api} from "../../../../../services/Main/Api";
import {useSnackbar} from "notistack";
import Context from "../../../../../contexts/Context";
import SimpleDialog from "../../../../../components/Shared/SimpleDialog";

const TableUnitBody = ({ response }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {enqueueSnackbar} = useSnackbar()
  const { filters, setFilters, setContextLoading } = useContext(Context)



  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [anchor, setAnchor] = useState(null)
  const [selectedUnit, setSelectedUnit] = useState(null)

  const isAluguel = location.pathname.includes('alugueis');
  const basePath = isAluguel ? 'alugueis' : 'condominio';

  function statusFilter(param) {
    switch (param) {
      case 'available':
        return 'Disponível';
      case 'occupied':
        return 'Ocupado';
      default:
        return '- - -';
    }
  }

  function statusColor(param) {
    switch (param) {
      case 'available':
        return 'success.main';
      case 'occupied':
        return 'error.main';
      default:
        return 'info.main';
    }
  }

  function deleteReq () {
    setContextLoading(true)

    api.delete(`/unit/${selectedUnit?.id}/`)
      .then(response =>{
        setFilters(prevState => ({...prevState, deleteDone: !filters?.deleteDone}))
        enqueueSnackbar('Unidade apagada', { variant: 'success' })
      })
      .catch(error => {
        !!error?.response?.data[0] && enqueueSnackbar(error.response?.data[0], { variant: 'error' })
        enqueueSnackbar('Erro ao excluir unidade', { variant: 'error' })
        console.log(error)
      })
      .finally(() => {
        setContextLoading(false)
      })
  }

  return (
    <>
    <TableBody>
      {response?.results?.map(object => (
        <TableRow
          hover onClick={() => {
          navigate(`/${basePath}/imoveis/${object.id}/editar`)
        }}
          key={object?.id}
          sx={{'&:last-child td, &:last-child th': {border: 0}, cursor: 'pointer'}}>
          <TableCell>{object?.name}</TableCell>
          <TableCell>{object?.number}</TableCell>

          <TableCell>{object?.email || <Close sx={{color: 'error.main'}}/>}</TableCell>
          <TableCell>{object?.phone}</TableCell>

          {!location.pathname.includes('alugueis') &&
            <TableCell>{object?.percent_meter ? `${object?.percent_meter}%` : <Close sx={{color: 'error.main'}}/>}</TableCell>
          }

          <TableCell
            component="th"
            scope="row"
            sx={{
              width: '24px'
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setSelectedUnit(object)
                setAnchor(e.currentTarget)
                setOpen(object.id)
              }}>
              <MoreVert/>
            </IconButton>

            <Menu
              open={open === object.id}
              anchorEl={anchor && anchor}
              onClose={(e) => {
                e.stopPropagation()
                setOpen(false)
              }}
              PaperProps={{
                sx: { width: 200, maxWidth: '100%' },
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuItem
                component={RouterLink}
                onClick={(e)=> {
                  setOpenDelete(true)
                  setOpen(false)
                  e.stopPropagation()
                }}
                // disabled={invoice?.status?.key !== "open"}
                sx={{
                  color: 'text.secondary'
                }}
              >
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <ListItemText
                  primary="Excluir unidade"
                  primaryTypographyProps={{ variant: 'body2' }}/>
              </MenuItem>


            </Menu>
          </TableCell>


        </TableRow>
      ))}
    </TableBody>
      <SimpleDialog
        open={openDelete}
        setOpen={setOpenDelete}
        title={'Apagar Unidade'}
        question={'Gostaria realmente de apagar esta unidade?'}
        functionProp={deleteReq}
      />
    </>
  )
}

export default TableUnitBody