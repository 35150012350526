import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Step, StepLabel, Stepper} from "@mui/material";
import SimpleButtons from "../../../components/Shared/SimpleButtons";
import Context from "../../../contexts/Context";
import {useNavigate, useParams} from "react-router-dom";

const RentWizard = (props) => {
  const { dataProvider, setDataProvider, setLoadingModal } = useContext(Context)
  const {  create, update, warrantyChoice, gambiUpdateWarranty } = props
  const {
    activeStep,
    firstStep,
    secondStep,
    thirdStep,
    fourthStep,
    fifthStep,
    rentId,
    enableCharge,
    enableSlip,
    enableWarranty,
    enableRecipient,
  } = dataProvider || {}

  const navigate = useNavigate()
  const params = useParams()

  //////////////////////
  //// Wizard Steps ////
  //////////////////////

  // -> first step, creating contract
  useEffect(function whenCreated(){
    if (firstStep === true) {
      setDataProvider(prevState => ({...prevState, activeStep: 1}))
    }
  },[firstStep])

  // -> third step, slip configuration
  // -> Note: the tax step (second step) is directly in the onClick button props
  useEffect(function slipCreated() {
    if (thirdStep === true){
      setDataProvider(prevState => ({...prevState, activeStep: 3}))
    }
  }, [thirdStep])

  // -> fourth step, warranty
  useEffect(function warrantyCreated() {
    if (fourthStep === true){
      setDataProvider(prevState => ({...prevState, activeStep: 4}))
    }
  }, [fourthStep])

  // -> Note: the recipient step (fifth step) is directly in the onClick button props

  return (
    <>
      {
      // Note: in some steps, when the <SimpleButtons> component is used, the `onDelete` props are used to skip the step
      <Stepper
        activeStep={activeStep}
        sx={{
          mb: '2rem'
        }}
      >
        <Step>
          <StepLabel>Contrato e Documentos</StepLabel>
        </Step>
        <Step>
          <StepLabel>Cobranças</StepLabel>
        </Step>
        <Step>
          <StepLabel>Configurações do Boleto</StepLabel>
        </Step>
        <Step>
          <StepLabel>Garantia</StepLabel>
        </Step>
        <Step>
          <StepLabel>Repasse</StepLabel>
        </Step>
        <Step>
          <StepLabel>Conclusão</StepLabel>
        </Step>
      </Stepper>
    }
      {props.children}


      {activeStep === 0 &&
        //-> Contract + Documents
        <SimpleButtons
          forwardButton={'Avançar'}
          onForward={() => {
            firstStep === true && setDataProvider(prevState => ({...prevState, activeStep: 1}))
            firstStep === false && create()
          }}
          noBack={firstStep === true && activeStep === 0}

        />
      }

      {activeStep === 1 &&
        // -> Taxs
        <SimpleButtons
          forwardButton={'Avançar'}


          onDelete={() => setDataProvider(prevState => ({...prevState, activeStep: 2}))}
          onForward={() => setDataProvider(prevState => ({...prevState, activeStep: 2}))}
          onBack={() =>  setDataProvider(prevState => ({...prevState, activeStep: 0}))}
        />
      }

      {activeStep === 2 &&
        // -> Slip config
        <SimpleButtons
          forwardButton={'Avançar'}
          forwardDisable={!enableSlip}
          deleteButton
          deleteText={'Pular'}
          onDelete={() => setDataProvider(prevState => ({...prevState, activeStep: 3}))}
          onForward={() => {
            thirdStep === true && setDataProvider(prevState => ({...prevState, activeStep: 3}))
            update()
          }}
          onBack={() =>  setDataProvider(prevState => ({...prevState, activeStep: 1}))}
        />
      }

      {activeStep === 3 &&
        // -> Warranty
        <SimpleButtons
          forwardButton={'Avançar'}
          forwardDisable={!enableWarranty}
          deleteButton
          deleteText={'Pular'}
          onDelete={() => setDataProvider(prevState => ({...prevState, activeStep: 4}))}
          onForward={() => {
            fourthStep === false && setDataProvider(prevState => ({...prevState, activeStep: 4}))
            gambiUpdateWarranty()
          }}
          onBack={() =>  setDataProvider(prevState => ({...prevState, activeStep: 2}))}
        />
      }

      {activeStep === 4 &&
        // -> Rent Recipient
        <SimpleButtons
          forwardButton={'Avançar'}
          forwardDisable={!enableRecipient}
          deleteButton
          deleteText={'Pular'}
          onDelete={() => setDataProvider(prevState => ({...prevState, activeStep: 5}))}
          onForward={() => setDataProvider(prevState => ({...prevState, activeStep: 5}))}
          onBack={() =>  setDataProvider(prevState => ({...prevState, activeStep: 3}))}
        />
      }

      {activeStep === 5 &&
        // -> Conclusion Page
        <SimpleButtons
          forwardButton={'Concluir'}
          onForward={() => navigate('/contratos')}
          onBack={() => setDataProvider(prevState => ({...prevState, activeStep: 4}))}
        />
      }

    </>
  )
}

export default RentWizard