import React from "react";
import {
  Button,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import {getCondoId} from "../../../../../services/Main/AuthStorage";

const TableCondoBody = (  {
    condoData,
    changeCondo
  }) => {

  return (
    <TableBody>
      {condoData?.results?.map(object => (
        <TableRow
          key={object?.id}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
          }}>
          <TableCell>
            {object?.name}
          </TableCell>
          <TableCell>
            {object?.state}
          </TableCell>
          <TableCell>
            {object?.city}
          </TableCell>

          { +getCondoId() !== +object?.id ?
            <TableCell>
              <Button
                value={object?.id}
                onClick={({target:{value}}) => changeCondo(value)}
              >
                Acessar
              </Button>
            </TableCell>
            :
            <TableCell>
              <Typography
                sx={{
                  color: '#14a72e',
                  textTransform: 'uppercase',
                  fontSize: '0.875rem',
                  paddingY: '6px',
                  paddingX: '8px'

                }}
              >
                Atual
              </Typography>
            </TableCell>
          }
        </TableRow>
      ))}
    </TableBody>
  )
}

export default TableCondoBody
