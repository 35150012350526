import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Button, Dialog, useMediaQuery} from "@mui/material";
import * as React from "react";

const MakeDelete = ({ openByStatus, handleCloseConfirmation, handleMakeItDelete  }) => {
    const sizeMatch = useMediaQuery('@media (min-width:600px)')

    return (
        <Dialog
            fullScreen={!sizeMatch}
            sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '600px', maxHeight: 435 }, padding: 2 }}

            maxWidth="xs"
            open={openByStatus.deleted ?? !!openByStatus.deleted }
            onClick={event => event.stopPropagation()}
        >
            <DialogTitle>{"Deletar Fatura"}</DialogTitle>
            <DialogContent>
                <strong>{"Atenção"}</strong>
                <br/>
                {"Se a fatura for deletada, não será possível criar outra para o mesmo mês."}
                <br/>
                {"Deseja realmente deletar esta fatura?"}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button autoFocus onClick={handleCloseConfirmation}>{"Cancelar"}</Button>
                <Button variant={"contained"} onClick={handleMakeItDelete}>{"Confirmar"}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default MakeDelete