import {Skeleton, TextField} from "@mui/material";
import Masks from "./Masks";
import React from "react";

const SimpleText =
  ({
     label,
     state,
     setState,
     keyName,
     disabled,
     multiline,
     rows,
     error,
     styles,
     required,
     onlyNumbers,
     variant,
     cpf,
     cnpj,
     cpfAndCnpj,
     money,
     cep,
     phone,
     InputProps,
     isLoading, // -> General usage is just passing the state you are going to use, but before set the value always use `!`
     directHelper,
     directError,
     directValue,
     onChange,
     inputProps,
     percent,
     decimals,
     float,
}) => {
  return (
    <>
     {!isLoading ?
         <TextField
           label={label}
           disabled={disabled}
           required={required}
           multiline={multiline}
           rows={rows}
           fullWidth
           size={'small'}
           value={(
             onlyNumbers ? state?.[keyName]?.toString()?.replace(/\D+/g, "") ?? ''
               : cpf ? Masks?.cpf(state?.[keyName]?.toString() ?? '')
               : cnpj ? Masks?.cnpj(state?.[keyName]?.toString() ?? '')
               : cpfAndCnpj ?  Masks?.cpfOrCnpj(state?.[keyName]?.toString() ?? '') ?? ''
               : money ? Masks?.money(state?.[keyName]?.toString()  ?? '')
               : cep ? Masks?.cep(state?.[keyName]?.toString() ?? '')
               : phone ? Masks?.phone(state?.[keyName]?.toString() ?? '' )
               : directValue ? directValue
               : decimals ? state?.[keyName]?.toString()?.replace(/[^0-9.,]/g, '') ?? ''
               : float ? state?.[keyName]?.toString()
                                 ?.replace(/[.]/g, ',')
                                 ?.replace(/[^0-9,]|,(\d{1,2}).*|(?<=,.*),/g, (_, group) => `,${group || ''}`)
                                 ?? ''
               : state?.[keyName]) ?? ''}
           onChange={setState ? (e) => setState(prevState => ({...prevState, [keyName]: e.target.value})) : onChange}
           error={directError ? !!directError : !!error?.[keyName]}
           helperText={directHelper ?? error?.[keyName]}
           variant={variant}
           sx={styles}
           InputProps={InputProps}
           inputProps={{
              ...(cpf && { maxLength: 14 }),
              ...(cpfAndCnpj && { maxLength: 18 }),
              ...(cnpj && { maxLength: 18  }),
              ...(phone && { maxLength: 15 }),
           }}
         />
            :
            <Skeleton
              variant="rounded"
              animation="pulse"
              height={39.964}
              sx={styles}
            />
          }
    </>
  )
}

export default SimpleText