import {FormControlLabel, Switch} from "@mui/material";
import React from "react";

const maintenanceCondoMailCheck = ({state, setState, disabled}) => {
  return (
    <FormControlLabel
      sx={{marginBottom: '1rem'}}
      control={
        <Switch
          disabled={disabled}
          checked={!!state?.select_email}
          onChange={(e) => {
            setState((prevState) => ({...prevState, select_email: !state?.select_email}))
          }}
          name="Enviar por e-mail"
        />}
      label={'Enviar via e-mail para os condôminos?'}
    />
  )
}

export default maintenanceCondoMailCheck