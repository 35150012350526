import {Grid, TextField} from "@mui/material";

const AccountInfo = ({ reqData, setReqData }) => {
  return (
    <Grid container spacing={1} sx={{ mb: 2 }}>
      <Grid item xs={4}>
        <TextField
          label="Agência"
          fullWidth
          size={'small'}
          type="text"
          required
          value={reqData?.bank_branch ?? ''}
          onChange={(({ target: { value } }) => setReqData((prevState) => ({
            ...prevState,
            bank_branch: value.slice(0, 20)
          })))}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Dígito (agência)"
          fullWidth
          size={'small'}
          type="text"
          required
          value={reqData?.bank_branch_digit ?? ''}
          onChange={(({ target: { value } }) => setReqData((prevState) => ({
            ...prevState,
            bank_branch_digit: value.slice(0, 20)
          })))}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Conta"
          size={'small'}
          fullWidth
          type="text"
          required
          value={reqData?.bank_account ?? ''}
          onChange={(({ target: { value } }) => setReqData((prevState) => ({
            ...prevState,
            bank_account: value.slice(0, 20)
          })))}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Dígito (conta)"
          size={'small'}
          fullWidth
          type="text"
          required
          value={reqData?.bank_account_digit ?? ''}
          onChange={(({ target: { value } }) => setReqData((prevState) => ({
            ...prevState,
            bank_account_digit: value.slice(0, 20)
          })))}
        />
      </Grid>
    </Grid>
  )
}

export default AccountInfo