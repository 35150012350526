import React from "react";
import {
  Box,
  Skeleton,
  Typography
} from "@mui/material";
import {Subtitles} from "@mui/icons-material";

const BookingReadName = ({
    isLoading,
    dataUpdate
  }) => {

  return (
    <>
      {!isLoading ?
        <Box
          sx={{
            display: 'flex'
          }}
        >
          <Subtitles sx={{mr: '0.5rem'}}/>
          <Typography
            sx={{marginBottom: '0.5rem'}}
          >
            <b>Título:</b> {dataUpdate?.name}
          </Typography>
        </Box>
        :
        <Skeleton
          variant="rounded"
          animation="pulse"
          height={24}
          sx={{marginBottom: '0.5rem'}}
        />
      }
    </>
  )
}

export default BookingReadName