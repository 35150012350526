import {Skeleton, Typography} from "@mui/material";
import NoDescription from "../../../../components/Shared/animations/NoDescription";
import React from "react";

const DocumentsReadyOnly = ({ reqData, documents }) => {
  return (
    <>
      {reqData?.title && documents ?
        <>
          <Typography
            variant={'h5'}
            sx={{
              mt:'1rem',
              mb: '2rem'
            }}
          >
            {reqData?.title}
          </Typography>
          {reqData?.description ?
            <Typography
              sx={{
                wordBreak: 'break-word',
                mb: '2rem',
              }}
            >
              {reqData?.description}
            </Typography>
            :
            <NoDescription
              size={'200px'}
              fontSize={'20px'}
            />
          }
        </>
        :
        <>
          <Skeleton
            height={50}
            sx={{
              mt:'1rem',
              // mb: '2rem',
              width: '100%'
            }}
          />
          <Skeleton
            height={500}
            sx={{
              mt:'-6rem',
              width: '100%'
            }}
          />
        </>
      }
    </>
  )
}

export default DocumentsReadyOnly