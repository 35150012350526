import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import {Box, Typography} from "@mui/material";
import {Check, Close} from "@mui/icons-material";
import React from "react";
import Masks from "../../../../components/Shared/Masks";


const SlipOptionsRead =
  (
    {
      reqData,
    }
  ) => {

      function referenceType(param) {
          switch (param) {
              case 'none':
                  return 'Sem Referência';
              case 'month':
                  return 'Mês de Referência';
              case 'range':
                  return 'Referência Entre Datas';
              case 'payment':
                  return 'Mês de Competência';
          }
      }


  function fineType() {
    if (reqData?.bankslip_fine_percent) {
      return 'percentual'
    } else if (reqData?.bankslip_fine_value){
      return 'fixa'
    } else {
      return ''
    }
  }

  function fineValue() {
    if (reqData?.bankslip_fine_percent || reqData?.bankslip_fine_percent === 0) {
      return <Typography>{reqData.bankslip_fine_percent}%</Typography>;
    } else if (reqData?.bankslip_fine_value || reqData?.bankslip_fine_value === 0) {
      return <Typography>{Masks.money(String(reqData.bankslip_fine_value) ?? '') ?? ''}</Typography>;
    }else {
      return <Box sx={{fontSize: '13px'}}>Não Informado</Box>;
    }
  }

  function interestType() {
    if (reqData?.bankslip_interest_percent) {
      return 'percentual'
    } else if (reqData?.bankslip_interest_value){
      return 'fixo'
    } else {
      return ''
    }
  }

  function interestValue() {
    if (reqData?.bankslip_interest_percent || reqData?.bankslip_interest_percent === 0) {
      return <Typography>{reqData.bankslip_interest_percent}%</Typography>;
    } else if (reqData?.bankslip_interest_value || reqData?.bankslip_interest_value === 0) {
      return <Typography>{Masks.money(String(reqData.bankslip_interest_value) ?? '') ?? ''}</Typography>;
    } else {
        return <Box sx={{fontSize: '13px'}}>Não Informado</Box>;
    }
  }


    function dueDay(){
    if (reqData?.bankslip_due_day) {
      return <Typography>{reqData?.bankslip_due_day}</Typography>
    } else {
      return <Box sx={{ color:'error.main'}}>{<Close/>}</Box>
    }
  }

  return (
    <Box
      sx={{
        mt: '1rem'
        // paddingX: '2rem',
      }}
    >
        <SimpleTypography
            label={`Dia de Vencimento`}
            directValueFree={<Box>
                <SimpleTypography
                    label={reqData?.bankslip_due_day ? `Vencimento todo dia ${reqData?.bankslip_due_day}` : 'Não Selecionado'}
                    // directValue={reqData?.generate_bankslip_day}
                    labelStyles={{
                        fontSize: '13px'
                        // fontWeight: 'bold'
                    }}
                    boxStyles={{
                        mt: '0.2rem',
                        width: '100%',
                        mb: '0'


                    }}
                />
            </Box>}
            labelStyles={{
                fontWeight: 'bold'
            }}
            boxStyles={{
                width: '100%',
                mb: '0.5rem'

            }}
        />

        <SimpleTypography
        label={'Geração Automática'}
        directValueFree={
          <Box>
              <SimpleTypography
                  label={reqData?.generate_bankslip_day ? `Gerar todo dia ${reqData?.generate_bankslip_day}` : 'Não Selecionado'}
                  // directValue={reqData?.generate_bankslip_day}
                  labelStyles={{
                      fontSize: '13px'
                      // fontWeight: 'bold'
                  }}
                  boxStyles={{
                      mt: '0.2rem',
                      width: '100%',
                      mb: '0'


                  }}
              />
          </Box>
        }
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
            width: '100%',
            mb: '0.5rem'

        }}
      />
        <SimpleTypography
            label={`Desconto até Dia`}
            directValueFree={
                <Box>
                <SimpleTypography
                label={reqData?.bankslip_discount_day ? `Desconto até o Dia ${reqData?.bankslip_discount_day}` : 'Não Selecionado'}

                    // directValue={reqData?.generate_bankslip_day}
                labelStyles={{
                fontSize: '13px'
                // fontWeight: 'bold'
            }}
                boxStyles={{
                mt: '0.2rem',
                width: '100%',
                mb: '0'


            }}
                />
                </Box>
        }
            labelStyles={{
                fontWeight: 'bold'
            }}
            boxStyles={{
                width: '100%',
                mb: '0.5rem'


            }}
        />

        <SimpleTypography
            label={`Valor do Desconto`}
            directValueFree={reqData?.bankslip_discount_value !== null ?
                Masks.money(String(reqData?.bankslip_discount_value)) :
                <Typography sx={{fontSize: '13px'}}>Não Selecionado</Typography>
                }
            labelStyles={{
                fontWeight: 'bold'
            }}
            boxStyles={{
                width: '100%',
                mb: '0.5rem'

            }}
        />

        <SimpleTypography
            label={`Multa ${fineType()}`}
            directValueFree={fineValue()}
            labelStyles={{
                fontWeight: 'bold'
            }}
            boxStyles={{
                width: '100%',
                mb: '0.5rem'


            }}
        />

        <SimpleTypography
            label={`Juros ${interestType()}`}
            directValueFree={interestValue()}
            labelStyles={{
                fontWeight: 'bold'
            }}
            boxStyles={{
                width: '100%',
                mb: '0.5rem'

            }}
        />

        <SimpleTypography
            label={'Modo de Referência'}
            directValueFree={
                <Box>
                    <SimpleTypography
                        label={(reqData?.reference_mode?.label)}
                        // directValue={reqData?.generate_bankslip_day}
                        labelStyles={{
                            fontSize: '13px'
                            // fontWeight: 'bold'
                        }}
                        boxStyles={{
                            mt: '0.2rem',
                            width: '100%',
                            mb: '0'


                        }}
                    />
                </Box>
            }
            labelStyles={{
                fontWeight: 'bold'
            }}
            boxStyles={{
                width: '100%',
                mb: '0.5rem'

            }}
        />

        <SimpleTypography
        label={'Envio para o E-mail'}
        directValueFree={
          <Box>
            {reqData?.send_email ?
                <Box>
                    <SimpleTypography
                        label={`Enviando por E-mail`}
                        // directValue={reqData?.generate_bankslip_day}
                        labelStyles={{
                            fontSize: '13px'
                            // fontWeight: 'bold'
                        }}
                        boxStyles={{
                            mt: '0.2rem',
                            width: '100%',
                            mb: '0'


                        }}
                    />
                </Box>
                :
                <Box>
                    <SimpleTypography
                        label={`Não Enviado`}
                        // directValue={reqData?.generate_bankslip_day}
                        labelStyles={{
                            fontSize: '13px'
                            // fontWeight: 'bold'
                        }}
                        boxStyles={{
                            mt: '0.2rem',
                            width: '100%',
                            mb: '0'


                        }}
                    />
                </Box>
            }
          </Box>
        }
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'
        }}
      />
        <SimpleTypography
            label={'Quantos Dias Enviar E-mail'}
            directValueFree={
                <Box>
                    <SimpleTypography
                        label={(reqData?.send_email_daily?.label)}
                        // directValue={reqData?.generate_bankslip_day}
                        labelStyles={{
                            fontSize: '13px'
                            // fontWeight: 'bold'
                        }}
                        boxStyles={{
                            mt: '0.2rem',
                            width: '100%',
                            mb: '0'


                        }}
                    />
                </Box>
            }
            labelStyles={{
                fontWeight: 'bold'
            }}
            boxStyles={{
                width: '100%',
                mb: '0.5rem'

            }}
        />

        <SimpleTypography
          label={'Envio para o Whatsapp'}
          directValueFree={
            <Box>
              {reqData?.whatsapp_send_message ?
                  <Box>
                      <SimpleTypography
                          label={`Enviando por Whatsapp`}
                          // directValue={reqData?.generate_bankslip_day}
                          labelStyles={{
                              fontSize: '13px'
                              // fontWeight: 'bold'
                          }}
                          boxStyles={{
                              mt: '0.2rem',
                              width: '100%',
                              mb: '0'


                          }}
                      />
                  </Box>
                  :
                  <Box>
                      <SimpleTypography
                          label={`Não Enviado`}
                          // directValue={reqData?.generate_bankslip_day}
                          labelStyles={{
                              fontSize: '13px'
                              // fontWeight: 'bold'
                          }}
                          boxStyles={{
                              mt: '0.2rem',
                              width: '100%',
                              mb: '0'


                          }}
                      />
                  </Box>
              }
            </Box>
          }
          labelStyles={{
            fontWeight: 'bold'
          }}
          boxStyles={{
            width: '100%',
            // mb: '0.5rem'
          }}
      />
    </Box>
  )
}

export default SlipOptionsRead