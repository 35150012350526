import React from "react";
import {Box} from "@mui/material";
import AnimationGlobal from "../../../../../components/Shared/animations/AnimationGlobal";
import loading from "../../../../../assets/animations/loading.json";

const ScrollObserver = ({ responseData }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Box
        id="scroll-observer"
        sx={{
          mt: '-60px',
        }}
      >

        {responseData?.next &&
          <AnimationGlobal
            size={'200px'}
            animation={loading}
            loop={true}
          />
        }
      </Box>
    </Box>
  )
}

export default ScrollObserver