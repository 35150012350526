import React from "react";
import {
  Box,
  Skeleton,
  Typography
} from "@mui/material";
import NoDescription from "../../../../../components/Shared/animations/NoDescription";

const ChargeDescriptionUpdate = ({
    edit,
    isLoading,
    state,
  }) => {

  return(
    <>
      {!isLoading ?
        <Box>

            {state?.description ?
              <>
                <Typography
                  sx={{
                    fontSize: '18px',
                  }}
                >
                  {state?.description}
                </Typography>
              </>
              : !state?.description &&
              <NoDescription
                size={'200px'}
                fontSize={'24px'}
              />
            }
        </Box>
        :
          <Skeleton
            variant="rounded"
            animation="pulse"
            height={150}
          />
      }
    </>

  )
}

export default ChargeDescriptionUpdate