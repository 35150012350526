import {Badge, Box, Skeleton, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import Masks from "./Masks";
import moment from "moment";
import {api} from "../../services/Main/Api";
import {getCondoId} from "../../services/Main/AuthStorage";
import NoDescription from "./animations/NoDescription";
import {Close} from "@mui/icons-material";
import { styled } from '@mui/system';


const SimpleTypography =
  (
    {
      isLoading,
      state,
      keyName,
      label,
      badge,
      type,
      styles,
      labelStyles,
      block,
      boxStyles,
      directValue,
      directValueFree
    }) => {
  const [units, setUnits] = useState(null)

  const StyledBadge = styled(Badge)(({ theme }) => ({
      '& .MuiBadge-badge': {
          backgroundColor: "",
          border: '2px solid #DD1960',
          color: '#DD1960',
          position: 'relative',
          marginLeft: -25,
          fontSize: 11,
      },
  }));


  useEffect(function getUnits() {
    if (type === 'unit') {
    api.get(`unit/?condo_id=${getCondoId()}`)
      .then(r => {
        setUnits(r?.data.results)
      })
      .catch(r => {
        console.log(r?.data)
      })
      .finally(() =>{
      })
    }
  }, [type])

  const unitValues = units?.map((option) => ({ name: `${option.number} | ${option.name}`, id: option.id }))

  return (
    <>
      {!isLoading ?
        <>
        { !block ?
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            // height: 16,
            mb: '2rem',
            ...boxStyles

          }}
        >

          {label && badge?
              <StyledBadge badgeContent={"Novo"}>
            <Typography
              sx={labelStyles}
            >
              {label}
            </Typography>
              </StyledBadge>
              :
              <Typography
                  sx={labelStyles}
              >
                  {label}
              </Typography>
          }


          {directValue &&
            <Typography
              sx={styles}
            >
              {directValue}
            </Typography>
          }

          {directValueFree &&
            <>
            {directValueFree}
            </>
          }

          {state && keyName &&
          <Typography
            sx={styles}
          >
            {(
              type === 'unit'
              ? unitValues?.find(e => e.id === state?.[keyName])?.name
              : type === 'money'
              ? Masks.money(String(state?.[keyName]))
              : type === 'moneyMultiplier'
              ? Masks.money(Math.round(state?.[keyName] * 100).toString())
              : type === 'date'
              ? moment(state[keyName])?.format('DD/MM/YYYY')
              : type === 'dateTime'
              ? moment(state[keyName])?.calendar()
              : type === 'time'
              ? moment(state[keyName])?.format('HH:mm')
              : state[keyName]) ?? <Close sx={{color: 'error.main'}}/>}
          </Typography>
          }
        </Box>
            :
            <Box>
              {state?.[keyName] ?
                <Typography
                  sx={styles}
                >
                  {state?.[keyName]}
                </Typography>
                : !state?.[keyName] &&
                <NoDescription
                  size={'200px'}
                  fontSize={'24px'}
                />
              }
            </Box>
          }
        </>
        :
        <Skeleton
          variant="rounded"
          animation="pulse"
          height={24}
          sx={{
            mb: '2rem',
            ...boxStyles
          }}
        />
      }
    </>
  )
}

export default SimpleTypography