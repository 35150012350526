import {
  Alert,
  Box,
  CardContent,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Table,
  TableContainer,
  Skeleton,
  useMediaQuery
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import Context from "../../../../../contexts/Context";
import {getCondoId} from "../../../../../services/Main/AuthStorage";
import {api} from "../../../../../services/Main/Api";
import TableTransferHead from "./TableTransferHead";
import TableTransferBody from "./TableTransferBody";
import TransferForm from "./TransferForm";
import SearchInput from "../../../../../components/Filters/SearchInput";
import NoResultsAnimation from "../../../../../components/Shared/animations/NoResultsAnimation";
import PaginationGlobal from "../../../../../components/Shared/PaginationGlobal";


const BankTransferList = (transferType) => {
  const  { filters, setFilters } = useContext(Context)

  const [data, setData] = useState(null)
  const [transferOpen, setTransferOpen] = useState()

  const initialFilters = () => {
    setFilters({
      page_size: 50,
      transferType: transferType?.transferType ? transferType?.transferType : '',
      bank_account__condo_id: +getCondoId(),
      ordering: '-created_at',
      search: '',
      status: ''
    })
  }

  useEffect(function setFilters(){
    initialFilters()
  },[])

    useEffect(function listTransfers () {
        if (filters?.transferType) {
            if (filters.transferType === 'ted') {
                api.get(`fitbank/money-transfer/history/`, {params: filters})
                    .then(response => {
                        setData(response.data)
                    })
                    .catch(error => {
                        console.log(error)
                    });
            } else if (filters.transferType === 'pix') {
                api.get(`fitbank/pix-transfer/history/`, {params: filters})
                    .then(response => {
                        setData(response.data)
                    })
                    .catch(error => {
                        console.log(error)
                    });
            }
        }
    }, [filters?.transferType, filters?.search, filters?.status, filters?.ordering, filters?.page]);
  const sizeMatch = useMediaQuery('@media (min-width:600px)')

  return(
    <>
      <CardContent
        sx={{
          paddingX: '2rem',
          mb: '1rem',
          width: '100%',
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent={'space-between'}
        >
            <Grid item xs={12}>
              <ToggleButtonGroup
                aria-label="Selecionar uma opção"
                fullWidth
                exclusive
                value={filters?.transferType}
                color={'primary'}
                onChange={(e, value) => {
                  setData(null)
                  initialFilters()
                  setFilters(prevState => ({...prevState, transferType: value}))
                }}
                sx={{
                  mb: '2rem'

              }}
              >
                <ToggleButton
                  value={"ted"}
                  selected={filters?.transferType === 'ted'}
                >
                  TED
                </ToggleButton>
                <ToggleButton
                  value={"pix"}
                  selected={filters?.transferType === 'pix'}
                >
                  Pix
                </ToggleButton>
              </ToggleButtonGroup>





              {/*<DateSelect />*/}
              {data ?
              <>
                  {filters?.transferType &&
                    <>
                    {/*<Divider*/}
                    {/*  sx={{*/}
                    {/*    mb: '1rem'*/}
                    {/*  }}*/}
                    {/*/>*/}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: sizeMatch ? 'row' : 'column',
                        // justifyContent: 'space-between',
                        alignItems: sizeMatch ? 'center' : '',
                        mb: '2rem'
                      }}
                    >
                      <SearchInput
                        label={'Procurar por nome'}
                        styles={{
                          maxWidth: '300px',
                          mb: sizeMatch ? 'auto' : '1rem',
                          mr: sizeMatch ? '0.5rem' : 'auto'
                        }}
                      />
                      <ToggleButtonGroup
                        aria-label="Selecionar uma opção"
                        fullWidth
                        size={'small'}
                        exclusive
                        value={filters?.transferType}
                        color={'primary'}
                        onChange={(e, value) => {
                          setData(null)
                          setFilters(prevState => ({...prevState, status: value}))
                         }}
                        sx={{
                          maxWidth: '300px'
                          }}
                        >
                          <ToggleButton
                            value={''}
                            selected={filters?.status === ''}
                          >
                            Todas
                          </ToggleButton>
                          <ToggleButton
                            value={
                              filters?.transferType === 'ted' ? 0 :
                              filters?.transferType === 'pix' && 'Created'
                            }
                            selected={
                              filters?.transferType === 'ted' ? filters?.status === 0 :
                              filters?.transferType === 'pix' && filters?.status === 'Created'
                            }
                          >
                            Agendadas
                          </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                    </>
                  }
                {filters?.transferType &&
                  <Box>
                    <Alert
                      // variant={'filled'}
                      severity={'info'}
                      sx={{
                        mb: '1rem',
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        padding: 0
                      }}
                    >
                      {'Transferências agendadas podem ser canceladas'}
                    </Alert>
                  </Box>
                }
                <TableContainer>
                    {filters?.transferType && data?.results?.length > 0 ?
                        <Table>
                          <TableTransferHead/>
                          <TableTransferBody
                            state={data}
                            setTransferOpen={setTransferOpen}

                          />
                        </Table>
                      : filters?.transferType &&
                      <NoResultsAnimation />
                    }
                </TableContainer>
              </>
                : filters?.transferType &&
                  <Skeleton
                    variant="rounded"
                    height={500}
                    animation="pulse"
                    sx={{
                      mb: '1.5rem'
                    }}
                  />

              }
            </Grid>
        </Grid>
      </CardContent>

      <TransferForm
        transferOpen={transferOpen}
        setTransferOpen={setTransferOpen}
      />

      <PaginationGlobal
        state={data}
        filters={filters}
        setFilters={setFilters}
      />


    </>
  )
}

export default BankTransferList

