import React, {useContext} from "react";
import {
  Box, Button, Chip,
  IconButton,
  Link,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material'
import InvoiceIcon from "../../../../../components/Invoice/InvoiceIcon";
import moment from "moment/moment";
import StatusInvoice from "../../../../../components/Invoice/StatusInvoice";
import Masks from "../../../../../components/Shared/Masks";
import { Close, PictureAsPdf, Link as LinkIcon, FileOpen, FileOpenOutlined, OpenInNew } from '@mui/icons-material'
import MakeItPaidMenu from "../../MakeItPaidMenu";
import {useNavigate} from "react-router-dom";
import {getNotBank} from "../../../../../services/Main/AuthStorage";
import Context from "../../../../../contexts/Context";

const TableInvoiceBody = ({state, handleManualPayment, reference}) => {
  const navigate = useNavigate()
  const { user } = useContext(Context)
  const { perm_bank } = user || {}

  return (
    <TableBody>
      {state?.results?.map(object =>
        <TableRow
          key={object.number}
          hover
          onClick={(e) => {
            e.stopPropagation()
            navigate(`/faturas/${object.id}`)
          }}
          sx={{ 'cursor': 'pointer', }}
        >
          <TableCell>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <InvoiceIcon
                variant={object?.status.key}
                key={object.id}
              />
              {object.number}
            </Box>
          </TableCell>
          <TableCell>
            {object.unit}
          </TableCell>
          { reference === 'payment' ?
          <TableCell
            align={'center'}
          >
            {object.payment_month_reference}
          </TableCell>
            :
          <TableCell
              align={'center'}
          >
            {object.reference_month}
          </TableCell>
          }
          <TableCell
            align={'center'}
          >
            {object.due_date
              ? moment(object.due_date).format('DD/MM/YYYY') : '-'
            }
          </TableCell>
          <TableCell
            align={'center'}
          >
            <StatusInvoice
              invoice={object}
              paidAt={object?.paid_at}
            />
          </TableCell>
          <TableCell
            sx={{
              fontWeight: 'bold'
            }}
          >
            {object?.total.toString().includes('-') && '- '}
            {Masks.money(object?.total.toString())}
          </TableCell>
          <TableCell
            align={'center'}

          >
            { perm_bank === true &&
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {/*{object?.bank_slip?.length > 0 ?*/}
            {/*   <Link*/}
            {/*    href={object?.bank_slip[0].file}*/}
            {/*    target="_blank"*/}
            {/*    onClick={(e) => e.stopPropagation()}*/}
            {/*  >*/}
            {/*    <IconButton>*/}
            {/*      <PictureAsPdf/>*/}
            {/*    </IconButton>*/}
            {/*  </Link>*/}
            {/*  :*/}
            {/*  <Close sx={{ color: 'error.main'}} />*/}
            {/*}*/}

              { object?.status.key !== 'open' && object?.status.key !== 'processing' && object.paid_by !== 'MANUAL' ?

                  <Link
                      href={object?.payment_page}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}>
                    <Chip
                        label={'Página de pagamento'}
                        size={'small'}
                        icon={<OpenInNew/>}
                    />
                  </Link>
                  :
                  <Close sx={{color: 'error.main'}}/>
              }
            </Box>
            }
          </TableCell>
          <TableCell
            align={'right'}
          >
            {(object.status.key !== 'paid' && object.status.key !== 'processing' ||
                    object.paid_by === 'MANUAL') &&
              <MakeItPaidMenu
                invoice={object}
                onSuccess={handleManualPayment}
              />
            }
          </TableCell>
        </TableRow>
      )
      }
    </TableBody>
  )
}

export default TableInvoiceBody;