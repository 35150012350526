import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { CssBaseline, Grow, ThemeProvider } from '@mui/material'
import theme from './layout/Main/Theme'
import { SnackbarProvider } from 'notistack'
import { HelmetProvider } from 'react-helmet-async'
import AuthProvider from './providers/AuthProvider'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <CssBaseline/>
          <AuthProvider>
            <App/>
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </HelmetProvider>
)

reportWebVitals()
