import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

const ChartRadialBarB3 = ({value, color, icon}) => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    setProgress(value)
  }, [value]);

  return (
    <Box sx={{ 
      position: 'relative', 
      display: 'inline-flex', 
      "@media (max-width:1199px)":{
      mr: 2
      },
      }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],            
        }}
        size={50}
        thickness={4}
        value={100}
      />
      <CircularProgress
        color={color || 'primary'}
        variant={'determinate'}
        value={progress || 0}
        size={50}
        sx={{position: 'absolute', left: 0}}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {icon ? icon : ""}
      </Box>
    </Box>
  )
}

ChartRadialBarB3.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
  icon: PropTypes.object
}

ChartRadialBarB3.defaultProps = {
  value: 0,
  color: "primary",
  icon: ""
}

export default ChartRadialBarB3
