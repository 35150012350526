import {Box, Grid, Skeleton, Typography} from "@mui/material";

const TransferDescription = ({ loading, data }) => {
  return (
    <Box
      py={1}
      my={3}
      borderTop={'1px solid #eee'}
    >
      <Grid
        container
        spacing={1}
        fontSize={'small'}
      >
        <Grid
          item
          xs={4}
        >
          <Typography
            variant={'subtitle2'}
          >
            {'Descrição'}
          </Typography>
        </Grid>
        <Grid
          item
          xs={8}
        >
          <Typography
            variant={'body2'}
          >
            {!loading
              ? data?.description || '-'
              : <Skeleton
                sx={{
                  mx: 'auto'
                }}
                variant={'text'}
                width={'100%'}
              />
            }
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TransferDescription