import {
  useEffect,
  useState,
  useContext
} from 'react'
import {
  Box,
  Card,
  Container,
  Pagination,
  Skeleton,
  Table,
  TableContainer,
} from '@mui/material'
import { api } from '../../../services/Main/Api'
import { getCondoId } from '../../../services/Main/AuthStorage'
import 'moment/locale/pt-br'
import { Helmet } from 'react-helmet'
import Context from "../../../contexts/Context";
import SearchField from "../../../components/Shared/SearchField";
import TableServiceHead from "./components/ServiceList/TableServiceHead";
import TableServiceBody from "./components/ServiceList/TableServiceBody";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import {AddCircleOutlined} from "@mui/icons-material";


const ServiceList = () => {
  const { filters, setFilters } = useContext(Context)
  useEffect(function serviceListFilters(){
    setFilters({
      condo: getCondoId(),
      page_size: 50
    })

  },[])
  const [providers, setProviders] = useState(null)
  const [jobs, setJobs] = useState(null)

  useEffect(function firstLoad(){
    api.get('/service/profession/')
      .then(response => {
        setJobs(response.data.results)
      })
      .catch(error => {
        console.log(error)
      })

  }, [])

  useEffect(function whenPageLoad(){
    if(filters) {
      api.get('/service/provider/', {params: filters}).then( r =>{
        setProviders(r.data)
      })
    }
  }, [filters])

  const handlePage = (e, value) => {
    setFilters({...filters, page: value})
  }

  return (
    <>
      <Helmet>
        <title>Serviços - Bloco B3</title>
      </Helmet>
      <Container maxWidth={false}>
      <TitleBtnList
        title={'Prestadores de Serviço'}
        buttonText={'Cadastrar Prestador'}
        buttonHref={'/manutencao/servicos/cadastrar'}
        icon={<AddCircleOutlined />}
      />
      <Card>
          <SearchField
            label={"Buscar Pelo Nome/Serviço"}
            state={filters}
            setState={setFilters}
            keyName={'search'}
            boxStyles={{
              p: '1rem',
              maxWidth: '400px'
            }}
          />
        {jobs ?
          <>
            <TableContainer sx={{p: 2}}>
              { providers?.results?.length > 0 ?
              <Table sx={{ minWidth: 800 }}>
                <TableServiceHead />
                <TableServiceBody
                  providers={providers}
                  jobs={jobs}
                />
              </Table>
              :
                <NoResultsAnimation />
              }
            </TableContainer>
        </>
        :
        <Container maxWidth={false}>
          <Skeleton
            variant="rounded"
            height={500}
            animation="pulse"
          />
        </Container>

      }
      </Card>
        {
          providers?.count > 0 &&
          <Box spacing={2} display={'flex'} justifyContent={'center'} sx={{ m: 3 }}>
            <Pagination
              count={Math.ceil(+providers?.count / +filters.page_size)}
              variant={'outlined'}
              color={'primary'}
              onChange={handlePage}
            />
          </Box>
        }
      </Container>
    </>
  )
}

export default ServiceList;
