import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useContext } from 'react'
import { api } from '../../services/Main/Api'
import {getCondoId} from "../../services/Main/AuthStorage";
import Context from "../../contexts/Context";

const ServiceFilter = ({ state, setState, keyName, defaultValue, styles, variant, value, error, label, disabled }) => {
  const { setFilters, unitOptions, getServicesOptions, servicesOptions } = useContext(Context)

  useEffect(() => {
    if (!servicesOptions) {
      getServicesOptions()
    }
  }, [])

  const alternateState = setState ?? setFilters

  return (
    <>
      <Autocomplete
        disabled={disabled}
        fullWidth
        disableClearable
        onChange={(_event,value) => alternateState((prevState) => ({...prevState, [keyName ?? 'service']: value?.value }))}
        defaultValue={defaultValue}
        value={value}
        renderInput={(props) => <TextField
          {...props}
          variant={variant}
          label={label ?? 'Serviço'}
          size={"small"}
          error={!!error?.[keyName]}
          helperText={error?.[keyName]}
        />}
        getOptionLabel={(option) => `${option.label}` ?? ''}
        options={servicesOptions ?? ''}
        // isOptionEqualToValue={(option, value) => option.id === value.id}
        sx={styles}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {`${option.label}`}
          </li>
        )}
      />
    </>
  )
}

export default ServiceFilter
