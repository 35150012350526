import {Box, Button} from "@mui/material";
import {ArrowBack, SaveOutlined} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import React from "react";

const SimpleButtons = (
  {
    forwardButton,
    backButton,
    loadingButton,
    onForward,
    onBack,
    type,
    deleteButton,
    onDelete,
    deleteText,
    cancelButton,
    onCancel,
    noBack,
    loadingText,
    loading,
    onLoadBtn,
    forwardDisable,
    variantLoading,
    loadDisable,
    justifyContent
  }) => {
  const back = () => window.history.back()

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: justifyContent ?? "space-between",
        marginY: "1rem"
      }}
    >
    {!noBack ?
      <Button
        type={"button"}
        variant={"outlined"}
        startIcon={<ArrowBack/>}
        onClick={onBack ?? back}
      >
        {backButton ?? 'Voltar'}
      </Button>
      :
      <Box>
      </Box>
    }
      <Box>
        {deleteButton &&
          <Button
            onClick={onDelete}
            variant="contained"
            size="large"
            sx={{
              mr: '1rem'
            }}
          >
            {deleteText ?? 'Excluir'}
          </Button>
        }
        {cancelButton &&
          <Button
            onClick={onCancel}
            variant="contained"
            size="large"
            sx={{
              mr: '1rem'
            }}
          >
            {deleteText ?? 'Cancelar'}
          </Button>
        }
        {forwardButton &&
          <Button
            type={type ?? 'submit'}
            variant="contained"
            size="large"
            disabled={forwardDisable}
            onClick={onForward}
          >
            {forwardButton}
          </Button>
        }
        {loadingButton &&
          <LoadingButton
            disabled={loadDisable}
            loading={loading}
            startIcon={<SaveOutlined/>}
            sx={{ml: 2}}
            variant={variantLoading ?? 'contained'}
            onClick={onLoadBtn}
            // type={'submit'}
            color={'primary'}
          >
            {loadingText}
          </LoadingButton>
        }
      </Box>
    </Box>
  )
}

export default SimpleButtons