import React, {useContext, useEffect, useState} from "react";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {
  Box,
  Card,
  Container,
  Pagination,
  Skeleton,
  Table,
  TableContainer,
} from '@mui/material'
import { Helmet } from 'react-helmet'
import {AddCircleOutlined} from "@mui/icons-material";
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import SearchField from "../../../components/Shared/SearchField";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import TableDocumentHead from "./components/TableDocumentHead";
import TableDocumentBody from "./components/TableDocumentBody";
import {api} from "../../../services/Main/Api";
import Context from "../../../contexts/Context";
import SearchInput from "../../../components/Filters/SearchInput";
import DocumentForm from "./DocumentForm";

const DocumentList = () => {
  const {dataProvider, setDataProvider} = useContext(Context)

  const [data, setData] = useState(null)
  const [createEvent, setCreateEvent] = useState(false)
  const [editEvent, setEditEvent] = useState({open: false, id: null})
  const [filters, setFilters] = useState({
    condo_id: getCondoId(),
    page_size: 50
  })

  useEffect(function getBills(){
    if(filters && !dataProvider?.openDocument) {
    api.get('/document/', {params: filters})
      .then(response => {
        setData(response.data)
      })
      .catch(error => {
        console.log(error)
      })
    }
  }, [filters, dataProvider?.openDocument ])

  const initialState = (setState) => {
    setState(null)
  }

  const handlePage = (e, value) => {
    setFilters({ ...filters, page: value })
  }

  return (
    <>
      <Helmet>
        <title>Documentos - Bloco B3</title>
      </Helmet>
      <Box
      >
        <Card>
        {data ?
        <>
          <TitleBtnList
            title={'Documentos'}
            buttonText={'Arquivar Documento'}
            onClick={()=> setDataProvider(prevState => ({ ...prevState, openDocument: true}))}
            icon={<AddCircleOutlined />}
            boxStyles={{ p: '1rem'}}
          />
          <SearchInput
            styles={{paddingX: '1rem'}}
          />
              <TableContainer sx={{p: 2}}>
                { data?.results?.length > 0 ?
                  <Table sx={{ minWidth: 800 }}>
                    <TableDocumentHead />
                    <TableDocumentBody
                      state={data}
                      setDataProvider={setDataProvider}
                    />
                  </Table>
                  :
                  <NoResultsAnimation />
                }
              </TableContainer>
            </>
            :
              <Skeleton
                variant="rounded"
                height={700}
                animation="pulse"
              />

          }
        </Card>
        {
          data?.count > 0 &&
          <Box spacing={2} display={'flex'} justifyContent={'center'} sx={{ m: 3 }}>
            <Pagination
              count={Math.ceil(+data?.count / +filters.page_size)}
              variant={'outlined'}
              color={'primary'}
              onChange={handlePage}
            />
          </Box>
        }
      </Box>
      <DocumentForm />

    </>
  )
}

export default DocumentList