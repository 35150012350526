import {Skeleton, Typography} from "@mui/material";
import {Notifications} from "@mui/icons-material";
import React from "react";

const MaintenanceReadNotification = ({isLoading, dataUpdate}) => {
  return (
    <>
      {!isLoading  ?
        <Typography
          sx={{marginBottom: '0.5rem', alignItems: 'center', display: 'flex'}}
        >
          <Notifications sx={{mr: '0.5rem'}}/>
          <b>Notificar quantos dias antes?</b>&nbsp;
          {dataUpdate?.notify_days_before}
        </Typography>
        :
        <Skeleton
          variant="rounded"
          animation="pulse"
          height={24}
          sx={{marginBottom: '0.5rem'}}
        />
      }
    </>
  )
}

export default MaintenanceReadNotification