import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  Grid,
  List,
  ListItem,
  Typography
} from "@mui/material";
import {Check, Remove} from "@mui/icons-material";
import AnimationGlobal from "../../../components/Shared/animations/AnimationGlobal";
import successAnimation from "../../../assets/animations/success.json";
import React, {useContext} from "react";
import Context from "../../../contexts/Context";

const ContractConclusionCard = () => {

  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////

  const { dataProvider } = useContext(Context)
  const { firstStep, secondStep, thirdStep, fourthStep, fifthStep } = dataProvider || {}


  ///////////////////////////
  //// General functions ////
  ///////////////////////////

  function avatarType(param) {
    const color = param  ? 'success' : 'warning';
    const icon = param ? <Check sx={{color: 'white', fontSize: '2rem'}}/> :
      <Remove sx={{color: 'white', fontSize: '2rem'}}/>;

    return (
      <Avatar sx={{ backgroundColor: `${color}.main`, mr: '0.5rem' }}>
        {icon}
      </Avatar>
    )
  }

  const contractText = firstStep ? 'Contrato criado' :
    'Não foi criado contrato'

  const taxText = secondStep ? 'Foi registrada ao menos uma cobrança' :
    'Não foi registrada nenhuma cobrança'

  const slipText = thirdStep ? 'Opções de boleto configurada' :
    'Não foi configurada as opções de boleto'

  const warrantyText = fourthStep ? 'Opções de garantia configurada' :
    'Não foi configurada as opçÕes de garantia'

  const recipientText = fifthStep ? 'Foi registrado ao menos um beneficiário' :
    'Não foi registrado beneficiário'


    return (
    <Grid item xs={12}>
    <Card>
      <Fade
        in={true}
        mountOnEnter
        unmountOnExit
        timeout={300}
      >
        <Box>
          <CardHeader
            avatar={<Avatar sx={{ bgcolor: 'primary.main' }}><Check/></Avatar>}
            title={'Conclusão'}/>
          <Divider sx={{ mb: '.3rem' }}/>
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: '1rem', flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <AnimationGlobal
              size={'200px'}
              animation={successAnimation}
              fontStyles={{mb: '1rem'}}
              fontSize={'20px'}
            />
            <List
              sx={{
                paddingBottom: '5rem'
              }}
            >
              <ListItem sx={{display: 'flex'}}>
                <Avatar sx={{backgroundColor: 'success.main', mr: '0.5rem'}}>
                  <Check sx={{color: 'white', fontSize: '2rem'}} />
                </Avatar>
                <Typography variant="body1" sx={{fontSize: '1.5rem'}}>
                  {contractText}
                </Typography>
              </ListItem>
              <ListItem sx={{display: 'flex'}}>
                {avatarType(secondStep)}
                <Typography variant="body1" sx={{fontSize: '1.5rem'}}>
                  {taxText}
                </Typography>
              </ListItem>
              <ListItem sx={{display: 'flex'}}>
                {avatarType(thirdStep)}
                <Typography variant="body1" sx={{fontSize: '1.5rem'}}>
                  {slipText}
                </Typography>
              </ListItem>
              <ListItem sx={{display: 'flex'}}>
                {avatarType(fourthStep)}
                <Typography variant="body1" sx={{fontSize: '1.5rem'}}>
                  {warrantyText}
                </Typography>
              </ListItem>
              <ListItem sx={{display: 'flex'}}>
                {avatarType(fifthStep)}
                <Typography variant="body1" sx={{fontSize: '1.5rem'}}>
                  {recipientText}
                </Typography>
              </ListItem>
            </List>
          </CardContent>
        </Box>
      </Fade>
    </Card>
    </Grid>
  )
}

export default ContractConclusionCard