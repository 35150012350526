import React, { useState } from 'react'
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material'
import { ArrowBack, LockClockOutlined, Visibility, VisibilityOff, } from '@mui/icons-material'
import { api } from '../../../services/Main/Api'
import { useSnackbar } from 'notistack'
import { Helmet } from 'react-helmet'

const ChangePassword = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)

  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const [errorHelperPassword, setErrorHelperPassword] = useState({ error: false, message: '' })
  const [errorHelperNewPassword, setErrorHelperNewPassword] = useState({ error: false, message: '' })
  const [errorHelperConfirmNewPassword, setErrorHelperConfirmNewPassword] = useState({ error: false, message: '' })

  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true)

    const data = {
      password: password,
      new_password: newPassword,
      confirm_new_password: confirmNewPassword,
    }

    api.put('user/change-password/', data).then(response => {
      setIsLoading(false)

      setErrorHelperPassword({ error: false, message: '' })
      setErrorHelperNewPassword({ error: false, message: '' })
      setErrorHelperConfirmNewPassword({ error: false, message: '' })

      setPassword('')
      setNewPassword('')
      setConfirmNewPassword('')
      enqueueSnackbar('Senha alterada com sucesso!', { variant: 'success' })
    }).catch(error => {
      setIsLoading(false)

      const { data } = error.response

      if (data.password) {
        setErrorHelperPassword({ error: true, message: data.password[0] })
      }
      if (data.new_password) {
        setErrorHelperNewPassword({ error: true, message: data.new_password[0] })
      }
      if (data.confirm_new_password) {
        setErrorHelperConfirmNewPassword({ error: true, message: data.confirm_new_password[0] })
      }
    })
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'password') return setPassword(value)
    if (name === 'newPassword') return setNewPassword(value)
    if (name === 'confirmPassword') return setConfirmNewPassword(value)
  }

  const handleClickShowPassword = () => {
    setShowPassword(current => !current)
  }

  const handleClickShowNewPassword = () => {
    setShowNewPassword(current => !current)
  }

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(current => !current)
  }

  return (
    <>
      <Helmet>
        <title>Trocar Senha - Bloco B3</title>
      </Helmet>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Box>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
            <CircularProgress color="inherit"/>
          </Backdrop>
          <Card>
            <CardHeader title={'Alterar Senha'}/>
            <Divider light sx={{ mb: 1 }}/>
            <CardContent>
              <Box sx={{ width: 400, maxWidth: '100%' }}>
                <TextField
                  fullWidth
                  label="Senha Atual"
                  name="password"
                  margin="normal"
                  required
                  type={showPassword ? 'text' : 'password'}
                  error={errorHelperPassword.error}
                  helperText={errorHelperPassword.message}
                  onChange={handleChange}
                  value={password}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                      </InputAdornment>,
                  }}
                />
              </Box>
              <Box sx={{ width: 400, maxWidth: '100%' }}>
                <TextField
                  fullWidth
                  label="Nova Senha"
                  name="newPassword"
                  margin="normal"
                  required
                  type={showNewPassword ? 'text' : 'password'}
                  error={errorHelperNewPassword.error}
                  helperText={errorHelperNewPassword.message}
                  onChange={handleChange}
                  value={newPassword}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                      </InputAdornment>,
                  }}
                />
              </Box>
              <Box sx={{ width: 400, maxWidth: '100%' }}>
                <TextField
                  fullWidth
                  label="Confirmar Senha"
                  name="confirmPassword"
                  margin="normal"
                  required
                  type={showConfirmPassword ? 'text' : 'password'}
                  error={errorHelperConfirmNewPassword.error}
                  helperText={errorHelperConfirmNewPassword.message}
                  onChange={handleChange}
                  value={confirmNewPassword}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                      </InputAdornment>,
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box component={'div'}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 3 }}>
          <Button type={'button'} variant={'outlined'} onClick={(e) => {
            window.history.back()
          }} startIcon={<ArrowBack/>}>{'Voltar'}</Button>
          <Button variant={'contained'} type={'submit'}>{'Salvar'}</Button>
        </Box>
      </Box>
    </>
  )
}

export default ChangePassword
