import {
  Typography
} from '@mui/material'
import {getCondoName} from "../../services/Main/AuthStorage";
import {useContext, useEffect, useState} from "react";
import Context from "../../contexts/Context";



const SelectedCondo = () => {
  const {condoNamelogin} = useContext(Context)

  const condoName = getCondoName() ?? condoNamelogin

  useEffect(function whenCondoNameChange() {
    getCondoName()
  }, [condoNamelogin]);

  return (
      <Typography
        value={condoName}
        variant={'h5'}
        sx={{
          color: 'rgb(31, 48, 64)',
          mr: '1rem',
          fontSize: '1rem'
        }}
      >
        {condoName}
      </Typography>
  )

}

export default SelectedCondo
