import React, {
  useContext,
  useEffect
} from "react";
import {
  Step,
  StepLabel,
  Stepper
} from "@mui/material";
import SimpleButtons from "../../../components/Shared/SimpleButtons";
import Context from "../../../contexts/Context";
import {useNavigate} from "react-router-dom";

const ExpenseWizard = ({ handleSubmit, children }) => {
  const { filters, setFilters, dataProvider } = useContext(Context)
  const { activeStep, firstStep, secondStep, operationType, payConfirmation } = filters || {}

  const navigate = useNavigate()

  function handleFirstStep() {
    if (!firstStep && activeStep === 0) {
      handleSubmit();
    } else if (activeStep === 0 && firstStep) {
      setFilters(prevState => ({ ...prevState, activeStep: activeStep + 1 }));
    }
  }

  function handleSecondStep() {
    if (activeStep === 1 && operationType === 'transfer' && !secondStep) {
      setFilters(prevState => ({ ...prevState, transferReq: true }));
    } else if (activeStep === 1 && operationType === 'payment' && !secondStep) {
      setFilters(prevState => ({ ...prevState, paymentReq: true }));
    } else if (activeStep === 1 && secondStep) {
      setFilters(prevState => ({ ...prevState, activeStep: activeStep + 1 }));
    }
  }

  function handleThirdStep() {
    if (activeStep === 2) {
      navigate('/despesas')
    }
  }


  function handleFilters() {
    handleFirstStep();
    handleSecondStep();
    handleThirdStep();
  }

  function onSkip() {
    activeStep === 1 && setFilters(prevState => ({
      ...prevState,
      activeStep: filters?.activeStep + 1,
      payment: false,
      transfer: false
    }));
  }

  function onBack() {
    activeStep === 0
      ? window.history.back()
      : setFilters(prevState => ({...prevState, activeStep: prevState.activeStep - 1}));
  }

  function disableOnTransfer(){
    return !dataProvider?.validation;
  }

  function disableOnPayment(){
    return activeStep === 1 && !payConfirmation;
  }

  function disableSystem() {
    if (operationType === 'transfer') {
      return disableOnTransfer();
    }

    if (operationType === 'payment') {
      return disableOnPayment();
    }

    if (activeStep === 0 || activeStep === 2) {
      return false;
    }

    if (secondStep === true) {
      return false;
    }

    return true;
  }

  function buttonText() {
    return operationType === 'transfer' && activeStep === 1
    ? 'Transferir'
    : operationType === 'payment' && activeStep === 1
    ? 'Pagar'
    : secondStep && activeStep === 1
    ? 'Avançar'
    : firstStep && activeStep === 2
    ? 'Finalizar'
    : 'Avançar'
  }

  function noBackButton () {
    return firstStep && activeStep === 0
  }

  useEffect(function firstStepDone(){
    firstStep && setFilters(prevState => ({...prevState, activeStep: 1}))
  }, [firstStep])

  useEffect(function secondStepDone(){
    secondStep && setFilters(prevState => ({...prevState, activeStep: 2}))
  }, [secondStep])


  return (
    <>
      <Stepper
        activeStep={activeStep}
        sx={{
          mb: '1rem'
        }}
      >
        <Step>
          <StepLabel>Criar despesa</StepLabel>
        </Step>
        <Step>
          <StepLabel>Realizar pagamento</StepLabel>
        </Step>
        <Step>
          <StepLabel>Conclusão</StepLabel>
        </Step>
      </Stepper>
         {children}

        <SimpleButtons
          forwardDisable={disableSystem()}
          forwardButton={buttonText()}
          noBack={noBackButton()}
          type={'button'}
          deleteButton={filters?.activeStep === 1}
          deleteText={'Pular'}
          onDelete={onSkip}
          onForward={handleFilters}
          onBack={onBack}
        />
    </>
  )
}

export default ExpenseWizard