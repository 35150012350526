import {Box, IconButton, InputAdornment, TextField} from "@mui/material";
import {Search, SearchOff} from "@mui/icons-material";
import React from "react";

const SearchField = ({label, state, setState, keyName, boxStyles}) => {
  return(
    <Box
      sx={boxStyles}
    >
      <TextField
        fullWidth
        size={'small'}
        label={label}
        onChange={({target: {value}}) => setState((prevState) => ({...prevState, [keyName]: value}))}
        value={state?.[keyName] ?? ''}
        InputProps={{
          endAdornment:
            <InputAdornment
              position="start"
            >
              <IconButton
                edge="end"
                onClick={() => {
                  setState((prevState) => ({...prevState, [keyName]: ''}))
                }}
              >
                {!state?.[keyName] ? <Search/> : <SearchOff color="primary"/>}
              </IconButton>
            </InputAdornment>
        }}
      />
    </Box>
  )
}

export default SearchField