import SimpleText from "../../../../../../components/Shared/SimpleText";
import ComplaintUpdateButtons from "./dependencies/ComplaintUpdateButtons";
import {Stack} from "@mui/material";
import React from "react";
import ComplaintToggleButton from "../Shared/ComplaintToggleButton";

const ComplaintEditableContent = ({ dataUpdate, setDataUpdate, setEdit, update, error  }) => {
  return (
    <Stack>
      <SimpleText
        label={'Título'}
        state={dataUpdate}
        setState={setDataUpdate}
        keyName={'title'}
        error={error}
        styles={{ mb: '1rem'}}
      />
      <SimpleText
        label={'Descrição'}
        state={dataUpdate}
        setState={setDataUpdate}
        keyName={'message'}
        error={error}
        multiline
        rows={5}
        styles={{ mb: '1rem'}}
      />
      <ComplaintToggleButton
        state={dataUpdate}
        setState={setDataUpdate}
      />
      <ComplaintUpdateButtons
        setEdit={setEdit}
        updateFunction={update}
      />
    </Stack>

  )
}

export default ComplaintEditableContent