import {Avatar, Stack, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import Iconify from "../../../../../components/Shared/Iconify";
import ActionsMenu from "../../../../../components/Shared/ActionsMenu";
import React, { useContext } from 'react'
import Context from '../../../../../contexts/Context'

const TableCategoryBody = ({ filteredCategories, handleDelete }) => {
  const { user } = useContext(Context)
  const { is_superuser } = user || {}
  return (
    <TableBody>
      {filteredCategories.map((item, i) => {
        const { id, description, is_deletable } = item
        return (
          <TableRow key={i} hover>
            <TableCell>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Avatar sx={{ height: 32, width: 32 }} alt={description}>
                  <Iconify icon={'bx:category'}/>
                </Avatar>
                <Typography variant="subtitle2" noWrap>
                  {description}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell align={'right'}>
              { is_deletable && <ActionsMenu
                canBeDeleted={!is_deletable}
                delete={() => handleDelete(id)}
              /> }
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}

export default TableCategoryBody