import React, {
  useContext,
  useState
} from "react";
import {
  Backdrop,
  CircularProgress,
} from '@mui/material'
import { LocalLibrary } from "@mui/icons-material";
import {api} from "../../../services/Main/Api";
import Context from "../../../contexts/Context";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {useSnackbar} from "notistack";
import SimpleText from "../../../components/Shared/SimpleText";
import SimpleDialog from "../../../components/Shared/SimpleDialog";

const BookingAreaCreate = ({ createEvent, setCreateEvent}) => {
  const {  loadingModal, setLoadingModal } = useContext(Context)
  const { enqueueSnackbar } = useSnackbar()

  const [reqData, setReqData] = useState({})
  const [error, setError] = useState(null)


  async function handleSubmit (){
    setLoadingModal(true);

    const data = {
      ...reqData,
      condo: getCondoId(),
      capacity: +reqData?.capacity
    }

    try {
      const response = await api.post('/booking/areas/', data);
      enqueueSnackbar("Área comum criada com Sucesso!", { variant: "success" });
      setCreateEvent(false);
    } catch (error) {
      enqueueSnackbar("Erro ao Criar Área Comum!", { variant: "fail" });
      console.log(error?.response?.data);
      setError(error?.response?.data);
    } finally {
      setLoadingModal(false);
    }
  };


  return (
    <>
      <SimpleDialog
        open={createEvent ?? false}
        title={'Criar Área Comum'}
        titleIcon={<LocalLibrary />}
        onClickConfirm={() => handleSubmit()}
        onClickCancel={() => {
          setCreateEvent(false)
          setReqData(null)
        }}
        dialogContent={
          <>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loadingModal}
            >
              <CircularProgress color="inherit"/>
            </Backdrop>

            <SimpleText
              label={'Nome'}
              state={reqData}
              setState={setReqData}
              keyName={'name'}
              required={true}
              error={error}
              styles={{
                mb: '1rem'
              }}
            />

            <SimpleText
              label={'Descrição'}
              state={reqData}
              setState={setReqData}
              keyName={'description'}
              error={error}
              styles={{
                mb: '1rem'
              }}
            />

            <SimpleText
              label={'Capacidade'}
              state={reqData}
              setState={setReqData}
              keyName={'capacity'}
              error={error}
              onlyNumbers={true}
              styles={{
                mb: '1rem'
              }}
            />

            <SimpleText
              label={'Informações Adicionais'}
              multiline
              rows={5}
              state={reqData}
              setState={setReqData}
              keyName={'information'}
              error={error}
              styles={{
                mb: '1rem'
              }}
            />
          </>
        }
      />
    </>
  )
}


export default BookingAreaCreate;
