import React, {
  useContext
} from "react";
import {
  Box,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from "@mui/material";
import CategoryIcon from "../../../../../components/Category/CategoryIcon";
import moment from "moment/moment";
import ActionsMenu from "../../../../../components/Shared/ActionsMenu";
import { useNavigate } from "react-router-dom";
import Context from "../../../../../contexts/Context";

const TableShape = ({
    stableSort,
    response,
    getComparator,
    order,
    orderBy,
    currency,
    handleDelete
  }) => {

  const navigate = useNavigate()
  const { setFilters } = useContext(Context)

  const theme = useTheme()

  return (
    <TableBody>
      {stableSort(response?.results || [], getComparator(order, orderBy)).map((row, index) => {
        const labelId = `enhanced-table-checkbox-${index}`
        return (
          <TableRow
            hover
            tabIndex={-1}
            key={row.id}
            sx={{'cursor': 'pointer'}}
            onClick={(e) => {
              row?.is_closed ? navigate(`/despesas/${row.id}`) : navigate(`/despesas/${row.id}/editar`)
              setFilters(prevState => ({...prevState, expenseCategory: row.category.key}))
              e.stopPropagation()
            }}
          >

            <TableCell
              component="th"
              id={labelId}
              scope="row"
              sx={{display: 'flex', alignItems: 'center'}}
            >
              <CategoryIcon variant={row.category.key} isClosed={row.is_closed}/>
              <Box>
                <Typography fontWeight={'bold'}>{row.category.value}</Typography>
                <Typography fontSize={'small'}
                            color={'GrayText'}> {row.division_pattern.value}</Typography>
              </Box>
            </TableCell>
            <TableCell
              align="left"
            >
              {row?.unit_name}
            </TableCell>
            <TableCell
              align="left"
            >
              {moment(row?.created_at).locale('pt-br').format('L LT')}
            </TableCell>
            <TableCell
              align="left"
              style={{textTransform: 'capitalize'}}
            >
              {moment(new Date(row.billing_year, row.billing_month - 1, 1)).locale('pt-br').format('MMMM/YYYY')}
            </TableCell>
            <TableCell
              align="right"
            >
              <Typography
                color={row.total < 0 ? theme.palette.success.dark : theme.palette.grey[900]}>
                {currency(row.total)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <ActionsMenu
                details={`/despesas/${row.id}`}
                canBeDetailed={(row.is_closed === false)}
                edit={`/despesas/${row.id}/editar`}
                canBeEdited={row.is_closed}
                delete={(e) => {
                  handleDelete(e, row.id)
                }}
                canBeDeleted={row.is_closed}
              />
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>

  )
}

export default TableShape