import BookingCreate from './BookingCreate'
import BookingUpdate from './BookingUpdate';
import {Helmet} from "react-helmet";
import CalendarBooking from "../../../components/Calendars/CalendarBooking";


const BookingCalendar = () => {
  return(
      <>
        <Helmet>
          <title>Reserva de Área Comum - Bloco B3</title>
        </Helmet>
        <BookingCreate />
        <BookingUpdate />
        <CalendarBooking />
      </>

  )
}

export default BookingCalendar;