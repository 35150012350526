import {InputAdornment, TextField} from "@mui/material";
import {Category} from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

const MaintenanceType = ({setState, state, disabled}) => {

  return (
    <TextField
      disabled={disabled}
      select
      fullWidth
      size={"small"}
      label={'Tipo de Manutenção'}
      value={state?.category ?? ''}
      sx={{marginBottom: (state?.category !== "PERIODIC") && '2rem'}}
      onChange={(e)=> setState((prevState) => ({...prevState, category: e.target.value}))}
      InputProps={{
        startAdornment:
          <InputAdornment position="start">
            <Category />
          </InputAdornment>

      }}
    >
      <MenuItem value={"PERIODIC"}>{"Recorrente"}</MenuItem>
      <MenuItem value={"ONLY"}>{"Uma vez, sem recorrência"}</MenuItem>
      <MenuItem value={"NOW"}>{"Urgente"}</MenuItem>
    </TextField>
  )
}

export default MaintenanceType