import React from "react";
import {
  Box,
  Skeleton,
  Typography
} from "@mui/material";
import { LocationOn } from "@mui/icons-material";

const BookingReadBookingArea = ({
    isLoading,
    dataUpdate,
    bookingAreas
  }) => {

  return (
    <>
      {!isLoading ?
        <Box
          sx={{display: 'flex'}}
        >
          <LocationOn sx={{ mr: '0.5rem'}}/>
          <Typography
            sx={{marginBottom: '0.5rem'}}
          >
            <b>Área reservada:</b> {bookingAreas?.find(option => option.id === dataUpdate?.areas_booking)?.name}
          </Typography>
        </Box>
        :
        <Skeleton
          variant="rounded"
          animation="pulse"
          height={24}
          sx={{marginBottom: '0.5rem'}}
        />
      }
    </>
  )
}

export default BookingReadBookingArea