import { Box, Card, CardContent, Skeleton, Stack, Typography, } from '@mui/material'

import React, { useEffect, useState, useContext } from 'react'
import Context from '../../contexts/Context'
import Divider from '@mui/material/Divider'
import ChartRadialBarB3 from '../Charts/ChartRadialBarB3'
import { AccessTimeFilled, CheckCircle, FileCopy, Notifications } from '@mui/icons-material'
import ReceiptIcon from '@mui/icons-material/Receipt'
import {getCondoId, getFilterDate} from '../../services/Main/AuthStorage'
import { api } from '../../services/Main/Api'
import moment from "moment";


const  CardInvoiceInfo = (props) => {
  const [summary, setSummary] = useState(0)
  const { condoIdLogin } = useContext(Context)

  useEffect(() => {
    const condo = getCondoId()
    const storageDate = getFilterDate()
    if (props) {
    const data = {
      condo: condoIdLogin ?? condo,
      start_month: +moment(props?.date).format('MM'),
      start_year: +moment(props?.date).format('yyyy'),
      end_month: +moment(props?.date).format('MM'),
      end_year: +moment(props?.date).format('yyyy'),
    }

    api.post(`analytics/invoice-summary/`, data)

      .then(response => {
      const data = response.data
      setSummary(data.totals)
    }).catch(error => {
      console.log('Summary error', error)
    })
    }
  }, [props?.date, props?.condo, condoIdLogin])

  const currency = (value) => new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)
  return (
    <>
      {!summary
        ? <Box>
          <Skeleton variant={'rounded'} width={'100%'} height={91.25} sx={{ mb: 2 }}/>
        </Box>
        : <Card sx={{ mb: 2 }}>
          <CardContent style={{ paddingBottom: '1rem' }}>
            <Stack
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    '@media (max-width:1199px)': {
                      display: 'none',
                    }
                  }}
                />}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                justifyContent: 'space-evenly',
                '@media (max-width:1199px)': {
                  flexWrap: 'wrap',
                },
              }}
            >
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '180px',
                alignItems: 'center',
                placeSelf: 'center'
              }}>
                <ChartRadialBarB3 value={100} color={'secondary'} icon={<FileCopy color={'secondary'}/>}/>
                <Box
                  sx={{
                    ml: 2,
                    '@media (max-width:1199px)': {
                      mt: 'auto',
                      mb: '1rem',
                      ml: 0,
                      mr: 'auto',
                    },
                  }}
                >
                  <h5 style={{ margin: 0, padding: 0 }}>{'TOTAL'}</h5>
                  <Typography variant="body2" sx={{ color: 'secondary.main' }}>
                    <strong>{summary?.total?.qty}</strong> faturas
                  </Typography>
                  <Typography sx={{ color: 'secondary.main', fontWeight: 'bold' }} variant="body2">
                    {currency(summary?.total?.value)}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '180px',
                alignItems: 'center',
                placeSelf: 'center',

              }}>
                <ChartRadialBarB3
                  value={(parseInt(summary?.open?.qty) * 100) / parseInt(summary?.total?.qty)}
                  color={'info'}
                  icon={<ReceiptIcon color={'info'}/>}

                  />
                <Box
                  sx={{
                    ml: 2,
                    '@media (max-width:1199px)': {
                      mt: 'auto',
                      mb: '1rem',
                      ml: 0,
                      mr: 'auto',
                    },
                  }}
                >
                  <h5 style={{ margin: 0, padding: 0 }}>{'EM ABERTO'}</h5>
                  <Typography variant="body2" sx={{ color: 'gray' }}>
                    <strong>{summary?.open?.qty}</strong> faturas
                  </Typography>
                  <Typography sx={{ color: 'info.main', fontWeight: 'bold' }} variant="body2">
                    {currency(summary?.open?.value)}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '180px',
                alignItems: 'center',
                placeSelf: 'center'
              }}>
                <ChartRadialBarB3
                  value={(parseInt(summary?.paid?.qty) * 100) / parseInt(summary?.total?.qty)}
                  color={'success'}
                  icon={<CheckCircle color={'success'}/>}
                />
                <Box
                  sx={{
                    ml: 2,
                    '@media (max-width:1199px)': {
                      mt: 'auto',
                      mb: '1rem',
                      ml: 0,
                      mr: 'auto',
                    },
                  }}
                >
                  <h5 style={{ margin: 0, padding: 0 }}>{'PAGOS'}</h5>
                  <Typography variant="body2" sx={{ color: 'gray' }}>
                    <strong>{summary?.paid?.qty}</strong> faturas
                  </Typography>
                  <Typography color="info" variant="body2" sx={{ color: 'success.main', fontWeight: 'bold' }}>
                    {currency(summary?.paid?.value)}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '180px',
                alignItems: 'center',
                placeSelf: 'center',
                position: 'relative'
              }}>
                <ChartRadialBarB3
                  value={(parseInt(summary?.pending?.qty) * 100) / parseInt(summary?.total?.qty)}
                  color={'warning'}
                  icon={<AccessTimeFilled color={'warning'}/>}
                />
                <Box
                  sx={{
                    ml: 2,
                    '@media (max-width:1199px)': {
                      mt: 'auto',
                      mb: '1rem',
                      ml: 0,
                      mr: 'auto',
                    },
                  }}
                >
                  <h5 style={{ margin: 0, padding: 0 }}>{'PENDENTE'}</h5>
                  <Typography variant="body2" sx={{ color: 'gray' }}>
                    <strong>{summary?.pending?.qty}</strong> Faturas
                  </Typography>
                  <Typography color="info" variant="body2" sx={{ color: 'warning.main', fontWeight: 'bold' }}>
                    {currency(summary?.pending?.value)}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  width: '180px',
                  alignItems: 'center',
                  placeSelf: 'start end',
                }}
              >
                <ChartRadialBarB3
                  value={(parseInt(summary?.overdue?.qty) * 100) / parseInt(summary?.total?.qty)}
                  color={'error'}
                  icon={<Notifications color={'error'}/>}
                />
                <Box
                  sx={{
                    ml: 2,
                    '@media (max-width:1199px)': {
                      mt: 'auto',
                      mb: 'auto',
                      ml: 0,
                      mr: 'auto',
                    },
                  }}
                >
                  <h5 style={{ margin: 0, padding: 0 }}>{'VENCIDAS'}</h5>
                  <Typography variant="body2" sx={{ color: 'gray' }}>
                    <strong>{summary?.overdue?.qty}</strong> faturas
                  </Typography>
                  <Typography color="info" variant="body2" sx={{ color: 'error.main', fontWeight: 'bold' }}>
                    {currency(summary?.overdue?.value)}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </CardContent>
        </Card>}
    </>
  )
}

export default CardInvoiceInfo
