import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import Masks from "../../../../components/Shared/Masks";
import {Box} from "@mui/material";

const GuarantorRead =
  ({
     edit,
     guarantee,
     guaranteeData,
}) => {
  return (
    <>
      {guarantee === 'guarantor' && !edit &&
        <Box
          sx={{
            // paddingX: '1rem'
          }}
        >
          {guaranteeData?.name &&
            <SimpleTypography
              label={'Nome'}
              directValue={guaranteeData?.name}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

          {guaranteeData?.cpf_cnpj &&
            <SimpleTypography
              label={'CPF / CNPJ'}
              directValue={Masks?.cpfOrCnpj(String(guaranteeData?.cpf_cnpj)) ?? ''}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

          {guaranteeData?.phone &&
            <SimpleTypography
              label={'Telefone'}
              directValue={Masks?.phone(String(guaranteeData?.phone)) ?? ''}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

          {guaranteeData?.street &&
            <SimpleTypography
              label={'Rua'}
              directValue={guaranteeData?.street}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

          {guaranteeData?.number &&
            <SimpleTypography
              label={'Número'}
              directValue={guaranteeData?.number}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

          {guaranteeData?.district &&
            <SimpleTypography
              label={'Bairro'}
              directValue={guaranteeData?.district}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

          {guaranteeData?.complement &&
            <SimpleTypography
              label={'Complemento'}
              directValue={guaranteeData?.complement}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

          {guaranteeData?.city &&
            <SimpleTypography
              label={'Cidade'}
              directValue={guaranteeData?.city}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

          {guaranteeData?.state &&
            <SimpleTypography
              label={'Estado'}
              directValue={guaranteeData?.state}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

          {guaranteeData?.cep &&
            <SimpleTypography
              label={'CEP'}
              directValue={Masks?.cep(String(guaranteeData?.cep)) ?? ''}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

        </Box>
      }
    </>
  )
}

export default GuarantorRead