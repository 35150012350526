import React, {useRef, useState} from 'react';
import {
    Avatar,
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    Grid,
    Snackbar,
    TextField
} from '@mui/material'
import {api} from "../../../services/Main/Api";
import {useNavigate} from "react-router-dom";
import MuiAlert from '@mui/material/Alert';
import Divider from "@mui/material/Divider";
import {ArrowBack} from "@mui/icons-material";
import { Helmet } from 'react-helmet';

export default function Register() {
    const navigate = useNavigate();
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [open, setOpen] = useState(false);

    const emailRef = useRef()
    const passwordRef = useRef()
    const confirmPasswordRef = useRef()
    const firstNameRef = useRef()
    const lastNameRef = useRef()

    const attr = {
        error: false,
        helperText: ''
    }

    const [email, setEmail] = useState(attr)
    const [isLoading, setIsLoading] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState(attr)
    const [password, setPassword] = useState(attr)
    const [lastName, setLastName] = useState(attr)
    const [firstName, setFirstName] = useState(attr)

    const handleSubmit = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        const data = {
            email: emailRef.current,
            password: passwordRef.current,
            confirm_password: confirmPasswordRef.current,
            first_name: firstNameRef.current,
            last_name: lastNameRef.current,
        }

        api.post("user/signup/", data)
            .then(response => {
                navigate("/login", {replace: true})
            })
            .catch(error => {
                setIsLoading(false)
                setFirstName(attr)
                setLastName(attr)
                setEmail(attr)
                setPassword(attr)
                setConfirmPassword(attr)

                const {data} = error.response

                if (data?.email) {
                    setEmail({
                        error: true,
                        helperText: data.email[0]
                    })
                }

                if (data?.password) {
                    setPassword({
                        error: true,
                        helperText: data.password[0]
                    })
                }

                if (data?.confirm_password) {
                    setConfirmPassword({
                        error: true,
                        helperText: data.confirm_password[0]
                    })
                }

                if (data?.first_name) {
                    setFirstName({
                        error: true,
                        helperText: data.first_name[0]
                    })
                }

                if (data?.last_name) {
                    setLastName({
                        error: true,
                        helperText: data.last_name[0]
                    })
                }
            })
    }

    return (
        <>
            <Helmet>
                <title>Cadastro - Bloco B3 </title>
            </Helmet>
            <Container component="form" fixed onSubmit={handleSubmit} noValidate sx={{marginY: '3em'}}>
                <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
                    <Alert onClose={() => setOpen(false)} severity="success" sx={{width: '100%'}}>
                        This is a success message!
                    </Alert>
                </Snackbar>
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={isLoading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>

                    <Card>
                        <CardHeader avatar={<Avatar sx={{bgcolor: "primary.main"}}></Avatar>}
                                    title={"Cadastro"}/>
                        <Divider light sx={{marginY: '.3rem'}}/>
                        <CardContent>
                            <Grid container spacing={2} justifyContent={"space-between"}>
                                <Grid item xs={12} lg={6}>

                                    <TextField

                                        fullWidth
                                        label="Nome*"
                                        name="firstName"
                                        error={firstName.error}
                                        helperText={firstName.helperText}
                                        onChange={(e) => {
                                            firstNameRef.current = e.target.value
                                        }}
                                        defaultValue={firstNameRef.current}
                                    /> </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextField

                                        fullWidth
                                        label="Sobrenome*"
                                        name="lastName"
                                        helperText={lastName.helperText}
                                        error={lastName.error}
                                        onChange={(e) => {
                                            lastNameRef.current = e.target.value
                                        }}
                                        defaultValue={lastNameRef.current}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <TextField

                                        fullWidth

                                        label="Email*"
                                        name="email"
                                        helperText={email.helperText}
                                        error={email.error}
                                        onChange={(e) => {
                                            emailRef.current = e.target.value
                                        }}
                                        defaultValue={emailRef.current}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextField
                                        fullWidth

                                        label="Senha*"
                                        name="password"
                                        helperText={password.helperText}
                                        error={password.error}
                                        onChange={(e) => {
                                            passwordRef.current = e.target.value
                                        }}
                                        defaultValue={passwordRef.current}
                                        type="password"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextField
                                        fullWidth

                                        label="Confirmar Senha*"
                                        helperText={confirmPassword.helperText}
                                        error={confirmPassword.error}
                                        name="password2"
                                        onChange={(e) => {
                                            confirmPasswordRef.current = e.target.value
                                        }}
                                        defaultValue={confirmPasswordRef.current}
                                        type="password"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>


                    </Card>

                    <Box  sx={{display: "flex", justifyContent: "space-between", marginY:"1rem"}}>

                        <Button type={"button"} variant={"outlined"}
                                startIcon={<ArrowBack />}
                                onClick={(e) => {
                            window.history.back()
                        }}>{"Voltar"}</Button>
                        <Button

                            type="submit"
                            variant="contained"
                            size="large"
                        >
                            {"Salvar"}
                        </Button>

                    </Box>



            </Container>
        </>
    )
}
