import {FormControlLabel, FormHelperText, Switch} from "@mui/material";
import React from "react";

const GlobalSwitch = ({state, setState, keyName, styles, label, helperText, disabled, alternativeCheck}) => {
  return (
    <>
      <FormControlLabel
        sx={styles}
        control={
          <Switch
            disabled={disabled}
            checked={!!state?.[keyName]}
            onChange={(e) => {
              setState((prevState) => ({...prevState, [keyName]: !state?.[keyName]}))
            }}
          />}
        label={label}
      />
      {helperText &&
        <FormHelperText>{helperText}</FormHelperText>
      }
    </>
  )
}

export default GlobalSwitch