import {TableBody, TableCell, TableRow} from "@mui/material";
import moment from "moment/moment";
import React from "react";
import {useNavigate} from "react-router-dom";

const TableServiceBody = ({providers, jobs}) => {
  const navigate = useNavigate()
  return (
    <TableBody>
      { providers?.results?.map((provider, index) => (
        <TableRow
          hover
          tabIndex={-1}
          key={index}
          sx={{ 'cursor': 'pointer' }}
          onClick={() => { navigate(`/manutencao/servicos/prestador/${provider.id}`)}}
        >

          <TableCell
            component="th"
            scope="row"
            sx={{ display: 'flex', alignItems: 'center' }}

          >
            {provider.name}
          </TableCell>
          <TableCell
            align="left"
          >
            {jobs?.find((e) => (e?.id === provider?.profession))?.name}

          </TableCell>
          <TableCell
            align="left"
          >
            {provider.email}
          </TableCell>
          <TableCell
            align="left"
          >
            {provider.phone}
          </TableCell>
          <TableCell
            align="left"
          >
            {moment(provider.created_at).format('DD/MM/yyyy')}
          </TableCell>
        </TableRow>
      ))}

    </TableBody>
  )
}

export default TableServiceBody