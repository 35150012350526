import React from "react";
import {
  Box,
  Skeleton,
  Typography
} from "@mui/material";
import {Square} from "@mui/icons-material";

const BookingReadUnit = ({
    isLoading,
    dataUpdate,
    units
  }) => {

  return (
    <>
      {!isLoading ?
        <Box
          sx={{display: 'flex'}}
        >
          <Square sx={{mr: '0.5rem'}} />
          <Typography
            sx={{marginBottom: '0.5rem'}}
          >
            <b>Imóvel:</b> {units?.find((option) => (option.id === dataUpdate.unit))?.name}
          </Typography>
        </Box>
        :
        <Skeleton
          variant="rounded"
          animation="pulse"
          height={24}
          sx={{marginBottom: '0.5rem'}}

        />
      }
    </>
  )
}

export default BookingReadUnit