import {Collapse, TextField} from "@mui/material";
import React from "react";


const MaintenanceMailNotes = ({state, setState, error, disabled }) => {
  return (
    <Collapse in={!!state?.select_email} unmountOnExit>
      <TextField
        label={"Descrição do e-mail"}
        disabled={disabled}
        multiline
        rows={4}
        fullWidth
        type="text"
        size={'small'}
        error={!!error?.name}
        helperText={error?.name}
        sx={{
          marginBottom: '2rem',
        }}
        onChange={({target:{value}}) => setState((prevState) => ({...prevState, email_notes: value}))}
        value={state?.email_notes ?? ''}
      />
    </Collapse>
  )
}

export default MaintenanceMailNotes