import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useContext } from 'react'
import { api } from '../../services/Main/Api'
import {getCondoId} from "../../services/Main/AuthStorage";
import Context from "../../contexts/Context";

const UnitFilter = ({
    setState,
    keyName,
    defaultValue,
    styles, variant,
    valueProp,
    error,
    label,
    justNumber,
    disableClearable
  }) => {

  const { setFilters, unitOptions, unitOptionsNumber, getUnitOptions} = useContext(Context)

  useEffect(() => {
    if (!unitOptions) {
      getUnitOptions()
    }
  }, [])

  const alternateState = setState ?? setFilters

  const optionsType = justNumber ? unitOptionsNumber : unitOptions

  return (
    <>
      <Autocomplete
        fullWidth
        disableClearable={disableClearable}
        onChange={(_event,value) => alternateState((prevState) => ({...prevState, [keyName ?? 'unit']: value?.value }))}
        defaultValue={defaultValue}
        value={valueProp}
        renderInput={(props) => <TextField
          {...props}
          variant={variant}
          label={label ?? 'Imóvel'}
          size={"small"}
          error={!!error?.[keyName]}
          helperText={error?.[keyName]}
        />}
        getOptionLabel={(option) => `${option.label}` ?? ['']}
        options={optionsType ?? ['']}
        sx={styles}
        renderOption={(props, option) => (
          <li {...props} key={option.key}>
            {`${option.label}`}
          </li>
        )}
      />
    </>
  )
}

export default UnitFilter
