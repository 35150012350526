import * as React from 'react'
import { useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { DeleteOutlined, EditOutlined, MoreVert, RemoveRedEye } from '@mui/icons-material'
import PropTypes from 'prop-types'
import ModalDelete from '../Modal/ModalDelete'

const ActionsMenu = (props) => {
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [openConfirmation, setOpenConfirmation] = React.useState(false)

  const handleOpenConfirmation = (e) => {
    e?.stopPropagation()
    setIsOpen(false)
    setOpenConfirmation(true)
  }

  const handleCloseConfirmation = (e) => {
    e?.stopPropagation()
    setOpenConfirmation(false)
  }

  return (
    <>
      <IconButton ref={ref} onClick={(e) => {
        e.stopPropagation()
        setIsOpen(true)
      }}>
        <MoreVert/>
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={(e) => {
          e.stopPropagation()

          setIsOpen(false)
        }}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {props?.details &&
          <MenuItem
            component={RouterLink}
            to={props?.details}
            sx={{ color: 'text.secondary' }}
            disabled={!!props?.canBeDetailed}
          >
            <ListItemIcon>
              <RemoveRedEye/>
            </ListItemIcon>
            <ListItemText primary="Detalhes" primaryTypographyProps={{ variant: 'body2' }}/>
          </MenuItem>}
        {props?.edit &&
          <MenuItem
            component={RouterLink}
            to={props?.edit}
            sx={{ color: 'text.secondary' }}
            disabled={!!props?.canBeEdited}
          >
            <ListItemIcon>
              <EditOutlined/>
            </ListItemIcon>
            <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }}/>
          </MenuItem>}

        {props?.delete &&
          <MenuItem
            sx={{ color: 'error.main' }}
            onClick={handleOpenConfirmation}
            disabled={!!props?.canBeDeleted}
          >
            <ListItemIcon>
              <DeleteOutlined color={'error'}/>
            </ListItemIcon>
            <ListItemText primary="Excluir" primaryTypographyProps={{ variant: 'body2' }}/>
          </MenuItem>}
      </Menu>
      {props?.delete &&
        <ModalDelete
          open={openConfirmation}
          onClose={handleCloseConfirmation}
          onDelete={props?.delete}
        />
      }
    </>
  )
}

ActionsMenu.propTypes = {
  edit: PropTypes.string,
  canBeEdited: PropTypes.bool,
  view: PropTypes.string,
  delete: PropTypes.func,
  canBeDeleted: PropTypes.bool,
}

ActionsMenu.defaultProps = {
  canBeEdited: true,
  canBeDeleted: true,
}

export default ActionsMenu