import {Skeleton, Typography} from "@mui/material";
import {Engineering} from "@mui/icons-material";
import React from "react";

const MaintenanceReadProvider = ({isLoading, services, dataUpdate}) => {
  return (
      <>
        {!isLoading ?
          <Typography
            sx={{
              marginBottom: '0.5rem',
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Engineering
              sx={{
                mr: '0.5rem'
              }}
            />
            <b>Prestador do Serviço: </b> &nbsp; {services?.find(e => e.id === dataUpdate?.service_provider)?.name}
          </Typography>
          :
          <Skeleton
            variant="rounded"
            animation="pulse"
            height={24}
            sx={{
              marginBottom:
                '0.5rem'
            }}
          />
        }
    </>
  )
}

export default MaintenanceReadProvider