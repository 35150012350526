import {Skeleton, Typography} from "@mui/material";
import {Refresh} from "@mui/icons-material";
import React from "react";

const MaintenanceReadStatus = ({isLoading, dataUpdate}) => {
  return(
    <>
      {!isLoading  ?
        <Typography
          sx={{marginBottom: '0.5rem', alignItems: 'center', display: 'flex'}}
        >
          <Refresh sx={{mr: '0.5rem'}}/>
          <b>Situação da Manutenção?</b>&nbsp;
          {dataUpdate?.status === 'OPEN' && 'Em aberto'}
          {dataUpdate?.status === 'FINISHED' && 'Finalizado'}
          {dataUpdate?.status === 'IN_PROGRESS' && 'Em andamento'}
        </Typography>
        :
        <Skeleton
          variant="rounded"
          animation="pulse"
          height={24}
          sx={{marginBottom: '0.5rem'}}
        />
      }
    </>
  )
}

export default MaintenanceReadStatus