import {Avatar, Box, DialogTitle, Divider, Typography} from "@mui/material";
import {Add, PriorityHigh} from "@mui/icons-material";
import React from "react";

const SimpleDialogTitle = ({ title, subtitle, styles, avatarIcon, actionMenu  }) => {
  return (
    <>
      <DialogTitle
        component={'div'}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...styles
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
        <Avatar
          sx={{
            bgcolor: "primary.main",
            mr: '1rem'
          }}
        >
          {avatarIcon ?? <PriorityHigh />}
        </Avatar>

          <Typography
            variant={'h6'}
          >
            {title}
          </Typography>

          { subtitle &&
              <Typography
                variant={'10px'}
              >
                {subtitle}
              </Typography>
          }
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {
            actionMenu
          }
        </Box>


      </DialogTitle>
      <Divider />
    </>
  )
}

export default SimpleDialogTitle