import React, {
  useContext,
  useEffect,
  useState
} from "react";
import {
  Skeleton,
} from '@mui/material'
import {
  Edit,
  Public,
} from "@mui/icons-material";
import moment from 'moment/moment'
import {api} from "../../../services/Main/Api";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {useSnackbar} from "notistack";
import Context from '../../../contexts/Context'
import Title from "../../../components/BookingAndMaintenance/Title";
import DateAndTime from "../../../components/BookingAndMaintenance/DateAndTime";
import DateSkeleton from "../../../components/BookingAndMaintenance/DateSkeleton";
import AutoCompleteGlobal from "../../../components/Shared/AutoCompleteGlobal";
import BookingReadOnly from "./Components/BookingReadOnly";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import Description from "../../../components/BookingAndMaintenance/Description";
import ButtonSet from "../../../components/Shared/ButtonSet";



const BookingUpdate = () => {
  const {editEvent, setEditEvent, clickDate, setRefresh} = useContext(Context)
  const {enqueueSnackbar} = useSnackbar()

  const [dataUpdate, setDataUpdate] = useState(null)
  const [timePickerStart, setTimePickerStart] = useState(null)
  const [timePickerEnd, setTimePickerEnd] = useState(null)
  const [error, setError] = useState(null)
  const [bookingAreas, setBookingAreas] = useState(null)
  const [units, setUnits] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [edit, setEdit] = useState(false)

  const today = moment(new Date()).subtract(1, "days").format()
  const clickedDate = moment(new Date (clickDate)).format()

  const initialState = () => {
    setDataUpdate(null)
    setError(null)
    setBookingAreas(null)
    setUnits(null)
    setIsLoading(true)
    setEdit(false)
  }


  function initialDate (){
    const formStartDate = moment(dataUpdate?.date_start).format('YYYY-MM-DD')
    const setStartDate = formStartDate + ' ' + timePickerStart
    return moment(setStartDate).format()
  }

  function endDate (){
    const formEndDate = moment(dataUpdate?.date_end).format('YYYY-MM-DD')
    const setEndDate = formEndDate + ' ' + timePickerEnd
    return moment(setEndDate).format()
  }

  function dateStart() {
    return dataUpdate?.date_start
  }

  function dateEnd() {
    return dataUpdate?.date_end
  }

  function bookingArea() {
    if (editEvent?.event) {
      api.get(`/booking/areas/?condo_id=${getCondoId()}`)
        .then(response => {
          setBookingAreas(response.data.results)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }


  async function unit (){
    try {
      if (editEvent?.event) {
        const response = await api.get(`/unit/?condo_id=${getCondoId()}`);
        setUnits(response.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  }


  async function handleSubmit (){
    try {
      const reqData = {
        ...dataUpdate,
        condo: getCondoId(),
      };

      await api.put(`/booking/${editEvent.id}`, reqData);

      enqueueSnackbar("Reserva Editada com Sucesso!", { variant: "success" });
      setRefresh((prevState) => !prevState);
      setEditEvent(false);
      setEdit(false);
      initialState();
    } catch (error) {
      enqueueSnackbar(error?.response?.data[0], { variant: "error" });
      console.log(error?.response?.data[0]);
      setError(error?.response?.data);
    }
  }

  async function getBooking (){
    try {
      const response = await api.get(`/booking/${editEvent.id}/`);
      setDataUpdate(response.data);
      setTimePickerStart(moment(response.data?.date_start).locale('pt-br').format("LT"));
      setTimePickerEnd(moment(response.data?.date_end).locale('pt-br').format("LT"));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteBooking (){
    try {
      const response = await api.delete(`booking/${editEvent.id}/`);
      setRefresh((prevState) => !prevState);
      enqueueSnackbar("Reserva Deletada com Sucesso!", { variant: "success" });
      initialState();
      setEditEvent(false);
    } catch (error) {
      console.log(error.response.data[0]);
      enqueueSnackbar(error.response.data[0], { variant: "error" });
    }
  }

  useEffect(function setBooking(){
    if (editEvent.id) {
      getBooking()
    }
    }, [editEvent])

  useEffect(function set(){
    bookingArea()
    unit()
    setIsLoading(true)
  }, [editEvent?.event])


  return (
      <>
          <SimpleDialog
            title={clickedDate > today ? "Editar Reserva" : 'Manutenção Passada'}
            titleIcon={<Edit/>}
            subtitle={clickedDate < today && 'Reserva passada - somente leitura'}
            open={!!editEvent ?? false}
            setOpen={setEditEvent}
            removeConfirm
            onClickCancel={() => {
              setEditEvent(false)
              initialState()
            }}
            actionMenu={
              <>
                {clickedDate > today &&
                  <ButtonSet
                    editState={edit}
                    editClick={() => {
                      setEdit(true)
                    }}
                    deleteClick={() => setOpenDelete(true)}
                    saveClick={() => {
                      handleSubmit()
                    }}
                    cancelClick={() => {
                      getBooking()
                      setEdit(false)
                    }}
                  />
                }
              </>

            }
            dialogContent={
              <>
                {edit  ?
                  <>
                    {!isLoading ?
                      <Title
                        state={dataUpdate}
                        setState={setDataUpdate}
                        keyName={'name'}
                        error={error}
                        styles={{mb: '2rem'}}
                      />
                      :
                      <Skeleton
                        variant="rounded"
                        animation="pulse"
                        height={39.974}
                        sx={{marginBottom: '2rem', marginTop: '2rem'}}
                      />
                    }

                    {!isLoading ?
                      <DateAndTime
                        labelDate={'Data de ínicio'}
                        labelTime={'Horário de ínicio'}
                        setState={setDataUpdate}
                        valueFunction={dateStart ?? initialDate}
                        timePicker={setTimePickerStart}
                        keyName={'date_start'}
                      />
                      :
                      <DateSkeleton />
                    }

                    {!isLoading ?
                      <DateAndTime
                        labelDate={'Data de Termino'}
                        labelTime={'Horário de termino'}
                        setState={setDataUpdate}
                        valueFunction={dateEnd ?? endDate}
                        timePicker={setTimePickerEnd}
                        keyName={'date_end'}
                      />
                      :
                      <DateSkeleton />
                    }

                    {!isLoading ?
                      <AutoCompleteGlobal
                        label={'Áreas Comuns'}
                        state={dataUpdate}
                        setState={setDataUpdate}
                        error={error}
                        keyName={'areas_booking'}
                        options={bookingAreas}
                        icon={<Public sx={{ml: '0.6rem'}}/>}
                        styles={{mb: '2rem'}}
                      />
                      :
                      <Skeleton
                        variant="rounded"
                        animation="pulse"
                        height={39.974}
                        sx={{marginBottom: '2rem'}}
                      />
                    }
                    {!isLoading ?
                      <AutoCompleteGlobal
                        label={'Imóvel'}
                        state={dataUpdate}
                        setState={setDataUpdate}
                        error={error}
                        keyName={'unit'}
                        options={units}
                        icon={<Public sx={{ml: '0.6rem'}}/>}
                        styles={{mb: '2rem'}}
                      />
                      :
                      <Skeleton
                        variant="rounded"
                        animation="pulse"
                        height={39.974}
                        sx={{marginBottom: '2rem'}}
                      />
                    }
                    {!isLoading ?
                      <Description
                        label={'Observações'}
                        state={dataUpdate}
                        setState={setDataUpdate}
                        error={error}
                        keyName={'notes'}
                      />
                      :
                      <Skeleton
                        variant="rounded"
                        animation="pulse"
                        height={175.3}
                        width={535.339}
                        sx={{marginBottom: '0.5rem'}}
                      />
                    }

                  </>
                  :
                  <BookingReadOnly
                    isLoading={isLoading}
                    dataUpdate={dataUpdate}
                    bookingAreas={bookingAreas}
                    units={units}
                  />
                }

                <SimpleDialog
                  open={openDelete}
                  setOpen={setOpenDelete}
                  title={'Apagar Reserva'}
                  question={'Você tem certeza que deseja excluir esse reserva?'}
                  functionProp={deleteBooking}
                />
              </>


            }
          />
        </>

  )
}


export default BookingUpdate;
