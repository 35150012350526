import { Breadcrumbs, Link, Typography } from '@mui/material'
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom'
import { Home, NavigateNextOutlined } from '@mui/icons-material'
import { api } from '../../services/Main/Api'
import { useEffect, useState } from 'react'

const getRoutePath = (location, params) => {
  const pathname = location

  if (!Object.keys(params).length) {
    return pathname
  }

  let path = pathname

  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`)
    }
  })

  return path
}

const UnitUserBreadCrumbs = () => {
  const location = useLocation()
  const params = useParams()
  const pathNames = location.pathname.split('/').filter((x) => x)
  const LinkRouter = (props) => <Link {...props} component={RouterLink}/>
  const [unitNumber, setUnitNumber] = useState('')

  useEffect(function unitNumber () {
    if (location.pathname.includes('/imoveis/') && params.id !== undefined) {
      api.get(`unit/${params.id}`).then(res => {
        setUnitNumber(res.data.number)
      })
    }

  }, [location.pathname, params.id])

  const breadcrumbNameMap = {
    '/usuario/faturas': 'Faturas',
    '/usuario/faturas/:id': 'Detalhes da Fatura',
  }

  return (
    <Breadcrumbs sx={{ display: { xs: 'none', md: 'block' } }} aria-label="breadcrumb"
                 separator={<NavigateNextOutlined fontSize={'small'}/>}>
      <LinkRouter underline="hover" color="inherit" to="/" sx={{ display: 'flex', alignItems: 'center' }}>
        <Home color={'primary'}/>
      </LinkRouter>
      {pathNames.map((value, index) => {
        const last = index === pathNames.length - 1
        const to = `/${pathNames.slice(0, index + 1).join('/')}`
        let route = getRoutePath(to, params)
        if (breadcrumbNameMap[route] !== undefined) {
          return last ? (
            <Typography color="text.primary" key={to}>
              {breadcrumbNameMap[route]}
            </Typography>
          ) : (
            <LinkRouter underline="hover" color="inherit" to={to} key={to}>
              {breadcrumbNameMap[route]}
            </LinkRouter>
          )
        }
      })}
    </Breadcrumbs>
  )
}

export default UnitUserBreadCrumbs
