import {Skeleton, Typography} from "@mui/material";
import {Event} from "@mui/icons-material";
import moment from "moment/moment";
import React from "react";

const MaintenanceReadEndDate = ({isLoading, dataUpdate}) => {
  return (
    <>
    {dataUpdate?.end_date &&
      <>
        {!isLoading ?
          <Typography
            sx={{marginBottom: '0.5rem', alignItems: 'center', display: 'flex'}}
          >
            <Event sx={{mr: '0.5rem'}}/>
            <b>Data de conclusão:</b>&nbsp;{moment(new Date(dataUpdate?.end_date)).format('LLL')}
          </Typography>
          :
          <Skeleton
            variant="rounded"
            animation="pulse"
            height={24}
            sx={{marginBottom: '0.5rem'}}
          />
        }
      </>
      }
    </>
  )
}

export default MaintenanceReadEndDate