import {Navigate, useOutlet} from "react-router-dom";
import {getToken, removeTokens} from "./AuthToken";
import {useEffect} from "react";
import {useSnackbar} from "notistack";
import {removeCondoId, removeCondoName, removeFilterDate, removeLocalUser} from "./AuthStorage";


const RequireAuth = () => {
    const outlet = useOutlet()
    const { enqueueSnackbar } = useSnackbar()

    useEffect(function withoutCookies(){
        if (!getToken()){
            // enqueueSnackbar('Por favor realize login', {
            //     anchorOrigin: {
            //         vertical: 'bottom',
            //         horizontal: 'center'
            //     }
            //     })
            removeTokens()
            removeLocalUser()
            removeCondoId()
            removeCondoName()
            removeFilterDate()
        }

    },[])


    return (getToken())
        ? outlet
      : <Navigate to={'/login'} replace={true} />
}

export default RequireAuth;
