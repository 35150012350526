import SimpleText from "../../../../components/Shared/SimpleText";
import {Box} from "@mui/material";
import React from "react";
import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import {Close} from "@mui/icons-material";

const RentRecipientRead =
  ({
    reqData,
   }) => {
  return (
    <Box
      sx={{
        paddingX: '2rem',
      }}
    >
      <SimpleTypography
        label={'Nome'}
        directValue={reqData?.name ?? <Close sx={{color: 'error.main'}}/>}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'
        }}
      />

      <SimpleTypography
        label={'CPF / CNPJ'}
        directValue={reqData?.cpf_cnpj ?? <Close sx={{color: 'error.main'}}/>}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'
        }}
      />

      <SimpleTypography
        label={'Telefone'}
        directValue={reqData?.phone ?? <Close sx={{color: 'error.main'}}/>}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'
        }}
      />

      <SimpleTypography
        label={'E-mail'}
        directValue={reqData?.email ?? <Close sx={{color: 'error.main'}}/>}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'
        }}
      />

    </Box>

  )
}

export default RentRecipientRead