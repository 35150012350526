import {Box, Fade, InputAdornment} from "@mui/material";
import SimpleText from "../../../../components/Shared/SimpleText";
import React from "react";

const CapitalizationInputs =
  ({
     edit,
     guarantee,
     guaranteeData,
     setGuaranteeData
   }) => {
    return (
      <Box
        sx={{
          // paddingX:'1rem'
        }}
      >
        {guarantee === 'titulo_de_captalização' && edit &&
          <Fade
            in={true}
            mountOnEnter
            unmountOnExit
            timeout={300}
          >
            <Box>
              <SimpleText
                label={'Número de Apólice/Contrato'}
                onlyNumbers
                state={guaranteeData}
                setState={setGuaranteeData}
                keyName={'insurance_policy_number'}
                styles={{ mb: '1rem' }}

              />

              <SimpleText
                label={'Seguradora'}
                state={guaranteeData}
                setState={setGuaranteeData}
                keyName={'insurer'}
                styles={{ mb: '1rem' }}
              />

              <SimpleText
                label={'Quantas vezes o valor da locação'}
                onlyNumbers
                state={guaranteeData}
                setState={setGuaranteeData}
                keyName={'how_many_times_the_rent'}
                styles={{ mb: '1rem' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      Vezes
                    </InputAdornment>
                  )
                }}
              />

            </Box>
          </Fade>
        }

      </Box>
    )
  }

export default CapitalizationInputs