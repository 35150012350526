import {Box, Pagination} from "@mui/material";
import React, {useContext} from "react";
import Context from "../../contexts/Context";

const PaginationGlobal = ({ state, filters, setFilters }) => {

  const handlePage = (e, value) => {
    setFilters({ ...filters, page: value })
  }

  return (
    <>
      {state?.count > 0 &&
        <Box
          spacing={2}
          display={'flex'}
          justifyContent={'center'}
          sx={{ m: 3 }}
        >
          <Pagination
            count={Math.ceil(state?.count / filters?.page_size)}
            variant={'outlined'}
            color={'primary'}
            onChange={handlePage}
          />
        </Box>}
    </>
  )
}

export default PaginationGlobal