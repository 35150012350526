import React from "react";
import BookingReadName from "./ReadOnlyComponents/BookingReadName";
import BookingReadStartDate from "./ReadOnlyComponents/BookingReadStartDate";
import BookingReadBookingArea from "./ReadOnlyComponents/BookingReadBookingArea";
import BookingReadUnit from "./ReadOnlyComponents/BookingReadUnit";
import BookingReadDescription from "./ReadOnlyComponents/BookingReadDescription";
import BookingReadEndDate from "./ReadOnlyComponents/BookingReadEndDate";

const BookingReadOnly = ({isLoading, dataUpdate, bookingAreas, units}) => {
  return (
    <>
      <BookingReadName
        isLoading={isLoading}
        dataUpdate={dataUpdate}
        />

      <BookingReadStartDate
        isLoading={isLoading}
        dataUpdate={dataUpdate}
        />

      <BookingReadEndDate
        isLoading={isLoading}
        dataUpdate={dataUpdate}
        />

      <BookingReadBookingArea
        isLoading={isLoading}
        dataUpdate={dataUpdate}
        bookingAreas={bookingAreas}
        />

      <BookingReadUnit
        isLoading={isLoading}
        dataUpdate={dataUpdate}
        units={units}
        />

      <BookingReadDescription
        isLoading={isLoading}
        dataUpdate={dataUpdate}
        />
    </>
  )
}

export default BookingReadOnly