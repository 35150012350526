import axios from 'axios';

import { removeTokens } from '../Main/AuthToken'
import {
  getUserRefreshToken,
  getUserToken,
  removeTokensUnitUser,
  setUserRefreshToken,
  setUserToken
} from "./UnitUserToken";


export const apiUser = axios.create({
  baseURL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://api.blocob3.com.br/',
})

let isRefreshing = false;
let failedRequestsQueue = []

apiUser.interceptors.request.use(async config => {
  const userToken = getUserToken();


  if (userToken) {
    config.headers.Authorization = `Bearer ${userToken}`;
  }

  return config;
});

apiUser.interceptors.response.use(response => {
  return response;
}, error => {

  if (error.response.status === 401 && error.config.url !== "/user/unit-login/") {
    if (error.response.data?.code === "token_not_valid") {
      const refresh = getUserRefreshToken()
      const orginalConfig = error.config
      if (!isRefreshing) {
        isRefreshing = true;
        apiUser.post("user/token-refresh/", {
          refresh
        }).then(response => {
          const {access} = response.data
          setUserToken(access)
          setUserRefreshToken(refresh)
          apiUser.defaults.headers["Authorization"] = `Bearer ${access}`
          failedRequestsQueue.forEach(request => request.onSuccess(access))
          failedRequestsQueue = []
        }).catch(err => {
          failedRequestsQueue.forEach(request => request.onFailure(err))
          failedRequestsQueue = []
          removeTokensUnitUser()
          removeTokens()
        }).finally(() => {
          isRefreshing = false;
        })
      }

      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({
          onSuccess: (token) => {
            orginalConfig.headers["Authorization"] = `Bearer ${token}`
            resolve(apiUser(orginalConfig))
          },
          onFailure: (err) => {
            console.log("onFailure")
            window.location.reload(true)
            reject(err)
          }
        })
      })
    }
  }

  return Promise.reject(error);
})
