import {useState, useEffect} from "react";
import {
  Avatar, 
  Box, 
  Button, 
  Container, 
  CssBaseline, 
  Grid, 
  Link, 
  TextField, 
  Typography, 
  Alert, 
  CircularProgress, 
  Backdrop, 
  Skeleton,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {LockClockOutlined, DoneOutline, PriorityHighOutlined, Visibility,VisibilityOff } from "@mui/icons-material";
import {api} from "../../../services/Main/Api";
import {useSearchParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const NewPassword = () => {
  const [paramSearch] =  useSearchParams()
  const navigate = useNavigate();


  

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm ] = useState(false);
  const [alert, setAlert] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  
   
  
  const [errorHelperPassword, setErrorHelperPassword] = useState({error: false, message: ''})
  const [errorHelperConfirmPassword, setErrorHelperConfirmPassword] = useState({error: false, message: ''})

  const sucessMsg = "Nova senha definida, você será redirecionado para a tela de login"

  useEffect(() => {
    setIsLoading(true)
    api.get(`user/reset-password/token-check/${paramSearch.get('token')}/?email=${paramSearch.get('email')}`)
      .then((response) => {
        setIsLoading(false)
        setInvalidToken(false)
        setForm(true)
        console.log(response);

      }).catch((error) => {
        setInvalidToken(true)
        setIsLoading(false)
        const {data} = error.response
        console.log(data);
      })

  }, [paramSearch])


  const handleSubmit = (event) => {
    event.preventDefault()
    setIsLoading(true);
    

    const data = {
      email: paramSearch.get('email'),
      token: paramSearch.get('token'),
      password: password,
      confirm_password: confirmPassword,
    }
    
    api.put('user/reset-password/set/', data)
      .then(response => {

        setErrorHelperPassword({error: false, message: ''})
        setErrorHelperConfirmPassword({error: false, message: ''})
        setIsLoading(false);
        setForm(false)
        setAlert(true)
        setTimeout(() => {navigate('/login')}, 10000)
        

      }).catch(error => {
        const {data} = error.response
        setIsLoading(false);
        console.log(data);

        if(password.trim().length === 0){
          setErrorHelperPassword({ error: true, message: 'Esse campo não pode ser vazio.'})          
        }
        if(confirmPassword.trim().length === 0){
          setErrorHelperConfirmPassword({ error: true, message: 'Esse campo não pode ser vazio.'})
        }
        if(confirmPassword.trim() !== password.trim()){
          setErrorHelperConfirmPassword({ error: true, message: 'Esses campos tem que ser iguais'})
          setErrorHelperPassword({ error: true, message: 'Esses campos tem que ser iguais'})
        }

      })
  }

  const handleClickShowPassword = () => {
    setShowPassword(current => !current);
  };


  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(current => !current);
  };
  


  return (
            <>
              <Helmet>
                <title>Nova Senha - Bloco B3</title>
              </Helmet>
              <CssBaseline/>
                <Backdrop
                  sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                  open={isLoading}>
                  <CircularProgress color="inherit"/>
                </Backdrop>
                {isLoading ?
                <Container component="main" maxWidth="xs"> 
                  <Skeleton 
                  variant="rectangular" 
                  width={450} 
                  height={500}
                  sx={{ mt: 8}}
                  /> 
                </Container>                
                :
                form ?
                (                
              <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main', height: 54, width: 54}}>
                        <LockClockOutlined fontSize={"large"}/>
                    </Avatar>
                    <Typography component="h1" variant="h5">{"Definir nova senha"}</Typography>
                    <Box component="form"
                         onSubmit={handleSubmit}
                         noValidate sx={{mt: 1}}>
                        
                        <TextField
                            label="Nova senha"
                            name="password"
                            margin="normal"
                            required
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            error={errorHelperPassword.error}
                            helperText={errorHelperPassword.message}
                            onChange={({target : {value}}) => setPassword(value)}
                            value={password}
                            autoComplete="email"
                            autoFocus
                            InputProps={{
                              endAdornment:
                                  <InputAdornment position="end">
                                      <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          edge="end"
                                      >
                                          {showPassword ? <Visibility/> : <VisibilityOff/>}
                                      </IconButton>
                                  </InputAdornment>,
                          }}
                        />
                        <TextField
                            label="Confirmar senha"
                            name="ConfirmPassword"
                            margin="normal"
                            required
                            fullWidth
                            type={showConfirmPassword ? 'text' : 'password'}
                            error={errorHelperConfirmPassword.error}
                            helperText={errorHelperConfirmPassword.message}
                            onChange={({target : {value}}) => setConfirmPassword(value)}
                            value={confirmPassword}
                            autoComplete="email"
                            autoFocus
                            InputProps={{
                              endAdornment:
                                  <InputAdornment position="end">
                                      <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowConfirmPassword}
                                          edge="end"
                                      >
                                          {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                                      </IconButton>
                                  </InputAdornment>,
                          }}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            {"Confirmar"}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="src/pages/MainPages/User/Login" variant="body2">{"Realizar Login"}</Link>
                            </Grid>
                            {/*<Grid item>*/}
                            {/*    <Link href="/User/Register" variant="body2">{"Criar Conta"}</Link>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Box>
                </Box>
              </Container>
                ) 
                : 
                invalidToken ?
                (

                  <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                      <Avatar sx={{m: 1, bgcolor: 'secondary.main', height: 54, width: 54}}>
                          <PriorityHighOutlined fontSize={"large"}/>
                      </Avatar>
                      <Typography 
                        component="h4" 
                        variant="h5"
                        sx={{
                          mt: 2,
                          mb: 4
                        }}
                        >
                          {"Link para recuperar senha expirou"}
                        </Typography>
                      <Box 
                           component="form"
                          onSubmit={handleSubmit}
                          noValidate

                          >
                            <Link href="/login" variant="body2">{"Página de login"}</Link>
                              
                      </Box>
                  </Box> 
                )
                : 
                alert ?
                (
                  <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                      <Avatar sx={{m: 1, bgcolor: 'secondary.main', height: 54, width: 54}}>
                          <DoneOutline fontSize={"large"}/>
                      </Avatar>
                      <Typography 
                        component="h4" 
                        variant="h4"
                        sx={{
                          mt:2,
                          mb: 2,
                        }}
                        >
                          {"Sucesso!"}
                        </Typography>  
                        <Typography                        
                          sx={{                          
                           mb: 2,
                          }}
                        >
                          {"Redirecionando"}
                        </Typography>
                        <CircularProgress 
                          sx={{
                            color: 'primary.main',
                            mb: 2,
                          }}                        
                        />
                        <Alert                          
                          sx={{                            
                            mt: 2,
                            mb: 4,
                            backgroundColor: 'transparent '                           
                          }}
                          variant="outlined"
                          >
                            {sucessMsg}                 
                          </Alert>
                          
                                  <Link href="/login" variant="body2">{"Página de login"}</Link>                              
                      </Box>                  
                                   
                )
                : null }
            </>

    
    

  )
}

export default NewPassword;