import {
  Avatar,
  Box, Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade, Grid, IconButton, InputAdornment,
  Skeleton,
  TextField,
  Typography, useMediaQuery
} from "@mui/material";
import {Article, AspectRatio, Edit, Person} from "@mui/icons-material";
import UnitFilter from "../../../components/Filters/UnitFilter";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import SimpleText from "../../../components/Shared/SimpleText";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import SimpleTypography from "../../../components/Shared/SimpleTypography";
import Masks from "../../../components/Shared/Masks";
import React, {useContext, useEffect, useState} from "react";
import SimpleButtons from "../../../components/Shared/SimpleButtons";
import Context from "../../../contexts/Context";
import {useParams} from "react-router-dom";
import SimpleSelector from "../../../components/Shared/SimpleSelector";
import UnitFormModelModal from "../Unit/UnitFormModelModal";
import {api} from "../../../services/Main/Api";
import {useSnackbar} from "notistack";
import RentRecipientInputs from "./RecipientForm/RentRecipientInputs";
import RentRecipientRead from "./RecipientForm/RentRecipientRead";
import SaveCancelButton from "../../../components/Shared/SaveCancelButton";

const RecipientForm = (props) => {
  const params =  useParams()
  const { isLoading, setUnitInfo, reqData, setReqData, error, prevStateData, getRecipient, recipientReq} = props
  const [edit, setEdit] = useState(false)
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);


  useEffect(function observeInputs() {
    const requiredKeys = ['name', 'cpf_cnpj', 'phone', 'email'];
    const hasChanged = requiredKeys?.some(key => reqData?.[key]?.toString() !== prevStateData?.[key]?.toString())
    if(hasChanged) {
      setIsSaveEnabled(true)
    } else {
      setIsSaveEnabled(false)
    }

  }, [reqData]);


  return (
    <Grid item xs={12}>
      {!isLoading ?
        <Card
          sx={{
            width: '100%',
          }}
        >
          <CardHeader
            avatar={<Avatar sx={{ bgcolor: 'primary.main' }}><Person/></Avatar>}
            title={'Beneficiário'}/>




          <Divider sx={{ mb: '.3rem' }}/>
          <CardContent>

            {params?.id &&
              //// => Title and buttons in update
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  paddingX: '2rem',
                  mb: '0.5rem'
                }}
              >
                <Typography
                  sx={{
                    // ...(edit && {mb: '1rem'}),
                    fontWeight: 'bold',
                    fontSize: '18px',
                    color: 'primary.main'
                  }}>
                  Informações
                </Typography>

                {!edit && reqData?.id &&
                  <IconButton
                    onClick={() => setEdit(true)}
                  >
                    <Edit/>
                  </IconButton>
                }

                <SaveCancelButton
                  edit={edit}
                  disabled={!isSaveEnabled}
                  align={'start'}
                  saveClick={() => {
                    recipientReq()
                    setEdit(false)
                  }}
                  cancelClick={() => {
                    getRecipient()
                    setEdit(false)
                  }}

                />

              </Box>
            }

            {edit || !params?.id ?
              <RentRecipientInputs
                reqData={reqData}
                setReqData={setReqData}
                error={error}
              />
              : reqData?.id &&
              <RentRecipientRead
                reqData={reqData}
              />
            }



          </CardContent>
        </Card>
        :
        <Skeleton
          height={400}
          width={'100%'}
          variant='rounded'
        />
      }
      <UnitFormModelModal
        setUnitInfo={setUnitInfo}
      />

    </Grid>
  )
}

export default RecipientForm