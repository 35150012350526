import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import {
  Card,
  CardContent,
  Container,
  Skeleton,
  useMediaQuery
} from '@mui/material'
import moment from 'moment'
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import ptBr from '@fullcalendar/core/locales/pt-br'
import dayGridPlugin from '@fullcalendar/daygrid'
import Context from '../../contexts/Context'
import { api } from '../../services/Main/Api'
import { getCondoId } from '../../services/Main/AuthStorage'
import TitleBtnList from "../Shared/TitleBtnList";

const CalendarBooking = () => {
  const {setCreateEvent, setClickDate, setEditEvent, refresh} = useContext(Context)

  const [events,  setEvents] = useState(null)
  const [filters, setFilter] = useState({areas_booking__condo_id: getCondoId()})

  const sizeMatch = useMediaQuery('@media (min-width:700px)')



  async function getCalendar() {
    try {
      const response = await api.get('/booking/', { params: filters });
      setEvents(
        response.data.results.map((e) => ({
          title: e.name,
          start: e.date_start,
          end: e.date_end,
          id: e.id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(function calendarState(){
    getCalendar()
  },[refresh])


  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      >
        <TitleBtnList
          title={'Reservar Área Comum'}
          buttonText={'Áreas Comuns'}
          buttonHref={'/reserva/areas'}
        />
      <Card sx={{maxWidth: '1200px'}}>
        {events ?
          <>
              <CardContent
                className={"my-calendar"}
              >
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
                initialView="dayGridMonth"
                selectable={true}
                locale={ptBr}
                weekends={true}
                aspectRatio={1.3}
                editable={true}
                headerToolbar={{
                  left: sizeMatch && 'prev,today,next',
                  center: 'title',
                  right: sizeMatch ? 'dayGridMonth,timeGridWeek,timeGridDay,dayGridYear' : 'prev,today,next'
                }}
                footerToolbar={{
                  right: !sizeMatch && 'dayGridMonth,timeGridWeek,timeGridDay,dayGridYear'
                }}
                eventClick={
                  function (arg) {
                    setClickDate(arg.event.startStr)
                    setEditEvent({event: true, id: arg.event.id})
                  }
                }
                dateClick={(arg) => {
                  setCreateEvent(true)
                  setClickDate(moment(arg.dateStr).format())
                }
                }
                events={events}
              />
            </CardContent>
          </>

            :
            <Skeleton
              variant="rounded"
              height={700}
              animation="pulse"
            />
          }
      </Card>

    </Container>
    )
  }

export default CalendarBooking;
