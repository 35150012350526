import {TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";

const TableAreaHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ color: 'primary.main' }}>
          Nome
        </TableCell>
        <TableCell sx={{ color: 'primary.main' }}>
          Descrição
        </TableCell>
        <TableCell sx={{ color: 'primary.main' }}>
          Capacidade
        </TableCell>
        <TableCell sx={{ color: 'primary.main' }}>
          Criado
        </TableCell>
      </TableRow>

    </TableHead>
  )
}

export default TableAreaHead