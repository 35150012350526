
import React from "react";
import {useParams} from "react-router-dom";
import BankTransferList from "../BankTransferList/BankTransferList";

const TransferDetailTed = () => {
    let { id } = useParams();
    let transferType = 'ted';

    return (
        <>

           <BankTransferList
               setTransferOpen={true}
               transferType={transferType}
               id={id}

           />
        </>

    )
}

export default TransferDetailTed