import {CardContent, TextField, Typography} from "@mui/material";
import React from "react";
import SimpleText from "../../../../../../../components/Shared/SimpleText";

const CommentMessage = ({ comment, finalId, edit, dataUpdate, setDataUpdate  }) => {
  return(
    <CardContent>
      {comment.id !== finalId ?
        <Typography>
          {comment?.message}
        </Typography>
        : edit &&
        <SimpleText
          label={'Comentário'}
          directValue={dataUpdate?.message ?? comment?.message}
          keyName={'message'}
          multiline={true}
          rows={5}
          state={dataUpdate}
          setState={setDataUpdate}
        />
      }
    </CardContent>
  )
}

export default CommentMessage