import CommentTitle from "./dependencies/CommentTitle";
import CommentSubHeader from "./dependencies/CommentSubHeader";
import {Avatar, CardHeader} from "@mui/material";
import {Chat} from "@mui/icons-material";
import CommentActionHeader from "./dependencies/CommentActionHeader";
import React from "react";

const CommentHeader = ({ comment, edit, setOpenMenu, setCommentId, handleClick }) => {
  return (
    <CardHeader
      title={ <CommentTitle />}
      subheader={
        <CommentSubHeader
          comment={comment}
        />
      }
      avatar={
        <Avatar
          sx={{bgcolor: "primary.main"}}
        >
          <Chat />
        </Avatar>
      }
      action={
        <CommentActionHeader
          edit={edit}
          comment={comment}
          setOpenMenu={setOpenMenu}
          setCommentId={setCommentId}
          handleClick={handleClick}
        />
      }
    />
  )
}

export default CommentHeader