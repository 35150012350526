import React, { useContext, useEffect, useState } from 'react'
import {
    Backdrop,
    Box,
    Card,
    Chip,
    Container,
    Grid, IconButton, InputAdornment, Link, Menu, MenuItem,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import { api } from '../../../services/Main/Api'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import CircularProgress from '@mui/material/CircularProgress'

import NoResultsAnimation from '../../../components/Shared/animations/NoResultsAnimation'
import {Alarm, Close, MoreVert, OpenInNew, Search} from '@mui/icons-material'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Masks from '../../../components/Shared/Masks'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import InvoiceIcon from '../../../components/Invoice/InvoiceIcon'
import StatusInvoice from '../../../components/Invoice/StatusInvoice'

const CompanyRateList = () => {
  const navigate = useNavigate()
  const [response, setResponse] = useState(null)
  const [monthInput, setMonthInput] = useState(moment().format("MM"))
  const [yearInput, setYearInput] = useState(moment().format("YYYY"))
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentItem, setCurrentItem] = React.useState(null);
  const [filters, setFilters] = useState(
      {
        page: 1,
        page_size: 50,
      }
  )
  const monthFormat = moment().format("MM")
  const yearFormat = moment().format("YYYY")

  useEffect(()=>{
    let params = { ...searchParams, "month": monthInput, "year": yearInput,}
    if (search){
      params.search = search
    }else{
      delete params.search
    }
    setSearchParams(params)
  }, [monthInput, yearInput, search])

  function getData(){
    setLoading(true)
    let month = monthFormat
    let year = yearFormat

    if (searchParams.get("month") && searchParams.get("year") ){
      month = searchParams.get("month")
      year = searchParams.get("year")
    }

    let filter = {...filters, }

    if (searchParams.get("search")){
      filter = {...filter, search: searchParams.get("search")}
    }

    api.get(`customer-invoice/rate/`, {params: filter})
        .then(response => {
          const data = response.data
          setResponse(data)
          setLoading(false)
        }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }


  useEffect(() => {

      getData()

  }, [])

  useEffect(() => {
    getData()
  }, [searchParams])
  useEffect(function whenPageLoad (){
    getData()
  },[filters])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
        setAnchorEl(null);
        setCurrentItem(null);
    };

    const handleRefundRate = (item) => {
        console.log('item', item)

            api.post('/transaction-rate/refund/transfer/', item)
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.error("Erro:", error);
            });
    };




    let typingTimer  = ""

  return (
      <>
        <Helmet>
          <title>Faturas dos Clientes - Bloco B3</title>
        </Helmet>

        <Backdrop
            sx={{ color: '#fff', zIndex: 1500, }}
            open={loading}
        >

          <CircularProgress color="inherit"/>
        </Backdrop>
        <Container maxWidth={false}>
          <Box
              sx={{
                display: 'flex',
                '@media (max-width:600px)': {
                  display: 'grid',
                  gridTemplateColumns: 'repeat(1, 1fr)',
                  gap: 3,
                },
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 3,

              }}
          >
            <Typography
                textAlign={'center'}
                variant={'h5'}
            >
              Faturas dos Clientes ({response?.count || 0})
            </Typography>
            <Box  sx={{textAlign: "right"}}>
              <TextField id="outlined-basic" label="Buscar por nome" variant="outlined" sx={{marginRight: ".5rem"}}
                         onKeyUp={(event)=>{
                           clearTimeout(typingTimer);
                           typingTimer = setTimeout(()=> setSearch(event.target.value), 1000);
                         }}
                         size={'small'}
                         InputProps={{
                           endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,
                         }}
              />

            </Box>
          </Box>


          <Card>
            {response?.results ?
                <>

                  <Grid container sx={{ p: 2, alignItems: 'center' }} spacing={1}>
                    <Grid item xs={12} sm={4} lg={3}>
                      {/*<UnitFilter />*/}
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                      {/*<ReferenceMonthFilter*/}
                      {/*  response={response}*/}
                      {/*/>*/}
                    </Grid>
                  </Grid>

                  <TableContainer>
                    {response?.results?.length > 0  ?
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Código</TableCell>
                              <TableCell>Nome</TableCell>
                              <TableCell>Cidade</TableCell>
                              <TableCell>Estado</TableCell>
                              <TableCell>Referencia</TableCell>
                              <TableCell align={'center'}>Numero/Documento</TableCell>
                                <TableCell align={'center'} >Status</TableCell>
                                <TableCell align={'right'}>Total</TableCell>
                                <TableCell align={'right'}></TableCell>


                            </TableRow>
                          </TableHead>
                          <TableBody>


                            { response.results.map(item =>
                                <TableRow
                                    key={item.id}
                                    hover
                                    sx={{'cursor': 'pointer'}}

                                    onClick={(e) => {
                                      e.stopPropagation()
                                      navigate(`/admin/company-rate/${item.id}`)
                                    }}
                                >
                                  <TableCell>
                                    <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                    >
                                      <InvoiceIcon
                                          variant={item?.status.key}
                                          key={item.id}
                                      />
                                      CLT-{item.id}
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                    >
                                      {item.name}
                                    </Box>
                                  </TableCell>


                                  <TableCell>
                                    <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                    >
                                      {item.city}
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                    >
                                      {item.state}
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    {moment(item.reference_date).format('DD/MM/YYYY')}
                                  </TableCell>
                                    <TableCell align={'center'}>{item?.document_number ? item?.document_number : '-'}</TableCell>

                                  <TableCell >
                                    <StatusInvoice
                                        invoice={item}
                                        paidAt={item?.paid_at}
                                    />
                                  </TableCell>
                                  <TableCell align={'right'} sx={{fontWeight: 'bold'}}>{Masks.money(item.total.toString())}</TableCell>
                                  <TableCell
                                      align={'center'}
                                  >
                                      {item?.status?.key === "paid" &&
                                      <IconButton
                                          item={item}
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              handleClick(e);
                                              setCurrentItem(item)
                                          }}
                                      >
                                          <MoreVert/>
                                      </IconButton>}
                                  </TableCell>
                                </TableRow>
                            )}

                          </TableBody>
                        </Table>
                        :
                        <NoResultsAnimation />
                    }
                  </TableContainer>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={() => {
                            handleRefundRate(currentItem);
                            handleClose();
                        }}>Reembolsar</MenuItem>
                    </Menu>

                </>
                :
                <Skeleton
                    variant="rounded"
                    height={700}
                    animation="pulse"
                />
            }
          </Card>

        </Container>
        {response?.count > 0 &&
            <PaginationGlobal
                state={response}
                filters={filters}
                setFilters={setFilters}
            />
        }
      </>
  )
}

export default CompanyRateList
