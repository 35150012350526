import {
    Alert,
    Avatar, Backdrop,
    Box, Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider, Grid,
    IconButton, Skeleton, Tab, Tabs, TextField, Tooltip, Typography, useMediaQuery,
} from "@mui/material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import React, {useContext, useEffect, useState} from "react";
import {api} from "../../../services/Main/Api";
import {useNavigate, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {getCondoId} from '../../../services/Main/AuthStorage'
import GlobalSwitch from "../../../components/Shared/GlobalSwitch";
import {Check, Close, Edit, OpenInNew} from '@mui/icons-material'
import Image from '../../../assets/images/whatsapp_background.png'
import PdfImage from '../../../assets/images/pdf_image.png'


const WhatsappConfig = () => {
    const [reqData, setReqData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const companyId = getCondoId()
    const {enqueueSnackbar} = useSnackbar()
    const [tabValue, setTabValue] = useState(0)
    const [prevStateData, setPrevStateData] = useState(null);
    const [edit, setEdit] = useState(false)
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [displayValue, setDisplayValue] = useState(reqData?.whatsapp_contact_phone ?? '');
    const [editMode, setEditMode] = useState(false);
    const [editValue, setEditValue] = useState('');
    const [isChanged, setIsChanged] = useState(false);

    function fetchData() {
        api.get(`condo/${companyId}/`)
            .then(response => {
                setReqData({...response.data,})
            })
            .catch(error => {
                console.log(error)
            })
    }

    function WhatsappConfig() {
        setIsLoading(true)
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const data = {
            ...reqData,
            whatsapp_send_message: reqData.whatsapp_send_message,
            whatsapp_name: reqData.whatsapp_name,
            whatsapp_contact_phone: reqData.whatsapp_contact_phone,
        }

        api.put(`/condo/${companyId}/`, data, headers)
            .then(response => {
                enqueueSnackbar('Whatsapp atualizado com sucesso', {variant: 'success'})
            })
            .catch(error => {
                enqueueSnackbar('Ocorreu um erro ao atualizar o whatsapp', {variant: 'error'})
                console.error(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(function observeInputs() {
        const requiredKeys = ['whatsapp_send_message', 'whatsapp_name', 'whatsapp_contact_phone',];
        const hasChanged = requiredKeys.some(key => reqData?.[key]?.toString() !== prevStateData?.[key]?.toString())
        if (hasChanged) {
            setIsSaveEnabled(true)
        } else {
            setIsSaveEnabled(false)
        }

    }, [reqData]);

    useEffect(function pageLoad() {
        fetchData()
    }, [])

    function formatPhone(value) {
        return value
            .replace(/\D+/g, "")
            .replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");

    }


    return (
        <>
            <Backdrop
                // sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                sx={{color: '#fff', zIndex: 1500,}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Box>
                <Card>
                    {!isLoading ?
                        <>
                            <CardHeader
                                avatar={
                                    <Avatar
                                        sx={{
                                            bgcolor: 'primary.main'
                                        }}>
                                        <WhatsAppIcon/>
                                    </Avatar>}
                                title={'Configurações do Whatsapp'}
                            />
                            <Divider sx={{my: '.5rem'}}/>
                            <Card>
                                <Grid container direction="row">
                                    <Grid item xs>
                                        <CardContent sx={{mt: '3rem'}}>

                                            <Box sx={{mt: '1rem',  display: 'flex', justifyContent: 'space-between'}}>
                                                    <h4>
                                                        Ativar whatsapp
                                                    </h4>
                                                <GlobalSwitch
                                                    // label={'Ativar whatsapp'}
                                                    state={reqData}
                                                    setState={setReqData}
                                                    keyName={'whatsapp_send_message'}
                                                    // styles={{mb: '0.3rem', mt: '1rem'}}
                                                />
                                            </Box>
                                            {reqData?.whatsapp_send_message &&
                                            <Box sx={{mt: '1rem'}}>
                                                <TextField
                                                    label={'Nome da empresa'}
                                                    size={'small'}
                                                    fullWidth
                                                    required
                                                    value={reqData?.whatsapp_name}
                                                    onChange={event => {
                                                        setIsChanged(true);
                                                        setEditValue(event.target.value);
                                                        setReqData({ ...reqData, whatsapp_name: event.target.value });
                                                    }}
                                                />
                                            </Box>
                                            }
                                            {reqData?.whatsapp_send_message &&
                                            <Box sx={{mt: '1rem', mb: '2rem'}}>
                                                <TextField
                                                    label='Telefone para contato'
                                                    size='small'
                                                    fullWidth
                                                    required
                                                    value={reqData?.whatsapp_contact_phone ? formatPhone(reqData?.whatsapp_contact_phone) : ''}
                                                    onChange={(event) => {
                                                        setIsChanged(true);
                                                        const onlyNumbers = event.target.value.replace(/\D+/g, "");
                                                        const newValue = formatPhone(event.target.value);
                                                        setReqData({ ...reqData, whatsapp_contact_phone : onlyNumbers});
                                                        setDisplayValue(newValue);
                                                    }}
                                                />
                                            </Box>
                                            }
                                            { isChanged &&
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    flexDirection: 'row',
                                                    gap: '1rem'
                                                }}
                                            >
                                                <Button
                                                    size={'small'}
                                                    disabled={!(reqData?.whatsapp_name) || !(reqData?.whatsapp_contact_phone)}
                                                    onClick={() => {
                                                        WhatsappConfig()
                                                        setEdit(false)
                                                        setIsChanged(false)
                                                    }}
                                                    sx={{
                                                        backgroundColor: 'red',
                                                        color: 'white',
                                                        ':hover': {
                                                            backgroundColor: 'darkred',
                                                        }
                                                    }}
                                                >
                                                Salvar
                                            </Button>
                                                <Button
                                                    size={'small'}
                                                    onClick={() => {
                                                        fetchData(null, true)
                                                        setEdit(false)
                                                        setIsChanged(false)
                                                    }}
                                                >
                                                Cancelar
                                            </Button>
                                            </Box>
                                            }
                                        </CardContent>
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                        container
                                        direction="column"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        Exemplos
                                        <Tabs
                                            value={tabValue}
                                            onChange={(_event, newValue) => setTabValue(newValue)}
                                            sx={{
                                                mt: '-.5rem',
                                                color: 'black',
                                                '& .MuiTab-root': {
                                                    '&:hover': {color: 'black'},
                                                    '&.Mui-selected': {color: 'black'},
                                                },
                                                '& .MuiTabs-indicator': {backgroundColor: 'black'},
                                            }}
                                        >
                                            <Tab label="Fatura"/>
                                            <Tab label="Repasse"/>
                                        </Tabs>
                                        {tabValue === 0 ?
                                            <Card sx={{
                                                border: '1px solid lightgray',
                                                borderRadius: '15px',
                                                width: '60%',
                                                minWidth: '300px',
                                                margin: 'auto',
                                                mb: '2rem',
                                                mt: '.2rem',
                                                pt: '7rem',
                                                backgroundImage: `url(${Image})`,
                                                backgroundSize: '100% 100%',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                            }}>
                                                <Card
                                                    sx={{

                                                        width: '80%',
                                                        ml: '.7rem',
                                                        p: '.5rem',
                                                        pt: 0,
                                                        mt: 0,
                                                        mb: '3rem',

                                                    }}>
                                                    <p style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '0.9rem',
                                                        lineHeight: '1.2rem'
                                                    }}>{reqData?.whatsapp_name || '"Nome da Empresa"'}</p>
                                                    <p style={{fontSize: '0.9rem', lineHeight: '1.2rem'}}>Olá "Nome do
                                                        Cliente"</p>
                                                    <p style={{fontSize: '0.9rem', lineHeight: '1.2rem'}}>Sua fatura
                                                        chegou com o valor de R$ 999.99, com vencimento para
                                                        10/10/2024.</p>

                                                    <p style={{fontSize: '0.9rem', lineHeight: '1.2rem'}}>
                                                        Dúvidas entre em contato pelo&nbsp;
                                                        {reqData?.whatsapp_contact_phone ? formatPhone(reqData?.whatsapp_contact_phone) : '"Telefone para Contato"'}
                                                    </p>
                                                    <Divider/>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        sx={{color: '#3399FF'}}
                                                    >
                                                        <OpenInNew sx={{fontSize: "20px", mt: '.8rem', mr: '.3rem'}}/>
                                                        <p style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '.9rem',
                                                            lineHeight: '1.2rem'
                                                        }}>Visualizar Fatura</p>
                                                    </Grid>
                                                </Card>
                                            </Card>
                                            :
                                            <Card sx={{
                                                border: '1px solid lightgray',
                                                borderRadius: '15px',
                                                width: '60%',
                                                minWidth: '300px',
                                                margin: 'auto',
                                                mb: '2rem',
                                                mt: '.2rem',
                                                pt: '4rem',
                                                backgroundImage: `url(${Image})`,
                                                backgroundSize: '100% 100%',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                            }}>
                                                <Card
                                                    sx={{

                                                        width: '75%',
                                                        ml: '.7rem',
                                                        pt: 0,
                                                        mt: 0,
                                                        mb: '3rem',

                                                    }}>
                                                        <Grid container direction="row" alignItems="flex-end" sx={{ backgroundColor:'#F5F5F5'}}>
                                                            <Grid item>
                                                        <img style={{marginTop: '.5rem', mb: '.5rem'}} src={PdfImage} alt="Imagem do pdf"/>
                                                            </Grid>
                                                            <Grid item >
                                                            <p style={{fontSize: '0.7rem', lineHeight: '.2rem'}}>Recibo de repasse.pdf</p>
                                                            <p style={{color: 'gray',fontSize: '0.5rem', lineHeight: '.2rem'}}>67KB . PDF</p>
                                                        </Grid>
                                                        </Grid>
                                                    <Grid sx={{pl: '.5rem'}}>
                                                        <p style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '0.9rem',
                                                            lineHeight: '1.2rem'
                                                        }}>Olá "Nome do
                                                            Cliente"</p>
                                                        <p style={{fontSize: '0.9rem', lineHeight: '1.2rem'}}>Seu
                                                            repasse referente ao imovel
                                                            da Rua Recife, 250 - Santa Maria, foi realizado
                                                            por {reqData?.whatsapp_name || '"Nome da Empresa"'} no
                                                            valor de R$ 999,99", com vencimento para
                                                            10/10/2024.</p>

                                                        <p style={{fontSize: '0.9rem', lineHeight: '1.2rem'}}>
                                                            Dúvidas entre em contato pelo&nbsp;
                                                            {reqData?.whatsapp_contact_phone ? formatPhone(reqData?.whatsapp_contact_phone) : '"Telefone para Contato"'}
                                                        </p>

                                                        <p style={{
                                                            color: 'gray',
                                                            fontSize: '0.9rem',
                                                            lineHeight: '1.2rem'
                                                        }}>Este é o seu comprovante</p>

                                                    </Grid>
                                                </Card>
                                            </Card>
                                        }
                                    </Grid>
                                </Grid>
                            </Card>
                        </>
                        :
                        <Skeleton
                            variant="rounded"
                            height={700}
                            animation="pulse"
                        />
                    }

                </Card>

            </Box>
        </>
    )
}

export default WhatsappConfig;