import {Avatar, Box, Button, Card, CardContent, CardHeader, Divider, Grid, Skeleton, Typography} from "@mui/material";
import {AccountBalance, Add, Pix} from "@mui/icons-material";
import React, {useContext} from "react";
import RentRecipientAccountSet from "../RentRecipientAccountSet";
import RentRecipientPixSet from "../RentRecipientPixSet";
import Context from "../../../../contexts/Context";
import moment from "moment/moment";

const RentFormSet = (
    {
      data,
      onChange,
      setDataDeleteAcc,
      isLoading,
      accUpdate,
      accCreate,
      getAcc,
      deleteAccById,

    }
  ) => {

  const { setDataProvider, dataProvider, sessionCheck } = useContext(Context)

  // function addAccSet() {
  //   setDataProvider(prevState => ({
  //     ...prevState,
  //     recipientPix: !dataProvider?.recipientPix
  //   }))
  // }
  //
  //
  // function addPixSet() {
  //   setDataProvider(prevState => ({
  //     ...prevState,
  //       recipientPix: !dataProvider?.recipientPix
  //   }))
  // }

  function handleAddDataAcc () {
    sessionCheck()
    const newDataList = [
      ...data, {
        bank: {name:'', code: ''},
        bank_branch: '',
        bank_account: '',
        bank_account_digit: '',
        account_type: 'TED'
      }
    ];
    onChange(newDataList);
  }

  function handleAddDataPix() {
    sessionCheck()
    const newDataList = [
      ...data, {
        description: '',
        value: '',
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        account_type: 'PIX'
      }
    ]
    onChange(newDataList)
  }

  return (
    <>
      <Grid item xs={12}>
        {!isLoading ?
          <Card>
            <CardHeader
              avatar={<Avatar sx={{bgcolor: 'primary.main'}}><AccountBalance/></Avatar>}
              title={'Contas Bancárias e Pix'}/>
            <Divider sx={{mb: '.3rem'}}/>

            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >

                <RentRecipientAccountSet
                  data={data}
                  onChange={onChange}
                  setDataDeleteAcc={setDataDeleteAcc}
                  isLoading={isLoading}
                  accUpdate={accUpdate}
                  accCreate={accCreate}
                  getAcc={getAcc}
                  deleteAccById={deleteAccById}
                />



                <RentRecipientPixSet
                  data={data}
                  onChange={onChange}
                  setDeletePix={setDataDeleteAcc}
                  isLoading={isLoading}
                  pixCreate={accCreate}
                  getPix={getAcc}
                  deletePixById={deleteAccById}
                />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: '2rem'
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={<Add/>}
                    onClick={handleAddDataAcc}
                    sx={{
                      width: '49%'
                    }}

                  >
                    Adicionar Conta Bancária
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<Add/>}
                    onClick={handleAddDataPix}
                    sx={{
                      width: '49%'
                    }}

                  >
                    Adicionar Pix
                  </Button>

                </Box>
              </Box>
            </CardContent>
          </Card>
          :
          <Skeleton
            height={300}
            width={'100%'}
            variant='rounded'
          />
        }

      </Grid>


    </>
  )
}

export default RentFormSet