import {Avatar, Card, CardContent, CardHeader, Divider} from "@mui/material";
import {UploadFile} from "@mui/icons-material";
import React from "react";

const CardFiles = ({children}) => {
  return (
    <Card className="container" >
      <CardHeader
        avatar={
          <Avatar
            sx={{
              bgcolor: 'primary.main'
            }}>
            <UploadFile/>
          </Avatar>}
        title={'Envio de Documentos'}
      />
      <Divider sx={{ mb: '.3rem' }}/>
      <CardContent>
        {children}
      </CardContent>
    </Card>

  )
}

export default CardFiles