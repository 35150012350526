import * as React from 'react';
import {useContext, useState} from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import { AdminPanelSettings, PersonOutlined } from '@mui/icons-material'

import {Skeleton, Typography} from "@mui/material";
import AuthContext from "../../contexts/Context";
import {grey} from "@mui/material/colors";

export default function UserMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const {signOut, user} = useContext(AuthContext);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        signOut();
    }

    function getInitials() {
        const fullname = user?.first_name + ' ' + user?.last_name
        return fullname.split(' ')
          .map(word => word.charAt(0))
          .join('')
          .toUpperCase();
    }

    return (
        <React.Fragment>
            {user ?
            <Box onClick={handleClick} sx={{ display: 'flex', alignItems: 'center', textAlign: 'right', cursor: "pointer" }}>
                    <IconButton
                        size="small"
                        sx={{ ml: 2, marginRight:'5px'  }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        {user?.photo ?
                          <Avatar src={user?.photo}
                                  sx={{bgcolor: "primary.main", width: 36, height: 36, color: "white"}}>
                              <PersonOutlined color={''}/>
                          </Avatar>
                          :
                          <Avatar
                            sx={(theme) => ({
                                backgroundColor: theme.palette.secondary.main,
                                width: 36,
                                height: 36,
                                fontSize: '1.1rem',
                                borderColor: grey[300],
                                p: 1
                            })}>
                              {getInitials()}
                          </Avatar>
                        }
                    </IconButton>
            </Box>
            :
                <Box sx={{ display: 'flex', justifyContent: "between", alignItems:"center", textAlign: 'right', cursor: "pointer" }}>
                    <Skeleton variant="circular" width={40} height={40} sx={{ marginRight:'5px' }} />
                    <Skeleton variant="rounded" width={70} height={30} />
                </Box>
            }

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}

                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: 220,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Typography

                  sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                      mb: '0.5rem'
                  }}
                >
                    {user?.first_name}
                </Typography>
                <Divider />

                <MenuItem
                    href={"/perfil"}
                    component={"a"}
                    sx={{ mt: '0.5rem'}}
                    >
                    <ListItemIcon>
                        <PersonOutlined fontSize="small" />
                    </ListItemIcon>
                    {"Perfil"}
                </MenuItem>
                <Divider />

                { user?.is_superuser &&
                  <MenuItem
                    href={"/admin/dashboard"}
                    component={"a"}
                    sx={{ mt: '0.5rem'}}
                  >
                      <ListItemIcon>
                          <AdminPanelSettings fontSize="small" />
                      </ListItemIcon>
                      {"Área Administrativa"}
                  </MenuItem>}
                      <Divider />

                <MenuItem onClick={handleSignOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    {"Sair"}
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
