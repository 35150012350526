import {Skeleton, Typography} from "@mui/material";
import {AccessTime, Timelapse} from "@mui/icons-material";
import React from "react";

const MaintenanceReadIfPeriodic = ({dataUpdate, isLoading}) => {
  const days = +dataUpdate?.number > 1 ? 'dias' : 'dia'
  const weeklys = +dataUpdate?.number > 1 ? 'semanas' : 'semana'
  const months = +dataUpdate?.number > 1 ? 'meses' : 'mês'
  const years = +dataUpdate?.number > 1 ? 'anos' : 'ano'

  return(
    <>
      {dataUpdate?.category === "PERIODIC" &&
        <>
          {!isLoading  ?
            <Typography
              sx={{marginBottom: '0.5rem', alignItems: 'center', display: 'flex'}}
            >
              <AccessTime sx={{mr: '0.5rem'}}/>
              <b>Período: </b>&nbsp;
              {dataUpdate?.choice  === 1 && "Diária"}
              {dataUpdate?.choice  === 7 && "Semanal"}
              {dataUpdate?.choice  === 30 && "Mensal"}
              {dataUpdate?.choice  === 365 && "Anual"}
            </Typography>
            :
            <Skeleton
              variant="rounded"
              animation="pulse"
              height={24}
              sx={{marginBottom: '0.5rem'}}
            />
          }

          {!isLoading ?
            <Typography
              sx={{marginBottom: '0.5rem', alignItems: 'center', display: 'flex'}}
            >
              <Timelapse sx={{mr: '0.5rem'}}/>
              <b>Tempo: </b>&nbsp;
              {dataUpdate?.number} {dataUpdate?.choice === 1 && days}
              {dataUpdate?.choice === 7 && weeklys}
              {dataUpdate?.choice === 30 && months}
              {dataUpdate?.choice === 365 && years}
            </Typography>
            :
            <Skeleton
              variant="rounded"
              animation="pulse"
              height={24}
              sx={{marginBottom: '0.5rem'}}
            />
          }
        </>
      }
    </>
  )
}

export default MaintenanceReadIfPeriodic