import {IconButton, InputAdornment, TextField} from "@mui/material";
import {Search, SearchOff} from "@mui/icons-material";
import React, {useContext, useEffect, useState} from "react";
import Context from "../../contexts/Context";

const SearchInput = ({ label, styles}) => {
  const { filters, setFilters } = useContext(Context)

  return (
        <TextField
          fullWidth
          size={'small'}
          label={label}
          onChange={({target:{value}}) => setFilters((prevState) => ({ ...prevState, search: value }))
          }
          value={filters?.search ?? ''}
          sx={{
            maxWidth: '400px',
            ...styles
          }}
          InputProps={{
            endAdornment:
              <InputAdornment
                position="start"
              >
                <IconButton edge="end" onClick={() => {
                  if (filters?.search) {
                    setFilters((prevState) => ({...prevState, search: ''}))
                  }
                }}>
                  {!filters?.search ? <Search/> : <SearchOff color="primary"/>}
                </IconButton>
              </InputAdornment>
          }}
        />
  )
}

export default SearchInput;