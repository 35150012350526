import { useContext, useEffect } from 'react'
import Context from '../../../contexts/Context'
import { useSearchParams } from 'react-router-dom'
import { NoMatch } from '../NoMatch'
import { getToken } from '../../../services/Main/AuthToken'

const Impersonate = () => {
  const { signIn } = useContext(Context)
  const [params] = useSearchParams()

  const login = async () => {
    if (getToken()) {
      const password = null
      const impersonate = true
      const email = params.get('email')
      if (email !== '' && email !== undefined && typeof email === 'string') {
        const data = {
          email,
          password,
          impersonate
        }
        await signIn(data)
      }
    }
  }

  useEffect(() => {
    login().then(() => {})
  }, [])

  return (<NoMatch/>)
}

export default Impersonate
