import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

const AccountType = ({ reqData, setReqData }) => {
  return (
    <>
      <ToggleButtonGroup
        fullWidth
        exclusive
        value={reqData.account_type}
        color={'primary'}
        onChange={(e, value) => value !== null && setReqData(prevState => ({
          ...prevState,
          account_type: value
        }))}
        sx={{ mb: 2 }}
      >
        <ToggleButton value={"0"}>Conta Corrente</ToggleButton>
        <ToggleButton value={"1"}>Conta Poupança</ToggleButton>
      </ToggleButtonGroup>
    </>
  )
}

export default AccountType