import React, {
  useEffect,
  useState,
  useContext
} from 'react'
import {
  Backdrop,
  Box,
  Card,
  Skeleton,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField,
} from '@mui/material'
import { api } from '../../../services/Main/Api'
import { useSnackbar } from 'notistack'
import {AddCircleOutlined, Category} from '@mui/icons-material'
import { getCondoId } from '../../../services/Main/AuthStorage'
import Page from '../../../components/Shared/Page'
import Context from "../../../contexts/Context";
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import TableCategoryBody from "./components/ComplaintCategory/TableCategoryBody";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import SimpleText from "../../../components/Shared/SimpleText";
import CircularProgress from "@mui/material/CircularProgress";

const CategoryComplaint = () => {
  const { filters, setFilters } = useContext(Context)
  useEffect(function categoryComplaintFilters(){
    setFilters({
      page_size:50,
      condo: getCondoId()
    })
  },[])

  const [response, setResponse] = useState(null)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(null)

  const { enqueueSnackbar } = useSnackbar()

  async function fetchData() {
    if (filters) {
      try {
        const response = await api.get(`/complaint/category/`, { params: filters });
        setResponse(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function postCategory() {
    try {
      const condo = getCondoId();
      setLoading(true);

      const postData = {
        ...data,
        condo: condo,
        slug: data?.description,
      };

      const response = await api.post(`/complaint/category/?condo=${condo}`, postData);
      enqueueSnackbar('Categoria criada com sucesso!', { variant: 'success' });
      setOpen(false);
      setData(null)
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setRefresh(prevState => !prevState);
      setLoading(false);
    }
  }


  async function deleteCategory(id) {
    try {
      setLoading(true);
      await api.delete(`/complaint/category/${id}`);
      enqueueSnackbar('Categoria apagada com sucesso!', { variant: 'success' });
    } catch (error) {
      console.error(error);
    } finally {
      setRefresh(prevState => !prevState);
      setLoading(false);
    }
  }

  useEffect(function getCategories(){
    fetchData()
  }, [refresh, filters])

  return(
    <>
      <Page title={'Categorias de Reclamação'}>
        <Box>
          <Backdrop
            sx={{ color: '#fff', zIndex: 1500, }}
            open={loading}
          >
            <CircularProgress color="inherit"/>
          </Backdrop>
          <Card>
            {response?.results ?
            <>
            <TitleBtnList
              title={'Categorias de Reclamação'}
              buttonText={'Criar Categoria'}
              icon={<AddCircleOutlined/>}
              onClick={() => setOpen(true)}
              boxStyles={{ m: '1rem'}}
            />

            <TableContainer>
              {response?.results?.length > 0 ?
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Nome
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableCategoryBody
                  state={response}
                  deleteCategory={deleteCategory}
                />
              </Table>
              :
               <NoResultsAnimation />
              }
            </TableContainer>
            </>

              :
            <Skeleton variant="rounded" height={500} animation="pulse" />
            }
          </Card>
        </Box>
          <SimpleDialog
            title={'Nova Categoria'}
            titleIcon={<Category />}
            open={open}
            onClickConfirm={() => postCategory()}
            onClickCancel={() => {
              setOpen(false)
              setData(null)
            }}
            dialogContent={
            <>
              <SimpleText
                fullWidth
                label={'Nome'}
                state={data}
                setState={setData}
                keyName={'description'}
                styles={{ mb: 2 }}
                error={!!error?.name}
              />
            </>
            }
          />

          <PaginationGlobal
            state={response}
            filters={filters}
            setFilters={setFilters}
          />
      </Page>
    </>
  )
}

export default CategoryComplaint;
