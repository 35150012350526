import React, {
  useEffect,
  useState,
  useContext
} from "react";
import {
  Box,
  Skeleton,
} from "@mui/material";
import {useParams} from "react-router-dom";
import {api} from "../../../../services/Main/Api";
import {useSnackbar} from "notistack";
import noComments from "../../../../assets/animations/noComments.json";
import loading from "../../../../assets/animations/loading.json";
import Context from "../../../../contexts/Context";
import CommentSender from "./components/CommentSender";
import CommentCounter from "./components/CommentCounter";
import CommentMain from "./components/CommentMain/CommentMain";
import AnimationGlobal from "../../../../components/Shared/animations/AnimationGlobal";
import CommentActionMenu from "./components/CommentActionMenu";
import ScrollObserver from "./components/ScrollObserver";

const Comments = () => {
  const param = useParams()
  const {id} = param
  const { enqueueSnackbar } = useSnackbar()

  const [data, setData] = useState(null)
  const [dataUpdate, setDataUpdate] = useState(null)
  const [responseData, setResponseData] = useState(null)
  const [observer, setObserver] = useState(null)
  const [openMenu, setOpenMenu] = useState(false)
  const [edit, setEdit] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [commentId, setCommentId] = useState(null)
  const [finalId, setFinalId] = useState(null)
  const { filters, setFilters } = useContext(Context)

  useEffect(function commentsFilters(){
    setFilters(prevState => ({
      ...prevState,
      complaint: id,
      page_size: 0,
    }))

  },[])

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleOpenConfirmation() {
    setOpenMenu(false)
    setOpenConfirmation(true)
  }

  function handleCloseConfirmation() {
    setOpenConfirmation(false)
  }

  async function fetchData() {
    try {
      if (filters?.complaint) {
        const response = await api.get(`/complaint/comment/`, { params: filters });
        setResponseData(response.data);
        setDataUpdate(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function postComment() {
    try {
      const dataReq = {
        ...data,
        complaint: +id
      };

      await api.post(`/complaint/comment/`, dataReq);
      enqueueSnackbar('Comentário criado com sucesso!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Ocorreu um erro ao criar o comentário', { variant: 'error' });
      console.log(error);
    } finally {
      setObserver((prevState) => !prevState);
      setData('');
    }
  }


  async function update() {
    try {
      const dataReq = {
        ...dataUpdate,
        complaint: +id
      };

      await api.put(`/complaint/comment/${finalId}/`, dataReq);
      enqueueSnackbar('Comentário atualizado com sucesso!', { variant: 'success' });
      setObserver((prevState) => !prevState);
    } catch (error) {
      console.log(error.response.data);
      enqueueSnackbar('Ocorreu um erro ao atualizar o comentário', { variant: 'error' });
    } finally {
      setEdit((prevState) => !prevState);
    }
  }


  async function deleteComment(id) {
    try {
      await api.delete(`/complaint/comment/${id}`);
      enqueueSnackbar('Comentário deletado com sucesso!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Erro ao excluir comentário', { variant: 'error' });
    } finally {
      setObserver(function(prevState) {
        return !prevState;
      });
    }
  }


  useEffect(function scrollObserver(){
    if (responseData?.next){
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries.some(entry => entry.isIntersecting)) {
          setFilters(prevState => ({...prevState, page_size: prevState.page_size + 10 }))
        }
      })
      intersectionObserver.observe(document.querySelector('#scroll-observer'));

      return () => intersectionObserver.disconnect()
    }
  },[responseData?.next])

  useEffect(function loadComments () {
    fetchData()
  }, [filters, observer])


  return (
    <>
      {dataUpdate ?
        <Box
          sx={{
            margin: '1rem'
          }}
        >
          <CommentCounter
            state={responseData}
          />
          <CommentSender
            state={data}
            setState={setData}
            submitFunction={postComment}
          />
      {responseData?.count ?
        <>
          <CommentMain
            responseData={responseData}
            edit={edit}
            setEdit={setEdit}
            setCommentId={setCommentId}
            setOpenMenu={setOpenMenu}
            handleClick={handleClick}
            dataUpdate={dataUpdate}
            setDataUpdate={setDataUpdate}
            finalId={finalId}
            setFinalId={setFinalId}
            update={update}
          />

          <CommentActionMenu
            openMenu={openMenu}
            anchorEl={anchorEl}
            setOpenMenu={setOpenMenu}
            setEdit={setEdit}
            setFinalId={setFinalId}
            commentId={commentId}
            openConfirmation={openConfirmation}
            handleOpenConfirmation={handleOpenConfirmation}
            handleCloseConfirmation={handleCloseConfirmation}
            deleteComment={deleteComment}
          />
        </>
        : !responseData  ?
          <AnimationGlobal
            size={'200px'}
            animation={loading}
          />
        :
        responseData?.count === 0 &&
          <AnimationGlobal
            text={'Nenhum comentário'}
            size={'200px'}
            fontSize={'23px'}
            animation={noComments}
          />
      }

         <ScrollObserver
           responseData={responseData}
         />
        </Box>
          :
        <Skeleton
          height={500}
          sx={{
              mt: '-6rem'
          }}
        />
    }
  </>
  )
}

export default Comments