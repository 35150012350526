import {Box, Button} from "@mui/material";
import {SaveAlt} from "@mui/icons-material";
import React from "react";

const CommentButtons = ({ comment, finalId, edit, setEdit, setFinalId, setDataUpdate, update, dataUpdate, index }) => {
  return (
    <>
      {comment?.id === finalId && edit &&
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mb: '1rem'
            }}
          >
            <Button
              variant="text"
              onClick={() => {
                setEdit(prevState => !prevState)
                setFinalId(null)
                setDataUpdate((prevState) => ({...prevState, message: comment.message}))
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="text"
              endIcon={<SaveAlt/>}
              disabled={dataUpdate?.message?.length < 1 || dataUpdate?.message === undefined || dataUpdate?.message === comment.message  && true}
              onClick={() => {
                update()
                setFinalId(null)
              }
              }
            >
              Salvar
            </Button>
          </Box>
      }
    </>
  )
}

export default CommentButtons