import {Backdrop, CircularProgress, Dialog, DialogContent, TextField, Typography} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React, {useContext, useEffect, useState} from "react";
import SimpleDialog from "../../../../../components/Shared/SimpleDialog";
import SimpleText from "../../../../../components/Shared/SimpleText";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {getCondoId, getFilterDate, setFilterDate} from "../../../../../services/Main/AuthStorage";
import UnitFilter from "../../../../../components/Filters/UnitFilter";
import {Box} from "@mui/system";
import DateAndTime from "../../../../../components/BookingAndMaintenance/DateAndTime";
import {api} from "../../../../../services/Main/Api";
import {useSnackbar} from "notistack";
import {useParams} from "react-router-dom";
import Context from "../../../../../contexts/Context";
import {CompareArrows, Outbox, Pix} from "@mui/icons-material";
import SimpleTypography from "../../../../../components/Shared/SimpleTypography";
import Masks from "../../../../../components/Shared/Masks";

const TransferForm = (props) => {
  const {
    transferOpen,
    setTransferOpen,
  }
    = props

  const { filters, setFilters, unitOptions } = useContext(Context)

  const {enqueueSnackbar} = useSnackbar()

  const [reqData, setReqData] = useState(null)
  const [edit, setEdit] = useState(false)
  const [error, setError] = useState(null)
  const [timePickerEntry, setTimePickerEntry] = useState(moment().locale('pt-br').format('LT'))
  const [timePickerExit, setTimePickerExit] = useState(moment().locale('pt-br').format('LT'))
  const [cancelOpen, setCancelOpen] = useState(false)
  const [reqLoading, setReqLoading] = useState(false)


  useEffect(function getInfo(){
    if(transferOpen){
      const alternateUrl = filters?.transferType === 'ted' ?
        api.get(`fitbank/money-transfer/${filters?.id}/`)
        : filters?.transferType === 'pix' &&
        api.get(`fitbank/pix-transfer/${filters?.id}/`)



      // setFilters(prevState => ({...prevState, loadDelivery: true }))
      alternateUrl
        .then(response =>{
          setReqData(response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          setFilters(prevState => ({...prevState, load: false }))
        })
    }
  },[filters?.id])

  // useEffect(function ifNoData(){
  //   !reqData?.exit_date && setReqData(prevState => ({...prevState, exit_date: moment(exitDate()).format()}))
  // },[openDelivery])

  // const entryDate = () => {
  //   const formatEntryDate = moment(reqData?.entry_date).format('YYYY-MM-DD')
  //   const finalEntryDate = formatEntryDate + ' ' + timePickerEntry
  //   return moment(finalEntryDate)
  // }

  // const exitDate = () => {
  //   const formatExitDate = moment(reqData?.exit_date).format('YYYY-MM-DD')
  //   const finalExitDate = formatExitDate + ' ' + timePickerExit
  //   return moment(finalExitDate)
  // }

  // const data = {
  //   ...reqData,
  //   condo: +getCondoId(),
  //   exit_date: moment(exitDate()).format()
  // }

  const resetFilters = () => {
    setFilters(prevState => ({
      ...prevState,
      id: null
    }))
  }


  // const create = () => {
  //
  //   if (filters?.isCreate) {
  //     setReqLoading(true)
  //     api.post(`/management/delivery/`, data)
  //       .then(response => {
  //         setOpenDelivery(false)
  //         setReqData(null)
  //         setFilters(prevState => ({...prevState, requestDone: !filters?.requestDone}))
  //         resetFilters()
  //         enqueueSnackbar('Entrega cadastrada', { variant: 'success' })
  //
  //       })
  //       .catch(error => {
  //         setError(error.response.data)
  //         enqueueSnackbar('Erro ao cadastrar entrega', { variant: 'success' })
  //         console.log(error)
  //       })
  //       .finally(()=> {
  //         setReqLoading(false)
  //       })
  //   }
  // }

  // const update = () => {
  //   if (filters?.isUpdate) {
  //     setReqLoading(true)
  //     api.put(`/management/delivery/${filters.id}`, data)
  //       .then(response =>{
  //         setFilters(prevState => ({...prevState, requestDone: !filters?.requestDone}))
  //         setEdit(false)
  //         setOpenDelivery(false)
  //         setReqData(null)
  //         resetFilters()
  //         enqueueSnackbar('Entrega atualizada', { variant: 'success' })
  //
  //       })
  //       .catch(error => {
  //         enqueueSnackbar('Erro ao atualizar entrega', { variant: 'success' })
  //         console.log(error)
  //       })
  //       .finally(()=> {
  //         setReqLoading(false)
  //       })
  //   }
  // }

  const cancelTransfer = () => {
    setReqLoading(true)

    const data ={
      condo_id: +getCondoId(),
      document_number: +reqData?.document_number
    }


    const alternateUrl = filters?.transferType === 'ted' ?
      api.post(`fitbank/money-transfer/cancel/`, data)
      : filters?.transferType === 'pix' &&
      api.post(`fitbank/pix-transfer/cancel/`, data)


    alternateUrl
      .then(response =>{
        setFilters(prevState => ({...prevState, requestDone: !filters?.requestDone, load: true}))

        setReqData(null)
        setTransferOpen(false)

        enqueueSnackbar('Transferência cancelada', { variant: 'success' })
        // resetFilters()

      })
      .catch(error => {
        enqueueSnackbar('Erro ao cancelar a transferência', { variant: 'error' })
        error.response.data.error && enqueueSnackbar(error.response.data.error, { variant: 'error' })

        console.log(error)
      })
      .finally(()=> {
        setReqLoading(false)
        setFilters(prevState => ({...prevState, load: false}))

      })

  }



  const confirmButton = filters?.isCreate ? 'Cadastrar entrega' : filters?.isUpdate && 'Editar entregar'

  // const requisitionConditional = filters?.isCreate ? create() : filters?.isUpdate && setEdit(true)


  // const enableButton = reqData?.name_delivery && reqData?.exit_date


  return (
    <>
      <SimpleDialog
        open={cancelOpen}
        setOpen={setCancelOpen}
        title={'Cancelar transferência'}
        question={'Realmente quer cancelar essa transferência?'}
        functionProp={cancelTransfer}
      />
      <SimpleDialog
        title={
          filters?.transferType === 'ted' ? 'Transferência TED' :
          filters?.transferType === 'pix' && 'Transferência Pix'
        }
        titleIcon={
          filters?.transferType === 'ted' ? <CompareArrows /> :
          filters?.transferType === 'pix' && <Pix />

        }
        disabled={reqData?.status?.value !== 0 && reqData?.status?.value !== 'Created'}
        open={transferOpen}
        setOpen={setTransferOpen}
        confirmText={'Cancelar'}
        cancelText={'Voltar'}
        onClickConfirm={() => {
          setCancelOpen(true)
        }}
        onClickCancel={() => {
          setFilters(prevState => ({...prevState, load: true }))
          resetFilters()
          setTransferOpen(false)
          setReqData(null)
        }}
        dialogContent={
          <>
            <Backdrop
              sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
              open={reqLoading}
            >
              <CircularProgress color="inherit"/>
            </Backdrop>

            <Box>
              <SimpleTypography
                label={'Favorecido'}
                labelStyles={{fontWeight: 'bold '}}
                directValue={reqData?.to_name ?? ''}
                isLoading={filters?.load}
              />
              <SimpleTypography
                label={'Banco'}
                labelStyles={{fontWeight: 'bold '}}
                directValue={reqData?.to_bank_name ?? ''}
                isLoading={filters?.load}
              />
              <SimpleTypography
                label={'Agência'}
                labelStyles={{fontWeight: 'bold '}}
                directValue={reqData?.to_bank_branch ?? ''}
                isLoading={filters?.load}
              />

              <SimpleTypography
                label={'Conta'}
                labelStyles={{fontWeight: 'bold '}}
                directValue={`${reqData?.to_bank_account} - ${reqData?.to_bank_account_digit}` ?? ''}
                isLoading={filters?.load}
              />
              <SimpleTypography
                label={'Valor'}
                labelStyles={{fontWeight: 'bold '}}
                directValue={Masks?.money(String(reqData?.value) ?? '')}
                isLoading={filters?.load}
              />
              <SimpleTypography
                label={'Situação'}
                labelStyles={{fontWeight: 'bold '}}
                directValue={reqData?.status?.label}
                isLoading={filters?.load}
              />
            </Box>
          </>
        }




      />

    </>

  )
}

export default TransferForm