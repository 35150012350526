import {IconButton, TableBody, TableCell, TableRow} from "@mui/material";
import React, {useContext, useState} from "react";
import moment from "moment";
import {Close, FileOpen, Share} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import Context from "../../../contexts/Context";

const VisitorTableBody = ({state, setOpenVisitor}) => {
  const { setDataProvider, unitOptions } = useContext(Context)


  return (
    <TableBody>
      { state?.results?.map((visitor, index) => (
        <TableRow
          hover
          tabIndex={-1}
          key={index}
          sx={{ 'cursor': 'pointer' }}
          onClick={() => {
            setDataProvider(prevState => ({...prevState, isUpdate: true , id: visitor?.id, loadVisitor: true}))
            setOpenVisitor(true)
          }}
        >

          <TableCell
            component="th"
            scope="row"
          >
            {visitor?.name ?? <Close sx={{color: 'error.main'}}/>}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
          >
            {moment(visitor?.entry_date).format('DD/MM/YYYY')}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
          >
            {unitOptions?.find(e => e.value === visitor?.unit_visitor)?.label  ?? <Close sx={{color: 'error.main'}}/>}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            align={'center'}
            sx={{
              fontWeight: 'bold',
              color: visitor?.authorized ? '#47b514' : '#ad0000'
            }}
          >
            {visitor?.authorized ? 'Autorizado' : 'Não autorizado'}
          </TableCell>
        </TableRow>
      ))}

    </TableBody>
  )
}

export default VisitorTableBody