import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

const getFaviconPath = (isDarkMode = false) => {
  return `/favicon-${isDarkMode ? 'dark' : 'light'}.ico`
}

const FavIconTheme = () => {
  const [faviconHref, setFaviconHref] = useState('/favicon-light.ico')

  useEffect(() => {
    const matcher = window.matchMedia('(prefers-color-scheme: dark)')

    setFaviconHref(getFaviconPath(matcher.matches))

    matcher.onchange = () => setFaviconHref(getFaviconPath(matcher.matches))
  }, [faviconHref])

  return (
    <Helmet>
      <link rel="icon" href={faviconHref}/>
    </Helmet>
  )
}

export default FavIconTheme