import {Box, Button, Collapse} from "@mui/material";
import DateAndTime from "../../../../../../../components/BookingAndMaintenance/DateAndTime";
import DateSkeleton from "../../../../../../../components/BookingAndMaintenance/DateSkeleton";
import React from "react";


const MaintenanceReadEndDateSave = ({hasEndDate, setDataUpdate, endDate, setTimePickerEnd, handleSubmit, isLoading}) => {
  return (
      <Collapse in={hasEndDate}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '2rem'

          }}
        >

          {!isLoading ?
            <DateAndTime
              labelDate={'Data de conclusão'}
              labelTime={'Horário de conclusão'}
              setState={setDataUpdate}
              valueFunction={endDate}
              timePicker={setTimePickerEnd}
              keyName={'end_date'}
            />
            :
            <DateSkeleton />
          }
          <Button

            type="button"
            size="large"
            sx={{ml: '1rem'}}
            onClick={(e) => {
              handleSubmit(e)
            }}
          >
            {"Salvar"}
          </Button>
        </Box>
      </Collapse>
  )
}

export default MaintenanceReadEndDateSave;