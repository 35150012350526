import {Box, Grid, Typography} from "@mui/material";

const TransferType = ({ type }) => {
  return (
    <Box
      py={1}
      my={1}>
      <Grid
        container
        spacing={1}
        fontSize={'small'}
      >
        <Grid
          item
          xs={4}>
          <Typography
            variant={'subtitle2'}>
            {'Tipo de Transf.'}
          </Typography>
        </Grid>
        <Grid
          item
          xs={8}>
          <Typography
            variant={'body2'}
          >
            {type}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TransferType