import React, { useEffect, useState, useContext } from 'react'
import {
  Card,
  CardContent,
  Container,
  Stack, TextField
} from '@mui/material'
import Context from '../../contexts/Context'
import CardInvoiceInfo from '../../components/Invoice/CardInvoiceInfo'
import { getCondoId, getCondoType, getFilterDate, setFilterDate } from '../../services/Main/AuthStorage'
import { Helmet } from 'react-helmet'
import Treemap from '../../components/Charts/Treemap'
import { Box } from '@mui/system'
import Pizza from '../../components/Charts/Pizza'
import OverduesCard from "../../components/Shared/OverduesCard";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import CondoDashboard from './Dashboard/condo'
import RentDashboard from './Dashboard/rent'


const Dashboard = () => {

  const storageDate = getFilterDate()

  const { condoIdLogin } = useContext(Context)
  const [date, setDate] = useState(storageDate ? moment(new Date(storageDate)).format() : moment().format())
  const condo = getCondoId()
  const { user } = useContext(Context)
  const { is_superuser } = user || {}

  useEffect(function preventError() {
    setFilterDate(date)
  }, [])

  return (
    <>
      <Helmet>
        <title>Início - Bloco B3</title>
      </Helmet>

      {(condoIdLogin || condo) &&
        <Container maxWidth="100%">
          {getCondoType() === "CONDO" &&
          <CondoDashboard />
          }
          {getCondoType() === "RENT" &&
          <RentDashboard/>
          }
        </Container>
      }
    </>
  )
}

export default Dashboard
