import React from "react";
import {
  Box,
  Typography
} from "@mui/material";
import ComplaintCategorySelector from "../../Shared/ComplaintCategorySelector";
import {WatchLater} from "@mui/icons-material";
import moment from "moment";

const ComplaintSubHeader = ({ edit, dataUpdate, setDataUpdate, error }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >

        {!edit ?
          <Typography
            sx={{
              mr: '0.5rem',
            }}

          >
            { dataUpdate?.category }
          </Typography>
          :
          <ComplaintCategorySelector
            state={dataUpdate}
            setState={setDataUpdate}
            keyName={'category'}
            variant={'standard'}
            styles={{
              width: '70%'
            }}
            error={error}
          />
        }

        <Typography
          component={'span'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '0.800rem',
            color: '#637381',
          }}
        >
          <WatchLater
            sx={{
              fontSize: '1rem',
              mr: '0.2rem'
            }}

          />
          {moment(dataUpdate?.created_at).fromNow()}
        </Typography>


      </Box>
      <Typography
        component={'span'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 400,
          fontSize: '0.800rem',
          color: '#637381',
        }}
      >
        {`Última atualização: ${moment(dataUpdate?.updated_at).fromNow()}`}
      </Typography>
    </>
  )
}

export default ComplaintSubHeader