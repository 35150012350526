import React from "react";
import {
  Grid,
  InputAdornment
} from "@mui/material";
import CondoUf from "./dependencies/CondoUf";
import {
  Badge,
  EditRoad,
  Factory,
  House,
  LocalPhone,
  LocationCity,
  LocationOn,
  Mail,
  More,
  ViewQuilt
} from "@mui/icons-material";
import SimpleText from "../../../../../../components/Shared/SimpleText";

const CondoInfo = ({
    condoData,
    setCondoData,
    error
  }) => {
  /*
    This component is related to the first segment of CondoUpdate form.
   */

  const uf = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MG',
    'MT',
    'MS',
    'PA',
    'PB',
    'PE',
    'PI',
    'PR',
    'RJ',
    'RN',
    'RO',
    'RS',
    'RR',
    'SC',
    'SE',
    'SP',
    'TO',
  ]


  return (
    <>

      <Grid container rowSpacing={3} spacing={2}>

        <Grid item xs={12} md={6}>

          <SimpleText
            label={'Nome'}
            state={condoData}
            setState={setCondoData}
            keyName={'name'}
            required={true}
            error={error}
            isLoading={!condoData}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Badge />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SimpleText
            label={'CNPJ'}
            state={condoData}
            setState={setCondoData}
            keyName={'cnpj'}
            cnpj={true}
            required={true}
            disabled={true}
            error={error}
            isLoading={!condoData}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Factory />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SimpleText
            label={'Email'}
            state={condoData}
            setState={setCondoData}
            keyName={'email'}
            isLoading={!condoData}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Mail />
                </InputAdornment>
              )
            }}
            error={error}
          />
        </Grid>

        <Grid item xs={12} md={6}>

          <SimpleText
            label={'Telefone'}
            required
            phone
            state={condoData}
            setState={setCondoData}
            keyName={'phone'}
            error={error}
            isLoading={!condoData}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LocalPhone />
                </InputAdornment>
              )
            }}
          />
        </Grid>


        <Grid item xs={12} md={4}>
          <SimpleText
            label={'CEP'}
            required
            cep
            state={condoData}
            setState={setCondoData}
            keyName={'cep'}
            error={error}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LocationOn />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <SimpleText
            label={'Rua'}
            required
            state={condoData}
            setState={setCondoData}
            keyName={'street'}
            error={error}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EditRoad />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <SimpleText
            label={'N.º'}
            required
            onlyNumbers
            state={condoData}
            setState={setCondoData}
            keyName={'number'}
            error={error}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <House />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <SimpleText
            label={'Complemento'}
            state={condoData}
            setState={setCondoData}
            keyName={'complement'}
            error={error}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <More />
                </InputAdornment>
              )
            }}
          />
        </Grid>


        <Grid item xs={12}>
          <SimpleText
            label={'Bairro'}
            required
            state={condoData}
            setState={setCondoData}
            keyName={'district'}
            error={error}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ViewQuilt />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <SimpleText
            label={'Cidade'}
            required
            state={condoData}
            setState={setCondoData}
            keyName={'city'}
            error={error}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LocationCity />
                </InputAdornment>
              )
            }}
          />
        </Grid>

          <CondoUf
            uf={uf}
            condoData={condoData}
            setCondoData={setCondoData}
            error={error}
          />
      </Grid>

    </>
  )
}

export default CondoInfo
