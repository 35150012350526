import RecipientFilter from "../RecipientFilter";
import {Box, Fade, TextField, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {ArrowRightAlt} from "@mui/icons-material";
import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import Context from "../../../../contexts/Context";

const FormSetBody = (
  {
    id,
    data,
    setData,
    recipientOptions,
    rent_recipient,
    percentage,
    handleId,
    handlePercentage,
    accOptions,
    pixOptions,
    recipient_account,
    recipient_pix_key,

  }
) => {
  const { dataProvider, setDataProvider } = useContext(Context)

  const defaultValue = {
    recipientValue: recipientOptions?.find(e => e.value === rent_recipient),
    accValue: accOptions?.find(e => e.value === recipient_account),
    pixValue: pixOptions?.find(e => e.value === recipient_account)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {!id &&
          <RecipientFilter
            label={'Beneficiário'}
            state={data}
            setState={setData}
            rent_recipient={rent_recipient}
            keyName={'recipientId'}
            secondKey={'cpfCnpj'}
            valueOption={'cpfCnpj'}
            thirdKey={'name'}
            secondValue={'name'}
            // defaultValue={defaultValue?.recipientValue ?? {label: 'Selecione'}}
            options={recipientOptions}
          />
        }

        <Fade
          in={!!data?.recipientId || !!dataProvider?.edit}
          mountOnEnter
          unmountOnExit
          timeout={500}

        >
          <Box
            sx={{
              display: 'flex',
              mt: '0.5rem',
              alignItems: 'center',
            }}
          >
            <TextField
              label="Porcentagem"
              size={'small'}
              // disabled={id && handleId !== id}
              value={percentage?.toString()?.replace(/[^0-9.]/g, "") ?? ''}
              onChange={handlePercentage}
              error={dataProvider?.errorPercent}
              sx={{
                maxWidth: '400px',
                width: '100%',
                mr: '0.5rem'
              }}
            />

            <Typography
              variant="subtitle1"
              sx={{
                display: 'flex',
                mr: '0.5rem',
                alignItems: 'center',
                color: 'primary.main'
              }}
            >
              Transferência
              <ArrowRightAlt/>
            </Typography>

            <ToggleButtonGroup
              size={'small'}
              exclusive
              fullWidth
              value={data?.transferType}
              color={'primary'}
              onChange={(e, value) => {
                setData(prevState => ({
                  ...prevState,
                  transferType: value,
                  transfer_date: moment().format('YYYY-MM-DD'),
                }))
              }}
              sx={{mr: '0.5rem'}}
            >

              {accOptions?.length &&
                <ToggleButton
                  value={"ted"}
                  selected={data?.transferType === 'ted'}
                >
                  TED
                </ToggleButton>
              }

              {pixOptions?.length &&
                <ToggleButton
                  value={"pix"}
                  selected={data?.transferType === 'pix'}
                >
                  Pix
                </ToggleButton>
              }
            </ToggleButtonGroup>


            {data?.transferType === 'ted' &&
              <RecipientFilter
                label={'Conta bancária'}
                state={data}
                setState={setData}
                keyName={'accId'}
                defaultValue={defaultValue?.accValue}
                options={accOptions}
                styles={{
                  mr: '0.5rem'
                }}
              />
            }

            {data?.transferType === 'pix' &&
              <RecipientFilter
                label={'Chave Pix'}
                state={data}
                setState={setData}
                keyName={'pixId'}
                defaultValue={defaultValue?.pixValue}
                options={pixOptions}
              />
            }

          </Box>
        </Fade>
      </Box>
    </>

  )
}

export default FormSetBody