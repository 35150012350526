import React from "react";
import {
  Avatar,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import Iconify from "../../../../../components/Shared/Iconify";
import ActionsMenu from "../../../../../components/Shared/ActionsMenu";

const TableCategoryBody = ({ state, deleteCategory }) => {
  return (
    <TableBody>
      {state?.results?.map((category, index) => (
        <TableRow key={index} hover>
          <TableCell>
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={2}
            >
              <Avatar sx={{ height: 32, width: 32 }} alt={category.description}>
                <Iconify icon={'bx:category'}/>
              </Avatar>
              <Typography
                variant={"subtitle2"}
                noWrap
              >
                {category?.description}
              </Typography>
            </Stack>
          </TableCell>
          <TableCell align={'right'}>
            <ActionsMenu
              canBeDeleted={false}
              delete={() => deleteCategory(category.id)}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}

export default TableCategoryBody