import DragAndDrop from "../../../../../../components/DragAndDrop/DragAndDrop";
import CardFiles from "./dependencies/CardFiles";
import React from "react";

const ExpenseDocuments = ({ }) => {
  return (
    <CardFiles>
      <DragAndDrop
        dropText={'Arraste e adicione os documentos ou clique para selecionar'}
        drop={true}
        deletable
      />
    </CardFiles>
  )
}

export default ExpenseDocuments