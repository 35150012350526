import {Box, Typography} from "@mui/material";
import Lottie, {LottieRefCurrentProps} from "lottie-react";
import loading from "../../../assets/animations/loading.json";
import {grey} from "@mui/material/colors";
import React from "react";

const AnimationGlobal = ({size, fontSize, text, animation, loop, speed, fontStyles, color}) => {
  return (

    <Box
      sx={{alignItems: 'center', display: 'flex', justifyContent: 'center', mt: '1rem', flexDirection: 'column' }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: size
        }}
      >
        <Lottie
          animationData={animation}
          loop={loop ?? false}
        />
      </Box>
      {text &&
      <Typography
        align={'center'}
        sx={{
          ...fontStyles,
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontSize: fontSize,
          fontWeight: 'bold',
          color: !color ? grey[400] : color
        }}

      >
        {text}
      </Typography>
      }

    </Box>
  )
}

export default AnimationGlobal