import {Skeleton, Typography} from "@mui/material";
import {AttachMoneyRounded} from "@mui/icons-material";
import Masks from "../../../../../../../components/Shared/Masks";
import React from "react";

const MaintenanceReadMaintenanceValue = ({isLoading, dataUpdate}) => {
  return (
    <>
      {!isLoading ?
        <Typography
          sx={{marginBottom: '0.5rem', alignItems: 'center', display: 'flex'}}
        >
          <AttachMoneyRounded sx={{mr: '0.5rem'}}/>
          <b>Valor da Manutenção:</b>&nbsp; {Masks.money(String(dataUpdate?.maintenance_value))}
        </Typography>
        :
        <Skeleton
          variant="rounded"
          animation="pulse"
          height={24}
          sx={{marginBottom: '0.5rem'}}
        />
      }
    </>
  )
}

export default MaintenanceReadMaintenanceValue