import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import {useMediaQuery} from "@mui/material";

const ModalDelete = (props) => {
  const { onClose, open, onDelete, ...other } = props
  const sizeMatch = useMediaQuery('@media (min-width:600px)')

  const handleCancel = (e) => {
    e.stopPropagation()
    onClose()
  }

  const handleOk = (e) => {
    e.stopPropagation()
    if (onDelete) {
      onDelete()
    }
    onClose()
  }

  return (
    <Dialog
      // sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      fullScreen={!sizeMatch}
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '600px', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle>{"Apagar"}</DialogTitle>
      <DialogContent dividers>
        {"Deseja realmente apagar este item?"}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>{"Cancelar"}</Button>
        <Button variant={"contained"} onClick={handleOk}>{"Ok"}</Button>
      </DialogActions>
    </Dialog>
  )
}

ModalDelete.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  open: PropTypes.bool.isRequired,
}

export default ModalDelete;
