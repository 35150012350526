import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {
    FormControl,
    FormControlLabel,
    FormLabel, Grid,
    InputAdornment, Radio,
    RadioGroup, Select,
    TextField,
    Tooltip,
    useMediaQuery
} from "@mui/material";
import SimpleSelector from "../../../../components/Shared/SimpleSelector";
import SimpleText from "../../../../components/Shared/SimpleText";
import {Percent} from "@mui/icons-material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ContractInputs = (
  {
    reqData,
    setReqData,
    contractTypeOptions,
    contractReadjustmentIndex,
    contractIsIncomeTax,
    adminTaxPreview,
    intermediationPreview,
    error,
  }) => {
  const params = useParams()
    const [includeIncomeTax, setIncludeIncomeTax] = useState(reqData?.is_income_tax);


    const sizeMatch = useMediaQuery('@media (min-width:730px)')
    const contractReadjustmentFormat = [
        {
            value: 'MANUALLY', label: 'Manualmente'
        },
        {
            value: 'AUTOMATIC', label: 'Automático'
        }
    ]

  return (
    <>
      <DesktopDatePicker
        label={'Data de Início'}
        value={moment(reqData?.start_date).toDate() ?? ''}
        onChange={(newValue) => setReqData((prevState) => ({
          ...prevState,
          start_date: newValue
        }))}
        slotProps={{
            field: { clearable: false, onClear: () => {} },
            textField: {
                size: "small"
            }
        }}
        sx={{
            mb: '1rem',
            width: '100%'
        }}
      />

      <SimpleSelector
        label={'Tipo de Contrato'}
        onlyNumbers
        state={reqData}
        setState={setReqData}
        keyName={'contract_type'}
        simpleOptions={contractTypeOptions}
        styles={{
          mb: '1rem',
          width: '100%'
          // maxWidth: reqData?.unit && sizeMatch ? '300px' : 'auto',

        }}
      />

      <SimpleText
        label={'Tempo de Contrato'}
        state={reqData}
        setState={setReqData}
        keyName={'contract_time'}
        error={error}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              meses
            </InputAdornment>
          )
        }}
        styles={{
          mb : '1rem',
          width: '100%',
          // maxWidth: reqData?.unit && sizeMatch ? '300px' : 'auto',

        }}

      />

      {/*<SimpleText*/}
      {/*  label={'Taxa Administrativa'}*/}
      {/*  float*/}
      {/*  required*/}
      {/*  directHelper={error?.administrative_tax ?? adminTaxPreview()}*/}
      {/*  onChange={(e) => setReqData(prevState => ({...prevState, administrative_tax: e.target.value?.slice(0,10)}))}*/}
      {/*  state={reqData}*/}
      {/*  // setState={setReqData}*/}
      {/*  keyName={'administrative_tax'}*/}
      {/*  error={error}*/}
      {/*  styles={{*/}
      {/*    width: '100%',*/}
      {/*    // maxWidth: reqData?.unit && sizeMatch ? '300px' : 'auto',*/}
      {/*    mb: '1rem',*/}
      {/*  }}*/}
      {/*  InputProps={{*/}
      {/*    endAdornment: (*/}
      {/*      <InputAdornment position="end">*/}
      {/*        <Percent />*/}
      {/*      </InputAdornment>*/}
      {/*    )*/}
      {/*  }}*/}
      {/*/>*/}

      {/*<SimpleText*/}
      {/*  label={'Taxa de Intermediação'}*/}
      {/*  float*/}
      {/*  directHelper={error?.intermediation_fee ?? intermediationPreview()}*/}
      {/*  state={reqData}*/}
      {/*  onChange={(e) => setReqData(prevState => ({...prevState, intermediation_fee: e.target.value?.slice(0,10)}))}*/}
      {/*  keyName={'intermediation_fee'}*/}
      {/*  error={error}*/}
      {/*  styles={{*/}
      {/*    width: '100%',*/}
      {/*    // maxWidth: reqData?.unit && sizeMatch ? '300px' : 'auto',*/}
      {/*    mb: '1rem',*/}
      {/*  }}*/}
      {/*  InputProps={{*/}
      {/*    endAdornment: (*/}
      {/*      <InputAdornment position="end">*/}
      {/*        <Percent />*/}
      {/*      </InputAdornment>*/}
      {/*    )*/}
      {/*  }}*/}
      {/*/>*/}

        <SimpleSelector
            label={'Reajustar'}
            state={reqData}
            setState={setReqData}
            // optionKeyValue={reqData?.readjustment_format.label}
            // value={value}
            keyName={'readjustment_format'}
            simpleOptions={contractReadjustmentFormat}
            styles={{
                mb: '1rem',
                width: '100%'
                // maxWidth: reqData?.unit && sizeMatch ? '300px' : 'auto',

            }}
        />

        <SimpleSelector
            label={'Indice para Reajuste'}
            state={reqData}
            setState={setReqData}
            keyName={'readjustment_index'}
            simpleIndexOptions={contractReadjustmentIndex}
            styles={{
                mb: '1rem',
                width: '100%'
                // maxWidth: reqData?.unit && sizeMatch ? '300px' : 'auto',

            }}
        />
        <SimpleSelector
            label={'Habilitar dedução do IRRF'}
            onlyNumbers
            state={reqData}
            setState={setReqData}
            keyName={'is_income_tax'}
            simpleOptions={contractIsIncomeTax}
            styles={{
                mb: '1rem',
                width: '100%'
                // maxWidth: reqData?.unit && sizeMatch ? '300px' : 'auto',

            }}
        />


        <SimpleText
        label={'Número do Contrato'}
        state={reqData}
        setState={setReqData}
        keyName={'contract_number'}
        error={error}
        styles={{
          // maxWidth: reqData?.unit && sizeMatch ? '300px' : 'auto',
          width: '100%',
          mb: '1rem',
        }}
      />


      {params?.id && reqData?.status !== 'pending' &&
        <ToggleButtonGroup
          fullWidth
          size={'small'}
          exclusive
          value={reqData?.active}
          color={'primary'}
          onChange={(e, value) => setReqData(prevState => ({
            ...prevState,
            status: value
          }))}
          sx={{
            // maxWidth: reqData?.unit && sizeMatch ? '300px' : '300px'
            width: '100%',

          }}
        >
          <ToggleButton
            value={'inactive'}
            selected={reqData?.status === 'inactive'}
          >
            Encerrado
          </ToggleButton>
          <ToggleButton
            value={'active'}
            selected={reqData?.status === 'active'}
            sx={{
              '&.Mui-selected': {
                color: '#3d3d3d',
                backgroundColor: 'rgba(0,255,0,0.38)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 255, 0, 0.7)',
                },
              },
            }}
          >
            Ativo
          </ToggleButton>
        </ToggleButtonGroup>
      }

    </>
  )
}

export default ContractInputs