import React from "react";
import {
  Divider,
  Grid,
  InputAdornment,
  Typography
} from "@mui/material";
import SimpleText from "../../../../../../components/Shared/SimpleText";
import {
  AspectRatio,
  PropaneTank,
  Water
} from "@mui/icons-material";

const CondoSpecs = ({ condoData, setCondoData, error }) => {
  /*
  This component is related to the second segment of CondoUpdate form.
 */

  return (
    <>
      <Divider sx={{ mb: 3, mt: 5 }} textAlign={'center'}>
        <Typography variant={'h6'}>{'Especificações'}</Typography>
      </Divider>
      <Grid container spacing={2} rowSpacing={3}>

        <Grid item xs={12} md={6}>
          <SimpleText
            label="Área Construída (m²)"
            onlyNumbers
            state={condoData}
            setState={setCondoData}
            keyName={'building_meter'}
            error={error}
            isLoading={!condoData}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AspectRatio />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <SimpleText
            label="Valor do Gás"
            money
            state={condoData}
            setState={setCondoData}
            keyName={'gas_value'}
            error={error}
            isLoading={!condoData}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PropaneTank />
                </InputAdornment>
              )
            }}
          />


        </Grid>
        <Grid item xs={6} md={3}>
          <SimpleText
            label="Valor do Água (m³)"
            money
            state={condoData}
            setState={setCondoData}
            keyName={'water_value'}
            error={error}
            isLoading={!condoData}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Water />
                </InputAdornment>
              )
            }}
          />

        </Grid>

      </Grid>
    </>
  )
}

export default CondoSpecs
