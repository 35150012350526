import {Box, Card} from "@mui/material";
import CommentHeader from "../CommentHeader/CommentHeader";
import CommentMessage from "./dependencies/CommentMessage";
import CommentButtons from "./dependencies/CommentButtons";
import React from "react";

const CommentMain =
  ({
     responseData,
     edit,
     setEdit,
     setCommentId,
     setOpenMenu,
     handleClick,
     dataUpdate,
     setDataUpdate,
     finalId,
     setFinalId,
     update
  }) => {
  return(
    <>
      {responseData?.results?.map((comment, index) =>
        <Box
          key={comment.id}
        >
          <Card
            sx={{
              marginBottom: '1rem',
              backgroundColor: 'rgba(172,172,177,0.17)',
            }}
          >
            <CommentHeader
              comment={comment}
              edit={edit}
              setCommentId={setCommentId}
              setOpenMenu={setOpenMenu}
              handleClick={handleClick}
            />

            <CommentMessage
              dataUpdate={dataUpdate}
              setDataUpdate={setDataUpdate}
              edit={edit}
              comment={comment}
              finalId={finalId}
            />
          </Card>
          <CommentButtons
            edit={edit}
            setEdit={setEdit}
            setFinalId={setFinalId}
            dataUpdate={dataUpdate}
            setDataUpdate={setDataUpdate}
            update={update}
            comment={comment}
            finalId={finalId}
          />
        </Box>
      )}
    </>
  )
}

export default CommentMain