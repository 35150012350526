import React, {
  useEffect
} from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from "@mui/material";
import { PropaneTankOutlined } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment/moment";

const GasConsume = ({
    state,
    setState,
    previousData,
    gasRequired,
    errors
  }) => {

  function readingHelperText() {
    if (errors && errors.previous_reading_date) {
      return errors.previous_reading_date;
    } else if (previousData && previousData.previousDate) {
      return `Última data de leitura: ${moment(previousData.previousDate).format('DD/MM/YYYY')}`;
    } else {
      return '';
    }
  }

  useEffect(function stateFill() {
    if(!state?.previous_reading_amount) {
      setState(prevState => ({...prevState, previous_reading_amount: previousData?.previousReading}))
    }
  }, [previousData])

  return (
    <Grid
      item
      xs={12}
    >
      {state?.category === 'GAS' &&
        <Card>
          <CardHeader
            avatar={<Avatar sx={{bgcolor: 'primary.main'}}><PropaneTankOutlined/></Avatar>}
            title={'Consumo de Gás'}/>
          <Divider
            sx={{
              mb: '.3rem'
            }}
          />
          <CardContent>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={6}
              >

                <DatePicker
                  sx={{ textAlign: 'center' }}
                  views={['year', 'month', 'day']}
                  label="Data da Leitura Anterior"
                  inputProps={{
                    placeholder: 'dd/mm/aaaa'
                  }}
                  value={moment(state?.previous_reading_date)}
                  onChange={(newValue) => setState((prevState) => ({
                    ...prevState,
                    previous_reading_date: moment(newValue).format('YYYY-MM-DD')
                  }))}
                  error={!!errors?.previous_reading_date}
                  helperText={readingHelperText()}
                  renderInput={
                    (params) => (
                      <TextField
                        {...params}
                        size={'small'}
                        sx={{ mb: 2 }}
                        helperText={null}
                        fullWidth
                        required
                      />
                    )
                  }
                />

              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  sx={{ textAlign: 'center' }}
                  views={['year', 'month', 'day']}
                  label="Data da Leitura Atual"
                  inputProps={{
                    placeholder: 'dd/mm/aaaa'
                  }}
                  value={moment(state?.current_reading_date)}
                  onChange={(newValue) => setState((prevState) => ({
                    ...prevState,
                    current_reading_date: moment(newValue).format('YYYY-MM-DD')
                  }))}
                  error={!!errors?.current_reading_date}
                  helperText={errors?.current_reading_date ? errors.current_reading_date : ''}
                  renderInput={
                    (params) => (
                      <TextField
                        {...params}
                        size={'small'}
                        sx={{ mb: 2 }}
                        helperText={null}
                        fullWidth
                        required
                      />
                    )
                  }
                />

              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Leitura Anterior"
                  size={'small'}
                  fullWidth
                  type={'text'}
                  value={state?.previous_reading_amount?.toString().replace(/[^0-9.,]/g, '') ?? ''}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      previous_reading_amount: e.target.value?.replace(/[^0-9.,]/g, '')
                    }))}
                  error={!!errors?.previous_reading_amount}
                  helperText={errors?.previous_reading_amount ? errors.previous_reading_amount : previousData?.previousReading ? `Última leitura: ${previousData?.previousReading}` : ''}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Leitura Atual"
                  size={'small'}
                  fullWidth
                  type={'text'}
                  required={!!gasRequired}
                  value={state?.current_reading_amount?.replace(/[^0-9.,]/g, '') ?? ''}
                  onChange={(e) => setState((prevState) => ({
                    ...prevState,
                    current_reading_amount: e.target.value?.replace(/[^0-9.,]/g, '')
                  }))}
                  error={!!errors?.current_reading_amount}
                  helperText={errors?.current_reading_amount}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }
    </Grid>
  )
}

export default GasConsume