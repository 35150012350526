import {Button, CardActions} from "@mui/material";
import {DownloadOutlined} from "@mui/icons-material";

const ReceiptDownload = ({ data }) => {
  return (
    <CardActions>
      <Button
        href={data?.receipt}
        sx={{ mx: 'auto' }}
        startIcon={<DownloadOutlined/>}>
        {'Baixar Comprovante'}
      </Button>
    </CardActions>
  )
}

export default ReceiptDownload