import {TableBody, TableCell, TableRow} from "@mui/material";
import moment from "moment/moment";
import React from "react";
import {useNavigate} from "react-router-dom";
import {Close} from "@mui/icons-material";

const TableComplaintBody = ({response}) => {
  const navigate = useNavigate()

  return (
    <TableBody>
    {response?.results?.map((complaint, index) => (
      <TableRow
        hover
        tabIndex={-1}
        key={index}
        sx={{
          'cursor': 'pointer'
        }}
        onClick={() => {
          navigate(`/reclamacoes/detalhes/${complaint.id}/`)
        }}
      >
        { complaint?.unit ?
          <TableCell
            align="left"
          >
            {`${complaint?.unit.number} | ${complaint?.unit.name} `}
          </TableCell>
          :
          <TableCell
            align="left"
          >
            <Close
              sx={{ color: 'error.main'}}
            />
          </TableCell>
        }
        <TableCell
          align="left"
        >
          {moment(complaint.created_at).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell
          align="left"
        >
          {moment(complaint.updated_at).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell
          align="left"
        >
          {complaint.category.value}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: complaint?.status === 'OPEN' ? '#0f9d0a' : 'primary.main'
          }}
        >
          {complaint.status === 'OPEN' ? 'Em Aberto' : 'Fechada'}
        </TableCell>
      </TableRow>
    ))}
</TableBody>
  )
}

export default TableComplaintBody