import {TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";

const RentReportHead = ({ filters }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          Mês
        </TableCell>
        <TableCell
          // align={'center'}
          sx={{
            color: 'primary.main'
          }}
        >
          Ano
        </TableCell>

      </TableRow>
    </TableHead>
  )
}

export default RentReportHead