import {Box, Skeleton, Typography} from "@mui/material";
import moment from "moment/moment";

const TimeInfo = ({ loading, data }) => {
  return (
    <Box textAlign={'center'} sx={{ mb: 3 }}>
      <Typography variant={'body2'}>
        {!loading ?
          moment(data?.created_at).format('DD \\d\\e MMMM \\d\\e YYYY')
          : <Skeleton sx={{ mx: 'auto' }} variant={'text'} width={'50%'}/>
        }
      </Typography>
      <Typography variant={'body2'}>
        {!loading
          ? <>{'às'} {moment(data?.created_at).format('hh:mm:ss')})</>
          : <Skeleton sx={{ mx: 'auto' }} variant={'text'} width={'30%'}/>
        }
      </Typography>
    </Box>
  )
}

export default TimeInfo