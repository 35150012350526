import {Box} from "@mui/system";
import {Button, IconButton} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import React from "react";
import SaveCancelButton from "./SaveCancelButton";

const ButtonSet = (
  {
    editState,
    editClick,
    deleteClick,
    saveClick,
    cancelClick,
    removeDelete,
    disableSave
  }) => {
  return (
    <>
      {!editState ?
        <Box>
          <IconButton
            onClick={editClick}
          >
            <Edit/>
          </IconButton>
          {!removeDelete &&
            <IconButton
              onClick={deleteClick}
            >
              <Delete/>
            </IconButton>
          }
        </Box>

        :

        <SaveCancelButton
          edit={editState}
          disabled={disableSave}
          saveClick={saveClick}
          cancelClick={cancelClick}
        />




      }
    </>
  )
}

export default ButtonSet