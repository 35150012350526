import { createTheme } from '@mui/material/styles'
import { ptBR } from '@mui/material/locale'
import palette from '../patterns/palette'
import typography from '../patterns/typography'
import shadows, { customShadows } from '../patterns/shadows'

const themeOptions = {
  palette,
  shape: { borderRadius: 8 },
  typography,
  shadows,
  customShadows,
}

const theme = createTheme({
  ...themeOptions,
  typography: {
    // fontFamily: "'Nunito', sans-serif;"
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          boxShadow: 'rgb(32 32 32 / 6%) 0px 8px 16px;',
        }
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontWeight: 'bold',
          fontSize: 20,
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '.4rem',
          boxShadow: 'none',
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // borderRadius: ".4rem",
          boxShadow: 'rgb(32 32 32 / 6%) 0px 8px 16px;'
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '.4rem'
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '15px',
          lineHeight: 1.7,
          color: '#afafaf',
          opacity: 1
        },
        shrink: {
          fontSize: '16px',
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'neutral.main',
          }
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          color: 'primary.main'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: 'success.main'
        }
      },
    },
  },
}, ptBR,)

// theme.components = ComponentsOverrides(theme)

export default theme
