import {IconButton, TableBody, TableCell, TableRow} from "@mui/material";
import React, {useContext, useState} from "react";
import moment from "moment";
import {Close, FileOpen, Share} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import Masks from "../../../../../components/Shared/Masks";
import Context from "../../../../../contexts/Context";

const TableTransferBody = (props) => {
  const { state, setTransferOpen } = props

  const { setFilters } = useContext(Context)

  return (
    <TableBody>
      { state?.results?.map((transfer, index) => (
        <TableRow
          hover
          tabIndex={-1}
          key={index}
          sx={{ 'cursor': 'pointer' }}
          onClick={(e) => {
            e.stopPropagation()
            setFilters(prevState => ({...prevState, isUpdate: true , id: transfer?.id, load: true}))
            setTransferOpen(true)
          }}
        >
          <TableCell
              component="th"
              scope="row"
          >
            {transfer?.number}
          </TableCell>

          <TableCell
            component="th"
            scope="row"
          >
            {transfer?.to_name ?? <Close sx={{color: 'error.main'}}/>}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
          >
            {moment(transfer?.created_at).format('DD/MM/YYYY HH:mm')}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
          >
            {Masks.money(String(transfer?.value)) ?? ''}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            align={'center'}
            sx={{
              fontWeight: 'bold',
              // color: deliver?.delivered ? '#47b514' : '#ad0000'
            }}
          >
            {
              transfer?.status?.label
            }
          </TableCell>
        </TableRow>
      ))}

    </TableBody>
  )
}

export default TableTransferBody