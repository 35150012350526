import {Box, Collapse, InputAdornment, TextField} from "@mui/material";
import {ArrowDownward, Timelapse} from "@mui/icons-material";
import React from "react";

const MaintenanceTime = ({state, setState, disabled }) => {
  const days = +state?.number > 1 ? 'dias' : 'dia'
  const weeklys = +state?.number > 1 ? 'semanas' : 'semana'
  const months = +state?.number > 1 ? 'meses' : 'mês'
  const years = +state?.number > 1 ? 'anos' : 'ano'

  return (
    <Collapse in={!!state?.choice && state?.category === "PERIODIC"} unmountOnExit>
      <Box
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}
      >
        <ArrowDownward
          sx={{
            fontSize: '40px',
            color: 'primary.main',
          }}/>
        <TextField
          fullWidth
          disabled={disabled}
          size={"small"}
          label={'Tempo'}
          sx={{marginBottom: '2rem'}}
          onChange={(e)=> setState((prevState) => ({...prevState, number: +e.target.value?.replace(/\D/g, '')}))}
          value={state?.number ?? ''}
          InputProps={{
            startAdornment:
              <InputAdornment position={'start'}>
                <Timelapse sx={{mr: '0.5rem'}}/>
                A cada
              </InputAdornment>,
            endAdornment:
              <InputAdornment position="end">
                {state?.choice === 1 && days}
                {state?.choice === 7 && weeklys}
                {state?.choice === 30 && months}
                {state?.choice === 365 && years}
              </InputAdornment>
          }}
        />
      </Box>
    </Collapse>
  )
}

export default MaintenanceTime