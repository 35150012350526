import {Box, Button} from "@mui/material";
import {SaveAlt} from "@mui/icons-material";
import React from "react";

const  ComplaintUpdateButtons = ({
    setEdit,
    updateFunction
  }) => {

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        mb: '1rem'
      }}
    >
      <Button
        variant="text"
        onClick={() => setEdit(prevState => !prevState)}
      >
        Cancelar
      </Button>
      <Button
        variant="text"
        endIcon={<SaveAlt />}
        onClick={updateFunction}
      >
        Salvar
      </Button>
    </Box>
  )
}

export default ComplaintUpdateButtons