import {Avatar, Box, IconButton, ListItemIcon, Typography} from "@mui/material";
import {Delete, Image, InsertDriveFile, PictureAsPdf, TextSnippet} from "@mui/icons-material";
import React from "react";
import {ImgIcon, Pdf, Txt, WhateverIcon} from "./IconTypes";

const Rejected = ({rejectedFiles, setRejectedFiles}) => {
  const imgTypes = ['.jpg', '.png', '.gif']
  return (
    <>
      {rejectedFiles?.length > 0 &&
        <Box
          sx={{
            borderWidth: 1,
            padding: '1rem',
            borderRadius: 2,
            backgroundColor: 'rgb(240,240,240)',
            marginBottom: '1rem',
            // display:  'flex',
            // flexWrap:  'wrap',
          }}
        >
          <Typography
            variant={"h6"}
            sx={{
              margin: '0 0 0 0.3rem',
              color: 'red',
            }}
          >
            Erro:
          </Typography>
          <Typography
            variant={"h6"}
            sx={{
              margin: '0 0 1rem 0.3rem',
              color: 'red',
              fontSize: '16px',
              fontWeight: 'bold',
            }}
          >
            Os arquivos abaixo passam do tamanho perimitido - 50 Mb
          </Typography>
          {rejectedFiles?.map((file, index) => (
            <ListItemIcon
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                wordBreak: 'break-all',
                marginBottom: '1rem',
                marginRight: '1rem'
              }}
              key={index}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  color: 'inherit'
                }}
              >
                {file?.file?.path?.includes('.pdf') ?
                  <Pdf />
                  : file?.file?.path?.includes('.txt') ?
                  <Txt />
                  : imgTypes.some(e => file?.path?.includes(e)) ?
                  <ImgIcon />
                  :
                  <WhateverIcon />
                }
                <Typography
                >
                  {file?.file?.path}
                </Typography>
              </Box>
              <IconButton
                onClick={
                  () =>
                    setRejectedFiles(rejectedFiles?.filter(e => e?.file?.path !== file?.file?.path))
                }
              >
                <Delete/>
              </IconButton>
            </ListItemIcon>
          ))}
        </Box>
      }
    </>
  )
}

export default Rejected