import React, { useContext, useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import Context from '../../contexts/Context'
import { getCondoId } from '../../services/Main/AuthStorage'
import { api } from '../../services/Main/Api'
import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import { Square } from '@mui/icons-material'
import moment from 'moment'
import Lottie from "lottie-react";
import emptyBox from "../../assets/animations/emptyBox.json";
import {grey} from "@mui/material/colors";

export default function Treemap (props) {
  const { condoIdLogin } = useContext(Context)
  const [unitInvoices, setUnitInvoices] = useState({})
  const [loading, setLoading] = useState(true)
  const [verifier, setVerifier] = useState(false)


  const theme = useTheme()

  useEffect(function firstRender () {
    const condo = getCondoId()

    const data = {
      condo: condoIdLogin ?? condo,
      reference_month: +moment(props?.date).format('MM'),
      reference_year: +moment(props?.date).format('YYYY')
    }

    api.post('analytics/apexcharts/unit-treemap/', data)
      .then(response => {
        const data = (response?.data?.series?.map((e) =>
          ({
            x: e?.unit,
            y: e?.total,
            fillColor:
              e?.status === 'pending' ? theme?.palette?.warning.main :
              e?.status === 'paid' ? theme?.palette?.success.main :
              e?.status === 'overdue' ? theme?.palette?.error.main :
              theme?.palette?.info?.main

          })))
        setUnitInvoices(
          {
            series: [
              {
                data: data ?? ''
              }
            ],
            options: {
              legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: -20,
                offsetX: -5
              },
              chart: {
                toolbar: {
                  show: false,
                },
                height: 350,
                type: 'treemap'
              },
              // title: {
              //   text: 'Faturas das Unidades',
              //   align: 'left'
              // },
              plotOptions: {
                treemap: {
                  distributed: true,
                  enableShades: false
                }
              },
              colors: [
                theme?.palette?.info?.main,
                theme?.palette?.success?.main,
                theme?.palette?.warning?.main,
                theme?.palette?.error?.main,
              ]
            },
          }
        )
        setLoading(false)
      }).catch(error => {
      console.log(error)
    }).finally(r => {
    setVerifier(true)
  })
  }, [props?.date, condoIdLogin])

  return (
    <>
      <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '2rem'
          }}
      >
        <Typography
            sx={{
              fontFamily: 'Helvetica, Arial, sans-serif',
              textAnchor: 'start',
              dominantBaseline: 'auto',
              fontSize: '14px',
              fontWeight: 900,



            }}
        >
          Faturas por Imóveis
        </Typography>
      </Box>
      {verifier ?
          <>
            {unitInvoices?.series[0]?.data?.length > 0 ?
          <>

          <Stack
            direction="row"
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mb: 0,
              mr: '1rem'
            }}
          >
            <Square
              sx={{
                color: theme?.palette?.info?.main,
                fontSize: 14,
                mr: '3px'
              }}
            />
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '15px',
                mr: '5px'
              }}
              component={'span'}
            >
              Em Aberto
            </Typography>
            <Square
              sx={{
                color: theme?.palette?.success?.main,
                fontSize: 14,
                mr: '3px'
              }}
            />
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '15px',
                mr: '5px'
              }}
              component={'span'}
            >
              Pago
            </Typography>
            <Square
              sx={{
                color: theme?.palette?.warning?.main,
                fontSize: 14,
                mr: '3px',
              }}
            />
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '15px',
                mr: '5px'
              }}
              component={'span'}
            >
              Pendente
            </Typography>
            <Square
              sx={{
                color: theme?.palette?.error?.main,
                fontSize: 14,
                mr: '3px'
              }}
            />
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '15px',
                mr: '5px',
              }}
              component={'span'}
            >
              Vencido
            </Typography>
          </Stack>
          <Chart
            options={unitInvoices?.options}
            series={unitInvoices?.series}
            type="treemap"
            height={350}
          />
          </>
          :
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >

              <Typography
                align={'center'}
                sx={{
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  color: grey[400],
                }}

              >
                Nenhum Resultado!
              </Typography>
              <Box
                sx={{
                  width:'250px',
                }}
              >
                <Lottie animationData={emptyBox} loop={true} />
              </Box>
            </Box>
            }
        </>
            :
            <Skeleton height={'500px'} variant={"rounded"} animation={'pulse'}/>
            }
    </>
  )
}
