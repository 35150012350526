import React, {
  useEffect,
  useState
} from "react";
import moment from "moment";
import {
  Box,
  TextField
} from "@mui/material";
import Masks from "../../../../../components/Shared/Masks";
import DateAndTime from "../../../../../components/BookingAndMaintenance/DateAndTime";
import {DesktopDatePicker} from "@mui/x-date-pickers";

const TransferInfo = ({ reqData, setReqData }) => {
  const [timepicker, setTimePicker] = useState(moment().locale('pt-br').format("LT"))

  function dateFormat () {
    const formEndDate = moment(reqData?.payment_date).format('YYYY-MM-DD')
    const setEndDate = formEndDate + ' ' + timepicker
    return moment(setEndDate).format()
  }

  useEffect(function whenClockChange(){
    setReqData(prevState => ({...prevState, payment_date: dateFormat() }))
  }, [reqData?.payment_date, timepicker])

    console.log('reqData', reqData)

  return (
      <Box
          sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: '1rem',
          }}
      >
      <TextField
        label="Valor"
        name="transferValue"
        type="text"
        size={'small'}
        required
        value={Masks.money(reqData?.value ?? '')}
        onChange={({ target: { value } }) => setReqData((prevState) => ({
          ...prevState,
          value: value
        }))}
        sx={{ mb: 2, width: '70%' }}
      />

        <DesktopDatePicker
            minDate={moment().toDate()}
            label={'Data de Transferência'}
            value={moment(reqData).toDate()}
            onChange={(newValue) => {
                setReqData((prevState) => ({
                    ...prevState,
                    payment_date: newValue
                }))
            }}
            slotProps={{
                field: { clearable: false, onClear: () => {} },
                textField: {
                    size: "small"
                }
            }}
        />
    </Box>
  )
}

export default TransferInfo