import {Box, Button, Typography} from "@mui/material";
import React from "react";
import {Receipt} from "@mui/icons-material";

const TitleBtnList = (
  {
    title,
    buttonText,
    buttonHref,
    icon,
    boxStyles,
    onClick,
    extraButton,
    buttonHrefExtra,
    iconExtra,
    buttonTextExtra,
    noButtons,
    onClickExtra
  }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        '@media (max-width:600px)': {
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: 3,
        },
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 3,
        ...boxStyles
      }}
    >
      <Typography
        textAlign={'center'}
        variant={'h5'}
      >
        {title}
      </Typography>
      {!noButtons &&
        <Box
          sx={{
            '@media (max-width:600px)': {
              display: 'grid',
              gridTemplateColumns: 'repeat(1, 1fr)',
              gap: 2,

            }
          }}
        >
          <Button
            variant={'contained'}
            href={buttonHref}
            startIcon={icon}
            onClick={onClick}
          >
            {buttonText}
          </Button>
          {extraButton &&
            <Button
              variant={'contained'}
              href={buttonHrefExtra}
              startIcon={iconExtra}
              onClick={onClickExtra}
              sx={{
                ml: '1rem',
                '@media (max-width:600px)': {
                  ml: 0,
                }
              }}
            >
              {buttonTextExtra}
            </Button>
          }
        </Box>
      }
    </Box>
  )
}

export default TitleBtnList;