import { Box, Card, Divider, Tab, Tabs, Typography } from '@mui/material'
import React, {useState} from "react";
import ReportGeneralList from "./ReportGeneralList";
import ReportRentList from "./ReportRentList";

const Report = () => {
  const [tabValue, setTabValue] = useState(0)


  return (
    <>
      <Card>
        <Typography
          variant={'h5'}
          sx={{
            p: '1rem'
          }}
        >
          {'Relatórios Financeiros'}
        </Typography>
        <Tab label="Locações"/>
        <Divider sx={{ mb: '.3rem' }}/>

        <ReportRentList />

      </Card>

    </>
  )
}
export default Report