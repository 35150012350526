import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import React from "react";
import {useMediaQuery} from "@mui/material";

const MaintenanceDeleteDialog = ({dataDelete, openDelete, setOpenDelete, deleteMaintenance, setEditEvent, setDataDelete }) => {
  const sizeMatch = useMediaQuery('@media (min-width:600px)')

  return (
    <Dialog
      fullScreen={!sizeMatch}
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '600px' } }}
      open={openDelete}
      onClose={() => setOpenDelete(false)}
    >
      <DialogTitle id="alert-dialog-title">
        {"Excluir Manutenção"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Você tem certeza que deseja excluir essa manutenção?
        </DialogContentText>
        <>
          <ToggleButtonGroup
            fullWidth
            name={'status'}
            exclusive
            value={dataDelete?.status}
            color={'primary'}
            onChange={(e, value) =>
              setDataDelete(
                value === 'unique_id' ? {
                  unique_id: true,
                  all_futures: false
                }
                :
                  value === 'all_futures' && {
                  unique_id: false,
                  all_futures: true
                })}
            sx={{mb: '1rem', mt: '0.5rem'}}
          >
            <ToggleButton
              value={"unique_id"}
              selected={dataDelete?.unique_id === true}
              >
              Excluir somente essa manutenção
            </ToggleButton>
            <ToggleButton
              value={"all_futures"}
              selected={dataDelete?.all_futures === true}
              >
              Excluir essa e todas manutenções futuras
            </ToggleButton>
          </ToggleButtonGroup>
        </>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '1rem'
        }}
      >
        <Button
          onClick={() => setOpenDelete(false)}
        >
          Cancelar
        </Button>
        <Button
          disabled={!dataDelete?.hasOwnProperty('unique_id', 'all_futures')}
          onClick={() => {
            deleteMaintenance()
            setOpenDelete(false)
            setEditEvent(false)
          }}
          autoFocus>
          Sim, excluir
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MaintenanceDeleteDialog