import {TextField} from "@mui/material";
import React from "react";

const Description = ({
    state,
    setState,
    error,
    disabled,
    label,
    keyName
  }) => {

  return (
    <TextField
      label={label}
      disabled={disabled}
      multiline
      required
      rows={4}
      fullWidth
      type="text"
      size={'small'}
      error={!!error?.[keyName]}
      helperText={error?.[keyName]}
      sx={{
        marginBottom: '2rem',
      }}
      onChange={({target:{value}}) => setState((prevState) => ({...prevState, [keyName]: value}))}
      value={state?.[keyName] ?? ''}
    />
  )
}

export default Description