import { Skeleton } from '@mui/material'
import { useEffect, useState } from 'react'



const NumberFormatB3 =  (props) => {

  const [value, setValue] = useState()

  useEffect(() => {
    if (props?.value >= 0 ){
      setValue(currency(props.value))
    }

  }, [props.value])

  const currency = (value) => new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(props.value / 100);

  return (
    <>
      {value ?
    <>{value}</> :
        <>
          {value}
      <Skeleton variant="text" animation="pulse" width={100} height={20}/>
        </>}
    </>
  )
}

export default NumberFormatB3;