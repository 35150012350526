import React from "react";
import {
  Button,
  Stack
} from "@mui/material";
import SimpleText from "../../../../../components/Shared/SimpleText";
import {Send} from "@mui/icons-material";

const CommentSender = ({ state, setState, submitFunction }) => {
  return (
    <Stack>
      <SimpleText
        label="Adicione um comentário..."
        state={state}
        setState={setState}
        keyName={'message'}
        multiline={true}
        variant="standard"
        styles={{ mb: '0.5rem ' }}
      />

      <Button
        variant="text"
        endIcon={<Send />}
        disabled={!!!state?.message}
        onClick={submitFunction}
        sx={{
          mb: '1rem'
        }}
      >
        Enviar
      </Button>
    </Stack>
  )
}

export default CommentSender