import {TableHead, TableRow} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import React from "react";

const InvoiceDetailThead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            color: 'gray'
          }}
        >
          Categoria
        </TableCell>
        <TableCell
          sx={{
            color: 'gray'
          }}
        >
        </TableCell>
        <TableCell
          align="right"
          sx={{
            color: 'gray'
          }}
        >
          Total
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default InvoiceDetailThead