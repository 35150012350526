const masks = {
  cpf (value) {
    return value
      .replace(/\D+/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  },

  cnpj (value) {
    return value
      ?.replace(/\D+/g, '')
      ?.replace(/(\d{2})(\d)/, '$1.$2')
      ?.replace(/(\d{3})(\d)/, '$1.$2')
      ?.replace(/(\d{3})(\d)/, '$1/$2')
      ?.replace(/(\d{4})(\d)/, '$1-$2')
      ?.replace(/(-\d{2})\d+?$/, '$1')
  },

  phone (value) {
    return value
      .replace(/\D+/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
      .replace(/(-\d{4})\d+?$/, '$1')
  },

  phoneDDI (value) {
    return value
      .replace(/\D+/g, '')
      .replace(/(\d{2})(\d)/, '+$1 $2')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
      .replace(/(-\d{4})\d+?$/, '$1')
  },

  cep (value) {
    return value
      .replace(/\D+/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
  },

  money(value) {
    if(value?.length > 0) {
      const isNegative = value?.charAt(0) === '-';
      const cleanValue = +value?.replace(/\D+/g, '');
      const options = {style: 'currency', currency: 'BRL'};
      const formattedValue = new Intl.NumberFormat('pt-br', options)?.format(cleanValue / 100);

      if (isNegative) {
        return '-' + formattedValue;
      } else if (value?.includes('-')) {
        return '-' + formattedValue;
      } else {
        return formattedValue;
      }
    }
  },

  formatMoneyString(str) {
    if (!str) return '';

    const isNegative = str?.charAt(0) === '-';
    let cleanStr = str?.replace(/[^\d.-]/g, '');

    if (isNegative) {
      cleanStr = '-' + cleanStr?.slice(1)?.replace(/-/g, '');
    } else if (cleanStr?.slice(-1) === '-') {
      cleanStr = '-' + cleanStr?.slice(0, -1)?.replace(/-/g, '');
    } else {
      cleanStr = cleanStr?.replace(/-/g, '');
    }

    return cleanStr;
  },

  cpfOrCnpj(param) {
    const value = param?.toString()?.replace(/\D+/g, "") ?? '';

    if (value.length <= 11) {
      return masks?.cpf(value);
    } else {
      return masks?.cnpj(value);
    }
  },

  cpfOrCnpjText(param) {
    const value = param?.toString() ?? '';

    if (value.length <= 11) {
      return masks?.cpf(value);
    } else {
      return masks?.cnpj(value);
    }
  },



  date (value) {
    return value
      .replace(/\D+/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\/\d{2})(\d)/, '$1/$2')
      .replace(/(\/\d{4})\d+?$/, '$1')
  },

  dateWithDashes (value) {
    return value
      .replace(/\D+/g, '')
      .replace(/(\d{2})(\d)/, '$1-$2')
      .replace(/(-\d{2})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')
  },
}

export default masks