import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import {
    Container,
    Grid,
    Card,
    CardContent,
    Box,
    Skeleton,
    Typography,
    Divider,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody, Button,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { api } from '../../../services/Main/Api';
import logo from '../../../assets/images/logo-sem-fundo.png'
import moment from 'moment'
import NumberFormatB3 from '../../../components/NumberFormat/NumberFormat';
import InternalNotesCard from '../../../components/Shared/InternalNotesCard';
import { Helmet } from 'react-helmet';
import DragAndDrop from "../../../components/DragAndDrop/DragAndDrop";
import SimpleButtons from "../../../components/Shared/SimpleButtons";
import Context from "../../../contexts/Context";
import {getDocuments} from "./Requisitions";
import CardFiles from "./components/ExpenseCreateUpdate/ExpenseDocuments/dependencies/CardFiles";
import AnimationGlobal from "../../../components/Shared/animations/AnimationGlobal";
import loading from "../../../assets/animations/loading.json"
import {Add} from "@mui/icons-material";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CreateDuplicateExpense from "./CreateDuplicateExpense";

const ExpenseDetails = () => {
  const params = useParams()
  const {
    userFiles,
    deleteApiFiles,
    setDeleteApiFiles,
    setDocuments,
  } = useContext(Context)

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(null);
  const [loadingBtn , setLoadingBtn] = useState(false)
  const [dropLoad, setDropLoad] = useState(true)
  const [sending, setSending] = useState(false)
  const [open, setOpen] = useState(false)




   const submitDocuments = async () => {
    setLoadingBtn(true)
    setSending(true)
    setDropLoad(true)
    userFiles?.map(file =>
      api.post('expense/document/',
        {
          expense: params?.id,
          file: file,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(r => {
      }).catch(error => {
        console.log(error.response.data)
      }).finally(() =>{
        setLoadingBtn(false)
      })
    )

    deleteApiFiles?.length > 0 &&
    deleteApiFiles?.map(file =>
      api.delete(`expense/document/${file.id}`,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(r => {
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        setLoadingBtn(false)
      })
    )
    setDeleteApiFiles([])
     setTimeout(() => {
       setSending(false)
     }, 500)
   }

  useEffect(function whenPageLoad() {
    setIsLoading(true)
    getDocuments(setDocuments, params, setDropLoad)
    api.get(`expense/${params?.id}`)
      .then(response => {
        setData(response.data);
      })
    setIsLoading(false)

  }, [params?.id, loadingBtn])


  return (
    <>
      <Helmet>
        <title>Detalhes da Despesa - Bloco B3</title>
      </Helmet>
      <Container fixed>
        {!isLoading ? <Card>

          <CardContent>
            <Grid container spacing={2}  justifyContent={"space-between"} alignContent={"center"}>
              <Grid item xs={12} lg={6} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', }}>
                <img src={logo} alt="" style={{height: 50}} />
              </Grid>
              <Grid item xs={12} lg={6} >
                <Box border sx={{display: 'flex', justifyContent: 'end', alignItems: 'center', alignContent: "center", marginTop: "1rem"}}>

                    <CreateDuplicateExpense
                        open={open}
                        params={params}
                        handleClose={() => setOpen(false)}
                    />
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ marginY: "2rem" }} />

            <Grid
              sx={{paddingX: "2rem"}}
              container spacing={2}
              justifyContent={"space-between"}
              alignContent={"center"}
              >
              <Grid item xs={12} lg={6}>
                { data ? <>
                <Typography variant="body2" mb>
                  <strong>
                    {'Categoria: '}
                  </strong>
                  {data?.category.value}
                </Typography>
                <Typography variant="body2" mb>
                  <strong>
                    {'Criada em: '}
                  </strong>
                  {moment(data?.created_at).format('DD/MM/YYYY')}
                </Typography>
                <Typography variant="body2" mb>
                  <strong>
                  {'Atualizada em: '}
                  </strong>
                  {moment(data?.updated_at).format('DD/MM/YYYY')}
                </Typography>
                <Typography variant="body2" mb>
                  <strong>
                  {'Mês de Pagamento: '}
                  </strong>
                  {data?.billing_month}
                </Typography>
                <Typography variant="body2"mb>
                  <strong>
                  {'Ano de Referência: '}
                  </strong>
                  {data?.billing_year}
                </Typography>
                  </>
                  :<>
                  <Skeleton variant="text" animation="pulse" width={200} height={20}/>
                  <Skeleton variant="text" animation="pulse" width={200} height={20}/>
                  <Skeleton variant="text" animation="pulse" width={200} height={20}/>
                  <Skeleton variant="text" animation="pulse" width={200} height={20}/>
                  <Skeleton variant="text" animation="pulse" width={200} height={20}/>
                  </>}

              </Grid>

              <Grid item xs={12} lg={6}>
              {data? <>
              <Typography variant="body2" mb>
                  <strong>
                  {'Data de Vencimento: '}
                  </strong>
                  {moment(data?.due_date).format('DD/MM/YYYY')}
                  </Typography>
                <Typography variant="body2" mb>
                  <strong>
                  {'Tipo de Divisão: '}
                  </strong>
                  {data?.division_pattern.value}
                </Typography>
                <Typography variant="body2" mb>
                  <strong>
                  {'Parcelas: '}
                  </strong>
                  {data?.installments ?? 'Não há parcelamento'}
                </Typography>
                <Typography variant="body2" mb>
                  <strong>
                  {'Imóvel: '}
                  </strong>
                  {data?.unit ?? 'Não especificado'}
                </Typography>
                <Typography variant="body2" mb>
                  <strong>
                  {'Bloco: '}
                  </strong>
                  {data?.block ?? 'Não especificado'}
                </Typography>
                </>
                :<>
                  <Skeleton variant="text" animation="pulse" width={200} height={20}/>
                  <Skeleton variant="text" animation="pulse" width={200} height={20}/>
                  <Skeleton variant="text" animation="pulse" width={200} height={20}/>
                  <Skeleton variant="text" animation="pulse" width={200} height={20}/>
                  <Skeleton variant="text" animation="pulse" width={200} height={20}/>
                  </>
                  }


              </Grid>

            </Grid>
            <TableContainer sx={{padding: "2rem"}}>
            <Table sx={{  marginY: "2rem" }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  { data?.description &&
                  <TableCell align="left" sx={{ color: "gray", paddingLeft: 0}}>Descrição</TableCell>
                  }
                  <TableCell align="right" sx={{ color: "gray"}}>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.description &&
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                  >
                    <TableCell component="th" scope="row"
                    sx={{display: 'flex', justifyContent: 'left'}}>
                        {data?.description}
                    </TableCell>
                    <TableCell align="center" sx={{display: 'flex', justifyContent: 'end'}}>
                    </TableCell>
                  </TableRow>
                }
                <TableRow>

                  <TableCell align="right" colSpan="2" >
                    <Box sx={{display: 'flex', justifyContent: 'end'}}>
                    <Typography variant={"h6"}><NumberFormatB3 value={data?.total}/></Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            </TableContainer>


            {/*<DragAndDrop*/}
            {/*  dropText={'Arraste e adicione os documentos ou clique para selecionar'}*/}
            {/*  deletable={true}*/}
            {/*  drop={true}*/}
            {/*/>*/}
            {/*<Box*/}
            {/*  sx={{*/}
            {/*    display: 'flex',*/}
            {/*    flexDirection: 'column',*/}
            {/*    justifyContent: 'center',*/}
            {/*    alignItems: 'center',*/}
            {/*    width: '100%'*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <SimpleButtons*/}
            {/*    noBack*/}
            {/*    loadingButton*/}
            {/*    loadingText={'Salvar'}*/}
            {/*    loading={loadingBtn}*/}
            {/*    loadDisable={userFiles.length <= 0 && deleteApiFiles.length <= 0}*/}
            {/*    onLoadBtn={(e) =>*/}
            {/*      submitDocuments(e)*/}
            {/*    }*/}
            {/*  />*/}
            {/*</Box>*/}
          </CardContent>
        </Card> : 'test'}
        {data ?
        <CardFiles>
        { !sending  ?
          <>
            <DragAndDrop
              dropText={'Arraste e adicione os documentos ou clique para selecionar'}
              deletable={true}
              drop={true}

            />
            <SimpleButtons
              noBack
              loadingButton
              justifyContent={'center'}
              loadingText={'Salvar'}
              loading={loadingBtn}
              loadDisable={userFiles.length <= 0 && deleteApiFiles.length <= 0}
              variantLoading={'contained'}
              onLoadBtn={(e) =>
                submitDocuments(e)
            }
            />
          </>
          :
          <AnimationGlobal
            animation={loading}
            size={'250px'}
            loop={true}
          />
          }
        </CardFiles>
          :
          <Skeleton
            height={390}
            width={'100%'}
            variant='rounded'
            sx={{mt: '2rem'}}
          />
        }
      <InternalNotesCard state={data?.internal_notes}/>
      <SimpleButtons />
      </Container>
    </>
  )
}

export default ExpenseDetails