import {Avatar, Box, FormControlLabel, Skeleton, Switch} from "@mui/material";
import {AttachMoney} from "@mui/icons-material";

const TransferNewAcc = ({ check, setCheck  }) => {
  return (
    <Box my={2}>
      <FormControlLabel
        control={
          <Switch
            checked={check}
            onChange={() => setCheck((prevState) => (!prevState))}/>}
        label="Transferir para nova conta"
      />
    </Box>
  )
}

export default TransferNewAcc