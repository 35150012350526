import {Box, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import Lottie from "lottie-react";
import warning from "../../assets/animations/warning.json";
import React from "react";

const NotAllowed = () => {
  return (
    <Box
      sx={{alignItems: 'center', display: 'flex', justifyContent: 'center', margin: '1rem', flexDirection: 'column'}}
    >
      <Typography
        align={'center'}
        sx={{
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontSize: '24px',
          fontWeight: 'bold',
          color: grey[400],
        }}
      >
        Não é possível utilizar datas passadas
      </Typography>
      <Box
        sx={{
          width: '300px'
        }}
      >
        <Lottie width="50px" animationData={warning} loop={false} />
      </Box>
    </Box>
  )
}

export default NotAllowed