import InvoiceDetailSenter from "./dependencies/InvoiceDetailSenter";
import InvoiceDetailAddressee from "./dependencies/InvoiceDetailAddressee";
import { Box } from "@mui/material";
import React from "react";
import { Forward } from "@mui/icons-material";

const InvoiceDetailBody = ({ condo, invoice }) => {
  return (
    <Box
      spacing={2}
      alignContent={'center'}
      sx={{
        display: 'flex',
        paddingX: '2rem',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <InvoiceDetailSenter
        condo={condo}
      />
      <Forward
        sx={{
          fontSize: '50px',
          m: '1rem'
        }}
      />

      <InvoiceDetailAddressee
        condo={condo}
        invoice={invoice}
      />
    </Box>
  )
}

export default InvoiceDetailBody