import {Autocomplete, Grid, IconButton, InputAdornment, Skeleton, TextField} from "@mui/material";
import {Search, SearchOff} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {api} from "../../../../../services/Main/Api";

const AutoCompleteJobs = ({ data, setData, isLoading, jobs }) => {
  return (
    <Grid
      item
      xs={12}
      lg={6}
    >
      {!isLoading ?
        <Autocomplete
          clearIcon={<SearchOff color="primary"/>}
          size={'small'}
          fullWidth
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          disableClearable
          onChange={(_event, value) => setData((prevState) => ({
            ...prevState,
            profession: value
          }))}
          value={(typeof data?.profession === 'number' ? jobs?.find((e) => (e?.id === data?.profession))?.name : data?.profession) ?? ''}
          options={jobs?.map((option) => option?.name ?? '') ?? ['']}
          renderInput={(params) => <TextField
            {...params}
            label="Selecionar Profissão"
            InputProps={{
              ...params.InputProps,
              endAdornment:
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => {
                      setData((prevState) => ({...prevState, profession: ''}))
                    }}
                    edge="end"
                    disabled={!!!data?.profession}
                  >
                    {!data?.profession ? <Search/> : <SearchOff color="primary"/>}
                  </IconButton>
                </InputAdornment>
            }}
          />}
        />
        :
        <Skeleton
          variant="rounded"
          animation="pulse"
          height={39.964}
        />
      }
    </Grid>
  )
}

export default AutoCompleteJobs