import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Skeleton,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import {
  History,
  Check,
  Update,
  Delete,
  Close,
  PriorityHigh,
  QuestionMark,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import moment from "moment";
import React, { useState } from "react";
import ShowMoreText from "react-show-more-text";
import { timelineItemClasses } from '@mui/lab/TimelineItem';


const InternalNotesCard = ({ state }) => {
  const [showMore, setShowMore] = useState(false);

  function backColor (tag){
    switch (tag) {
      case "success":
        return "#61bf33"
      case "info":
        return "#7f90a4"
      case "updated":
        return "#009cdf"
      case "warning":
        return "#e96b1a"
      case "deleted":
        return "#d7d7d7"
      default:
        return "#df1919"
    }
  }


  return (
    <>
      <Box>
        <Card
          sx={{
            mt: "1rem",
            width: "100%",
          }}
        >
          {state?.length > 0 ? (
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Typography variant="h5" display="flex" alignItems="center">
                    <History
                      sx={{
                        mr: 1,
                        fontSize: 25,
                        color: "primary.main",
                      }}
                    />
                    Histórico
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ marginY: "2rem" }} />

              <Timeline  sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}>
                {state?.map((e, index) => (
                  <TimelineItem key={index}>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          backgroundColor: backColor(e.tag),
                          margin: 0
                        }}
                      >
                        {e.tag === "success" ? (
                          <Check />
                        ) : e.tag === "info" ? (
                          <QuestionMark />
                        ) : e.tag === "updated" ? (
                          <Update />
                        ) : e.tag === "warning" ? (
                          <PriorityHigh />
                        ) : e.tag === "deleted" ? (
                          <Delete />
                        ) : (
                          <Close />
                        )}
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{
                        // py: "12px",
                        // px: 2,
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "flex-start",
                        justifyContent: 'start',
                        paddingY: 0

                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                          paddingY: 0
                        }}
                      >
                        <Typography fontWeight="bold" component="span">
                          {e.user ?? "Movimentação Interna"}
                        </Typography>
                        {/*<Tooltip*/}
                        {/*  placement="right"*/}
                        {/*  arrow*/}
                        {/*  title={*/}
                        {/*    <Typography>*/}
                        {/*      {moment(e?.created_at).format("L - LT")}*/}
                        {/*    </Typography>*/}
                        {/*  }*/}
                        {/*>*/}
                          <Typography fontWeight="bold">
                            {moment(e?.created_at).format("L - LT")}
                          </Typography>
                        {/*</Tooltip>*/}
                      </Box>
                      <ShowMoreText
                        lines={1}
                        more={<ExpandMore />}
                        less={<ExpandLess color="primary" />}
                        onClick={() => setShowMore(true)}
                        expanded={showMore}
                        width={0}
                      >
                        {e.message}
                      </ShowMoreText>


                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </CardContent>
          ) : (
            <Skeleton
              variant="rounded"
              height={600}
              animation="pulse"
            />
          )}
        </Card>
      </Box>
    </>
  );
};

export default InternalNotesCard;
