import React, {
  useContext,
  useEffect,
  useState
} from "react";
import {
  Box,
  Card,
  Container,
  Pagination,
  Skeleton,
  Table,
  TableContainer,
} from '@mui/material'
import {getCondoId} from "../../../services/Main/AuthStorage";
import { Helmet } from 'react-helmet'
import {AddCircleOutlined} from "@mui/icons-material";
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import SearchField from "../../../components/Shared/SearchField";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import TableBillHead from "./components/TableBillHead";
import TableBillBody from "./components/TableBillBody";
import {api} from "../../../services/Main/Api";
import Context from "../../../contexts/Context";
import BillboardForm from "./BillboardForm";

const BillboardList = () => {
  const { dataProvider, setDataProvider } = useContext(Context)
  const [data, setData] = useState(null)
  const [filters, setFilters] = useState({
    condo_id: getCondoId(),
    page_size: 50
  })

  async function getBills() {
    try {
      if (filters) {
        const response = await api.get('/billboard/', { params: filters });
        setData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handlePage (e, value){
    setFilters({ ...filters, page: value })
  }


  useEffect(function listLoad(){
    getBills()
  }, [filters, dataProvider?.openBill])


  return (
    <>
      <Helmet>
        <title>Avisos - Bloco B3</title>
      </Helmet>

      <Container
        maxWidth={false}
      >
        <TitleBtnList
          title={'Avisos'}
          buttonText={'Registrar Aviso'}
          onClick={()=> setDataProvider(prevState => ({ ...prevState, openBill: true}))}
          icon={<AddCircleOutlined />}
        />
        <Card>
          {data ?
            <>

          <SearchField
            label={"Buscar aviso"}
            state={filters}
            setState={setFilters}
            keyName={'search'}
            boxStyles={{
              p: 2,
              maxWidth: '400px'
            }}
          />
              <TableContainer sx={{p: 2}}>
                { data?.results?.length > 0 ?
                  <Table sx={{ minWidth: 800 }}>
                    <TableBillHead />
                    <TableBillBody
                      state={data}
                      setDataProvider={setDataProvider}
                    />
                  </Table>
                  :
                  <NoResultsAnimation />
                }
              </TableContainer>
            </>
            :
              <Skeleton
                variant="rounded"
                height={700}
                animation="pulse"
              />
          }
        </Card>
        {
          data?.count > 0 &&
          <Box spacing={2} display={'flex'} justifyContent={'center'} sx={{ m: 3 }}>
            <Pagination
              count={Math.ceil(+data?.count / +filters.page_size)}
              variant={'outlined'}
              color={'primary'}
              onChange={handlePage}
            />
          </Box>
        }
      </Container>
      <BillboardForm />

    </>
  )
}

export default BillboardList