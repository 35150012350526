import React, {
  useContext,
  useEffect,
  useState
} from "react";
import {
  Add,
  PriorityHigh,
  Public
} from "@mui/icons-material";
import moment from 'moment/moment'
import {api} from "../../../services/Main/Api";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {useSnackbar} from "notistack";
import Context from '../../../contexts/Context'
import Title from "../../../components/BookingAndMaintenance/Title";
import DateAndTime from "../../../components/BookingAndMaintenance/DateAndTime";
import AutoCompleteGlobal from "../../../components/Shared/AutoCompleteGlobal";
import Description from "../../../components/BookingAndMaintenance/Description";
import NotAllowed from "../../../components/BookingAndMaintenance/NotAllowed";
import UnitFilter from "../../../components/Filters/UnitFilter";
import SimpleDialog from "../../../components/Shared/SimpleDialog";


const BookingCreate = () => {
  const {createEvent, setCreateEvent, clickDate, setRefresh} = useContext(Context)
  const {enqueueSnackbar} = useSnackbar()

  const [data, setData] = useState(null)
  const [timePickerStart, setTimePickerStart] = useState(moment().locale('pt-br').format("LT"))
  const [timePickerEnd, setTimePickerEnd] = useState(moment().locale('pt-br').format("LT"))
  const [bookingArea, setBookingArea] = useState(null)
  const [error, setError] = useState(null)

  const today = moment(new Date()).subtract(1, "days").format()
  const clickedDate = moment(new Date (clickDate)).format()


  function initialState (){
    setData({
      date_start: moment(new Date(clickDate)).format(),
      date_end: moment(new Date(clickDate)).format()
    })
    setError(null)
    setBookingArea(null)
  }

  function initialDate (){
    const formStartDate = moment(data?.date_start).format('YYYY-MM-DD')
    const setStartDate = formStartDate + ' ' + timePickerStart
    return moment(setStartDate).format()
  }

  function endDate (){
    const formEndDate = moment(data?.date_end).format('YYYY-MM-DD')
    const setEndDate = formEndDate + ' ' + timePickerEnd
    return moment(setEndDate).format()
  }


  async function getBookingArea() {
    try {
      if (createEvent) {
        const response = await api.get(`/booking/areas/?condo_id=${getCondoId()}`);
        setBookingArea(response.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSubmit (e){
    e.preventDefault();

    try {
      const reqData = {
        ...data,
        condo: getCondoId(),
        date_start: moment.utc(new Date(data?.date_start)).format(),
        date_end: moment.utc(new Date(data?.date_end)).format()
      };

      await api.post('/booking/', reqData);

      enqueueSnackbar("Reserva Criada com Sucesso!", { variant: "success" });
      setRefresh((prevState) => !prevState);
      initialState();
      setCreateEvent(false);
    } catch (error) {
      enqueueSnackbar(error?.response?.data[0], { variant: "error" });
      console.log(error?.response?.data);
      setError(error?.response?.data);
    } finally {
      setData({ date_start: moment(new Date(clickDate)).format(), date_end: moment(clickDate).format() });
    }
  }


  useEffect(function whenCreateEvent(){
    getBookingArea()
  }, [createEvent])

  useEffect(function whenClockChange(){
    setData(prevState => ({...prevState, date_end: endDate(), date_start: initialDate() }))
  }, [data?.date_end, timePickerEnd])

  useEffect(function whenClickDate () {
    setData(
      {
        date_start: moment(new Date(clickDate)).format(),
        date_end: moment(new Date(clickDate)).format()
      })
    setTimePickerStart(moment().locale('pt-br').format("LT"))
    setTimePickerEnd(moment().locale('pt-br').format("LT"))
  }, [clickDate])

  return (
    <>
      <SimpleDialog
        title={clickedDate > today ? 'Criar Reserva' : 'Erro'}
        titleIcon={clickedDate > today ? <Add/> : <PriorityHigh/>}
        open={createEvent ?? false}
        setOpen={setCreateEvent}
        confirmText={clickedDate > today && 'Criar'}
        onClickConfirm={clickedDate > today && handleSubmit}
        removeConfirm={clickedDate < today}
        onClickCancel={() => {
          initialState()
          setCreateEvent(false)
        }}
        dialogContent={
          <>
            {clickedDate > today ?
              <>
                <Title
                  state={data}
                  setState={setData}
                  keyName={'name'}
                  error={error}
                  styles={{mb: '1rem'}}
                />

                <DateAndTime
                  labelDate={'Data de ínicio'}
                  labelTime={'Horário de ínicio'}
                  disabled={true}
                  setState={setData}
                  valueFunction={initialDate}
                  timePicker={setTimePickerStart}
                  styles={{mb: '1rem'}}
                  keyName={'date_start'}
                />

                <DateAndTime
                  labelDate={'Data de Termino'}
                  labelTime={'Horário de termino'}
                  setState={setData}
                  valueFunction={endDate}
                  timePicker={setTimePickerEnd}
                  keyName={'date_end'}
                />

                <AutoCompleteGlobal
                  label={'Áreas Comuns'}
                  state={data}
                  setState={setData}
                  error={error}
                  keyName={'areas_booking'}
                  options={bookingArea}
                  optioMapName={'name'}
                  icon={<Public sx={{ml: '0.6rem'}}/>}
                  styles={{mb: '1rem'}}
                />

                <UnitFilter
                  state={data}
                  setState={setData}
                  keyName={'unit'}
                  styles={{
                    mb: '1rem'
                  }}
                />

                <Description
                  label={'Observações'}
                  state={data}
                  setState={setData}
                  error={error}
                  keyName={'notes'}
                />
              </>
              :
              <NotAllowed/>
            }
          </>
        }
        />
      </>



  )
}


export default BookingCreate;
