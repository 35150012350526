import React, { useContext, useEffect, useState } from 'react'
import {
  Backdrop,
  Box, Button,
  Card, CardActions, CardHeader, Checkbox,
  Chip,
  Container, Divider, FormControl,
  Grid, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField,
  Tooltip,
  Typography,
  Link,
  IconButton, GlobalStyles,
} from '@mui/material'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { api } from '../../../services/Main/Api'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import CircularProgress from '@mui/material/CircularProgress'
import Context from '../../../contexts/Context'

import NoResultsAnimation from '../../../components/Shared/animations/NoResultsAnimation'
import {Alarm, OpenInNew, Search} from '@mui/icons-material'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Masks from '../../../components/Shared/Masks'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import {getCondoId} from "../../../services/Main/AuthStorage";
import DialogContent from '@mui/material/DialogContent'
import RentalChargePayment from './RentalChargePayment'
import Dialog from '@mui/material/Dialog'
import DialogActions from "@mui/material/DialogActions";
import AuthValidation from "../../../components/Shared/AuthValidation";
import DialogTitle from "@mui/material/DialogTitle";
import {MobileDatePicker} from "@mui/x-date-pickers";
import {hover} from "@testing-library/user-event/dist/hover";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const RentalChargesList = () => {
  const navigate = useNavigate()
  const [response, setResponse] = useState(null)
  const [monthInput, setMonthInput] = useState(moment().format("MM"))
  const [yearInput, setYearInput] = useState(moment().format("YYYY"))
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState(null)
  const [category, setCategory] = useState([])
  const [categorySelected, setCategorySelected] = useState([])
  const [open, setOpen] = useState(false);
  const [selectedCharge, setSelectedCharge] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [filters, setFilters] = useState(
      {
        condo_id: getCondoId(),
        page: 1,
        page_size: 50,
      }
  )
  const monthFormat = moment().format("MM")
  const yearFormat = moment().format("YYYY")
  const [showAuthValidation, setShowAuthValidation] = useState(false);
  const { sessionCheck, dataProvider } = useContext(Context)
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogItem, setDialogItem] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
    const [rowHovers, setRowHovers] = React.useState({});
    const { user } = useContext(Context)
    const { perm_bank } = user || {}

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleOpen = () => setOpen(true);

  useEffect(() => {
    if (dataProvider?.validation){
      handleOpen(setOpen(true))
    }

  }, [ dataProvider?.validation])

  const handleClick = () => {
    setShowAuthValidation(true);
    !dataProvider?.validation && sessionCheck();

    dataProvider?.validation && handleOpen();

  };

  const handleClose = () => {
    setIsLoading(true);
    setOpen(false);
    setShowAuthValidation(false)
    setIsLoading(false);
  }

  const handleDialogDateOpen = (item) => {
    setDialogItem(item);
    setOpenDialog(true);
  };  const handleDialogDateClose = () => setOpenDialog(false);


  useEffect(()=>{
    let params = { ...searchParams, "month": monthInput, "year": yearInput,}
    if (search){
      params.search = search
    }else{
      delete params.search
    }

    if (categorySelected){
     params.category = categorySelected
    }else{
      delete params.category
    }


    setSearchParams(params)
  }, [monthInput, yearInput, search, categorySelected])

  function getData(){
    setLoading(true)
    let month = monthFormat
    let year = yearFormat

    if (searchParams.get("month") && searchParams.get("year") ){
      month = searchParams.get("month")
      year = searchParams.get("year")
    }

    let filter = {...filters, month:month, year:year}
    if (searchParams.get("rent_contract_id")){
      filter = {...filter, rent_contract_id: searchParams.get("rent_contract_id")}
    }

    if (searchParams.get("search")){
      filter = {...filter, search: searchParams.get("search")}
    }

    api.get(`rent-contract/payment/`, {params: filter})
      .then(response => {
        const data = response.data
        setResponse(data)
        setLoading(false)
      }).catch(error => {
        console.log(error)
        setLoading(false)
    })
  }

  function getDataCategory(){

    let month = monthFormat
    let year = yearFormat

    if (searchParams.get("month") && searchParams.get("year") ){
      month = searchParams.get("month")
      year = searchParams.get("year")
    }else{
      month = moment().format('MM')
      year = moment().format('YYYY')
    }

    let filter = {...filters, month:month, year:year}

    if (searchParams.get("category")){
        filter = {...filter, category}
    }

    api.put(`rent-contract/payment/`, {}, {params: filter})
      .then(response => {
        const data = response.data

        setCategory(data)

      }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  function getChargeDetails(chargeId) {
    setLoading(true);
    api.get(`rent-contract/payment/${chargeId}`)
        .then(response => {
          setSelectedCharge(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
        });
  }

  const UpdateChargeDueDateChange = () => {
    api.put(`rent-contract/payment/${dialogItem.id}`, {
      charge_due_date: moment(selectedDate).format('YYYY-MM-DD'),
    })
        .then((resp) => {
            console.log(resp, "1");
            const updatedItemIndex = response.results.findIndex(item => item.id === dialogItem.id);
            if (updatedItemIndex !== -1) {
                const updatedItem = {...response.results[updatedItemIndex]};
                updatedItem.charge_due_date = resp.data.charge_due_date;
                const newResponse = {...response};
                newResponse.results[updatedItemIndex] = updatedItem;
                setResponse(newResponse);
                handleDialogDateClose()
            }

        })
        .catch((error) => {

          console.error(error);
        });
  };

  useEffect(() => {
    if (searchParams.get("month") && searchParams.get("year") ){
      setMonthInput(searchParams.get("month"))
      setYearInput(searchParams.get("year"))
    }else {
      getData()

    }
  }, [])

  useEffect(() => {
    getDataCategory()
  }, [])

  useEffect(() => {
    getData()

  }, [searchParams])

  useEffect(function whenPageLoad (){
        getData()
  },[filters])


  console.log(dialogItem, 'dialogItem')
  console.log(filters, 'filters')

  let typingTimer  = ""

  return (
    <>
      {isLoading && (
          <CircularProgress />
      )}
      <Helmet>
        <title>Gestão de Pagamentos - Bloco B3</title>
      </Helmet>

      <Backdrop
        sx={{ color: '#fff', zIndex: 1500, }}
        open={loading}
      >

        <CircularProgress color="inherit"/>
      </Backdrop>
      <GlobalStyles styles={{
        '.MuiInputBase-input': {
          textAlign: 'center',
        }
      }}
      />
      <Container maxWidth={false}>
        <Box
          sx={{
            display: 'flex',
            '@media (max-width:600px)': {
              display: 'grid',
              gridTemplateColumns: 'repeat(1, 1fr)',
              gap: 3,
            },
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,

          }}
        >
          <Typography
            textAlign={'center'}
            variant={'h5'}
          >
            Encargos Locatícios ({response?.count || 0})
          </Typography>

        </Box>


        <Card>
          <CardActions  sx={{ display:'flex', justifyContent: 'end'}}>
            <Box >

              <TextField id="outlined-basic" label="Buscar por Locatário" variant="outlined" sx={{marginRight: ".5rem"}}
                         onKeyUp={(event)=>{
                           clearTimeout(typingTimer);
                           typingTimer = setTimeout(()=> setSearch(event.target.value), 1000);
                         }}
                         size={'small'}
                         InputProps={{
                           endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,
                         }}
              />
              <FormControl sx={{  width: 200, marginRight: 1 }}>
                <InputLabel id="demo-multiple-checkbox-label">Categoria</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={categorySelected}
                  onChange={(e)=>{

                    setCategorySelected(e.target.value)
                  }}
                  input={<OutlinedInput label="Categoria" size={'small'} />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                  size={'small'}
                >
                  {category.map((item, index) => (
                    <MenuItem key={index} value={item.key}>
                      <Checkbox checked={categorySelected.indexOf(item.key) > -1} />
                      <ListItemText primary={item.value} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <DatePicker
                openTo="month"
                views={['year', 'month']}
                label="Mês de Pagamento"
                inputProps={{ readOnly: true }}
                disableMaskedInput

                value={moment(`${monthInput}-${yearInput}`, 'MM-YYYY').toDate()}
                onChange={(newValue) => {
                  setMonthInput(moment(new Date(newValue)).format("MM"))
                  setYearInput(moment(new Date(newValue)).format("YYYY"))
                }}
                slotProps={{
                    field: { clearable: false, onClear: () => {} },
                    textField: {
                        size: "small"
                    }
                }}
              />
            </Box>
          </CardActions>


          {response?.results ?
            <>
              <AuthValidation />
              <TableContainer>
                {response?.results?.length > 0  ?
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Categoria</TableCell>
                        <TableCell>Contrato</TableCell>
                        <TableCell align={'center'}>Vencimento</TableCell>
                        <TableCell>Pagamento</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Valor</TableCell>
                        <TableCell></TableCell>


                    </TableRow>
                    </TableHead>
                    <TableBody>


                    { response.results.map(item =>
                      <TableRow
                        key={item.id}
                        hover
                        sx={{'cursor': 'pointer'}}
                        onMouseEnter={() => setRowHovers(prevState => ({ ...prevState, [item.id]: true }))}
                        onMouseLeave={() => setRowHovers(prevState => ({ ...prevState, [item.id]: false }))}

                        // onClick={(e) => {
                        //   e.stopPropagation()
                        //   navigate(`/repasses/${item.id}`)
                        // }}
                      >
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {item.number}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {item.category === 'Outro' ? `${item.category} - ${item.description}` : item.category }
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {item.rent_contract}
                          </Box>
                        </TableCell>
                        <TableCell align={'center'}>
                          <Link align={'center'} onClick={() => handleDialogDateOpen(item)}
                                style={{
                                    color: 'primary.main',
                                    fontSize: (!item?.charge_due_date && !item?.payment_due_date) ? 'small': null
                          }}
                          >
                            {item?.charge_due_date ? moment(`${item?.charge_due_date}`).format('DD/MM/YYYY'):
                                item?.payment_due_date ? moment(`${item?.payment_due_date}-${item?.month}-${item?.year}`, 'DD-MM-YYYY').format('DD/MM/YYYY')
                                    : rowHovers[item.id]  ? "Adicionar data" : '_'}

                       </Link>
                        </TableCell>

                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                            color={item?.total < 0 && "error.main" }
                          >
                            {item.type_fee}

                          </Box>
                        </TableCell>

                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Chip
                                color={
                                  item.rental_charge_payment_data || paymentSuccess && item.id === selectedCharge.id
                                      ? item.rental_charge_payment_data?.status === 5
                                          ? "success"
                                          : "warning"
                                      : "info"
                                }
                                size="small"
                                variant="outlined"
                                label={
                                  item.rental_charge_payment_data || paymentSuccess && item.id === selectedCharge.id
                                      ? item.rental_charge_payment_data?.status === 5
                                          ? "Pagamento realizado"
                                          : "Processando pagamento"
                                      : " Pagamento em aberto"
                                }
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                          >
                            {Masks.money(item.value.toString())}
                          </Box>
                        </TableCell>
                          { perm_bank === true &&
                        <TableCell>
                          <Box
                              sx={{ display: 'flex', alignItems: 'center' }}
                              color={item?.total < 0 && "error.main" }
                          >
                            {
                              item.rental_charge_payment_data || paymentSuccess && item.id === selectedCharge.id ?
                                  <Link
                                      href={item.rental_charge_payment_data?.pdf_url}
                                      target="_blank"
                                      onClick={(e) => e.stopPropagation()}
                                  >
                                    <IconButton>
                                      <PictureAsPdfIcon />
                                    </IconButton>
                                  </Link>
                                  :
                                  <Button
                                      size={'small'}
                                      variant="outlined"
                                      style={{ textTransform: "none" }}
                                      onClick={() => {
                                        handleClick();
                                        getChargeDetails(item.id);
                                      }}
                                  >
                                    Efetuar Pagamento
                                  </Button>
                            }
                          </Box>
                        </TableCell>
                          }
                      </TableRow>
                    )}

                    </TableBody>
                  </Table>
                  :
                  <NoResultsAnimation />
                }
              </TableContainer>
            </>
            :
            <Skeleton
              variant="rounded"
              height={700}
              animation="pulse"
            />
          }
        </Card>
        {selectedCharge && (
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'md'}
            >

                <RentalChargePayment charge={selectedCharge} handleClose={handleClose} handleOpen={handleOpen}
                                     setPaymentSuccess={setPaymentSuccess} />

            </Dialog>
        )}
        <Dialog
            open={openDialog}
            onClose={handleDialogDateClose}
            fullWidth={true}
            maxWidth={'xs'}
        >
          <DialogTitle sx={{mb:2}}>Adicionar Data de Vencimento</DialogTitle>
          <DialogContent sx={{p:2}}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth >
                <MobileDatePicker

                    label="Data de pagamento"
                    value={selectedDate}
                    onChange={
                      handleDateChange
                    }
                    render
                    renderInput={(props) => (
                        <TextField
                            label="Data de vencimento"
                            inputProps={{
                              ...props.inputProps,
                              placeholder: "DD/MM/AAAA",
                              readOnly: true,

                            }}
                            required
                            size="small"
                            sx={{
                                mr: '0.5rem',
                                my: '0.5rem',
                            }}
                        />
                    )}
                />

              </FormControl>
            </Grid>
          </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', mx: 1 }}>
                <Button onClick={handleDialogDateClose}>Fechar</Button>
                <Button variant="contained" size={'small'} color={"primary"} onClick={UpdateChargeDueDateChange}>Salvar</Button>
            </DialogActions>
        </Dialog>
      </Container>
      {response?.count > 0 &&
        <PaginationGlobal
          state={response}
          filters={filters}
          setFilters={setFilters}
        />
      }
    </>
  )
}

export default RentalChargesList
