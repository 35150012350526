import React, {
  useContext,
  useEffect
} from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import {
  Delete,
  ReceiptLongOutlined
} from "@mui/icons-material";
import SimpleSelector from "../../../../../../components/Shared/SimpleSelector";
import UnitFilter from "../../../../../../components/Filters/UnitFilter";
import SimpleText from "../../../../../../components/Shared/SimpleText";
import GlobalSwitch from "../../../../../../components/Shared/GlobalSwitch";
import Context from "../../../../../../contexts/Context";
import { getCondoId } from "../../../../../../services/Main/AuthStorage";
import { useParams } from "react-router-dom";

const ExpenseInfo = ({
    reqData,
    setReqData,
    setShowGas,
    setGasWaterRequired,
    categoryOptions,
    title,
    errors,
    disabled
  }) => {

  const { getUnitOptions, unitOptions } = useContext(Context)
  const params = useParams()
  const divisionPatternOptions = {
    'UNIT_FRACTION': 'Fração por Imóvel', 'UNIT': 'Imóvel', 'EVENLY': 'Igualmente',
  }

  useEffect(function exemptUp(){
    if (reqData?.exempt_units?.length > 0){
      setReqData(prevState => ({...prevState, exempt: true }))
    }
  }, [reqData?.exempt_units])


  useEffect(function exemptArray() {
    if (reqData?.unitExempt) {
      setReqData(prevState => {
        // Check if reqData.unitExempt already exists in prevState.exempt_units
        const alreadyExists = prevState.exempt_units?.includes(reqData.unitExempt);

        if (!alreadyExists) {
          const updatedExemptUnits = prevState.exempt_units
            ? [...prevState.exempt_units, reqData.unitExempt]
            : [reqData.unitExempt];

          return { ...prevState, exempt_units: updatedExemptUnits };
        }

        return prevState; // No changes needed if the value already exists
      });
    }
  }, [reqData?.unitExempt]);


  useEffect(function divisionObserver(){
    if (reqData?.division_pattern !== 'UNIT'){
      setReqData(prevState => ({...prevState, unit: null}))
    }

  }, [reqData?.division_pattern])

  useEffect(function getOptions(){
    if(getCondoId()) {
      getUnitOptions()
    }
  },[])

  useEffect(function setGas(){
    if (reqData?.category === 'GAS' || reqData?.category === 'WATER_READING') {
      setShowGas(true)
      setGasWaterRequired(true)
    } else {
      setShowGas(false)
      setGasWaterRequired(false)
    }
  }, [reqData])

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader
          avatar={<Avatar sx={{ bgcolor: 'primary.main' }}><ReceiptLongOutlined/></Avatar>}
          title={title}/>
        <Divider sx={{ mb: '.3rem' }}/>
        <CardContent>

          <SimpleSelector
            label={'Divisão'}
            required
            state={reqData}
            setState={setReqData}
            keyName={'division_pattern'}
            options={Object.keys(divisionPatternOptions)}
            alternateOption={divisionPatternOptions}
            error={errors}
            styles={{
              mb: '1rem'
            }}
          />

          {reqData?.division_pattern === 'UNIT' && !params.id ?
            <UnitFilter
              label={'Imóvel'}
              state={reqData}
              setState={setReqData}
              errors={errors}
              keyName={'unit'}
              styles={{
                mb: '1rem'
              }}
            />
            : unitOptions?.find(e => e.value === reqData?.unit) && reqData?.division_pattern === 'UNIT' &&
            <UnitFilter
              label={'Imóvel'}
              disableClearable={true}
              state={reqData}
              setState={setReqData}
              errors={errors}
              keyName={'unit'}
              valueProp={unitOptions?.find(e => e.value === reqData?.unit)}
              styles={{
                mb: '1rem'
              }}
            />
          }

          <SimpleSelector
            disabled={disabled}
            label={'Categoria'}
            state={reqData}
            setState={setReqData}
            keyName={'category'}
            options={Object.keys(categoryOptions)}
            alternateOption={categoryOptions}
            error={errors}
            styles={{
              mb: '1rem'
            }}
          />

          <SimpleText
            label={'Descrição'}
            multiline
            rows={5}
            state={reqData}
            setState={setReqData}
            keyName={'description'}
            error={errors}
          />

          <GlobalSwitch
            state={reqData}
            setState={setReqData}
            keyName={'recurring_expense'}
            label={'Essa despesa é recorrente?'}
            helperText={'Caso essa opção seja marcada a despesa será gerada automaticamente todo mês'}
            styles={{
              mt: '1rem'
            }}
          />

          <GlobalSwitch
            state={reqData}
            setState={setReqData}
            keyName={'exempt'}
            label={'Deseja isentar alguma unidade dessa despesa?'}
            helperText={'Pode ser selecionado mais de uma unidade'}
            styles={{
              mt: '1rem',
              // mb: '1rem'
            }}
          />

          {reqData?.exempt &&
            <UnitFilter
              label={'Unidade para insentar'}
              state={reqData}
              setState={setReqData}
              errors={errors}
              keyName={'unitExempt'}
              styles={{
                mb: '1rem',
                mt: '1rem'
              }}
            />
          }


          {reqData?.exempt_units?.length > 0 && (
            <Box
              sx={{
                mt: '1rem',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  color: 'primary.main',
                }}
              >
                Unidades insentas
              </Typography>
              {reqData?.exempt_units?.map(exemptValue => {
                const correspondingOption = unitOptions?.find(option => option.value === exemptValue);

                const handleRemoveExempt = () => {
                  setReqData(prevState => ({
                    ...prevState,
                    exempt_units: prevState.exempt_units.filter(value => value !== exemptValue),
                  }));
                };

                return (
                  <Box
                    key={exemptValue}
                    sx={{
                      display: 'flex',
                      fontSize: '16px',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography>{correspondingOption?.label}</Typography>
                    <IconButton onClick={handleRemoveExempt}>
                      {<Delete />}
                    </IconButton>
                  </Box>
                );
              })}
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  )
}

export default ExpenseInfo