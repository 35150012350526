import {Box, Typography} from "@mui/material";
import {LocalAtmOutlined} from "@mui/icons-material";

const TransferTitle = ({ title }) => {
  return (
      <Box
        display={'flex'}
        alignItems={'center'}
        py={1}
        my={2}
        borderTop={'1px solid #eee'}
        borderBottom={'1px solid #eee'}>
        <LocalAtmOutlined
          fontSize={'small'}
          sx={{ mr: 1 }}
        />
        <Typography
          sx={{ py: 1 }}
          variant={'button'}
          component={'div'}>
          {title}
        </Typography>
      </Box>
  )
}

export default TransferTitle