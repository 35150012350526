import Masks from "../../../../../../../components/Shared/Masks";
import {InputAdornment, TextField} from "@mui/material";
import {AttachMoneyRounded} from "@mui/icons-material";
import React from "react";

const MaintenanceValue = ({state, setState, disabled}) => {
  return (
    <TextField
      label="Valor da Manutenção"
      size={"small"}
      disabled={disabled}
      fullWidth
      placeholder={'R$ 0,00' }
      sx={{marginBottom: '2rem'}}
      onChange={({ target: { value } })  => setState((prevState) => ({...prevState, maintenance_value: value}))}
      value={Masks.money(String(state?.maintenance_value ?? '')) ?? ''}
      InputProps={{
        startAdornment:
          <InputAdornment position="start">
            <AttachMoneyRounded />
          </InputAdornment>
      }}
    />
  )
}

export default MaintenanceValue