import {Box, Typography} from "@mui/material";
import React from "react";

const CommentTitle = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography
        variant={'h7'}
      >
        Usuário
      </Typography>
    </Box>
  )
}

export default CommentTitle