import {TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import React, {useContext} from "react";
import Context from "../../../contexts/Context";

const VisitorTableHead = ({ filters, setFilters }) => {

  const handleFilter = (asc: string, desc: string) => {
    setFilters(prevState => ({
        ...prevState,
        ordering: filters?.ordering === asc ? desc : asc
      })
    )
  }


  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-name' || filters?.ordering === 'name'}
            direction={filters?.ordering === '-name' ? 'desc' : 'asc'}
            onClick={() => handleFilter('name', '-name') }
          >
            Nome
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-entry_date' || filters?.ordering === 'entry_date'}
            direction={filters?.ordering === '-entry_date' ? 'desc' : 'asc'}
            onClick={() => handleFilter('entry_date', '-entry_date') }
          >
            Entrada
          </TableSortLabel>
        </TableCell>
        <TableCell
          // align={'left'}
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-unit_visitor' || filters?.ordering === 'unit_visitor'}
            direction={filters?.ordering === '-unit_visitor' ? 'desc' : 'asc'}
            onClick={() => handleFilter('unit_visitor', '-unit_visitor') }
          >
           Imóvel
          </TableSortLabel>
        </TableCell>
        <TableCell
          align={'center'}
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-authorized' || filters?.ordering === 'authorized'}
            direction={filters?.ordering === '-authorized' ? 'desc' : 'asc'}
            onClick={() => handleFilter('authorized', '-authorized') }
          >
            Situação
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default VisitorTableHead