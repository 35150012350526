import React from "react";
import { Typography } from "@mui/material";

const ComplaintReadContent = ({ dataUpdate }) => {
  return (
    <>
      <Typography
        sx={{
          margin: '1rem',
          wordBreak: 'break-all',
          padding: '1rem',
          fontWeight: '550'
        }}
        variant={'h5'}
      >

        {dataUpdate?.title}
      </Typography>
      <Typography
        sx={{
          margin: '1rem',
          wordBreak: 'break-all',
          backgroundColor: '#e6e6e6',
          borderRadius: '10px',
          padding: '1rem'
        }}
      >
        {dataUpdate?.message}
      </Typography>
    </>
  )
}

export default ComplaintReadContent