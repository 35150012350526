import React, {
  useEffect,
  useState,
  useContext
} from 'react'
import {
  Avatar,
  Backdrop,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Fade,
  Grid,
  ListItem,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  List,
  Link,
  Alert
} from '@mui/material'
import {
  gasWaterPreviousReading,
  gasWaterValue,
  getCategories,
} from "./Requisitions";
import {
  getCondoId,
  setFilterDate
} from '../../../services/Main/AuthStorage'
import {
  Check,
  ReceiptLongOutlined,
  Remove
} from "@mui/icons-material";
import Context from '../../../contexts/Context'
import { api } from '../../../services/Main/Api'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { Helmet } from 'react-helmet'
import GasConsume from "./components/ExpenseCreateUpdate/GasConsume/GasConsume";
import ExpenseInfo from "./components/ExpenseCreateUpdate/ExpenseInfo/ExpenseInfo";
import ExpensePayment from "./components/ExpenseCreateUpdate/ExpensePayment/ExpensePayment";
import ExpenseResume from "./components/ExpenseCreateUpdate/ExpenseResume/ExpenseResume";
import ExpenseDocuments from "./components/ExpenseCreateUpdate/ExpenseDocuments/ExpenseDocuments";
import Payment from "../Payment/Payment";
import BankTransfer from "../BankOperations/BankTransfer";
import ExpenseWizard from "./ExpenseWizard";
import ToggleButton from "@mui/material/ToggleButton";
import AnimationGlobal from "../../../components/Shared/animations/AnimationGlobal";
import successAnimation from "../../../assets/animations/success.json"
import WaterConsume from "./components/ExpenseCreateUpdate/WaterConsume/WaterConsume";

const ExpenseCreate = () => {
  const { userFiles, condoIdLogin, loadingModal, setLoadingModal, filters, setFilters, user } = useContext(Context)
  const { perm_bank } = user || {}
  const { enqueueSnackbar } = useSnackbar()

  const sizeMatch = useMediaQuery('@media (min-width:1000px)')

  const {
    operationType,
    activeStep,
    firstStep,
    secondStep,
    paymentDone,
    transferDone,
    receiptUrl
  } = filters || {}


  const [reqData, setReqData] = useState({
    condo: getCondoId(),
    billing_month: +moment().format('MM'),
    billing_year: +moment().format('YYYY'),
    previous_reading_date: moment().format('YYYY-MM-DD'),
    current_reading_date: moment().format('YYYY-MM-DD')
  })
  const [showGas, setShowGas] = useState(false)
  const [gasWaterRequired, setGasWaterRequired] = useState(false)
  const [errors, setErrors] = useState()
  const [installments, setInstallments] = useState(1)
  const [gasWaterTotalValue, setGasWaterTotalValue] = useState('')
  const [categoryList, setCategoryList] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [reqDataInstallments, setReqDataInstallments] = useState([])
  const [previousData, setPreviousData] = useState(null)

  function stepComplete() {
    return (
      <Card>
        <Fade
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={300}
        >
          <CardContent>
            <AnimationGlobal
              text={'Etapa concluída'}
              size={'200px'}
              animation={successAnimation}
              fontStyles={{mb: '1rem'}}
              fontSize={'20px'}
            />
          </CardContent>
        </Fade>
      </Card>
    );
  }

  function conclusionText () {
    const linkAcess = <Link href={receiptUrl} target='_blank' underline='none'>{`Clique aqui`}</Link>
    return secondStep && paymentDone && receiptUrl
      ? <>{linkAcess} {`para ver o recibo do pagamento agendado`}</>
      : secondStep && transferDone && receiptUrl
        ? <>{linkAcess} {`para ver o recibo da transferência agendada`}</>
        : `Opção de pagamento não utilizada`
  }

  function avatarType() {
    const color = secondStep && receiptUrl ? 'success' : 'warning';
    const icon = secondStep && receiptUrl ? <Check sx={{color: 'white', fontSize: '2rem'}} /> : <Remove sx={{color: 'white', fontSize: '2rem'}} />;

    return (
      <Avatar sx={{ backgroundColor: `${color}.main`, mr: '0.5rem' }}>
        {icon}
      </Avatar>
    )
  }

  const handleSubmit = () => {
    setErrors(null)
    const fullDate = moment(`${reqData?.billing_year}-${reqData?.billing_month}-01`, 'YYYY-MM-DD').format()
    setFilterDate(fullDate)
    setLoadingModal(true)

    const data = {
      ...reqData,
      total: reqData?.category !== 'DISCOUNT' ?
        reqData?.total?.toString()?.replace(/[^\d]+/g, "")
        : reqData?.total?.toString()?.replace(/[^\d]+/g, "")  * -1,
      previous_reading_amount: reqData?.previous_reading_amount?.toString().replace(/,/g,'.') || 0,
      current_reading_amount: reqData?.current_reading_amount?.toString().replace(/,/g,'.'),
      previous_reading_date: moment(reqData?.previous_reading_date).format('YYYY-MM-DD'),
      current_reading_date: moment(reqData?.current_reading_date).format('YYYY-MM-DD'),
      ...(!reqData?.exempt_units && {exempt_units: []}),
      condo: getCondoId()
    }

    let expenseUrl = 'expense/'
    if (reqData?.category === 'GAS') {
      expenseUrl = 'expense/gas/'
    }

    if (reqData?.category === 'WATER_READING') {
      expenseUrl = 'expense/water/'
    }


    api.post(expenseUrl, data )
      .then(response => {
        setFilters(prevState => ({...prevState, expenseId: response?.data.id}))

        enqueueSnackbar('Despesa cadastrada com sucesso!', { variant: 'success' })
        setFilters(prevState => ({...prevState, firstStep: true}))

        userFiles?.map(file =>
          api.post('expense/document/',
            {
              expense: response.data.id,
              file: file,
            },
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).
          then(r => {
          }).catch(error => {
            console.log(error.response.data)
          })
        )
        if (response.status === 201) {
          if (installments > 1) {
            api.post('expense/installment/', {
              expense: r?.data.id, installments: installments
            }).then(r => {

              enqueueSnackbar('Despesa cadastrada com sucesso!', { variant: 'success' })
            }).catch(response => {
              enqueueSnackbar('Erro ao criar despesa, por favor revise as informações', { variant: 'error' })
              setLoadingModal(false)
              console.log(response)
            }).finally(()=>{
              setLoadingModal(false)
            })
          }
        }

      }).catch(reason => {
      setLoadingModal(false)
      console.log(reason)
      setErrors(reason?.response.data)
    })
      .finally(()=>{
        setLoadingModal(false)
      })
  }

  useEffect(function getOptions(){
    getCategories(setCategoryList, condoIdLogin)
  }, [])

  useEffect(function initialFilters(){
    setFilters(prevState => ({
      ...prevState,
      operationType: '',
      transferReq: false,
      paymentReq: false,
      transferDone: false,
      paymentDone: false,
      codeSent: false,
      payConfirmation: false,
      codeLength: false,
      firstStep: false,
      secondStep: false,
      expenseId: null,
      activeStep: 0
    }))
  }, [])

  useEffect(function categoryOptions(){
    setCategoryOptions(
      categoryList.reduce(function(result, current) {
        return Object.assign(result, current);
      }, {})
    )
  }, [categoryList])

  useEffect(function getGasValue() {
    gasWaterValue(reqData, setGasWaterTotalValue)
  }, [reqData?.current_reading_amount])

  useEffect(() => {
    reqData?.division_pattern !== 'UNIT' && delete reqData?.unit;

    if (reqData?.category !== 'GAS' && reqData?.category !== 'WATER') {
      delete reqData?.previous_reading_amount;
      delete reqData?.current_reading_amount;
      delete reqData?.previous_reading_date;
      delete reqData?.current_reading_date;
      setGasWaterTotalValue('');
    }
  }, [reqData?.category]);

  useEffect(function clearHelperText(){
    setPreviousData(null)
  }, [reqData?.division_pattern, reqData?.unit])

  useEffect(function previousDateAndReading (){
    gasWaterPreviousReading(reqData, setPreviousData)
  },[reqData?.unit, reqData?.category ])

  return (
    <>
      <Helmet>
        <title>Criar Despesa - Bloco B3</title>
      </Helmet>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingModal}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>

      <Container >
         <ExpenseWizard
           handleSubmit={handleSubmit}
         >
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={2}>
                {filters?.activeStep === 0 && !filters?.firstStep &&
                  <>
                    <ExpenseInfo
                      title={'Nova Despesa'}
                      reqData={reqData}
                      setReqData={setReqData}
                      setShowGas={setShowGas}
                      setGasWaterRequired={setGasWaterRequired}
                      categoryOptions={categoryOptions}
                      disabled={false}
                      errors={errors}
                    />

                    <GasConsume
                      state={reqData}
                      setState={setReqData}
                      showGas={showGas}
                      previousData={previousData}
                      gasRequired={gasWaterRequired}
                      errors={errors}
                    />

                    <WaterConsume
                      state={reqData}
                      setState={setReqData}
                      previousData={previousData}
                      setGasWaterRequired={setGasWaterRequired}
                      errors={errors}
                    />

                    <ExpensePayment
                      reqData={reqData}
                      setReqData={setReqData}
                      gasWaterRequired={gasWaterRequired}
                      installments={installments}
                      setInstallments={setInstallments}
                      errors={errors}
                    />
                  </>
                }
            </Grid>

            </Grid>
            {!filters?.firstStep &&
              <Grid item xs={12} lg={4}>
                <Grid container spacing={2}>
                <ExpenseResume
                  reqData={reqData}
                  reqDataInstallments={reqDataInstallments}
                  gasWaterTotalValue={gasWaterTotalValue}
                  installments={installments}
                />
                <Grid item xs={12}>

                  <ExpenseDocuments />

                </Grid>
              </Grid>
            </Grid>
            }

          </Grid>
          {activeStep === 1 && !secondStep &&
            <Card
              sx={{
                width: '100%',
              }}
            >
              <CardHeader
                avatar={<Avatar sx={{ bgcolor: 'primary.main' }}><ReceiptLongOutlined/></Avatar>}
                title={'Realizar pagamento'}/>
              <Divider sx={{ mb: '.3rem' }}/>
              <Fade
                in={true}
                mountOnEnter
                unmountOnExit
                timeout={300}
              >
                <CardContent
                  sx={{
                    paddingX: sizeMatch ? '10rem' : 'auto'
                  }}
                >
                    { perm_bank === true ?
                      <Box
                        sx={{
                          maxWidth: '400px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: '0 auto'
                        }}
                      >
                        <ToggleButtonGroup
                          size={'small'}
                          fullWidth
                          exclusive
                          value={operationType}
                          color={'primary'}
                          onChange={(e, value) => setFilters(prevState => ({
                            ...prevState,
                            operationType: value
                          }))}
                          sx={{ mb: 2 }}
                        >
                          <ToggleButton
                            value={'payment'}
                            selected={operationType === 'payment'}
                          >
                            Boleto bancário
                          </ToggleButton>
                          <ToggleButton
                            value={'transfer'}
                            selected={operationType === 'transfer'}
                          >
                            Transferência bancária
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>

                      :
                      <Box
                        sx={{
                        marginY: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                        }}
                      >
                        <Alert
                          severity="warning"
                        >
                          Desculpe, você não possui permissão para realizar esta etapa. Para prosseguir clique no botão 'PULAR'.
                        </Alert>
                      </Box>
                    }


                  {operationType === 'payment' &&
                    <Payment/>
                  }

                  {operationType === 'transfer' &&
                    <BankTransfer/>
                  }

                </CardContent>
              </Fade>
            </Card>
          }

          {activeStep === 2 &&
            <Card>
              <Fade
                in={true}
                mountOnEnter
                unmountOnExit
                timeout={300}
              >
                <Box>
                  <CardHeader
                    avatar={<Avatar sx={{ bgcolor: 'primary.main' }}><Check/></Avatar>}
                    title={'Conclusão'}/>
                  <Divider sx={{ mb: '.3rem' }}/>
                  <CardContent
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mt: '1rem', flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <AnimationGlobal
                      size={'200px'}
                      animation={successAnimation}
                      fontStyles={{mb: '1rem'}}
                      fontSize={'20px'}
                    />
                    <List
                      sx={{
                        paddingBottom: '5rem'
                      }}
                    >
                      <ListItem sx={{display: 'flex'}}>
                        <Avatar sx={{backgroundColor: 'success.main', mr: '0.5rem'}}>
                          <Check sx={{color: 'white', fontSize: '2rem'}} />
                        </Avatar>
                        <Typography variant="body1" sx={{fontSize: '1.5rem'}}>Despesa criada com sucesso</Typography>
                      </ListItem>
                      <ListItem sx={{display: 'flex'}}>
                        {avatarType()}
                        <Typography variant="body1" sx={{fontSize: '1.5rem'}}>
                          {conclusionText()}
                        </Typography>
                      </ListItem>
                    </List>
                  </CardContent>
                 </Box>
              </Fade>
            </Card>
          }

          {firstStep && activeStep === 0 && stepComplete()}
          {secondStep && activeStep === 1 && stepComplete()}

        </>
        </ExpenseWizard>

      </Container>
    </>
  )
}

export default ExpenseCreate
