import {TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import React, {useContext} from "react";
import Context from "../../../contexts/Context";

const DeliveryTableHead = ({ filters, setFilters }) => {

  const handleFilter = (asc: string, desc: string) => {
    setFilters(prevState => ({
        ...prevState,
        ordering: filters?.ordering === asc ? desc : asc
      })
    )
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-name_delivery' || filters?.ordering === 'name_delivery'}
            direction={filters?.ordering === '-name_delivery' ? 'desc' : 'asc'}
            onClick={() => handleFilter('name_delivery', '-name_delivery') }
          >
            Destinatário
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-exit_date' || filters?.ordering === 'exit_date'}
            direction={filters?.ordering === '-exit_date' ? 'desc' : 'asc'}
            onClick={() => handleFilter('exit_date', '-exit_date') }
          >
           Entregue
          </TableSortLabel>
        </TableCell>
        <TableCell
          // align={'left'}
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-unit_delivery' || filters?.ordering === 'unit_delivery'}
            direction={filters?.ordering === '-unit_delivery' ? 'desc' : 'asc'}
            onClick={() => handleFilter('unit_delivery', '-unit_delivery') }
          >
           Imóvel
          </TableSortLabel>
        </TableCell>
        <TableCell
          align={'center'}
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-delivered' || filters?.ordering === 'delivered'}
            direction={filters?.ordering === '-delivered' ? 'desc' : 'asc'}
            onClick={() => handleFilter('delivered', '-delivered') }
          >
            Situação
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default DeliveryTableHead