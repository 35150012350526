import React, {useContext} from "react";
import PaymentChecker from "./PaymentChecker";
import PaymentPreview from "./PaymentPreview";
import PayButton from "./PayButton";
import Context from "../../../../contexts/Context";
import SimpleDialog from "../../../../components/Shared/SimpleDialog";

const PaymentCard =
  ({
     data,
     setData,
     handleSubmitPreview,
     duplicatedPaymentChecker,
     error,
     setError,
     paymentReq,
     date,
     setDate,
     setPaymentReq
  }) => {

  const { filters, setFilters } = useContext(Context)

  const { openPayDialog} = filters || {}



  return (
    <>
            <PaymentPreview
              paymentReq={paymentReq}
            />

            <PaymentChecker
              data={data}
              setData={setData}
              handleSubmitPreview={handleSubmitPreview}
              error={error}
              paymentReq={paymentReq}
            />

            <PayButton
              date={date}
              setDate={setDate}
              setError={setError}
              paymentReq={paymentReq}
              setPaymentReq={setPaymentReq}
              duplicatedPaymentChecker={duplicatedPaymentChecker}

            />
            <SimpleDialog
              title={'Pagamento duplicado'}
              disableBackdropClick
              open={openPayDialog}
              onClickCancel={() => setFilters(prevState => ({...prevState, openPayDialog: false }))}
              question={'Esse pagamento já foi agendado. Deseja prosseguir mesmo assim?'}
              onClickConfirm={() => {
                setFilters(prevState => ({...prevState, openPayDialog: false, duplicityChecker: {success: true} }))
              }}
            />

    </>
  )

}

export default PaymentCard