import { FormControl, InputLabel, Select } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'

export const ExpenseCategory = (props) => {
  const { selected, values, onChange } = props

  return (
    <FormControl size={'small'} fullWidth>
      <InputLabel id={'category_label'}>{'Categoria'}</InputLabel>
      <Select
        id={'category-filter'}
        label={'Categoria'}
        name={'category-filter'}
        labelId={'category_label'}
        defaultValue=""
        value={selected || ''}
        onChange={onChange}
      >
        {Object.keys(values).map((item) => (
          <MenuItem key={item} value={item}>{values[item]}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
