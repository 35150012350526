import {Autocomplete, Grid, Skeleton, TextField} from "@mui/material";
import React from "react";

const StateFilter = ({ state, setState, keyName, isLoading, error, styles }) => {
  const uf = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MG',
    'MT',
    'MS',
    'PA',
    'PB',
    'PE',
    'PI',
    'PR',
    'RJ',
    'RN',
    'RO',
    'RS',
    'RR',
    'SC',
    'SE',
    'SP',
    'TO',
  ]



  return (
    <Grid item xs={12} sm={4}>
      {!isLoading ?
        <Autocomplete
          fullWidth
          size={"small"}
          isOptionEqualToValue={(option, value) => typeof option === typeof value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          disableClearable
          sx={styles}
          onChange={
            (_event, value) => {
              setState((prevState) => ({ ...prevState, [keyName]: value ?? '' }))
            }}
          value={state?.[keyName] ?? ''}
          options={uf?.map((option) => option ?? '')}
          renderInput={(params) => <TextField
            {...params}
            label="UF"
            error={!!error?.uf ?? ''}
            helperText={error?.uf}
          />}
        />
        :
        <Skeleton
          variant="rounded"
          animation="pulse"
          sx={{
            height: '39.974px',
          }}
        />
      }
    </Grid>
  )
}

export default StateFilter