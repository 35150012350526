import {Avatar, Card, CardContent, CardHeader, Divider, Grid} from "@mui/material";
import {AddBusiness} from "@mui/icons-material";
import SimpleText from "../../../../../components/Shared/SimpleText";
import SimpleSelector from "../../../../../components/Shared/SimpleSelector";

const ProviderAdress = ({ data, setData, isLoading }) => {

  const uf = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ]


  return (
    <Card
      sx={{
        marginY: "1rem"
      }}
    >
      <CardHeader
        title={"Endereço"}
        avatar={
          <Avatar
            sx={{
              bgcolor: "primary.main"
            }}>
            <AddBusiness/>
          </Avatar>
        }
      />
      <Divider
        sx={{
          marginY: '.3rem'
        }}
      />
      <CardContent>
        <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
        >
          <Grid
            item
            xs={12}
            lg={6}
          >
            <SimpleText
              label={'CEP'}
              cep
              state={data}
              setState={setData}
              keyName={'cep'}
              isLoading={isLoading}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
          >
            <SimpleText
              label={'Rua'}
              state={data}
              setState={setData}
              keyName={'street'}
              isLoading={isLoading}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
          >
            <SimpleText
              label={'Número'}
              onlyNumbers
              state={data}
              setState={setData}
              keyName={'number'}
              isLoading={isLoading}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
          >
            <SimpleText
              label={'Bairro'}
              state={data}
              setState={data}
              keyName={'district'}
              isLoading={isLoading}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
          >
            <SimpleText
              label={'Cidade'}
              state={data}
              setState={setData}
              keyName={'city'}
              isLoading={isLoading}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
          >
            <SimpleSelector
              label={'UF'}
              state={data}
              setState={setData}
              keyName={'state'}
              options={uf}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ProviderAdress