import {Helmet} from "react-helmet";
import React, {useContext, useEffect, useState} from "react";
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container, Fade,
  Skeleton,
  Table,
  TableContainer,
  TextField,
  Typography
} from "@mui/material";
import TableDocumentHead from "../Documents/components/TableDocumentHead";
import TableDocumentBody from "../Documents/components/TableDocumentBody";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import TableReportHead from "./components/TableReportHead";
import TableReportBody from "./components/TableReportBody";
import Context from "../../../contexts/Context";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {api} from "../../../services/Main/Api";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import moment from "moment";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import RentReportBody from "./components/RentReportBody";
import RentReportHead from "./components/RentReportHead";
import {FilterList, Receipt} from "@mui/icons-material";

const ReportList = () => {
  const [filters, setFilters] = useState({
    condo_id: getCondoId(),
    page_size: 50
  })
  const [openDialog, setOpenDialog] = useState(false)
  const [reqData, setReqData] = useState({
    month: moment(),
    year: moment(),
  })
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [openFilter, setOpenFilter] = useState(false)

  async function getReports() {

    const data = {
      ...filters,
      month: moment(filters?.month).month() + 1,
      year: moment(filters?.year).year()
    }

    try {
      const response = await api.get(`general_rent_report/`, { params: data });
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  async function generateReport() {
    const data = {
      condo: +getCondoId(),
      month: +moment(reqData?.month).format('MM'),
      year: +moment(reqData?.year).format('yyyy')
    }

    try {
      const response = await api.post(`general_rent_report/`, data);
      setOpenDialog(false)
      getReports()
    } catch (error) {
      console.error(error);
    }
  }

  function initialFilterState() {
    setFilters({
      condo_id: getCondoId(),
      page_size: 50
    })
    setOpenFilter(false)
  }

  useEffect(() => {
    getReports();
  }, [filters]);

  return (
    <>
      <Helmet>
        <title>Relatórios - Bloco B3</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          size={'small'}
          startIcon={<Receipt />}
          variant={'contained'}
          sx={{
            m: '1rem',
          }}
          onClick={() => setOpenDialog(true)}
        >
          Gerar Relatório
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          // justifyContent: 'space-between'
        }}
      >
        {!openFilter ?
          <Button
            size={'small'}
            startIcon={<FilterList/>}
            onClick={() => setOpenFilter(true)}
            sx={{
              marginX: '1rem',
              p: 0

            }}
          >
            Filtrar
          </Button>
          :
          <Button
            size={'small'}
            startIcon={<FilterList />}
            onClick={() => {
              initialFilterState()
            }}
            sx={{
              marginX: '1rem',
              p: 0
            }}
          >
            Remover filtro
          </Button>
        }
      </Box>


      <Fade
          in={openFilter}
          mountOnEnter
          unmountOnExit
          timeout={300}
        >

          <Box
            sx={{
              marginX: '1rem',
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'space-evenly',
              width : '40%',
            }}
          >
            <DesktopDatePicker
              label="Mês"
              disableMaskedInput
              inputFormat="MMMM"
              value={filters?.month}
              openTo={'month'}
              views={['month']}
              disableHighlightToday
              onChange={(e) => {
                setFilters((prevState) => ({...prevState, month: moment(e).format('MM')}))
                setError((prevState) => ({ ...prevState, month: null }))
              }}
              renderInput={(params) =>
                <TextField
                {...params}
                size={'small'}
                fullWidth
                error={!!error?.month}
                helperText={error?.month ? error.month : ''}
                sx={{
                  pr: '1rem'
                }}
                variant={'standard'}


                />}
            />

            <DesktopDatePicker
              label="Ano"
              disableMaskedInput
              inputFormat="yyyy"
              value={filters?.year}
              openTo={'year'}
              views={['year']}
              disableHighlightToday
              onChange={(e) => {
                setFilters((prevState) => ({...prevState, year: moment(e).format()}))
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  fullWidth
                  size={'small'}
                  variant={'standard'}
                />}
            />

          </Box>
        </Fade>




      <SimpleDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={'Gerar Relatório de Locação'}
        // question={'Realmente deseja remover esse beneficiário do contrato?'}
        onClickConfirm={() => generateReport()}
        onClickCancel={() => {
          setOpenDialog(false)
          setReqData(null)
        }}
        dialogContent={
          <CardContent>

            <DesktopDatePicker
              label="Mês"
              disableMaskedInput
              inputFormat="MMMM"
              value={reqData?.month}
              openTo={'month'}
              views={['month']}
              disableHighlightToday
              onChange={(e) => {
                setReqData((prevState) => ({...prevState, month: moment(e).format()}))
                setError((prevState) => ({ ...prevState, month: null }))
              }}
              renderInput={(params) => <TextField
                {...params}
                size={'small'}
                fullWidth
                error={!!error?.month}
                helperText={error?.month ? error.month : ''}
                sx={{
                  mb: '1rem'
                }}

              />}
            />

            <DesktopDatePicker
              label="Ano"
              disableMaskedInput
              inputFormat="yyyy"
              value={reqData?.year}
              openTo={'year'}
              views={['year']}
              disableHighlightToday
              onChange={(e) => {
                setReqData((prevState) => ({...prevState, year: moment(e).format()}))
                setError((prevState) => ({ ...prevState, year: null }))
              }}
              renderInput={(params) => <TextField
                {...params}
                fullWidth
                size={'small'}
                error={!!error?.year}
                helperText={error?.year ? error.year : ''}

              />}
            />

          </CardContent>


          }

        />


        {data?.results ?
          <>

            {/*<ToggleButtonGroup*/}
            {/*  fullWidth*/}
            {/*  exclusive*/}
            {/*  value={filters?.report}*/}
            {/*  color={'primary'}*/}
            {/*  onChange={(e, value) => {*/}
            {/*    value !== null &&*/}
            {/*    setData(null)*/}
            {/*    setFilters(prevState => ({*/}
            {/*      ...prevState,*/}
            {/*      report: value*/}
            {/*    }))*/}
            {/*  }}*/}
            {/*  sx={{p: '1rem' }}*/}
            {/*>*/}
            {/*  <ToggleButton value={"month"}>Mensal</ToggleButton>*/}
            {/*  <ToggleButton value={"year"}>Anual</ToggleButton>*/}
            {/*</ToggleButtonGroup>*/}
            <TableContainer sx={{p: 2}}>

              { data?.results?.length > 0 ?
                <Table sx={{ minWidth: 800}}>
                  <RentReportHead
                    filters={filters}
                  />
                  <RentReportBody
                    state={data}
                    filters={filters}
                  />
                </Table>
                :
                <NoResultsAnimation />
              }
            </TableContainer>
          </>
          :
          <Skeleton
            variant="rounded"
            height={700}
            animation="pulse"
          />
        }
      <PaginationGlobal
        state={data}
        filters={filters}
        setFilters={setFilters}
      />

    </>
  )
}

export default ReportList