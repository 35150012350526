import PropTypes from 'prop-types'
import {
  AttachMoneyOutlined,
  ClassOutlined,
  CleaningServicesOutlined,
  ManageAccountsOutlined,
  OpacityOutlined,
  PowerOutlined,
  PropaneTankOutlined,
  SavingsOutlined,
  HomeRepairServiceOutlined,
  CircleOutlined, Water
} from '@mui/icons-material'
import { Avatar } from '@mui/material'

const CategoryIcon = ({variant, isClosed}) => {



  function renderIcon(value) {
    if (value === 'WATER') {
      return [<OpacityOutlined sx={{color: !isClosed ? "secondary.light" : '#21b04b'}} />, "secondary.light"]
    } else if (value === 'WATER_READING') {
      return [<Water sx={{color: !isClosed ? "secondary.light" : '#21b04b'}} />, "secondary.light"]
    } else if (value === 'ENERGY') {
      return [<PowerOutlined sx={{color: !isClosed ? "secondary.light" : '#21b04b'}} />, "secondary.light"]
    } else if (value === 'RESERVE_FUND') {
      return [<SavingsOutlined sx={{color: !isClosed ? "secondary.light" : '#21b04b'}} />, "secondary.light"]
    } else if (value === 'GAS') {
      return [<PropaneTankOutlined sx={{color: !isClosed ? "secondary.light" : '#21b04b'}} />, "secondary.light"]
    } else if (value === 'ADMINISTRATION_FEE') {
      return [<ManageAccountsOutlined sx={{color: !isClosed ? "secondary.light" : '#21b04b'}} />, "secondary.light"]
    } else if (value === 'CLEANING_FEE') {
      return [<CleaningServicesOutlined sx={{color: !isClosed ? "secondary.light" : '#21b04b'}} />, "secondary.light"]
    } else if (value === 'DISCOUNT') {
      return [<AttachMoneyOutlined sx={{color: !isClosed ? "secondary.light" : '#21b04b'}} />, "secondary.light"]
    } else if (value === 'SERVICE_FEE') {
      return [<HomeRepairServiceOutlined sx={{color: !isClosed ? "secondary.light" : '#21b04b'}} />, "secondary.light"]
    } else {
      return [<CircleOutlined sx={{color: !isClosed ? "secondary.light" : '#21b04b'}} />, "secondary.light"]
    }
  }

  return (
    <Avatar sx={{width: 36, height: 36, mr: 2, bgcolor: "transparent", border: `.05px solid`, borderColor: `${renderIcon(variant)[1]}`}}>
      {renderIcon(variant)[0]}
    </Avatar>
  )
}

CategoryIcon.propTypes = {
  variant: PropTypes.string.isRequired
}

export default CategoryIcon
