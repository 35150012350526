import {IconButton, ListItemIcon, ListItemText, MenuItem, TableBody, TableCell, TableRow} from "@mui/material";
import React, {useContext, useState} from "react";
import moment from "moment";
import {Close, Delete, FileOpen, MoreVert, Receipt, Share} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import Masks from "../../../components/Shared/Masks";
import Context from "../../../contexts/Context";
import Menu from "@mui/material/Menu";
import {Link as RouterLink} from "react-router-dom";

const HistoryTableBody = (props) => {
  const { state, setTransferOpen, setFilters } = props
  const [selectedTransfer, setSelectedTransfer] = useState(null)
  const [anchor, setAnchor] = useState(null)
  const [open, setOpen] = useState(false)

  console.log(state)

  return (
    <TableBody>
      { state?.results?.map((transfer, index) => (
        <TableRow
          hover
          tabIndex={-1}
          key={index}
          sx={{ 'cursor': 'pointer' }}
          onClick={(e) => {
            e.stopPropagation()
            setFilters(prevState => ({...prevState, isUpdate: true , id: transfer?.id, load: true}))
            setTransferOpen(true)
          }}
        >

          <TableCell
            component="th"
            scope="row"
          >
            {transfer?.rent_recipient_name ?? <Close sx={{color: 'error.main'}}/>}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
          >
            {moment(transfer?.created_at).format('DD/MM/YYYY HH:mm')}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
          >
            {Masks.money(String(transfer?.value)) ?? ''}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
          >
            {transfer?.user_detail?.first_name}
          </TableCell>
          <TableCell
            // component="th"
            // scope="row"
            // align={'center'}
            sx={{
              fontWeight: 'bold',
              // color: deliver?.delivered ? '#47b514' : '#ad0000'
            }}
          >
            {
              transfer?.status?.label?.replace(/Transferência\s(\w+)/i, (_, p1) => p1.charAt(0).toUpperCase() + p1.slice(1))
            }
          </TableCell>


          <TableCell
            align={'center'}
            component="th"
            scope="row"
            sx={{
              width: '24px'
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setSelectedTransfer(transfer)
                setAnchor(e.currentTarget)
                setOpen(transfer.id)
              }}>
              <MoreVert/>
            </IconButton>

            <Menu
              open={open === transfer.id}
              anchorEl={anchor && anchor}
              onClose={(e) => {
                e.stopPropagation()
                setOpen(false)
              }}
              PaperProps={{
                sx: { width: 200, maxWidth: '100%' },
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuItem
                component={RouterLink}
                onClick={(e)=> {
                  setOpen(false)
                  e.stopPropagation()
                }}
                to={transfer?.receipt}
                target="_blank"
                // disabled={invoice?.status?.key !== "open"}
                sx={{
                  color: 'text.secondary'
                }}
              >
                <ListItemIcon>
                  <Receipt />
                </ListItemIcon>
                <ListItemText
                  primary="Recibo"
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </MenuItem>

              <MenuItem
                component={RouterLink}
                onClick={(e)=> {
                  setOpen(false)
                  e.stopPropagation()
                }}
                target="_blank"
                to={transfer?.rent_report}
                // disabled={invoice?.status?.key !== "open"}
                sx={{
                  color: 'text.secondary'
                }}
              >
                <ListItemIcon>
                  <FileOpen />
                </ListItemIcon>
                <ListItemText
                  primary="Demonstrativo"
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </MenuItem>


            </Menu>
          </TableCell>
        </TableRow>
      ))}

    </TableBody>
  )
}

export default HistoryTableBody